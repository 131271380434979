import API from "../../constants/api";
import { REQ_TIRESMIN_SUCCESS } from "../types/tireMinTypes";

export const getTiresMinAction = (customer_id) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getTiresMin(customer_id)
        .then(res => {
          if (res.tires) {
            dispatch({
              type: REQ_TIRESMIN_SUCCESS,
              payload: res.tires
            })
            resolve(res.tires)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}