import moment from "moment";
import chunk from "lodash/chunk";
import React from "react";
import { trackPromise } from "react-promise-tracker";

import { toast } from "react-toastify";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

class Inspections extends React.Component {
  constructor(props) {
    super(props);

    this.state = { inspections: [] };
  };

  async componentDidMount() {
    let fechaInicial = new URLSearchParams(window.location.search).get("fecha_inicial")
    let fechaFinal = new URLSearchParams(window.location.search).get("fecha_final")
    let str = fechaInicial != "null" ? "?fecha_inicial=" + fechaInicial + "&fecha_final=" + fechaFinal : ""
    let responseInspections = await fetch(`${APIS.API_REST_INSPECTIONS_TECHNICIAN_ANALYST.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + str}`, {
      headers:{
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      } });
    let jsonInspections = await responseInspections.json();
    this.setState({inspections: jsonInspections.Avances });
  };

  deleteInspection = (id) => {
    trackPromise(fetch(APIS.API_REST_INSPECTIONS_TECHNICIAN_ANALYST_DELETE.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + "/" + id, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "DELETE" })
      .then(response => response.json())
      .then(json => {
        let inspections = [...this.state.inspections]
        inspections = inspections.filter(inspection => inspection.id != id)
        console.log(inspections)
        this.setState({ inspections });
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.ins-one-item-28")
        }
        toast.error(err.toString());
        this.setState({
          error: err
        });
      }));
  }

  getLastInspectionsView = (result) => {
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th>{this.props.t("ins-prev.ins-list-1")}</th>
                  <th>{this.props.t("ins-prev.ins-list-2")}</th>
                  <th>{this.props.t("ins-prev.ins-list-3")}</th>
                  <th>{this.props.t("ins-prev.ins-list-4")}</th>
                  <th>{this.props.t("ins-prev.ins-list-5")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  result.map((ins, i) => {
                    return <tr key={i}>
                      <td>{ins.placa + " " + ins.numero_vehiculo}</td>
                      <td>{!!ins.params ? ins.params.type : null}</td>
                      <td>{!!ins.params ? ins.params.value : null}</td>
                      <td>{ins.tecnico}</td>
                      <td>{moment.utc(ins.fecha).local()
                                 .locale("es").format("LLL")}</td>
                      <td>
                        <button className="btn btn-danger"
                          onClick={(event) => this.deleteInspection(ins.id)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
          <br />
        </div>
      </div>
    );
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("ins-prev.ins-list-6")}</h3>
            <br />
            {
              !!this.state.inspections && this.state.inspections.length > 0 ?
                this.getLastInspectionsView(this.state.inspections) : null
            }
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Inspections);
