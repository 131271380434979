import moment from "moment";
import "moment/locale/es";
import React, { Component } from "react";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { trackPromise } from "react-promise-tracker";
import $ from "jquery";

import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";
import * as VARIABLES from "../../../constants/variables";

import MoreInfoTireInfo from "./MoreInfoTireInfo";
import TypeFormTire from "./TypeFormTire";
import InspectionHlForm from "./InspectionHlForm";
import NoveltysList from "./NoveltysList";
import { Link } from "react-router-dom";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;
const fieldsInspection = VARIABLES.INSPECTION_FIELDS;

const escapeRegexCharacters = str => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const getSuggestionValue = suggestion => suggestion.codigo;

const renderSuggestion = suggestion => (
  <div>
    {suggestion.codigo}
  </div>
);

const INITIAL_STATE = {
  tiresList: {
    Registrar: [],
    reencauche: [],
    catNew: [],
    catRetread: []
  },
  tire_code: "",
  type: "",
  suggestions: [],
  fields: [],
  warehouses: [],
  combustibles: [],
  vehicle_id: [],
  work_date: null,
  warehouse_id: null,
  result: null,
  error: null,
  noveltysCount: 0,
};

class TireInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if (this.props.codigo && this.props.findNow) {
      this.setState({ tire_code: this.props.codigo })
      this.onSubmit()
    }
  }

  getLastInspectionDate = (date) => {
    let fmtDate = "No disponible";
    if (!!date) {
      if (isNaN(1 * date)) {
        fmtDate =
          moment.utc(date).local().locale("es").format("LLL");
      } else {
        fmtDate =
          moment.unix((1 * date) / 1000000000).local().locale("es").format("LLL");
      }
    }
    return fmtDate;
  };

  getTableResult = object => {
    return <table className="table table-striped mb-0">
      <tbody key={object.codigo}>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-1")}</th>
          <td>{object.marca}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-2")}</th>
          <td>{object.modelo}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-3")}</th>
          <td>{object.dimension}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-4")}</th>
          <td>{object.retreadbrand}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-5")}</th>
          <td>{object.retreadband}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-6")}</th>
          <td>{object.retreadimention}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-7")}</th>
          <td>{object.vida}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-8")}</th>
          <td>{object.km_recorrido}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-9")}</th>
          <td>{object.codigo}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-10")}</th>
          <td>{object.DOT}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-11")}</th>
          <td>{1 * object.posicion_montaje}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-12")}</th>
          <td>{object.precio}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-13")}</th>
          <td>{object.prof_minima}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-14")}</th>
          <td>{object.prof_original}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-15")}</th>
          <td>{object.ult_prof_centro_exterior}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-16")}</th>
          <td>{object.ult_prof_centro}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-17")}</th>
          <td>{object.ult_prof_interior}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-18")}</th>
          <td>{object.vehiculo}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-19")}</th>
          <td>{!!object.regrabada ? "Si" : "No"}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-20")}</th>
          <td>{object.regrabado}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-21")}</th>
          <td>{object.vehicle_plate}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-22")}</th>
          <td>{object.ubicacion}</td>
        </tr>
        <tr>
          <th>{this.props.t("ins-one.ins-one-tire-23")}</th>
          <td>
            {this.getLastInspectionDate(object.fc_ultima_inspeccion)}
          </td>
        </tr>
      </tbody>
    </table>
  }

  changeWarehouse = (e) => {
    e.preventDefault()
    let tire_code = this.state.tire_code;
    let str = "?by_inspection=true"
    const work_date = moment.utc(this.state.work_date).format();
    const warehouse_id = this.state.warehouse_id

    let tire_id = this.props.tires.find(x => x.codigo === tire_code).id;
    var data = { cost: 0, worktype: "bodegas", work_date, warehouse_id, };
    trackPromise(fetch(`${APIS.API_REST_TIRE_WORK.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id", tire_id)}` + str, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          toast.success(this.props.t("alerts.ins-one-item-50"))
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onSubmit = event => {
    if (!!event) event.preventDefault();
    if (isInternetConnected(toast)) return;

    let tire_code = this.state.tire_code || this.props.codigo;

    let tire_id = this.props.tires.find(x => x.codigo === tire_code).id;
    let res = [];

    trackPromise(fetch(`${APIS.API_REST_TIRES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${tire_id}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(this.props.t("alerts.ins-one-item-51"));
        }
        res = json;
        this.setState({ fields: [], type: "" });
        return fetch(`${APIS.API_REST_NOVELTIES_TIRE.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id", tire_id)}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        let message = json.message;
        if (!message) {
          this.setState({ noveltysCount: json.novedades.length, result: res });
        }
        this.setState({
          vehicle_id: res.vehicle_id
        });
        if (res.vehicle_id !== null && res.vehicle_id !== "") {
          trackPromise(fetch(`${APIS.API_REST_FUELS_HISTORY.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("vehicle_id", res.vehicle_id)}`, {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
            .then(response => response.json())
            .then(json => {
              if (!!json.message) {
                this.setState({
                  combustibles: []
                });
                throw new Error(this.props.t("alerts.ins-one-item-52"));
              }
              this.setState({
                combustibles: json.combustibles
              });
            })
            .catch(error => {
              let err = error;
              if (valConnection.indexOf(error.toString()) > -1) {
                err = this.props.t("alerts.ins-one-item-28");
              }
              toast.error(err.toString());
            }));
        }
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.ins-one-item-28");
        }
        toast.error(err.toString());
        this.setState({ error: error })
      }));
  };

  onChange = event => {
    let cfields = [...fieldsInspection];
    switch (event.target.value) {
      case "descarte":
        cfields = cfields.filter(st => (st !== "marca_id") &&
          (st !== "modelo_id") && (st !== "dimension_id") &&
          (st !== "prof_original") && (st !== "prof_minima") &&
          (st !== "vida") && (st !== "diseno") && (st !== "ciudad") &&
          (st !== "centro_costo") && (st !== "ubicacion") && (st !== "hours")
          && (st !== "tipo_trabajo") && (st !== "costo"));
        setTimeout(
          () => {
            $("#posicionNovelties1").modal();
            $("#noveltyTypePos1 option[value=Desecho]").show();
            $("#noveltyTypePos1 option[value=Novedad]").hide();
            $("#noveltyTypePos1 option[value=Antecedente]").hide();
            $("#DOT1").show();
          },
          1000
        );
        break;
      case "reencauche":
        cfields = cfields.filter(st => (st !== "marca_id") &&
          (st !== "modelo_id") && (st !== "dimension_id") &&
          (st !== "diseno") && (st !== "ciudad") &&
          (st !== "centro_costo") && (st !== "hours") &&
          (st !== "precio_regrabado") && (st !== "tipo_trabajo") && (st !== "costo") && (st !== "pos_final") && (st !== "pos_initial"));
        this.setState({
          tiresList: this.props.cataloguesTire
        });
        if (!!this.props.object) {
          this.setBandsState(this.props.object.dimension)
        }
        break;
      case "regrabado":
        cfields = cfields.filter(st => (st !== "marca_id") &&
          (st !== "modelo_id") && (st !== "dimension_id") &&
          (st !== "diseno") && (st !== "ciudad") &&
          (st !== "centro_costo") && (st !== "ubicacion") && (st !== "hours") &&
          (st !== "tipo_trabajo") && (st !== "costo") &&
          (st !== "precio_reencauche") && (st !== "precio_catalogo") && (st !== "sin_uso") && (st !== "pos_final") && (st !== "pos_initial"));
        break;
      default:
        cfields = [];
    };
    this.setState({
      fields: cfields,
      [event.target.name]: event.target.value
    });
  };

  getSuggestions = value => {
    let tires = this.props.tires;
    let fmtSuggestions = tires.filter(tire => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return tire.codigo.toLowerCase().search(fmtValue) >= 0;
    });
    return fmtSuggestions.slice(0, 20);
  };

  onChangeTire = (event, { newValue }) => {
    this.setState({ tire_code: newValue });
  };

  onTiresFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onTiresClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  render() {
    const { tire_code, fields, suggestions, type, result, noveltysCount, work_date, warehouse_id } = this.state;
    const { cars, customerId, getTires, authUser } = this.props;
    const tireProps = {
      placeholder: "",
      value: tire_code,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "1%",
        marginBottom: 0
      },
      onChange: this.onChangeTire
    };

    let isInvalidTireCode = tire_code === "";
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <form onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col-sm-2">
                  <label htmlFor="inputCarCode">{this.props.t("ins-one.ins-one-tire-24")}</label>
                </div>
                <div className="col-sm-10 input-group mb-3">
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onTiresFetchRequested}
                    onSuggestionsClearRequested={this.onTiresClearRequested}
                    shouldRenderSuggestions={() => (true)}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={tireProps}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary text-white"
                      type="submit"
                      disabled={isInvalidTireCode}><i className="fa fa-search"></i> {this.props.t("ins-one.ins-one-tire-25")}</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {!!result ?
          <div className="row mb-3">
            <MoreInfoTireInfo
              t={this.props.t}
              object={result}
              authUser={authUser}
              records={this.props.records}
              waste={this.props.waste}
              novelties={this.props.novelties}
              movements={this.props.movements}
              onSubmitTire={this.onSubmit} />
            <NoveltysList
              t={this.props.t}
              authUser={authUser}
              tireId={result.id}
              noveltysCount={noveltysCount}
              divId={result.id} />
          </div> : null}
        {!!result && !!this.props.authUser.permissions.create ?
          <TypeFormTire isMultiple={false} type={type} delCreate={true} t={this.props.t} onChange={this.onChange} authUser={authUser} /> : null}
        {
          !!result && !!type && !!result && !!fields && fields.length > 0 ?
            <InspectionHlForm
              object={result}
              t={this.props.t}
              fields={fields}
              records={this.props.records}
              waste={this.props.waste}
              novelties={this.props.novelties}
              movements={this.props.movements}
              cars={cars}
              type={type}
              vehicle_id={this.state.vehicle_id}
              kms={this.state.combustibles}
              warehouses={this.props.warehouses}
              tiresList={this.state.tiresList}
              firebaseUser={this.props.firebaseUser}
              authUser={authUser}
              tireCode={tire_code}
              customerId={customerId}
              getTires={getTires}
              onSubmitTire={this.onSubmit}
            /> : null
        }
        {
          !!result ?
            <div className="row">
              <div className="col-sm-12 text-center v-scroll mt-3">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">{this.props.t("ins-one.ins-one-tire-26")}</h6>
                    <form onSubmit={(e) => this.changeWarehouse(e)}>
                      <div>
                        <select className="form-control" name="warehouse_id" value={warehouse_id} onChange={(e) => this.setState({warehouse_id: e.target.value})} required>
                          <option value={null}>{this.props.t("ins-one.ins-one-tire-27")}</option>
                          {this.props.warehouses.map((warehouse, key) => {
                            if (warehouse.name !== 'llanta_nueva' && warehouse.name !== 'descarte') {
                              return (
                                <option key={key} value={warehouse.id}>{warehouse.name}</option>
                              )
                            }
                          })}
                        </select>
                      </div>
                        <div className="mt-2">
                          <input className="form-control"
                            placeholder={this.props.t("client-vehicles.client-item-6")}
                            id="workDate"
                            name="work-date"
                            type="datetime-local"
                            value={work_date}
                            max={moment().format("YYYY-MM-DDTHH:mm")}
                            onChange={(e) => this.setState({work_date: e.target.value})}
                            required />
                        </div>
                        <div className="input-group-append mt-2 justify-content-end">
                          <button className="btn btn-primary text-white" type="submit"><i className="fa fa-save"></i> {this.props.t("ins-one.ins-one-tire-28")}</button>
                        </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <div className="">
                  <Link to="/works/multiple">
                    <button onClick={() => localStorage.setItem('is_tire_massive', "si")} className="btn btn-primary text-white m-3" type="button">{this.props.t("ins-one.ins-one-tire-29")}</button>
                  </Link>
                </div>

              </div>

              <div className="col-sm-12 text-center v-scroll mt-3">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">{this.props.t("ins-one.ins-one-tire-30")}</h6>
                    {this.getTableResult(result)}
                  </div>
                </div>
              </div>
            </div> : null
        }
      </div>
    );
  }
}

export default TireInfo;
