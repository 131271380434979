import i18n from "i18next";
import LanguegeDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import global_es from "./es/global.json";
import global_br from "./pt/global.json";

i18n
  .use(LanguegeDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      es: {
        global: global_es,
      },
      pt: {
        global: global_br,
      },
    },
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
