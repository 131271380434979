import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsTireCatalogue from "../../../constants/actions/TireCatalogues";
import * as APIS from "../../../constants/apis";

function getTireCatalogue(id) {
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_TIRECATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${id}`, {
      headers:{
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      } })
      .then(response => response.json())
      .then(json => {dispatch(getTireCatalogueSuccess({
        catalogue : json
      })); })
      .catch(error => {
        toast.success(error.message);
      }));
  };
};

export function getTireCatalogueSuccess(json) {
  return {
    type: constantsTireCatalogue.GET_TICATALOGUE_SUCCESS,
    json
  };
};

class TireCatalogueItem extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getTireCatalogue(id);
  };

  render() {
    const { catalogue } = this.props;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!catalogue ?
          <div className="container-fluid mt-100">
            <h3>{this.props.t("tire-catalogue.tire-info")}</h3>
            <br />
            <div className="text-center v-scroll">
              <table className="table table-striped table-bordered table-sm mb-0">
                <tbody>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-1")}
                    </th>
                    <td className="w-50">
                      {catalogue.marca}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-2")}
                    </th>
                    <td className="w-50">
                      {catalogue.modelo}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-3")}
                    </th>
                    <td className="w-50">
                      {catalogue.dimention}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-6")}
                    </th>
                    <td className="w-50">
                      {catalogue.prof_original}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-7")}
                    </th>
                    <td className="w-50">
                      {catalogue.prof_minima}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-10")}
                    </th>
                    <td className="w-50">
                      {catalogue.precio}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-11")}
                    </th>
                    <td className="w-50">
                      {catalogue.distribuidor}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-8")}
                    </th>
                    <td className="w-50">
                      {catalogue.presion_minima}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-9")}
                    </th>
                    <td className="w-50">
                      {catalogue.presion_maxima}
                    </td>
                  </tr>
                  <tr>
                    <th className="w-50">
                    {this.props.t("tire-catalogue.tire-item-12")}
                    </th>
                    <td className="w-50">
                      {catalogue.costo_milimetraje}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  catalogue : state => state.catalogue
});

const mapDispatchToProps = { getTireCatalogue };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(TireCatalogueItem));
