import React from "react";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import Tires from "./Tires";
import * as APIS from "../../constants/apis";

class RetreadBrandTires extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      tires: []
    };
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.getTires(id);
  };

  getTires = (id) => {
    return trackPromise(fetch(`${APIS.API_REST_RETREADBRANDS_TIRES
      .replace("customer_id", localStorage.getItem("GlobalCustomerId"))
      .replace("retreadbrand_id", id)}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        this.setState({ tires: json.tires_by_retreadbrand});
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };

  render() {
    const { tires } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("tire-retread.tire-retread-text")}</h3>
              </div>
              <div className="col-md-4">
              </div>
              <div className="col-md-12">
                <Tires tires={tires} t={this.props.t}/>
              </div>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const condition = authUser =>
  !!authUser

export default withAuthorization(condition)(RetreadBrandTires);
