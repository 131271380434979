import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as APIS from "../../constants/apis";

const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

class InvoicesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { invoices: [] };
  };

  componentDidMount() {
    this.getInvoices(this.props.match.params.id);
  };

  getInvoices = (id) => {
    return trackPromise(fetch(`${APIS.API_REST_INVOICES.replace("customer_id", id)}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(this.props.t("alerts.invoice-item-1"))
        }
        return response.json();
      })
      .then(json => {
        this.setState({ invoices: json.tires });
      })
      .catch(error => {
        throw new Error(this.props.t("alerts.invoice-item-2"))
      }))
  };

  deleteInvoice = (id) => {
    return trackPromise(fetch(`${APIS.API_REST_INVOICES.replace("customer_id", this.props.match.params.id)}/${id}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "DELETE"
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(this.props.t("alerts.invoice-item-3"));
        }
        return response.json();
      })
      .then(json => {
        toast.success(this.props.t("alerts.invoice-item-7"))
        this.setState({ invoices: this.state.invoices.filter(invoice => invoice.id != id) });
      })
      .catch(error => {
        throw new Error(this.props.t("alerts.invoice-item-4"))
      }))
  };

  validateIfNameisDate = (name) => {
    const date = new Date(name)
    if (date instanceof Date) {
      if (date.getMonth() && date.getFullYear) {
        return (this.props.t("invoice.inv-item-20")) + " " + months[date.getMonth()] + " " + date.getFullYear()
      } else {
        return name
      }
    }
  }

  validateifObIsDate = (observacion) => {
    const date = new Date(observacion)
    if (date instanceof Date) {
      if (date.getMonth()) {
        return (this.props.t("invoice.inv-item-21")) + " " + months[date.getMonth()]
      } else {
        return observacion
      }
    }
  }


  getInvoicesList = (invoices, authUser) => {
    if (!invoices) return null;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("invoice.inv-item-1")}</th>
              <th scope="col"># {this.props.t("invoice.inv-item-2")}</th>
              <th scope="col"># {this.props.t("invoice.inv-item-3")}</th>
              <th scope="col">{this.props.t("invoice.inv-item-4")}</th>
              <th scope="col">{this.props.t("invoice.inv-item-5")}</th>
              <th scope="col">{this.props.t("invoice.inv-item-6")}</th>
            </tr>
          </thead>
          <tbody>
            {
              invoices.map(invoice => {

                return <tr key={invoice.id}>
                  <td>
                    {
                      this.validateIfNameisDate(invoice.name)
                    }
                  </td>
                  <td>
                    {invoice.vehicle_count}
                  </td>
                  <td>
                    {invoice.total_tires}
                  </td>
                  <td>
                    {!!invoice.creation_date ? moment.utc(invoice.creation_date).local().locale('es').format('LLL') : ''}
                  </td>
                  <td>
                    {
                      this.validateifObIsDate(invoice.observacion)
                    }
                  </td>
                  <td>
                    {!!authUser.permissions.delete ?
                      <Link to={"/invoices/" + this.props.match.params.id + "/detail/" + invoice.id} className="btn btn-primary">
                        <i className="fa fa-eye"></i> {this.props.t("invoice.inv-item-7")}
                      </Link> : null}
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("invoice.inv-item-8")}</h3>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                {!!authUser.permissions.create ?
                  <Link to={"/invoices/" + this.props.match.params.id + "/new"}
                    className="btn btn-primary">
                    <i className="fa fa-plus"></i> {this.props.t("globals.register")}
                  </Link>
                  : null}
              </div>
              <div className="col-md-6">
                <p className="pull-right">{this.props.t("invoice.inv-item-9")} <b>{!!this.state.invoices ? this.state.invoices.length : 0}</b></p>
              </div>
            </div>
            <br></br>
            {this.getInvoicesList(this.state.invoices, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const condition = authUser =>
  !!authUser

export default withAuthorization(condition)(InvoicesList);
