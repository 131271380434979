import React from "react";
import { withFirebase } from "../Firebase";

const SignOutButton = ({ firebase, t }) => (
  <a href="" className="dropdown-item" onClick={firebase.doSignOut}>
    {t("navigation.logout-text")}
  </a>
);

export default withFirebase(SignOutButton);
