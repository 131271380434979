import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as constantsBrand from "../../../constants/actions/RetreadBrands";
import * as constantsModel from "../../../constants/actions/RetreadModels";
import * as APIS from "../../../constants/apis";

import RetreadDimensionList from "./RetreadDimensions/RetreadDimensionsList";

function getModel(brandId, modelId) {
  let band = {};
  let dimensions = [];
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_RETREADMODELS.replace("retreadbrand_id", brandId)}/${modelId}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        band = json;
        return fetch(APIS.API_REST_RETREADDIMENSIONS.replace("retreadband_id", band.id), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        dimensions = json.RetreadDimentions;
        dispatch(onModelSuccess({
          band: band,
          dimensions: dimensions
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  }
}

export function onModelSuccess(json) {
  return {
    type: constantsModel.GET_MODEL_SUCCESS,
    json
  }
}

export function onReqSuccess(bands, band, message) {
  const json = {
    bands: bands,
    band: band,
    message: message
  };
  return {
    type: constantsBrand.REQUEST_SUCCESS,
    json
  };
};

class RetreadDimensionEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      retreadbrand_id: this.props.match.params.brandid,
      customer_id: localStorage.getItem('GlobalCustomerId')
    };
  };

  componentDidMount() {
    const modelId = this.props.match.params.id;
    const brandId = this.props.match.params.brandid;
    this.props.getModel(brandId, modelId);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.band || (this.props.band !== prevProps.band)) {
      this.setState({
        name: this.props.band.name
      });
    };
  };

  onSubmit = event => {
    event.preventDefault();
    const { onReqSuccess, bands } = this.props;

    const id = this.props.match.params.id;
    const data = {
      ...this.state,
    };

    const brandId = this.props.match.params.brandid;
    return trackPromise(fetch(`${APIS.API_REST_RETREADMODELS
      .replace("retreadbrand_id", brandId)}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "PUT",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          window.location.reload(false);
          onReqSuccess(bands, this.props.t("alerts.r-tire-item-9"));
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  render() {
    const { band, dimensions, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!band ?
            <div className="container-fluid mt-100">

              <h3>{ authUser.roles != "ANALYST" ? this.props.t("tire-retread.tire-retread-edit") : this.props.t("tire-retread.tire-retread-edit-see")}</h3>
              <br /><br />
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="nameInput">{this.props.t("tire-brands.tire-item-1")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-brands.tire-item-1")}
                        id="nameInput"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        required />
                      <input type="hidden"
                        name="customer_id"
                        value={this.state.customer_id} />
                    </div>
                  </div>
                </div>
                {
                  authUser.roles != "ANALYST" ?
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button type="submit"
                        className="btn btn-primary">
                        <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                    </button>
                    </div>
                  </div>
                  :
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button type="button"
                        onClick={() => window.history.back()}
                        className="btn btn-primary">
                        <i className="fa fa-arrow-left"></i> {this.props.t("globals.back")}
                    </button>
                    </div>
                  </div>
                }
              </form>
              <br />
              <hr />
              <RetreadDimensionList
              authUser={authUser}
              t={this.props.t}
                band={band}
                dimensions={dimensions}
              />
            </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  bands: state => state.bands,
  band: state => state.band,
  dimensions: state => state.dimensions,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getModel, onReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(RetreadDimensionEditItem));
