import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import * as constantsVehicle from "../../../constants/actions/Vehicles";

const initialState = {
  previus     : "",
  next        : "",
  total       : "",
  vehicles    : [],
  tires       : [],
  brands      : [],
  lines       : [],
  types       : [],
  schemas     : [],
  cities      : [],
  costcenters : [],
  warehouses  : [],
  tbds        : [],
  rbds        : [],
  tctgs       : [],
  rctgs       : [],
  vehicle     : {},
  tire        : {},
  message     : null,
  error       : null
};

function rootReducer(state, action) {
  switch(action.type) {
    case constantsVehicle.GET_VEHICLES_SUCCESS:
      return {
        previus   : action.json.previus,
        next      : action.json.next,
        total     : action.json.total,
        vehicles  : action.json.vehicles,
        tires     : action.json.tires,
        vehicle   : {},
        error     : null
      };
    case constantsVehicle.GET_VEHICLE_SUCCESS:
      return {
        vehicle  : action.json.vehicle,
        vehicles : action.json.vehicles,
        tires    : action.json.tires,
        tire     : action.json.tire,
        tbds     : action.json.tbds,
        rbds     : action.json.rbds,
        tctgs    : action.json.tctgs,
        rctgs    : action.json.rctgs,
        warehouses : action.json.warehouses
      };
    case constantsVehicle.GET_LISTS_SUCCESS:
      return {
        vehicle     : action.json.vehicle,
        brands      : action.json.brands,
        lines       : action.json.lines,
        groups      : action.json.groups,
        types       : action.json.types,
        schemas     : action.json.schemas,
        cities      : action.json.cities,
        costcenters : action.json.costcenters
      };
    case constantsVehicle.GET_REQUEST_SUCCESS:
      return {
        vehicles  : action.json.vehicles,
        vehicle   : action.json.vehicle,
        tires     : action.json.tires,
        message   : action.json.message
      };
    case constantsVehicle.GET_REQUEST_ERROR:
      return {
        previus   : action.json.previus,
        next      : action.json.next,
        total     : action.json.total,
        vehicles  : action.json.vehicles,
        vehicle   : action.json.vehicle,
        tires     : action.json.tires,
        error     : action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
