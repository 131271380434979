import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as APIS from "../../../constants/apis";
import * as VARIABLES from "../../../constants/variables";
import Autosuggest from "react-autosuggest";

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

class WorkNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: localStorage.getItem("GlobalCustomerId"),
      work_date: null,
      worktype: null,
      cost: null,
      warehouse_id: null,
      warehouses: [],
      tire_id: null,
      order: "",
      observacion: "",
      technicians: [],
      technical: "",
      suggestionsTnc: [],
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ tire_id: id });
    this.getWarehouses();
    this.getTnc();
  }

  getTnc = () => {
    trackPromise(
      fetch(
        APIS.API_REST_TECHNICIANS.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ technicians: json.Users });
        })
    );
  };

  getWarehouses = () => {
    return trackPromise(
      fetch(
        `${APIS.API_REST_WAREHOUSES.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        )}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-6"));
          }
          return response.json();
        })
        .then((json) => {
          this.setState({ warehouses: json.bodegas });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.work-tire-item-6"));
        })
    );
  };

  onSubmitWork(event, history) {
    event.preventDefault();

    var data = {
      ...this.state,
    };
    delete data.technicians;
    delete data.suggestionsTnc;
    if (data.worktype !== "bodegas") {
      delete data.warehouse_id;
    }
    data.work_date = moment(data.work_date).utc().format();

    trackPromise(
      fetch(
        `${APIS.API_REST_TIRE_WORK.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("tire_id", this.state.tire_id)}`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          const status = json.message;
          if (!status) {
            toast.success(this.props.t("alerts.work-tire-item-7"));
            this.props.history.push("/works/tires/" + this.state.tire_id);
          } else {
            throw new Error(status);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getSuggestionsTnc = (value) => {
    let tnc = this.state.technicians;
    let reTnc = tnc.filter((item) => {
      const tncValue = escapeRegexCharacters(value.toLowerCase().trim());
      return (
        (item.name.toLowerCase().search(tncValue) >= 0 && item.active) ||
        (item.email.toLowerCase().search(tncValue) >= 0 && item.active)
      );
    });
    return reTnc;
  };

  onChangeTnc = (event, { newValue }) => {
    this.setState({
      technical: newValue,
    });
  };

  onSuggestionsFetchRequestedTnc = ({ value }) => {
    this.setState({
      suggestionsTnc: this.getSuggestionsTnc(value),
    });
  };

  onSuggestionsClearRequestedTnc = () => {
    this.setState({
      suggestionsTnc: [],
    });
  };

  getSuggestionValueTnc = (suggestion) => suggestion.name;

  renderSuggestionTnc = (suggestion) => (
    <div>
      <p>
        {suggestion.name}: {suggestion.email}
      </p>
    </div>
  );

  render() {
    const { history } = this.props;

    const inputProps = {
      placeholder: this.props.t("alerts.ins-one-item-14"),
      value: this.state.technical,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "100%",
        marginBottom: 0,
      },
      onChange: this.onChangeTnc,
    };

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("work-tire.client-item-12")}</h3>
            <br />
            <br />
            <form onSubmit={(event) => this.onSubmitWork(event, history)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="codigoInput">
                      {this.props.t("client-tires.client-item-27")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder="#"
                      id="orderInput"
                      name="order"
                      value={this.state.order}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="codigoInput">
                      {this.props.t("client-vehicles.client-item-7")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t(
                        "client-vehicles.client-observation"
                      )}
                      id="observacionInput"
                      name="observacion"
                      value={this.state.observacion}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="worktypeInput">
                      {this.props.t("work-tire.client-item-1")}
                    </label>
                    <br></br>
                    <i className="text-info small">
                      {this.props.t("globals.required")}
                    </i>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      id="worktypeInput"
                      name="worktype"
                      value={this.state.worktype}
                      onChange={this.onChange}
                      required
                    >
                      <option value="">
                        {this.props.t("work-tire.client-item-10")}
                      </option>
                      {/* <option value="Bodegas">Bodegas</option> */}
                      {VARIABLES.TYPES5.slice(
                        0,
                        localStorage.getItem("GlobalCustomerId") === "650"
                          ? 5
                          : 3
                      ).map((type) => {
                        return (
                          <option key={type.value} value={type.value}>
                            {this.props.t(`selectors.${type.name}`)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {this.state.worktype === "bodegas" ? (
                <div className="row form-group">
                  <div className="col-sm-2">
                    <label htmlFor="warehouseInput">
                      {this.props.t("work-tire.client-item-11")}
                    </label>
                  </div>
                  <div className="col-sm-10 input-group">
                    <select
                      className="form-control"
                      placeholder={this.props.t("work-tire.client-item-11")}
                      id="warehouseInput"
                      name="warehouse_id"
                      value={this.state.warehouse_id}
                      onChange={this.onChange}
                      required
                    >
                      <option value="">
                        {this.props.t("work-tire.client-item-9")}
                      </option>
                      {!!this.state.warehouses &&
                        this.state.warehouses.map((warehouse) => {
                          return (
                            <option key={warehouse.id} value={warehouse.id}>
                              {warehouse.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              ) : null}
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="costInput">
                      {this.props.t("work-tire.client-item-2")}
                    </label>
                    <br></br>
                    <i className="text-info small">
                      {this.props.t("globals.required")}
                    </i>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("work-tire.client-item-2")}
                      id="costInput"
                      name="cost"
                      value={this.state.cost}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-2">
                  <label htmlFor="inputCarTechnician">
                    {this.props.t("ins-one.ins-one-31")}
                  </label>
                </div>
                <div className="col-sm-10">
                  <Autosuggest
                    suggestions={this.state.suggestionsTnc}
                    onSuggestionsFetchRequested={
                      this.onSuggestionsFetchRequestedTnc
                    }
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequestedTnc
                    }
                    shouldRenderSuggestions={() => true}
                    getSuggestionValue={this.getSuggestionValueTnc}
                    renderSuggestion={this.renderSuggestionTnc}
                    inputProps={inputProps}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="workDate">
                      {this.props.t("work-tire.client-item-3")}
                    </label>
                    <br></br>
                    <i className="text-info small">
                      {this.props.t("globals.required")}
                    </i>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("work-tire.client-item-3")}
                      id="workDate"
                      name="work_date"
                      value={this.state.work_date}
                      type="datetime-local"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    <i className="fa fa-save"></i>{" "}
                    {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(WorkNewItem);
