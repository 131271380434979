import Q from "q";
import resizer from "iframe-resizer";
import React, { Component } from "react";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import * as APIS from "../../constants/apis";
import * as VALIDATIONS from "../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;

const INITIAL_STATE = {
  iframeUrls: [],
  iframeUrls2: [],
  iframeUrls3: [],
  cReport: "",
  cReport2: "",
  cReport3: "",
};

class DashboardReportList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };
  };

  async componentDidMount() {
    let responseDashboards = await fetch(`${APIS.API_REST_DASHBOARDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    });
    let jsonDashboards = await responseDashboards.json();
    let dashboards = jsonDashboards.dashboards.filter(dashboard => dashboard.tipo === "reporte")
    let dashboards2 = jsonDashboards.dashboards.filter(dashboard => dashboard.tipo === "historico")
    let dashboards3 = jsonDashboards.dashboards.filter(dashboard => dashboard.tipo === "consulta")
    let str = []
    dashboards.forEach(item => {
      str.push(item.codigo + "|" + item.name)
    })
    let str2 = []
    dashboards2.forEach(item => {
      str2.push(item.codigo + "|" + item.name)
    })
    let str3 = []
    dashboards3.forEach(item => {
      str3.push(item.codigo + "|" + item.name)
    })
    trackPromise(this.getAllIframes(str, str2, str3)
      .then(() => {
        toast.success(this.props.t("alerts.dash-item-4"));
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.dash-item-5");
        }
        toast.error(err.toString());
      }));
  };

  getIframe = (dashboard, type) => {
    let deferred = Q.defer();

    trackPromise(fetch(`${APIS.API_REST_REPORT_URL}/${dashboard.split("|")[0]}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Unreachable endpoint");
        }
        return response.json();
      })
      .then(json => {
        if (!!json.iframe_ur) {
          let iframeUrls;
          switch (type) {
            case 1:
              iframeUrls = [...this.state.iframeUrls];
              break;
            case 2:
              iframeUrls = [...this.state.iframeUrls2];
              break;
            case 3:
              iframeUrls = [...this.state.iframeUrls3];
              break;
          }
          let url = {
            url: json.iframe_ur,
            name: !!dashboard.split("|")[1] ?
              dashboard.split("|")[1] : "Reporte " + (iframeUrls.length + 1),
            id: "report" + iframeUrls.length
          };
          iframeUrls.push(url);
          switch (type) {
            case 1:
              this.setState({
                iframeUrls: iframeUrls,
                cReport: !!iframeUrls && iframeUrls.length > 0 ? iframeUrls[0] : ""
              });
              break;
            case 2:
              this.setState({
                iframeUrls2: iframeUrls,
                cReport2: !!iframeUrls && iframeUrls.length > 0 ? iframeUrls[0] : ""
              });
              break;
            case 3:
              this.setState({
                iframeUrls3: iframeUrls,
                cReport3: !!iframeUrls && iframeUrls.length > 0 ? iframeUrls[0] : ""
              });
              break;
          }
          deferred.resolve(iframeUrls);
        } else {
          throw new Error(this.props.t("alerts.dash-item-6"));
        }
      })
      .catch(error => {
        deferred.reject(error);
      }));

    return deferred.promise;
  };

  getAllIframes = (dashboards, dashboards2, dashboards3) => {
    let promises = [];

    if (!!dashboards2) {
      for (let i = 0; i < dashboards2.length; i++) {
        if (!!dashboards2[i])
          promises.push(this.getIframe(dashboards2[i], 2))
      }
    }
    if (!!dashboards3) {
      for (let i = 0; i < dashboards2.length; i++) {
        if (!!dashboards3[i])
          promises.push(this.getIframe(dashboards3[i], 3))
      }
    }
    for (let i = 0; i < dashboards.length; i++) {
      if (!!dashboards[i])
        promises.push(this.getIframe(dashboards[i], 1))
    }

    return Q.all(promises);
  };

  onChangeReport = (iframeUrl, type) => {
    switch (type) {
      case 1: {
        this.setState({
          cReport: iframeUrl
        });
        break;
      }
      case 2: {
        this.setState({
          cReport2: iframeUrl
        });
        break;
      }
      case 3: {
        this.setState({
          cReport3: iframeUrl
        });
        break;
      }
    }
  };

  resizeIframe(reportId) {
    document.getElementById(reportId).style.height = "500px";
    resizer.iframeResize({ log: false }, "#" + reportId)
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#home">{this.props.t("dashboard.dash-item-6")}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#menu1">{this.props.t("dashboard.dash-item-7")}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#menu2">{this.props.t("dashboard.dash-item-8")}</a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="home">
                <div className="row">
                  <div className="col-md-12">
                    <br />
                    <br />
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        {this.props.t("dashboard.dash-item-9")}
                      </button>
                      <div className="dropdown-menu">
                        {
                          this.state.iframeUrls && this.state.iframeUrls.map((iframeUrl, idx) => {
                            return <button
                              key={idx}
                              className="dropdown-item"
                              onClick={() => this.onChangeReport(iframeUrl, 1)}>
                              {iframeUrl.name}
                            </button>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <h3>{!!this.state.cReport ? this.state.cReport.name : null}</h3>
                    {
                      !!this.state.cReport ?
                        <iframe
                          id={this.state.cReport.id}
                          title={this.state.cReport.name}
                          src={this.state.cReport.url}
                          frameBorder={0}
                          scrolling="no"
                          onLoad={() => this.resizeIframe(this.state.cReport.id, 3)}
                        />
                        : null
                    }
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="menu1">
                <div className="row">
                  <div className="col-md-12">
                    <br />
                    <br />
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        {this.props.t("dashboard.dash-item-10")}
                      </button>
                      <div className="dropdown-menu">
                        {
                          this.state.iframeUrls2 && this.state.iframeUrls2.map((iframeUrl, idx) => {
                            return <button
                              key={idx}
                              className="dropdown-item"
                              onClick={() => this.onChangeReport(iframeUrl, 2)}>
                              {iframeUrl.name}
                            </button>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <h3>{!!this.state.cReport2 ? this.state.cReport2.name : null}</h3>
                    {
                      !!this.state.cReport2 ?
                        <iframe
                          id={this.state.cReport2.id}
                          title={this.state.cReport2.name}
                          src={this.state.cReport2.url}
                          frameBorder={0}
                          scrolling="no"
                          onLoad={() => this.resizeIframe(this.state.cReport2.id, 3)}
                        />
                        : null
                    }
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="menu2">
                <div className="row">
                  <div className="col-md-12">
                    <br />
                    <br />
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        {this.props.t("dashboard.dash-item-11")}
                      </button>
                      <div className="dropdown-menu">
                        {
                          this.state.iframeUrls3 && this.state.iframeUrls3.map((iframeUrl, idx) => {
                            return <button
                              key={idx}
                              className="dropdown-item"
                              onClick={() => this.onChangeReport(iframeUrl, 3)}>
                              {iframeUrl.name}
                            </button>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-md-12">
                    <h3>{!!this.state.cReport3 ? this.state.cReport3.name : null}</h3>
                    {
                      !!this.state.cReport3 ?
                        <iframe
                          id={this.state.cReport3.id}
                          title={this.state.cReport3.name}
                          src={this.state.cReport3.url}
                          frameBorder={0}
                          scrolling="no"
                          onLoad={() => this.resizeIframe(this.state.cReport3.id, 3)}
                        />
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
};

const condition = authUser =>
  !!authUser

export default withAuthorization(condition)(DashboardReportList);
