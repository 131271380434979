import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../../constants/routes";
import { useTranslation } from "react-i18next";

const PasswordForgetPage = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div style={{ background: "#f2f2f2", height: "100vh" }}>
      <div className="signin-content">
        <div className="shadow text-center p-5 mb-5 bg-body rounded signin-body">
          <div className="forget-body">
            <Link to={ROUTES.SIGN_IN}>
              <i class="fas fa-arrow-left"></i>
            </Link>
            <p>v1.62</p>
          </div>
          <h3 className="mb-5">{t("auth.recovery-password")}</h3>
          <PasswordForgetForm t={t} />
        </div>
      </div>
    </div>
  );
};

const INITIAL_STATE = {
  email: "",
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        toast.error(error.message);
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    let email = this.state.email;
    const isInvalid = email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <label htmlFor="inputEmail" className="signin-label">
            {this.props.t("auth.email")}
          </label>
          <input
            name="email"
            type="email"
            id="inputEmail"
            className="form-control"
            value={this.state.email}
            onChange={this.onChange}
            placeholder={this.props.t("auth.email")}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary signin-submit"
          disabled={isInvalid}
        >
          {this.props.t("auth.btn-recovery-password")}
        </button>
      </form>
    );
  }
}

const PasswordForgetLink = ({ t }) => {
  return (
    <p className="ml-3">
      <Link to={ROUTES.PASSWORD_FORGET}>{t("auth.forgot-password")}</Link>
    </p>
  );
};

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
