import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/bootstrap.min.css";
import "./styles/styles.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@fortawesome/fontawesome-free/js/all.min.js";
import "datatables.net/js/jquery.dataTables.min.js";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "awesomplete/awesomplete.css";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";

import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { usePromiseTracker } from "react-promise-tracker";

import * as serviceWorker from "./serviceWorker";
import Firebase, { FirebaseContext } from "./components/Auth/Firebase";

import App from "./components/App";
import Loader from "./components/Loader";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import { generateStore } from "./store";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./translations/index";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const store = generateStore();

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress && <Loader />;
};

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <ToastContainer />
        <LoadingIndicator />
        <App />
      </I18nextProvider>
    </Provider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
