import React from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsBrand from "../../constants/actions/Brands";
import * as APIS from "../../constants/apis";

function onSubmitBrand(event, state, brands, brand, history, t) {
  event.preventDefault();

  return dispatch => {
    const data = {
      ...state,
      customer_id: localStorage.getItem("GlobalCustomerId"),
    };
    if (!localStorage.getItem("GlobalCustomerId")) return null;
    trackPromise(fetch(`${APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.status !== 201) {
          throw new Error(t("alerts.brands-item-2"));
        }
        return response.json();
      })
      .then(json => {
        const status = json.message;
        if (!status) {
          dispatch({ type: constantsBrand.GET_BRANDS_REQUEST });
          return fetch(APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
            .then(response => response.json())
            .then(json => {
              history.push("/brands");
              dispatch(getReqSuccess(brands, brand, "Marca Creada"));
              dispatch(getBrandsSuccess(json));
            })
            .catch(error => {
              history.push("/brands");
              dispatch(getReqError(brands, brand, error.toString()));
            });
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

export function getBrandsSuccess(json) {
  return {
    type: constantsBrand.GET_BRANDS_SUCCESS,
    json
  };
};

export function getReqSuccess(brands, brand, message) {
  const json = {
    brands: brands,
    brand: brand,
    message: message
  };
  return {
    type: constantsBrand.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(brands, brand, error) {
  const json = {
    brands: brands,
    brand: brand,
    error: error
  };
  return {
    type: constantsBrand.GET_REQUEST_ERROR,
    json
  };
};

class BrandNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // code: ""
    };
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name, code } = this.state;
    const { brand, brands, history } = this.props;

    $("input#nameInput").on({
      keydown: function (e) {
        if (e.which === 32)
          return false;
      },
      change: function () {
        this.value = this.value.replace(/\s/g, "");
      }
    });
    $("input#codeInput").on({
      keydown: function (e) {
        if (e.which === 32)
          return false;
      },
      change: function () {
        this.value = this.value.replace(/\s/g, "");
      }
    });

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("brands.brands-register")}</h3>
            <br /><br />
            <form onSubmit={(event) => this.props.onSubmitBrand(event,
              this.state, brands, brand, history, this.props.t)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">{this.props.t("brands.brands-item-1")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("brands.brands-item-1")}
                      id="nameInput"
                      name="name"
                      value={name}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                    className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  brands: state => state.brands,
  brand: state => state.brand
});

const mapDispatchToProps = { onSubmitBrand };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(BrandNewItem));
