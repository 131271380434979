import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsTireBrand from "../../constants/actions/TireBrands";
import * as APIS from "../../constants/apis";

function deleteTireBrand(id, brands, t) {
  if (window.confirm(t("alerts.r-tire-item-3"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_TIREBRANDS}/${id}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "DELETE"
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(t("alerts.r-tire-item-4"));
          }
          return response.json();
        })
        .then(json => {
          dispatch(onReqSuccess(brands, json.message));
          return fetch(APIS.API_REST_TIREBRANDS, {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
            .then(response => response.json())
            .then(json => {
              dispatch(onTireBrandsSuccess({
                brands: json.Tirebrands
              }));
            })
            .catch(error => {
              dispatch(onReqError(brands, error.message.toString()));
            });
        })
        .catch(error => {
          dispatch(onReqError(brands, error.message.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(onTireBrandsSuccess({
        brands: brands
      }));
    };
  }
};

export function onTireBrandsSuccess(data) {
  const json = {
    brands: data.brands
  };
  return {
    type: constantsTireBrand.GET_BRANDS_SUCCESS,
    json
  };
};

export function onReqSuccess(brands, message) {
  const json = {
    brands: brands,
    message: message
  };
  return {
    type: constantsTireBrand.REQUEST_SUCCESS,
    json
  };
};

export function onReqError(brands, error) {
  const json = {
    brands: brands,
    error: error
  };
  return {
    type: constantsTireBrand.REQUEST_ERROR,
    json
  };
};

class TireBrandDeleteItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { brands, brand, brandDelete, deleteTireBrand } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteTireBrand(brandDelete.id,
          brands, brand, this.props.t)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({
  brands: state => state.brands,
  brand: state => state.brand
});

const mapDispatchToProps = { deleteTireBrand };

export default connect(structuredSelector, mapDispatchToProps)(TireBrandDeleteItem);
