import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthUserContext, withAuthorization } from '../../Auth/Session';
import MacroActions from './Actions';
import * as ROUTES from '../../../constants/routes'
import * as APIS from '../../../constants/apis'
import Autosuggest from 'react-autosuggest';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import Loader2 from '../../Loader/Loader2';

const escapeRegexCharacters = str => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};


const INITIAL_STATE = {
    isLoading: false,
    isCreate: true,
    search: "",
    dataSearch: "",

    // VIEWS
    showModalUsers: false,
    showModalUsersErrors: false,
    showModalFleets: false,
    showModalFleetsErrors: false,


    addFleets: null,
    addUsers: null,
    // VIEWS

    
    // USERS
    userSelectedId: null,
    userSelected: '',
    rolSelected: '',
    users: null,
    suggestionUsers: [],
    itemsUsersAddList: [],


    usersPerMacro: [],
    usersPerMacroErrors: [],
    // USERS

    // Fleets
    fleetSelectedId: null,
    fleetSelected: '',
    fleets: null,
    suggestionFleets: [],
    itemsFleetsAddList: [],

    fleetsPerMacro: [],
    fleetsPerMacroErrors: [],
    // Fleets

    // Macros
    macroData: [], 
    macroSelectedId: null,
    // Macros

}

class MacroAcocunt extends Component {
    constructor(props) {
        super(props);
            this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        this.getInitData()
    }

    getInitData = async () => {
        let users = [];
        let fleets = [];
        let macros = [];
        return trackPromise(fetch(`${APIS.API_REST_USERS}`, {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
          .then(response => response.json())
          .then(json => {
            users = json.Users;
            this.setState({ users: users });
            return fetch(`${APIS.API_REST_CUSTOMERS}`, {
                headers:{
                  "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                  "Accept-language": localStorage.getItem("LanguageSelected"),
                } })
                .then(response => response.json())
                .then(json => {
                  fleets = json.Customers;
                  this.setState({ fleets: fleets });
                  return fetch(`${APIS.API_REST_MACRO_ACCOUNT}`, {
                    headers:{
                      "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                      "Accept-language": localStorage.getItem("LanguageSelected"),
                    } })
                    .then(response => response.json())
                    .then(json => {
                      macros = json.macro_accounts;
                      this.setState({ macroData: macros });
                    })
                })
          })
          .catch(err => {
              toast.error(err.message)
          }));
    }

    updateMacro = () => {
        let macros;
        trackPromise(fetch(`${APIS.API_REST_MACRO_ACCOUNT}`, {
            headers:{
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            } })
            .then(response => response.json())
            .then(json => {
              macros = json.macro_accounts;
              this.setState({macroData: macros})
            })
            .catch(err => {
                toast.error(err.message)
        }))
    }

    // ================== USERS ===================

    onSubmitUsers = () => {
        let data = {
            macro_account: this.state.itemsUsersAddList
        }

        trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_POST_USERS_MACRO, {
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            },
            method: "POST",
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
            if(json.create_error) {
                this.setState({ showModalUsersErrors: true, usersPerMacroErrors:  json.create_error})
                throw new Error(json.message)
            }

            toast.success(json.message)
            this.setState({ itemsUsersAddList: [], usersPerMacroErrors: [], addUsers: null, showModalUsers: false})
            this.updateMacro()
        })
        .catch(err => {
            toast.error(err.message)
        }))
    }

    getUsersPerMacro = (macroId) => {
        let str = `?type_account=mc_account`
        trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_GET_USERS_MACRO.replace("macro_id", macroId) + str, {
            headers:{
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            }
        })
        .then(response => response.json())
        .then(json => {
            this.setState({ usersPerMacro: json.user_macro_accounts })

        })
        .catch(err => {
            toast.error(err.message)
        }))
    }

    deleteUserList = (id) => {
        this.setState({ itemsUsersAddList: this.state.itemsUsersAddList.filter(list => list.user_id != id) })
    }

    deleteUserPerMacro = (id, macroName, macroUser, userId) => {
        if(window.confirm(this.props.t("macros.m-item-1") + " " + macroUser + " " + this.props.t("macros.m-item-2") + macroName)) {
            trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_DELETE_USERS_PER_MACRO.replace("macro_id", id), {
                headers:{
                    "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                    "Accept-language": localStorage.getItem("LanguageSelected"),
                },
                method: "DELETE"
            })
            .then(response => {
                if(response.status != 200) {
                    throw new Error(this.props.t("macros.m-item-3"))
                }
                return response.json()
            })
            .then(json => {
                toast.success(json.message)
                this.setState({ usersPerMacro: this.state.usersPerMacro.filter(list => list.user_id != userId) })
                this.updateMacro()
            })
            .catch(err => {
                toast.error(err.message)
            }))
        }
    }

    addUsersList = (e) => {
        e.preventDefault();
        if (this.state.userSelected != "") {
            if(this.validateAddUsersList(this.state.userSelectedId) === null) {
                const data = {
                    user_id: this.state.userSelectedId,
                    name: this.state.userSelected,
                    macro_account_id: this.state.macroSelectedId,
                }
                this.state.itemsUsersAddList.push(data)
                this.setState({userSelected: ''})
            } else {
                toast.error(this.props.t("macros.m-item-4"))
                this.setState({userSelected: ''})
            }
        } else {
            toast.error(this.props.t("macros.m-item-5"))
        }
    }

    validateAddUsersList = (id) => {
        const item = this.state.itemsUsersAddList
        for (let i = 0; i < item.length; i++) {
           if(item[i].user_id === id) {
               return item[i].user_id
           } 
        }

        return null;
    }

    getSuggestionsUsers = value => {
        let users = this.state.users
        let reUsers = users.filter(item => {
            const usersValue = escapeRegexCharacters(value.toLowerCase().trim());
            return item.display_name.toLowerCase().search(usersValue) >= 0 || item.email.toLowerCase().search(usersValue) >= 0;
        })
        return reUsers;
    };

    onPlatesFetchRequestedUsers = ({ value }) => {
        this.setState({ suggestionUsers: this.getSuggestionsUsers(value) });
    };
    
      onPlatesClearRequestedUsers = () => {
        this.setState({ suggestionUsers: [] });
    };

    onSuggestionsFetchRequestedUsers = ({ value }) => {
        this.setState({
            suggestionUsers: this.getSuggestionsUsers(value)
        });
    };

    getSuggestionValueUsers = suggestion => {
        this.setState({ userSelectedId: suggestion.id })

        return (
            suggestion.display_name
        )
    };

    renderSuggestionUsers = suggestion => (
        <div>
            { suggestion.display_name + " - " + suggestion.email}
        </div>
    );

    onSuggestionsClearRequestedUsers = () => {
        this.setState({
            suggestionUsers: []
        });
    };

    onChangeUser = (event, { newValue }) => {
        this.setState({
            userSelected: newValue
        });
    };

    // ================== USERS ===================

    onChange = event =>{
        this.setState({ [event.target.name]: event.target.value });
    };

    renderUsersAssociatesErrors = () => {
        const handleClose = () => this.setState({ showModalUsersErrors: false, usersPerMacroErrors: [] })

        return (
            <>
                <Modal show={this.state.showModalUsersErrors} onHide={handleClose} aria-labelledby="example-modal-sizes-title-sm" dialogClassName="modal-sm-mc-errors">
                    <Modal.Header closeButton>
                        <Modal.Title style={{flex: 1}} id="example-modal-sizes-title-sm">{this.props.t("macros.m-item-6")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table text-center">
                            <thead>
                                <tr>
                                    <th scope="col">{this.props.t("macros.m-item-7")}</th>
                                    <th scope="col">{this.props.t("macros.m-item-8")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.usersPerMacroErrors.length > 0 ?
                                    this.state.usersPerMacroErrors.map(item => (
                                        <tr className="mt-3">
                                            <td>{item.name}</td>
                                            <td>{item.error === "user-macro-already-exist" ? this.props.t("macros.m-item-9") : item.error}</td>
                                        </tr>
                                    ))
                                    :
                                    null
                                }
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={() => handleClose()} className="btn btn-primary mr-2">{this.props.t("macros.m-item-10")}</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    renderUsersAssociates = () => {
        const handleClose = () => this.setState({ showModalUsers: false, addUsers: null, itemsUsersAddList: [], usersPerMacro: [] })

        const usersProps = {
            placeholder: "Usuario",
            value: this.state.userSelected,
            className: "form-control",
            style: {
              position: "relative",
              WebkitBoxFlex: 1,
              msFlex: "1 1 auto",
              flex: "1 1 auto",
              width: "100%",
              marginBottom: 0
            },
          onChange: this.onChangeUser
        };

        return (
        <>
            <Modal show={this.state.showModalUsers} onHide={handleClose} aria-labelledby="example-modal-sizes-title-sm" dialogClassName="modal-sm-mc">
                <Modal.Header closeButton>
                    <Modal.Title style={{flex: 1}} id="example-modal-sizes-title-sm">{!this.state.addUsers ? this.props.t("macros.m-item-11"): this.props.t("macros.m-item-12")}</Modal.Title>
                    {!this.state.addUsers ? <button className="btn btn-primary" onClick={() => this.setState({addUsers: true, itemsUsersAddList: []})}>{this.props.t("macros.m-item-13")}</button> : null}
                </Modal.Header>
                <Modal.Body>
                    { !this.state.addUsers ?
                        this.state.usersPerMacro.length === 0 ?
                            <p className="text-center mt-4">{this.props.t("macros.m-item-14")}</p>
                            :
                            this.state.usersPerMacro.length > 0 ?
                                <table className="table text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">{this.props.t("macros.m-item-7")}</th>
                                            <th scope="col">{this.props.t("auth.email")}</th>
                                            <th scope="col">Rol</th>
                                            <th scope="col">{this.props.t("macros.m-item-15")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.usersPerMacro.map(item => (
                                                <tr className="mt-3">
                                                    <td>{item.name_user}</td>
                                                    <td>{item.email_user}</td>
                                                    <td>Gerente</td>
                                                    <td>
                                                        <button onClick={() => this.deleteUserPerMacro(item.id, item.name_mc, item.name_user, item.user_id)} type="button" className="border-0 bg-transparent"><i className="fa fa-trash text-danger"></i></button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                :
                                <Loader2 />
                        :
                        <>
                        <form onSubmit={e => this.addUsersList(e)} className="p-4">
                            <div className="form-group">
                                <div>
                                    <label htmlFor="nameInput">{this.props.t("macros.m-item-16")}</label>
                                </div>
                                <div className="macro_auto">
                                <Autosuggest
                                    suggestions={this.state.suggestionUsers}
                                    onSuggestionsFetchRequested={this.onPlatesFetchRequestedUsers}
                                    onSuggestionsClearRequested={this.onPlatesClearRequestedUsers}
                                    shouldRenderSuggestions={() => (true)}
                                    getSuggestionValue={this.getSuggestionValueUsers}
                                    renderSuggestion={this.renderSuggestionUsers}
                                    inputProps={usersProps}
                                />
                                </div>
                            </div>
                            <button className="btn btn-primary d-block" disabled={this.state.userSelected != "" ? false : true} style={{margin: '0 auto'}}>{this.props.t("macros.m-item-17")}</button>
                        </form>

                        {
                            this.state.itemsUsersAddList.length > 0 ? 
                            <>
                                <table className="table text-center mt-5">
                                        <thead>
                                            <tr>
                                                <th scope="col">{this.props.t("macros.m-item-16")}</th>
                                                <th scope="col">{this.props.t("macros.m-item-15")}</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                {
                                    this.state.itemsUsersAddList.map(item => (
                                        <tr className="mt-3">
                                            <td>{item.name}</td>
                                            <td>
                                                <button onClick={() => this.deleteUserList(item.user_id)} className="macro-times"><i class="fas fa-times"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                    </tbody>
                                </table>
                            </>
                            :
                            <>
                            </>
                        }
                        </>
                    }
                </Modal.Body>
                <Modal.Footer style={{display: "flex", justifyContent: "normal"}}>
                    {!!this.state.addUsers ?
                        <>
                            <div className="d-flex justify-content-between" style={{width: "100%"}}>
                                <p className="ml-1 text-left">{this.props.t("macros.m-item-18") + this.state.itemsUsersAddList.length}</p>
                                <div>
                                    <button onClick={() => this.setState({ addUsers: null })} className="btn btn-light mr-2">{this.props.t("macros.m-item-19")}</button>
                                    <button onClick={this.onSubmitUsers} disabled={this.state.itemsUsersAddList.length > 0 ? false : true} className="btn btn-primary">{this.props.t("macros.m-item-20")}</button>
                                </div>
                            </div>
                        </>
                        :
                        null
                    }
                </Modal.Footer>
            </Modal>
        </>
        )
    }

    // ================== FLEETS ===================

    onSubmitFleets = () => {
        let data = {
            macro_account: this.state.itemsFleetsAddList
        }

        trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_POST_CLIENTS_MACRO, {
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            },
            method: "POST",
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
            if(json.create_error) {
                this.setState({ showModalFleetsErrors: true, fleetsPerMacroErrors:  json.create_error})
                throw new Error(json.message)
            }

            toast.success(json.message)
            this.setState({ itemsFleetsAddList: [], fleetsPerMacroErrors: [], addFleets: null, showModalFleets: false})
            this.updateMacro()
        })
        .catch(err => {
            toast.error(err.message)
        }))
    }

    getFleetsPerMacro = (macroId) => {
        let str = `?type_account=mc_account`
        trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_GET_CLIENTS_MACRO.replace("macro_id", macroId) + str, {
            headers:{
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            }
        })
        .then(response => response.json())
        .then(json => {
            this.setState({ fleetsPerMacro: json.client_macro_accounts })

        })
        .catch(err => {
            toast.error(err.message)
        }))
    }

    deleteFleetList = (id) => {
        this.setState({ itemsFleetsAddList: this.state.itemsFleetsAddList.filter(list => list.client_id != id) })
    }

    deleteFleetPerMacro = (id, macroName, macroUser, userId) => {
        if(window.confirm(this.props.t("macros.m-item-21") + " " + macroUser + " " + this.props.t("macros.m-item-2") + macroName)) {
            trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_DELETE_CLIENTS_PER_MACRO.replace("macro_id", id), {
                headers:{
                    "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                    "Accept-language": localStorage.getItem("LanguageSelected"),
                },
                method: "DELETE"
            })
            .then(response => {
                if(response.status != 200) {
                    throw new Error(this.props.t("macros.m-item-3"))
                }
                return response.json()
            })
            .then(json => {
                toast.success(json.message)
                this.setState({ fleetsPerMacro: this.state.fleetsPerMacro.filter(list => list.client_id != userId) })
                this.updateMacro()
            })
            .catch(err => {
                toast.error(err.message)
            }))
        }
    }

    addFleetList = (e) => {
        e.preventDefault();
        if (this.state.fleetSelected != "") {
            if(this.validateAddFleetList(this.state.fleetSelectedId) === null) {
                const data = {
                    client_id: this.state.fleetSelectedId,
                    name: this.state.fleetSelected,
                    macro_account_id: this.state.macroSelectedId
                }
                this.state.itemsFleetsAddList.push(data)
                this.setState({fleetSelected: ''})
            } else {
                toast.error(this.props.t("macros.m-item-22"))
                this.setState({fleetSelected: ''})
            }
        } else {
            toast.error(this.props.t("macros.m-item-5"))
        }
    }

    validateAddFleetList = (id) => {
        const item = this.state.itemsFleetsAddList
        for (let i = 0; i < item.length; i++) {
           if(item[i].client_id === id) {
               return item[i].client_id
           } 
        }


        return null;
    }

    getSuggestionsFleets = value => {
        let fleets = this.state.fleets
        let reFleets = fleets.filter(item => {
            const fleetsValue = escapeRegexCharacters(value.toLowerCase().trim());
            return item.name.toLowerCase().search(fleetsValue) >= 0;
        })
        return reFleets;
    };

    onPlatesFetchRequestedFleets = ({ value }) => {
        this.setState({ suggestionFleets: this.getSuggestionsFleets(value) });
    };
    
      onPlatesClearRequestedFleets = () => {
        this.setState({ suggestionFleets: [] });
    };

    onSuggestionsFetchRequestedFleets = ({ value }) => {
        this.setState({
            suggestionFleets: this.getSuggestionsFleets(value)
        });
    };

    getSuggestionValueFleets = suggestion => {
        this.setState({ fleetSelectedId: suggestion.id })

        return (
            suggestion.name
        )
    };

    renderSuggestionFleets = suggestion => (
        <div>
            { suggestion.name }
        </div>
    );

    onSuggestionsClearRequestedFleets = () => {
        this.setState({
            suggestionFleets: []
        });
    };

    onChangeFlet = (event, { newValue }) => {
        this.setState({
            fleetSelected: newValue
        });
    };


    // ================== FLEETS ===================

    renderFleetsAssociatesErrors = () => {
        const handleClose = () => this.setState({ showModalFleetsErrors: false, fleetsPerMacroErrors: [] })

        return (
            <>
                <Modal show={this.state.showModalFleetsErrors} onHide={handleClose} aria-labelledby="example-modal-sizes-title-sm" dialogClassName="modal-sm-mc-errors">
                    <Modal.Header closeButton>
                        <Modal.Title style={{flex: 1}} id="example-modal-sizes-title-sm">{this.props.t("macros.m-item-6")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table text-center">
                            <thead>
                                <tr>
                                    <th scope="col">{this.props.t("macros.m-item-7")}</th>
                                    <th scope="col">{this.props.t("macros.m-item-8")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.fleetsPerMacroErrors.length > 0 ?
                                    this.state.fleetsPerMacroErrors.map(item => (
                                        <tr className="mt-3">
                                            <td>{item.name}</td>
                                            <td>{item.error}</td>
                                        </tr>
                                    ))
                                    :
                                    null
                                }
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={() => handleClose()} className="btn btn-primary mr-2">{this.props.t("macros.m-item-10")}</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    renderFleetsAssociates = () => {
        const handleClose = () => this.setState({ showModalFleets: false, addFleets: null, itemsFleetsAddList: [] });

        const fleetsProps = {
            placeholder: "Flota",
            value: this.state.fleetSelected,
            className: "form-control",
            style: {
              position: "relative",
              WebkitBoxFlex: 1,
              msFlex: "1 1 auto",
              flex: "1 1 auto",
              width: "100%",
              marginBottom: 0
            },
          onChange: this.onChangeFlet
        };

        return (
            <>
                <Modal show={this.state.showModalFleets} onHide={handleClose} aria-labelledby="example-modal-sizes-title-sm" dialogClassName="modal-sm-mc">
                    <Modal.Header closeButton>
                        <Modal.Title style={{flex: 1}} id="example-modal-sizes-title-sm">{!this.state.addFleets ? this.props.t("macros.m-item-44"): this.props.t("macros.m-item-45")}</Modal.Title>
                        {!this.state.addFleets ? <button className="btn btn-primary" onClick={() => this.setState({addFleets: true,  itemsFleetsAddList: []})}>{this.props.t("macros.m-item-23")}</button> : null}
                    </Modal.Header>
                    <Modal.Body>
                    { !this.state.addFleets ?
                        this.state.fleetsPerMacro.length === 0 ?
                        <p className="text-center mt-4">{this.props.t("macros.m-item-24")}</p>
                        :
                        this.state.fleetsPerMacro.length > 0 ?
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">{this.props.t("macros.m-item-7")}</th>
                                        <th scope="col">{this.props.t("macros.m-item-15")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.fleetsPerMacro.map(item => (
                                            <tr className="mt-3">
                                                <td>{item.name_customer}</td>
                                                <td>
                                                    <button onClick={() => this.deleteFleetPerMacro(item.id, item.name_mc, item.name_customer, item.client_id)} type="button" className="border-0 bg-transparent"><i className="fa fa-trash text-danger"></i></button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            :
                            <Loader2 />
                        :
                        <>
                        <form onSubmit={e => this.addFleetList(e)} className="p-4">
                            <div className="form-group">
                                <div>
                                    <label htmlFor="nameInput">{this.props.t("macros.m-item-25")}</label>
                                </div>
                                <div className="macro_auto">
                                <Autosuggest
                                    suggestions={this.state.suggestionFleets}
                                    onSuggestionsFetchRequested={this.onPlatesFetchRequestedFleets}
                                    onSuggestionsClearRequested={this.onPlatesClearRequestedFleets}
                                    shouldRenderSuggestions={() => (true)}
                                    getSuggestionValue={this.getSuggestionValueFleets}
                                    renderSuggestion={this.renderSuggestionFleets}
                                    inputProps={fleetsProps}
                                />
                                </div>
                            </div>
                            <button className="btn btn-primary d-block" disabled={this.state.fleetSelected != "" ? false : true} style={{margin: '0 auto'}}>{this.props.t("macros.m-item-17")}</button>
                        </form>

                        {
                            this.state.itemsFleetsAddList.length > 0 ? 
                            <>
                                <table className="table text-center mt-5">
                                        <thead>
                                            <tr>
                                                <th scope="col">{this.props.t("macros.m-item-25")}</th>
                                                <th scope="col">{this.props.t("macros.m-item-15")}</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                {
                                    this.state.itemsFleetsAddList.map(item => (
                                        <tr className="mt-3">
                                            <td>{item.name}</td>
                                            <td>
                                                <button onClick={() => this.deleteFleetList(item.client_id)} className="macro-times"><i class="fas fa-times"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                    </tbody>
                                </table>
                            </>
                            :
                            <>
                            </>
                        }
                        </>
                    }
                    
                    </Modal.Body>
                    <Modal.Footer style={{display: "flex", justifyContent: "normal"}}>
                        {!!this.state.addFleets ?
                            <>
                                <div className="d-flex justify-content-between" style={{width: "100%"}}>
                                    <p className="ml-1 text-left">{this.props.t("macros.m-item-18") + this.state.itemsFleetsAddList.length}</p>
                                    <div>
                                        <button onClick={() => this.setState({ addFleets: null })} className="btn btn-light mr-2">{this.props.t("macros.m-item-19")}</button>
                                        <button onClick={this.onSubmitFleets} disabled={this.state.itemsFleetsAddList.length > 0 ? false : true} className="btn btn-primary">{this.props.t("macros.m-item-20")}</button>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    renderTableElements = () => {
        const { search } = this.state;
        let searched = search.toLowerCase()
        let macros =  this.state.macroData.filter(item => {
            return (item.name.toLowerCase().search(searched) >= 0)
        });

        return (
            <div>
                {
                    this.state.macroData.length > 0 ?
                    <table className="table text-center">
                    <thead>
                        <tr>
                            <th scope="col">{this.props.t("macros.m-item-7")}</th>
                            <th scope="col">{this.props.t("macros.m-item-11")}</th>
                            <th scope="col">{this.props.t("macros.m-item-27")}</th>
                            <th scope="col">{this.props.t("macros.m-item-28")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            macros.map(item => (
                                <tr className="mt-3">
                                    <td>{item.name}</td>
                                    <td> 
                                        <div style={{position: "relative"}}>
                                            <button type="button" className="btn btn-primary" onClick={() => {
                                                this.setState({showModalUsers: true, macroSelectedId: item.id})
                                                this.getUsersPerMacro(item.id)
                                                }}> <i className="fa fa-user"></i> </button> 
                                            {
                                                item.user_count > 0 ?
                                                <span style={{position: "absolute", top: "-10px", background: "#1674ab",}} className="badge badge-danger"> {item.user_count} </span>
                                                :
                                                null
                                            }
                                        </div>
                                    </td>
                                    <td> 
                                        <div style={{position: "relative"}}>
                                            <button className="btn btn-primary" type="button" onClick={() => {
                                                this.setState({showModalFleets: true, macroSelectedId: item.id})
                                                this.getFleetsPerMacro(item.id)
                                            }}> <i className="fa fa-truck-pickup"></i> </button> 
                                            {
                                                item.client_count > 0 ?
                                                <span style={{position: "absolute", top: "-10px", background: "#1674ab",}} className="badge badge-danger"> {item.client_count} </span>
                                                :
                                                null
                                            }
                                            
                                        </div>
                                    </td>
                                    <td>
                                        <MacroActions t={this.props.t} mName={item.name} mId={item.id} updateMacro={this.updateMacro}/>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                :
                null
                }
                { this.state.showModalUsers ? this.renderUsersAssociates() : null}
                { this.state.showModalFleets ? this.renderFleetsAssociates() : null}
                { this.state.showModalUsersErrors ? this.renderUsersAssociatesErrors() : null}
                { this.state.showModalFleetsErrors ? this.renderFleetsAssociatesErrors() : null}
            </div>
        )
    }

    inputSearch = (e) => {
        this.setState({ search: e.target.value })
    }

    render() {
        
        return (
            <AuthUserContext.Consumer>
            {authUser => (
                <div className="container-fluid mt-100">
                    <div className="row mb-5">
                        <h2 className="col-md-6">{this.props.t("macros.m-item-29")}</h2>
                        <div className="col-md-5">
                            <input
                            className="form-control mt-2"
                            type="text"
                            id="search"
                            placeholder="Buscar..."
                            onChange={(e) => this.inputSearch(e)}
                            />
                        </div>
                        <Link to={ROUTES.MACROS_MACRO_ACCOUNT_NEW} className="btn btn-primary col-md-1 mt-1" style={{height: "40px"}}> <i className="fa fa-plus"></i> {this.props.t("macros.m-item-30")}</Link>
                    </div>

                    {this.renderTableElements()}
                </div>
            )}
            </AuthUserContext.Consumer>      
        )
    }
}


const condition = authUser => !!authUser;

export default withAuthorization(condition)(MacroAcocunt);
