import React from "react";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import Metabase from "../Metabase";
import { validateMetaLanguage } from "../../../utils/validateMetaLanguage";

class Shopping extends React.Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <Metabase dashboardId={validateMetaLanguage(1106, 1106)} />
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Shopping);
