import { REQ_WAREHOUSES_SUCCESS } from '../types/TireWarehousesTypes'


const INITAL_STATE = {
  warehouses: []
}

export const warehousesReducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case REQ_WAREHOUSES_SUCCESS:
      return {
        ...state,
        warehouses: action.payload
      } 
    default:
      return state
  }
}