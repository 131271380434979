import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import * as constantsWarehouses from "../../../constants/actions/Warehouses";

const initialState = {
  warehouses: [],
  warehouse : {},
  message   : null,
  error     : null
};

function rootReducer(state, action) {
  switch(action.type) {
    case constantsWarehouses.GET_WAREHOUSES_SUCCESS:
      return {
        warehouses: action.json.warehouses,
        warehouse : {},
        error     : null
      };
    case constantsWarehouses.GET_WAREHOUSE_SUCCESS:
      return {
        warehouse: action.json.warehouse
      };
    case constantsWarehouses.GET_REQUEST_SUCCESS:
      return {
        warehouses: action.json.warehouses,
        warehouse : action.json.warehouse,
        message   : action.json.message,
      };
    case constantsWarehouses.GET_REQUEST_ERROR:
      return {
        warehouses: action.json.warehouses,
        warehouse : action.json.warehouse,
        message   : action.json.message,
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
