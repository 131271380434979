import $ from "jquery";
import React, { Component } from "react";

class ErrorsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };
  }

  showModal = (errors) => {
    this.setState({ errors: errors });
    $("#errorsModal").modal();
  };

  getErrorsList = (errors) => {
    return (
      <ul className="list-group">
        {!!errors.length > 0 &&
          errors.map((error, i) => {
            return (
              <li className="list-group-item" key={i}>
                {" "}
                {error.message}
              </li>
            );
          })}
      </ul>
    );
  };

  render() {
    return (
      <div
        className="modal fade"
        id="errorsModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="errorsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark" id="errorsModalLabel">
                {this.props.t("globals.file-errors")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Cerrar"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-dark">
              {this.getErrorsList(this.state.errors)}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                aria-label="Cerrar"
              >
                {this.props.t("globals.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorsModal;
