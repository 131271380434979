import React from "react";
import { validateMetaLanguage } from "../../../utils/validateMetaLanguage";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import Metabase from "../Metabase";

// const customDashboard = (customer) => {
//   if(!!customer) return null
//   customer == 94 ? 846 : customer == 129 ? 853 : customer == 196 ? 854 : 830

//   return customer
// }

let customer = localStorage.getItem("GlobalCustomerId")

class Sinisters extends React.Component {
  constructor(props) {
    super(props);
  };

  // isSinisters={customer == 94 || customer == 129 || customer == 196 || customer == 130 || customer == 153 ? true : false}


  render() {
    let dash_id_es = 852;
    let dash_id_pt = 927;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Metabase dashboardId={validateMetaLanguage(dash_id_es, dash_id_pt)} />
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(Sinisters);
