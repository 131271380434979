import React, { Component, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import { trackPromise } from "react-promise-tracker";
import * as ROLES from "../../constants/roles";
import _debounce from "lodash/debounce";
import * as APIS from "../../constants/apis";

import { useDispatch, useSelector } from "react-redux";
import {
  getUsersPaginationAction,
} from "../../redux/actions/UsersAction";
import Loading from "../Loader";
import API from "../../constants/api";
import { paginationEstructure } from "../../constants/paginationCode";
import Pagination from "../pagination";
import UserDeleteItem from "./UserDeleteItem"
import UserActiveItem from "./UserActiveItem"

const UserList = ({ t }) => {
  const dispatch = useDispatch()
  const data = useSelector(selector => selector)
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState({
    users: data.users.users,
    active: true,
    next: data.users.next,
    previus: data.users.previus,
    total: data.users.total,
    page: 1,
    limit: 20,
  })

  useEffect(() => {
    if (data.users.users.length === 0) {
      getUsers(state.active, state.page, state.limit)
    } else {
      setLoading(false)
    }
  }, [])

  const getUsers = (active, page, limit) => {
    setLoading(true)
    let str = `?active=${active}&page=${page}&limit=${limit}`
    dispatch(getUsersPaginationAction(str))
      .then(response => {
        setState(state => ({
          ...state,
          users: response.Users,
          next: response.next,
          previus: response.previus,
          total: response.total,
        }))
        setLoading(false)
      })
      .catch(error => {
        if (error.response.status != 200) {
          toast.error(error.response.data.message)
          setLoading(false)
        }
      })
  }

  const debounceFn = _debounce((searchTerm, active) => {
    if (searchTerm !== "") {
      let str = `?search=${searchTerm}&active=${active}`
      API.getUsers(str)
        .then(response => {
          setState(state => ({
            ...state,
            users: response.Users
          }))
        })
        .catch(error => {
          if (error.response.status != 200) {
            toast.error(error.response.data.message)
          }
        })
    } else {
      setState(state => ({
        ...state,
        users: data.users.users
      }))
    }
  }, 1000);

  const handleSearch = (value) => {
    debounceFn(value.toLowerCase(), state.active);
  };

  const paginationSubmit = (page, limit, authUser) => {
    setState(state => ({
      ...state,
      page: page,
      limit: limit,
    }))
    getUsers(state.active, page, limit)
  }

  const clearDelete = (id) => {
    let userss = state.users.filter(
      (user) => user.uid !== id
    );
    setState(state => ({
      ...state,
      users: userss
    }))
  }

  const filterByActive = (e, filter) => {
    e.preventDefault();
    setState(state => ({
      ...state,
      active: filter,
      page: 1,
      limit: 20,
    }))
    getUsers(filter, 1, 20)
  }

  const getUsersList = (authUser) => {
    if (state.users.length === 0) return null

    const pagination = paginationEstructure(state.next, state.previus, state.total, state.limit, state.page);

    return (
      <div className="text-center v-scroll">
        <button
          className="btn btn-primary text-white mr-2 float-right"
          onClick={() => getUsers(state.active, state.page, state.limit)}
          type="button"
        >
          <i className="fa fa-sync-alt"></i> {t("globals.act-users")}
        </button>
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{t("users.users-th-1")}</th>
              <th scope="col">{t("users.users-th-2")}</th>
              <th scope="col">{t("users.users-th-3")}</th>
              <th scope="col">{t("users.users-th-4")}</th>
              <th scope="col">{t("users.users-th-6")}</th>
            </tr>
          </thead>
          <tbody>
            {state.users.map((user) => (
              <tr key={user.uid}>
                <td>{user.email}</td>
                <td>{user.display_name}</td>
                <td>
                  {user.roles === "ADMIN" && user.restricted_admin === false
                    ? "SUPER ADMIN"
                    : user.roles === "CUSTOMER" && user.is_manager === true
                      ? "GERENTE"
                      : user.roles}
                </td>
                <td>
                  {Object.entries(user.permissions).map((permission, index) => {
                    let separator =
                      index < Object.keys(user.permissions).length - 1
                        ? " / "
                        : "";
                    return !!permission[1] ? names[index] + separator : "";
                  })}
                </td>
                <td>
                  {!!authUser.permissions.edit ? (
                    <>
                      <UserActiveItem t={t} user={user} clearDelete={clearDelete} />
                      <Link
                        to={"/users/edit/" + user.uid}
                        className="btn btn-primary"
                      >
                        <i className="fa fa-edit"></i> {t("globals.edit")}
                      </Link>
                    </>
                  ) : null}
                  &nbsp;
                  {!!authUser.permissions.delete ? (
                    <UserDeleteItem t={t} id={user.uid} clearDelete={clearDelete} />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="d-flex justify-content-center">
          {state.users.length > 0 ? (
            <Pagination
              colspan="10"
              pageP={pagination.pageP}
              pageN={pagination.pageN}
              page={state.page}
              limit={state.limit}
              links={pagination.links}
              submit={(page, limit) =>
                paginationSubmit(page, limit, authUser)
              }
            />
          ) : null}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-6">
                <h3>{t("users.users-list")}</h3>
              </div>
              <div className="col-md-2">
                <div
                  className="btn-group btn-group-toggle float-right"
                  data-toggle="buttons"
                >
                  <label className="btn btn-primary active">
                    <input
                      type="radio"
                      name="isActive"
                      value={true}
                      onClick={(e) => filterByActive(e, true)}
                      autoComplete="off"
                    />
                    {t("globals.actives")}
                  </label>
                  <label className="btn btn-primary">
                    <input
                      type="radio"
                      name="isActive"
                      value={false}
                      onClick={(e) => filterByActive(e, false)}
                      autoComplete="off"
                    />{" "}
                    {t("globals.deactives")}
                  </label>
                </div>
              </div>
              <div className="input-group-append"></div>
              <div className="col-md-4 input-group-append">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder={t("globals.search-input")}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-md-12">
                {!!authUser.permissions.create ? (
                  <Link to="/users/new" className="btn btn-primary">
                    <i className="fa fa-plus"></i> {t("globals.register")}
                  </Link>
                ) : null}
              </div>
            </div>
            <br />
            <br />
            {getUsersList(authUser)}
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

let names = ["CREAR", "EDITAR", "ELIMINAR", "VER"];

const condition = (authUser) => !!authUser && authUser.roles === ROLES.ADMIN;

export default withAuthorization(condition)(UserList);
