import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import * as constantsCustomer from "../../constants/actions/Customers";

const initialState = {
  customers   : [],
  tires       : [],
  inspections : [],
  customer    : {},
  message     : null,
  error       : null
};

function rootReducer(state, action) {
  switch(action.type) {
    case constantsCustomer.GET_CUSTOMERS_SUCCESS:
      return {
        customers : action.json.Customers,
        customer  : {},
        error     : null
      };
    case constantsCustomer.GET_CUSTOMER_SUCCESS:
      return {
        customer    : action.json.customer,
        tires       : action.json.tires,
        inspections : action.json.inspections,
        customers   : [],
        error       : null
      };
    case constantsCustomer.GET_REQUEST_SUCCESS:
      return {
        customers : action.json.customers,
        customer  : action.json.customer,
        tires     : action.json.tires,
        message   : action.json.message
      };
    case constantsCustomer.GET_REQUEST_ERROR:
      return {
        customers : action.json.customers,
        customer  : action.json.customer,
        error     : action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};