export const GET_VEHICLES_REQUEST   = "GET_VEHICLES_REQUEST";
export const GET_VEHICLES_SUCCESS   = "GET_VEHICLES_SUCCESS";
export const GET_CUSTOMERS_SUCCESS  = "GET_CUSTOMERS_SUCCESS";
export const GET_VEHICLE_REQUEST    = "GET_VEHICLE_REQUEST";
export const GET_VEHICLE_SUCCESS    = "GET_VEHICLE_SUCCESS";
export const GET_LISTS_SUCCESS      = "GET_LISTS_SUCCESS";
export const GET_LINES_SUCCESS      = "GET_LINES_SUCCESS";
export const DELETE_VEHICLE_REQUEST = "DELETE_VEHICLE_REQUEST";
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS";
export const GET_REQUEST_ERROR      = "GET_REQUEST_ERROR";
export const GET_REQUEST_SUCCESS    = "GET_REQUEST_SUCCESS";