import React, { Component } from "react";

import * as VARIABLES from "../../../constants/variables";

class TypeForm extends Component {
  constructor(props) {
    super(props);
  }
  getTypesOptions = (typeform) => {
    let data;
    typeform === "vehicle"
      ? this.props.showRotations ? (data = VARIABLES.TYPES2) : (data = VARIABLES.TYPES22)
      : (data = VARIABLES.TYPES);
    return data.map((type) => {
      if (!!this.props.delCreate && type !== "Registrar") {
        return (
          <option key={type.value} value={type.value}>
            {this.props.t(`selectors.${type.name}`)}
          </option>
        );
      }
      return null;
    });
  };

  render() {
    return (
      <form>
        <div className="form-group width-150">
          <select
            className="form-control"
            id="typeSelect"
            name="type"
            value={this.props.type}
            onChange={this.props.onChange}
          >
            <option value="">
              {this.props.t("ins-one.ins-one-tire-form")}
            </option>
            {this.getTypesOptions(this.props.typeform)}
          </select>
        </div>
      </form>
    );
  }
}

export default TypeForm;
