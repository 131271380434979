export const DATA_CUSTOMERS_SUCCESS = "DATA_CUSTOMERS_SUCCESS"
export const DATA_REQUEST_ERROR = "DATA_REQUEST_ERROR"
export const SET_LOADING_CUSTOMER = "SET_LOADING_CUSTOMER"
export const SET_LOADING_CUSTOMER_SUCCES = "SET_LOADING_CUSTOMER"
export const DATA_CUSTOMERS_SEARCH_SUCCESS = "DATA_USERS_SEARCH_SUCCESS"
export const DATA_CUSTOMERS_SEARCH_CLEAR_SUCCESS = "DATA_CUSTOMERS_SEARCH_CLEAR_SUCCESS"

export const DATA_CUSTOMERS_PAGINATION_SUCCESS = "DATA_CUSTOMERS_PAGINATION_SUCCESS"

export const DATA_CUSTOMERS_MACRO_SUCCES = "DATA_CUSTOMERS_MACRO_SUCCES"
export const DATA_CUSTOMERS_MACRO_ERROR = "DATA_CUSTOMERS_MACRO_ERROR"