import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

//Navigation
import Navigation from "../Navigation";
import Help from "../help";

// Presentation
import LandingPage from "../Landing";
import HomePage from "../Home";

// Account
import SignInPage from "../Auth/SignIn";
import PasswordForgetPage from "../Auth/PasswordForget";
import AccountPage from "../Auth/Account";
import { withAuthentication } from "../Auth/Session";

// Users
import Users from "../Users";
import UserNewItem from "../Users/UserNewItem";
import UserEditItem from "../Users/UserEditItem";

// Invoices
import Invoices from "../Invoices";
import InvoiceNewItem from "../Invoices/InvoiceNewItem";
import InvoiceItem from "../Invoices/InvoiceItem";

// Dashboards
import Dashboards from "../Dashboards";
import DashboardNewItem from "../Dashboards/DashboardNewItem";
import DashboardEditItem from "../Dashboards/DashboardEditItem";
import DashboardReports from "../Dashboards/DashboardReports";

// Customers
import Customers from "../Customers";
import CustomerEditItem from "../Customers/CustomerEditItem";
import CustomerItem from "../Customers/CustomerItem";
import CustomerNewItem from "../Customers/CustomerNewItem";
import Formats from "../Formats";
import MacroCustomers from "../Customers/CustomerItemMacro";

// Vehicle brands
import Brands from "../Brands";
import BrandEditItem from "../Brands/BrandEditItem";
import BrandNewItem from "../Brands/BrandNewItem";

// Vehicle schemas
import Schemas from "../Schemas";
import SchemaEditItem from "../Schemas/SchemaEditItem";
import SchemaNewItem from "../Schemas/SchemaNewItem";

// Vehicle types
import VehicleTypes from "../VehicleTypes";
import VehicleTypeEditItem from "../VehicleTypes/VehicleTypeEditItem";
import VehicleTypeNewItem from "../VehicleTypes/VehicleTypeNewItem";

// Tire brands
import TireBrands from "../TireBrands";
import TireBrandEditItem from "../TireBrands/TireBrandEditItem";
import TireBrandNewItem from "../TireBrands/TireBrandNewItem";
import TireBrandTires from "../TireBrands/TireBrandTires";

// Tire models
import TireModelEditItem from "../TireBrands/TireModels/TireModelEditItem";
import TireModelTires from "../TireBrands/TireModels/TireModelTires";

// Tire dimensions
import TireDimensionNewItem from "../TireBrands/TireModels/TireDimensions/TireDimensionNewItem";
import TireDimensionEditItem from "../TireBrands/TireModels/TireDimensions/TireDimensionEditItem";

// Retread brands
import RetreadBrands from "../RetreadBrands";
import RetreadBrandEditItem from "../RetreadBrands/RetreadBrandEditItem";
import RetreadBrandNewItem from "../RetreadBrands/RetreadBrandNewItem";
import RetreadBrandTires from "../RetreadBrands/RetreadBrandTires";

// Retread bands
import RetreadBandEditItem from "../RetreadBrands/RetreadBands/RetreadBandEditItem";
import RetreadBandTires from "../RetreadBrands/RetreadBands/RetreadBandTires";

// Retread dimensions
import RetreadDimensionNewItem from "../RetreadBrands/RetreadBands/RetreadDimensions/RetreadDimensionNewItem";
import RetreadDimensionEditItem from "../RetreadBrands/RetreadBands/RetreadDimensions/RetreadDimensionEditItem";

// Tire catalogue
import TireCatalogues from "../Customers/TireCatalogues";
import TireCatalogueNewItem from "../Customers/TireCatalogues/TireCatalogueNewItem";
import TireCatalogueEditItem from "../Customers/TireCatalogues/TireCatalogueEditItem";
import TireCatalogueMultiple from "../Customers/TireCatalogues/TireCataloguesMultiple";
import TireCatalogueItem from "../Customers/TireCatalogues/TireCatalogueItem";

// Retread catalogue
import RetreadCatalogues from "../Customers/RetreadCatalogues";
import RetreadCatalogueNewItem from "../Customers/RetreadCatalogues/RetreadCatalogueNewItem";
import RetreadCatalogueEditItem from "../Customers/RetreadCatalogues/RetreadCatalogueEditItem";
import RetreadCatalogueMultiple from "../Customers/RetreadCatalogues/RetreadCataloguesMultiple";
import RetreadCatalogueItem from "../Customers/RetreadCatalogues/RetreadCatalogueItem";

// Warehouses
import Warehouses from "../Customers/Warehouses";
import WarehouseEditItem from "../Customers/Warehouses/WarehouseEditItem";
import WarehouseNewItem from "../Customers/Warehouses/WarehouseNewItem";

// Cities
import Cities from "../Customers/Cities";
import CityEditItem from "../Customers/Cities/CityEditItem";
import CityNewItem from "../Customers/Cities/CityNewItem";

// Cost centers
import CostCenters from "../Customers/CostCenters";
import CostCenterEditItem from "../Customers/CostCenters/CostCenterEditItem";
import CostCenterNewItem from "../Customers/CostCenters/CostCenterNewItem";

// Groups
import Groups from "../Customers/Groups";
import GroupEditItem from "../Customers/Groups/GroupEditItem";
import GroupNewItem from "../Customers/Groups/GroupNewItem";

// Vehicles
import Vehicles from "../Customers/Vehicles";
import VehicleNewItem from "../Customers/Vehicles/VehicleNewItem";
import VehicleEditItem from "../Customers/Vehicles/VehicleEditItem";
import VehicleMultiple from "../Customers/Vehicles/VehicleMultiple";
import VehicleHistoryList from "../Customers/Vehicles/VehicleHistoryList";
import VehicleItem from "../Customers/Vehicles/VehicleItem";

// Works Vehicle
import WorksVehicle from "../Works/Vehicles";
import WorkVehicleNewItem from "../Works/Vehicles/WorkNewItem";
import WorkVehicleEditItem from "../Works/Vehicles/WorkEditItem";
import WorkMultiple from "../Works/Multiple";

// Queries Vehicle
import QueriesVehicle from "../Queries/Vehicles";
import QueriesWorkVehicle from "../Queries/WorkVehicles";

// Tires
import Tires from "../Customers/Vehicles/Tires";
import TireNewItem from "../Customers/Vehicles/Tires/TireNewItem";
import TireEditItem from "../Customers/Vehicles/Tires/TireEditItem";
import TireMultiple from "../Customers/Vehicles/Tires/Multiple";
import TireHistoryList from "../Customers/Vehicles/Tires/TireHistoryList";

// Works Tire
import WorksTire from "../Works/Tires";
import WorkTireNewItem from "../Works/Tires/WorkNewItem";
import WorkTireEditItem from "../Works/Tires/WorkEditItem";

// Queries Vehicle
import QueriesTire from "../Queries/Tires";
import QueriesWorkTire from "../Queries/WorkTires";

// Measurements
import Distances from "../Measurement/Distance/Simple";
import DistancesMultiple from "../Measurement/Distance/Multiple";
import Fuels from "../Measurement/Fuel/Simple";
import FuelsMultiple from "../Measurement/Fuel/Multiple";

// Inspections
import Inspections from "../Inspection/Form";
import InspectionsMultiple from "../Inspection/Multiple";
import InspectionsPending from "../Inspection/Pending";
import InspectionsPendingScrap from "../Inspection/Pending/Scrap";
import InspectionsPendingRotation from "../Inspection/Pending/rotation";
import InspectionsPendingWithout from "../Inspection/Pending/WithoutMeasure";
import InspectionsPreview from "../Inspection/Preview";
import InspectionsAdvance from "../Inspection/Preview/advance";
import InspectionsPreviewMultiple from "../Inspection/Preview/Multiple";
import InspectionsPreviewList from "../Inspection/Preview/list";

// Novelties
import NoveltiesMultiple from "../Novelty/Multiple";

// Plans
import ReportsPlans from "../Reports/Plans";
import ReportsPlanItem from "../Reports/Plans/PlanItem";
import ReportsPlanCurrent from "../Reports/Plans/PlanCurrent";
import ReportsPlanTires from "../Reports/Plans/Tires";
import ReportsShopping from "../Reports/Shopping";
import ReportsTank from "../Reports/Tank";

// Indicators
import ReportsIndicators from "../Reports/Indicators";

// Informs
import ReportsInforms from "../Reports/Informs";

// Informs
import ReportsInspections from "../Reports/Inspections";
import ReportsInspectionsPreassure from "../Reports/Inspections/preassure";

// Top10
import ReportsTop10 from "../Reports/Top10";

// Performances
import ReportsPerformances from "../Reports/Performances/index";
import ReportsPerformancesLast from "../Reports/Performances/last";

// Injections
import ReportsInjections from "../Reports/injections";

// Dismount
import ReportsDismount from "../Reports/dismount";

// Discards
import ReportsDiscards from "../Reports/Discards";

// Warehouses
import ReportsWarehouses from "../Reports/Warehouses";

// Inventory
import ReportsInventory from "../Reports/Inventory";

// Stats Tires
import ReportsStatsTires from "../Reports/Statistics/Tires";

// Stats Gestion
import ReportsStatsGestion from "../Reports/Gestion/index";

// Stats Vehicles
import ReportsStatsVehicles from "../Reports/Statistics/Vehicles";

// Fullfilment
import ReportsFullfilment from "../Reports/Fullfilment";

// Errors Detection
import ReportsErrorsDetection from "../Reports/ErrorsDetection";

// Pressures
import ReportsPressures from "../Reports/Pressures";

// Sinisters
import ReportsSinisters from "../Reports/Sinisters";

// Tire Projection
import ReportsTireProjection from "../Reports/TireProjection";

// Top Indicators
import ReportsTopIndicators from "../Reports/TopIndicators";

// Users
import ReportsUsers from "../Reports/Users";

// Compilance Plant
import ReportsCompliance from "../Reports/CompilanceMonth";

// Stores
import configGlobalStore from "../App/actions";
import configBrandStore from "../Brands/actions";
import configTireBrandStore from "../TireBrands/actions";
import configRetreadBrandStore from "../RetreadBrands/actions";
import configSchemaStore from "../Schemas/actions";
import configVehicleTypeStore from "../VehicleTypes/actions";
import configCustomerStore from "../Customers/actions";
import configVehicleStore from "../Customers/Vehicles/actions";
import configCityStore from "../Customers/Cities/actions";
import configCostCenterStore from "../Customers/CostCenters/actions";
import configWarehouseStore from "../Customers/Warehouses/actions";
import configGroupStore from "../Customers/Groups/actions";
import configTireCatalogueStore from "../Customers/TireCatalogues/actions";
import configRetreadCatalogueStore from "../Customers/RetreadCatalogues/actions";
import configPlanStore from "../Reports/Plans/actions";

// Macros
import MAccount from "../Macros/mc/index";
import MAccountNew from "../Macros/mc/MacroNewItem";
import MAccountEdit from "../Macros/mc/MacroEditItem";
import MMAccount from "../Macros/mmc/index";
import MMAccountNew from "../Macros/mmc/MacroNewItem";
import MMAccountEdit from "../Macros/mmc/MacroEditItem";
import Macro from "../Landing/Macro";
import MacroMacro from "../Landing/MacroMacro";

// Routes
import * as ROUTES from "../../constants/routes";
import { useTranslation } from "react-i18next";
import AdminMassive from "../Multiple/AdminMassive";
import { stonly } from "../../utils/stonlychange";

// Venture
import VehicleVenture from "../Reports/ventures/Vehicle";
import TireVenture from "../Reports/ventures/Tires";
import IndicatorsVenture from "../Reports/ventures/Indicators";
import Compilance from "../Reports/ventures/Compilance";
import Consumption from "../Reports/ventures/Consumption";
import DiscardsVentures from "../Reports/ventures/Discards";
import MilimetersVentures from "../Reports/ventures/Milimeters";
import Yields from "../Reports/ventures/Yields";
import MtoPlan from "../Reports/ventures/MtoPlan";
import ConsolidatedPlan from "../Reports/ventures/ConsolidatedPlan";
import Shopping from "../Reports/ventures/Shopping";
import Emissions from "../Reports/ventures/Emissions";

// Contexts
const GlobalContext = React.createContext();

const App = () => {
  const [t] = useTranslation("global");

  // stonly change default icon
  useEffect(() => {
    setTimeout(() => {
      stonly();
    }, 1000);
  }, []);
  // stonly change default icon

  $(function () {
    $(".dropdown-accordion").on(
      "click",
      'a[data-toggle="collapse"]',
      function (event) {
        $($(this).attr("href")).collapse("toggle");
        event.stopPropagation();
      }
    );
    $("body").tooltip({ selector: "[data-toggle=tooltip]" });
    $("#tidio-chat > #tidio-chat-iframe").css("inset", "auto 120px 5px auto");
  });

  return (
    <BrowserRouter>
      <Navigation />
      <Switch>
        <Route
          exact
          path={ROUTES.ADMIN_MASSIVE}
          component={() => <AdminMassive t={t} />}
        />

        {/*Presentation*/}
        <Route exact path={ROUTES.HELP} component={() => <Help t={t} />} />
        <Route exact path={ROUTES.HOME} component={() => <HomePage t={t} />} />
        <Route
          exact
          path={ROUTES.LANDING}
          component={() => <LandingPage t={t} />}
        />
        {/*Account*/}
        <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route
          exact
          path={ROUTES.PASSWORD_FORGET}
          component={PasswordForgetPage}
        />
        <Route
          exact
          path={ROUTES.ACCOUNT}
          component={() => <AccountPage t={t} />}
        />
        <Route
          exact
          path={ROUTES.FORMATS}
          component={() => <Formats t={t} />}
        />
        {/* Users */}
        <Route exact path={ROUTES.USERS} component={() => <Users t={t} />} />
        <Route
          exact
          path={ROUTES.USERS_NEW}
          component={() => <UserNewItem t={t} />}
        />
        <Route
          exact
          path={ROUTES.USERS_EDIT}
          component={() => <UserEditItem t={t} />}
        />
        {/* Invoices */}
        <Route
          exact
          path={ROUTES.INVOICES}
          component={() => <Invoices t={t} />}
        />
        <Route
          exact
          path={ROUTES.INVOICES_NEW}
          component={() => <InvoiceNewItem t={t} />}
        />
        <Route
          exact
          path={ROUTES.INVOICES_SHOW}
          component={() => <InvoiceItem t={t} />}
        />
        {/* Dashboards */}
        <Route
          exact
          path={ROUTES.DASHBOARDS}
          component={() => <Dashboards t={t} />}
        />
        <Route
          exact
          path={ROUTES.DASHBOARDS_NEW}
          component={() => <DashboardNewItem t={t} />}
        />
        <Route
          exact
          path={ROUTES.DASHBOARDS_EDIT}
          component={() => <DashboardEditItem t={t} />}
        />
        <Route
          exact
          path={ROUTES.DASHBOARDS_REPORTS}
          component={() => <DashboardReports t={t} />}
        />
        {/*Distance*/}
        <Route
          exact
          path={ROUTES.DISTANCES_ONE}
          component={() => <Distances t={t} />}
        />
        <Route
          exact
          path={ROUTES.DISTANCES_MULTIPLE}
          component={() => <DistancesMultiple t={t} />}
        />
        {/*Fuels*/}
        <Route
          exact
          path={ROUTES.FUELS_ONE}
          component={() => <Fuels t={t} />}
        />
        <Route
          exact
          path={ROUTES.FUELS_MULTIPLE}
          component={() => <FuelsMultiple t={t} />}
        />
        {/*Inspections*/}
        <Route
          exact
          path={ROUTES.INSPECTIONS_MULTIPLE}
          component={() => <InspectionsMultiple t={t} />}
        />
        <Route
          exact
          path={ROUTES.INSPECTIONS_PENDING}
          component={() => <InspectionsPending t={t} />}
        />
        <Route
          exact
          path={ROUTES.INSPECTIONS_PENDING_SCRAP}
          component={() => <InspectionsPendingScrap t={t} />}
        />
        <Route
          exact
          path={ROUTES.INSPECTIONS_PENDING_ROTATION}
          component={() => <InspectionsPendingRotation t={t} />}
        />
        <Route
          exact
          path={ROUTES.INSPECTIONS_PENDING_WITHOUT}
          component={() => <InspectionsPendingWithout t={t} />}
        />
        <Route
          exact
          path={ROUTES.INSPECTIONS_PREVIEW}
          component={() => <InspectionsPreview t={t} />}
        />
        <Route
          exact
          path={ROUTES.INSPECTIONS_PREVIEW_BETA}
          component={() => <InspectionsAdvance t={t} />}
        />
        <Route
          exact
          path={ROUTES.INSPECTIONS_PREVIEW_MULTIPLE}
          component={() => <InspectionsPreviewMultiple t={t} />}
        />
        <Route
          exact
          path={ROUTES.INSPECTIONS_PREVIEW_LIST}
          component={() => <InspectionsPreviewList t={t} />}
        />
        {/*Novelties*/}
        <Route
          exact
          path={ROUTES.NOVELTIES_MULTIPLE}
          component={() => <NoveltiesMultiple t={t} />}
        />
        {/*Works*/}
        <Route
          exact
          path={ROUTES.WORKS_VEHICLE}
          component={() => <WorksVehicle t={t} />}
        />
        <Route
          exact
          path={ROUTES.WORKS_VEHICLE_NEW}
          component={() => <WorkVehicleNewItem t={t} />}
        />
        <Route
          exact
          path={ROUTES.WORKS_VEHICLE_EDIT}
          component={() => <WorkVehicleEditItem t={t} />}
        />
        <Route
          exact
          path={ROUTES.WORKS_TIRE}
          component={() => <WorksTire t={t} />}
        />
        <Route
          exact
          path={ROUTES.WORKS_TIRE_NEW}
          component={() => <WorkTireNewItem t={t} />}
        />
        <Route
          exact
          path={ROUTES.WORKS_TIRE_EDIT}
          component={() => <WorkTireEditItem t={t} />}
        />
        <Route
          exact
          path={ROUTES.WORKS_MULTIPLE}
          component={() => <WorkMultiple t={t} />}
        />
        {/*Queries*/}
        <Route
          exact
          path={ROUTES.QUERIES_VEHICLE}
          component={() => <QueriesVehicle t={t} />}
        />
        <Route
          exact
          path={ROUTES.QUERIES_TIRE}
          component={() => <QueriesTire t={t} />}
        />
        <Route
          exact
          path={ROUTES.QUERIES_WORK_TIRE}
          component={() => <QueriesWorkTire t={t} />}
        />
        <Route
          exact
          path={ROUTES.QUERIES_WORK_VEHICLE}
          component={() => <QueriesWorkVehicle t={t} />}
        />
        {/*Indicators*/}
        <Route
          exact
          path={ROUTES.REPORTS_INDICATORS}
          component={() => <ReportsIndicators t={t} />}
        />
        {/*Informs*/}
        <Route
          exact
          path={ROUTES.REPORTS_INFORMS}
          component={() => <ReportsInforms t={t} />}
        />
        {/*InformsInspections*/}
        <Route
          exact
          path={ROUTES.REPORTS_INSPECTIONS}
          component={() => <ReportsInspections t={t} />}
        />
        <Route
          exact
          path={ROUTES.REPORTS_INSPECTIONS_PREASSURE}
          component={() => <ReportsInspectionsPreassure t={t} />}
        />
        {/*Top10*/}
        <Route
          exact
          path={ROUTES.REPORTS_TOP10}
          component={() => <ReportsTop10 t={t} />}
        />
        {/*Performances*/}
        <Route
          exact
          path={ROUTES.REPORTS_PERFORMANCES}
          component={() => <ReportsPerformances t={t} />}
        />
        <Route
          exact
          path={ROUTES.REPORTS_PERFORMANCES_LAST}
          component={() => <ReportsPerformancesLast t={t} />}
        />
        {/*injections*/}
        <Route
          exact
          path={ROUTES.REPORTS_INJECTIONS}
          component={() => <ReportsInjections t={t} />}
        />
        {/*projection*/}
        <Route
          exact
          path={ROUTES.REPORTS_TIRE_PROJECTION}
          component={() => <ReportsTireProjection t={t} />}
        />
        {/*top indicators*/}
        <Route
          exact
          path={ROUTES.REPORTS_TOP_INDICATORS}
          component={() => <ReportsTopIndicators t={t} />}
        />
        {/*dismounts*/}
        <Route
          exact
          path={ROUTES.REPORTS_DISMOUNTS}
          component={() => <ReportsDismount t={t} />}
        />
        {/*Discards*/}
        <Route
          exact
          path={ROUTES.REPORTS_DISCARDS}
          component={() => <ReportsDiscards t={t} />}
        />
        {/*Warehouses*/}
        <Route
          exact
          path={ROUTES.REPORTS_WAREHOUSES}
          component={() => <ReportsWarehouses t={t} />}
        />
        {/*Inventory*/}
        <Route
          exact
          path={ROUTES.REPORTS_INVENTORY}
          component={() => <ReportsInventory t={t} />}
        />
        {/*Stats Tires*/}
        <Route
          exact
          path={ROUTES.REPORTS_STATS_TIRES}
          component={() => <ReportsStatsTires t={t} />}
        />
        {/*Stats Gestion*/}
        <Route
          exact
          path={ROUTES.REPORTS_STATS_GESTION}
          component={() => <ReportsStatsGestion t={t} />}
        />
        {/*Stats Vehicles*/}
        <Route
          exact
          path={ROUTES.REPORTS_STATS_VEHICLES}
          component={() => <ReportsStatsVehicles t={t} />}
        />
        {/*Fullfilment*/}
        <Route
          exact
          path={ROUTES.REPORTS_FULLFILMENT}
          component={() => <ReportsFullfilment t={t} />}
        />
        {/*ErrorsDetection*/}
        <Route
          exact
          path={ROUTES.REPORTS_ERRORS_DETECTION}
          component={() => <ReportsErrorsDetection t={t} />}
        />
        {/*Pressures*/}
        <Route
          exact
          path={ROUTES.REPORTS_PRESSURES}
          component={() => <ReportsPressures t={t} />}
        />
        {/*Shopping*/}
        <Route
          exact
          path={ROUTES.REPORTS_PLAN_SHOPPING}
          component={() => <ReportsShopping t={t} />}
        />
        {/*Tank*/}
        <Route
          exact
          path={ROUTES.REPORTS_PLAN_TANK}
          component={() => <ReportsTank t={t} />}
        />
        {/*Sinisters*/}
        <Route
          exact
          path={ROUTES.REPORTS_SINISTERS}
          component={() => <ReportsSinisters t={t} />}
        />
        {/*Users*/}
        <Route
          exact
          path={ROUTES.USERS_REPORT}
          component={() => <ReportsUsers t={t} />}
        />
        {/* COMPILANCE MONTH */}
        <Route
          exact
          path={ROUTES.COMPILANCE_MONTH_REPORT}
          component={() => <ReportsCompliance t={t} />}
        />
        {/* Tire Tires */}
        <Route
          exact
          path={ROUTES.TIREBRANDS_TIRES}
          component={() => <TireBrandTires t={t} />}
        />
        <Route
          exact
          path={ROUTES.TIREMODELS_TIRES}
          component={() => <TireModelTires t={t} />}
        />
        {/* Retread Tires */}
        <Route
          exact
          path={ROUTES.RETREADBRANDS_TIRES}
          component={() => <RetreadBrandTires t={t} />}
        />
        <Route
          exact
          path={ROUTES.RETREADMODELS_TIRES}
          component={() => <RetreadBandTires t={t} />}
        />
        {/* Plan Tires */}
        <Route
          exact
          path={ROUTES.REPORTS_PLAN_TIRES}
          component={() => <ReportsPlanTires t={t} />}
        />
        <Route
          exact
          path={ROUTES.CUSTOMERSMACRO}
          component={() => <MacroCustomers t={t} />}
        />

        <Provider context={GlobalContext} store={configGlobalStore()}>
          <Provider store={configCustomerStore()}>
            <Switch>
              {/*Crud customers*/}
              <Route
                exact
                path={ROUTES.CUSTOMERS_SHOW}
                component={() => <CustomerItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.CUSTOMERS_NEW}
                component={() => <CustomerNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.CUSTOMERS_EDIT}
                component={() => <CustomerEditItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud brands*/}
          <Provider store={configBrandStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.BRANDS}
                component={() => <Brands t={t} />}
              />
              <Route
                exact
                path={ROUTES.BRANDS_NEW}
                component={() => <BrandNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.BRANDS_EDIT}
                component={() => <BrandEditItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud vehicle types*/}
          <Provider store={configVehicleTypeStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.TYPES}
                component={() => <VehicleTypes t={t} />}
              />
              <Route
                exact
                path={ROUTES.TYPES_NEW}
                component={() => <VehicleTypeNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.TYPES_EDIT}
                component={() => <VehicleTypeEditItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud schemas*/}
          <Provider store={configSchemaStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.SCHEMAS}
                component={() => <Schemas t={t} />}
              />
              <Route
                exact
                path={ROUTES.SCHEMAS_NEW}
                component={() => <SchemaNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.SCHEMAS_EDIT}
                component={() => <SchemaEditItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud tire brands*/}
          <Provider store={configTireBrandStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.TIREBRANDS}
                component={() => <TireBrands t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIREBRANDS_NEW}
                component={() => <TireBrandNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIREBRANDS_EDIT}
                component={() => <TireBrandEditItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIREMODELS_EDIT}
                component={() => <TireModelEditItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIREDIMENSIONS_NEW}
                component={() => <TireDimensionNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIREDIMENSIONS_EDIT}
                component={() => <TireDimensionEditItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud retread brands*/}
          <Provider store={configRetreadBrandStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.RETREADBRANDS}
                component={() => <RetreadBrands t={t} />}
              />
              <Route
                exact
                path={ROUTES.RETREADBRANDS_NEW}
                component={() => <RetreadBrandNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.RETREADBRANDS_EDIT}
                component={() => <RetreadBrandEditItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.RETREADMODELS_EDIT}
                component={() => <RetreadBandEditItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.RETREADDIMENSIONS_NEW}
                component={() => <RetreadDimensionNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.RETREADDIMENSIONS_EDIT}
                component={() => <RetreadDimensionEditItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Tire catalogues*/}
          <Provider store={configTireCatalogueStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.TIRECATALOGUES}
                component={() => <TireCatalogues t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIRECATALOGUES_NEW}
                component={() => <TireCatalogueNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIRECATALOGUES_EDIT}
                component={() => <TireCatalogueEditItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIRECATALOGUES_MULTIPLE}
                component={() => <TireCatalogueMultiple t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIRECATALOGUES_SHOW}
                component={() => <TireCatalogueItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Retread catalogues*/}
          <Provider store={configRetreadCatalogueStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.RETREADCATALOGUES}
                component={() => <RetreadCatalogues t={t} />}
              />
              <Route
                exact
                path={ROUTES.RETREADCATALOGUES_NEW}
                component={() => <RetreadCatalogueNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.RETREADCATALOGUES_EDIT}
                component={() => <RetreadCatalogueEditItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.RETREADCATALOGUES_MULTIPLE}
                component={() => <RetreadCatalogueMultiple t={t} />}
              />
              <Route
                exact
                path={ROUTES.RETREADCATALOGUES_SHOW}
                component={() => <RetreadCatalogueItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud warehouses*/}
          <Provider store={configWarehouseStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.WAREHOUSES}
                component={() => <Warehouses t={t} />}
              />
              <Route
                exact
                path={ROUTES.WAREHOUSES_NEW}
                component={() => <WarehouseNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.WAREHOUSES_EDIT}
                component={() => <WarehouseEditItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud cities*/}
          <Provider store={configCityStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.CITIES}
                component={() => <Cities t={t} />}
              />
              <Route
                exact
                path={ROUTES.CITIES_NEW}
                component={() => <CityNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.CITIES_EDIT}
                component={() => <CityEditItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud cost centers*/}
          <Provider store={configCostCenterStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.COSTCENTERS}
                component={() => <CostCenters t={t} />}
              />
              <Route
                exact
                path={ROUTES.COSTCENTERS_NEW}
                component={() => <CostCenterNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.COSTCENTERS_EDIT}
                component={() => <CostCenterEditItem t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud groups*/}
          <Provider store={configGroupStore()}>
            <Switch></Switch>
          </Provider>
          {/*Crud plans*/}
          <Provider store={configPlanStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.REPORTS_PLANS}
                component={() => <ReportsPlans t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_PLANS_CURRENT}
                component={() => <ReportsPlanCurrent t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_PLANS_SHOW}
                component={() => <ReportsPlanItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_TIRE}
                component={() => <TireVenture t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_VEHICLE}
                component={() => <VehicleVenture t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_INDICATORS}
                component={() => <IndicatorsVenture t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_COMPILANCE}
                component={() => <Compilance t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_MILIMETERS}
                component={() => <MilimetersVentures t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_DISCARDS}
                component={() => <DiscardsVentures t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_YIELDS}
                component={() => <Yields t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_CONSUMPTION}
                component={() => <Consumption t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_CONSOLIDATEDPLAN}
                component={() => <ConsolidatedPlan t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_MTOPLAN}
                component={() => <MtoPlan t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_SHOPPING}
                component={() => <Shopping t={t} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS_VENTURES_EMISSIONS}
                component={() => <Emissions t={t} />}
              />
            </Switch>
          </Provider>
          {/*Crud vehicles*/}
          <Provider store={configVehicleStore()}>
            <Switch>
              <Route
                exact
                path={ROUTES.MACROS_MACRO_ACCOUNT}
                component={() => <MAccount t={t} />}
              />
              <Route
                exact
                path={ROUTES.MACROS_MACRO_ACCOUNT_NEW}
                component={() => <MAccountNew t={t} />}
              />
              <Route
                exact
                path={ROUTES.MACROS_MACRO_ACCOUNT_EDIT}
                component={() => <MAccountEdit t={t} />}
              />
              <Route
                exact
                path={ROUTES.MACROS_MACRO_MACRO_ACCOUNT}
                component={() => <MMAccount t={t} />}
              />
              <Route
                exact
                path={ROUTES.MACROS_MACRO_MACRO_ACCOUNT_NEW}
                component={() => <MMAccountNew t={t} />}
              />
              <Route
                exact
                path={ROUTES.MACROS_MACRO_MACRO_ACCOUNT_EDIT}
                component={() => <MMAccountEdit t={t} />}
              />
              <Route
                exact
                path={ROUTES.MACROS_MACRO_ACCOUNT_VIEW_REPORTS}
                component={() => <Macro t={t} />}
              />
              <Route
                exact
                path={ROUTES.MACROS_MACRO_MACRO_ACCOUNT_VIEW_REPORTS}
                component={() => <MacroMacro t={t} />}
              />
              <Route
                exact
                path={ROUTES.VEHICLES_NEW}
                component={() => <VehicleNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.VEHICLES_EDIT}
                component={() => <VehicleEditItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.VEHICLES_MULTIPLE}
                component={() => <VehicleMultiple t={t} />}
              />
              <Route
                exact
                path={ROUTES.VEHICLES_HISTORY}
                component={() => <VehicleHistoryList t={t} />}
              />
              <Route
                exact
                path={ROUTES.VEHICLES_SHOW}
                component={() => <VehicleItem t={t} />}
              />

              <Route
                exact
                path={ROUTES.VEHICLES_NEW}
                component={() => <VehicleNewItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.VEHICLES_EDIT}
                component={() => <VehicleEditItem t={t} />}
              />
              <Route
                exact
                path={ROUTES.VEHICLES_MULTIPLE}
                component={() => <VehicleMultiple t={t} />}
              />
              <Route
                exact
                path={ROUTES.VEHICLES_HISTORY}
                component={() => <VehicleHistoryList t={t} />}
              />
              <Route
                exact
                path={ROUTES.VEHICLES_SHOW}
                component={() => <VehicleItem t={t} />}
              />

              <Route
                exact
                path={ROUTES.TIRES_MULTIPLE}
                component={() => <TireMultiple t={t} />}
              />
              <Route
                exact
                path={ROUTES.TIRES_HISTORY}
                component={() => <TireHistoryList t={t} />}
              />
            </Switch>
          </Provider>
          {/*Inspections*/}
          <Route
            exact
            path={ROUTES.CUSTOMERS}
            component={() => <Customers t={t} />}
          />
          <Route
            exact
            path={ROUTES.INSPECTIONS_ONE}
            component={() => <Inspections t={t} />}
          />
          <Route
            exact
            path={ROUTES.GROUPS}
            component={() => (
              <Groups
                t={t}
                reducer="groups"
                str=""
                type="REQ_GROUPS_SUCCESS"
                typecreate="CREATE_GROUPS_SUCCESS"
                typeput="PUT_GROUPS_SUCCESS"
                typedelete="DELETE_GROUPS_SUCCESS"
                title=""
              />
            )}
          />
          <Route
            exact
            path={ROUTES.GROUPS_ONE}
            component={() => (
              <Groups
                t={t}
                str="?type_group=group1"
                reducer="groupsOne"
                type="REQ_GROUPS_ONE_SUCCESS"
                typecreate="CREATE_GROUPS_ONE_SUCCESS"
                typeput="PUT_GROUPS_ONE_SUCCESS"
                typedelete="DELETE_GROUPS_ONE_SUCCESS"
                title="1"
              />
            )}
          />
          <Route
            exact
            path={ROUTES.GROUPS_TWO}
            component={() => (
              <Groups
                t={t}
                str="?type_group=group2"
                reducer="groupsTwo"
                type="REQ_GROUPS_TWO_SUCCESS"
                typecreate="CREATE_GROUPS_TWO_SUCCESS"
                typeput="PUT_GROUPS_TWO_SUCCESS"
                typedelete="DELETE_GROUPS_TWO_SUCCESS"
                title="2"
              />
            )}
          />
          <Route
            exact
            path={ROUTES.GROUPS_NEW}
            component={() => <GroupNewItem t={t} />}
          />
          <Route
            exact
            path={ROUTES.GROUPS_EDIT}
            component={() => <GroupEditItem t={t} />}
          />
          <Route
            exact
            path={ROUTES.VEHICLES}
            component={() => <Vehicles t={t} />}
          />
          <Route
            exact
            path={ROUTES.TIRES_EDIT}
            component={() => <TireEditItem t={t} />}
          />
          <Route
            exact
            path={ROUTES.TIRES_NEW}
            component={() => <TireNewItem t={t} />}
          />
          <Route exact path={ROUTES.TIRES} component={() => <Tires t={t} />} />
        </Provider>
      </Switch>
    </BrowserRouter>
  );
};

export default withAuthentication(App);
