import React from "react";
import { AuthUserContext, withAuthorization } from "./Auth/Session";

class Pagination extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <tfoot>
            <tr>
                <td colSpan={this.props.colspan}>
                <nav aria-label="Navigation">
                    <ul class="mt-3 pagination justify-content-center">
                    <li class="page-item">
                    {
                        !!this.props.pageP ?
                        <button
                            className="page-link"
                            onClick={(e) => this.props.submit(this.props.pageP,this.props.limit)}>
                            <i className="fa fa-arrow-left"></i>
                        </button>
                        :
                        <button
                            className="page-link"
                            disabled>
                            <i className="fa fa-arrow-left"></i>
                        </button>
                    }
                    </li>
                    {
                        this.props.links.map((page, index) => {
                        let clase = (page == this.props.page) ? "active" : ""
                        return <li className={"page-item " + clase}>
                                <button class="page-link" onClick={(e) => this.props.submit(page,this.props.limit)}>{page}</button>
                                </li>
                        })
                    }
                    <li class="page-item">
                    {
                        !!this.props.pageN ?
                        <button
                            className="page-link"
                            onClick={(e) => this.props.submit(this.props.pageN,this.props.limit)}>
                            <i className="fa fa-arrow-right"></i>
                        </button>
                        :
                        <button
                            className="page-link"
                            disabled>
                            <i className="fa fa-arrow-right"></i>
                        </button>
                    }
                    </li>
                    <li className="page-item">
                        <div class="btn-group" role="group">
                        <button id="btnGroupDrop1" type="button" class="page-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Mostrando { this.props.limit} items
                        </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <button class="dropdown-item" 
                            onClick={(e) => this.props.submit(1,20)}>Mostrar 20</button>
                            <button class="dropdown-item" 
                            onClick={(e) => this.props.submit(1,50)}>Mostrar 50</button>
                            <button class="dropdown-item" onClick={(e) => this.props.submit(1,100)}>Mostrar 100</button>
                        </div>
                        </div>
                    </li>
                    </ul>
                </nav>
                </td>
            </tr>
        </tfoot>
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(Pagination);
