import moment from "moment";
import "moment/locale/es";
import React, { Component } from "react";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { trackPromise } from "react-promise-tracker";
import $ from "jquery";
import Novelties from "../Novelty";
import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";
import * as VARIABLES from "../../../constants/variables";

const unmountObsList = VARIABLES.UNMOUNT_OBSERVATIONS;
const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const getSuggestionValue = (suggestion) => {
  if (!!suggestion) {
    if (!!suggestion.label) {
      return suggestion.label;
    }
    if (!!suggestion.name || !!suggestion.dimention || !!suggestion.plate) {
      return suggestion;
    }
  }
};

const renderSuggestion = (suggestion) => {
  if (!!suggestion) {
    if (!!suggestion.label) {
      return <div>{suggestion.label}</div>;
    }
    if (!!suggestion.name) {
      return <div>{suggestion.name}</div>;
    }
    if (!!suggestion.dimention) {
      return (
        <div>
          {suggestion.dimention}
          <br />
          {!suggestion.profundidad_original ||
          !suggestion.profundidad_minima ? (
            <i>
              <small>{this.props.t("alerts.ins-one-item-19")}</small>
            </i>
          ) : null}
        </div>
      );
    }
    if (!!suggestion.plate) {
      return <div>{suggestion.plate}</div>;
    }
  }
};

let getInitialState = (props) => {
  let initialState = {
    inspection: {
      placa_vehiculo: null,
    },
    inspectionClone: {},
    bands: [],
    platesSuggestions: [],
    brandsSuggestions: [],
    modelsSuggestions: [],
    dimsSuggestions: [],
    bandsSuggestions: [],
    result: null,
    error: null,
    isNewKmTire: false,
    placa_vehiculo: null,
  };

  let fields = props.fields;
  let obj = props.object;
  let user = props.authUser;

  if (!!obj) {
    initialState.inspection["tire_id"] = obj.id;
  }

  initialState.inspection["precio_del_catalogo"] = "";
  for (let i = 0; i < fields.length; i++) {
    if (!!obj) {
      if (fields[i] === "vehicle_id") {
        if (props.type === "desmonte") {
          initialState.inspection[fields[i]] = "";
        } else {
          initialState.inspection[fields[i]] = obj.vehicle_id;
        }
      } else if (fields[i] === "ubicacion") {
        if (props.type === "desmonte") {
          initialState.inspection[fields[i]] = "";
        }
      } else if (fields[i] === "posicion_montaje") {
        initialState.inspection[fields[i]] = 1 * obj.posicion_montaje;
      } else if (fields[i] === "codigo") {
        initialState.inspection[fields[i]] = obj.codigo;
      } else if (fields[i] === "vida") {
        if (props.type === "reencauche") {
          if (!isNaN(obj.vida)) {
            initialState.inspection[fields[i]] = 1 * obj.vida + 1;
          } else {
            initialState.inspection[fields[i]] =
              obj.vida != null ? obj.vida : "";
          }
        } else {
          initialState.inspection[fields[i]] = obj.vida != null ? obj.vida : "";
        }
      } else if (fields[i] === "marca") {
        initialState.inspection[fields[i]] = obj.marca;
      } else if (fields[i] === "modelo") {
        initialState.inspection[fields[i]] = obj.modelo;
      } else if (fields[i] === "dimension") {
        initialState.inspection[fields[i]] = obj.dimension;
      } else if (fields[i] === "prof_minima") {
        initialState.inspection[fields[i]] =
          obj.prof_minima != null ? obj.prof_minima : "";
      } else if (fields[i] === "prof_original") {
        initialState.inspection[fields[i]] =
          obj.prof_original != null ? obj.prof_original : "";
      } else if (fields[i] === "ult_prof_centro") {
        initialState.inspectionClone[fields[i]] = obj.ult_prof_centro;
        initialState.inspection[fields[i]] = "";
      } else if (fields[i] === "ult_prof_centro_exterior") {
        initialState.inspectionClone[fields[i]] = obj.ult_prof_centro_exterior;
        initialState.inspection[fields[i]] = "";
      } else if (fields[i] === "ult_prof_interior") {
        initialState.inspectionClone[fields[i]] = obj.ult_prof_interior;
        initialState.inspection[fields[i]] = "";
      } else if (fields[i] === "ult_presion") {
        initialState.inspection[fields[i]] =
          obj.ult_presion != null ? obj.ult_presion : "";
      } else {
        initialState.inspection[fields[i]] = "";
      }
    } else {
      if (!!props.use) {
        initialState.inspection.sin_uso = props.use;
      }
      if (!!props.state) {
        initialState.inspection.estado = props.state;
      }
      initialState.inspection[fields[i]] = "";
    }
    if (fields[i] === "analista") {
      initialState.inspection[fields[i]] = user.email;
    }
  }

  for (let i = 0; i < fields.length; i++) {
    if (initialState.inspection[fields[i]] == null) {
      initialState.inspection[fields[i]] = "";
    }
  }

  initialState.inspection.type = props.type;

  if (props.type === "reencauche") {
    initialState.inspection.retreadbrand_id = "";
    initialState.inspection.retreadband_id = "";
    initialState.inspection.retreadimention_id = "";
  }

  if (props.type === "montaje") {
    initialState.inspection.placa_vehiculo = "";
  }
  initialState.inspection.placa_vehiculo = "";

  return initialState;
};

class InspectionHlForm extends Component {
  constructor(props) {
    super(props);

    this.selectedNewModel = {};
    this.selectedNewBand = {};
    this.novelties = React.createRef();
    this.state = { ...getInitialState(props, this.state) };
  }

  getWarehouses() {
    let arr = [];
    this.props.warehouses.forEach((warehouse) =>
      arr.push({ id: warehouse.id, label: warehouse.name })
    );
    let inspection = { ...this.state.inspection };
    //inspection.ubicacion = arr[0].id;
    this.setState({ inspection, bodegas: arr });
  }

  componentDidMount() {
    this.setState({ platesSuggestions: this.props.cars });
    this.getWarehouses();
  }

  componentDidUpdate(prevProps) {}

  getFixedField = (string) => {
    if (typeof string !== "string") return "";
    let newString =
      string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    return newString.replace(/_/g, " ");
  };

  getModels = (brand, brands) => {
    for (let i = 0; i < brands.length; i++) {
      if (brand === brands[i].name) {
        return brands[i].tiremodels;
      }
    }
    return [];
  };

  getDimensions = (model, brand, brands) => {
    let models = this.getModels(brand, brands);
    if (!!model) {
      for (let i = 0; i < models.length; i++) {
        if (model === models[i].name) {
          return models[i].tiredimentions;
        }
      }
    }
    return [];
  };

  getDimension = (dimension, model, brand, brands) => {
    if (!dimension) return null;
    let dimensions = this.getDimensions(model, brand, brands);
    if (!!dimension) {
      for (let i = 0; i < dimensions.length; i++) {
        if (dimension.trim() === dimensions[i].dimention) {
          return dimensions[i];
        }
      }
    }
    return null;
  };

  isInFmtCars = (code, cars) => {
    for (let i = 0; i < cars.length; i++) {
      if (code === cars[i].id) {
        return true;
      }
    }
    return false;
  };

  getCurrentCars = (cars) => {
    let fmtCars = [];

    for (let i = 0; i < cars.length; i++) {
      if (!this.isInFmtCars(cars[i].plate, fmtCars)) {
        fmtCars.push({
          id: "" + cars[i].plate,
          label: "" + cars[i].plate,
        });
      }
    }
    fmtCars.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
    return fmtCars;
  };

  /**
   *---------------------------*
   *---------------------------*
   *----Autosuggests fields----*
   *---------------------------*
   *---------------------------*
   **/

  // TODO: Translate each field to an individual component
  // ---------------Autosuggests for plate field------------------//
  onChangePlate = (event, { newValue }) => {
    let inspection = { ...this.state.inspection };
    if (typeof newValue === "object" && newValue !== null) {
      inspection.placa_vehiculo = newValue.plate;
      inspection.vehicle_id = newValue.id;
    } else {
      let cars = this.props.cars;
      for (let i = 0; i < cars.length; i++) {
        if (
          cars[i].plate.toLowerCase().trim() === newValue.toLowerCase().trim()
        ) {
          inspection.placa_vehiculo = cars[i].plate;
          inspection.vehicle_id = cars[i].id;
          break;
        } else {
          inspection.placa_vehiculo = newValue;
          inspection.vehicle_id = 0;
        }
      }
    }
    this.setState({ inspection });
  };

  getPlatesSuggestions = (value) => {
    let cars = this.props.cars;
    let fmtSuggestions;
    if (!!value) {
      fmtSuggestions = cars.filter((car) => {
        let fmtValue = !!value
          ? car.plate
              .toLowerCase()
              .search(escapeRegexCharacters(value.toLowerCase().trim())) >= 0
          : car.plate;
        return fmtValue;
      });
    } else {
      fmtSuggestions = cars;
    }
    return fmtSuggestions.slice(0, 20);
  };

  onPlatesFetchRequested = ({ value }) => {
    this.setState({ platesSuggestions: this.getPlatesSuggestions(value) });
  };

  onPlatesClearRequested = () => {
    this.setState({ platesSuggestions: [] });
  };

  // ---------------Autosuggest for brand field------------------//
  onChangeBrand = (event, { newValue }) => {
    let inspection = { ...this.state.inspection };
    if (typeof newValue === "object" && newValue !== null) {
      inspection.marca = newValue.name;
      inspection.marca_id = newValue.id;
    } else {
      const brands = this.props.tiresList[this.props.type];
      for (let i = 0; i < brands.length; i++) {
        if (newValue.trim() === brands[i].name) {
          inspection.marca_id = brands[i].id;
          break;
        } else {
          inspection.marca_id = "";
        }
      }
      inspection.marca = newValue;
    }
    this.setState({ inspection });
  };

  getBrandsSuggestions = (value) => {
    let brands = this.props.tiresList[this.props.type];
    return brands.filter((brand) => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return brand.name.toLowerCase().search(fmtValue) >= 0;
    });
  };

  onBrandsFetchRequested = ({ value }) => {
    this.setState({ brandsSuggestions: this.getBrandsSuggestions(value) });
  };

  onBrandsClearRequested = () => {
    this.setState({ brandsSuggestions: [] });
  };

  // ---------------Autosuggest for model field------------------//
  onChangeModel = (event, { newValue }) => {
    let inspection = { ...this.state.inspection };
    if (typeof newValue === "object" && newValue !== null) {
      inspection.modelo = newValue.name;
      inspection.modelo_id = newValue.id;
    } else {
      const brand = this.state.inspection.marca;
      const brands = this.props.tiresList[this.props.type];
      const models = this.getModels(brand, brands);
      for (let i = 0; i < models.length; i++) {
        if (newValue.trim() === models[i].name) {
          inspection.modelo_id = models[i].id;
          break;
        } else {
          inspection.modelo_id = "";
        }
      }
      inspection.modelo = newValue;
    }
    this.setState({ inspection });
  };

  getModelsSuggestions = (value) => {
    let brand = this.state.inspection.marca;
    let brands = this.props.tiresList[this.props.type];
    let models = this.getModels(brand, brands);
    return models.filter((model) => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return model.name.toLowerCase().search(fmtValue) >= 0;
    });
  };

  onModelsFetchRequested = ({ value }) => {
    this.setState({ modelsSuggestions: this.getModelsSuggestions(value) });
  };

  onModelsClearRequested = () => {
    this.setState({ modelsSuggestions: [] });
  };

  // ---------------Autosuggest for dimension field------------------//
  onChangeDimension = (event, { newValue }) => {
    let inspection = { ...this.state.inspection };
    let brand = this.state.inspection.marca;
    let model = this.state.inspection.modelo;
    let brands = this.props.tiresList[this.props.type];
    if (typeof newValue === "object" && newValue !== null) {
      inspection.dimension = newValue.dimention;
      inspection.dimension_id = newValue.id;
      if (this.props.state === "nueva") {
        inspection.prof_original = newValue.profundidad_original;
        inspection.prof_minima = newValue.profundidad_minima;
        if (this.props.use === "nouse") {
          inspection.ult_prof_centro_exterior = newValue.profundidad_original;
          inspection.ult_prof_centro = newValue.profundidad_original;
          inspection.ult_prof_interior = newValue.profundidad_original;
        }
      }
      if (this.props.state === "reencauchada") {
        this.setBandsState(newValue.dimention);
      }
      this.selectedNewModel = this.getSelectedModel(
        brand,
        model,
        newValue.dimention
      );
    } else {
      const dimension = this.getDimension(newValue, model, brand, brands);
      if (!!dimension) {
        if (this.props.state === "nueva") {
          inspection.prof_original = dimension.profundidad_original;
          inspection.prof_minima = dimension.profundidad_minima;
          if (this.props.use === "nouse") {
            inspection.ult_prof_centro_exterior =
              dimension.profundidad_original;
            inspection.ult_prof_centro = dimension.profundidad_original;
            inspection.ult_prof_interior = dimension.profundidad_original;
          }
        }
        if (this.props.state === "reencauchada") {
          this.setBandsState(dimension.dimention);
        }
        inspection.dimension_id = dimension.id;
      } else {
        inspection.dimension_id = "";
        inspection.prof_original = "";
        inspection.prof_minima = "";
        inspection.ult_prof_centro_exterior = "";
        inspection.ult_prof_centro = "";
        inspection.ult_prof_interior = "";
        inspection.Precio_vida_actual = "";
      }
      inspection.dimension = newValue;
    }
    this.setState({ inspection });
  };

  getDimensionsSuggestions = (value) => {
    let brand = this.state.inspection.marca;
    let model = this.state.inspection.modelo;
    let brands = this.props.tiresList[this.props.type];
    let dimensions = this.getDimensions(model, brand, brands);
    return dimensions.filter((dimension) => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return dimension.dimention.toLowerCase().search(fmtValue) >= 0;
    });
  };

  onDimensionsFetchRequested = ({ value }) => {
    this.setState({ dimsSuggestions: this.getDimensionsSuggestions(value) });
  };

  onDimensionsClearRequested = () => {
    this.setState({ dimsSuggestions: [] });
  };

  // ---------------Autosuggest for band field------------------//
  onChangeBand = (event, { newValue }) => {
    let inspection = { ...this.state.inspection };

    const use = inspection.sin_uso;
    if (typeof newValue === "object" && newValue !== null) {
      const dimensions = newValue;
      this.selectedNewBand = dimensions;
      if (use) {
        inspection.ult_prof_centro_exterior = dimensions.profundidad_original;
        inspection.ult_prof_centro = dimensions.profundidad_original;
        inspection.ult_prof_interior = dimensions.profundidad_original;
      }
      inspection.prof_original = dimensions.profundidad_original;
      inspection.prof_minima = dimensions.profundidad_minima;
      inspection.retreadbrand_id = newValue.marca_id;
      inspection.retreadband_id = this.selectedNewBand.modelo_id;
      inspection.retreadimention_id = this.selectedNewBand.dimension_id;
      inspection.nombre_banda_reencauche = newValue.name;
      inspection.precio_del_catalogo = dimensions.precio;
      inspection.retreadcatalogue_id = newValue.id;
    } else {
      let sband = null;
      const band = newValue;
      const bands = this.state.bands;
      for (let i = 0; i < bands.length; i++) {
        if (
          bands[i].marca + " " + bands[i].modelo + " " + bands[i].dimention ===
          band
        ) {
          sband = bands[i];
          const dimensions = bands[i];
          this.selectedNewBand = dimensions;
          if (use) {
            inspection.ult_prof_centro_exterior =
              dimensions.profundidad_original;
            inspection.ult_prof_centro = dimensions.profundidad_original;
            inspection.ult_prof_interior = dimensions.profundidad_original;
          }
          inspection.prof_original = dimensions.profundidad_original;
          inspection.prof_minima = dimensions.profundidad_minima;
          inspection.precio_del_catalogo = dimensions.precio;
          inspection.retreadcatalogue_id = bands[i].id;
          break;
        }
      }
      if (!!sband) {
        inspection.retreadbrand_id = sband.marca_id;
        inspection.retreadband_id = this.selectedNewBand.modelo_id;
        inspection.retreadimention_id = this.selectedNewBand.dimension_id;
      } else {
        inspection.retreadbrand_id = null;
        inspection.retreadband_id = null;
        inspection.retreadimention_id = null;
      }
      inspection.nombre_banda_reencauche = newValue;
    }
    this.setState({ inspection });
  };

  getBandsSuggestions = (value) => {
    //let bands = this.state.bands;
    let bands = this.props.tiresList.catRetread;
    return bands.filter((band) => {
      band.name =
        band.marca.toUpperCase() +
        " " +
        band.modelo.toUpperCase() +
        " " +
        band.dimention.toUpperCase();
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return band.name.toLowerCase().search(fmtValue) >= 0;
    });
  };

  onBandsFetchRequested = ({ value }) => {
    this.setState({ bandsSuggestions: this.getBandsSuggestions(value) });
  };

  onBandsClearRequested = () => {
    this.setState({ bandsSuggestions: [] });
  };

  getIrregularDepths = () => {
    let irrDepths = {
      max_name: "",
      max_value: -Infinity,
      min_name: "",
      min_value: +Infinity,
    };

    let count = 0;
    let areValidDepths = true;
    const depths = [
      "ult_prof_centro_exterior",
      "ult_prof_interior",
      "ult_prof_centro",
    ];
    for (let i = 0; i < depths.length; i++) {
      if (
        isNaN(this.state.inspection[depths[i]]) ||
        !this.state.inspection[depths[i]]
      ) {
        if (++count > 1) {
          areValidDepths = false;
        }
      }
    }

    if (areValidDepths) {
      for (let i = 0; i < depths.length; i++) {
        if (
          !!this.state.inspection[depths[i]] &&
          1 * this.state.inspection[depths[i]] > irrDepths.max_value
        ) {
          irrDepths.max_value = 1 * this.state.inspection[depths[i]];
          irrDepths.max_name = depths[i];
        }
        if (
          !!this.state.inspection[depths[i]] &&
          1 * this.state.inspection[depths[i]] < irrDepths.min_value
        ) {
          irrDepths.min_value = 1 * this.state.inspection[depths[i]];
          irrDepths.min_name = depths[i];
        }
      }
      if (irrDepths.max_value - irrDepths.min_value < 2.5) {
        return null;
      }
    } else {
      return null;
    }

    return irrDepths;
  };

  getClassValidDepths = (type, field, previousValue, currentValue) => {
    const depths = [
      "ult_prof_centro_exterior",
      "ult_prof_interior",
      "ult_prof_centro",
    ];
    if (depths.indexOf(field) > -1) {
      if (
        type !== "reencauche" &&
        type !== "regrabado" &&
        previousValue < currentValue
      ) {
        return "form-control border border-danger";
      }
      const irrDepths = this.getIrregularDepths();
      if (!!irrDepths) {
        if (irrDepths.max_name === field || irrDepths.min_name === field) {
          return "form-control border border-warning";
        }
      }
    }
    return "form-control";
  };

  // DOM Fields
  getFieldDOM = (field, required, readonly) => {
    const brandProps = {
      placeholder: this.props.t("alerts.ins-one-item-20"),
      value: this.state.inspection[field],
      className: "form-control",
      onChange: this.onChangeBrand,
      required: required,
    };

    const modelProps = {
      placeholder: this.props.t("alerts.ins-one-item-21"),
      value: this.state.inspection[field],
      className: "form-control",
      onChange: this.onChangeModel,
      required: required,
    };

    const dimensionProps = {
      placeholder: this.props.t("alerts.ins-one-item-22"),
      value: this.state.inspection[field],
      className: "form-control",
      onChange: this.onChangeDimension,
      required: required,
    };

    const bandProps = {
      placeholder: this.props.t("ins-one.ins-one-hl-form-band"),
      value: this.state.inspection[field],
      className: "form-control",
      onChange: this.onChangeBand,
      required: required,
    };

    const plateProps = {
      placeholder: this.props.t("alerts.ins-one-item-24"),
      value: this.state.inspection.placa_vehiculo,
      className: "form-control",
      onChange: this.onChangePlate,
      required: required,
    };

    if (field === "marca" && this.props.type === "reencauche") {
      return (
        <Autosuggest
          suggestions={this.state.brandsSuggestions}
          onSuggestionsFetchRequested={this.onBrandsFetchRequested}
          onSuggestionsClearRequested={this.onBrandsClearRequested}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={brandProps}
        />
      );
    } else if (field === "modelo" && this.props.type === "reencauche") {
      return (
        <Autosuggest
          suggestions={this.state.modelsSuggestions}
          onSuggestionsFetchRequested={this.onModelsFetchRequested}
          onSuggestionsClearRequested={this.onModelsClearRequested}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={modelProps}
        />
      );
    } else if (field === "dimension" && this.props.type === "reencauche") {
      return (
        <Autosuggest
          suggestions={this.state.dimsSuggestions}
          onSuggestionsFetchRequested={this.onDimensionsFetchRequested}
          onSuggestionsClearRequested={this.onDimensionsClearRequested}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={dimensionProps}
        />
      );
    } else if (field === "nombre_banda_reencauche") {
      return (
        <Autosuggest
          suggestions={this.state.bandsSuggestions}
          onSuggestionsFetchRequested={this.onBandsFetchRequested}
          onSuggestionsClearRequested={this.onBandsClearRequested}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={bandProps}
        />
      );
    } else if (field === "ubicacion" && this.props.type === "reencauche") {
      return (
        <select
          className="form-control width"
          id="warehouseInput"
          name={field}
          value={this.state.inspection[field]}
          disabled={
            this.state.inspection["nombre_banda_reencauche"] === ""
              ? true
              : false
          }
          onChange={this.onChange}
          required={required}
        >
          <option value="">{this.props.t("ins-one.ins-one-vl-form-2")}</option>
          {!!this.props.warehouses &&
            this.props.warehouses.map((option) => {
              if (option.name !== "descarte") {
                return (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                );
              }
            })}
        </select>
      );
    } else if (field === "vehicle_id" && this.props.type === "montaje") {
      return (
        <Autosuggest
          suggestions={this.state.platesSuggestions}
          onSuggestionsFetchRequested={this.onPlatesFetchRequested}
          onSuggestionsClearRequested={this.onPlatesClearRequested}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={plateProps}
        />
      );
    } else if (field === "Observaciones" && this.props.type === "desmonte") {
      return (
        <select
          className="form-control"
          id={field}
          name={field}
          value={this.state.inspection[field]}
          onChange={this.onChange}
          required={required}
        >
          <option value="">{this.props.t("ins-one.ins-one-vl-form-5")}</option>
          {unmountObsList.map((observation) => {
            return (
              <option key={observation.name} value={observation.name}>
                {observation.name}
              </option>
            );
          })}
        </select>
      );
    } else {
      let type = "";
      if (
        field === "ult_prof_centro_exterior" ||
        field === "ult_prof_centro" ||
        field === "ult_prof_interior" ||
        field === "Precio_vida_actual" ||
        field === "prof_original" ||
        field === "prof_minima" ||
        field === "posicion_montaje" ||
        field === "kilometers" ||
        field === "precio_reencauche" ||
        field === "precio_regrabado"
      ) {
        type = "number";
      } else if (field === "fc_ultima_inspeccion") {
        type = "datetime-local";
      } else {
        type = "text";
      }
      if (
        field === "precio_reencauche" &&
        this.props.type !== "reencauche" &&
        this.props.type !== "regrabado"
      ) {
        return null;
      }
      let placeholder = "";
      if (
        field === "ult_prof_centro_exterior" ||
        field === "ult_prof_centro" ||
        field === "ult_prof_interior"
      ) {
        if (this.props.type === "montaje") {
          required = false;
        }
        placeholder = this.state.inspectionClone[field];
      } else if (field === "ult_presion") {
        placeholder = this.state.inspectionClone[field];
      } else {
        placeholder = this.getFixedField(field);
      }
      if (field === "precio_reencauche" && this.props.type === "reencauche") {
        placeholder = this.props.t("ins-one.ins-one-hl-form-retread-price");
      }
      if (field === "kilometers") {
        return (
          <div className="input-group">
            <input
              name="kilometers"
              type="number"
              id="kilometers"
              className="form-control"
              readOnly=""
              onChange={this.onChange}
              value={this.state.inspection.kilometers}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary text-white"
                type="button"
                data-toggle="modal"
                data-target="#last10KmTire"
              >
                <i className="fa fa-chart-line"></i>{" "}
                {this.props.t("ins-one.ins-one-hl-form-1")}
              </button>
              <div
                className="modal fade"
                id="last10KmTire"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="last10KmTireLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        id="last10KmTireLabel"
                        className="modal-title text-dark"
                      >
                        {this.props.t("ins-one.ins-one-hl-form-2")}
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Cerrar"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body text-dark">
                      {this.state.isNewKmTire === false ? (
                        <div>
                          <button
                            type="button"
                            onClick={(e) =>
                              this.setState({ isNewKmTire: true })
                            }
                            className="btn btn-primary float-right"
                          >
                            {this.props.t("ins-one.ins-one-hl-form-3")}
                          </button>
                          <table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>
                                  {this.props.t("ins-one.ins-one-hl-form-4")}
                                </th>
                                <th>
                                  {this.props.t("ins-one.ins-one-hl-form-5")}
                                </th>
                                <th>
                                  {this.props.t("ins-one.ins-one-hl-form-6")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.kms.map((fuel, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{fuel.kilometers}</td>
                                    <td>
                                      {moment
                                        .utc(fuel.fecha_inspeccion)
                                        .local()
                                        .locale("es")
                                        .format("LLL")}
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        className="btn btn-primary"
                                        onClick={(e) =>
                                          this.chooseKmTire(fuel.kilometers)
                                        }
                                      >
                                        <i className="fa fa-link"></i>{" "}
                                        {this.props.t(
                                          "ins-one.ins-one-hl-form-7"
                                        )}
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div>
                          <button
                            type="button"
                            onClick={(e) =>
                              this.setState({ isNewKmTire: false })
                            }
                            className="btn btn-primary float-right"
                          >
                            {this.props.t("ins-one.ins-one-hl-form-8")}
                          </button>
                          <br />
                          <br />
                          <fieldset>
                            <div className="row form-group">
                              <div className="col-sm-2">
                                <label htmlFor="newKmTireInput">
                                  {this.props.t("ins-one.ins-one-hl-form-9")}
                                </label>
                              </div>
                              <div className="col-sm-10">
                                <input
                                  name="km"
                                  type="text"
                                  id="newKmTireInput"
                                  className="form-control"
                                  placeholder={this.props.t(
                                    "ins-one.ins-one-hl-form-9"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="row form-group">
                              <div className="col-sm-2">
                                <label htmlFor="newDateTireInput">
                                  {this.props.t("ins-one.ins-one-hl-form-10")}
                                </label>
                              </div>
                              <div className="col-sm-10">
                                <input
                                  name="date"
                                  type="datetime-local"
                                  id="newDateTireInput"
                                  className="form-control"
                                  placeholder={this.props.t(
                                    "ins-one.ins-one-hl-form-10"
                                  )}
                                />
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={(e) => this.registerNewKmTire(e)}
                              className="btn btn-primary float-right"
                            >
                              {this.props.t("ins-one.ins-one-hl-form-11")}
                            </button>
                          </fieldset>
                        </div>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        aria-label="Cerrar"
                      >
                        {this.props.t("ins-one.ins-one-hl-form-12")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (field === "precio_catalogo" || field === "sin_uso") {
        return (
          <input
            name={field}
            disabled={
              this.state.inspection["nombre_banda_reencauche"] === ""
                ? true
                : false
            }
            id={field}
            type="checkbox"
            checked={this.state.inspection[field]}
            onChange={this.onChangeCheckbox}
          />
        );
      } else if (field === "order") {
        return (
          <input
            name={field}
            id={field}
            type={type}
            required={required}
            value={this.state.inspection[field]}
            className={this.getClassValidDepths(
              this.props.type,
              field,
              this.state.inspectionClone[field],
              this.state.inspection[field]
            )}
            onChange={this.onChange}
            placeholder={this.props.t(`ins-one.ins-one-hl-form-${field}`)}
            readOnly={readonly}
          />
        );
      } else {
        return (
          <input
            name={field}
            id={field}
            type={type}
            required={required}
            value={this.state.inspection[field]}
            className={this.getClassValidDepths(
              this.props.type,
              field,
              this.state.inspectionClone[field],
              this.state.inspection[field]
            )}
            onChange={this.onChange}
            placeholder={placeholder}
            readOnly={readonly}
          />
        );
      }
    }
  };

  getKilometerId = (km, fecha) => {
    if (km !== null) {
      let kms = this.props.kms;
      for (let i = 0; i < kms.length; i++) {
        if (
          kms[i].kilometers.toString().trim() === km.toString().trim() &&
          kms[i].fecha_inspeccion.trim().substring(0, 10) ===
            fecha.trim().substring(0, 10)
        ) {
          return kms[i].id;
        }
      }
    }
    return null;
  };

  registerNewKmTire = (event) => {
    event.preventDefault();
    let kmsArr = this.props.kms;
    let data1 = {
      kilometers: document.getElementById("newKmTireInput").value,
      fecha_subida: moment().utc().format(),
      fecha_inspeccion: moment(
        document.getElementById("newDateTireInput").value
      )
        .utc()
        .format(),
    };
    fetch(
      APIS.API_REST_FUELS.replace(
        "customer_id",
        localStorage.getItem("GlobalCustomerId")
      ).replace("vehicle_id", this.props.vehicle_id),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
        },
        method: "POST",
        body: JSON.stringify(data1),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (!!json.message) {
          toast.error(json.message);
          //throw new Error(json.message);
        } else {
          kmsArr.push({ ...data1, id: json.id });
          toast.success(this.props.t("alerts.ins-one-item-25"));
          this.setState({ isNewKmTire: false, combustibles: kmsArr });
          let inspec = { ...this.state.inspection };
          inspec["kilometers"] =
            document.getElementById("newKmTireInput").value;
          this.setState({ inspec });
          $("#last10KmTire").modal("hide");
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        //throw new Error(error);
      });
  };

  chooseKmTire(kilometers) {
    $("#last10KmTire").modal("hide");
    let inspection = { ...this.state.inspection };
    inspection["kilometers"] = kilometers;
    this.setState({ inspection });
  }

  getDynamicForm = (fields) => {
    let state = this.props.state;
    let display = "block";
    return (
      <div>
        {fields.map((field) => {
          let required = true;
          let readonly = false;
          if (
            field === "ult_presion" ||
            field === "Observaciones" ||
            field === "novedades" ||
            field === "obsevaciones_analista" ||
            field === "order"
          ) {
            required = false;
          }
          if (this.props.type !== "montaje" && field === "posicion_montaje") {
            return null;
          }
          if (this.props.type === "regrabado" && field === "vida") {
            return null;
          }
          if (this.props.type === "reencauche" && field === "vida") {
            readonly = true;
          }
          if (this.props.type === "desmonte") {
            if (field === "posicion_montaje") {
              required = false;
            }
          }
          if (
            field === "marca" ||
            field === "modelo" ||
            field === "dimension"
          ) {
            return null;
          }
          if (field === "codigo") {
            return null;
          }
          if (this.props.type !== "montaje" && field === "vehicle_id") {
            return null;
          }
          if (
            this.props.type === "montaje" &&
            (field === "ult_prof_centro_exterior" ||
              field === "ult_prof_centro" ||
              field === "ult_prof_interior")
          ) {
            required = false;
            readonly = true;
          }
          if (
            this.props.type !== "reencauche" &&
            field === "nombre_banda_reencauche"
          ) {
            return null;
          }
          if (field === "diseno") {
            return null;
          }
          if (field === "analista") {
            return null;
          }
          let label = "";
          let newField = this.getFixedField(field);
          if (field === "Observaciones" && this.props.type === "desmonte") {
            label = "Acción";
          } else if (field === "codigo") {
            label = "Número de calor";
          } else if (field === "vehicle_id") {
            label = "Vehículo";
          } else if (field === "order") {
            label = this.props.t(`ins-one.ins-one-hl-form-${field}`);
          } else {
            label = newField;
          }
          if (
            field === "ult_prof_interior" ||
            field === "ult_prof_centro" ||
            field === "ult_prof_centro_exterior"
          ) {
            label = newField + " *";
            required = true;
          }
          if (field === "prof_original" && this.props.type === "reencauche") {
            readonly = true;
          }
          if (field === "fc_ultima_inspeccion") {
            label = this.props.t(`ins-one.ins-one-hl-form-fc`);
          }
          if (field === "ubicacion") {
            label = this.props.t(`ins-one.ins-one-hl-form-warehouse`);
          }
          if (field === "nombre_banda_reencauche") {
            label = this.props.t(`ins-one.ins-one-hl-form-band`);
          }
          if (field === "prof_original") {
            label = this.props.t(`ins-one.ins-one-hl-form-original`);
          }
          if (field === "prof_minima") {
            label = this.props.t(`ins-one.ins-one-hl-form-minimum`);
          }
          if (field === "ult_prof_centro_exterior") {
            label = this.props.t(`ins-one.ins-one-hl-form-center-outside`);
          }
          if (field === "ult_prof_interior") {
            label = this.props.t(`ins-one.ins-one-hl-form-inside`);
          }
          if (field === "ult_prof_centro") {
            label = this.props.t(`ins-one.ins-one-hl-form-center`);
          }
          if (field === "ult_presion") {
            label = this.props.t(`ins-one.ins-one-hl-form-pressure`);
          }
          if (field === "vida") {
            label = this.props.t(`ins-one.ins-one-hl-form-life`);
          }
          if (field === "precio_catalogo") {
            label = this.props.t(`ins-one.ins-one-hl-form-catalog-price`);
          }
          if (field === "precio_reencauche") {
            label = this.props.t(`ins-one.ins-one-hl-form-retread-price`);
          }
          if (field === "sin_uso") {
            label = this.props.t(`ins-one.ins-one-hl-form-unused`);
          }
          if (field === "tecnico") {
            required = false;
          }
          if (
            (this.props.type === "reencauche" ||
              this.props.type === "regrabado") &&
            field === "kilometers"
          ) {
            return null;
          }
          if (
            (this.props.type === "reencauche" ||
              this.props.type === "regrabado") &&
            field === "tecnico"
          ) {
            return null;
          }
          if (
            (field === "precio_reencauche" || field === "precio_regrabado") &&
            this.props.type !== "reencauche" &&
            this.props.type !== "regrabado"
          ) {
            return null;
          }
          return (
            <div
              style={{ display: display }}
              key={field}
              className="form-group"
            >
              <div className="row">
                <div className="col-md-2">
                  <label htmlFor={field}>{label}</label>
                </div>
                <div className="col-md-10">
                  {this.getFieldDOM(field, required, readonly)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  getDynamicFormDiscard = (fields) => {
    let state = this.props.state;
    let display = "block";
    return (
      <div>
        {fields.map((field) => {
          if (field == "order") {
            let required = true;
            let readonly = false;
            let label = "";
            if (field === "order") {
              required = false;
            }
            if (field === "order") {
              label = "Numero de factura";
            }
            return (
              <div
                style={{ display: display }}
                key={field}
                className="form-group"
              >
                <div className="row">
                  <div className="col-md-2">
                    <label htmlFor={field}>{label}</label>
                  </div>
                  <div className="col-md-10">
                    {this.getFieldDOM(field, required, readonly)}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  formatJson = (json) => {
    for (let key in json) {
      if (json[key] === "") {
        json[key] = null;
      }
      if (key === "ult_presion" && !isNaN(json[key]) && json[key] != null) {
        json[key] = "" + parseFloat(json[key]).toFixed(1);
      }
      if (
        json[key] != null &&
        !isNaN(json[key]) &&
        json[key].toString().indexOf(".") !== -1
      ) {
        json[key] = parseFloat(json[key]);
      }
    }
    return json;
  };

  getFmtNovelties = (novelties) => {
    let fmtNovelties = [];

    for (let i = 0; i < novelties.length; i++) {
      fmtNovelties.push({
        seccion_llanta: novelties[i].seccion.split("|")[1],
        motivo: novelties[i].novedad.split("|")[1],
        ligado_a: novelties[i].novedad.split("|")[2],
        codigo_causa: novelties[i].causa.split("|")[0],
        posible_causa: novelties[i].causa.split("|")[1],
      });
    }

    return fmtNovelties;
  };

  getFmtdJsonNovelties = (data) => {
    const state = this.novelties.current.state;

    const images = state.insImages;
    const noveltyType = state.noveltyType;
    const fecha_subida = moment(state.fecha_subida).utc().format();
    const DOT = state.DOT;
    const sinester = state.sinester;
    const journey = state.journey;
    const operator = state.operator;
    const novelties = this.getFmtNovelties(state.insNovelties);

    if (!noveltyType || novelties.length === 0) return null;

    return {
      //fecha_subida: moment().utc().format(),
      fecha_subida: fecha_subida,
      tiponovedad: noveltyType,
      DOT: DOT,
      inspector: data.tecnico,
      customer_id: this.props.customerId,
      noveltys: novelties,
      pictures: images,
      sinester: sinester,
      journey: journey,
      operator: operator,
    };
  };

  saveNovelties = async (data) => {
    let fmtNovelties = this.getFmtdJsonNovelties(data);
    if (!fmtNovelties) return Promise.resolve(false);
    if (this.props.type === "descarte") {
      fmtNovelties.inspector = this.props.authUser.email;
    }
    const response = await fetch(
      `${APIS.API_REST_NOVELTIES_TIRE.replace(
        "customer_id",
        localStorage.getItem("GlobalCustomerId")
      ).replace("tire_id", data.tire_id)}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "POST",
        body: JSON.stringify(fmtNovelties),
      }
    );

    if (!response.ok) {
      const responseMessage = await response.json();
      toast.error(responseMessage.message);
      throw new Error(responseMessage.message);
    }

    const responseJson = await response.json();
    return responseJson.novedades[0].id;
  };

  runValidations = (json) => {
    if (!!json.message) {
      if (json.message === "Fallo La Validacion") {
        let msg = "";
        Object.keys(json.resp).forEach((key) => {
          if (!json.resp[key][1]) {
            msg = msg + json.resp[key][0] + "\n";
          }
        });
        throw new Error(msg);
      } else {
        throw new Error(json.message);
      }
    }
  };

  onSubmitInspection = (data, authUser) => {
    this.props.firebaseUser.doGenerateToken().then(function (idToken) {
      localStorage.setItem("firebaseAuthToken", idToken);
    });
    if (!!data.ubicacion) {
      data.bodega_id = data.ubicacion;
    }
    let data1 = {
      kilometers: data.kilometers,
      fecha_subida: moment().utc().format(),
      fecha_inspeccion: data.fc_ultima_inspeccion,
    };
    if (this.props.type === "descarte") {
      data.type = "inspeccion";
    }
    let code;
    data.km_id = this.getKilometerId(
      data.kilometers,
      data.fc_ultima_inspeccion
    );
    this.props.onSubmitTire();
    this.saveNovelties(data).then((noveltiesId) => {
      if (this.props.type !== "descarte") {
        return trackPromise(
          fetch(
            APIS.API_REST_INSPECTIONS.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            ).replace("tire_id", data.tire_id),
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
              method: "POST",
              body: JSON.stringify({
                ...data,
                novedades_id: !!noveltiesId ? noveltiesId : null,
                observaciones: [
                  {
                    observaciones_analista: data.obsevaciones_analista,
                    observaciones: data.Observaciones,
                  },
                ],
              }),
            }
          )
            .then((response) => response.json())
            .then((json) => {
              this.runValidations(json);
              //this.props.getTires();
              this.props.onSubmitTire();
              this.setState({ ...getInitialState(this.props) });
              toast.success(this.props.t("alerts.ins-one-item-27"));
            })
            .catch((error) => {
              let err = error.message;
              if (valConnection.indexOf(error.toString()) > -1) {
                err = this.props.t("alerts.ins-one-item-28");
              }
              toast.error(err.toString());
            })
        );
      } else {
        this.props.onSubmitTire();
        this.setState({ ...getInitialState(this.props) });
        toast.success(this.props.t("alerts.ins-one-item-27"));
      }
    });
  };

  getInitialStateCreate = (initialState) => {
    initialState.inspection.codigo = "";
    initialState.inspection.marca = "";
    initialState.inspection.marca_id = "";
    initialState.inspection.diseno = "";
    initialState.inspection.diseno_id = "";
    initialState.inspection.dimension = "";
    initialState.inspection.dimension_id = "";
    initialState.inspection.ult_prof_centro_exterior = "";
    initialState.inspection.ult_prof_interior = "";
    initialState.inspection.ult_prof_centro = "";
    initialState.inspection.order = "";

    return initialState;
  };

  onSubmitTire = (data) => {
    const createData = {
      fc_ultima_inspeccion: data.fc_ultima_inspeccion,
      customer_id: data.customer_id,
      marca_id: data.marca_id,
      modelo_id: data.modelo_id,
      dimension_id: data.dimension_id,
      prof_original: data.prof_original,
      prof_minima: data.prof_minima,
      ult_prof_interior: data.ult_prof_interior,
      ult_prof_centro: data.ult_prof_centro,
      ult_prof_centro_exterior: data.ult_prof_centro_exterior,
      retreadband_id: data.retreadband_id,
      retreadbrand_id: data.retreadbrand_id,
      retreadimention_id: data.retreadimention_id,
      tirecatalogue_id: data.tirecatalogue_id,
      retreadcatalogue_id: data.retreadcatalogue_id,
      inspector: data.tecnico,
      order: data.order,
    };

    trackPromise(
      fetch(
        `${APIS.API_REST_TIRES.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "POST",
          body: JSON.stringify({
            ...createData,
            ...this.getFmtdJsonNovelties(data),
          }),
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.runValidations(json);
          //this.props.getTires();
          toast.success(this.props.t("alerts.ins-one-item-29"));
          this.setState(
            this.getInitialStateCreate({ ...getInitialState(this.props) })
          );
        })
        .catch((error) => {
          toast.error(error.message.toString());
        })
    );
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (isInternetConnected(toast)) return;

    const inspection = { ...this.state.inspection };
    inspection.fc_ultima_inspeccion = moment(inspection.fc_ultima_inspeccion)
      .utc()
      .format();
    const data = {
      ...this.formatJson(inspection),
      customer_id: !!this.props.object
        ? this.props.object.customer_id
        : this.props.customerId,
    };
    this.onSubmitInspection(data);
  };

  setBandsState = (dimension) => {
    let bands = [];
    let brands = this.props.tiresList.reencauche;
    for (let i = 0; i < brands.length; i++) {
      let designs = brands[i].retreadbands;
      for (let j = 0; j < designs.length; j++) {
        let dimensions = designs[j].retreadimentions;
        for (let k = 0; k < dimensions.length; k++) {
          if (dimension === dimensions[k].dimention) {
            bands.push({
              id: designs[j].id,
              name:
                brands[i].name +
                " " +
                designs[j].name +
                " " +
                dimensions[k].dimention,
              retreadbrand_id: brands[i].id,
              retreadimentions: designs[j].retreadimentions,
            });
          }
        }
      }
    }
    this.setState({ bands });
  };

  getSelectedModel = (brand, model, dimension) => {
    let brands = this.props.tiresList.Registrar;
    for (let i = 0; i < brands.length; i++) {
      if (brands[i].marca === brand) {
        let designs = brands[i].disenos;
        for (let j = 0; j < designs.length; j++) {
          if (designs[j].diseno === model) {
            let dimensions = designs[j].dimensiones;
            for (let k = 0; k < dimensions.length; k++) {
              if (dimensions[k].dimension === dimension) {
                return dimensions[k];
              }
            }
          }
        }
      }
    }
    return null;
  };

  onChange = (event) => {
    let use = this.props.use;
    let state = this.props.state;
    let inspection = { ...this.state.inspection };

    inspection[event.target.name] = event.target.value;
    if (
      use === "use" &&
      (event.target.name === "ult_prof_centro_exterior" ||
        event.target.name === "ult_prof_interior" ||
        event.target.name === "ult_prof_centro")
    ) {
      let ext =
        event.target.name === "ult_prof_centro_exterior"
          ? event.target.value
          : 1 * this.state.inspection.ult_prof_centro_exterior;
      let cen =
        event.target.name === "ult_prof_centro"
          ? event.target.value
          : 1 * this.state.inspection.ult_prof_centro;
      let int =
        event.target.name === "ult_prof_interior"
          ? event.target.value
          : 1 * this.state.inspection.ult_prof_interior;
      let min = Math.min(ext, cen, int);
      let model =
        state === "nueva" ? this.selectedNewModel : this.selectedNewBand;
      if (!!model) {
        let newPrice = model.costomm * min;
        inspection.Precio_vida_actual = newPrice;
      } else {
        inspection.Precio_vida_actual = "";
      }
    }
    if (event.target.name === "ubicacion") {
      const warehouse = this.props.warehouses.filter(
        (item) => item.id === Number(event.target.value)
      )[0];
      const check = warehouse && warehouse.name === "llanta_nueva";
      if (warehouse && warehouse.name === "llanta_nueva") {
        inspection.ult_prof_centro = inspection.prof_original;
        inspection.ult_prof_centro_exterior = inspection.prof_original;
        inspection.ult_prof_interior = inspection.prof_original;
      } else {
        inspection.ult_prof_centro = "";
        inspection.ult_prof_centro_exterior = "";
        inspection.ult_prof_interior = "";
      }
      document.getElementById("ult_prof_centro_exterior").disabled = check;
      document.getElementById("ult_prof_centro").disabled = check;
      document.getElementById("ult_prof_interior").disabled = check;
    }
    this.setState({
      inspection,
    });
  };

  onChangeCheckbox = (event) => {
    let inspection = { ...this.state.inspection };
    if (event.target.name === "precio_catalogo") {
      if (event.target.checked) {
        inspection.precio_reencauche = inspection.precio_del_catalogo;
      } else {
        inspection.precio_reencauche = "";
      }
    } else {
      let arr = Object.values(
        document.getElementById("warehouseInput").options
      );
      if (event.target.checked) {
        inspection.ult_prof_centro_exterior = inspection.prof_original;
        inspection.ult_prof_centro = inspection.prof_original;
        inspection.ult_prof_interior = inspection.prof_original;
        inspection.ubicacion = arr.find(
          (element) => element.text === "llanta_nueva"
        ).value;
      } else {
        inspection.ult_prof_centro_exterior = "";
        inspection.ult_prof_centro = "";
        inspection.ult_prof_interior = "";
        inspection.ubicacion = "";
      }
      document.getElementById("warehouseInput").disabled = event.target.checked;
      document.getElementById("ult_prof_centro_exterior").disabled =
        event.target.checked;
      document.getElementById("ult_prof_centro").disabled =
        event.target.checked;
      document.getElementById("ult_prof_interior").disabled =
        event.target.checked;
    }
    inspection[event.target.name] = event.target.checked;
    this.setState({
      inspection,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    //this.setState({platesSuggestions: this.props.cars})
  }

  render() {
    const { fields, state, type } = this.props;
    const { inspection, tiresList } = this.state;

    if (this.props.type === "descarte") {
      if (!!this.novelties.current) {
        let state = this.novelties.current.state;
        state.noveltyType = "Desecho";
      }
    } else {
      if (!!this.novelties.current) {
        let state = this.novelties.current.state;
        state.noveltyType = "Novedad";
      }
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title text-center">
                {this.props.t("ins-one.ins-one-hl-form-13")}
              </h6>
              <form onSubmit={(event) => this.onSubmit(event)}>
                {this.props.type !== "descarte"
                  ? this.getDynamicForm(fields)
                  : null}
                <div className="row mt-5 mb-5">
                  <div className="col-md-12 text-center">
                    {this.props.type !== "descarte" ? (
                      <button
                        type="button"
                        className="btn btn-primary text-white"
                        data-toggle="modal"
                        onClick={() => {
                          $("#posicionNovelties1").modal();
                          $("#noveltyTypePos1 option[value=Desecho]").hide();
                          $("#DOT1").hide();
                          $("#noveltyTypePos1 option[value=Novedad]").show();
                          $(
                            "#noveltyTypePos1 option[value=Antecedente]"
                          ).show();
                          $("#noveltyTypePos1").val("Novedad");
                          let state = this.novelties.current.state;
                          state.noveltyType = "Novedad";
                        }}
                      >
                        <i className="fa fa-bullhorn"></i>{" "}
                        {this.props.t("ins-one.ins-one-hl-form-14")}
                      </button>
                    ) : null}
                    <Novelties
                      t={this.props.t}
                      ref={this.novelties}
                      records={this.props.records}
                      waste={this.props.waste}
                      novelties={this.props.novelties}
                      movements={this.props.movements}
                      onlyNolveties={true}
                      posicion={1}
                      codigo={this.state.inspection.codigo}
                    />
                  </div>
                </div>
                {fields.length > 0 ? (
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary text-white"
                      >
                        <i className="fa fa-save"></i>{" "}
                        {this.props.type === "descarte"
                          ? this.props.t("ins-one.ins-one-hl-form-15")
                          : this.props.t("ins-one.ins-one-hl-form-16")}
                      </button>
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InspectionHlForm;
