import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsTireCatalogue
  from "../../../constants/actions/TireCatalogues";
import * as APIS from "../../../constants/apis";

function onSubmitTireCatalogue(event, state, catalogues, catalogue,
  history, t) {
  event.preventDefault();
  return dispatch => {
    let data = {
      customer_id: localStorage.getItem("GlobalCustomerId"),
      ...state,
    };
    let dataDimention;
    if (!state.custom_brands && !state.custom_bands && !state.custom_dimentions) {
      data.marca_id = state.marca.split("|")[0];
      data.modelo_id = state.modelo.split("|")[0];
      trackPromise(fetch(`${APIS.API_REST_TIRECATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "POST",
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(json => {
          const status = json.message;
          if (!status) {
            history.push("/tirecatalogues");
            dispatch(getReqSuccess(catalogues, catalogue,
              t("alerts.catalogue-item-2")));
          } else {
            if (!!status.marca_id) {
              throw new Error(t("alerts.retread-item-6"));
            } else if (!!status.modelo_id) {
              throw new Error(t("alerts.retread-item-7"));
            } else if (!!status.dimension_id) {
              throw new Error(t("alerts.retread-item-8"));
            } else {
              throw new Error(status);
            }
          }
        })
        .catch(error => {
          toast.error(error.toString());
        }));
    } else {
      if (state.custom_brands) {
        trackPromise(fetch(`${APIS.API_REST_CUSTOMER_TIREBRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "POST",
          body: JSON.stringify({ name: state.marca })
        })
          .then(response => {
            if (response.status !== 201) {
              throw new Error(t("alerts.retread-item-9"));
            }
            return response.json();
          })
          .then(json => {
            data.marca_id = json.id;
            return fetch(`${APIS.API_REST_CUSTOMER_TIREMODELS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))
              .replace("tirebrand_id", json.id)}`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
              method: "POST",
              body: JSON.stringify({ name: state.modelo })
            });
          })
          .then(response => {
            if (response.status !== 201) {
              throw new Error(t("alerts.retread-item-10"));
            }
            return response.json();
          })
          .then(json => {
            data.modelo_id = json.id;
            dataDimention = {
              tiremodel_id: json.id,
              codigo: state.dimention,
              dimention: state.dimention,
              profundidad_original: state.prof_original,
              profundidad_minima: state.prof_minima,
              presion_maxima: state.presion_maxima,
              presion_minima: state.presion_minima,
              costo_milimetraje: state.costo_milimetraje.toString()
            }
            return fetch(`${APIS.API_REST_CUSTOMER_TIREDIMENSIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))
              .replace("tiremodel_id", json.id)}`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
              method: "POST",
              body: JSON.stringify(dataDimention)
            });
          })
          .then(response => {
            if (response.status !== 201) {
              throw new Error(t("alerts.retread-item-11"));
            }
            return response.json();
          })
          .then(json => {
            data.dimension_id = json.id;
            return fetch(`${APIS.API_REST_TIRECATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
              method: "POST",
              body: JSON.stringify(data)
            });
          })
          .then(response => {
            if (response.status !== 201) {
              throw new Error(t("alerts.retread-item-12"));
            }
            return response.json();
          })
          .then(json => {
            history.push("/tirecatalogues");
            dispatch(getReqSuccess(catalogues, catalogue, t("alerts.catalogue-item-2")));
          })
          .catch(error => {
            toast.error(error.toString());
          }));
      } else if (!state.custom_brands && state.custom_bands) {
        data.marca_id = state.marca.split("|")[0];
        trackPromise(fetch(`${APIS.API_REST_CUSTOMER_TIREMODELS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))
          .replace("tirebrand_id", data.marca_id)}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "POST",
          body: JSON.stringify({ name: state.modelo })
        })
          .then(response => {
            if (response.status !== 201) {
              throw new Error(t("alerts.retread-item-10"));
            }
            return response.json();
          })
          .then(json => {
            data.modelo_id = json.id;
            dataDimention = {
              tiremodel_id: json.id,
              codigo: state.dimention,
              dimention: state.dimention,
              profundidad_original: state.prof_original,
              profundidad_minima: state.prof_minima,
              presion_maxima: state.presion_maxima,
              presion_minima: state.presion_minima,
              costo_milimetraje: state.costo_milimetraje.toString()
            }
            return fetch(`${APIS.API_REST_CUSTOMER_TIREDIMENSIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))
              .replace("tiremodel_id", json.id)}`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
              method: "POST",
              body: JSON.stringify(dataDimention)
            });
          })
          .then(response => {
            if (response.status !== 201) {
              throw new Error(t("alerts.retread-item-11"));
            }
            return response.json();
          })
          .then(json => {
            data.dimension_id = json.id;
            return fetch(`${APIS.API_REST_TIRECATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
              method: "POST",
              body: JSON.stringify(data)
            });
          })
          .then(response => {
            if (response.status !== 201) {
              throw new Error(t("alerts.retread-item-12"));
            }
            return response.json();
          })
          .then(json => {
            history.push("/tirecatalogues");
            dispatch(getReqSuccess(catalogues, catalogue, t("alerts.catalogue-item-1")));
          })
          .catch(error => {
            toast.error(error.toString());
          }));
      } else if (!state.custom_brands && !state.custom_bands && state.custom_dimentions) {
        data.marca_id = state.marca.split("|")[0];
        data.modelo_id = state.modelo.split("|")[0];
        dataDimention = {
          tiremodel_id: data.modelo_id,
          codigo: state.dimention,
          dimention: state.dimention,
          profundidad_original: state.prof_original,
          profundidad_minima: state.prof_minima,
          presion_maxima: state.presion_maxima,
          presion_minima: state.presion_minima,
          costo_milimetraje: state.costo_milimetraje.toString()
        }
        trackPromise(fetch(`${APIS.API_REST_CUSTOMER_TIREDIMENSIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))
          .replace("tiremodel_id", data.modelo_id)}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "POST",
          body: JSON.stringify(dataDimention)
        })
          .then(response => {
            if (response.status !== 201) {
              throw new Error(t("alerts.retread-item-11"));
            }
            return response.json();
          })
          .then(json => {
            data.dimension_id = json.id;
            return fetch(`${APIS.API_REST_TIRECATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
              method: "POST",
              body: JSON.stringify(data)
            });
          })
          .then(response => {
            if (response.status !== 201) {
              throw new Error(t("alerts.retread-item-12"));
            }
            return response.json();
          })
          .then(json => {
            history.push("/tirecatalogues");
            dispatch(getReqSuccess(catalogues, catalogue, t("alerts.catalogue-item-1")));
          })
          .catch(error => {
            toast.error(error.toString());
          }));
      }
    }
  }
};

function getListsForTireCatalogues(t) {
  let brands, bands, dimensions = [];
  return dispatch => {
    trackPromise(fetch(APIS.API_REST_CUSTOMER_TIREBRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.retread-item-14"));
        }
        return response.json();
      })
      .then(json => {
        brands = json.Tirebrands;
        dispatch(getTireCatalogueAndListsSuccess({
          brands: brands,
          bands: bands,
          dimensions: dimensions
        }));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

function getBandsList(brandId, props) {
  return dispatch => {
    if (!brandId) {
      return dispatch(getTireCatalogueAndListsSuccess({
        catalogue: props.catalogue,
        brands: props.brands,
        bands: [],
        dimensions: []
      }));
    }
    trackPromise(fetch(APIS.API_REST_CUSTOMER_TIREMODELS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tirebrand_id", brandId), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(props.t("alerts.retread-item-15"));
        }
        return response.json();
      })
      .then(json => {
        dispatch(getTireCatalogueAndListsSuccess({
          catalogue: props.catalogue,
          brands: props.brands,
          bands: json.Tiremodels,
          dimensions: props.dimensions
        }));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

function getDimsList(modelId, props) {
  return dispatch => {
    if (!modelId) {
      return dispatch(getTireCatalogueAndListsSuccess({
        catalogue: props.catalogue,
        brands: props.brands,
        bands: props.bands,
        dimensions: []
      }));
    }
    trackPromise(fetch(APIS.API_REST_CUSTOMER_TIREDIMENSIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tiremodel_id", modelId), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(props.t("alerts.retread-item-16"));
        }
        return response.json();
      })
      .then(json => {
        dispatch(getTireCatalogueAndListsSuccess({
          catalogue: props.catalogue,
          brands: props.brands,
          bands: props.bands,
          dimensions: json.Dimentions
        }));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

export function getTireCatalogueAndListsSuccess(json) {
  return {
    type: constantsTireCatalogue.GET_LISTS_SUCCESS,
    json
  }
};

export function getTireCataloguesSuccess(json) {
  return {
    type: constantsTireCatalogue.GET_TICATALOGUES_SUCCESS,
    json
  };
};

export function getReqSuccess(catalogues, catalogue, message) {
  const json = {
    catalogues: catalogues,
    catalogue: catalogue,
    message: message
  };
  return {
    type: constantsTireCatalogue.GET_REQUEST_SUCCESS,
    json
  };
};

class TireCatalogueNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // codigo            : "",
      marca: "",
      modelo: "",
      dimension_id: "",
      prof_original: "",
      prof_minima: "",
      precio: "",
      distribuidor: "",
      presion_minima: "",
      presion_maxima: "",
      costo_milimetraje: "",
      custom_brands: false,
      custom_bands: false,
      custom_dimentions: false,
      is_equal_dimension: false,
    };
  };

  componentDidMount() {
    const { getListsForTireCatalogues } = this.props;
    getListsForTireCatalogues(this.props.t);
  };

  showHideInputs(brands, bands, dimentions) {
    this.setState({ custom_brands: brands })
    this.setState({ custom_bands: bands })
    this.setState({ custom_dimentions: dimentions })
    let status = (brands || bands || dimentions) ? false : true
    document.getElementById("profOrInput").readOnly = status
    document.getElementById("pressMinInput").readOnly = status
    document.getElementById("pressMaxInput").readOnly = status
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    if (event.target.name === "prof_minima") {
      if (event.target.value >= this.state.prof_original) {
        document.getElementById("profMinInput").setCustomValidity("La profundidad minima no puede ser igual o mayor a la original");
      } else {
        document.getElementById("profMinInput").setCustomValidity("");
      }
    }
    if (!this.state.custom_brands && event.target.name === "marca") {
      const brandId = event.target.value.split("|")[0];
      this.setState({
        modelo: "",
        dimension_id: "",
        prof_original: "",
        presion_minima: "",
        presion_maxima: "",
        is_equal_dimension: false
      });
      this.props.getBandsList(brandId, this.props);
    }
    if (!this.state.custom_bands && event.target.name === "modelo") {
      const modelId = event.target.value.split("|")[0];
      this.setState({
        dimension_id: "",
        prof_original: "",
        presion_minima: "",
        presion_maxima: "",
        is_equal_dimension: false
      });
      this.props.getDimsList(modelId, this.props);
    }
    if (event.target.name === "precio") {
      if (this.state.prof_minima != "" && this.state.prof_original != "") {
        var cxm = parseFloat(event.target.value / parseFloat(this.state.prof_original - this.state.prof_minima));
        this.setState({ costo_milimetraje: cxm })
      }
    }
    if (!this.state.custom_dimentions && event.target.name === "dimension_id") {
      var arr = this.props.dimensions;
      var arr2 = arr.filter(arr => arr.id == event.target.value);
      var p_ori = "";
      if (arr2.length > 0) {
        p_ori = arr2[0].profundidad_original;
      }
      this.setState({
        prof_original: p_ori,
        presion_minima: "",
        presion_maxima: "",
        is_equal_dimension: false
      });
    } else {
      // if(event.target.name === "precio") {
      //   var cxm = parseFloat(parseFloat(event.target.value) / parseFloat(document.getElementById("profOrInput").value));
      //   this.setState({ costo_milimetraje: cxm});
      // }
    }
  };

  onChangeEqualDimention = event => {
    if (event.target.checked) {
      var arr = this.props.dimensions;
      var arr2 = arr.filter(arr => arr.id == this.state.dimension_id);
      var p_min = "";
      var p_max = "";
      if (arr2.length > 0) {
        p_min = arr2[0].presion_minima;
        p_max = arr2[0].presion_maxima;
      }
      this.setState({
        presion_minima: p_min,
        presion_maxima: p_max,
        is_equal_dimension: true
      });
    } else {
      this.setState({
        presion_minima: "",
        presion_maxima: "",
        is_equal_dimension: false
      });
    }
  }

  onChangePess = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {

    const { codigo, marca, modelo, dimension_id, prof_original, prof_minima,
      precio, distribuidor, presion_minima, presion_maxima,
      costo_milimetraje, dimention, custom_brands, custom_bands, custom_dimentions, is_equal_dimension } = this.state;
    const { catalogue, catalogues, brands, bands, dimensions,
      history } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("tire-catalogue.tire-new")}</h3>
            <br /><br />
            <form onSubmit={(event) => this.props.onSubmitTireCatalogue(
              event, this.state, catalogues,
              catalogue, history, this.props.t)}>
              <div className="form-group">
                <div className="row">
                  {
                    !custom_brands ?
                      <a href="#" style={{ display: "none" }} className="pull-right col-sm-12"
                        onClick={(e) => this.showHideInputs(true, true, true)}>{this.props.t("tire-catalogue.tire-question-1")}</a>
                      :
                      <a href="#" className="pull-right col-sm-12"
                        onClick={(e) => this.showHideInputs(false, false, false)}>{this.props.t("tire-catalogue.tire-question-2")}</a>
                  }
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label>{this.props.t("tire-catalogue.tire-item-1")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    {
                      !custom_brands ?
                        <select className="form-control"
                          placeholder="Marca de la llanta"
                          id="cataloguebrandInput"
                          name="marca"
                          value={marca}
                          onChange={this.onChange}
                          required>
                          <option value="">{this.props.t("tire-catalogue.tire-select-brand")}</option>
                          {
                            !!brands && brands.map(brand => {
                              return <option key={brand.id}
                                value={brand.id + "|" + brand.name}>
                                {brand.name}
                              </option>
                            })
                          }
                        </select>
                        :
                        <input className="form-control"
                          placeholder={this.props.t("tire-catalogue.tire-select-brand")}
                          id="brandInput"
                          name="marca"
                          type="text"
                          value={marca}
                          onChange={this.onChange}
                          required />
                    }
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  {
                    !custom_bands ?
                      <a href="#" style={{ display: "none" }} className="pull-right col-sm-12"
                        onClick={(e) => this.showHideInputs(custom_brands, true, true)}>{this.props.t("tire-catalogue.tire-question-3")}</a>
                      : !custom_brands ?
                        <a href="#" className="pull-right col-sm-12"
                          onClick={(e) => this.showHideInputs(custom_brands, false, false)}>{this.props.t("tire-catalogue.tire-question-4")}</a>
                        : null
                  }
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label>{this.props.t("tire-catalogue.tire-item-2")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    {
                      !custom_bands ?
                        <select className="form-control"
                          placeholder={this.props.t("tire-catalogue.tire-item-2")}
                          id="cataloguebandInput"
                          name="modelo"
                          value={modelo}
                          onChange={this.onChange}
                          required>
                          <option value="">{this.props.t("tire-catalogue.tire-select-model")}</option>
                          {
                            !!bands && bands.map(band => {
                              return <option key={band.id}
                                value={band.id + "|" + band.name}>
                                {band.name}
                              </option>
                            })
                          }
                        </select>
                        :
                        <input className="form-control"
                          placeholder={this.props.t("tire-catalogue.tire-item-13")}
                          id="bandInput"
                          name="modelo"
                          type="text"
                          value={modelo}
                          onChange={this.onChange}
                          required />
                    }
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  {
                    !custom_dimentions ?
                      <a href="#" style={{ display: "none" }} className="pull-right col-sm-12"
                        onClick={(e) => this.showHideInputs(custom_brands, custom_bands, true)}>{this.props.t("tire-catalogue.tire-question-5")}</a>
                      : !custom_bands ?
                        <a href="#" className="pull-right col-sm-12"
                          onClick={(e) => this.showHideInputs(custom_brands, custom_bands, false)}>{this.props.t("tire-catalogue.tire-question-6")}</a>
                        : null
                  }
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label>{this.props.t("tire-catalogue.tire-item-3")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    {
                      !custom_dimentions ?
                        <select className="form-control"
                          placeholder={this.props.t("tire-catalogue.tire-item-3")}
                          id="cataloguedimensionInput"
                          name="dimension_id"
                          value={dimension_id}
                          onChange={this.onChange}
                          required>
                          <option value="">{this.props.t("tire-catalogue.tire-select-dimensions")}</option>
                          {
                            !!dimensions && dimensions.map(dimension => {
                              return <option key={dimension.id}
                                value={dimension.id}>
                                {dimension.dimention}
                              </option>
                            })
                          }
                        </select>
                        :
                        <input className="form-control"
                          placeholder={this.props.t("tire-catalogue.tire-item-3")}
                          id="dimensionInput"
                          name="dimention"
                          type="text"
                          value={dimention}
                          onChange={this.onChange}
                          required />
                    }
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="profOrInput">{this.props.t("tire-catalogue.tire-item-6")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-catalogue.tire-item-6")}
                      id="profOrInput"
                      name="prof_original"
                      type="number"
                      value={prof_original}
                      readOnly={true}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="profMinInput">{this.props.t("tire-catalogue.tire-item-7")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-catalogue.tire-item-7")}
                      id="profMinInput"
                      name="prof_minima"
                      type="number"
                      value={prof_minima}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="priceInput">{this.props.t("tire-catalogue.tire-item-10")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-catalogue.tire-item-10")}
                      id="priceInput"
                      name="precio"
                      type="number"
                      value={precio}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="providerInput">{this.props.t("tire-catalogue.tire-item-11")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-catalogue.tire-item-11")}
                      id="providerInput"
                      name="distribuidor"
                      type="text"
                      value={distribuidor}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2 mt-4">
                    <label htmlFor="permissionIdInput">Mismas Presiones</label>
                  </div>
                  <div className="col-sm-10 mt-4">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="is_equal_dimension"
                        name="is_equal_dimension"
                        checked={is_equal_dimension}
                        onChange={this.onChangeEqualDimention}
                      />
                    </div>
                  </div >
                </div >
              </div >
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="pressMinInput">{this.props.t("tire-catalogue.tire-item-8")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-catalogue.tire-item-8")}
                      id="pressMinInput"
                      name="presion_minima"
                      type="number"
                      value={presion_minima}
                      readOnly={is_equal_dimension}
                      onChange={this.onChangePess}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="pressMaxInput">{this.props.t("tire-catalogue.tire-item-9")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-catalogue.tire-item-9")}
                      id="pressMaxInput"
                      name="presion_maxima"
                      type="number"
                      value={presion_maxima}
                      readOnly={is_equal_dimension}
                      onChange={this.onChangePess}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="costInput">{this.props.t("tire-catalogue.tire-item-12")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-catalogue.tire-item-12")}
                      id="costInput"
                      name="costo_milimetraje"
                      type="number"
                      value={costo_milimetraje}
                      readOnly={true}
                      required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                    className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form >
          </div >
        )
        }
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  catalogues: state => state.catalogues,
  catalogue: state => state.catalogue,
  brands: state => state.brands,
  bands: state => state.bands,
  dimensions: state => state.dimensions
});

const mapDispatchToProps = {
  onSubmitTireCatalogue,
  getListsForTireCatalogues,
  getBandsList,
  getDimsList
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(TireCatalogueNewItem));
