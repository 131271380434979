import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsSchema from "../../constants/actions/Schemas";
import * as APIS from "../../constants/apis";

function getSchema(name, t) {
  return dispatch => {
    if (!localStorage.getItem("GlobalCustomerId")) return null;
    return trackPromise(fetch(`${APIS.API_REST_SCHEMAS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.schemas-item-1"));
        }
        return response.json();
      })
      .then(json => {
        dispatch(getSchemaSuccess({
          schema: json
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };
};

export function getSchemaSuccess(json) {
  return {
    type: constantsSchema.GET_SCHEMA_SUCCESS,
    json
  };
};

export function getReqSuccess(schemas, schema, message) {
  const json = {
    schemas: schemas,
    schema: schema,
    message: message
  };
  return {
    type: constantsSchema.GET_REQUEST_SUCCESS,
    json
  };
};

class SchemaEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // code: "",
      axesnumber: "",
      tiresnumber: "",
      spares: "",
    };
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getSchema(id, this.props.t);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.schema || (this.props.schema !== prevProps.schema)) {
      this.setState({
        name: this.props.schema.name,
        // code: this.props.schema.code,
        axesnumber: this.props.schema.axesnumber,
        tiresnumber: this.props.schema.tiresnumber
      });
    };
  };

  onSubmit = event => {
    event.preventDefault();
    const { getReqSuccess, schema, schemas } = this.props;

    const name = this.props.match.params.id;
    const data = {
      ...this.state,
    };

    return trackPromise(fetch(`${APIS.API_REST_SCHEMAS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "PUT",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          this.props.history.push("/schemas");
          getReqSuccess(schemas, schema, this.props.t("alerts.schemas-item-2"));
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onChange = event => {
    if (event.target.name === 'tiresnumber') {
      this.setState({ name: document.getElementById("axesNumberInput").value + '_ejes_' + event.target.value + '_llantas' });
    }
    if (event.target.name === 'axesnumber') {
      this.setState({ name: event.target.value + '_ejes_' + document.getElementById("tiresNumberInput").value + '_llantas' });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  render() {
    const { schema, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!schema ?
            <div className="container-fluid mt-100">

              <h3>{this.props.t("schema.schema-edit")}</h3>
              <br /><br />
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="nameInput">{this.props.t("schema.schema-th-1")}</label>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("schema.schema-th-1")}
                        id="nameInput"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        readOnly={true} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="axesNumberInput">{this.props.t("schema.schema-axles-number")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("schema.schema-axles-number")}
                        id="axesNumberInput"
                        name="axesnumber"
                        type="number"
                        value={this.state.axesnumber}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="tiresNumberInput">{this.props.t("schema.schema-tires-number")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("schema.schema-tires-number")}
                        id="tiresNumberInput"
                        name="tiresnumber"
                        type="number"
                        value={this.state.tiresnumber}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="sparesInput">{this.props.t("schema.schema-spare-parts")}</label>
                      <br></br><i className="text-info small"></i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                              id="sparesInput"
                              name="spares"
                              type="number"
                              value={this.state.spares}
                              onChange={this.onChange}/> 
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button type="submit"
                      className="btn btn-primary">
                      <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                  </div>
                </div>
              </form>
            </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  schemas: state => state.schemas,
  schema: state => state.schema,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getSchema, getReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(SchemaEditItem));
