import { REQ_MIN_CARS_SUCCESS, REQ_MIN_CARS_ERROR } from '../types/InspectionOneTypes'


const INITIAL_STATE = {
  min_cars: [],
  min_tires: [],
  waste: [],
  novelties: [],
  records: [],
  warehouses: [],
  error: null
}


export const inspectionOneReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQ_MIN_CARS_SUCCESS:
     return {
       ...state,
       min_cars: action.payload
     } 
    case REQ_MIN_CARS_ERROR:
     return {
       ...state,
       error: action.payload
     } 
    default:
      return state
  } 
}