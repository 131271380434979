// import app from 'firebase/app'
import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { async } from "q";
import { toast } from "react-toastify";
import * as APIS from "../../../constants/apis";
import { getAuthUser, initSegment } from "../../../constants/kompassify";
import { startConfig } from "../../../utils/mixpanelActions";
import { stonlyConfig } from "../../../utils/stonlychange";

const leerCookie = () => {
  let micookie;
  var lista = document.cookie.split(";");
  for (let i = 0; i < lista.length; i++) {
    var busca = lista[i].search("lang");
    if (busca > -1) {
      micookie = lista[i];
      var igual = micookie.indexOf("=");
      var valor = micookie.substring(igual + 1);
      localStorage.setItem("LanguageSelected", valor);
    }
  }
};

leerCookie();

localStorage.setItem("is_tire_massive", "no");

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    app.initializeApp(config, "appCreate");

    this.db = app.database();
    this.auth = app.auth();
    this.authCreate = app.apps[1].auth();
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.authCreate.createUserWithEmailAndPassword(email, password);

  doSignOut = () => {
    var reseller = "";
    if (localStorage.getItem("logo")) {
      reseller = localStorage.getItem("logo");
    }
    const i18n = localStorage.getItem("i18nextLng");
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("i18nextLng", i18n);
    localStorage.setItem("LanguageSelected", i18n);
    if (reseller != "") {
      localStorage.setItem("logo", reseller);
    }
    this.auth.signOut();
  };

  doApp = () => {
    const userId = this.auth.currentUser.uid;
    let db = this.db;
    return { db, userId };
  };

  doFreeTrial = () => {
    let db = this.db;
    return db;
  };

  doNotifications = () => {
    let db = this.db;
    return db;
  };

  doUpdateEmailCurrentUser = (email) => {
    this.auth.currentUser
      .updateEmail(email)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doSignOutCreate = () => this.authCreate.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doGenerateToken = () => this.auth.currentUser.getIdToken(true);

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        if (!localStorage.getItem("GlobalCustomerId")) {
          authUser.getIdToken(true).then(function (token) {
            localStorage.setItem("firebaseAuthToken", token);
            fetch(`${APIS.API_REST_USERS}/${authUser.uid}`, {
              headers: {
                Authorization: "Basic " + token,
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            })
              .then((response) => {
                return response.json();
              })
              .then((json) => {
                let message = json.message;
                if (!message) {
                  let roles = json.roles;
                  let display_name = json.display_name;
                  let only_reports = json.only_reports;
                  let permissions = json.permissions;
                  let customer_id = json.customer_id;
                  let active = json.active;
                  let language = json.language;
                  let isMc = json.is_mc;
                  let isMmc = json.is_mmc;
                  let id = json.id;
                  let daysCreated = json.days_created;
                  let restricted_admin = json.restricted_admin;
                  if (roles !== "TECHNICIAN" && active === true) {
                    localStorage.setItem("userId", json.id);
                    if (localStorage.getItem("GlobalCustomerId")) {
                      if (!!json.customer_id) {
                        localStorage.setItem(
                          "GlobalCustomerName",
                          json.display_name
                        );
                        localStorage.setItem(
                          "GlobalCustomerId",
                          json.customer_id
                        );
                      }
                      fetch(
                        APIS.API_REST_INSPECTIONS_PENDING_COUNT.replace(
                          "customer_id",
                          localStorage.getItem("GlobalCustomerId")
                        ),
                        {
                          headers: {
                            Authorization: "Basic " + token,
                            "Accept-language":
                              localStorage.getItem("LanguageSelected"),
                          },
                        }
                      )
                        .then((response) => {
                          return response.json();
                        })
                        .then((json) => {
                          authUser = {
                            uid: authUser.uid,
                            username: display_name,
                            email: authUser.email,
                            roles: roles,
                            permissions: permissions,
                            only_reports: only_reports,
                            language: language,
                            isMc: isMc,
                            isMmc: isMmc,
                            id: id,
                            daysCreated: daysCreated,
                            restricted_admin: restricted_admin,
                          };
                          localStorage.setItem(
                            "authUser",
                            JSON.stringify(authUser)
                          );
                          localStorage.setItem(
                            "GlobalPendingCount",
                            json.count
                          );
                          localStorage.setItem(
                            "GlobalPendingCountScrap",
                            json.count_waste
                          );
                          if (!!customer_id) {
                            localStorage.setItem(
                              "GlobalCustomerName",
                              display_name
                            );
                            localStorage.setItem(
                              "GlobalCustomerId",
                              customer_id
                            );
                            fetch(
                              APIS.API_REST_INSPECTIONS_PENDING_WITHOUT_COUNT.replace(
                                "customer_id",
                                localStorage.getItem("GlobalCustomerId")
                              ),
                              {
                                headers: {
                                  Authorization: "Basic " + token,
                                  "Accept-language":
                                    localStorage.getItem("LanguageSelected"),
                                },
                              }
                            )
                              .then((response) => {
                                return response.json();
                              })
                              .then((json) => {
                                localStorage.setItem(
                                  "GlobalPendingWithoutKmCount",
                                  json.count
                                );
                                fetch(
                                  APIS.API_REST_INSPECTIONS_PENDING_LIST_ANALIST.replace(
                                    "customer_id",
                                    localStorage.getItem("GlobalCustomerId")
                                  ),
                                  {
                                    headers: {
                                      Authorization: "Basic " + token,
                                      "Accept-language":
                                        localStorage.getItem(
                                          "LanguageSelected"
                                        ),
                                    },
                                  }
                                )
                                  .then((response) => response.json())
                                  .then((json) => {
                                    if (json.Vehiculos) {
                                      localStorage.setItem(
                                        "GlobalPendingAnalist",
                                        json.Vehiculos.length
                                      );
                                    }
                                    window.addEventListener(
                                      "message",
                                      function (event) {
                                        if (
                                          event.data.TYPE ===
                                          "KOMPASSIFY_BOOT_LOADER_IS_READY"
                                        ) {
                                          initSegment(authUser);
                                        }
                                      }
                                    );
                                    stonlyConfig(authUser);
                                    startConfig(authUser);
                                    next(authUser);
                                  });
                              });
                          }
                        });
                    } else {
                      authUser = {
                        uid: authUser.uid,
                        username: json.display_name,
                        email: authUser.email,
                        roles: json.roles,
                        permissions: json.permissions,
                        only_reports: json.only_reports,
                        language: language,
                        isMc: isMc,
                        isMmc: isMmc,
                        id: id,
                        daysCreated: daysCreated,
                        restricted_admin: restricted_admin,
                      };
                      localStorage.setItem(
                        "authUser",
                        JSON.stringify(authUser)
                      );
                      localStorage.setItem("GlobalPendingCount", 0);
                      localStorage.setItem("GlobalPendingWithoutKmCount", 0);
                      // getAuthUser(authUser)
                      window.addEventListener("message", function (event) {
                        if (
                          event.data.TYPE === "KOMPASSIFY_BOOT_LOADER_IS_READY"
                        ) {
                          initSegment(authUser);
                        }
                      });
                      stonlyConfig(authUser);
                      startConfig(authUser);
                      next(authUser);
                    }
                  } else {
                    fallback();
                  }
                } else {
                  fallback();
                }
              });
          });
        } else {
          authUser.getIdToken(true).then(function (token) {
            localStorage.setItem("firebaseAuthToken", token);
            const auth = localStorage.getItem("authUser");
            authUser = JSON.parse(auth);
            stonlyConfig(authUser);
            startConfig(authUser);
            next(authUser);
          });
        }
      } else {
        stonlyConfig(null);
        startConfig(null);
        fallback();
      }
    });
}

export default Firebase;
