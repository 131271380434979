/*
 ** GLOBAL MODULE
 */

// Presentation pages
export const LANDING = "/";
export const HOME = "/home";
export const HELP = "/help";

// Account
export const SIGN_UP = "/sign-up";
export const SIGN_IN = "/sign-in";
export const ACCOUNT = "/account";
export const FORMATS = "/formats";
export const PASSWORD_FORGET = "/password-forget";

// Users
export const USERS = "/users";
export const USERS_NEW = "/users/new";
export const USERS_EDIT = "/users/edit/:id";
export const USERS_SHOW = "/users/:id";
export const USERS_REPORT = "/reports/users";
export const COMPILANCE_MONTH_REPORT = "/reports/compilance/months/";

// Customers
export const CUSTOMERS = "/customers";
export const CUSTOMERSMACRO = "/macro/customers";
export const CUSTOMERS_NEW = "/customers/new";
export const CUSTOMERS_EDIT = "/customers/edit/:id";
export const CUSTOMERS_SHOW = "/customers/show/:id";

// Invoices
export const INVOICES = "/invoices/:id";
export const INVOICES_NEW = "/invoices/:id/new";
export const INVOICES_SHOW = "/invoices/:id/detail/:invoiceid";

/*
 ** DASHBOARDS MODULE
 */

// Dashboards
export const DASHBOARDS = "/dashboards/:uid";
export const DASHBOARDS_NEW = "/dashboards/:uid/new";
export const DASHBOARDS_EDIT = "/dashboards/:uid/edit/:id";
export const DASHBOARDS_REPORTS = "/reports";

/*
 ** GENERAL MODULE
 */

// Vehicle Brands
export const BRANDS = "/brands";
export const BRANDS_NEW = "/brands/new";
export const BRANDS_EDIT = "/brands/edit/:id";

// Vehicle Schemas
export const SCHEMAS = "/schemas";
export const SCHEMAS_NEW = "/schemas/new";
export const SCHEMAS_EDIT = "/schemas/edit/:id";

// Vehicle Types
export const TYPES = "/types";
export const TYPES_NEW = "/types/new";
export const TYPES_EDIT = "/types/edit/:id";

// Tire Brands
export const TIREBRANDS = "/tirebrands";
export const TIREBRANDS_NEW = "/tirebrands/new";
export const TIREBRANDS_EDIT = "/tirebrands/edit/:id";
export const TIREBRANDS_SHOW = "/tirebrands/:id";
export const TIREBRANDS_TIRES = "/tirebrands/:id/tires";

// Tire Models
export const TIREMODELS_EDIT = "/tiremodels/:brandid/edit/:id";
export const TIREMODELS_TIRES = "/tirebrands/:brand_id/tiremodels/:id/tires";

// Tire Dimensions
export const TIREDIMENSIONS_NEW = "/tiredimensions/:modelid/new";
export const TIREDIMENSIONS_EDIT = "/tiredimensions/:modelid/edit/:id";

// Retread Brands
export const RETREADBRANDS = "/retreadbrands";
export const RETREADBRANDS_NEW = "/retreadbrands/new";
export const RETREADBRANDS_EDIT = "/retreadbrands/edit/:id";
export const RETREADBRANDS_SHOW = "/retreadbrands/:id";
export const RETREADBRANDS_TIRES = "/retreadbrands/:id/tires";

// Retread Models
export const RETREADMODELS_EDIT = "/retreadbands/:brandid/edit/:id";
export const RETREADMODELS_TIRES =
  "/retreadbrands/:brand_id/retreadbands/:id/tires";

// Retread Dimensions
export const RETREADDIMENSIONS_NEW = "/retreaddimensions/:bandid/new";
export const RETREADDIMENSIONS_EDIT = "/retreaddimensions/:bandid/edit/:id";

/*
 ** CATALOGUES MODULE
 */

// Tire Catalogues
export const TIRECATALOGUES = "/tirecatalogues";
export const TIRECATALOGUES_NEW = "/tirecatalogues/new";
export const TIRECATALOGUES_EDIT = "/tirecatalogues/edit/:id";
export const TIRECATALOGUES_SHOW = "/tirecatalogues/:id";
export const TIRECATALOGUES_MULTIPLE = "/tirecatalogues/multiple";

// Retread Catalogues
export const RETREADCATALOGUES = "/retreadcatalogues";
export const RETREADCATALOGUES_NEW = "/retreadcatalogues/new";
export const RETREADCATALOGUES_EDIT = "/retreadcatalogues/edit/:id";
export const RETREADCATALOGUES_SHOW = "/retreadcatalogues/:id";
export const RETREADCATALOGUES_MULTIPLE = "/retreadcatalogues/multiple";

/*
 ** CLIENT MODULE
 */

// Warehouses
export const WAREHOUSES = "/warehouses";
export const WAREHOUSES_NEW = "/warehouses/new";
export const WAREHOUSES_EDIT = "/warehouses/edit/:id";

// Cities
export const CITIES = "/cities";
export const CITIES_NEW = "/cities/new";
export const CITIES_EDIT = "/cities/edit/:id";

// Cost Centers
export const COSTCENTERS = "/costcenters";
export const COSTCENTERS_NEW = "/costcenters/new";
export const COSTCENTERS_EDIT = "/costcenters/edit/:id";

// Groups
export const GROUPS = "/groups";
export const GROUPS_ONE = "/groups/one";
export const GROUPS_TWO = "/groups/two";
export const GROUPS_ONE_NEW = "/groups/one/new";
export const GROUPS_ONE_EDIT = "/groups/one/edit/:id";
export const GROUPS_NEW = "/groups/new";
export const GROUPS_EDIT = "/groups/edit/:id";

// Vehicles
export const VEHICLES = "/vehicles";
export const VEHICLES_NEW = "/vehicles/new";
export const VEHICLES_EDIT = "/vehicles/edit/:id";
export const VEHICLES_SHOW = "/vehicles/:id";
export const VEHICLES_MULTIPLE = "/vehicles/multiple";
export const VEHICLES_HISTORY = "/vehicles/history/:id";

// Work Vehicle
export const WORKS_VEHICLE = "/works/vehicles/:id";
export const WORKS_VEHICLE_NEW = "/works/vehicles/new/:id";
export const WORKS_VEHICLE_EDIT = "/works/vehicles/edit/:vehicle_id/:id";

// Queries Vehicle
export const QUERIES_VEHICLE = "/queries/vehicles";
export const QUERIES_WORK_VEHICLE = "/queries/work/vehicles";

// Tires
export const TIRES = "/tires";
export const TIRES_NEW = "/tires/new";
export const TIRES_EDIT = "/tires/edit/:id";
export const TIRES_HISTORY = "/tires/history/:id";
export const TIRES_MULTIPLE = "/tires/multiple";

// Work Tires
export const WORKS_TIRE = "/works/tires/:id";
export const WORKS_TIRE_NEW = "/works/tires/new/:id";
export const WORKS_TIRE_EDIT = "/works/tires/edit/:tire_id/:id";
export const WORKS_MULTIPLE = "/works/multiple";

// Queries Tires
export const QUERIES_TIRE = "/queries/tires";
export const QUERIES_WORK_TIRE = "/queries/work/tires";

/*
 ** MEASUREMENTS MODULE
 */

// Measurements
export const DISTANCES_ONE = "/distances/one";
export const DISTANCES_MULTIPLE = "/distances/multiple";
export const FUELS_ONE = "/fuels/one";
export const FUELS_MULTIPLE = "/fuels/multiple";

/*
 ** INSPECTIONS MODULE
 */

// Novelties
export const NOVELTIES_ONE = "/novelties/one";
export const NOVELTIES_MULTIPLE = "/novelties/multiple";

/*
 ** INSPECTIONS MODULE
 */

// Inspections
export const INSPECTIONS_ONE = "/inspections/one";
export const INSPECTIONS_MULTIPLE = "/inspections/multiple";
export const INSPECTIONS_PENDING = "/inspections/pending";
export const INSPECTIONS_PENDING_WITHOUT =
  "/inspections/pending_without_measure";
export const INSPECTIONS_PENDING_EDIT = "/inspections/pending/:vehicle_id/:id";
export const INSPECTIONS_PENDING_SCRAP = "/inspections/pending/scrap";
export const INSPECTIONS_PENDING_ROTATION = "/inspections/pending/rotation";
export const INSPECTIONS_PREVIEW = "/inspections/preview";
export const INSPECTIONS_PREVIEW_BETA = "/inspections/preview/beta";
export const INSPECTIONS_PREVIEW_MULTIPLE = "/inspections/preview/multiple";
export const INSPECTIONS_PREVIEW_LIST = "/inspections/preview/list";

/*
 ** REPORTS MODULE
 */

// Plans
export const REPORTS_PLANS = "/reports/plans";
export const REPORTS_PLANS_CURRENT = "/reports/plans/current";
export const REPORTS_PLAN_TIRES = "/reports/plans/tires";
export const REPORTS_PLANS_SHOW = "/reports/plans/:id";

// Indicators
export const REPORTS_INDICATORS = "/reports/indicators";

// Inform General
export const REPORTS_INFORMS = "/reports/informs";

// Inform
export const REPORTS_INSPECTIONS = "/reports/inspections";
export const REPORTS_INSPECTIONS_PREASSURE = "/reports/inspections/preassure";

// Top
export const REPORTS_TOP10 = "/reports/top10";

// Top
export const REPORTS_PERFORMANCES = "/reports/performances";
export const REPORTS_PERFORMANCES_LAST = "/reports/performances/last";

// Injections
export const REPORTS_INJECTIONS = "/reports/injections";

// Projection
export const REPORTS_TIRE_PROJECTION = "/reports/projection";

// Top Indicators
export const REPORTS_TOP_INDICATORS = "/reports/top/indicators";

// Dismount
export const REPORTS_DISMOUNTS = "/reports/dismount";

// Discards
export const REPORTS_DISCARDS = "/reports/discards";

// Warehouses
export const REPORTS_WAREHOUSES = "/reports/warehouses";

// Inventory
export const REPORTS_INVENTORY = "/reports/inventory";

// Stats Tires
export const REPORTS_STATS_TIRES = "/reports/stats/tires";

// Gestión
export const REPORTS_STATS_GESTION = "/reports/stats/gestion";

// Stats Vehicles
export const REPORTS_STATS_VEHICLES = "/reports/stats/vehicles";

// Errors Detection
export const REPORTS_FULLFILMENT = "/reports/fullfilment";

// Errors Detection
export const REPORTS_ERRORS_DETECTION = "/reports/errors";

// Sinisters
export const REPORTS_SINISTERS = "/reports/sinisters";

// Pressures
export const REPORTS_PRESSURES = "/reports/pressures";

// Tank
export const REPORTS_PLAN_TANK = "/reports/tank";

// Shopping
export const REPORTS_PLAN_SHOPPING = "/reports/shopping";

// Ventures
export const REPORTS_VENTURES_TIRE = "/reports/ventures/tire";
export const REPORTS_VENTURES_VEHICLE = "/reports/ventures/vehicle";
export const REPORTS_VENTURES_INDICATORS = "/reports/ventures/indicators";
export const REPORTS_VENTURES_COMPILANCE = "/reports/ventures/compilance";
export const REPORTS_VENTURES_MILIMETERS = "/reports/ventures/milimeters";
export const REPORTS_VENTURES_DISCARDS = "/reports/ventures/discards";
export const REPORTS_VENTURES_YIELDS = "/reports/ventures/yields";
export const REPORTS_VENTURES_CONSUMPTION = "/reports/ventures/consumption";
export const REPORTS_VENTURES_CONSOLIDATEDPLAN =
  "/reports/ventures/consolidatedplan";
export const REPORTS_VENTURES_MTOPLAN = "/reports/ventures/mtoplan";
export const REPORTS_VENTURES_SHOPPING = "/reports/ventures/shopping";
export const REPORTS_VENTURES_EMISSIONS = "/reports/ventures/emissions";

// Macro Accounts
export const MACROS_MACRO_ACCOUNT = "/macros/macro";
export const MACROS_MACRO_ACCOUNT_NEW = "/macros/new";
export const MACROS_MACRO_ACCOUNT_EDIT = "/macros/edit/:id";

export const MACROS_MACRO_MACRO_ACCOUNT = "/macros/macro/macro";
export const MACROS_MACRO_MACRO_ACCOUNT_NEW = "/macros/macro/new";
export const MACROS_MACRO_MACRO_ACCOUNT_EDIT = "/macros/macro/edit/:id";

export const MACROS_MACRO_ACCOUNT_VIEW_REPORTS = "/macros/reports";
export const MACROS_MACRO_MACRO_ACCOUNT_VIEW_REPORTS = "/macros/macro/reports";

// Massive
export const ADMIN_MASSIVE = "/massive/admin";
