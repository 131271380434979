import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import * as constantsRetreadCatalogue from "../../../constants/actions/RetreadCatalogues";
import * as APIS from "../../../constants/apis";

function deleteRetreadCatalogue(name, catalogues, catalogue, t) {
  if(window.confirm("¿Estás seguro de eliminar este catálogo?")) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_RETREADCATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
        headers:{
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "DELETE" })
        .then(response => {
          if(response.status !== 200) {
            throw new Error(t("alerts.brands-item-5"));
          }
          return response.json(); })
        .then(json => {
          dispatch(getReqSuccess(catalogues, catalogue,
                                    json.message));
              return fetch(APIS.API_REST_RETREADCATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
                                                                    headers:{
                                                                      "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                                      "Accept-language": localStorage.getItem("LanguageSelected"),
                                                                    } })
                .then(response => response.json())
                .then(json => {
                  dispatch(getRetreadCataloguesSuccess({
                    catalogues : json.RetreadCatalogue,
                  })); })
                .catch(error => {
                  dispatch(getReqError(catalogues, catalogue, error.toString()));
                }); })
        .catch(error => {
          dispatch(getReqError(catalogues, catalogue,
                              error.message.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(getRetreadCataloguesSuccess({
        catalogues : catalogues,
      }));
    };
  }
};

export function getRetreadCataloguesSuccess(json) {
  return {
    type: constantsRetreadCatalogue.GET_RECATALOGUES_SUCCESS,
    json
  };
};

export function getReqSuccess(catalogues,
                              catalogue, message) {
  const json = {
    catalogues : catalogues,
    catalogue  : catalogue,
    message    : message
  };
  return {
    type: constantsRetreadCatalogue.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(catalogues, catalogue, error) {
  const json = {
    catalogues : catalogues,
    catalogue  : catalogue,
    error      : error
  };
  return {
    type: constantsRetreadCatalogue.GET_REQUEST_ERROR,
    json
  };
};

class RetreadCatalogueDeleteItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render () {
    const { catalogues, catalogue,
            catalogueDelete, deleteRetreadCatalogue } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteRetreadCatalogue(
                                catalogueDelete.id, catalogues, catalogue, this.props.t)}
                className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
};

const structuredSelector = createStructuredSelector({
  catalogues : state => state.catalogues,
  catalogue  : state => state.catalogue
});

const mapDispatchToProps = { deleteRetreadCatalogue };

export default connect(structuredSelector, mapDispatchToProps)(RetreadCatalogueDeleteItem);
