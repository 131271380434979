import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../Auth/Session";

import * as constantsCustomer from "../../constants/actions/Customers";
import * as APIS from "../../constants/apis";
import * as ROLES from "../../constants/roles";

import TireList from "./Vehicles/Tires/TireList";

function getCustomer(name) {
  let tires = [];
  let customer = null;
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_CUSTOMERS}/${name}`, {
      headers:{
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      } })
      .then(response => response.json())
      .then(json => {
        customer = json;
        return fetch(`${APIS.API_REST_TIRES.replace("customer_id", name)}`, {
          headers:{
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          } }) })
      .then(response => response.json())
      .then(json => {
        tires = json.tires;
        return dispatch(getCustomerSuccess({
          customer : customer,
          tires    : tires
        })); })
      .catch(error => {
        toast.error(error.message);
      }));
  }
}

export function getCustomerSuccess(json) {
  return {
    type: constantsCustomer.GET_CUSTOMER_SUCCESS,
    json
  }
}

class CustomerItem extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getCustomer(id);
  };

  render() {
    const { customer, tires } = this.props;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!customer ?
          <div className="container-fluid mt-100">
            <h3>{this.props.t("customers.customer-info")}</h3>
            <br />
            
            <div className="text-center v-scroll">
              <table className="table table-striped table-bordered table-sm mb-0">
                <tbody>
                  <tr>
                    <td className="w-50">
                    {this.props.t("customers.customer-item-2")}:
                    </td>
                    <td className="w-50">
                      {customer.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50">
                    {this.props.t("customers.customer-max-travel")}:
                    </td>
                    <td className="w-50">
                      {customer.maxtravel}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <h3>{this.props.t("customers.customer-tires")}</h3>
            {
              !!tires && tires.length > 0 ?
                <TireList
                  t={this.props.t}
                  tires={tires}
                  customerName={btoa(customer.name)}
                /> : null
            }
          </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  customer : state => state.customer,
  tires    : state => state.tires
});

const mapDispatchToProps = { getCustomer };

const condition = authUser =>
!!authUser && (authUser.roles === ROLES.ADMIN || authUser.roles === ROLES.ANALYST);

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(CustomerItem));
