import React from "react";
import LineEditItem from "./LineEditItem";

class LinesList extends React.Component {

  getLinesList = (brand, lines) => {
    if (!lines) return null;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("brands-item-1")}</th>
              <th scope="col">{this.props.t("brands-item-2")}</th>
            </tr>
          </thead>
          <tbody>
            {
              lines.map(line => {
                return <LineEditItem
                  t={this.props.t}
                  line={line}
                  brand={brand}
                />
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    const { brand, lines } = this.props;

    return (this.getLinesList(brand, lines));
  };
};

export default LinesList;
