import React from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Autosuggest from "react-autosuggest";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import Pagination from "../../pagination";
import * as APIS from "../../../constants/apis";
import { WORK_TYPES } from "../../../constants/variables";

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => <div>{suggestion.codigo}</div>;

class WorksList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 20,
      suggestions: [],
      tires: [],
      tire_code: "",
      tire_id: "",
      tire: null,
      works: [],
      pageP: null,
      pageN: null,
      total: 0,
      brand: null,
      model: null,
      dimention: null,
    };
  }

  componentDidMount() {
    this.getTires();
    if (this.props.match.params.id !== ":id") {
      this.setState({ tire_id: this.props.match.params.id });
      this.getWorks(this.props.match.params.id, 1);
    } else {
      this.setState({ tire_id: "", works: "", total: 0 });
    }
  }

  getSuggestions = (value) => {
    let tires = this.state.tires;
    let fmtSuggestions = tires.filter((tire) => {
      const fmtValue = escapeRegexCharacters(value);
      return tire.codigo.toLowerCase().search(fmtValue) >= 0;
    });
    return fmtSuggestions.slice(0, 20);
  };

  onTiresFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onTiresClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  getTires = () => {
    return trackPromise(
      fetch(
        `${APIS.API_REST_TIRES_MIN_INFO.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        )}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-1"));
          }
          return response.json();
        })
        .then((json) => {
          this.setState({ tires: json.tires });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.work-tire-item-1"));
        })
    );
  };

  getWorks = (tire_id, page, limit) => {
    var str = "";
    let total = 0;
    let pageP, pageN, works;
    if (page !== "0" && !!page) {
      // str = "?page=" + page + "&limit="+limit + "&by_inspection=false";
      str = "?page=" + page + "&limit=" + limit;
    } else {
      // str = "?page=1&limit=50&by_inspection=false";
      str = "?page=1&limit=50";
    }
    return trackPromise(
      fetch(
        `${
          APIS.API_REST_TIRE_WORK.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("tire_id", tire_id) + str
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-2"));
          }
          return response.json();
        })
        .then((json) => {
          if (!!json.previus) {
            str = json.previus;
            pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          if (!!json.next) {
            str = json.next;
            pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          total = json.total;
          works = json.works;
          //this.setState({ works: json.works, pageP: pageP, pageN: pageN, total: json.total });
        })
        .then(() =>
          fetch(
            `${APIS.API_REST_TIRES.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            )}/${tire_id}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          )
        )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-3"));
          }
          return response.json();
        })
        .then((json) => {
          this.setState({
            works: works,
            pageP: pageP,
            pageN: pageN,
            total: total,
            brand: json.marca,
            model: json.modelo,
            dimention: json.dimension,
            tire: json,
          });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.work-tire-item-2"));
        })
    );
  };

  deleteWork = (tire_id, id) => {
    return trackPromise(
      fetch(
        `${APIS.API_REST_TIRE_WORK.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("tire_id", tire_id)}/${id}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "DELETE",
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-4"));
          }
          return response.json();
        })
        .then((json) => {
          toast.success(this.props.t("alerts.work-tire-item-10"));
          this.getWorks(tire_id, 1);
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.work-tire-item-1"));
        })
    );
  };

  getWorksList = (works, pageP, pageN, authUser) => {
    if (!works) return null;
    const { search } = this.state;
    let pages = [];
    let start, end;
    let totalPages = Math.ceil(this.state.total / this.state.limit);
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
      start = Math.max(1, this.state.page - 4);
      end = Math.min(totalPages, this.state.page + 5);

      if (start === 1) {
        end = 10;
      } else if (end === totalPages) {
        start = totalPages - 9;
      }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("work-tire.client-item-1")}</th>
              <th scope="col">{this.props.t("work-tire.client-item-2")}</th>
              <th scope="col">{this.props.t("work-tire.client-item-3")}</th>
              <th scope="col">{this.props.t("work-tire.client-item-4")}</th>
            </tr>
          </thead>
          <tbody>
            {works.map((work) => {
              return (
                <tr key={work.id}>
                  <td>
                    {WORK_TYPES.map((type) => {
                      if (type.value === work.worktype.toLowerCase()) {
                        return this.props.t(`selectors.${type.name}`);
                      }
                      return null;
                    })}
                  </td>
                  <td>{work.cost}</td>
                  <td>
                    {moment
                      .utc(work.work_date)
                      .local()
                      .locale("es")
                      .format("LLL")}
                  </td>
                  <td>
                    {!!authUser.permissions.edit ? (
                      <Link
                        to={
                          "/works/tires/edit/" +
                          this.state.tire_id +
                          "/" +
                          work.id
                        }
                        className="btn btn-primary"
                      >
                        <i className="fa fa-edit"></i>{" "}
                        {this.props.t("globals.edit")}
                      </Link>
                    ) : null}
                    &nbsp;
                    {!!authUser.permissions.delete ? (
                      <button
                        onClick={() =>
                          this.deleteWork(this.state.tire_id, work.id)
                        }
                        className="btn btn-danger"
                      >
                        <i className="fa fa-trash"></i>{" "}
                        {this.props.t("globals.delete")}
                      </button>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
          {works.length > 0 ? (
            <Pagination
              colspan="4"
              pageP={pageP}
              pageN={pageN}
              page={this.state.page}
              limit={this.state.limit}
              links={links}
              submit={(page, limit) => {
                this.setState({ page: page, limit: limit }, () => {
                  this.getWorks(this.state.tire_id, page, limit);
                });
              }}
            />
          ) : null}
        </table>
      </div>
    );
  };

  onChange = (event, { newValue }) => {
    if (typeof newValue === "object" && newValue !== null) {
      this.setState({ tire_code: newValue.codigo });
    } else {
      let tires = this.state.tires;
      for (let i = 0; i < tires.length; i++) {
        if (tires[i].codigo === newValue) {
          this.setState({ tire_code: tires[i].codigo });
          break;
        } else {
          this.setState({
            tire_code: newValue,
          });
        }
      }
    }
  };

  onSelectTire = () => {
    let tires = this.state.tires;
    for (let i = 0; i < tires.length; i++) {
      if (tires[i].codigo === this.state.tire_code) {
        this.props.history.push("/works/tires/" + tires[i].id);
        this.setState({ tire_id: tires[i].id });
        this.getWorks(tires[i].id, 1);
        break;
      }
    }
  };

  render() {
    const tireProps = {
      placeholder: this.props.t("alerts.work-tire-item-5"),
      value: this.state.tire_code,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "1%",
        marginBottom: 0,
      },
      onChange: this.onChange,
    };
    let isInvalidTireId = this.state.tire_code === "";
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-sm-8">
                <h3>{this.props.t("work-tire.client-item-5")}</h3>
              </div>
              <div className="col-sm-4 input-group">
                <Autosuggest
                  suggestions={this.state.suggestions}
                  onSuggestionsFetchRequested={this.onTiresFetchRequested}
                  onSuggestionsClearRequested={this.onTiresClearRequested}
                  shouldRenderSuggestions={() => true}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={tireProps}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary text-white"
                    onClick={this.onSelectTire}
                    disabled={isInvalidTireId}
                  >
                    <i className="fa fa-search"></i>{" "}
                    {this.props.t("work-tire.client-item-6")}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                {!!authUser.permissions.create ? (
                  !!this.state.tire_id ? (
                    <Link
                      to={"/works/tires/new/" + this.state.tire_id}
                      className="btn btn-primary"
                    >
                      <i className="fa fa-plus"></i>{" "}
                      {this.props.t("globals.register")}
                    </Link>
                  ) : null
                ) : null}
              </div>
              <div className="col-md-6">
                <p className="pull-right">
                  {!!this.state.tire
                    ? "Codigo al calor: " + this.state.tire.codigo
                    : null}
                </p>
                <p className="pull-right">
                  {!!this.state.tire
                    ? "Posición: " +
                      this.state.tire.posicion_montaje +
                      " Montada en vehículo: " +
                      this.state.tire.vehicle_plate +
                      " - " +
                      this.state.tire.vehiculo
                    : null}
                </p>
                <p className="pull-right">
                  <b>
                    {!!this.state.brand
                      ? this.state.brand +
                        " " +
                        this.state.model +
                        " " +
                        this.state.dimention
                      : null}
                  </b>
                </p>
                <p className="pull-right">
                  {this.props.t("work-tire.client-item-7")}{" "}
                  <b>{!!this.state.total ? this.state.total : 0}</b>
                </p>
              </div>
            </div>
            <br />
            <br />
            {this.getWorksList(
              this.state.works,
              this.state.pageP,
              this.state.pageN,
              authUser
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(WorksList);
