import Q from "q";
import xlsx from "xlsx";
import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../../Auth/Session";
import ErrorsModal from "./ErrorsModal";
import DragDropFile from "./DragDropFile";
import DataInput from "./DataInput";
import OutTable from "./OutTable";

import * as ROUTES from "../../../../constants/routes";
import * as APIS from "../../../../constants/apis";
import * as VALIDATIONS from "../../../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;
const randomString = Math.random().toString(36);

const INITIAL_STATE = {
  inputKey: randomString,
  done: [],
  notDone: [],
  data: [],
  cols: [],
  vehicles: [],
  errors: []
};

class MultipleFuels extends Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();

    this.serverErrors = [];
    this.handleFile = this.handleFile.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.state = { ...INITIAL_STATE };
  };

  async componentDidMount() {
    let responseVehicles = await fetch(`${APIS.API_REST_VEHICLES_MIN_INFO.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    });
    let jsonVehicles = await responseVehicles.json();
    this.setState({ vehicles: jsonVehicles.vehicles });
  };

  getVehicleId = (plate) => {
    let vehicle = this.state.vehicles.find(x => x.plate === plate);
    if (vehicle) {
      return vehicle.id;
    } else {
      vehicle = this.state.vehicles.find(x => x.vehiclenumber === plate);
      if (vehicle) {
        return vehicle.id;
      } else {
        return null;
      }
    }
  };

  makeCols = refstr => {
    let o = [],
      C = xlsx.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = {
        name: xlsx.utils.encode_col(i),
        key: i
      }
    }
    return o;
  };

  getDataErrors = (cols, data) => {
    let errors = [];

    // Número de columnas
    if (cols.length !== 8) {
      errors.push({
        message: this.props.t("multiple.global-item-1")
      });
    }

    // Número de datos
    if (data.length < 2) {
      errors.push({
        message: this.props.t("multiple.global-item-2")
      });
    }

    // Columnas necesarias
    let fields = [
      this.props.t("multiple.advace-item-1"),
      this.props.t("multiple.advace-item-2"),
      this.props.t("multiple.advace-item-3"),
      this.props.t("multiple.advace-item-4"),
      this.props.t("multiple.advace-item-5"),
      this.props.t("multiple.advace-item-6"),
      this.props.t("multiple.advace-item-7"),
      this.props.t("multiple.advace-item-8")
    ];
    for (let i = 0; i < fields.length; i++) {
      if (data[0].indexOf(fields[i]) === -1) {
        errors.push({
          message: this.props.t("multiple.global-item-3") + fields[i] + this.props.t("multiple.global-item-4") + this.props.t("multiple.global-item-5")
        });
      }
    }

    // Validaciones de los datos
    for (let i = 1; i < data.length; i++) {
      let rowObject = {
        idx: (i + 1)
      };
      for (let j = 0; j < 8; j++) {
        data[i][j] = data[i][j]
        if (data[0][j] === this.props.t("multiple.advace-item-4")) {
          if (this.getVehicleId(data[i][j]) === null) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.advace-item-9") + data[0][j]
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.advace-item-1")) {
          if (moment(data[i][j], "YYYY-MM-DD").format("YYYY-MM-DD") !==
            data[i][j]) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.advace-item-10")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.advace-item-2")) {
          const reg = new RegExp("^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$");
          if (!reg.test(data[i][j])) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.advace-item-11")
            });
          }
        }
      }
    }

    return errors;
  };

  handleFile = file => {
    this.setState({ data: [], cols: [], done: [], notDone: [], errors: [] });

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = xlsx.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1, raw: false });
      const cols = this.makeCols(ws["!ref"]);
      const errors = this.getDataErrors(cols, data);
      if (errors.length === 0) {
        this.setState({
          data: data,
          cols: this.makeCols(ws["!ref"]),
          inputKey: Math.random().toString(36)
        });
      } else {
        this.modal.current.showModal(errors);
        this.setState({
          errors: [],
          data: [],
          cols: [],
          inputKey: Math.random().toString(36)
        });
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  exportTemplate = event => {
    event.preventDefault();

    let header = [
      this.props.t("multiple.advace-item-1"),
      this.props.t("multiple.advace-item-2"),
      this.props.t("multiple.advace-item-3"),
      this.props.t("multiple.advace-item-4"),
      this.props.t("multiple.advace-item-5"),
      this.props.t("multiple.advace-item-6"),
      this.props.t("multiple.advace-item-7"),
      this.props.t("multiple.advace-item-8")
    ];
    const ws = xlsx.utils.aoa_to_sheet([header]);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Plantilla");
    xlsx.writeFile(wb, this.props.t("multiple.advace-item-12") + ".xlsx")
  };

  formatData = (register) => {
    let data = {
      vehicle_ids: [{id:"",params:{}}],
      fc_ultima_inspeccion: "",
      tecnico: "",
    };
    let header = this.state.data[0];
    for (let i = 0; i < header.length; i++) {
      if (header[i] === this.props.t("multiple.advace-item-4")) {
        data.vehicle_ids[0].id = this.getVehicleId(register[i]);
        if(register[4]){
          data.vehicle_ids[0].params = { type: header[4], value: register[4] }
        }else if(register[5]){
          data.vehicle_ids[0].params = { type: header[5], value: register[5] }
        }else if(register[6]){
          data.vehicle_ids[0].params = { type: header[6], value: register[6] }
        }else if(register[7]){
          data.vehicle_ids[0].params = { type: header[7], value: register[7] }
        }
      } else if (header[i] === this.props.t("multiple.advace-item-1")) {
        data.fc_ultima_inspeccion =
          moment(register[i] + " " + register[i + 1]).utc().format();
      } else if (header[i] === this.props.t("multiple.advace-item-3")) {
        data.tecnico = register[i];
      }
    }

    return data;
  };

  saveRegister = (idx, register, authUser) => {
    let deferred = Q.defer();

    if (isInternetConnected(toast)) {
      deferred.reject();
      return deferred.promise;
    }

    let that = this;
    trackPromise(fetch(APIS.API_REST_INSPECTIONS_TECHNICIAN.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(that.formatData(register))
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        const done = [...that.state.done];
        done.push(idx);
        that.setState({ done: done });
        deferred.resolve(json);
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("multiple.global-item-6") +
            (idx + 1);
        } else {
          err = this.props.t("multiple.global-item-7") + (idx + 1) + ". " + error.toString();
        }
        that.serverErrors.push({ message: err });
        const notDone = [...that.state.notDone];
        notDone.push(idx);
        that.setState({ notDone: notDone });
        deferred.reject(that.serverErrors);
      }));

    return deferred.promise;
  };

  getCleanedRegisters = registers => {
    let newRegisters = [];
    for (let i = 1; i < registers.length; i++) {
      if (registers[i].length > 0) {
        newRegisters.push(registers[i]);
      }
    }

    return newRegisters;
  };

  async saveRegisters(registers, authUser) {
    let deferred = Q.defer();

    let newRegisters = this.getCleanedRegisters(registers);
    for (let i = 0; i < newRegisters.length; i++) {
      if (this.state.done.indexOf(i) === -1) {
        await this.saveRegister(i, newRegisters[i], authUser)
          .then(() => {
            return true;
          })
          .catch(errors => {
            deferred.reject(errors);
            return deferred.promise;
          });
      }
    }

    deferred.resolve();
    return deferred.promise;
  };

  onSubmit = (event, authUser) => {
    event.preventDefault();
    this.serverErrors = [];
    this.setState({ notDone: [] });

    trackPromise(this.saveRegisters(this.state.data, authUser)
      .then(() => {
        toast.success(this.props.t("multiple.global-item-8"));
        this.setState({
          data: [],
          cols: [],
          done: [],
          notDone: [],
          errors: []
        });
        this.props.history.push(ROUTES.INSPECTIONS_PREVIEW);
      })
      .catch(errors => {
        this.modal.current.showModal(errors);
      }));
  };

  render() {
    const { data, cols, done, notDone } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <ErrorsModal ref={this.modal} t={this.props.t} />
            <h3>{this.props.t("ins-prev.ins-list-7")}</h3>
            <br /><br />
            <DragDropFile handleFile={this.handleFile} t={this.props.t}>
              <form>
                <div>
                  <DataInput t={this.props.t} handleFile={this.handleFile}
                    exportTemplate={this.exportTemplate}
                    inputKey={this.state.inputKey || ""} />
                  <OutTable data={data}
                    cols={cols}
                    done={done}
                    notDone={notDone} />
                  {data.length > 1 ?
                    <div className="row">
                      <div className="col-md-12 mt-5">
                        <button type="submit"
                          className="btn btn-primary"
                          style={{ width: "100%" }}
                          onClick={(event) => this.onSubmit(event, this.props.firebase)}>
                          <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                        </button>
                      </div>
                    </div> : null}
                </div>
              </form>
            </DragDropFile>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(MultipleFuels);
