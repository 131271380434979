import React, { Component } from "react";

const SheetJSFT = ["xlsx"].map(function (x) { return "." + x; }).join(",");

class DataInput extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  };

  handleChange = event => {
    const files = event.target.files;
    if (files && files[0]) {
      this.props.handleFile(files[0]);
    }
  };

  render() {
    const { inputKey, exportTemplate, header, type, novelties } = this.props;

    return (
      <div className="form-group">
        <div className="row">
          <div className={type === "Registrar con descarte" ?
            "col-md-4" : "col-md-8"}>
            <input
              type="file"
              key={inputKey}
              className="form-control"
              id="file"
              style={{ height: "calc(1.5em + .75rem + 7px)" }}
              accept={SheetJSFT}
              onChange={this.handleChange}
            />
          </div>
          <div className={type === "Registrar con descarte" ?
            "col-md-4" : "hidden"}>
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#wasteModal"
              style={{ width: "100%" }}>
              <i className="fa fa-trash"></i> {this.props.t("distance.client-noveltie-6")}
            </button>
            {getWasteModal(novelties)}
          </div>
          <div className="col-md-4">
            <button
              className="btn btn-primary"
              onClick={exportTemplate}
              style={{ width: "100%" }}
              disabled={!header.length}>
              <i className="fa fa-download"></i> {this.props.t("globals.download-excel")}
            </button>
          </div>
        </div>
      </div>
    );
  };
};

const getWasteTable = (novelties) => (
  <table className="table table-striped table-bordered table-sm text-center">
    <thead>
      <tr>
        <th scope="col">Código Ruedata</th>
        <th scope="col">Seccion</th>
        <th scope="col">Motivo</th>
        <th scope="col">Causa</th>
      </tr>
    </thead>
    <tbody>
      {
        novelties.map((waste, idx1) => {
          return waste.novedades.map((novelty, idx2) => {
            return novelty.posibles_causas.map((cause, idx3) => {
              return <tr key={("" + idx1) + ("" + idx2) + ("" + idx3)}>
                <td>{novelty.codigo_novedad}</td>
                <td>{waste.seccion_llanta}</td>
                <td>{novelty.novedad}</td>
                <td>{cause.posible_causa}</td>
              </tr>
            })
          })
        })
      }
    </tbody>
  </table>
);

const getWasteModal = (novelties) => (
  <div
    className="modal fade"
    id="wasteModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="wasteLabel"
    aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title"
            id="wasteLabel">
            Valores de Desecho
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h3>Diccionario de Desechos</h3>
          {getWasteTable(novelties)}
        </div>
      </div>
    </div>
  </div>
);

export default DataInput;
