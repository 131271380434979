import moment from "moment";
import "moment/locale/es";
import { trackPromise } from "react-promise-tracker";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import Autosuggest from "react-autosuggest";
import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";
import * as FUNCIONES from "../../../constants/funciones";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const escapeRegexCharacters = str =>
  (str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

const getSuggestionValue = suggestion => suggestion;

const renderSuggestion = (suggestion, idx) => (
  <div>
    {suggestion.plate + " - " + suggestion.vehiclenumber}
  </div>
);

class Inspections extends React.Component {
  constructor() {
    super();
    this.state = { fecha_inicial: null, fecha_final: null, vehicle_plates: [""], currentIndex: -1, suggestions: [], inspections: null, vehicle_ids: [], fc_ultima_inspeccion: null, tecnico: null, technicians: [], vehicles: [] };
  };

  componentDidMount() {
    this.getTechnicians();
    this.getVehicles();
    this.getInspectionsCompare();
  };

  getTechnicians = () => {
    trackPromise(fetch(APIS.API_REST_TECHNICIANS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json())
      .then(json => {
        this.setState({ technicians: json.Users });
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.ins-one-item-28")
        }
        toast.error(err.toString());
        this.setState({
          error: err
        });
      }));
  };

  generateExcel(type) {
    var str = "?export=true" 
    var url
    if(!!this.state.fecha_inicial && !!this.state.fecha_final){
      str = str + "&fecha_inicial=" + this.state.fecha_inicial + "&fecha_final=" + this.state.fecha_final
    }
    if (type === 0) {
      url = APIS.API_REST_INSPECTIONS_TECHNICIAN_UPLOADED.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + str
    }else if(type === 1){
      url = APIS.API_REST_INSPECTIONS_PENDING_LIST.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + str
    }else{
      url = APIS.API_REST_INSPECTIONS_PENDING_LIST_ANALIST.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + str
    }
    return trackPromise(fetch(url, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(this.props.t("alerts.ins-one-item-73"));
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.props.t("alerts.ins-one-item-86")}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success(this.props.t("alerts.ins-one-item-75"));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  getVehicles = () => {
    trackPromise(fetch(APIS.API_REST_VEHICLES_MIN_INFO.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        this.setState({ vehicles: json.vehicles });
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.ins-one-item-28")
        }
        toast.error(err.toString());
        this.setState({
          error: err
        });
      }));
  };

  getInspectionsCompare = () => {
    var str = "" 
    if(!!this.state.fecha_inicial && !!this.state.fecha_final){
      str = "?fecha_inicial=" + this.state.fecha_inicial + "&fecha_final=" + this.state.fecha_final
    }
    return trackPromise(fetch(`${APIS.API_REST_INSPECTIONS_COMPARE.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + str}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(this.props.t("alerts.ins-one-item-87"))
        }
        return response.json();
      })
      .then(json => {
        this.setState({ inspections: json.inspection_compare });
      })
      .catch(error => {
        throw new Error(this.props.t("alerts.ins-one-item-87"))
      }))
  };

  onSubmit = (event) => {
    if(!!event) event.preventDefault();
    
    const data = { vehicle_ids: this.state.vehicle_ids, fc_ultima_inspeccion: moment(this.state.fc_ultima_inspeccion).utc().format(), tecnico: this.state.tecnico };
    return trackPromise(fetch(`${APIS.API_REST_INSPECTIONS_TECHNICIAN.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
        headers:{
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "POST",
        body: JSON.stringify(data) 
      })      
      .then(response => {
        return response.json()
      })
      .then(json => {
        if(!!json.message) {
          toast.error(json.message);
        }else{
          this.getInspectionsCompare();
          toast.success(this.props.t("alerts.ins-one-item-89"));
          this.setState({ vehicle_ids: [], vehicle_plates: [], fc_ultima_inspeccion: "", tecnico: "", currentIndex: -1 });
        }
      })
      .catch(error => {
        throw new Error(this.props.t("alerts.ins-one-item-88"))
      }))
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onChangePlate = (event, { newValue }) => {
    let vehicle_plates = [...this.state.vehicle_plates];
    let vehicle_plate = {...vehicle_plates[this.state.currentIndex]};
    let vehicle_ids = [...this.state.vehicle_ids];
    let vehicle_id = {...vehicle_ids[this.state.currentIndex]};
    if (typeof newValue === "object" && newValue !== null) {
      vehicle_plate = newValue.plate + " - " + newValue.vehiclenumber;
      vehicle_plates[this.state.currentIndex] = vehicle_plate;
      vehicle_id = newValue.id;
      vehicle_ids[this.state.currentIndex].id = vehicle_id;
      this.setState({
        vehicle_plates,
        vehicle_ids
      });
    } else {
      let vehicles = this.state.vehicles;
      for (let i = 0; i < vehicles.length; i++) {
        if (vehicles[i].plate.toLowerCase() === newValue.toLowerCase()) {
          vehicle_plate = vehicles[i].plate + " - " + vehicles[i].vehiclenumber;
          vehicle_plates[this.state.currentIndex] = vehicle_plate;
          vehicle_id = vehicles[i].id;
          vehicle_ids[this.state.currentIndex].id = vehicle_id;
          this.setState({
            vehicle_plates,
            vehicle_ids
          });
          break;
        }else{
          vehicle_plate = newValue;
          vehicle_plates[this.state.currentIndex] = vehicle_plate;
          vehicle_id = 0;
          vehicle_ids[this.state.currentIndex].id = vehicle_id;
          this.setState({
            vehicle_plates,
            vehicle_ids
          });
        }
      }
    }
  };

  onPlatesFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onPlatesClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
  };

  getSuggestions = value => {
    let vehicles = this.state.vehicles;
    let fmtSuggestions = vehicles.filter(car => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase());
      return car.plate.toLowerCase().search(fmtValue) >= 0 || car.vehiclenumber.toLowerCase().search(fmtValue) >= 0;
    });
    return fmtSuggestions.slice(0, 20);
  };

  addRow = () => {
    let plates = [...this.state.vehicle_plates, ""];
    let vehicle_ids = this.state.vehicle_ids;
    vehicle_ids.push({id: null, params: {type: null, value: null}})
    console.log(vehicle_ids)
    this.setState({ vehicle_ids, vehicle_plates: plates, currentIndex: this.state.currentIndex + 1 }); 
  }

  deleteRow = (idx) => {
    let vehicle_plates = [...this.state.vehicle_plates];
    vehicle_plates.splice(idx,1)
    let vehicle_ids = [...this.state.vehicle_ids];
    vehicle_ids.splice(idx,1)
    this.setState({ vehicle_ids: vehicle_ids, vehicle_plates: vehicle_plates, currentIndex: this.state.currentIndex - 1 }); 
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeParams = (item, index, event) => {
    let vehicle_ids = [...this.state.vehicle_ids];
    vehicle_ids[index].params[item] = event.target.value
    this.setState({ vehicle_ids });
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-6">
                <h3>
                  {this.props.t("ins-prev.ins-item-1")}
                </h3>
              </div>
              <div className="col-md-6 text-right">
                <div className="row">
                  <div className="form-group col-md-6">
                    <div className="row float-right">
                      <div className="col-sm-2">
                        <label htmlFor="fechaInicial">{this.props.t("ins-prev.ins-item-2")}</label>
                      </div>
                      <div className="col-sm-10">
                        <input className="form-control"
                          placeholder={this.props.t("ins-prev.ins-item-2")}
                          id="fechaInicial"
                          name="fecha_inicial"
                          value={this.state.fecha_inicial}
                          type="date"
                          onChange={this.onChange}
                          required />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="fechaFinal">{this.props.t("ins-prev.ins-item-3")}</label>
                      </div>
                    <div className="col-sm-10 input-group mb-3">
                        <input
                          aria-describedby="btnSearch"
                          className="form-control"
                          type="date"
                          id="fecha_final"
                          name="fecha_final"
                          value={this.state.fecha_final}
                          placeholder={this.props.t("ins-prev.ins-item-3")}
                          onChange={this.onChange} />
                        <div className="input-group-append">
                          <button className="btn btn-primary" id="btnSearch"
                            onClick={(event) => this.getInspectionsCompare()}>
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 pull-right">
                <Link to={"/inspections/preview/multiple"}
                      className="btn btn-primary">
                  <i className="fa fa-upload"></i> {this.props.t("ins-prev.ins-item-4")}
                </Link>
                &nbsp;
                <Link to={"/inspections/preview/list?fecha_inicial=" + this.state.fecha_inicial + "&fecha_final=" + this.state.fecha_final}
                      className="btn btn-primary">
                  <i className="fa fa-eye"></i> {this.props.t("ins-prev.ins-item-5")}
                </Link>
                &nbsp;
                <button className="btn btn-success" onClick={(event) => this.generateExcel(1)}>
                  <i className="fa fa-file-excel"></i> {this.props.t("ins-prev.ins-item-6")}
                </button>
                &nbsp;
                <button className="btn btn-success" onClick={(event) => this.generateExcel(2)}>
                  <i className="fa fa-file-excel"></i> {this.props.t("ins-prev.ins-item-7")}
                </button>
                &nbsp;
                <button className="btn btn-success" onClick={(event) => this.generateExcel(0)}>
                  <i className="fa fa-file-excel"></i> {this.props.t("ins-prev.ins-item-8")}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <br />
                <table className="table table-bordered table-sm">
                  <thead>
                    <tr className="bg-primary text-white">
                      <th>{this.props.t("ins-prev.ins-item-9")}</th>
                      <th>{this.props.t("ins-prev.ins-item-10")}</th>
                      <th>{this.props.t("ins-prev.ins-item-11")}</th>
                      <th>{this.props.t("ins-prev.ins-item-12")}</th>
                      <th>{this.props.t("ins-prev.ins-item-13")}</th>
                      {/* <th>{this.props.t("ins-prev.ins-item-14")}</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{ !!this.state.inspections ? this.state.inspections.total_vehiculos : 0 }</td>
                      <td>{ !!this.state.inspections ? this.state.inspections.vehiculos_recibidos : 0 }</td>
                      <td>{ !!this.state.inspections ? this.state.inspections.vehiculos_pendientes_tecnico : 0 }</td>
                      <td>{ !!this.state.inspections ? FUNCIONES.abbreviateNumber(this.state.inspections.cumplimiento_tecnico) + "%" : 0 }</td>
                      <td>{ !!this.state.inspections ? this.state.inspections.vehiculos_pendientes_analista : 0 }</td>
                      {/* <td>{ !!this.state.inspections ? FUNCIONES.abbreviateNumber(this.state.inspections.cumplimiento_analista) + "%" : 0 }</td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-md-12">
                <h3>{this.props.t("ins-prev.ins-item-15")}</h3>
                <br />
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="fcUltimaInspeccion">{this.props.t("ins-prev.ins-item-16")}</label>
                      </div>
                      <div className="col-sm-10">
                          <input className="form-control"
                              placeholder={this.props.t("ins-prev.ins-item-16")}
                              id="fcUltimaInspeccion"
                              name="fc_ultima_inspeccion"
                              value={this.state.fc_ultima_inspeccion}
                              type="datetime-local"
                              onChange={this.onChange}
                              required />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="tecnicoInput">{this.props.t("ins-prev.ins-item-17")}</label>
                      </div>
                      <div className="col-sm-10">
                        <select
                        className="form-control"
                        id="tecnicoInput"
                        name="tecnico"
                        value={this.state.tecnico}
                        onChange={this.onChange}>
                        <option value="">
                        {this.props.t("ins-prev.ins-item-18")}
                        </option>
                        {
                          !!this.state.technicians && this.state.technicians.map(technician => {
                            return <option
                              key={technician.email}
                              value={technician.email}>
                              {technician.name + " " + technician.email}
                            </option>;
                          })
                        }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="platesInput">{this.props.t("ins-prev.ins-item-19")}</label>
                      </div>
                      <div className="col-sm-10 input-group mb-3">
                        <p onClick={(e)=> this.addRow()} className="btn btn-primary float-right">
                          <i className="fa fa-plus" /> {this.props.t("ins-prev.ins-item-20")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.vehicles.length > 0 && this.state.vehicle_ids.length > 0 && this.state.vehicle_ids.map((vehicle_id, idx) => {
                      return <div className="row">
                              <div className="col-sm-12 input-group mb-3">
                                <Autosuggest
                                  id={idx}
                                  suggestions={this.state.suggestions}
                                  onSuggestionsFetchRequested={this.onPlatesFetchRequested}
                                  onSuggestionsClearRequested={this.onPlatesClearRequested}
                                  onSuggestionSelected={this.onSuggestionSelected}
                                  shouldRenderSuggestions={() => (true)}
                                  getSuggestionValue={getSuggestionValue}
                                  renderSuggestion={renderSuggestion}
                                  inputProps={{
                                        placeholder: "Vehículo",
                                        value: this.state.vehicle_plates[idx],
                                        className: "form-control",
                                        style: {
                                          position: "relative",
                                          WebkitBoxFlex: 1,
                                          msFlex: "1 1 auto",
                                          flex: "1 1 auto",
                                          width: "1%",
                                          marginBottom: 0
                                        },
                                        onChange: this.onChangePlate,
                                  }}
                                />
                                <select
                                  className="form-control"
                                  name="tipo"
                                  value={vehicle_id.params.type}
                                  onChange={(e) => this.onChangeParams("type", idx, e)}>
                                  <option value="">
                                  {this.props.t("ins-prev.ins-item-21")}
                                  </option>
                                  <option>{this.props.t("ins-prev.ins-item-22")}</option>
                                  <option>{this.props.t("ins-prev.ins-item-23")}</option>
                                  <option>{this.props.t("ins-prev.ins-item-24")}</option>
                                  <option>{this.props.t("ins-prev.ins-item-25")}</option>
                                </select>
                                <input className="form-control"
                                  placeholder="Valor"
                                  value={vehicle_id.params.value}
                                  name="valor"
                                  type="text"
                                  onChange={(e) => this.onChangeParams("value", idx, e)} />
                                <div className="input-group-append">
                                  <button type="button" className="btn btn-danger"
                                    onClick={(event) => this.deleteRow(idx)}>
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                    })
                  }
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button type="submit"
                              className="btn btn-primary">
                        <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>  
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Inspections);
