import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as constantsPlan from "../../../constants/actions/Plans";
import * as APIS from "../../../constants/apis";
import PlanDeleteItem from "./PlanDeleteItem";

function getPlans(planes, next, previus, plan, page, search) {
  let previusReq, nextReq, planesReq = [], total = 0;
  return dispatch => {
    var str = "";
    if (page !== "0") {
      str = "?page=" + page + "&limit=20";
      if (search !== "")
        str = str + "&query=" + search
    }
    return trackPromise(fetch(APIS.API_REST_PLANS_HISTORICS.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Error en el servidor al obtener planes." +
            " Por favor inténtalo de nuevo.")
        }
        return response.json();
      })
      .then(json => {
        planesReq = json
        previusReq = json.previus
        nextReq = json.next
        total = json.total
        dispatch(getPlansSuccess({
          previus: previusReq,
          next: nextReq,
          total: total,
          planes: planesReq,
          plan: plan
        }));
      })
      .catch(error => {
        dispatch(getReqError({
          planes: planes,
          plan: plan,
          previus: previus,
          next: next,
          total: total,
          error: error.message
        }));
      }));
  };
};

export function getPlansSuccess(json) {
  return {
    type: constantsPlan.GET_PLANS_SUCCESS,
    json
  };
};

export function getReqError(json) {
  return {
    type: constantsPlan.GET_REQUEST_ERROR,
    json
  };
};

class PlansList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  };

  componentDidMount() {
    const { planes, next, previus, plan } = this.props;
    this.props.getPlans(planes, next, previus, plan, 1, "");
  };

  getPlansList = (planes, next, previus, plan, authUser) => {
    const { search } = this.state;
    if (!planes) return null;
    let pageP, pageN, str;
    if (!!previus) {
      str = previus;
      pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    if (!!next) {
      str = next;
      pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Fecha</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {
              planes.map(plan => {
                return <tr key={plan.id}>
                  <td>
                    {plan.name}
                  </td>
                  <td>
                    {moment.utc(plan.created_at).local().locale("es").format("LLL")}
                  </td>
                  <td>
                    <Link to={"/reports/plans/" + plan.plan_id}
                      className="btn btn-primary">
                      <i className="fa fa-eye"></i> Ver
                  </Link>&nbsp;
                  {!!authUser.permissions.delete ?
                      <PlanDeleteItem
                        planDelete={plan}
                      /> : null}
                  </td>
                </tr>
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td>
                {
                  !!previus ?
                    <button
                      className="btn btn-primary"
                      onClick={(e) => this.props.getPlans(planes, next, previus, plan, pageP, search)}>
                      <i className="fa fa-arrow-left"></i>
                    </button>
                    :
                    <button
                      className="btn btn-primary"
                      disabled>
                      <i className="fa fa-arrow-left"></i>
                    </button>
                }
              </td>
              <td>
                {
                  !!next ?
                    <button
                      className="btn btn-primary"
                      onClick={(e) => this.props.getPlans(planes, next, previus, plan, pageN, search)}>
                      <i className="fa fa-arrow-right"></i>
                    </button>
                    :
                    <button
                      className="btn btn-primary"
                      disabled>
                      <i className="fa fa-arrow-right"></i>
                    </button>
                }
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };
  
  onChange = (event) => {
    this.setState({
      search: event.target.value
    });
  };

  render() {
    const { planes, plan, next, previus, total, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <div className="row">
              <div className="col-md-8">
                <h3>Historico de Planes</h3>
              </div>
              <div className="col-md-4 pull-right">
                <div className="input-group mb-3 flex-nowrap">
                  <input
                    aria-describedby="btnSearch"
                    className="form-control"
                    type="text"
                    id="search"
                    placeholder="Buscar..."
                    onChange={this.onChange}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" id="btnSearch"
                      onClick={(event) => this.props.getPlans(planes, next, previus, plan, 1, this.state.search)}>
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br />
            {this.getPlansList(planes, next, previus, plan, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  planes: state => state.planes,
  plan: state => state.plan,
  next: state => state.next,
  previus: state => state.previus,
  total: state => state.total,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getPlans };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(PlansList));
