import { DATA_CUSTOMERS_SUCCESS, DATA_REQUEST_ERROR, SET_LOADING_CUSTOMER, SET_LOADING_CUSTOMER_SUCCES, DATA_CUSTOMERS_MACRO_SUCCES, DATA_CUSTOMERS_SEARCH_SUCCESS, DATA_CUSTOMERS_SEARCH_CLEAR_SUCCESS, DATA_CUSTOMERS_PAGINATION_SUCCESS } from '../types/CustomerTypes'

const INITIAL_STATE = {
    customers: null,
    isLoading: true,
    customersMacro: [],
    searched: null,
    customers2: [],
    next: null,
    total: null,
    previus: null,
}


export const customersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DATA_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload,
                isLoading: false
            }
        case SET_LOADING_CUSTOMER:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_LOADING_CUSTOMER_SUCCES:
            return {
                ...state,
                isLoading: action.payload
            }
        case DATA_CUSTOMERS_MACRO_SUCCES:
            return {
                ...state,
                customersMacro: action.payload
            }
        case DATA_CUSTOMERS_SEARCH_SUCCESS:
            return {
                ...state,
                searched: action.payload
            }
        case DATA_CUSTOMERS_SEARCH_CLEAR_SUCCESS:
            return {
                ...state,
                searched: action.payload
            }
        case DATA_CUSTOMERS_PAGINATION_SUCCESS:
            return {
                ...state,
                customers2: action.payload.customers,
                total: action.payload.total,
                next: action.payload.next,
                previus: action.payload.previus,
            }
        default:
            return state
    }
}