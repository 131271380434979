import $ from "jquery";
import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import SignOutButton from "../Auth/SignOut";
import { AuthUserContext } from "../Auth/Session";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import API from "../../constants/api";
import * as VARIABLES from "../../constants/variables";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { initSegment } from "../../constants/kompassify";
import { isSuperAdmin } from "../../constants/validations";
import { CUSTOMERSMACRO } from "../../constants/routes";
import StandByMultiple from "../Multiple/StandByMultiple";
import CustomerPilotTrial from "../Customers/CustomerPilotTrial";
import DropZone from "../Multiple/DropZone";
import Inspections from "../Multiple/selects/Inspections";
import Notifications from "../notifications/Notifications";
import Suspensions from "../notifications/itemsComponents/Suspensions";

var logo = "../../images/logo.png";
var url = window.location.search;
var qArray = url.split("&"); //get key-value pairs
for (var i = 0; i < qArray.length; i++) {
  var pArr = qArray[i].split("="); //split key and value
  console.log("parame " + pArr);
  if (pArr[0] == "?logo") {
    console.log("param " + pArr[1] + ".png");
    localStorage.setItem("logo", "../../images/" + pArr[1] + ".png");
    logo = "../../images/" + pArr[1] + ".png";
  }
}
if (localStorage.getItem("logo")) {
  logo = localStorage.getItem("logo");
}

const Navigation = () => {
  const [t, i18n] = useTranslation("global");

  const customerName = !!localStorage.getItem("GlobalCustomerName")
    ? localStorage.getItem("GlobalCustomerName")
    : t("globals.customer");

  return (
    <div>
      <AuthUserContext.Consumer>
        {(authUser) =>
          !!authUser ? (
            <NavigationAuth
              authUser={authUser}
              globalCustomer={
                (sessionStorage.getItem("GlobalMacroSelectedName") &&
                  window.location.pathname == "/macros/macro/reports") ||
                (sessionStorage.getItem("GlobalMacroSelectedName") &&
                  window.location.pathname == "/macros/reports")
                  ? sessionStorage.getItem("GlobalMacroSelectedName")
                  : customerName
              }
              showDisplay={
                (sessionStorage.getItem("GlobalMacroSelectedName") &&
                  window.location.pathname == "/macros/macro/reports") ||
                (sessionStorage.getItem("GlobalMacroSelectedName") &&
                  window.location.pathname == "/macros/reports")
                  ? "none"
                  : localStorage.getItem("GlobalMacroSelectedName") &&
                    customerName == "Escoge un cliente global"
                  ? "none"
                  : sessionStorage.getItem("GlobalMacroSelectedName") &&
                    customerName == "Escolher um cliente global"
                  ? "none"
                  : ""
              }
            />
          ) : (
            <NavigationNonAuth authUser={authUser} />
          )
        }
      </AuthUserContext.Consumer>
    </div>
  );
};

const setLanguageSelected = (lan, i18n, t) => {
  i18n.changeLanguage(lan);
  toast.success(t("alerts.nav-item-2"));
  localStorage.setItem("LanguageSelected", lan);
};

const displayMessage = (t) => {
  toast.warn(t("alerts.nav-item-1"));
};

const NavigationAuth = ({ authUser, globalCustomer, showDisplay }) => {
  const [t, i18n] = useTranslation("global");
  const [showModalMassive, setShowModalMassive] = useState(false);
  const [showModalNotifications, setShowModalNotifications] = useState(false);
  const [pendingCount, setPendingCount] = useState({
    pending: localStorage.getItem("GlobalPendingCount"),
    pendingWaste: localStorage.getItem("GlobalPendingCountScrap"),
    pendingKm: localStorage.getItem("GlobalPendingWithoutKmCount"),
    pendingAnalist: localStorage.getItem("GlobalPendingAnalist"),
    pendingRoation: localStorage.getItem("GlobalPendingRotation"),
  });

  const customerName = !!localStorage.getItem("GlobalCustomerName")
    ? localStorage.getItem("GlobalCustomerName")
    : t("globals.customer");

  const refreshCounter = (localItem) => {
    let id = localStorage.getItem("GlobalCustomerId");
    if (localItem === "GlobalPendingCount") {
      API.getPendingCount(id).then((resPending) => {
        localStorage.setItem("GlobalPendingCount", resPending.count);
        localStorage.setItem("GlobalPendingCountScrap", resPending.count_waste);
        setPendingCount({
          pending: resPending.count,
          pendingWaste: resPending.count_waste,
          pendingKm: pendingCount.pendingKm,
          pendingAnalist: pendingCount.pendingAnalist,
        });
      });
    }

    if (localItem === "GlobalPendingRotation") {
      API.getPendingRotationCount(id).then((resRotation) => {
        localStorage.setItem("GlobalPendingRotation", resRotation.count);
        setPendingCount({
          pending: pendingCount.pending,
          pendingWaste: pendingCount.pendingWaste,
          pendingRoation: resRotation.count,
          pendingAnalist: pendingCount.pendingAnalist,
          pendingKm: pendingCount.pendingKm,
        });
      });
    }

    if (localItem === "GlobalPendingWithoutKmCount") {
      API.getPendingWithoutCount(id).then((reswithout) => {
        localStorage.setItem("GlobalPendingWithoutKmCount", reswithout.count);
        setPendingCount({
          pending: pendingCount.pending,
          pendingWaste: pendingCount.pendingWaste,
          pendingKm: reswithout.count,
          pendingAnalist: pendingCount.pendingAnalist,
        });
      });
    }

    if (localItem === "GlobalPendingAnalist") {
      API.getPendingAnalistCount(id).then((resAnalist) => {
        localStorage.setItem(
          "GlobalPendingAnalist",
          resAnalist.Vehiculos.length
        );
        setPendingCount({
          pending: pendingCount.pending,
          pendingWaste: pendingCount.pendingWaste,
          pendingKm: pendingCount.pendingKm,
          pendingAnalist: resAnalist.Vehiculos.length,
        });
      });
    }
  };

  if (
    localStorage.getItem("logo") ||
    window.location.search == "?logo=arsenal" ||
    window.location.search == "?logo=pulpomatic"
  ) {
    $(function () {
      setTimeout(() => {
        console.log("param " + $("#clgo").html());
        $("#clgo").hide();
        $("#clgo-wsp").hide();
      }, 1000);
    });
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      {console.log("print: " + window.location.pathname)}
      <span></span>
      <Link className="navbar-brand" id="hola" to={ROUTES.LANDING}>
        <img src={logo} alt="Ruedata - Home" height="50" />
      </Link>
      v1.62
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      {
        (console.log("print: " + customerName),
        customerName !== "Escoge un cliente global" ||
        window.location.pathname === "/customers" ||
        customerName !== "Escolher um cliente global"
          ? null
          : displayMessage(t))
      }
      {/* <div className="kompassify_alerts">
        <i class="fas fa-bell bell-2"></i>
      </div> */}
      {authUser.roles === ROLES.ANALYST || authUser.roles === ROLES.ADMIN ? (
        <StandByMultiple />
      ) : null}
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          {/* <li>
            <Link className="nav-link" to={ROUTES.HELP}>
              <i class="fas fa-graduation-cap"></i>
            </Link>
          </li> */}

          {/* {localStorage.getItem("logo") ? null : (
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="helpDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-question-circle"></i>
                {t("navigation.help-text")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="helpDropdownMenuLink"
              >
                <a
                  href="https://ruedata.atlassian.net/servicedesk/customer/portal/4"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="dropdown-item"
                >
                  {t("navigation.support-text")}
                </a>
                <a href="http://academia.ruedata.com/" rel="noopener noreferrer" target="_blank" className="dropdown-item">{t("navigation.academy-text")}</a>            
                <Link to={ROUTES.HELP} className="dropdown-item">
                  {t("navigation.academy-text")}
                </Link>
                <a
                  className="dropdown-item"
                  href={"/INSTRUCTIVO USO DE PLATAFORMA 2.0.pdf"}
                  target="_blank"
                >
                  {t("navigation.manual-text")}
                </a>
              </div>
            </li>
          )} */}
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="languageDropdownMenuLink"
              href="/"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {i18n.language.split("-")[0].toUpperCase()}{" "}
              <i class="fas fa-globe-americas"></i>
            </a>
            <div
              className="dropdown-menu"
              aria-labelledby="languageDropdownMenuLink"
            >
              <button
                className="dropdown-item"
                onClick={() => setLanguageSelected("es", i18n, t)}
              >
                {" "}
                {t("navigation.spanish-text")}
              </button>
              <button
                className="dropdown-item"
                onClick={() => setLanguageSelected("pt", i18n, t)}
              >
                {" "}
                {t("navigation.portuguese-text")}{" "}
                <span id="betaLanguage" className="badge badge-danger">
                  Beta
                </span>
              </button>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              id="accountDropdownMenuLink"
              href="/"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-user"></i>
            </a>
            <div
              className="dropdown-menu dropdown-accordion"
              aria-labelledby="accountDropdownMenuLink"
            >
              {authUser.roles === ROLES.ADMIN ||
              authUser.roles === ROLES.ANALYST ? (
                <Link className="dropdown-item" to={ROUTES.USERS}>
                  {t("navigation.users-text")}
                </Link>
              ) : null}
              {isSuperAdmin(authUser.roles, authUser.restricted_admin) ? (
                <>
                  <a
                    href="#submenuIdiom"
                    className="submenu dropdown-item"
                    data-toggle="collapse"
                  >
                    Macros <i className="fa fa-caret-down"></i>
                  </a>
                  <div className="collapse" id="submenuIdiom">
                    <Link
                      className="dropdown-item"
                      to={ROUTES.MACROS_MACRO_ACCOUNT}
                    >
                      M.Cuenta
                    </Link>
                    <Link
                      className="dropdown-item"
                      to={ROUTES.MACROS_MACRO_MACRO_ACCOUNT}
                    >
                      MM.Cuenta
                    </Link>
                  </div>
                </>
              ) : null}

              <Link className="dropdown-item" to={ROUTES.USERS_REPORT}>
                {t("navigation.users-reports-text")}
              </Link>
              <Link className="dropdown-item" to={ROUTES.ACCOUNT}>
                {t("navigation.my-account-text")}
              </Link>
              <Link className="dropdown-item" to={ROUTES.FORMATS}>
                {t("navigation.my-formats-text")}
              </Link>
              {isSuperAdmin(authUser.roles, authUser.restricted_admin) ? (
                <>
                  <div
                    className="dropdown-item"
                    onClick={() => setShowModalNotifications(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <a>{t("notifications.notifications")}</a>
                  </div>
                  {showModalNotifications ? (
                    <Notifications
                      t={t}
                      authUser={authUser}
                      showModal={showModalNotifications}
                      hideModal={setShowModalNotifications}
                    />
                  ) : null}
                </>
              ) : null}
              <SignOutButton t={t} />
            </div>
          </li>
          <li className="nav-item">
            {authUser.roles === ROLES.ADMIN ||
            authUser.roles === ROLES.ANALYST ||
            authUser.roles === ROLES.CUSTOMER ? (
              (authUser.roles === ROLES.CUSTOMER && authUser.isMc) ||
              (authUser.roles === ROLES.CUSTOMER && authUser.isMmc) ? (
                <Link className="nav-link" to={ROUTES.CUSTOMERSMACRO}>
                  <i
                    className="fa fa-briefcase"
                    style={
                      localStorage.getItem("GlobalCustomerId")
                        ? null
                        : { color: "#E2B900" }
                    }
                  ></i>
                  <b
                    id="customerNameLink"
                    style={
                      localStorage.getItem("GlobalCustomerId")
                        ? null
                        : { color: "#E2B900" }
                    }
                  >
                    {" "}
                    {globalCustomer}
                  </b>
                </Link>
              ) : (
                <Link className="nav-link" to={ROUTES.CUSTOMERS}>
                  <i
                    className="fa fa-briefcase"
                    style={
                      localStorage.getItem("GlobalCustomerId")
                        ? null
                        : { color: "#E2B900" }
                    }
                  ></i>
                  <b
                    id="customerNameLink"
                    style={
                      localStorage.getItem("GlobalCustomerId")
                        ? null
                        : { color: "#E2B900" }
                    }
                  >
                    {" "}
                    {globalCustomer}
                  </b>
                </Link>
              )
            ) : (
              <Link
                className="nav-link"
                to={
                  "/customers/show/" + localStorage.getItem("GlobalCustomerId")
                }
              >
                <i className="fa fa-briefcase"></i>{" "}
                <b id="customerNameLink"> {globalCustomer}</b>
              </Link>
            )}
          </li>
          <li className="nav-item" id="GlobalPilotCount">
            <CustomerPilotTrial t={t} />
            <Suspensions t={t} />
            {/* {sessionStorage.getItem("GlobalCustomerProduct") === "Piloto" ? (
            ) : null} */}
          </li>
        </ul>
        {!!authUser.only_reports ? (
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="itemsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.reports")}
              </a>
              <div
                className="dropdown-menu dropdown-accordion dropdown-menu-right"
                aria-labelledby="itemsDropdownMenuLink"
              >
                <a
                  href="#submenu1"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.general-reports")}{" "}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu1">
                  <Link className="dropdown-item" to={ROUTES.REPORTS_INFORMS}>
                    {t("navigation.managerial-reports")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_TOP_INDICATORS}
                  >
                    {t("navigation.top-indicators")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_INDICATORS}
                  >
                    {t("navigation.performance-indicators")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_PERFORMANCES}
                  >
                    {t("navigation.performance-brad-band")}
                  </Link>
                  {/* <Link className="dropdown-item"
                    to={ROUTES.REPORTS_PERFORMANCES_LAST}>{t("navigation.performance-brad-band-last")}</Link> */}
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_STATS_GESTION}
                  >
                    {t("navigation.magnament-statistics")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_INVENTORY}>
                    {t("navigation.inventory-milimiters")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_DISMOUNTS}>
                    {t("navigation.performance-dismount")}
                  </Link>
                </div>
                <div className="dropdown-divider"></div>
                <a
                  href="#submenu2"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.maintenance-plan")}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu2">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_PLAN_TIRES}
                  >
                    {t("navigation.tire-plan")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_PLANS_CURRENT}
                  >
                    {t("navigation.current-plan")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_PLANS}>
                    {t("navigation.history-plan")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_FULLFILMENT}
                  >
                    {t("navigation.compilance-plan")}
                  </Link>
                </div>
                <div className="dropdown-divider"></div>
                <a
                  href="#submenu3"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.shopping=plan")}{" "}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu3">
                  <Link className="dropdown-item" to={ROUTES.REPORTS_PLAN_TANK}>
                    {t("navigation.useful-projection")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_PLAN_SHOPPING}
                  >
                    {t("navigation.buy-current-plan")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_TIRE_PROJECTION}
                  >
                    {t("navigation.tire-projection")}{" "}
                    <span class="badge badge-danger">
                      {t("navigation.sinesters-beta")}
                    </span>
                  </Link>
                </div>
                <div className="dropdown-divider"></div>
                <a
                  href="#submenu4"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.maintenance-operation")}{" "}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu4">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_INSPECTIONS}
                  >
                    {t("navigation.inspections-compilance")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_INSPECTIONS_PREASSURE}
                  >
                    {t("navigation.inspections-compilance-preassure")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_ERRORS_DETECTION}
                  >
                    {t("navigation.alerts-anomalies")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_TOP10}>
                    {t("navigation.top-wear-anomalies")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_DISCARDS}>
                    {t("navigation.scrap")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_WAREHOUSES}
                  >
                    {t("navigation.reports-warehouses")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_PRESSURES}>
                    {t("navigation.pressures")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_SINISTERS}>
                    {t("navigation.sinesters")}{" "}
                    <span class="badge badge-danger">
                      {t("navigation.sinesters-beta")}
                    </span>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_INJECTIONS}
                  >
                    {t("navigation.tire-injection")}
                  </Link>
                </div>
                <div className="dropdown-divider"></div>
                <a
                  href="#submenu5"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.statistics")}{" "}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu5">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_STATS_TIRES}
                  >
                    {t("navigation.statistics-tire")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_STATS_VEHICLES}
                  >
                    {t("navigation.statistics-vehicle")}
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        ) : localStorage.getItem("GlobalCustomerId") ? (
          <ul
            id="navMenu"
            className="navbar-nav"
            style={{ display: showDisplay }}
          >
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="itemsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.general")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="itemsDropdownMenuLink"
              >
                <Link className="dropdown-item" to={ROUTES.SCHEMAS}>
                  {t("navigation.vehicle-scheme")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.BRANDS}>
                  {t("navigation.vehicle-brand")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.TYPES}>
                  {t("navigation.vehicle-types")}
                </Link>
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ||
                authUser.roles === ROLES.ADMIN ? (
                  <Link className="dropdown-item" to={ROUTES.TIREBRANDS}>
                    {t("navigation.new-tires")}
                  </Link>
                ) : null}
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ||
                authUser.roles === ROLES.ADMIN ? (
                  <Link className="dropdown-item" to={ROUTES.RETREADBRANDS}>
                    {t("navigation.tires-retreading")}
                  </Link>
                ) : null}
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="itemsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.catalogue")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="itemsDropdownMenuLink"
              >
                <Link className="dropdown-item" to={ROUTES.TIRECATALOGUES}>
                  {t("navigation.new-tires")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.RETREADCATALOGUES}>
                  {t("navigation.tires-retreading")}
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="itemsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.client")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="itemsDropdownMenuLink"
              >
                <Link className="dropdown-item" to={ROUTES.WAREHOUSES}>
                  {t("navigation.warehouses")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.COSTCENTERS}>
                  {t("navigation.cost-center")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.CITIES}>
                  {t("navigation.cities")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.GROUPS}>
                  {t("navigation.groups")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.GROUPS_ONE}>
                  {t("navigation.groups")} 1
                </Link>
                <Link className="dropdown-item" to={ROUTES.GROUPS_TWO}>
                  {t("navigation.groups")} 2
                </Link>
                <Link className="dropdown-item" to={ROUTES.VEHICLES}>
                  {t("navigation.vehicles")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.TIRES}>
                  {t("navigation.tires")}
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="itemsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.measurements")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="itemsDropdownMenuLink"
              >
                <Link className="dropdown-item" to={ROUTES.FUELS_ONE}>
                  {t("navigation.fuel")}
                </Link>
                {authUser.roles === ROLES.ADMIN ||
                authUser.roles === ROLES.ANALYST ||
                authUser.roles === ROLES.CUSTOMER ? (
                  <Link className="dropdown-item" to={ROUTES.FUELS_MULTIPLE}>
                    {t("navigation.masive-fuel")}
                  </Link>
                ) : null}
                <Link className="dropdown-item" to={ROUTES.DISTANCES_ONE}>
                  {t("navigation.kms-hrs-miles")}
                </Link>
                {authUser.roles === ROLES.ADMIN ||
                authUser.roles === ROLES.ANALYST ||
                authUser.roles === ROLES.CUSTOMER ? (
                  <Link
                    className="dropdown-item"
                    to={ROUTES.DISTANCES_MULTIPLE}
                  >
                    {t("navigation.masive-kms-hrs-miles")}
                  </Link>
                ) : null}
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="inspectionsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.inspections")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="inspectionsDropdownMenuLink"
              >
                <Link className="dropdown-item" to={ROUTES.INSPECTIONS_ONE}>
                  {t("navigation.inspections-one")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.INSPECTIONS_MULTIPLE}
                >
                  {t("navigation.inspections-masives")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.NOVELTIES_MULTIPLE}>
                  {t("navigation.novelties") +
                    " " +
                    t("navigation.novelties-masive")}
                </Link>
                {authUser.roles === ROLES.ANALYST ||
                authUser.roles === ROLES.ADMIN ? (
                  <>
                    <div
                      className="dropdown-item"
                      onClick={() => setShowModalMassive(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <a>
                        Nuevo Masivo
                        <span
                          id="GlobalPendingAnalist"
                          className="badge badge-danger mx-2"
                        >
                          Beta
                        </span>
                      </a>
                    </div>
                    {showModalMassive ? (
                      <Inspections
                        t={t}
                        authUser={authUser}
                        showModalMassive={showModalMassive}
                        setShowModalMassive={setShowModalMassive}
                      />
                    ) : null}
                  </>
                ) : null}
                <div className="d-flex counters">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.INSPECTIONS_PENDING}
                  >
                    {t("navigation.inspections-pending")}{" "}
                    {localStorage.getItem("GlobalPendingCount") > 0 ? (
                      <>
                        <span id="pendingCount" className="badge badge-danger">
                          {pendingCount.pending}
                        </span>
                      </>
                    ) : null}
                  </Link>
                  {localStorage.getItem("GlobalPendingCount") > 0 ||
                  localStorage.getItem("GlobalPendingCountScrap") > 0 ? (
                    <span
                      className="countersRefresh"
                      onClick={() => refreshCounter("GlobalPendingCount")}
                    >
                      <i class="fas fa-redo-alt"></i>
                    </span>
                  ) : null}
                </div>
                <div className="d-flex counters">
                  <Link
                    className="dropdown-item counters"
                    to={ROUTES.INSPECTIONS_PENDING_SCRAP}
                  >
                    {t("navigation.inspections-pending-scrap")}{" "}
                    {localStorage.getItem("GlobalPendingCountScrap") > 0 ? (
                      <>
                        <span id="pendingCount" className="badge badge-danger">
                          {pendingCount.pendingWaste}
                        </span>
                      </>
                    ) : null}
                  </Link>
                </div>
                {VARIABLES.rotationsEmails.includes(authUser.email) ? (
                  <div className="d-flex counters">
                    <Link
                      className="dropdown-item"
                      to={ROUTES.INSPECTIONS_PENDING_ROTATION}
                    >
                      {t("navigation.inspections-pending-rotation")}{" "}
                      {localStorage.getItem("GlobalPendingRotation") > 0 ? (
                        <span
                          id="GlobalPendingRotation"
                          className="badge badge-danger"
                        >
                          {pendingCount.pendingRoation}
                        </span>
                      ) : null}
                    </Link>
                    {localStorage.getItem("GlobalPendingRotation") > 0 ? (
                      <span
                        className="countersRefresh"
                        onClick={() => refreshCounter("GlobalPendingRotation")}
                      >
                        <i class="fas fa-redo-alt"></i>
                      </span>
                    ) : null}
                  </div>
                ) : null}
                <div className="d-flex counters">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.INSPECTIONS_PENDING_WITHOUT}
                  >
                    {t("navigation.inspections-pending-without")}{" "}
                    {localStorage.getItem("GlobalPendingWithoutKmCount") > 0 ? (
                      <span
                        id="pendingWithoutKmCount"
                        className="badge badge-danger"
                      >
                        {pendingCount.pendingKm}
                      </span>
                    ) : null}
                  </Link>
                  {localStorage.getItem("GlobalPendingWithoutKmCount") > 0 ? (
                    <span
                      className="countersRefresh"
                      onClick={() =>
                        refreshCounter("GlobalPendingWithoutKmCount")
                      }
                    >
                      <i class="fas fa-redo-alt"></i>
                    </span>
                  ) : null}
                </div>
                {/* <div className="d-flex counters">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.INSPECTIONS_PREVIEW}
                  >
                    {t("navigation.inspections-advance")}{" "}
                    {localStorage.getItem("GlobalPendingAnalist") > 0 ? (
                      <span id="pendingAnalist" className="badge badge-danger">
                        {pendingCount.pendingAnalist}
                      </span>
                    ) : null}
                  </Link>
                  {localStorage.getItem("GlobalPendingAnalist") > 0 ? (
                    <span
                      className="countersRefresh"
                      onClick={() => refreshCounter("GlobalPendingAnalist")}
                    >
                      <i class="fas fa-redo-alt"></i>
                    </span>
                  ) : null}
                </div> */}
                <div className="d-flex counters">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.INSPECTIONS_PREVIEW_BETA}
                  >
                    {t("navigation.inspections-advance")}
                    {localStorage.getItem("GlobalPendingAnalist") > 0 ? (
                      <span id="pendingAnalist" className="badge badge-danger">
                        {pendingCount.pendingAnalist}
                      </span>
                    ) : null}
                  </Link>
                </div>
              </div>
              {/*  */}
            </li>
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="inspectionsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.novelties")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="inspectionsDropdownMenuLink"
              >
                <Link className="dropdown-item" to={ROUTES.NOVELTIES_MULTIPLE}>
                  {t("navigation.novelties-masive")}
                </Link>
              </div>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="itemsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.works")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="itemsDropdownMenuLink"
              >
                <Link className="dropdown-item" to={ROUTES.WORKS_VEHICLE}>
                  {t("navigation.works-vehicles")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.WORKS_TIRE}>
                  {t("navigation.works-tires")}
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={() => localStorage.setItem("is_tire_massive", "no")}
                  to={ROUTES.WORKS_MULTIPLE}
                >
                  {t("navigation.works-masives")}
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="itemsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.query")}
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="itemsDropdownMenuLink"
              >
                <Link className="dropdown-item" to={ROUTES.QUERIES_VEHICLE}>
                  {t("navigation.query-vehicles")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.QUERIES_TIRE}>
                  {t("navigation.query-tires")}
                </Link>
                <Link className="dropdown-item" to={ROUTES.QUERIES_WORK_TIRE}>
                  {t("navigation.query-works-tire")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.QUERIES_WORK_VEHICLE}
                >
                  {t("navigation.query-works-vehicle")}
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="itemsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("navigation.reports")}
              </a>
              <div
                className="dropdown-menu dropdown-accordion dropdown-menu-right"
                aria-labelledby="itemsDropdownMenuLink"
              >
                <a
                  href="#submenu1"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.general-reports")}{" "}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu1">
                  <Link className="dropdown-item" to={ROUTES.REPORTS_INFORMS}>
                    {t("navigation.managerial-reports")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_TOP_INDICATORS}
                  >
                    {t("navigation.top-indicators")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_INDICATORS}
                  >
                    {t("navigation.performance-indicators")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_PERFORMANCES}
                  >
                    {t("navigation.performance-brad-band")}
                  </Link>
                  {/* <Link className="dropdown-item"
                      to={ROUTES.REPORTS_PERFORMANCES_LAST}>{t("navigation.performance-brad-band-last")}</Link> */}
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_STATS_GESTION}
                  >
                    {t("navigation.magnament-statistics")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_INVENTORY}>
                    {t("navigation.inventory-milimiters")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_DISMOUNTS}>
                    {t("navigation.performance-dismount")}
                  </Link>
                </div>
                <div className="dropdown-divider"></div>
                <a
                  href="#submenu2"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.maintenance-plan")}{" "}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu2">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_PLAN_TIRES}
                  >
                    {t("navigation.tire-plan")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_PLANS_CURRENT}
                  >
                    {t("navigation.current-plan")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_PLANS}>
                    {t("navigation.history-plan")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_FULLFILMENT}
                  >
                    {t("navigation.compilance-plan")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.COMPILANCE_MONTH_REPORT}
                  >
                    {t("navigation.query-compilance-month")}
                  </Link>
                </div>
                <div className="dropdown-divider"></div>
                <a
                  href="#submenu3"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.shopping=plan")}{" "}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu3">
                  <Link className="dropdown-item" to={ROUTES.REPORTS_PLAN_TANK}>
                    {t("navigation.useful-projection")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_PLAN_SHOPPING}
                  >
                    {t("navigation.buy-current-plan")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_TIRE_PROJECTION}
                  >
                    {t("navigation.tire-projection")}{" "}
                    <span class="badge badge-danger">
                      {t("navigation.sinesters-beta")}
                    </span>
                  </Link>
                </div>
                <div className="dropdown-divider"></div>
                <a
                  href="#submenu4"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.maintenance-operation")}{" "}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu4">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_INSPECTIONS}
                  >
                    {t("navigation.inspections-compilance")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_INSPECTIONS_PREASSURE}
                  >
                    {t("navigation.inspections-compilance-preassure")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_ERRORS_DETECTION}
                  >
                    {t("navigation.alerts-anomalies")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_TOP10}>
                    {t("navigation.top-wear-anomalies")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_DISCARDS}>
                    {t("navigation.scrap")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_WAREHOUSES}
                  >
                    {t("navigation.reports-warehouses")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_PRESSURES}>
                    {t("navigation.pressures")}
                  </Link>
                  <Link className="dropdown-item" to={ROUTES.REPORTS_SINISTERS}>
                    {t("navigation.sinesters")}{" "}
                    <span class="badge badge-danger">
                      {t("navigation.sinesters-beta")}
                    </span>
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_INJECTIONS}
                  >
                    {t("navigation.tire-injection")}
                  </Link>
                </div>
                <div className="dropdown-divider"></div>
                <a
                  href="#submenu5"
                  className="submenu dropdown-item"
                  data-toggle="collapse"
                >
                  {t("navigation.statistics")}{" "}
                  <i className="fa fa-caret-down"></i>
                </a>
                <div className="collapse" id="submenu5">
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_STATS_TIRES}
                  >
                    {t("navigation.statistics-tire")}
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_STATS_VEHICLES}
                  >
                    {t("navigation.statistics-vehicle")}
                  </Link>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="itemsDropdownMenuLink"
                href="/"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Dashboards (beta)
              </a>
              <div
                className="dropdown-menu dropdown-accordion dropdown-menu-right"
                aria-labelledby="itemsDropdownMenuLink"
              >
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_INDICATORS}
                >
                  {t("navigation.ventures.indicators")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_TIRE}
                >
                  {t("navigation.ventures.tire")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_VEHICLE}
                >
                  {t("navigation.ventures.vehicle")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_COMPILANCE}
                >
                  {t("navigation.ventures.compilance")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_MILIMETERS}
                >
                  {t("navigation.ventures.milimeters")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_DISCARDS}
                >
                  {t("navigation.ventures.discards")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_YIELDS}
                >
                  {t("navigation.ventures.yields")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_CONSUMPTION}
                >
                  {t("navigation.ventures.consumption")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_CONSOLIDATEDPLAN}
                >
                  {t("navigation.ventures.consolidatedplan")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_MTOPLAN}
                >
                  {t("navigation.ventures.mtoplan")}
                </Link>
                <Link
                  className="dropdown-item"
                  to={ROUTES.REPORTS_VENTURES_SHOPPING}
                >
                  {t("navigation.ventures.shopping")}
                </Link>
                {localStorage.getItem("GlobalCustomerId") === "138" && (
                  <Link
                    className="dropdown-item"
                    to={ROUTES.REPORTS_VENTURES_EMISSIONS}
                  >
                    {t("navigation.ventures.emissions")}
                  </Link>
                )}
              </div>
            </li>
          </ul>
        ) : null}
      </div>
    </nav>
  );
};

const NavigationNonAuth = ({ authUser }) => {
  const [t, i18n] = useTranslation("global");

  if (
    localStorage.getItem("logo") ||
    window.location.search == "?logo=arsenal" ||
    window.location.search == "?logo=pulpomatic"
  ) {
    $(function () {
      setTimeout(() => {
        console.log("param " + $("#clgo").html());
        $("#clgo").hide();
        $("#clgo-wsp").hide();
      }, 1000);
    });
  }

  return (
    <div className="navigation-nonauth">
      {/* <div className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          id="helpDropdownMenuLink"
          href="/"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-question-circle"></i>
          {t("navigation.help-text")}
        </a>
        <div
          className="dropdown-menu dropdown-accordion dropdown-menu-right"
          aria-labelledby="helpDropdownMenuLink"
        >
          <a
            href="https://ruedata.atlassian.net/servicedesk/customer/portal/4"
            rel="noopener noreferrer"
            target="_blank"
            className="dropdown-item"
          >
            {t("navigation.support-text")}
          </a>
          <a href="http://academia.ruedata.com/" rel="noopener noreferrer" target="_blank" className="dropdown-item">{t("navigation.academy-text")}</a>   
          <a
            href="https://docs.google.com/document/d/1EHptoP8SXF4wLeKV9ttoJ84novG1nl1Kk7sxg5yU_F8/edit"
            rel="noopener noreferrer"
            target="_blank"
            className="dropdown-item"
          >
            {t("navigation.academy-text")}
          </a>
          <a
            className="dropdown-item"
            href={"/INSTRUCTIVO USO DE PLATAFORMA 2.0.pdf"}
            target="_blank"
          >
            {t("navigation.manual-text")}
          </a>
        </div>
      </div> */}
      <div></div>

      <div className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          id="languageDropdownMenuLink"
          href="/"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {localStorage.getItem("LanguageSelected") &&
            localStorage.getItem("LanguageSelected").toUpperCase()}{" "}
          <i class="fas fa-globe-americas"></i>
        </a>
        <div
          className="dropdown-menu"
          aria-labelledby="languageDropdownMenuLink"
        >
          <button
            className="dropdown-item"
            onClick={() => setLanguageSelected("es", i18n, t)}
          >
            {" "}
            {t("navigation.spanish-text")}
          </button>
          <button
            className="dropdown-item"
            onClick={() => setLanguageSelected("pt", i18n, t)}
          >
            {" "}
            {t("navigation.portuguese-text")}{" "}
            <span id="GlobalPendingAnalist" className="badge badge-danger">
              Beta
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Navigation);
