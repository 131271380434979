import React from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import PasswordChangeForm from "../PasswordChange";

const AccountPage = ({t}) => {
  
  return (
    <AuthUserContext.Consumer>
    {authUser => (
      <div className="container-fluid mt-100">
        <div className="row">
          <div className="col-sm-6 offset-sm-3 text-center">
            <h3 className="m-5">{authUser.email}</h3>
            <PasswordChangeForm t={t}/>
          </div>
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>
  )
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);
