import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsVehicle from "../../../constants/actions/Vehicles";
import * as APIS from "../../../constants/apis";

import TireList from "./Tires/TireList";

function getVehicle(vehicleCode, vehicles) {
  let tires = [];
  let vehicle = {};
  return (dispatch) => {
    return trackPromise(
      fetch(
        `${APIS.API_REST_VEHICLES.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        )}/${vehicleCode}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          vehicle = json;
          return fetch(
            `${APIS.API_REST_TIRES_VEHICLE.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            ).replace("vehicle_id", vehicle.id)}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          tires = json.tires_by_vehicle;
          dispatch(
            getVehicleSuccess({
              vehicle: vehicle,
              vehicles: vehicles,
              tires: tires,
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
    );
  };
}

export function getVehicleSuccess(json) {
  return {
    type: constantsVehicle.GET_VEHICLE_SUCCESS,
    json,
  };
}

class VehicleItem extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    const { vehicles } = this.props;
    this.props.getVehicle(id, vehicles);
  }

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  render() {
    const { vehicle, tires, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          !!vehicle ? (
            <div className="container-fluid mt-100">
              <h3>{this.props.t("client-vehicles.client-info")}</h3>
              <br />

              <div className="text-center v-scroll">
                <table className="table table-striped table-bordered table-sm mb-0">
                  <tbody>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-14")}
                      </th>
                      <td className="w-50">{vehicle.centrocosto}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-13")}
                      </th>
                      <td className="w-50">{vehicle.ciudad}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-12")}
                      </th>
                      <td className="w-50">{vehicle.fuel}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-11")}
                      </th>
                      <td className="w-50">{vehicle.group}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-11")} 1
                      </th>
                      <td className="w-50">{vehicle.group1}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-11")} 2
                      </th>
                      <td className="w-50">{vehicle.group2}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-9")}
                      </th>
                      <td className="w-50">{vehicle.linemodel}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-1")}
                      </th>
                      <td className="w-50">{vehicle.plate}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-10")}
                      </th>
                      <td className="w-50">{vehicle.schema}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-16")}
                      </th>
                      <td className="w-50">{vehicle.vehiclebrand}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-2")}
                      </th>
                      <td className="w-50">{vehicle.vehiclenumber}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-18")}
                      </th>
                      <td className="w-50">{vehicle.vehicletype}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-17")}
                      </th>
                      <td className="w-50">{vehicle.year}</td>
                    </tr>
                    <tr>
                      <th className="w-50">
                        {this.props.t("client-vehicles.client-item-7")}
                      </th>
                      <td className="w-50">
                        {!!vehicle.observaciones
                          ? vehicle.observaciones[0].observacion
                          : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <h3>{this.props.t("client-vehicles.client-item-19")}</h3>
              <TireList t={this.props.t} tires={tires} />
            </div>
          ) : null
        }
      </AuthUserContext.Consumer>
    );
  }
}

const structuredSelector = createStructuredSelector({
  vehicle: (state) => state.vehicle,
  vehicles: (state) => state.vehicles,
  tires: (state) => state.tires,
  message: (state) => state.message,
  error: (state) => state.error,
});

const mapDispatchToProps = { getVehicle };

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(
  connect(structuredSelector, mapDispatchToProps)(VehicleItem)
);
