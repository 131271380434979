export const VAL_CONNECTION = [
  "TypeError: Failed to fetch",
  "Error: Unreachable endpoint"
];

export const isInternetConnected = function (toastr) {
  if (navigator.onLine === false) {
    return toastr.error("No estás conectado a Internet, " +
      "inténtalo más tarde");
  }
};

export const isSuperAdmin = (role_name, isSuperAdmin) => {
  if (role_name === "ADMIN" && isSuperAdmin === false) {
    return true
  }


  return false
}
