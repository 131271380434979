import $ from "jquery";
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import * as APIS from "../../constants/apis";
import * as VALIDATIONS from "../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

class LastFuelsInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fecha_inspeccion: "",
      consecutivo: "",
      km_old: "",
      kilometers: ""
    };
  };

  deleteKm(fuelId, carNumber) {
    let label = "Medición";
    if (this.props.type === "fuel") {
      label = "Combustible"
    }
    if (window.confirm(this.props.t("alerts.fuel-item-9") + label + "?")) {
      return trackPromise(fetch(APIS.API_REST_FUELS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("vehicle_id", carNumber) + "/" + fuelId, {
        method: "DELETE",
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.fuel-item-6") + label);
          }
          return response.json();
        })
        .then(json => {
          if (json.message) {
            toast.success(label + this.props.t("alerts.fuel-item-7"));
            let fuels = this.props.fuels.filter(item => item.id != fuelId)
            this.props.setListGas(fuels)
          }

        })
        .catch(error => {
          toast.error(error.toString());
        }));
    }
  };

  onSubmit = event => {
    event.preventDefault();
    if (isInternetConnected(toast)) return;

    const data = cloneDeep(this.state);
    data.fecha_inspeccion = this.convertDateToUTC(data.fecha_inspeccion);
    trackPromise(fetch(`${APIS.API_REST_FUELS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("vehicle_id", this.props.carNumber)}/${data.consecutivo}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "PUT",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        $("#kmUpdate" + data.consecutivo).modal("hide");
        toast.success(this.props.t("alerts.fuel-item-8"));
        this.props.onSubmitCar();
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.fuel-item-5");
        }
        toast.error(err.message.toString());
        this.setState({ error: err });
      }));
  };

  getLastInspectionDate = (date) => {
    let dateTemp = "";
    dateTemp = date.replace("T", " ");
    dateTemp = dateTemp.replace("Z", "");
    let fmtDate = dateTemp;
    if (date.includes("T")) {
      var f1 = new Date(dateTemp);
      f1.setHours(f1.getHours() - 5);
      var month = (f1.getMonth() < 9 ? '0' : '') + (f1.getMonth() + 1);
      var day = (f1.getDate() < 10 ? '0' : '') + f1.getDate();
      var hour = (f1.getHours() < 10 ? '0' : '') + f1.getHours();
      var minute = (f1.getMinutes() < 10 ? '0' : '') + f1.getMinutes();
      //var second = (f1.getSeconds()<10?'0':'') + f1.getSeconds();
      fmtDate = f1.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute;
    }
    return fmtDate;
  };

  convertDateToUTC = (date) => {
    var f1 = new Date(date);
    f1.setHours(f1.getHours() + 5);
    var month = (f1.getMonth() < 9 ? '0' : '') + (f1.getMonth() + 1);
    var day = (f1.getDate() < 10 ? '0' : '') + f1.getDate();
    var hour = (f1.getHours() < 10 ? '0' : '') + f1.getHours();
    var minute = (f1.getMinutes() < 10 ? '0' : '') + f1.getMinutes();
    var second = (f1.getSeconds() < 10 ? '0' : '') + f1.getSeconds();
    let fmtDate = f1.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second + "Z";
    return fmtDate;
  };

  updateObject = object => {
    this.setState({
      fecha_inspeccion: object.fecha_inspeccion,
      consecutivo: object.consecutivo,
      km_old: object.km_old,
      kilometers: object.km_new
    });
  };

  onChange = event => {
    let state = { ...this.state };
    state[event.target.name] = event.target.value;
    this.setState({ ...state });
  };

  render() {
    const { km_old, kilometers, fecha_inspeccion } = this.state;
    const { object } = this.props;

    return (
      <td>
        {!!this.props.authUser.permissions.edit ?
          <button className="btn btn-primary text-white"
            data-toggle="modal"
            data-target={"#kmUpdate" + object.consecutivo}
            onClick={() => this.updateObject(object)}>
            <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
          </button> : null}&nbsp;
        <div className="modal fade"
          id={"kmUpdate" + object.consecutivo}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="moreInfoModalLabel"
          aria-hidden="true">
          <div className="modal-dialog"
            role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark"
                  id="moreInfoModalLabel">
                  {this.props.t("measure.client-item-16")}
                </h5>
                <button type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-dark">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2 pull-left">
                        <label htmlFor="fecha_inspeccion">{this.props.t("measure.client-item-9")}</label>
                      </div>
                      <div className="col-sm-10">
                        <input className="form-control"
                          type="text"
                          id="fecha_inspeccion"
                          name="fecha_inspeccion"
                          placeholder={this.getLastInspectionDate(fecha_inspeccion)}
                          value={this.getLastInspectionDate(fecha_inspeccion)}
                          onChange={this.onChange}
                          required />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-sm-2 pull-left">
                        <label htmlFor="kilometers">{this.props.t("measure.client-item-17")}</label>
                      </div>
                      <div className="col-sm-10">
                        <input className="form-control"
                          type="number"
                          id="kilometers"
                          name="kilometers"
                          placeholder={km_old}
                          value={kilometers}
                          onChange={this.onChange}
                          required />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <button type="submit"
                          className="btn btn-primary">
                          <i className="fa fa-edit"></i> {this.props.t("globals.act")}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {!!this.props.authUser.permissions.delete ?
          <button className="btn btn-danger text-white"
            onClick={() => this.deleteKm(this.props.fuelId, this.props.carNumber)}>
            <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
          </button> : null}
      </td>
    );
  };
};

export default LastFuelsInfo;