import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsWarehouse from "../../../constants/actions/Warehouses";
import * as APIS from "../../../constants/apis";

function getWarehouse(id, t) {
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_WAREHOUSES.replace("customer_id",
    localStorage.getItem("GlobalCustomerId"))}/${id}`, {
                                                    headers:{
                                                      "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                      "Accept-language": localStorage.getItem("LanguageSelected"),
                                                    } })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(t("alerts.warehouse-item-2"));
        }
        return response.json(); })
      .then(json => {
        dispatch(getWarehouseSuccess({
          warehouse: json
        })); })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };
};

export function getWarehouseSuccess(json) {
  return {
    type: constantsWarehouse.GET_WAREHOUSE_SUCCESS,
    json
  };
};

export function getReqSuccess(warehouses, warehouse, message) {
  const json = {
    warehouses: warehouses,
    warehouse : warehouse,
    message   : message
  };
  return {
    type: constantsWarehouse.GET_REQUEST_SUCCESS,
    json
  };
};

class WarehouseEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // code: ""
    };
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getWarehouse(id, this.props.t);
  };

  componentDidUpdate(prevProps) {
    if(!prevProps.warehouse || (this.props.warehouse !== prevProps.warehouse)) {
      this.setState({
        customer_id : this.props.warehouse.customer_id,
        name        : this.props.warehouse.name,
        // code        : this.props.warehouse.code
      });
    };
  };

  onSubmit = event => {
    event.preventDefault();
    const { getReqSuccess, warehouse, warehouses } = this.props;

    const name = this.props.match.params.id
    const data = {
      ...this.state,
    };

    if(warehouse.name === data.name && warehouse.code === data.code) {
      this.props.history.push("/warehouses");
      getReqSuccess(warehouses, warehouse, this.props.t("alerts.warehouse-item-3"));
      return Promise.resolve();
    }

    return trackPromise(fetch(`${APIS.API_REST_WAREHOUSES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers:{
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        } })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if(!status) {
          this.props.history.push("/warehouses");
          getReqSuccess(warehouses, warehouse,
            this.props.t("alerts.warehouse-item-3"));
        } else {
          throw new Error(status);
        } })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onChange = event =>{
    this.setState({ [event.target.name]: event.target.value });
  };

  showSuccessDialog = (message) => {
    if(!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if(!!error && !!toast) {
      toast.error(error);
    }
  };

  render () {
    const { warehouse, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!warehouse ?
          <div className="container-fluid mt-100">
            
            <h3>{this.props.t("client-general.warehouse-edit")}</h3>
            <br/><br/>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">{this.props.t("client-general.client-item-1")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("client-general.client-item-1")}
                           id="nameInput"
                           name="name"
                           value={this.state.name}
                           onChange={this.onChange}
                           required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                          className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  warehouses: state => state.warehouses,
  warehouse : state => state.warehouse,
  message   : state => state.message,
  error     : state => state.error
});

const mapDispatchToProps = { getWarehouse, getReqSuccess };

const condition = authUser =>
  !!authUser

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(WarehouseEditItem));
