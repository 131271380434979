import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import * as constantsSchema from "../../constants/actions/Schemas";

const initialState = {
  schemas: [],
  schema: {},
  message: null,
  error: null
};

function rootReducer(state, action) {
  switch (action.type) {
    case constantsSchema.GET_SCHEMAS_SUCCESS:
      return {
        schemas: action.json.schemas,
        schema: {},
        error: null
      };
    case constantsSchema.GET_SCHEMA_SUCCESS:
      return {
        schema: action.json.schema,
        schemas: [],
        error: null
      };
    case constantsSchema.GET_REQUEST_SUCCESS:
      return {
        schemas: action.json.schemas,
        schema: action.json.schema,
        message: action.json.message
      };
    case constantsSchema.GET_REQUEST_ERROR:
      return {
        schemas: action.json.schemas,
        schema: action.json.schema,
        error: action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
