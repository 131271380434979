import React, { Component } from "react";

class OutTable extends Component {
  render() {
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <tbody>
            {
              this.props.data.map((r, i) => {
                if (r.length > 0) {
                  return <tr className=
                    {!!i ?
                      (this.props.done.indexOf(i - 1) > -1 ?
                        "table-success" :
                        (this.props.notDone.indexOf(i - 1) > -1 ?
                          "table-danger" : "")) :
                      ""}
                    key={i}>
                    <td>{!!i ? i : ""}</td>
                    {
                      this.props.cols.map(c => {
                        return <td key={c.key}>
                          {!!r[c.key] ? r[c.key].toString() : r[c.key]}
                        </td>
                      })
                    }
                  </tr>
                } else {
                  return null;
                }
              })
            }
          </tbody>
        </table>
      </div>
    );
  };
};

export default OutTable;
