import Q from "q";
import xlsx from "xlsx";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../../Auth/Session";

import ErrorsModal from "./ErrorsModal";
import DragDropFile from "./DragDropFile";
import DataInput from "./DataInput";
import OutTable from "./OutTable";

import * as APIS from "../../../../constants/apis";
import * as VALIDATIONS from "../../../../constants/validations";

const valConnection       = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;
const randomString        = Math.random().toString(36);

const INITIAL_STATE = {
  // Multiple
  inputKey      : randomString,
  done          : [],
  notDone       : [],
  data          : [],
  cols          : [],
  errors        : [],
  // Customers
  retreadbrands : []
};

class MultipleRetreadCatalogues extends Component {
  constructor(props) {
    super(props);
 
    this.modal  = React.createRef();

    this.serverErrors   = [];
    this.handleFile     = this.handleFile.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    let retreadbrands = [];
    trackPromise(fetch(`${APIS.API_REST_CUSTOMER_RETREADBRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers:{
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          } })
      .then(response => response.json())
      .then(json => {
        if(!!json.message) {
          throw Error(json.message);
        }
        retreadbrands = json.Retreadbrands;
        this.setState({
          retreadbrands : retreadbrands,
        }); })
      .catch(error => {
        toast.error(error);
      }));
  };

  makeCols = refstr => {
    let o = [],
        C = xlsx.utils.decode_range(refstr).e.c + 1;
    for(var i = 0; i < C; ++i) {
      o[i] = {
        name : xlsx.utils.encode_col(i),
        key  : i
      }
    }
    return o;
  };

  getCurrentBrandInfo = (brandInfo) => {
    let curBrand = {
      marca_id     : "",
      modelo_id    : "",
      dimension_id : ""
    };

    if(!brandInfo.marca || !brandInfo.modelo || !brandInfo.dimension) {
      return curBrand;
    }

    const brands = this.state.retreadbrands;
    for(let i = 0; i < brands.length; i++) {
      if(String(brands[i].name).trim() === String(brandInfo.marca).trim()) {
        const models = brands[i].retreadbands;
        curBrand.marca_id = brands[i].id;
        for(let j = 0; j < models.length; j++) {
          if(String(models[j].name).trim() ===
             String(brandInfo.modelo).trim()) {
            const dimensions = models[j].retreadimentions;
            curBrand.modelo_id = models[j].id;
            for(let k = 0; k < dimensions.length; k++) {
              if(String(dimensions[k].dimention).trim() ===
                 String(brandInfo.dimension).trim()) {
                curBrand.dimension_id = dimensions[k].id;
                return curBrand;
              }
            }
          }
        }
      }
    }

    return null;
  };

  getRepeatedRows = (rowsObjects) => {
    if(rowsObjects.length > 1) {
      let result = Object.values(rowsObjects.reduce((c, v) => {
        let k = v.plate;
        c[k] = c[k] || [];
        c[k].push(v);
        return c;
      }, {})).reduce((c, v) => v.length > 1 ? c.concat(v) : c, []);
      return result;
    } else {
      return [];
    }
  };

  getDataErrors = (cols, data) => {
    let errors = [];

    // Número de columnas
    if(cols.length !== 10) {
      errors.push({
        message: this.props.t("multiple.global-item-1")
      });
    }

    // Número de datos
    if(data.length < 2) {
      errors.push({
        message: this.props.t("multiple.global-item-2")
      });
    }

    // Columnas necesarias
    let fields = [
      this.props.t("multiple.r-tire-item-1"), 
      this.props.t("multiple.r-tire-item-2"), 
      this.props.t("multiple.r-tire-item-3"),
      this.props.t("multiple.r-tire-item-4"), 
      this.props.t("multiple.r-tire-item-5"), 
      this.props.t("multiple.r-tire-item-6"),
      this.props.t("multiple.r-tire-item-7"), 
      this.props.t("multiple.r-tire-item-8"), 
      this.props.t("multiple.r-tire-item-9"),
      this.props.t("multiple.r-tire-item-10")
    ];
    for(let i = 0; i < fields.length; i++) {
      if(data[0].indexOf(fields[i]) === -1) {
        errors.push({
          message: this.props.t("multiple.global-item-3") + fields[i] + this.props.t("multiple.global-item-4") + this.props.t("multiple.global-item-5")
        });
      }
    }

    // Validaciones de los datos
    for(let i = 1; i < data.length; i++) {
      let brandInfo = {
        marca     : "",
        modelo    : "",
        dimension : "",
      };
      for(let j = 0; j < data[i].length; j++) {
        if(data[0][j] === this.props.t("multiple.r-tire-item-4") ||
           data[0][j] === this.props.t("multiple.r-tire-item-5") ||
           data[0][j] === this.props.t("multiple.r-tire-item-8") ||
           data[0][j] === this.props.t("multiple.r-tire-item-9") ||
           data[0][j] === this.props.t("multiple.r-tire-item-10")) {
          if(isNaN(data[i][j])) {
            errors.push({
              message: this.props.t("multiple.r-tire-item-12") + (i+1) + this.props.t("multiple.r-tire-item-13") +
                       data[0][j] + this.props.t("multiple.r-tire-item-14")
            });
          }
        }
        if(data[0][j] === this.props.t("multiple.r-tire-item-1")) {
          brandInfo.marca = data[i][j];
        }
        if(data[0][j] === this.props.t("multiple.r-tire-item-2")) {
          brandInfo.modelo = data[i][j];
        }
        if(data[0][j] === this.props.t("multiple.r-tire-item-3")) {
          brandInfo.dimension = data[i][j];
        }
      }
      if(!this.getCurrentBrandInfo(brandInfo)) {
        errors.push({
          message: this.props.t("multiple.r-tire-item-12") + (i+1) + this.props.t("multiple.r-tire-item-15")
        });
      }
    }

    return errors;
  };

  handleFile = file => {
    this.setState({data: [], cols: [], done: [], notDone: [], errors: []});

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (event) => {
      const bstr   = event.target.result;
      const wb     = xlsx.read(bstr, {type:rABS ? "binary" : "array"});
      const wsname = wb.SheetNames[0];
      const ws     = wb.Sheets[wsname];
      const data   = xlsx.utils.sheet_to_json(ws, {header: 1, raw: false});
      const cols   = this.makeCols(ws["!ref"]);
      const errors = this.getDataErrors(cols, data);
      if(errors.length === 0) {
        this.setState({
          data     : data,
          cols     : this.makeCols(ws["!ref"]),
          inputKey : Math.random().toString(36)
        });
      } else {
        this.modal.current.showModal(errors);
        this.setState({
          errors   : [],
          data     : [],
          cols     : [],
          inputKey : Math.random().toString(36)
        });
      }
    };
    if(rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  exportTemplate = event => {
    event.preventDefault();

    let header =  [
      this.props.t("multiple.r-tire-item-1"), 
      this.props.t("multiple.r-tire-item-2"), 
      this.props.t("multiple.r-tire-item-3"),
      this.props.t("multiple.r-tire-item-4"), 
      this.props.t("multiple.r-tire-item-5"), 
      this.props.t("multiple.r-tire-item-6"),
      this.props.t("multiple.r-tire-item-7"), 
      this.props.t("multiple.r-tire-item-8"), 
      this.props.t("multiple.r-tire-item-9"),
      this.props.t("multiple.r-tire-item-10")
    ];

    const ws = xlsx.utils.aoa_to_sheet([header]);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Plantilla");
    xlsx.writeFile(wb, `${this.props.t("multiple.r-tire-item-11")}.xlsx`)
  };

  formatData = (register) => {
    let data = {
      customer_id       : localStorage.getItem("GlobalCustomerId"),
      marca             : "",
      modelo            : "",
      dimension         : "",
      marca_id          : "",
      modelo_id         : "",
      dimension_id      : "",
      precio            : "",
      prof_original     : "",
      prof_minima       : "",
      distribuidor      : "",
      presion_minima    : "",
      presion_maxima    : "",
    };

    let header = this.state.data[0];
    for(let i = 0; i < header.length; i++) {
      if(header[i] === this.props.t("multiple.r-tire-item-1")) {
        data.marca = register[i];
      } else if(header[i] === this.props.t("multiple.r-tire-item-2")) {
        data.modelo = register[i];
      } else if(header[i] === this.props.t("multiple.r-tire-item-3")) {
        data.dimension = register[i];
      } else if(header[i] === this.props.t("multiple.r-tire-item-4")) {
        data.prof_original = register[i];
      } else if(header[i] === this.props.t("multiple.r-tire-item-5")) {
        data.prof_minima = register[i];
      } else if(header[i] === this.props.t("multiple.r-tire-item-6")) {
        data.precio = register[i];
      }  else if(header[i] === this.props.t("multiple.r-tire-item-7")) {
        data.distribuidor = register[i];
      } else if(header[i] === this.props.t("multiple.r-tire-item-8")) {
        data.presion_minima = register[i];
      } else if(header[i] === this.props.t("multiple.r-tire-item-9")) {
        data.presion_maxima = register[i];
      } 
    }

    const brandInfo = {
      marca     : data.marca || null,
      modelo    : data.modelo || null,
      dimension : data.dimension || null
    };

    const brInfo = this.getCurrentBrandInfo(brandInfo);
    data.marca_id     = (brInfo.marca_id || null);
    data.modelo_id    = (brInfo.modelo_id || null);
    data.dimension_id = (brInfo.dimension_id || null);

    return data;
  };

  saveRegister = (idx, register) => {
    let deferred = Q.defer();

    if(isInternetConnected(toast)) {
      deferred.reject();
      return deferred.promise;
    }

    const data = this.formatData(register);
    trackPromise(fetch(`${APIS.API_REST_RETREADCATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers:{
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
        method: "POST",
        body: JSON.stringify(data) })
      .then(response => response.json())
      .then(json => {
        if(!!json.message) {
          throw new Error(json.message);
        }
        const done = [...this.state.done];
        done.push(idx);
        this.setState({done: done});
        deferred.resolve(json); })
      .catch(error => {
        let err = error;
        if(valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("multiple.global-item-6") +
                (idx+1);
        } else {
          err = this.props.t("multiple.global-item-7") + (idx+1) + ". " + error.toString();
        }
        this.serverErrors.push({message: err});
        const notDone = [...this.state.notDone];
        notDone.push(idx);
        this.setState({notDone: notDone});
        deferred.reject(this.serverErrors);
      }));

    return deferred.promise;
  };

  getCleanedRegisters = registers => {
    let newRegisters = [];

    for(let i = 1; i < registers.length; i++){
      if(registers[i].length > 0) {
        newRegisters.push(registers[i]);
      }
    }

    return newRegisters;
  };

  async saveRegisters(registers) {
    let deferred = Q.defer();

    let newRegisters = this.getCleanedRegisters(registers);
    for(let i = 0; i < newRegisters.length; i++) {
      if(this.state.done.indexOf(i) === -1) {
        await this.saveRegister(i, newRegisters[i])
          .then(() => {
            return true; })
          .catch(error => {
            deferred.reject(error);
          });
      }
    }

    deferred.resolve();
    return deferred.promise;
  };

  onSubmit = event => {
    event.preventDefault();
    this.serverErrors = [];
    this.setState({notDone: []});

    this.saveRegisters(this.state.data)
      .then(() => {
        toast.success(this.props.t("multiple.global-item-8"));
        this.setState({
          data    : [],
          cols    : [],
          done    : [],
          notDone : [],
          errors  : []
        }); })
      .catch(errors => {
        this.modal.current.showModal(errors);
      });
  };

  getModels = (id) => {
    const brands = this.state.brands;
    for(let i = 0; i < brands.length; i++) {
      if(brands[i].id === 1*id) {
        return brands[i].vehiclemodels;
      }
    }
    return [];
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { data, cols, done, notDone } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <ErrorsModal ref={this.modal} t={this.props.t}/>
            <h3>{this.props.t("tire-catalogue.tire-massive-new-2")}</h3>
            <br /><br />
            <DragDropFile handleFile={this.handleFile}>
              <form>
                <div>
                  <DataInput handleFile={this.handleFile}
                            t={this.props.t}
                             exportTemplate={this.exportTemplate}
                             inputKey={this.state.inputKey || ""} />
                  <OutTable data={data}
                            cols={cols}
                            done={done}
                            notDone={notDone} />
                  {data.length > 1 ?
                    <div className="row">
                      <div className="col-md-12 mt-5">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{ width: "100%" }}
                          onClick={this.onSubmit} >
                          <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                        </button>
                      </div>
                    </div> : null}
                </div>
              </form>
            </DragDropFile>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(MultipleRetreadCatalogues);
