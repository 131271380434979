import Q, { async } from "q";
import xlsx from "xlsx";
import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import ErrorsModal from "./ErrorsModal";
import TypeFormTire from "../Form/TypeFormTire";
import DragDropFile from "./DragDropFile";
import DataInput from "./DataInput";
import OutTable from "./OutTable";

import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";
import * as VARIABLES from "../../../constants/variables";
import { method } from "lodash";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;
const variablesObs = VARIABLES.UNMOUNT_OBSERVATIONS;
const randomString = Math.random().toString(36);



const INITIAL_STATE = {
  filePath: "",
  inputKey: randomString,
  type: "",
  fields: [],
  done: [],
  notDone: [],
  data: [],
  cols: [],
  errors: [],
  vehicles: [],
  tires: [],
  warehouses: [],
  // Brands
  brands: [],
  retreadbrands: [],
  tirecatalogues: [],
  retreadcatalogues: [],
  tiresAllinspections: [],
  vehiclesAllInspections: [],

};

class MultipleInspections extends Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();

    this.serverErrors = [];
    this.handleFile = this.handleFile.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    let warehouses, vehicles, tires, brands, retreadbrands, tirecatalogues,
      retreadcatalogues;
    trackPromise(fetch(`${APIS.API_REST_WAREHOUSES
      .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        warehouses = json.bodegas;
      })
      // .then(() => {
      //   console.log("join tire")
      //   fetch(`${APIS.API_REST_TIRES_MIN_INFO
      //     .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}` + "?city_id=145", {
      //     headers: {
      //       "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      //     }
      //   })
      //     .then(response => {
      //       console.log(response)
      //       return response.json()
      //     })
      //     .then(json => {
      //       if (!!json.message) {
      //         throw Error(json.message);
      //       }
      //       tires = json.tires;
      //       console.log(tires)
      //     })
      // })
      // .then(() => {
      //   fetch(`${APIS.API_REST_VEHICLES_MIN_INFO
      //     .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      //     headers: {
      //       "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      //     }
      //   })
      //     .then(response => response.json())
      //     .then(json => {
      //       if (!!json.message) {
      //         throw Error(json.message);
      //       }
      //       vehicles = json.vehicles;
      //     })
      // })
      .then(() => {
        return fetch(`${APIS.API_REST_CUSTOMER_TIREBRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        brands = json.Tirebrands;
      })
      .then(() => {
        return fetch(`${APIS.API_REST_CUSTOMER_RETREADBRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        retreadbrands = json.Retreadbrands;
      })
      .then(() => {
        return fetch(`${APIS.API_REST_TIRECATALOGUES_MIN_INFO
          .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        tirecatalogues = json.TireCatalogue;
      })
      .then(() => {
        return fetch(`${APIS.API_REST_RETREADCATALOGUES_MIN_INFO
          .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        retreadcatalogues = json.RetreadCatalogue;
        this.setState({
          brands: brands,
          retreadbrands: retreadbrands,
          tirecatalogues: tirecatalogues,
          retreadcatalogues: retreadcatalogues,
          vehicles: vehicles,
          warehouses: warehouses,
          tires: tires,
        });
      })
      .catch(error => {
        toast.error(error.message);
      }));
  };

  onChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "type") {
      let elements = [];
      switch (event.target.value) {
        case "desmonte":
          elements = [
            this.props.t("multiple.ins-item-1"),
            this.props.t("multiple.ins-item-2"),
            this.props.t("multiple.ins-item-3"),
            this.props.t("multiple.ins-item-4"),
            this.props.t("multiple.ins-item-5"),
            this.props.t("multiple.ins-item-6"),
            this.props.t("multiple.ins-item-7"),
            this.props.t("multiple.ins-item-8"),
            this.props.t("multiple.ins-item-9"),
            this.props.t("multiple.ins-item-10"),
            this.props.t("multiple.ins-item-42"),
            this.props.t("multiple.ins-item-11"),
            this.props.t("multiple.ins-item-12"),
            this.props.t("multiple.ins-item-13")
          ];
          break;
        case "montaje":
          elements = [
            this.props.t("multiple.ins-item-1"),
            this.props.t("multiple.ins-item-2"),
            this.props.t("multiple.ins-item-3"),
            this.props.t("multiple.ins-item-4"),
            this.props.t("multiple.ins-item-14"),
            this.props.t("multiple.ins-item-6"),
            this.props.t("multiple.ins-item-7"),
            this.props.t("multiple.ins-item-8"),
            this.props.t("multiple.ins-item-9"),
            this.props.t("multiple.ins-item-10"),
            this.props.t("multiple.ins-item-42"),
            this.props.t("multiple.ins-item-11"),
            this.props.t("multiple.ins-item-12"),
            this.props.t("multiple.ins-item-13")
          ];
          break;
        case "inspeccion":
          elements = [
            this.props.t("multiple.ins-item-1"),
            this.props.t("multiple.ins-item-2"),
            this.props.t("multiple.ins-item-3"),
            this.props.t("multiple.ins-item-4"),
            this.props.t("multiple.ins-item-14"),
            this.props.t("multiple.ins-item-6"),
            this.props.t("multiple.ins-item-7"),
            this.props.t("multiple.ins-item-8"),
            this.props.t("multiple.ins-item-9"),
            this.props.t("multiple.ins-item-10"),
            this.props.t("multiple.ins-item-42"),
            this.props.t("multiple.ins-item-11"),
            this.props.t("multiple.ins-item-12"),
            this.props.t("multiple.ins-item-13")
          ];
          break;
        case "reencauche":
          elements = [
            this.props.t("multiple.ins-item-1"),
            this.props.t("multiple.ins-item-2"),
            this.props.t("multiple.ins-item-6"),
            this.props.t("multiple.ins-item-15"),
            this.props.t("multiple.ins-item-16"),
            this.props.t("multiple.ins-item-17"),
            this.props.t("multiple.ins-item-18"),
            this.props.t("multiple.ins-item-19"),
            this.props.t("multiple.ins-item-7"),
            this.props.t("multiple.ins-item-8"),
            this.props.t("multiple.ins-item-9"),
            this.props.t("multiple.ins-item-10"),
            this.props.t("multiple.ins-item-42"),
            this.props.t("multiple.ins-item-11"),
            this.props.t("multiple.ins-item-12"),
            this.props.t("multiple.ins-item-13"),
            this.props.t("multiple.ins-item-20"),
          ];
          break;
        case "regrabado":
          elements = [
            this.props.t("multiple.ins-item-1"),
            this.props.t("multiple.ins-item-2"),
            this.props.t("multiple.ins-item-6"),
            this.props.t("multiple.ins-item-15"),
            this.props.t("multiple.ins-item-7"),
            this.props.t("multiple.ins-item-8"),
            this.props.t("multiple.ins-item-9"),
            this.props.t("multiple.ins-item-10"),
            this.props.t("multiple.ins-item-42"),
            this.props.t("multiple.ins-item-11"),
            this.props.t("multiple.ins-item-12"),
            this.props.t("multiple.ins-item-21"),
            this.props.t("multiple.ins-item-22"),
            this.props.t("multiple.ins-item-13")
          ];
          break;
        default:
        // Do nothing
      };
      this.setState({ fields: elements });
    } else if (event.target.name === "customer_id") {
      if (!!event.target.value) {
        let tirecatalogues, retreadcatalogues, vehicles = [];
        trackPromise(fetch(`${APIS.API_REST_TIRECATALOGUES
          .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
          .then(response => response.json())
          .then(json => {
            if (!!json.message) {
              throw Error(json.message);
            }
            tirecatalogues = json.TireCatalogue;
          })
          .then(() => {
            return fetch(`${APIS.API_REST_RETREADCATALOGUES
              .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
              headers: {
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              }
            })
          })
          .then(response => response.json())
          .then(json => {
            if (!!json.message) {
              throw Error(json.message);
            }
            retreadcatalogues = json.RetreadCatalogue;
          })
          .then(() => {
            return fetch(`${APIS.API_REST_VEHICLES
              .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
              headers: {
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              }
            })
          })
          .then(response => response.json())
          .then(json => {
            if (!!json.message) {
              throw Error(json.message);
            }
            vehicles = json.vehicles;
            this.setState({
              vehiclesAllInspections: vehicles,
              tirecatalogues: tirecatalogues,
              retreadcatalogues: retreadcatalogues
            });
          })
          .catch(error => {
            toast.error(error.message);
          }));
      } else {
        this.setState({
          tirecatalogues: [],
          retreadcatalogues: []
        });
      }
    }
  };

  makeCols = refstr => {
    let o = [],
      C = xlsx.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = {
        name: xlsx.utils.encode_col(i),
        key: i
      }
    }
    return o;
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };

  getCurrentTireInfo = (brandInfo, type) => {
    let curBrand = {
      marca_id: "",
      modelo_id: "",
      dimension_id: ""
    };

    if (!brandInfo.marca || !brandInfo.modelo || !brandInfo.dimension) {
      return curBrand;
    }

    const labelModels = (type === "nueva") ? "tiremodels" : "retreadbands";
    const labelDims = (type === "nueva") ? "tiredimentions" : "retreadimentions";
    const labelBrands = (type === "nueva") ? "brands" : "retreadbrands";
    const brands = this.state[labelBrands];
    for (let i = 0; i < brands.length; i++) {
      if (String(brands[i].name).trim() === String(brandInfo.marca).trim()) {
        const models = brands[i][labelModels];
        curBrand.marca_id = brands[i].id;
        for (let j = 0; j < models.length; j++) {
          if (String(models[j].name).trim() ===
            String(brandInfo.modelo).trim()) {
            const dimensions = models[j][labelDims];
            curBrand.modelo_id = models[j].id;
            for (let k = 0; k < dimensions.length; k++) {
              if (String(dimensions[k].dimention).trim() ===
                String(brandInfo.dimension).trim()) {
                curBrand.dimension_id = dimensions[k].id;
                return curBrand;
              }
            }
          }
        }
      }
    }

    return null;
  };

  getVehicleId = (codigo) => {
    const vehicles = this.state.vehiclesAllInspections;
    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].plate === codigo) {
        return vehicles[i].id;
      }
    }
    return null;
  };

  getTireId = (codigo) => {
    const tires = this.state.tiresAllinspections;
    console.log(tires)
    for (let i = 0; i < tires.length; i++) {
      if (tires[i].codigo === codigo) {
        return tires[i].id;
      }
    }

    return null;
  };

  getRetreadCatalogueId = (codigo) => {
    const tires = this.state.retreadcatalogues;
    for (let i = 0; i < tires.length; i++) {
      if (tires[i].codigo === codigo) {
        return tires[i].id;
      }
    }
    return null;
  };

  getWarehouse = (codigo) => {
    const warehouses = this.state.warehouses;
    for (let i = 0; i < warehouses.length; i++) {
      if (warehouses[i].name === codigo) {
        return warehouses[i].id;
      }
    }
    return null;
  };

  getDataTireIns = async (cols, data) => {
    let tires = []
    let vehicles = []
    for (let i = 1; i < data.length; i++) {
      let fields = this.state.fields
      for (let j = 0; j < fields.length; j++) {
        if (data[0][j] === this.props.t("multiple.ins-item-6")) {
          tires.push(data[i][j])
        }
        if (data[0][j] === this.props.t("multiple.ins-item-3")) {
          vehicles.push(data[i][j])
        }
      }
    }

    let datas = {}

    if (tires.length >= 1) {
      const responseTire = await fetch(`${APIS.API_REST_TIRES_MIN_INFO
        .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: 'POST',
        body: JSON.stringify({
          "tires_code": tires
        })
      })
      let resTire = await responseTire.json()
      datas.result_tire = await resTire.tires
    }

    if (vehicles.length >= 1) {
      const responseVehicle = await fetch(`${APIS.API_REST_VEHICLES_MIN_INFO
        .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: 'POST',
        body: JSON.stringify({
          "vehicles_plate": vehicles
        })
      })
      let resVehicle = await responseVehicle.json()
      datas.result_vehicle = await resVehicle.vehicles
    }

    return datas
  }

  getDataErrors = (cols, data) => {
    let errors = [];

    // Número de columnas
    let fields = this.state.fields;
    if (cols.length !== fields.length) {
      errors.push({
        message: this.props.t("multiple.global-item-1")
      });
    }

    // Número de datos
    if (data.length < 2) {
      errors.push({
        message: this.props.t("multiple.global-item-2")
      });
    }

    // Columnas necesarias
    for (let i = 0; i < fields.length; i++) {
      if (data[0].indexOf(fields[i]) === -1) {
        errors.push({
          message: this.props.t("multiple.global-item-3") + fields[i] + this.props.t("multiple.global-item-4") + this.props.t("multiple.global-item-5")
        });
      }
    }

    // Validaciones de los datos
    let type = this.state.type;
    for (let i = 1; i < data.length; i++) {
      let brandInfoRetread = {
        marca: "",
        modelo: "",
        dimension: "",
        use: ""
      };
      for (let j = 0; j < fields.length; j++) {
        if (data[0][j] === this.props.t("multiple.ins-item-7") ||
          data[0][j] === this.props.t("multiple.ins-item-9") ||
          data[0][j] === this.props.t("multiple.ins-item-8") || data[0][j] === this.props.t("multiple.ins-item-10") ||
          data[0][j] === this.props.t("multiple.ins-item-4") || data[0][j] === this.props.t("multiple.ins-item-14")) {
          if (!!data[i][j] && isNaN(data[i][j])) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-24") +
                this.props.t("multiple.ins-item-25") + data[0][j]
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.ins-item-11") && type === "desmonte") {
          const dataVariables = variablesObs.map((item) => item.name).join(", ")
          if (!!data[i][j] &&
            this.findWithAttr(variablesObs, "name", data[i][j]) === -1) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-26") +
                this.props.t("multiple.ins-item-27") + this.props.t("multiple.ins-item-28") + dataVariables
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.ins-item-5") && type === "desmonte") {
          const warehouse = this.getWarehouse(data[i][j]);
          const nameWarehouses = this.state.warehouses.map((item) => item.name ? item.name : this.props.t("multiple.ins-item-29")).join(", ")
          if (!!warehouse) {
            data[i][j] = data[i][j];
          } else {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-30") + data[i][j] +
                this.props.t("multiple.ins-item-31") + this.props.t("multiple.ins-item-28") + nameWarehouses
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.ins-item-19")) {
          const catalogue = this.getRetreadCatalogueId(data[i][j]);
          if (!!catalogue) {
            data[i][j] = data[i][j];
          } else {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-32") + data[i][j] +
                this.props.t("multiple.ins-item-31")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.ins-item-3")) {
          const plate = this.getVehicleId(data[i][j]);
          if (!!plate) {
            data[i][j] = data[i][j];
          } else {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-33") + data[i][j] +
                this.props.t("multiple.ins-item-31")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.ins-item-6")) {
          const tire = this.getTireId(data[i][j]);
          console.log(tire)
          if (!!tire) {
            data[i][j] = data[i][j];
          } else {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-34") + data[i][j] +
                this.props.t("multiple.ins-item-31")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.ins-item-1")) {
          if (moment(data[i][j], "YYYY-MM-DD").format("YYYY-MM-DD") !==
            data[i][j]) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-35")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.ins-item-2")) {
          let reg = new RegExp("^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$");
          if (!reg.test(data[i][j])) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-36")
            });
          }
        }

        if (data[0][j] === this.props.t("multiple.ins-item-13")) {
          if (!data[i][j]) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-37")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.ins-item-7") ||
          data[0][j] === this.props.t("multiple.ins-item-8") ||
          data[0][j] === this.props.t("multiple.ins-item-9")) {
          if (data[i][j] == null && /*USO*/ data[i][7] === "U") {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-38") + data[0][j] + this.props.t("multiple.ins-item-39")
            });
          }
        }

        if (data[0][j] === this.props.t("multiple.ins-item-16")) {
          brandInfoRetread.marca = data[i][j]
        }

        if (data[0][j] === this.props.t("multiple.ins-item-17")) {
          brandInfoRetread.modelo = data[i][j]
        }

        if (data[0][j] === this.props.t("multiple.ins-item-18")) {
          brandInfoRetread.dimension = data[i][j]
        }

        if (type === "reencauche") {
          if (this.getCurrentTireInfo(brandInfoRetread, type) === null) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-40") + type
            });
          }
        }
      }
    }

    return errors;
  };

  handleFile = (file) => {
    this.setState({ data: [], cols: [], done: [], notDone: [], errors: [] });

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = async (event) => {
      const bstr = event.target.result;
      const wb = xlsx.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1, raw: false });
      const cols = this.makeCols(ws["!ref"]);
      const tireListIns = await this.getDataTireIns(cols, data)
      let errors;
      if (tireListIns.result_tire) {
        this.setState({ tiresAllinspections: tireListIns.result_tire })
      }
      if (tireListIns.result_vehicle) {
        this.setState({ vehiclesAllInspections: tireListIns.result_vehicle })
      }
      if (tireListIns) {
        errors = await this.getDataErrors(cols, data);
      }
      if (errors.length === 0) {
        this.setState({
          data: data,
          cols: this.makeCols(ws["!ref"]),
          inputKey: Math.random().toString(36)
        });
      } else {
        this.modal.current.showModal(errors);
        this.setState({
          errors: [],
          data: [],
          cols: [],
          inputKey: Math.random().toString(36)
        });
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  exportTemplate = event => {
    event.preventDefault();

    let header = this.state.fields;
    const ws = xlsx.utils.aoa_to_sheet([header]);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Plantilla");
    xlsx.writeFile(wb, this.props.t("multiple.ins-item-41") + this.state.type + ".xlsx")
  };

  formatData = (register, authUser) => {
    let inspection = {
      brandInfo: {},
      retreadInfo: {},
      type: "",
      tire_id: null,
      fc_ultima_inspeccion: "",
      plate: "",
      ubicacion: "",
      codigo: "",
      nombre_banda_reencauche: null,
      ult_presion: null,
      posicion_montaje: null,
      analista: "",
      tecnico: "",
      ult_prof_centro_exterior: "",
      ult_prof_centro: "",
      ult_prof_interior: "",
      type: "",
      banda_reencauche: "",
      vehiculo: "",
      customer_id: null,
      km_id: null,
      novedades_id: null,
      kilometers: "",
      hora: "",
      vida: "",
      order: "",
      observaciones_analista: "",
      observaciones: "",
      prof_original: 0,
      prof_minima: 0,
    };

    // Set customer and type
    inspection.type = this.state.type;
    inspection.customer_id = localStorage.getItem("GlobalCustomerId");

    // Retread
    const brandInfoRetread = {
      marca: null,
      modelo: null,
      dimension: null,
    }

    // Set general values
    let header = this.state.data[0];
    for (let i = 0; i < header.length; i++) {
      if (header[i] === this.props.t("multiple.ins-item-1")) {
        inspection.fc_ultima_inspeccion = register[i]
        // } else if (header[i] === "Número Interno ó ID") {
        //   if(register[i] !== undefined) {
        //     inspection.plate = register[i]
        //   }
        // } else if (header[i] === "Placa") {
        //   if(register[i] !== undefined) {
        //     inspection.plate = register[i]
        //     inspection.vehiculo = register[i]
        //   }

      } else if (header[i] === this.props.t("multiple.ins-item-2")) {
        inspection.hora = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-4")) {
        inspection.kilometers = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-5") && inspection.type === "desmonte") {
        inspection.bodega_id = this.getWarehouse(register[i]);
      } else if (header[i] === this.props.t("multiple.ins-item-6")) {
        inspection.codigo = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-7")) {
        inspection.ult_prof_centro_exterior = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-8")) {
        inspection.ult_prof_centro = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-9")) {
        inspection.ult_prof_interior = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-3")) {
        inspection.vehiculo = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-10")) {
        inspection.ult_presion = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-13")) {
        inspection.tecnico = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-14") && inspection.type === "montaje") {
        inspection.posicion_montaje = parseInt(register[i])
      } else if (header[i] === this.props.t("multiple.ins-item-14") && inspection.type === "inspeccion") {
        inspection.posicion_montaje = parseInt(register[i])
      } else if (header[i] === this.props.t("multiple.ins-item-20")) {
        inspection.precio_regrabado = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-15")) {
        inspection.vida = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-42")) {
        inspection.order = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-11")) {
        inspection.observaciones = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-12")) {
        inspection.observaciones_analista = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-21")) {
        inspection.prof_original = register[i]
      } else if (header[i] === this.props.t("multiple.ins-item-22")) {
        inspection.prof_minima = register[i]
      }


      if (inspection.type === "reencauche") {
        if (header[i] === this.props.t("multiple.ins-item-16")) {
          brandInfoRetread.marca = register[i]
        } else if (header[i] === this.props.t("multiple.ins-item-17")) {
          brandInfoRetread.modelo = register[i]
        } else if (header[i] === this.props.t("multiple.ins-item-18")) {
          brandInfoRetread.dimension = register[i]
        } else if (header[i] === this.props.t("multiple.ins-item-19")) {
          inspection.catalogo = register[i]
        }
        // Mapping names of brands to ids
        const trInfo = this.getCurrentTireInfo(brandInfoRetread, "reencauche");
        inspection.retreadbrand_id = trInfo.marca_id;
        inspection.retreadband_id = trInfo.modelo_id;
        inspection.retreadimention_id = trInfo.dimension_id;
        inspection.retreadcatalogue_id = this.getRetreadCatalogueId(inspection.catalogo)
      }
    }

    // Set last date and analyst
    inspection.fc_ultima_inspeccion = moment(inspection.fc_ultima_inspeccion +
      " " + inspection.hora).utc().format();
    inspection.analista = authUser.email;

    return inspection;
  };

  runValidations = (json) => {
    if (!!json.message) {
      if (json.message === "Fallo La Validacion") {
        let msg = "";
        Object.keys(json.resp).forEach((key) => {
          if (!json.resp[key][1]) {
            msg = msg + json.resp[key][0] + "\n";
          }
        });
        throw new Error(msg);
      } else {
        throw new Error(json.message);
      }
    }
  };

  saveRegister = (idx, register, authUser) => {
    let deferred = Q.defer();
    if (isInternetConnected(toast)) deferred.reject();
    const data = this.formatData(register, authUser);
    let tire_id = this.getTireId(data.codigo);
    data.vehicle_id = this.getVehicleId(data.vehiculo);
    data.massive = true;
    trackPromise(fetch(APIS.API_REST_INSPECTIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id", tire_id), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify({
        ...data,
        observaciones: [
          {
            observaciones_analista: data.obsevaciones_analista,
            observaciones: data.Observaciones
          }
        ]
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.runValidations(json);
        return json;
      })
      .then(json => {
        let done = [...this.state.done];
        done.push(idx);
        this.setState({ done: done });
        deferred.resolve(json);
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("multiple.global-item-6") +
            (idx + 1);
        } else {
          err = this.props.t("multiple.global-item-7") + (idx + 1) + ". " + error.toString();
        }
        this.serverErrors.push({ message: err });
        let notDone = [...this.state.notDone];
        notDone.push(idx);
        this.setState({ notDone: notDone });
        deferred.reject(this.serverErrors);
      }));
    return deferred.promise;
  };

  getCleanedRegisters = registers => {
    let newRegisters = [];

    for (let i = 1; i < registers.length; i++) {
      if (registers[i].length > 0) {
        newRegisters.push(registers[i]);
      }
    }

    return newRegisters;
  };

  async saveRegisters(registers, authUser) {
    let deferred = Q.defer();

    let newRegisters = this.getCleanedRegisters(registers);
    for (let i = 0; i < newRegisters.length; i++) {
      if (this.state.done.indexOf(i) === -1) {
        await this.saveRegister(i, newRegisters[i], authUser)
          .then(() => {
            return true;
          })
          .catch(errors => {
            deferred.reject(errors);
            return deferred.promise;
          });
      }
    }

    deferred.resolve();
    return deferred.promise;
  };

  onSubmit = (event, authUser) => {
    event.preventDefault();
    this.serverErrors = [];
    this.setState({ notDone: [] });

    this.saveRegisters(this.state.data, authUser)
      .then(() => {
        toast.success(this.props.t("multiple.global-item-8"));
        this.setState({
          type: "",
          data: [],
          cols: [],
          done: [],
          notDone: [],
          errors: []
        });
      })
      .catch(errors => {
        this.modal.current.showModal(errors);
      });
  };

  render() {
    const { data, cols, done, notDone, fields,
      type } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <ErrorsModal ref={this.modal} t={this.props.t} />
            <h3>{this.props.t("ins-one.ins-one-massive")}</h3>
            <br></br>
            <DragDropFile handleFile={this.handleFile}>
              <form>
                <TypeFormTire
                  isMultiple={true}
                  type={type}
                  t={this.props.t}
                  onChange={this.onChange}
                  delCreate={false}
                  massiveInspections={true}
                  authUser={authUser}
                />
                <br />
                {
                  !!type ?
                    <div>
                      <DataInput
                        t={this.props.t}
                        type={type}
                        handleFile={this.handleFile}
                        exportTemplate={this.exportTemplate}
                        header={fields}
                        inputKey={this.state.inputKey || ""}
                      />
                      {
                        data.length > 1 ?
                          <OutTable
                            t={this.props.t}
                            type={type}
                            data={data}
                            cols={cols}
                            done={done}
                            notDone={notDone}
                          /> : null
                      }
                      {
                        data.length > 1 ?
                          <div className="row">
                            <div className="col-md-12 mt-5">
                              <button type="submit"
                                className="btn btn-primary"
                                style={{ width: "100%" }}
                                onClick={(event) => this.onSubmit(event, authUser)}>
                                <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                              </button>
                            </div>
                          </div> : null
                      }
                    </div> : null
                }
              </form>
            </DragDropFile>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(MultipleInspections);
