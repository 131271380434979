import React from "react";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsCustomer from "../../constants/actions/Customers";
import * as APIS from "../../constants/apis";
import API from "../../constants/api";
import * as ROLES from "../../constants/roles";
import * as VARIABLES from "../../constants/variables";
import { isSuperAdmin } from "../../constants/validations";

import CustomerDeleteItem from "./CustomerDeleteItem";

class CustomersMacroList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      status: null,
      isActive: true,


      isPerMacro: false,
      dataMacrosFleets: []
    };
  };

  componentDidMount() {
    if(sessionStorage.getItem("GlobalMacroSelectedId")) {
      this.getFleetsPerMacro()
    } else {
      toast.info("Detectamos que tienes asignadas una o mas macro cuentas, por favor, selecciona una antes de continuar")
      this.props.history.push("/")
    }
  };

  getFleetsPerMacro = () => {
      let id = sessionStorage.getItem("GlobalMacroSelectedId")
      if(id && sessionStorage.getItem("GlobalMacroSelectedType") === "mc") {
        let str = `?type_account=mc_account`
        trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_GET_CLIENTS_MACRO_NEW.replace("macro_id", id) + str, {
            headers:{
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            }
        })
        .then(response => response.json())
        .then(json => {
            if (json.Customers) {
              this.setState({ dataMacrosFleets: json.Customers })
            }
        })
        .catch(err => {
            toast.error(err.message)
        }))
      } else {
        let str = `?type_account=mmc_account`
        trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_GET_CLIENTS_MACRO_NEW.replace("macro_id", id) + str, {
            headers:{
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            }
        })
        .then(response => response.json())
        .then(json => {
            if (json.Customers) {
              this.setState({ dataMacrosFleets: json.Customers })
            }
        })
        .catch(err => {
            toast.error(err.message)
        }))
      }
  }

  chooseGlobalCustomer(name, id) {
    toast.success(this.props.t("alerts.customer-item-1"));
    localStorage.setItem('GlobalCustomerName', name);
    localStorage.setItem('GlobalCustomerId', id);
    document.getElementById("customerNameLink").innerHTML = name
    setTimeout(() => window.location.reload(), 1000)
    fetch(APIS.API_REST_INSPECTIONS_PENDING_COUNT.replace("customer_id", id), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        document.getElementById("navMenu").style.display = ""
        localStorage.setItem('GlobalPendingCount', json.count);
        document.getElementById("pendingCount").innerHTML = "" + json.count + ""
        fetch(APIS.API_REST_INSPECTIONS_PENDING_WITHOUT_COUNT.replace("customer_id", id), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
          .then(response => {
            return response.json();
          })
          .then(json => {
            localStorage.setItem('GlobalPendingWithoutKmCount', json.count);
            document.getElementById("pendingWithoutKmCount").innerHTML = "" + json.count + ""
          })
      })
  };

  getCustomersZona = (customers) => {
    const arrayCustomersZona = []
    for(let i = 0; i < customers.length; i++) {
      if(customers[i].zone === "Noreste") {
        arrayCustomersZona.push(customers[i])
      }
    }
    for(let i = 0; i < customers.length; i++) {
      if(customers[i].zone === "Sureste") {
        arrayCustomersZona.push(customers[i])
      }
    }
    for(let i = 0; i < customers.length; i++) {
      if(customers[i].zone === "Centro") {
        arrayCustomersZona.push(customers[i])
      }
    }
    for(let i = 0; i < customers.length; i++) {
      if(customers[i].zone === "Pacifico") {
        arrayCustomersZona.push(customers[i])
      }
    }
    for(let i = 0; i < customers.length; i++) {
      if(customers[i].zone === "Bajio") {
        arrayCustomersZona.push(customers[i])
      }
    }
    for(let i = 0; i < customers.length; i++) {
      if(customers[i].zone === "Norte") {
        arrayCustomersZona.push(customers[i])
      }
    }
    for(let i = 0; i < customers.length; i++) {
      if(customers[i].zone === "Metro") {
        arrayCustomersZona.push(customers[i])
      }
    }
    for(let i = 0; i < customers.length; i++) {
      if(customers[i].zone === "Sur") {
        arrayCustomersZona.push(customers[i])
      }
    }
    for(let i = 0; i < customers.length; i++) {
      if(customers[i].zone === null) {
        arrayCustomersZona.push(customers[i])
      }
    }

    return arrayCustomersZona
  }

  getCustomersList = (authUser) => {
    const { search, dataMacrosFleets } = this.state;
    if(!dataMacrosFleets) return null;

    let customData = dataMacrosFleets
    customData = customData.filter(customer => {
      return (customer.name.toLowerCase().search(search) >= 0 );
    })

    return (
      <>
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("customers.customer-item-1")}</th>
              <th scope="col">{this.props.t("customers.customer-item-2")}</th>
              <th scope="col">{this.props.t("customers.customer-item-3")}</th>
              <th scope="col">{this.props.t("customers.customer-item-8")}</th>
              <th scope="col">{this.props.t("customers.customer-item-9")}</th>
              <th scope="col">{this.props.t("customers.customer-item-6")}</th>
              <th scope="col">{this.props.t("customers.customer-item-7")}</th>
            </tr>
          </thead>
          <tbody>
          {
          customData.map((customer, index) => {
            return <tr key={customer.id}>
                <td>
                  {customer.zone}
                </td>
                <td>
                  {customer.name}
                </td>
                <td>
                  {customer.analista}
                </td>
                <td>
                {customer.tire_count}
                </td>
                <td>
                {customer.vehicle_count}
                </td>
                <td>
                  {customer.standby_inspections}
                </td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => this.chooseGlobalCustomer(
                                    customer.name,
                                    customer.id)}>
                    <i className="fa fa-briefcase"></i> {this.props.t("customers.customer-choose")}
                  </button>&nbsp;
                </td>
            </tr>
          })
          }
          </tbody>
        </table>
      </div>
      </>
    );
  };

  showSuccessDialog = (message) => {
    if(!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if(!!error && !!toast) {
      toast.error(error);
    }
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render () {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("customers.customer-list") } Macro</h3>
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder={this.props.t("globals.search-input")}
                  onChange={this.onChangeSearch}
                />
              </div>
            </div>
            <br/>
            <br/><br/><br/><br/>
            {this.getCustomersList(authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const condition = authUser =>
!!authUser && (authUser.roles === ROLES.ADMIN || authUser.roles === ROLES.ANALYST || authUser.roles === ROLES.CUSTOMER);

export default withAuthorization(condition)(CustomersMacroList);
