import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsVehicleType from "../../constants/actions/VehicleTypes";
import * as APIS from "../../constants/apis";
import VehicleTypeDeleteItem from "./VehicleTypeDeleteItem";

function getVehicleTypes(vtypes, vtype, t) {
  return dispatch => {
    if (!localStorage.getItem("GlobalCustomerId")) return null;
    return trackPromise(fetch(APIS.API_REST_TYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.v-type-item-1"));
        }
        return response.json();
      })
      .then(json => {
        dispatch(getVehicleTypesSuccess({
          vtypes: json.vehicletypes
        }));
      })
      .catch(error => {
        dispatch(getVehicleTypesError({
          vtypes: vtypes,
          vtype: vtype,
          error: error.message
        }));
      }));
  };
};

export function getVehicleTypesSuccess(json) {
  return {
    type: constantsVehicleType.GET_VTYPES_SUCCESS,
    json
  };
};

export function getVehicleTypesError(json) {
  return {
    type: constantsVehicleType.GET_REQUEST_ERROR,
    json
  };
};

class VehicleTypesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  };

  componentDidMount() {
    this.props.getVehicleTypes(this.props.vtypes, this.props.vtype, this.props.t);
  };

  getVehicleTypesList = (vtypes, authUser) => {
    if (!vtypes) return null;
    const { search } = this.state;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("vehicles-type.type-item-1")}</th>
              <th scope="col">{this.props.t("vehicles-type.type-item-2")}</th>
            </tr>
          </thead>
          <tbody>
            {
              vtypes.filter(vtype => {
                return vtype.name.toLowerCase().search(search) >= 0;
              }).map(vtype => {
                return <tr key={vtype.id}>
                  <td>
                    {vtype.name}
                  </td>
                  <td>
                    {!!authUser.permissions.edit ?
                      <Link to={"/types/edit/" + vtype.id}
                        className="btn btn-primary">
                        <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                  </Link> : null}&nbsp;
                 {!!authUser.permissions.delete ?
                      <VehicleTypeDeleteItem t={this.props.t} vtypeDelete={vtype} /> : null}
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render() {
    const { vtypes, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("vehicles-type.type-list")}</h3>
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder={this.props.t("globals.search-input")}
                  onChange={this.onChangeSearch}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                {!!authUser.permissions.create ?
                  <Link
                    to="/types/new"
                    className="btn btn-primary">
                    <i className="fa fa-plus"></i> {this.props.t("globals.register")}
                </Link> : null}
              </div>
            </div>
            <br /><br />
            {this.getVehicleTypesList(vtypes, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  vtypes: state => state.vtypes,
  vtype: state => state.vtype,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getVehicleTypes };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(VehicleTypesList));
