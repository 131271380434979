import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import React from "react";
import { toast } from "react-toastify";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as APIS from "../../constants/apis";
import * as jsPDF from "jspdf";
import * as html2Canvas from "html2canvas";
import Metabase from "../Reports/Metabase";
import { validateMetaLanguage } from "../../utils/validateMetaLanguage";

class Tires extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_city: null,
      id_costcenter: null,
      id_group: null,
      catCities: [],
      catFront1: [],
      catFront2: [],
      query: [],
      type: "",
      fecha_inicial: "",
      fecha_final: "",
      pageP: null,
      pageN: null,
      dashName: null,
      perMetabase: null,
    };
  }

  componentDidMount() {
    this.getCatalogues();
  }

  getCatalogues() {
    //CATALOGUES
    fetch(
      APIS.FILTER_CITIES.replace(
        "customer_id",
        localStorage.getItem("GlobalCustomerId")
      ),
      {
        headers: {
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        this.setState({ catCities: json.cities });
      });
    fetch(
      APIS.FILTER_VEHICLEGROUPS.replace(
        "customer_id",
        localStorage.getItem("GlobalCustomerId")
      ),
      {
        headers: {
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        this.setState({ catFront1: json.vehiclegroups });
      });
    fetch(
      APIS.FILTER_COSTCENTERS.replace(
        "customer_id",
        localStorage.getItem("GlobalCustomerId")
      ),
      {
        headers: {
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        this.setState({ catFront2: json.costcenters });
      });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  generateExcel() {
    var str =
      "?export=true&fecha_inicial=" +
      this.state.fecha_inicial +
      "&fecha_final=" +
      this.state.fecha_final;
    if (this.state.type) {
      str = str + "&" + this.state.type + "=true";
    }
    return trackPromise(
      fetch(
        `${
          APIS.API_REST_TIRES_QUERY.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ) + str
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.query-item-1"));
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.props.t("alerts.query-item-2")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success(this.props.t("alerts.query-item-3"));
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  }

  generatePDF() {
    var str =
      "?pdf=true&fecha_inicial=" +
      this.state.fecha_inicial +
      "&fecha_final=" +
      this.state.fecha_final +
      "&city_id=" +
      this.state.id_city +
      "&id_costcenter=" +
      this.state.id_costcenter +
      "&id_group=" +
      this.state.id_group;
    if (this.state.type) {
      str = str + "&" + this.state.type + "=true";
    }
    return trackPromise(
      fetch(
        `${
          APIS.API_REST_TIRES_QUERY.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ) + str
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.query-item-5"));
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.state.type}_${this.props.t("alerts.query-item-2")}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success(this.props.t("alerts.query-item-6"));
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  }

  getQuery = (page, type) => {
    this.setState({ perMetabase: null, dashId: null });
    var str = "";
    if (page !== "0" && !!page) {
      str = "?page=" + page + "&limit=20";
    } else {
      str = "?page=1&limit=20";
    }
    if (type) {
      str = str + "&" + type + "=true";
    }

    str =
      str +
      "&fecha_inicial=" +
      this.state.fecha_inicial +
      "&fecha_final=" +
      this.state.fecha_final +
      "&city_id=" +
      this.state.id_city;
    console.log(str);
    return trackPromise(
      fetch(
        `${
          APIS.API_REST_TIRES_QUERY.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ) + str
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.query-item-4"));
          }
          return response.json();
        })
        .then((json) => {
          let pageP, pageN;
          if (!!json.previus) {
            str = json.previus;
            pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          if (!!json.next) {
            str = json.next;
            pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          this.setState({ query: json.tires, pageP: pageP, pageN: pageN });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.query-item-4"));
        })
    );
  };

  getQueryView = (plans, pageP, pageN, type) => {
    if (!plans) return null;
    return (
      <div className="table-responsive">
        <table className="table table-sm">
          <thead>
            <tr>
              {plans.length > 0
                ? Object.entries(plans[0]).map((plan) => {
                    return (
                      <th scope="col">
                        {plan[0].replace(/_/g, " ").toUpperCase()}
                      </th>
                    );
                  })
                : null}
            </tr>
          </thead>
          <tbody>
            {plans.length > 0
              ? plans.map((plan, index) => {
                  return (
                    <tr key={index}>
                      {Object.entries(plan).map((pl) => {
                        return <td>{pl[1]}</td>;
                      })}
                    </tr>
                  );
                })
              : null}
          </tbody>
          <tfoot>
            <tr>
              <td>
                {!!pageP ? (
                  <button
                    className="btn btn-primary"
                    onClick={(e) => this.getQuery(pageP, type)}
                  >
                    <i className="fa fa-arrow-left"></i>
                  </button>
                ) : (
                  <button className="btn btn-primary" disabled>
                    <i className="fa fa-arrow-left"></i>
                  </button>
                )}
              </td>
              <td>
                {!!pageN ? (
                  <button
                    className="btn btn-primary"
                    onClick={(e) => this.getQuery(pageN, type)}
                  >
                    <i className="fa fa-arrow-right"></i>
                  </button>
                ) : (
                  <button className="btn btn-primary" disabled>
                    <i className="fa fa-arrow-right"></i>
                  </button>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  showNewMetabase = () => {
    return (
      <>
        {this.state.dashName === "general" ? (
          <Metabase dashboardId={validateMetaLanguage(898, 1113)} />
        ) : null}
        {this.state.dashName === "montajes" ? (
          <Metabase dashboardId={validateMetaLanguage(896, 1115)} />
        ) : null}
        {this.state.dashName === "desmontes" ? (
          <Metabase dashboardId={validateMetaLanguage(895, 1116)} />
        ) : null}
        {this.state.dashName === "desechos meta" ? (
          <Metabase dashboardId={validateMetaLanguage(954, 1118)} />
        ) : null}
        {this.state.dashName === "inspecciones" ? (
          <Metabase dashboardId={validateMetaLanguage(897, 1121)} />
        ) : null}
        {this.state.dashName === "general movimientos" ? (
          <Metabase dashboardId={validateMetaLanguage(899, 1114)} />
        ) : null}
        {this.state.dashName === "novedades y antecedentes meta" ? (
          <Metabase dashboardId={validateMetaLanguage(970, 1123)} />
        ) : null}
        {this.state.dashName === "psi" ? (
          <Metabase dashboardId={validateMetaLanguage(1048, 1122)} />
        ) : null}
        {this.state.dashName === "warehouses" ? (
          <Metabase dashboardId={validateMetaLanguage(1024, 1119)} />
        ) : null}
        {this.state.dashName === "brands" ? (
          <Metabase dashboardId={validateMetaLanguage(1109, 1109)} />
        ) : null}
        {this.state.dashName === "performance" ? (
          <Metabase dashboardId={validateMetaLanguage(1033, 1120)} />
        ) : null}
        {this.state.dashName === "rotations" ? (
          <Metabase dashboardId={validateMetaLanguage(1092, 1117)} />
        ) : null}
      </>
    );
  };

  onShowNewMetabase = (name) => {
    this.setState({ perMetabase: true, dashName: name, type: name });
    this.showNewMetabase();
  };

  onChangeInicial = (event) => {
    this.setState({ fecha_inicial: event.target.value });
  };

  onChangeFinal = (event) => {
    this.setState({ fecha_final: event.target.value });
  };

  onChangeCitite = (event) => {
    this.setState({ id_city: event.target.value });
    setTimeout(() => {
      this.getQuery("1", "novedades");
    }, 500);
  };

  render() {
    const { catCities, catFront1, catFront2 } = this.state;
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-4">
                {!this.state.perMetabase && (
                  <h3>
                    {this.props.t("query-tire.client-item-1")} {this.state.type}
                  </h3>
                )}
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4 pull-right">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.props.t("query-tire.client-item-2")}
                      </button>
                      <div className="dropdown-menu">
                        <button
                          className="dropdown-item"
                          onClick={() => this.onShowNewMetabase("general")}
                        >
                          <span>
                            {this.props.t("query-tire.client-item-3")}
                          </span>
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            this.onShowNewMetabase("general movimientos")
                          }
                        >
                          <span>
                            {this.props.t("query-tire.client-item-15")}
                          </span>
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => this.onShowNewMetabase("montajes")}
                        >
                          {this.props.t("query-tire.client-item-4")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => this.onShowNewMetabase("desmontes")}
                        >
                          {this.props.t("query-tire.client-item-5")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => this.onShowNewMetabase("rotations")}
                        >
                          {this.props.t("query-tire.client-item-21")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            this.setState({
                              pageP: null,
                              pageN: null,
                              type: "desechos",
                            });
                            this.getQuery("1", "desechos");
                          }}
                        >
                          {this.props.t("query-tire.client-item-6")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            this.onShowNewMetabase("desechos meta")
                          }
                        >
                          {this.props.t("query-tire.client-item-16")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => this.onShowNewMetabase("warehouses")}
                        >
                          {this.props.t("query-tire.client-item-18")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => this.onShowNewMetabase("brands")}
                        >
                          {this.props.t("query-tire.client-item-22")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => this.onShowNewMetabase("performance")}
                        >
                          {this.props.t("query-tire.client-item-19")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => this.onShowNewMetabase("inspecciones")}
                        >
                          {this.props.t("query-tire.client-item-7")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => this.onShowNewMetabase("psi")}
                        >
                          {this.props.t("query-tire.client-item-20")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            this.setState({
                              pageP: null,
                              pageN: null,
                              type: "novedades",
                            });
                            this.getQuery("1", "novedades");
                          }}
                        >
                          {this.props.t("query-tire.client-item-8")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            this.setState({
                              pageP: null,
                              pageN: null,
                              type: "antecedentes",
                            });
                            this.getQuery("1", "antecedentes");
                          }}
                        >
                          {this.props.t("query-tire.client-item-9")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            this.onShowNewMetabase(
                              "novedades y antecedentes meta"
                            )
                          }
                        >
                          {this.props.t("query-tire.client-item-17")}
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.type === "desechos" ||
                  this.state.type === "novedades" ||
                  this.state.type === "antecedentes" ? (
                    <div className="col-md-8 text-right">
                      <div className="row">
                        <div className="form-group col-md-5">
                          <div className="row float-right">
                            <div className="col-sm-2">
                              <label htmlFor="fechaInicial">
                                {this.props.t("query-tire.client-item-10")}
                              </label>
                            </div>
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                placeholder={this.props.t(
                                  "query-tire.client-item-10"
                                )}
                                id="fechaInicial"
                                name="fecha_inicial"
                                value={this.state.fecha_inicial}
                                type="date"
                                onChange={this.onChangeInicial}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-md-7">
                          <div className="row">
                            <div className="col-sm-2">
                              <label htmlFor="fechaFinal">
                                {this.props.t("query-tire.client-item-11")}
                              </label>
                            </div>
                            <div className="col-sm-10 input-group mb-3">
                              <input
                                aria-describedby="btnSearch"
                                className="form-control"
                                type="date"
                                id="fecha_final"
                                name="fecha_final"
                                value={this.state.fecha_final}
                                placeholder={this.props.t(
                                  "query-tire.client-item-11"
                                )}
                                onChange={this.onChangeFinal}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-primary"
                                  id="btnSearch"
                                  onClick={(event) => {
                                    if (!!this.state.type) {
                                      this.setState({
                                        pageP: null,
                                        pageN: null,
                                      });
                                      this.getQuery("1", this.state.type);
                                    }
                                  }}
                                >
                                  <i className="fa fa-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <br />
            <div id="divToPrint" style={{ padding: "2em", display: "none" }}>
              <div className="row">
                <div className="col-md-12">
                  <table border="2" width="100%">
                    <thead>
                      <th width="50%">
                        <img
                          style={{ marginTop: "0px", height: "70px" }}
                          src="/images/logo.png"
                          alt="Ruedata - Logo"
                          className="img-fluid"
                        />
                        <hr></hr>
                        <h3>{localStorage.getItem("GlobalCustomerName")}</h3>
                      </th>
                      <th width="50%" className="text-center">
                        <h2>{this.state.type.toUpperCase()}</h2>
                        <hr></hr>
                        <h3>
                          {new Date()
                            .toLocaleString("es-CO", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                            .toUpperCase()}
                        </h3>
                      </th>
                    </thead>
                  </table>
                  <table border="2" width="100%">
                    <tbody id="tbody"></tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>

              <div className="form-group col-md-2">
                {this.state.type === "desechos" &&
                this.state.type === "novedades" &&
                this.state.type === "antecedentes" ? (
                  <div className="row">
                    <label className="col-md-2">
                      {this.props.t("query-tire.client-item-12")}
                    </label>
                    <div className="col-md-10">
                      <select
                        id="catFront2"
                        name="id_costcenter"
                        value={this.state.id_costcenter}
                        className="form-control"
                        data-live-search="true"
                        onChange={this.onChange}
                      >
                        <option></option>
                        {catFront2.map((activity) => {
                          return (
                            <option key={activity.name} value={activity.id}>
                              {activity.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group col-md-2">
                {this.state.type === "desechos" &&
                this.state.type === "novedades" &&
                this.state.type === "antecedentes" ? (
                  <div className="row">
                    <label className="col-md-2">
                      {this.props.t("query-tire.client-item-13")}
                    </label>
                    <div className="col-md-10">
                      <select
                        id="catFront1"
                        name="id_group"
                        className="form-control"
                        data-live-search="true"
                        value={this.state.id_group}
                        onChange={this.onChange}
                      >
                        <option></option>
                        {catFront1.map((activity) => {
                          return (
                            <option key={activity.name} value={activity.id}>
                              {activity.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group col-md-2">
                {this.state.type === "desechos" &&
                this.state.type === "novedades" &&
                this.state.type === "antecedentes" ? (
                  <div className="row">
                    <label className="col-md-2">
                      {this.props.t("query-tire.client-item-14")}
                    </label>
                    <div className="col-md-10">
                      <select
                        id="catCities"
                        name="id_city"
                        className="form-control"
                        data-live-search="true"
                        value={this.state.id_city}
                        onChange={this.onChangeCitite}
                      >
                        <option></option>
                        {catCities.map((activity) => {
                          return (
                            <option key={activity.name} value={activity.id}>
                              {activity.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-2 text-right">
                {this.state.type === "desechos" ||
                this.state.type === "novedades" ||
                this.state.type === "antecedentes" ? (
                  <button
                    className="btn btn-primary"
                    onClick={(event) => this.generatePDF()}
                  >
                    <i className="fa fa-file-pdf"></i>{" "}
                    {this.props.t("globals.download")}
                  </button>
                ) : null}

                {!this.state.perMetabase ? (
                  <button
                    className="btn btn-success"
                    onClick={(event) => this.generateExcel()}
                  >
                    <i className="fa fa-file-export"></i>{" "}
                    {this.props.t("globals.exports")}
                  </button>
                ) : null}
              </div>
            </div>
            <br />
            {this.state.perMetabase
              ? null
              : this.getQueryView(
                  this.state.query,
                  this.state.pageP,
                  this.state.pageN,
                  this.state.type
                )}
            {this.state.perMetabase ? this.showNewMetabase() : null}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Tires);
