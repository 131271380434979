import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import * as constantsVehicle from "../../../constants/actions/Vehicles";
import * as APIS from "../../../constants/apis";
import { toast } from "react-toastify";
import api from "../../../constants/api";


class VehicleDeleteItem extends React.Component {
  constructor(props) {
    super(props)
  }
  deleteVehicle(name, vehicles, vehicle, t) {
    if (window.confirm("¿Estás seguro de eliminar éste vehículo?")) {
      trackPromise(
        api
          .deleteVehicle(localStorage.getItem("GlobalCustomerId"), name)
          .then(res => {
            console.log(res)
            if (res.message) {
              toast.success(res.message)
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            }
          })
          .catch(error => {
            if (error.response.status != 200) {
              toast.error(error.response.data.message)
            }
          })
      )
    }
  }

  render() {
    const { vehicles, vehicle, vehicleDelete, deleteVehicle } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => this.deleteVehicle(vehicleDelete.id, vehicles, vehicle, this.props.t)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
};

export default VehicleDeleteItem;
