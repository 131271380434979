import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsWarehouse from "../../../constants/actions/Warehouses";
import * as APIS from "../../../constants/apis";

import WarehouseDeleteItem from "./WarehouseDeleteItem";

function getWarehouses(warehouses, warehouse, t) {
  let warehousesReq = [];
  return dispatch => {
    return trackPromise(fetch(APIS.API_REST_WAREHOUSES.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
                      headers:{
                        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                        "Accept-language": localStorage.getItem("LanguageSelected"),
                      } })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(t("alerts.warehouse-item-1"));
        }
        return response.json(); })
      .then(json => {
        warehousesReq = json.bodegas;
        dispatch(getWarehousesSuccess({
          warehouses: warehousesReq,
          warehouse : warehouse
        })); })
      .catch(error => {
        dispatch(getWarehousesError({
          warehouses: warehouses,
          warehouse : warehouse,
          error     : error.message
        }));
      }));
  };
};

export function getWarehousesSuccess(json) {
  return {
    type: constantsWarehouse.GET_WAREHOUSES_SUCCESS,
    json
  };
};

export function getWarehousesError(json) {
  return {
    type: constantsWarehouse.GET_REQUEST_ERROR,
    json
  };
};

class WarehousesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  };

  componentDidMount() {
    const { warehouses, warehouse } = this.props;
    this.props.getWarehouses(warehouses, warehouse, this.props.t);
  };

  getWarehousesList = (warehouses, authUser) => {
    if(!warehouses) return null;
    const { search } = this.state;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("client-general.client-item-1")}</th>
              <th scope="col">{this.props.t("client-general.client-item-2")}</th>
            </tr>
          </thead>
          <tbody>
          {
            warehouses.filter(customer => {
              return customer.name.toLowerCase().search(search) >= 0;
            }).map(warehouse => {
              return <tr key={warehouse.id}>
                <td>
                  {warehouse.name}
                </td>
                <td>
                 { !!authUser.permissions.edit ? 
                  <Link to={"/warehouses/edit/"+warehouse.id}
                        className="btn btn-primary">
                    <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                  </Link> : null }&nbsp;
                 { !!authUser.permissions.delete ? 
                  <WarehouseDeleteItem
                    t={this.props.t}
                    warehouseDelete={warehouse}
                  /> : null }
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    );
  };

  showSuccessDialog = (message) => {
    if(!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if(!!error && !!toast) {
      toast.error(error);
    }
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render () {
    const { warehouses, message, error } =this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("client-general.warehouse-list")}</h3>
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder={this.props.t("globals.search-input")}
                  onChange={this.onChangeSearch}
                />
              </div>
            </div>
            <br/>
                 { !!authUser.permissions.create ? 
            <Link to={"/warehouses/new"}
                  className="btn btn-primary"><i className="fa fa-plus"></i> {this.props.t("globals.register")}</Link> : null }
            <br/><br/>
            {this.getWarehousesList(warehouses, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  warehouses: state => state.warehouses,
  warehouse : state => state.warehouse,
  message   : state => state.message,
  error     : state => state.error
});

const mapDispatchToProps = { getWarehouses };

const condition = authUser =>
  !!authUser

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(WarehousesList));
