import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsTireBrand from "../../constants/actions/TireBrands";
import * as APIS from "../../constants/apis";

function onSubmitTireBrand(event, state, brands, history, t) {
  event.preventDefault();

  return dispatch => {
    var data = {
      ...state,
    };
    trackPromise(fetch(`${APIS.API_REST_TIREBRANDS}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          history.push("/tirebrands");
          dispatch(onReqSuccess(brands, t("alerts.b-tire-item-4")));
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  }
};

export function onReqSuccess(brands, message) {
  const json = {
    brands: brands,
    message: message
  };
  return {
    type: constantsTireBrand.REQUEST_SUCCESS,
    json
  };
};

class TireBrandNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      customer_id: localStorage.getItem('GlobalCustomerId')
    };
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name, customer_id } = this.state;
    const { brands, history } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <h3>{this.props.t("tire-brands.tire-new")}</h3>
            <br /><br />
            <form onSubmit={(event) => this.props.onSubmitTireBrand(event,
              this.state, brands, history, this.props.t)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">{this.props.t("tire-brands.tire-item-1")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-brands.tire-item-1")}
                      id="nameInput"
                      name="name"
                      value={name}
                      onChange={this.onChange}
                      required />
                    <input type="hidden"
                      name="customer_id"
                      value={customer_id} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                    className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  brands: state => state.brands,
  brand: state => state.brand
});

const mapDispatchToProps = { onSubmitTireBrand };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(TireBrandNewItem));
