export const INSPECTION_FIELDS = [
  "pos_initial",
  "pos_final",
  "fc_ultima_inspeccion",
  "vehicle_id",
  "ubicacion",
  "codigo",
  "marca_id",
  "marca",
  "modelo_id",
  "modelo",
  "dimension_id",
  "dimension",
  "nombre_banda_reencauche",
  "prof_original",
  "prof_minima",
  "ult_prof_centro_exterior",
  "ult_prof_centro",
  "ult_prof_interior",
  "ult_presion",
  "vida",
  "kilometers",
  "hours",
  "posicion_montaje",
  "diseno",
  "analista",
  "order",
  "tecnico",
  "precio_reencauche",
  "precio_regrabado",
  "tipo_trabajo",
  "precio_catalogo",
  "sin_uso",
  "costo",
];

export const TYPES = [
  {
    value: "descarte",
    name: "discard",
  },
  {
    value: "reencauche",
    name: "retreading",
  },
  {
    value: "regrabado",
    name: "rerecorded",
  },
];

export const TYPES2 = [
  {
    value: "desmonte",
    name: "clearance",
  },
  {
    value: "montaje",
    name: "mounting",
  },
  {
    value: "inspeccion",
    name: "inspection",
  },
  {
    value: "vehicle_rotation",
    name: "vehicle_rotation",
  },
  {
    value: "rin_rotation",
    name: "rin_rotation",
  },
  {
    value: "preasure_inspection",
    name: "preasure_inspection",
  },
];

export const TYPES22 = [
  {
    value: "desmonte",
    name: "clearance",
  },
  {
    value: "montaje",
    name: "mounting",
  },
  {
    value: "inspeccion",
    name: "inspection",
  },
];

export const TYPES3 = [
  {
    value: "desmonte",
    name: "clearance",
  },
  {
    value: "montaje",
    name: "mounting",
  },
  {
    value: "inspeccion",
    name: "inspection",
  },
  {
    value: "reencauche",
    name: "retreading",
  },
  {
    value: "regrabado",
    name: "rerecorded",
  },
];

export const TYPES3OTHER = [
  {
    value: "desmonte",
    name: "clearance",
  },
  {
    value: "montaje",
    name: "mounting",
  },
  {
    value: "reencauche",
    name: "retreading",
  },
  {
    value: "regrabado",
    name: "rerecorded",
  },
];

export const TYPES4 = [
  {
    value: "Balanceo",
    name: "balance",
  },
  {
    value: "Despinche",
    name: "despinche",
  },
  {
    value: "Rotación",
    name: "rotation",
  },
];

export const vehicleWorkTypes = [
  {
    value: "alineacion",
    name: "alignment",
  },
  {
    value: "torqueo",
    name: "torque",
  },
  {
    value: "montaje / desmontaje",
    name: "assembly&disassembly",
  },
  {
    value: "calibración",
    name: "calibration",
  },
  {
    value: "rotaciónes",
    name: "rotations",
  },
  {
    value: "girada de rim",
    name: "rim-spin",
  },
  {
    value: "cambio de neumáticos",
    name: "tire-change",
  },
  {
    value: "alineación / balanceo",
    name: "alignment&balance",
  },
];

export const tireWorkTypes = [
  {
    value: "balanceo",
    name: "balance",
  },
  {
    value: "despinche",
    name: "despinche",
  },
  {
    value: "reparacion mayor",
    name: "major-repair",
  },
  {
    value: "aplicación de válvula",
    name: "valve-application",
  },
  {
    value: "aplicación de extensión",
    name: "extension-application",
  },
];

export const WORK_TYPES = [
  {
    value: "balanceo",
    name: "balance",
  },
  {
    value: "despinche",
    name: "despinche",
  },
  {
    value: "reparacion mayor",
    name: "major-repair",
  },
  {
    value: "aplicación de válvula",
    name: "valve-application",
  },
  {
    value: "aplicación de extensión",
    name: "extension-application",
  },
  {
    value: "alineacion",
    name: "alignment",
  },
  {
    value: "torqueo",
    name: "torque",
  },
  {
    value: "montaje / desmontaje",
    name: "assembly&disassembly",
  },
  {
    value: "calibración",
    name: "calibration",
  },
  {
    value: "rotaciónes",
    name: "rotations",
  },
  {
    value: "girada de rim",
    name: "rim-spin",
  },
  {
    value: "cambio de neumáticos",
    name: "tire-change",
  },
  {
    value: "bodegas",
    name: "warehouses",
  },
  {
    value: "alineación / balanceo",
    name: "alignment&balance",
  },
];

export const TYPES5 = [
  {
    value: "Balanceo",
    name: "balance",
  },
  {
    value: "Despinche",
    name: "despinche",
  },
  {
    value: "Reparacion Mayor",
    name: "major-repair",
  },
  {
    value: "Aplicación de válvula",
    name: "valve-application",
  },
  {
    value: "Aplicación de extensión",
    name: "extension-application",
  },
  // {
  //   name: "Rotacion"
  // },
];

export const UNMOUNT_OBSERVATIONS = [
  {
    value: "Descarte",
    name: "discard_1",
  },
  {
    value: "Rotación",
    name: "rotation",
  },
  {
    value: "Despinche",
    name: "despinche",
  },
  {
    value: "Regrabado",
    name: "re-recorded",
  },
  {
    value: "Bodega",
    name: "winery",
  },
  {
    value: "Recambio",
    name: "replacement",
  },
  {
    value: "Transito de reencauche",
    name: "transit_of_retreading",
  },
];

export const CLIENT_TYPE = [
  "Starter",
  "Small Business",
  "Business Account",
  "Key Account",
  "Enterprise",
];

export const COUNTRIES = [
  "Argentina",
  "Bolivia",
  "Brasil",
  "Chile",
  "Colombia",
  "Ecuador",
  "Guatemala",
  "Honduras",
  "México",
  "Panamá",
  "Perú",
  "Rep. Dominicana",
  "Uruguay",
];

export const CLIENT_PRODUCT = [
  "Demo 2 meses",
  "Demo 14 dias",
  "Pruebas internas",
  "Implementación",
  "SAAS",
  "PRO",
  "PRO +",
];

export const PRODUCT_STATE = [
  "Demo 2 meses",
  "Demo 14 dias",
  "En implementación",
  "Implementado",
  "Pendiente de inicio",
];

export const KEYS_MASSIVES = [
  {
    id: "tire_massive",
    value: "Llantas",
  },
  {
    id: "vehicle_massive",
    value: "Vehiculos",
  },
  {
    id: "inspection_massive",
    value: "Inspecciones",
  },
  {
    id: "km_massive",
    value: "Kms / Hrs",
  },
];

export const GRAPH_COLORS = [
  "#509ee3",
  "#ef9090",
  "#f9d45c",
  "#ef8c8c",
  "#88bf4d",
  "#7172ad",
  "#a989c5",
  "#f2a86f",
  "#bec589",
  "#554dbf",
  "#808080",
  "#bf4d4f",
  "#b5f95c",
  "#a1f0d3",
  "#f95c67",
];

export const allFleetsCemexIds = [
  262, 130, 76, 256, 153, 156, 159, 268, 152, 239, 240, 238, 218, 74, 155, 77,
  253, 149, 90, 129, 184, 187, 208, 94, 206, 198, 196, 210, 185,
];

const allFloatsPanamericano = [
  "panamericano san luis potosi",
  "panamericano zapopan",
  "panamericano veracruz",
  "panamericano morelia",
  "panamericano chihuahua",
  "panamericano queretaro",
  "panamericano monterrey",
  "panamericano leon",
  "panamericano pachuca",
  "panamericano culiacan",
  "panamericano tlalnepantla",
  "panamericano puebla",
  "panamericano oaxaca",
  "panamericano villahermosa",
];

const allFloatsCemex = [
  "cemex concretos cancun",
  "cemex concretos monterrey",
  "cemex concretos jalisco",
  "cemex concretos baja california norte",
  "cemex concretos ciudad de méxico",
  "cemex concretos istmo",
  "cemex concretos nayarit",
  "cemex concretos san luis de potosi",
  "cemex concretos colima",
  "cemex concretos bajio",
  "cemex concretos queretaro",
  "cemex concretos yuc/cam",
  "cemex concretos pue/oax",
  "cemex concretos guerrero",
  "cemex concretos veracruz",
  "cemex concretos sinaloa",
  "cemex concretos sonora",
  "cemex concretos baja california sur",
  "cemex concretos coahuila norte",
  "cemex concretos coahuila sur",
  "cemex concretos tamaulipas norte",
  "cemex concretos tamaulipas sur",
];

export const rotationsEmails = [
  "r.hernandez@ruedata.com",
  "wilsonluiz@cutrale.com.br",
  "patio.limeira@cutrale.com.br",
  "agnaldoc@cutrale.com.br",
  "a.rivera@ruedata.com",
  "jorge@ruedata.com",
  "a.romero@ruedata.com",
  "y.bonilla@ruedata.com",
  "y.duque@ruedata.com",
  "w.herrera@ruedata.com",
  "a.roberto@ruedata.com",
  "n.velandia@ruedata.com",
  "r.hernandez@ruedata.com",
  "jonnathan.garcia@greenmovil.com.co",
  "carlos.ramirez@greenmovil.com.co",
];

export const managerEmails = [
  "Adriana Rodriguez",
  "Carlos Salvador Rodriguez",
  "Isaura Alvarez",
  "Oscar Niño",
  "Reynold Hernandez",
  "Johanna Rodriguez",
  "Daniela Ovalle",
];

export const MACRO_ACCOUNTS_CEMEX_PERMISSIONS = {
  users: {
    jefeMantenimiento: [
      {
        email: "enriqueignacio.mercado@cemex.com",
        permissions: ["region", "plaza"],
        assigned: ["centro"],
        name: "Cemex Zonal Centro",
        flots: [
          "cemex concretos ciudad de méxico",
          "cemex concretos queretaro",
          "cemex concretos guerrero",
        ],
        clientIds: [94, 187, 205],
      },
      {
        email: "jefe_mto_centro@ruedata.com",
        permissions: ["region", "plaza"],
        assigned: ["centro"],
        name: "Cemex Zonal Centro",
        flots: [
          "cemex concretos ciudad de méxico",
          "cemex concretos queretaro",
          "cemex concretos guerrero",
        ],
        clientIds: [94, 187, 205],
      },
      {
        email: "hugo.aguila@cemex.com",
        permissions: ["region", "plaza"],
        assigned: ["noreste"],
        name: "Cemex Zonal Noreste",
        flots: [
          "cemex concretos monterrey",
          "cemex concretos san luis de potosi",
          "cemex concretos bajio",
          "cemex concretos coahuila norte",
          "cemex concretos coahuila sur",
          "cemex concretos tamaulipas norte",
          "cemex concretos tamaulipas sur",
        ],
        clientIds: [130, 159, 185, 210, 253, 256, 268],
      },
      {
        email: "luisfernando.gaytan@cemex.com",
        permissions: ["region", "plaza"],
        assigned: ["noreste"],
        name: "Cemex Zonal Noreste",
        flots: [
          "cemex concretos monterrey",
          "cemex concretos san luis de potosi",
          "cemex concretos bajio",
          "cemex concretos coahuila norte",
          "cemex concretos coahuila sur",
          "cemex concretos tamaulipas norte",
          "cemex concretos tamaulipas sur",
        ],
        clientIds: [130, 159, 185, 210, 253, 256, 268],
      },
      {
        email: "hugo@cemex.com",
        permissions: ["region", "plaza"],
        assigned: ["noreste"],
        name: "Cemex Zonal Noreste",
        flots: [
          "cemex concretos monterrey",
          "cemex concretos san luis de potosi",
          "cemex concretos bajio",
          "cemex concretos coahuila norte",
          "cemex concretos coahuila sur",
          "cemex concretos tamaulipas norte",
          "cemex concretos tamaulipas sur",
        ],
        clientIds: [130, 159, 185, 210, 253, 256, 268],
      },
      {
        email: "jefe_mto_noreste@ruedata.com",
        permissions: ["region", "plaza"],
        assigned: ["noreste"],
        name: "Cemex Zonal Noreste",
        flots: [
          "cemex concretos monterrey",
          "cemex concretos san luis de potosi",
          "cemex concretos bajio",
          "cemex concretos coahuila norte",
          "cemex concretos coahuila sur",
          "cemex concretos tamaulipas norte",
          "cemex concretos tamaulipas sur",
        ],
        clientIds: [130, 159, 185, 210, 253, 256, 268],
      },
      {
        email: "emmanuel.reyes@cemex.com",
        permissions: ["region", "plaza"],
        assigned: ["noreste"],
        name: "Cemex Zonal Noreste",
        flots: [
          "cemex concretos monterrey",
          "cemex concretos san luis de potosi",
          "cemex concretos bajio",
          "cemex concretos coahuila norte",
          "cemex concretos coahuila sur",
          "cemex concretos tamaulipas norte",
          "cemex concretos tamaulipas sur",
        ],
        clientIds: [130, 159, 185, 210, 253, 256, 268],
      },
      {
        email: "luisfernando.gaytan@cemex.com",
        permissions: ["region", "plaza"],
        assigned: ["noreste"],
        name: "Cemex Zonal Noreste",
        flots: [
          "cemex concretos monterrey",
          "cemex concretos san luis de potosi",
          "cemex concretos bajio",
          "cemex concretos coahuila norte",
          "cemex concretos coahuila sur",
          "cemex concretos tamaulipas norte",
          "cemex concretos tamaulipas sur",
        ],
        clientIds: [130, 159, 185, 210, 253, 256, 268],
      },
      {
        email: "julioalonso.preciado@cemex.com",
        permissions: ["region", "plaza"],
        assigned: ["pacifico"],
        name: "Cemex Zonal Pacifico",
        flots: [
          "cemex concretos jalisco",
          "cemex concretos baja california norte",
          "cemex concretos nayarit",
          "cemex concretos colima",
          "cemex concretos sinaloa",
          "cemex concretos sonora",
          "cemex concretos baja california sur",
        ],
        clientIds: [90, 152, 153, 184, 238, 239, 240],
      },
      {
        email: "jefe_mto_pacifico@ruedata.com",
        permissions: ["region", "plaza"],
        assigned: ["pacifico"],
        name: "Cemex Zonal Pacifico",
        flots: [
          "cemex concretos jalisco",
          "cemex concretos baja california norte",
          "cemex concretos nayarit",
          "cemex concretos colima",
          "cemex concretos sinaloa",
          "cemex concretos sonora",
          "cemex concretos baja california sur",
        ],
        clientIds: [90, 152, 153, 184, 238, 239, 240],
      },
      {
        email: "ramiro.ruiz@cemex.com",
        permissions: ["region", "plaza"],
        assigned: ["sureste"],
        name: "Cemex Zonal Sureste",
        flots: [
          "cemex concretos cancun",
          "cemex concretos istmo",
          "cemex concretos yuc/cam",
          "cemex concretos pue/oax",
          "cemex concretos veracruz",
        ],
        clientIds: [129, 149, 196, 208, 218],
      },
      {
        email: "jefe_mto_sureste@ruedata.com",
        permissions: ["region", "plaza"],
        assigned: ["sureste"],
        name: "Cemex Zonal Sureste",
        flots: [
          "cemex concretos cancun",
          "cemex concretos istmo",
          "cemex concretos yuc/cam",
          "cemex concretos pue/oax",
          "cemex concretos veracruz",
        ],
        clientIds: [129, 149, 196, 208, 218],
      },
      {
        email: "miguel.sanchez@panamericano.mx",
        permissions: ["region", "plaza"],
        assigned: ["norte"],
        name: "Panamericano Zonal Norte",
        flots: [
          "panamericano san luis potosi",
          "panamericano chihuahua",
          "panamericano monterrey",
        ],
        clientIds: [204, 222, 225],
      },
      {
        email: "panamericano_norte@ruedata.com",
        permissions: ["region", "zona"],
        assigned: ["norte"],
        name: "Panamericano Global",
        flots: [
          "panamericano san luis potosi",
          "panamericano chihuahua",
          "panamericano monterrey",
        ],
        clientIds: [204, 222, 225],
      },
      {
        email: "luis.cortesp@panamericano.mx",
        permissions: ["region", "zona"],
        assigned: ["sur"],
        name: "Panamericano Global",
        flots: [
          "panamericano veracruz",
          "panamericano oaxaca",
          "panamericano villahermosa",
        ],
        clientIds: [217, 232, 233],
      },
      {
        email: "panamericano_sur@ruedata.com",
        permissions: ["region", "zona"],
        assigned: ["sur"],
        name: "Panamericano Global",
        flots: [
          "panamericano veracruz",
          "panamericano oaxaca",
          "panamericano villahermosa",
        ],
        clientIds: [217, 232, 233],
      },
      {
        email: "arnoldo.espinozatap@brinks.com",
        permissions: ["region", "zona"],
        assigned: ["noreste"],
        name: "Panamericano Zonal Noreste",
        flots: ["panamericano culiacan"],
        clientIds: [213],
      },
      {
        email: "panamericano_noreste@ruedata.com",
        permissions: ["region", "zona"],
        assigned: ["noreste"],
        name: "Panamericano Zonal Noreste",
        flots: ["panamericano culiacan"],
        clientIds: [213],
      },
      {
        email: "roberto.ortega@panamericano.mx",
        permissions: ["region", "plaza"],
        assigned: ["bajio"],
        name: "Panamericano Zonal Bajio",
        flots: [
          "panamericano zapopan",
          "panamericano leon",
          "panamericano morelia",
          "panamericano queretaro",
        ],
        clientIds: [207, 224, 214, 216],
      },
      {
        email: "panamericano_bajio@ruedata.com",
        permissions: ["region", "zona"],
        assigned: ["bajio"],
        name: "Panamericano Zonal Bajio",
        flots: [
          "panamericano zapopan",
          "panamericano leon",
          "panamericano morelia",
          "panamericano queretaro",
        ],
        clientIds: [207, 224, 214, 216],
      },
    ],
    gerente: [
      {
        email: "gerardo.delatorre@cemex.com",
        permissions: ["region", "zona"],
        assigned: ["all"],
        name: "Cemex Global",
        flots: allFloatsCemex,
        clientIds: [
          90, 94, 129, 130, 152, 149, 153, 184, 185, 187, 196, 208, 218, 238,
          239, 240, 210, 253, 256, 268,
        ],
      },
      {
        email: "s.baquero69@gmail.com",
        permissions: ["region", "zona"],
        assigned: ["all"],
        name: "Cemex Global",
        flots: allFloatsCemex,
        clientIds: [
          90, 94, 129, 130, 152, 149, 153, 184, 185, 187, 196, 208, 218, 238,
          239, 240, 210, 253, 256, 268,
        ],
      },
      {
        email: "davidfernando.esquivel@cemex.com",
        permissions: ["region", "zona"],
        assigned: ["all"],
        name: "Cemex Global",
        flots: allFloatsCemex,
        clientIds: [
          90, 94, 129, 130, 152, 149, 153, 184, 185, 187, 196, 208, 218, 238,
          239, 240, 210, 253, 256, 268,
        ],
      },
      {
        email: "harold.prietou@panamericano.mx",
        permissions: ["region", "zona"],
        assigned: ["all"],
        name: "Panamericano Global",
        flots: allFloatsPanamericano,
        clientIds: [
          204, 207, 213, 214, 215, 216, 217, 222, 225, 224, 232, 233, 234, 235,
        ],
      },
      {
        email: "panamericano@ruedata.com",
        permissions: ["region", "zona"],
        assigned: ["all"],
        name: "Panamericano Global",
        flots: allFloatsPanamericano,
        clientIds: [
          204, 207, 213, 214, 215, 216, 217, 222, 225, 224, 232, 233, 234, 235,
        ],
      },
      {
        email: "luis.barrancos@panamericano.mx",
        permissions: ["region", "zona"],
        assigned: ["all"],
        name: "Panamericano Global",
        flots: allFloatsPanamericano,
        clientIds: [
          204, 207, 213, 214, 215, 216, 217, 222, 225, 224, 232, 233, 234, 235,
        ],
      },
      {
        email: "alberto.amadorc@panamericano.mx",
        permissions: ["region", "zona"],
        assigned: ["all"],
        name: "Panamericano Global",
        flots: allFloatsPanamericano,
        clientIds: [
          204, 207, 213, 214, 215, 216, 217, 222, 225, 224, 232, 233, 234, 235,
        ],
      },
      {
        email: "alfredo.roa@panamericano.mx",
        permissions: ["region", "zona"],
        assigned: ["metro"],
        name: "Panamericano Global",
        flots: [
          "panamericano tlalnepantla",
          "panamericano pachuca",
          "panamericano puebla",
        ],
        clientIds: [235, 215, 234],
      },
      {
        email: "panamericano_metro@ruedata.com",
        permissions: ["region", "zona"],
        assigned: ["metro"],
        name: "Panamericano Global",
        flots: [
          "panamericano tlalnepantla",
          "panamericano pachuca",
          "panamericano puebla",
        ],
        clientIds: [235, 215, 234],
      },
    ],
  },
  jefeEmails: [
    "ramiro.ruiz@cemex.com",
    "enriqueignacio.mercado@cemex.com",
    "jefe_mto_sureste@ruedata.com",
    "jefe_mto_centro@ruedata.com",
    "jefe_mto_pacifico@ruedata.com",
    "jefe_mto_noreste@ruedata.com",
    "miguel.sanchez@panamericano.mx",
    "roberto.ortega@panamericano.mx",
    "panamericano_norte@ruedata.com",
    "panamericano_bajio@ruedata.com",
    "hugo.aguila@cemex.com",
    "panamericano_sur@ruedata.com",
    "luis.barrancos@panamericano.mx",
    "alberto.amadorc@panamericano.mx",
    "panamericano_centro@ruedata.com",
    "luisfernando.gaytan@cemex.com",
    "hugo.aguila@cemex.com",
    "hugo@cemex.com",
    "emmanuel.reyes@cemex.com",
    "arnoldo.espinozatap@brinks.com",
    "luis.cortesp@panamericano.mx",
    "julioalonso.preciado@cemex.com",
  ],
  gerenteEmails: [
    "gerardo.delatorre@cemex.com",
    "s.baquero69@gmail.com",
    "harold.prietou@panamericano.mx",
    "panamericano@ruedata.com",
    "soporte@ruedata.com",
    "luis.barrancos@panamericano.mx",
    "alberto.amadorc@panamericano.mx",
    "alfredo.roa@panamericano.mx",
    "panamericano_metro@ruedata.com",
    "davidfernando.esquivel@cemex.com",
  ],
  allIds: [
    90, 94, 129, 130, 152, 149, 153, 184, 185, 187, 196, 208, 204, 210, 207,
    213, 214, 215, 216, 217, 218, 222, 225, 224, 232, 233, 234, 238, 239, 240,
    253, 256, 268,
  ],
};

export const MACRO_ACCOUNTS = [
  // [
  //   ["gerardo.delatorre@cemex.com", "davidfernando.esquivel@cemex.com"],
  //   ["cemex concretos cancun", "cemex concretos monterrey", "cemex concretos guadalajara", "cemex concretos baja california norte", "cemex concretos ciudad de mexico", "cemex concretos istmo", "cemex concretos nayarit"],
  //   [90, 94, 129, 130, 152, 149, 153],
  //   "Cemex Global"
  // ],
  [
    ["agarnica@somos.co"],
    ["git masivo", "somos u"],
    [42, 122],
    "Sumilogistica",
  ],
  [
    [
      "e.frausto@lidcorp.mx",
      "m.garbajosa@lidcorp.mx",
      "o.rodriguez@lidcorp.mx",
      "lipu@gmail.com",
      "diego.delille@traffilogla.com",
    ],
    ["lipu", "settepi"],
    [99, 133],
    "Lipu Global",
  ],
  [
    [
      "v.fuentes@traxion.global",
      "g.rodriguez@traxion.global",
      "traxion@gmail.com",
    ],
    ["transportes suvi", "afn autoexpress frontera norte"],
    [109, 118],
    "TRAXION",
  ],
  [
    ["franciscorosendo.mendezm@cemex.com", "cemexrd@ruedata.com"],
    ["cemex suministros rd", "cemex sc concretos rd"],
    [155, 156],
    "Cemex RD",
  ],
  [
    [
      "gmovil@ruedata.com",
      "josel.pinilla@gmovilsas.com.co",
      "alfredorr284@hotmail.com",
    ],
    ["gmóvil patio y", "gmóvil patio troncal"],
    [82, 169],
    "Gmóvil Global",
  ],
  // [
  //   ["harold.prietou@panamericano.mx","panamericano@ruedata.com", "fernando.ardura@traffilogla.com", "gerardo.garcia@traffilogla.com", "soporte@ruedata.com"],
  //   ["panamericano san luis de potosi", "panamericano zapopan"],
  //   [204, 207],
  //   "Panamericano Global"
  // ]
  [
    [
      "coord.transportes@bydsa.com",
      "analistatpts@bydsa.com",
      "bydsa@ruedata.com",
      "jescobar@bydsa.com",
      "alejandro.trevino@bydsa.com",
    ],
    ["bydsa guadalupe", "bydsa torreón"],
    [226, 220],
    "BYDSA",
  ],
  [
    ["pacasmayo@ruedata.com"],
    ["pacasmayo trujillo", "pacasmayo piura"],
    [229, 230],
    "Pacasmayo Concreto",
  ],
  [
    ["estrella_roja@ruedata.com"],
    ["estrella roja carga", "estrella roja pasajeros"],
    [92, 88],
    "Estrella Roja Global",
  ],
];
