import moment from "moment";
import "moment/locale/es";
import Q from "q";
import React, { Component } from "react";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { trackPromise } from "react-promise-tracker";
import Novelties from "../Novelty";
import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";
import * as VARIABLES from "../../../constants/variables";
import axios from "axios";

const $ = require("jquery");
const unmountObsList = VARIABLES.UNMOUNT_OBSERVATIONS;
const types4 = VARIABLES.TYPES4;
const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const getSuggestionValue = (suggestion) => {
  return suggestion;
};

const renderSuggestion = (suggestion) => {
  if (!!suggestion.name) {
    return <div>{suggestion.name}</div>;
  }
  if (!!suggestion.codigo) {
    return <div>{suggestion.codigo}</div>;
  }
};

let getInitialState = (props) => {
  let initialState = {
    inspection: {},
    inspectionClone: {},
    bandsSuggestions: [],
    tiresSuggestions: [],
    tiresList: [],
    rotationsSelect: [],
    isPosFinal: false,
    bands: [],
    ubicacion: "",
  };

  let fields = props.fields;
  let object = props.object;
  let km = props.km;
  let hour = props.hour;
  let order = props.order;
  let user = props.authUser;
  initialState.inspection["tire_id"] = object.id;

  for (let i = 0; i < fields.length; i++) {
    if (fields[i] === "vehicle_id") {
      if (props.type === "desmonte") {
        initialState.inspection[fields[i]] = object.vehicle_id;
      } else {
        initialState.inspection[fields[i]] = object.vehicle_id;
      }
    } else if (fields[i] === "ubicacion") {
      if (props.type === "desmonte") {
        initialState.inspection[fields[i]] = "";
      }
    } else if (fields[i] === "vida") {
      if (props.type === "reencauche") {
        if (!isNaN(object.vida)) {
          initialState.inspection[fields[i]] = 1 * object.vida + 1;
        } else {
          initialState.inspection[fields[i]] = object.vida;
        }
      } else {
        initialState.inspection[fields[i]] = object.vida;
      }
    } else if (fields[i] === "tecnico") {
      initialState.inspection[fields[i]] = props.technician;
    } else if (fields[i] === "order") {
      initialState.inspection[fields[i]] = order;
    } else if (fields[i] === "fc_ultima_inspeccion") {
      initialState.inspection[fields[i]] = props.date;
    } else if (fields[i] === "ult_prof_centro") {
      initialState.inspectionClone[fields[i]] = object.ult_prof_centro;
    } else if (fields[i] === "ult_prof_centro_exterior") {
      initialState.inspectionClone[fields[i]] = object.ult_prof_centro_exterior;
    } else if (fields[i] === "ult_prof_interior") {
      initialState.inspectionClone[fields[i]] = object.ult_prof_interior;
    } else if (fields[i] === "ult_presion") {
      initialState.inspectionClone[fields[i]] = object.ult_presion;
    } else if (fields[i] === "kilometers") {
      initialState.inspection[fields[i]] = km;
    } else if (fields[i] === "hours") {
      initialState.inspection[fields[i]] = hour;
    } else if (fields[i] === "tipo_trabajo") {
      initialState.inspection[fields[i]] = object.tipo_trabajo;
    } else if (fields[i] === "costo") {
      initialState.inspection[fields[i]] = object.costo;
    } else if (fields[i] === "posicion_montaje") {
      initialState.inspection[fields[i]] = object.posicion_montaje;
    } else if (fields[i] === "codigo") {
      initialState.inspection[fields[i]] = object.codigo;
    } else if (fields[i] === "analista") {
      initialState.inspection[fields[i]] = user.email;
    } else {
      initialState.inspection[fields[i]] = "";
    }
  }

  for (let i = 0; i < fields.length; i++) {
    if (initialState.inspection[fields[i]] == null) {
      initialState.inspection[fields[i]] = "";
    }
  }

  initialState.inspection.type = props.type;

  if (props.type === "reencauche") {
    initialState.inspection.retreadbrand_id = "";
    initialState.inspection.retreadband_id = "";
    initialState.inspection.retreadimention_id = "";
  }

  if (props.type === "vehicle_rotation" || props.type === "rin_rotation") {
    initialState.inspection["pos_initial"] = object.posicion_montaje;
  }
  return initialState;
};

class InspectionVlForm extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.novelties = React.createRef();

    this.state = { ...getInitialState(props), technicians: [] };
  }

  getWarehouses() {
    let arr = [];
    this.props.warehouses.forEach((warehouse) =>
      arr.push({ id: warehouse.id, label: warehouse.name })
    );
    let inspection = { ...this.state.inspection };
    //inspection.ubicacion = arr[0].id;
    this.setState({ inspection, bodegas: arr });
  }

  getTnc = () => {
    trackPromise(
      fetch(
        APIS.API_REST_TECHNICIANS.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ technicians: json.Users });
        })
    );
  };

  componentDidMount() {
    this._isMounted = true;
    this.getWarehouses();
    this.getTnc();
    if (document.getElementById("form_keys")) {
      document.getElementById("form_keys").addEventListener("keypress", (e) => {
        if (e.keyCode === 13 || e.which === 13) {
          e.preventDefault();
          return false;
        }
      });
    }
  }

  setBandsState = (dimension) => {
    let bands = [];
    let brands = this.state.tiresList.reencauche;
    for (let i = 0; i < brands.length; i++) {
      let designs = brands[i].retreadbands;
      for (let j = 0; j < designs.length; j++) {
        let dimensions = designs[j].retreadimentions;
        for (let k = 0; k < dimensions.length; k++) {
          if (dimension === dimensions[k].dimention) {
            bands.push(designs[j]);
          }
        }
      }
    }
    this.setState({ bands });
  };

  getFixedField = (string) => {
    if (typeof string !== "string") return "";
    /*
    let newString = string.charAt(0).toUpperCase() +
                    string.slice(1).toLowerCase();
    return newString.replace(/_/g, " ");
    */
    if (string === "obsevaciones_analista") {
      return "obs analista";
    } else {
      let arr = string.split("_");
      let arrLength = arr.length;
      return arr[arrLength - 1];
    }
  };

  getModels = (marca, marcas) => {
    for (let i = 0; i < marcas.length; i++) {
      if (marca === marcas[i].marca) {
        return marcas[i].disenos;
      }
    }
    return [];
  };

  getDimensions = (model, marca, marcas) => {
    let models = this.getModels(marca, marcas);
    if (!!model) {
      for (let i = 0; i < models.length; i++) {
        if (model === models[i].diseno) {
          return models[i].dimensiones;
        }
      }
    }
    return [];
  };

  getDimension = (dimension, model, marca, marcas) => {
    let dimensions = this.getDimensions(model, marca, marcas);
    if (!!dimension) {
      for (let i = 0; i < dimensions.length; i++) {
        if (dimension === dimensions[i].dimension) {
          return dimensions[i];
        }
      }
    }
    return null;
  };

  // ---------------Autosuggest for tire field------------------//
  onChangeTire = (event, { newValue }) => {
    let inspection = { ...this.state.inspection };
    let inspectionClone = { ...this.state.inspectionClone };

    if (typeof newValue === "object" && newValue !== null) {
      inspection.codigo = newValue.codigo;
      inspection.tire_id = newValue.id;
      inspectionClone.ult_prof_centro = newValue.ult_prof_centro;
      inspectionClone.ult_prof_centro_exterior =
        newValue.ult_prof_centro_exterior;
      inspectionClone.ult_prof_interior = newValue.ult_prof_interior;
    } else {
      let tires = this.props.tires;
      for (let i = 0; i < tires.length; i++) {
        if (
          tires[i].codigo.toLowerCase().trim() === newValue.toLowerCase().trim()
        ) {
          inspection.codigo = tires[i].codigo;
          inspection.tire_id = tires[i].id;
          inspectionClone.ult_prof_centro = tires[i].ult_prof_centro;
          inspectionClone.ult_prof_centro_exterior =
            tires[i].ult_prof_centro_exterior;
          inspectionClone.ult_prof_interior = tires[i].ult_prof_interior;
          break;
        } else {
          inspection.codigo = newValue;
          inspection.tire_id = newValue;
          inspectionClone.ult_prof_centro = "";
          inspectionClone.ult_prof_centro_exterior = "";
          inspectionClone.ult_prof_interior = "";
        }
      }
    }
    this.setState({
      inspection: inspection,
      inspectionClone: inspectionClone,
    });
  };

  getTiresSuggestions = (value) => {
    if (value.length < 3) return [];
    let tires = this.props.tires;
    const fmtSuggestions = tires.filter((tire) => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return tire.codigo.toLowerCase().search(fmtValue) >= 0;
    });
    return fmtSuggestions;
  };

  onTiresFetchRequested = ({ value }) => {
    this.setState({ tiresSuggestions: this.getTiresSuggestions(value) });
  };

  onTiresClearRequested = () => {
    this.setState({ tiresSuggestions: [] });
  };

  // ---------------Autosuggest for band field------------------//
  onChangeBand = (event, { newValue }) => {
    let inspection = { ...this.state.inspection };
    let selectedNewBand = "";

    const use = this.props.use;
    const type = this.props.type;
    const state = this.props.state;
    const dimension = !!inspection.dimension
      ? inspection.dimension
      : !!this.props.object
      ? this.props.object.dimension
      : "";
    if (typeof newValue === "object" && newValue !== null) {
      const dimensions = newValue.retreadimentions;
      for (let i = 0; i < dimensions.length; i++) {
        if (dimensions[i].dimention === dimension) {
          selectedNewBand = dimensions[i];
          if (state === "reencauchada" || type === "reencauche") {
            inspection.prof_original = dimensions[i].profundidad_original;
            inspection.prof_minima = dimensions[i].profundidad_minima;
            if (use === "nouse") {
              inspection.ult_prof_centro_exterior =
                dimensions[i].profundidad_original;
              inspection.ult_prof_centro = dimensions[i].profundidad_original;
              inspection.ult_prof_interior = dimensions[i].profundidad_original;
            }
          }
          break;
        }
      }
      inspection.retreadbrand_id = newValue.retreadbrand_id;
      inspection.retreadband_id = selectedNewBand.retreadband_id;
      inspection.retreadimention_id = selectedNewBand.id;
      inspection.nombre_banda_reencauche = newValue.name;
    } else {
      let sband = null;
      const band = newValue;
      const bands = this.state.bands;
      for (let i = 0; i < bands.length; i++) {
        if (bands[i].name === band) {
          sband = bands[i];
          const dimensions = bands[i].retreadimentions;
          for (let j = 0; j < dimensions.length; j++) {
            if (dimensions[j].dimention === dimension) {
              selectedNewBand = dimensions[j];
              if (state === "reencauchada" || type === "reencauche") {
                inspection.prof_original = dimensions[j].profundidad_original;
                inspection.prof_minima = dimensions[j].profundidad_minima;
                if (use === "nouse") {
                  inspection.ult_prof_centro_exterior =
                    dimensions[j].profundidad_original;
                  inspection.ult_prof_centro =
                    dimensions[j].profundidad_original;
                  inspection.ult_prof_interior =
                    dimensions[j].profundidad_original;
                  inspection.Precio_vida_actual = dimensions[j].precio;
                }
              }
              break;
            }
          }
          break;
        }
      }
      inspection.retreadbrand_id = sband.retreadbrand_id;
      inspection.retreadband_id = selectedNewBand.retreadband_id;
      inspection.retreadimention_id = selectedNewBand.id;
      inspection.nombre_banda_reencauche = newValue;
    }
    this.setState({ inspection });
  };

  getBandsSuggestions = (value) => {
    let bands = this.state.bands;
    return bands.filter((band) => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return band.name.toLowerCase().search(fmtValue) >= 0;
    });
  };

  onBandsFetchRequested = ({ value }) => {
    this.setState({ bandsSuggestions: this.getBandsSuggestions(value) });
  };

  onBandsClearRequested = () => {
    this.setState({ bandsSuggestions: [] });
  };

  getIrregularDepths = () => {
    let irrDepths = {
      max_name: "",
      max_value: -Infinity,
      min_name: "",
      min_value: +Infinity,
    };

    let count = 0;
    let areValidDepths = true;
    const depths = [
      "ult_prof_centro_exterior",
      "ult_prof_interior",
      "ult_prof_centro",
    ];
    for (let i = 0; i < depths.length; i++) {
      if (
        isNaN(this.state.inspection[depths[i]]) ||
        !this.state.inspection[depths[i]]
      ) {
        if (++count > 1) {
          areValidDepths = false;
        }
      }
    }

    if (areValidDepths) {
      for (let i = 0; i < depths.length; i++) {
        if (
          !!this.state.inspection[depths[i]] &&
          1 * this.state.inspection[depths[i]] > irrDepths.max_value
        ) {
          irrDepths.max_value = 1 * this.state.inspection[depths[i]];
          irrDepths.max_name = depths[i];
        }
        if (
          !!this.state.inspection[depths[i]] &&
          1 * this.state.inspection[depths[i]] < irrDepths.min_value
        ) {
          irrDepths.min_value = 1 * this.state.inspection[depths[i]];
          irrDepths.min_name = depths[i];
        }
      }
      if (irrDepths.max_value - irrDepths.min_value < 2.5) {
        return null;
      }
    } else {
      return null;
    }

    return irrDepths;
  };

  getClassValidDepths = (type, field, previousValue, currentValue) => {
    const depths = [
      "ult_prof_centro_exterior",
      "ult_prof_interior",
      "ult_prof_centro",
    ];
    if (depths.indexOf(field) > -1) {
      if (
        (!!currentValue || currentValue === 0) &&
        type !== "reencauche" &&
        previousValue < currentValue
      ) {
        return "form-control width-150 border border-danger";
      }
      if (
        (!!currentValue || currentValue === 0) &&
        type === "reencauche" &&
        previousValue > currentValue
      ) {
        return "form-control width-150 border border-danger";
      }
      const irrDepths = this.getIrregularDepths();
      if (!!irrDepths) {
        if (irrDepths.max_name === field || irrDepths.min_name === field) {
          return "form-control width-150 border border-warning";
        }
      }
    }
    return "form-control width-150";
  };

  getIptionsRotations = (data) => {
    let arr = [];
    const response = this.props.positionsRow;
    let subArr = this.props.getAllByVehiclePositions();
    for (let i = 0; i < response.length; i++) {
      if (subArr.includes(response[i].position)) {
        arr.push(response[i]);
      }
    }
    this.setState({ rotationsSelect: arr });
  };

  // DOM Fields
  getFieldDOM = (field, required, readonly) => {
    const tireProps = {
      placeholder: this.props.t("alerts.ins-one-item-30"),
      value: this.state.inspection[field],
      className: "form-control width-150",
      onChange: this.onChangeTire,
    };

    const bandProps = {
      placeholder: this.props.t("alerts.ins-one-item-31"),
      value: this.state.inspection[field],
      className: "form-control width-150",
      onChange: this.onChangeBand,
    };

    if (field === "codigo") {
      return (
        <Autosuggest
          suggestions={this.state.tiresSuggestions}
          onSuggestionsFetchRequested={this.onTiresFetchRequested}
          onSuggestionsClearRequested={this.onTiresClearRequested}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={tireProps}
        />
      );
    } else if (field === "nombre_banda_reencauche") {
      return (
        <Autosuggest
          suggestions={this.state.bandsSuggestions}
          onSuggestionsFetchRequested={this.onBandsFetchRequested}
          onSuggestionsClearRequested={this.onBandsClearRequested}
          shouldRenderSuggestions={() => true}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={bandProps}
        />
      );
    } else if (
      field === "marca_id" &&
      (this.props.type === "Registrar" || this.props.type === "reencauche")
    ) {
      return (
        <select
          className="form-control width-150"
          id={field}
          name={field}
          value={this.state.inspection[field]}
          onChange={this.onChange}
          required={required}
        >
          <option value="">{this.props.t("ins-one.ins-one-vl-form-1")}</option>
          {this.state.tiresList[this.props.type].marcas.map((marca) => {
            return (
              <option key={marca.marca} value={marca.marca}>
                {marca.marca}
              </option>
            );
          })}
        </select>
      );
    } else if (
      field === "pos_final" &&
      this.props.type === "vehicle_rotation"
    ) {
      return (
        <select
          className="form-control width-70 inspectionVLSelect"
          name={field}
          value={this.state.inspection[field]}
          onChange={this.onChange}
          onClick={() => this.getIptionsRotations(this.props.objects)}
          required={required}
        >
          <option value={null}></option>
          {this.state.rotationsSelect.map((item) => {
            if (item.position !== this.state.inspection.posicion_montaje) {
              return (
                <option
                  key={item.position}
                  value={item.position}
                  disabled={item.selected ? true : false}
                >
                  {item.position}
                </option>
              );
            }
          })}
        </select>
      );
    } else if (field === "ubicacion" && this.props.type === "desmonte") {
      return (
        <select
          className="form-control width-150"
          id={"ubicacionVL" + this.state.inspection.posicion_montaje}
          name={field}
          value={this.state.inspection[field]}
          onChange={this.onChange}
          required={required}
        >
          <option value="">{this.props.t("ins-one.ins-one-vl-form-2")}</option>
          {!!this.state.bodegas &&
            this.state.bodegas.map((option) => {
              if (option.label !== "llanta_nueva") {
                return (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                );
              }
            })}
        </select>
      );
    } else if (
      field === "modelo_id" &&
      (this.props.type === "Registrar" || this.props.type === "reencauche")
    ) {
      let marca = this.state.inspection["marca_id"];
      let marcas = this.state.tiresList[this.props.type].marcas;

      let models = this.getModels(marca, marcas);
      return (
        <select
          className="form-control width-150"
          id={field}
          name={field}
          value={this.state.inspection[field]}
          onChange={this.onChange}
          required={required}
        >
          <option value="">{this.props.t("ins-one.ins-one-vl-form-3")}</option>
          {models.map((model) => {
            return (
              <option key={model.diseno} value={model.diseno}>
                {model.diseno}
              </option>
            );
          })}
        </select>
      );
    } else if (
      field === "dimension_id" &&
      (this.props.type === "Registrar" || this.props.type === "reencauche")
    ) {
      let marca = this.state.inspection["marca_id"];
      let model = this.state.inspection["modelo_id"];
      let marcas = this.state.tiresList[this.props.type].marcas;

      let dimensions = this.getDimensions(model, marca, marcas);
      return (
        <select
          className="form-control width-150"
          id={field}
          name={field}
          value={this.state.inspection[field]}
          onChange={this.onChange}
          required={required}
        >
          <option value="">{this.props.t("ins-one.ins-one-vl-form-4")}</option>
          {dimensions.map((dimension) => {
            return (
              <option key={dimension.dimension} value={dimension.dimension}>
                {dimension.dimension}
              </option>
            );
          })}
        </select>
      );
    } else if (field === "Observaciones" && this.props.type === "desmonte") {
      return (
        <select
          className="form-control width-150"
          id={field}
          name={field}
          value={this.state.inspection[field]}
          onChange={this.onChange}
          required={required}
        >
          <option value="">{this.props.t("ins-one.ins-one-vl-form-5")}</option>
          {unmountObsList.map((observation) => {
            return (
              <option key={observation.name} value={observation.name}>
                {observation.name}
              </option>
            );
          })}
        </select>
      );
    } else if (field === "tipo_trabajo" && this.props.type === "desmonte") {
      return (
        <select
          className="form-control width-150"
          id={field}
          name={field}
          value={this.state.inspection[field]}
          onChange={this.onChange}
          required={required}
        >
          <option value="">{this.props.t("ins-one.ins-one-vl-form-6")}</option>
          {types4.map((type) => {
            return (
              <option key={type.value} value={type.value}>
                {this.props.t(`selectors.${type.name}`)}
              </option>
            );
          })}
        </select>
      );
    } else {
      let widthPx = "";
      let type = "";
      if (
        field === "ult_prof_centro_exterior" ||
        field === "ult_prof_centro" ||
        field === "ult_prof_interior" ||
        field === "Precio_vida_actual" ||
        field === "prof_original" ||
        field === "prof_minima" ||
        field === "posicion_montaje" ||
        field === "kilometers" ||
        field === "costo"
      ) {
        type = "number";
        //type = "text";
        widthPx = "80px";
      } else {
        type = "text";
        widthPx = "120px";
      }
      if (field === "ult_presion") {
        widthPx = "60px";
      }
      let placeholder = "";
      if (
        field === "ult_prof_centro_exterior" ||
        field === "ult_prof_centro" ||
        field === "ult_prof_interior"
      ) {
        if (
          this.props.type === "montaje" ||
          this.props.type === "preasure_inspection"
        ) {
          required = false;
        }
        placeholder = this.state.inspectionClone[field];
      } else if (field === "ult_presion") {
        placeholder = this.state.inspectionClone[field];
      } else {
        placeholder = this.getFixedField(field);
      }
      if (field === "order") {
        required = false;
        placeholder = "Documento";
      }

      if (field === "pos_initial") {
        widthPx = "60px";
        placeholder = this.state.inspection.posicion_montaje;
      }
      // if (field === "pos_final" && this.props.finalPositionAfterSelectPather) {
      //   widthPx = "60px";
      //   readonly = true;
      //   placeholder = this.props.finalPositionAfterSelectPather;
      // }
      return (
        <>
          <input
            name={field}
            id={field}
            type={type}
            value={this.state.inspection[field]}
            className={this.getClassValidDepths(
              this.props.type,
              field,
              this.state.inspectionClone[field],
              this.state.inspection[field]
            )}
            onChange={this.onChange}
            style={{ width: widthPx }}
            placeholder={placeholder}
            required={required}
            readOnly={readonly}
          />
        </>
      );
    }
  };

  getDynamicForm = (object, fields) => {
    return (
      <div>
        <table className="table table-bordered">
          <tbody>
            <tr>
              {fields.map((field) => {
                let required = true;
                let readonly = false;
                let clase = "form-group";
                if (
                  field === "kilometers" ||
                  field === "posicion_montaje" ||
                  field === "fc_ultima_inspeccion" ||
                  (field === "analista") | (field === "banda_reencauche") ||
                  field === "tecnico" ||
                  (field === "codigo" && this.props.type !== "montaje") ||
                  (field === "vehicle_id" && this.props.type !== "desmonte")
                ) {
                  return null;
                }
                if (this.props.type === "regrabado" && field === "vida") {
                  return null;
                }
                if (
                  this.props.type !== "reencauche" &&
                  field === "nombre_banda_reencauche"
                ) {
                  return null;
                }
                if (
                  this.props.type === "reencauche" &&
                  (field === "prof_original" || field === "prof_minima")
                ) {
                  return null;
                }
                if (this.props.type === "desmonte" && field === "vehicle_id") {
                  return null;
                }
                if (field === "hours") {
                  return null;
                }
                if (
                  (this.props.type === "montaje" ||
                    this.props.type === "preasure_inspection") &&
                  (field === "ult_prof_centro_exterior" ||
                    field === "ult_prof_centro" ||
                    field === "ult_prof_interior")
                ) {
                  readonly = true;
                  required = false;
                }
                if (this.props.type === "desmonte") {
                  if (field === "posicion_montaje") {
                    required = false;
                  }
                }
                if (
                  field === "ult_presion" ||
                  field === "Observaciones" ||
                  field === "novedades" ||
                  field === "obsevaciones_analista"
                ) {
                  required = false;
                }
                if (
                  this.props.type !== "Registrar" &&
                  (field === "marca" ||
                    field === "modelo" ||
                    field === "dimension")
                ) {
                  return null;
                }
                let label = "";
                let newField = this.getFixedField(field);
                if (
                  field === "Observaciones" &&
                  this.props.type === "desmonte"
                ) {
                  label = "Acción";
                  return null;
                } else {
                  label = newField;
                }
                if (
                  field === "tipo_trabajo" &&
                  this.props.type === "desmonte"
                ) {
                  clase = "form-group hidden trabajo";
                  required = false;
                }
                if (field === "ult_presion") {
                  label = this.props.t("ins-one.ins-one-65");
                }
                if (field === "costo" && this.props.type === "desmonte") {
                  clase = "form-group hidden trabajo";
                  required = false;
                }
                if (field === "order") {
                  required = false;
                  label = this.props.t("ins-one.ins-one-66");
                }
                if (
                  field === "pos_final" &&
                  this.props.type === "vehicle_rotation"
                ) {
                  required = true;
                  label = "Pos Final";
                }
                if (
                  field === "pos_initial" &&
                  this.props.type === "vehicle_rotation"
                ) {
                  required = true;
                  label = "Pos Inicial";
                  readonly = true;
                }
                if (field === "ubicacion") {
                  label = this.props.t("ins-one.ins-one-69");
                }
                return (
                  <td key={field} className={clase}>
                    <label htmlFor={field}>{label}</label>
                    {this.getFieldDOM(field, required, readonly)}
                  </td>
                );
              })}
              {fields.length > 0 ? (
                <td>
                  <button
                    type="button"
                    className="btn btn-primary text-white height-70"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={this.props.t("alerts.ins-one-item-32")}
                    onClick={(e) => {
                      if (
                        this.state.inspection.type === "desmonte" &&
                        this.state.ubicacion === "trabajo" &&
                        this.state.inspection.tipo_trabajo === ""
                      ) {
                        toast.error("Por favor selecciona un tipo de trabajo");
                      } else {
                        $("#posicionNovelties" + object.posicion_montaje).modal(
                          "show"
                        );
                        $(
                          "#noveltyTypePos" +
                            object.posicion_montaje +
                            " option[value=Desecho]"
                        ).hide();
                        $("#DOT" + object.posicion_montaje).hide();
                        $(
                          "#noveltyTypePos" +
                            object.posicion_montaje +
                            " option[value=Novedad]"
                        ).show();
                        $(
                          "#noveltyTypePos" +
                            object.posicion_montaje +
                            " option[value=Antecedente]"
                        ).show();
                        document.getElementById(
                          "noveltyTypePos" + object.posicion_montaje
                        ).value = "Novedad";
                        let state = this.novelties.current.state;
                        state.noveltyType = "Novedad";
                      }
                    }}
                  >
                    <i className="fa fa-bullhorn"></i>
                  </button>
                </td>
              ) : null}
              {fields.length > 0 ? (
                <td>
                  <Novelties
                    t={this.props.t}
                    ref={this.novelties}
                    records={this.props.records}
                    waste={this.props.waste}
                    movements={this.props.movements}
                    novelties={this.props.novelties}
                    posicion={object.posicion_montaje}
                  />
                </td>
              ) : null}
              {fields.length > 0 ? (
                <td>
                  <button
                    style={{ display: "none" }}
                    type="submit"
                    className="btn btn-primary text-white height-70"
                  >
                    <i className="fa fa-save"></i>
                  </button>
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  areValidFields = (inspection) => {
    let type = inspection.type;
    for (let attr in inspection) {
      if (
        attr === "Observaciones" ||
        attr === "banda_reencauche" ||
        attr === "novedades" ||
        attr === "obsevaciones_analista" ||
        attr === "analista" ||
        attr === "marca" ||
        attr === "modelo" ||
        attr === "dimension" ||
        attr === "kilometers" ||
        attr === "tipo_trabajo" ||
        attr === "costo" ||
        attr === "hours" ||
        attr === "vida" ||
        attr === "nombre_banda_reencauche" ||
        attr === "order" ||
        attr === "parent_rotation"
      ) {
        continue;
      }
      if (type === "desmonte") {
        if (attr === "posicion_montaje" || attr === "banda_reencauche")
          continue;
      }
      if (type === "inspeccion") {
        if (attr === "ubicacion") continue;
      }
      if (type === "montaje" || type === "preasure_inspection") {
        if (attr === "ubicacion") continue;
        if (attr === "ult_prof_centro_exterior") continue;
        if (attr === "ult_prof_centro") continue;
        if (attr === "ult_prof_interior") continue;
      }
      if (type !== "preasure_inspection" && attr === "ult_presion") {
        continue;
      }

      if (type === "desmonte") {
        if (
          attr === "ubicacion" &&
          this.state.ubicacion === "trabajo" &&
          inspection.tipo_trabajo === ""
        ) {
          return {
            isValid: false,
            field: "tipo_trabajo",
          };
        }
      }
      if (attr === "pos_final") {
        if (inspection[attr] === "" && type === "vehicle_rotation") {
          return {
            isValid: false,
            field: "Pos Final",
          };
        } else {
          continue;
        }
      }

      if (inspection[attr] == null || inspection[attr] === "") {
        return {
          isValid: false,
          field: attr,
        };
      }
    }

    return { isValid: true };
  };

  getFmtNovelties = (novelties) => {
    let fmtNovelties = [];

    for (let i = 0; i < novelties.length; i++) {
      fmtNovelties.push({
        seccion_llanta: novelties[i].seccion.split("|")[1],
        motivo: novelties[i].novedad.split("|")[1],
        ligado_a: novelties[i].novedad.split("|")[2],
        codigo_causa: novelties[i].causa.split("|")[0],
        posible_causa: novelties[i].causa.split("|")[1],
      });
    }

    return fmtNovelties;
  };

  getFmtdJsonNovelties = (data) => {
    const state = this.novelties.current.state;

    const images = state.insImages;
    const noveltyType = state.noveltyType;
    const fecha_subida = moment(state.fecha_subida).utc().format();
    const DOT = state.DOT;
    const sinister = state.sinister;
    const journey = state.journey;
    const operator = state.operator;
    const novelties = this.getFmtNovelties(state.insNovelties);

    return {
      //fecha_subida: moment().utc().format(),
      fecha_subida: fecha_subida,
      DOT: DOT,
      tiponovedad: noveltyType,
      inspector: data.tecnico,
      customer_id: this.props.customerId,
      noveltys: novelties,
      pictures: images,
      sinister: sinister,
      journey: journey,
      operator: operator,
    };
  };

  formatJson = (json) => {
    for (let key in json) {
      if (json[key] === "") {
        json[key] = null;
      }
      if (key === "ult_presion" && !isNaN(json[key]) && json[key] != null) {
        json[key] = "" + parseFloat(json[key]).toFixed(1);
      }
      if (
        json[key] != null &&
        !isNaN(json[key]) &&
        json[key].toString().indexOf(".") !== -1
      ) {
        json[key] = parseFloat(json[key]);
      }
    }
    return json;
  };

  runValidations = (json) => {
    if (!!json.message) {
      if (json.message === "Fallo La Validacion") {
        let msg = "";
        Object.keys(json.resp).forEach((key) => {
          if (!json.resp[key][1]) {
            msg = msg + json.resp[key][0] + "\n";
          }
        });
        toast.error(msg);
        throw new Error(msg);
      } else {
        toast.error(json.message);
        throw new Error(json.message);
      }
    }
  };

  getKilometerId = (km, fecha) => {
    if (!!km) {
      let kms = this.props.kms;
      for (let i = 0; i < kms.length; i++) {
        var dateLocal = moment.utc(kms[i].fecha_inspeccion).local().format();
        var date2Local = moment.utc(fecha).local().format();
        if (
          kms[i].kilometers.toString().trim() === km.toString().trim() &&
          dateLocal.trim().substring(0, 10) ===
            date2Local.trim().substring(0, 10)
        ) {
          return kms[i].id;
        }
      }
    }
    return null;
  };

  getHoursId = (hour, fecha) => {
    if (!!hour) {
      let hours = this.props.hours;
      for (let i = 0; i < hours.length; i++) {
        var dateLocal = moment.utc(hours[i].fecha_inspeccion).local().format();
        var date2Local = moment.utc(fecha).local().format();
        if (
          hours[i].hours.toString().trim() === hour.toString().trim() &&
          dateLocal.trim().substring(0, 10) ===
            date2Local.trim().substring(0, 10)
        ) {
          return hours[i].id;
        }
      }
    }
    return null;
  };

  validateCampTnc = (valueTnc) => {
    let tnc = this.state.technicians;
    const tncValue = valueTnc.toLowerCase();
    for (let i = 0; i < tnc.length; i++) {
      if (tnc[i].name.toLowerCase() === tncValue) {
        return true;
      }
    }

    return false;
  };

  setInitData = () => {
    this.props.resetState();
    this._isMounted && this.setState({ ...getInitialState(this.props) });
    toast.success(this.props.t("alerts.ins-one-item-27"));
  };

  onSubmit = (event, isSameCar, isMultiple, setInitDataInspections) => {
    event.preventDefault();
    if (isInternetConnected(toast)) return;

    let str = "?by_inspection=true";

    let deferred = Q.defer();
    this.props.firebaseUser.doGenerateToken().then(function (idToken) {
      localStorage.setItem("firebaseAuthToken", idToken);
    });
    let inspection = { ...this.state.inspection };
    if (!inspection.fc_ultima_inspeccion) {
      toast.info(this.props.t("alerts.ins-one-item-33"));
      deferred.reject(this.props.t("alerts.ins-one-item-33"));
      return deferred.promise;
    }

    if (!inspection.tecnico) {
      toast.info(this.props.t("alerts.ins-one-item-34"));
      deferred.reject(this.props.t("alerts.ins-one-item-34"));
      return deferred.promise;
    }

    if (this.validateCampTnc(inspection.tecnico) === false) {
      toast.error(this.props.t("alerts.ins-one-item-35"));
      throw new Error(this.props.t("alerts.ins-one-item-35"));
    }

    inspection.fc_ultima_inspeccion = moment(inspection.fc_ultima_inspeccion)
      .utc()
      .format();
    let data = {
      ...this.formatJson(inspection),
      customer_id: this.props.customerId,
    };
    if (!!data.ubicacion) {
      data.bodega_id = data.ubicacion;
    }
    data.km_id = this.getKilometerId(
      data.kilometers,
      data.fc_ultima_inspeccion
    );
    data.hour_id = this.getHoursId(data.hours, data.fc_ultima_inspeccion);
    data.massive = false;

    const state = this.novelties.current.state;
    const images = state.insImages;
    const noveltyType = state.noveltyType;
    const fecha_subida = moment(state.fecha_subida).utc().format();
    const DOT = state.DOT;
    const sinester = state.sinester;
    const journey = state.journey;
    const operator = state.operator;
    const novelties = this.getFmtNovelties(state.insNovelties);
    data.fecha_subida = fecha_subida;
    data.DOT = DOT;
    data.sinester = sinester;
    data.journey = journey;
    data.operator = operator;
    if (novelties.length > 0) {
      data.tiponovedad = noveltyType;
    } else {
      data.tiponovedad = null;
    }
    data.inspector = data.tecnico;
    data.customer_id = this.props.customerId;
    data.noveltys = novelties;
    data.pictures = images;

    if (data.type === "preasure_inspection") {
      const body = {
        tire: data.tire_id,
        pressure: data.ult_presion,
        plate: this.props.carCode.split(" - ")[0],
        created_by: data.analista,
        tecnico: data.tecnico,
        inspection_date: data.fc_ultima_inspeccion,
      };
      trackPromise(
        axios
          .post(APIS.API_REST_INSPECTIONS_PREASSURE, body)
          .then((response) => {
            toast.success(this.props.t("alerts.ins-one-item-27"));
            deferred.resolve("resolve");
          })
          .catch((err) => {
            if (valConnection.indexOf(err.toString()) > -1) {
              err = this.props.t("alerts.ins-one-item-28");
            }
            let errorResp = { error: err };
            //toast.error(err.toString());
            deferred.reject(errorResp);
          })
      );
    } else {
      trackPromise(
        fetch(
          APIS.API_REST_INSPECTIONS_NOVELTIES.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("tire_id", data.tire_id),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
            method: "POST",
            body: JSON.stringify({
              ...data,
              observaciones: [
                {
                  observaciones_analista: data.obsevaciones_analista,
                  observaciones: data.Observaciones,
                },
              ],
            }),
          }
        )
          .then((response) => response.json())
          .then((json) => {
            if (!!json.message) {
              this.runValidations(json);
              deferred.reject();
            } else {
              if (
                this.props.type === "desmonte" &&
                !!data.costo &&
                !!data.tipo_trabajo
              ) {
                fetch(
                  APIS.API_REST_TIRE_WORK.replace(
                    "customer_id",
                    localStorage.getItem("GlobalCustomerId")
                  ).replace("tire_id", data.tire_id) + str,
                  {
                    headers: {
                      Authorization:
                        "Basic " + localStorage.getItem("firebaseAuthToken"),
                      "Accept-language":
                        localStorage.getItem("LanguageSelected"),
                    },
                    method: "POST",
                    body: JSON.stringify({
                      work_date: data.fc_ultima_inspeccion,
                      customer_id: localStorage.getItem("GlobalCustomerId"),
                      worktype: data.tipo_trabajo,
                      cost: data.costo,
                      tire_id: data.tire_id,
                    }),
                  }
                )
                  .then((response) => response.json())
                  .then((json) => {
                    if (!isMultiple) {
                      //this.props.onSubmitCar(event,isSameCar);
                    }
                    // this.props.resetState();
                    // this._isMounted && this.setState({ ...getInitialState(this.props) });
                    toast.success(this.props.t("alerts.ins-one-item-27"));
                    deferred.resolve("resolve");
                  });
              } else {
                if (!isMultiple) {
                  // this.props.onSubmitCar(event,isSameCar);
                }
                // this.props.resetState();
                // this._isMounted && this.setState({ ...getInitialState(this.props) });
                toast.success(this.props.t("alerts.ins-one-item-27"));
                deferred.resolve("resolve");
              }
            }
          })
          .catch((error) => {
            let err = error;
            if (valConnection.indexOf(error.toString()) > -1) {
              err = this.props.t("alerts.ins-one-item-28");
            }
            let errorResp = { error: error };
            //toast.error(err.toString());
            deferred.reject(errorResp);
          })
      );
    }

    return deferred.promise;
  };

  onChange = (event) => {
    let el = document.querySelectorAll(".trabajo");
    let inspection = { ...this.state.inspection };
    if (event.target.name === "ubicacion" && this.props.type === "desmonte") {
      let sel = document.getElementById(event.target.id);
      this.setState({ ubicacion: sel.options[sel.selectedIndex].text });
      if (sel.options[sel.selectedIndex].text === "descarte") {
        let state = this.novelties.current.state;
        state.noveltyType = "Desecho";
        $("#posicionNovelties" + inspection["posicion_montaje"]).modal();
        $(
          "#noveltyTypePos" +
            inspection["posicion_montaje"] +
            " option[value=Desecho]"
        ).show();
        $(
          "#noveltyTypePos" +
            inspection["posicion_montaje"] +
            " option[value=Novedad]"
        ).hide();
        $(
          "#noveltyTypePos" +
            inspection["posicion_montaje"] +
            " option[value=Antecedente]"
        ).hide();
      } else {
        let state = this.novelties.current.state;
        state.noveltyType = "Novedad";
      }
      if (sel.options[sel.selectedIndex].text === "trabajo") {
        for (var i = 0; i < el.length; i++) {
          el[i].classList.remove("hidden");
        }
      } else {
        for (var i = 0; i < el.length; i++) {
          el[i].classList.add("hidden");
        }
      }
    }
    if (event.target.name === "ult_presion") {
      if (
        event.target.value.length > 3 ||
        isNaN(event.target.value) ||
        event.target.value.includes(".")
      ) {
        return;
      }
    }
    inspection[event.target.name] = event.target.value;
    if (
      event.target.name === "pos_final" &&
      this.props.type === "vehicle_rotation"
    ) {
      if (this.state.inspection.pos_final !== "") {
        this.props.changeStatusPositions(
          this.state.inspection.pos_final,
          false
        );
      }

      this.props.changeStatusPositions(Number(event.target.value), true);
      inspection.pos_final = Number(event.target.value);
    }
    if (!!event.target.value) {
      switch (event.target.name) {
        case "dimension_id":
          let marca = this.state.inspection["marca_id"];
          let model = this.state.inspection["modelo_id"];
          let marcas = this.state.tiresList[this.props.type].marcas;
          let dimension = event.target.value;

          let dimensionObj = this.getDimension(dimension, model, marca, marcas);
          inspection.prof_original = dimensionObj.profundidad_original;
          inspection.prof_minima = dimensionObj.profundidad_minimacambio;
          inspection.Precio_vida_actual = dimensionObj.precio;
          break;
        default:
        // Do nothing
      }
    }
    this.setState({
      inspection,
    });
  };

  onChangeFastWarehouse = (name, id) => {
    let el = document.querySelectorAll(".trabajo");
    let inspection = { ...this.state.inspection };
    this.setState({ ubicacion: name });
    if (name === "descarte") {
      let state = this.novelties.current.state;
      state.noveltyType = "Desecho";
      $("#posicionNovelties" + inspection["posicion_montaje"]).modal();
      $(
        "#noveltyTypePos" +
          inspection["posicion_montaje"] +
          " option[value=Desecho]"
      ).show();
      $(
        "#noveltyTypePos" +
          inspection["posicion_montaje"] +
          " option[value=Novedad]"
      ).hide();
      $(
        "#noveltyTypePos" +
          inspection["posicion_montaje"] +
          " option[value=Antecedente]"
      ).hide();
    } else {
      let state = this.novelties.current.state;
      state.noveltyType = "Novedad";
    }
    if (name === "trabajo") {
      for (var i = 0; i < el.length; i++) {
        el[i].classList.remove("hidden");
      }
    } else {
      for (var i = 0; i < el.length; i++) {
        el[i].classList.add("hidden");
      }
    }
    inspection["ubicacion"] = id;
    this.setState({
      inspection,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._isMounted = false;

    let inspection = { ...this.state.inspection };
    let inspectionClone = { ...this.state.inspectionClone };
    // If props changes on km
    if (nextProps.km !== this.state.inspection.kilometers) {
      inspection.kilometers = nextProps.km;
    }
    if (nextProps.hour !== this.state.inspection.hours) {
      inspection.hours = nextProps.hour;
    }
    // If props changes on date
    if (nextProps.date !== this.state.inspection.fc_ultima_inspeccion) {
      inspection.fc_ultima_inspeccion = nextProps.date;
    }
    // If props changes on technician
    if (nextProps.technician !== this.state.inspection.tecnico) {
      inspection.tecnico = nextProps.technician;
    }
    // If props changes on code tire
    if (nextProps.object.codigo !== this.state.inspection.codigo) {
      if (nextProps.type !== "montaje") {
        if (!!nextProps.object.codigo) {
          inspection.codigo = nextProps.object.codigo;
          inspection.tire_id = nextProps.object.tire_id;
        } else {
          inspection.codigo = "";
          inspection.tire_id = "";
        }
      }
    }
    // If props changes on depths
    if (
      nextProps.object.ult_prof_centro !==
        this.state.inspectionClone.ult_prof_centro &&
      nextProps.type !== "montaje"
    ) {
      inspectionClone.ult_prof_centro = nextProps.object.ult_prof_centro;
    }
    if (
      nextProps.object.ult_prof_centro_exterior !==
        this.state.inspectionClone.ult_prof_centro_exterior &&
      nextProps.type !== "montaje"
    ) {
      inspectionClone.ult_prof_centro_exterior =
        nextProps.object.ult_prof_centro_exterior;
    }
    if (
      nextProps.object.ult_prof_centro !==
        this.state.inspectionClone.ult_prof_centro &&
      nextProps.type !== "montaje"
    ) {
      inspectionClone.ult_prof_interior = nextProps.object.ult_prof_interior;
    }
    // If props changes on band
    if (nextProps.type !== "reencauche") {
      inspection.nombre_banda_reencauche = "";
      inspection.banda_reencauche = "";
    }
    // If props changes on type for action type
    if (inspection.type !== nextProps.type) {
      inspection.type = nextProps.type;
    }
    // If props changes on type for plate
    inspection.vehiculo = nextProps.object.vehicle_id;
    // inspection.order = nextProps.order
    /*
    if(nextProps.type !== "desmonte") {
      inspection.vehiculo = nextProps.object.vehicle_id;
    }
    */
    // If props changes on life for plate
    if (nextProps.type === "reencauche") {
      if (!isNaN(nextProps.object.vida)) {
        inspection.vida = 1 * nextProps.object.vida + 1;
      } else {
        inspection.vida = nextProps.object.vida;
      }
    } else {
      inspection.vida = nextProps.object.vida;
    }
    if (
      nextProps.type === "vehicle_rotation" ||
      nextProps.type === "rin_rotation"
    ) {
      inspection["pos_initial"] = inspection.posicion_montaje;
    }
    this.setState({ inspection, inspectionClone });
  }

  render() {
    const { fields, object, firebaseUser } = this.props;
    return (
      <form
        className="col-sm-12"
        onSubmit={(event) => this.onSubmit(event, true, false)}
        id="form_keys"
      >
        {this.getDynamicForm(object, fields)}
      </form>
    );
  }
}

export default InspectionVlForm;
