import { REQ_CATA_MOVEMENTS_SUCCESS, REQ_CATA_NOVELTIES_SUCCESS, REQ_CATA_RECORDS_SUCCESS, REQ_CATA_WASTE_SUCCESS } from '../types/cataloguesTypes'


const INITIAL_STATE = {
  catNovelties: [],
  catRecords: [],
  catWastes: [],
  catMovements: []
}

export const cataloguesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQ_CATA_NOVELTIES_SUCCESS:
      return {
        ...state,
        catNovelties: action.payload
      }
    case REQ_CATA_RECORDS_SUCCESS:
      return {
        ...state,
        catRecords: action.payload
      }
    case REQ_CATA_WASTE_SUCCESS:
      return {
        ...state,
        catWastes: action.payload
      }
    case REQ_CATA_MOVEMENTS_SUCCESS:
      return {
        ...state,
        catMovements: action.payload
      }
    default:
      return state
  }
}