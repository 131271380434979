import { REQ_TIRES_SUCCES, REQ_TIRES_ERROR, CLEAR_TIRES_ERROR, CLEAR_TIRES_SUCCESS, REQ_TIRE_SUCCESS, PUT_TIRE_SUCCESS, DELETE_TIRE_SUCCESS } from '../types/TiresTypes'
import API from '../../constants/api'
import { setErrorAction } from './MapErrorsAction';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';


export const getTiresAction = (customerId, page, limit, search, searchType, searchEqual) => {
  return (dispatch) => {
    var str = "";
    if (page !== "0") {
      str = "?page=" + page + "&limit=" + limit;
      if (search) {
        str = str + "&" + searchType + "=" + search
      }
      if (searchEqual) {
        str = str + "&igual=true"
      }
    }
    API
      .getAllTires(customerId, str)
      .then(res => {
        dispatch({
          type: REQ_TIRES_SUCCES,
          payload: res
        })
      })
      .catch(error => dispatch({
        type: REQ_TIRES_ERROR,
        payload: error.toString()
      }))
  }
}

export const getTireAction = (customerId, id) => {
  return (dispatch) => {
    API
      .getTire(customerId, id)
      .then(res => {
        dispatch({
          type: REQ_TIRE_SUCCESS,
          payload: res
        })
      })
      .catch(error => dispatch(setErrorAction(error.toString())))
  }
}

export const putTireAction = (customerId, id, data) => {
  return (dispatch) => {
    trackPromise(
      API
        .putTire(customerId, id, data)
        .then(res => {
          dispatch({
            type: PUT_TIRE_SUCCESS,
            payload: res
          })
          window.history.back()
          toast.success("Llanta actualizada")
        })
        .catch(error => {
          if (error.response.status != 200) {
            dispatch(setErrorAction(error.response.data.message.toString()))
          }
        })
    )
  }
}

export const deleteTireAction = (customerId, tire) => {
  return dispatch => new Promise((resolve, reject) => {
    trackPromise(
      API
        .deleteTire(customerId, tire.id)
        .then(res => {
          dispatch({
            type: DELETE_TIRE_SUCCESS,
            payload: tire
          })
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    )
  })
}

export const clearTiresErrorAction = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TIRES_ERROR,
      payload: null
    })
  }
}

export const clearTiresAction = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TIRES_SUCCESS,
      payload: []
    })
  }
}














