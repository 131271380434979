import API from "../../constants/api";
import { REQ_VEHICLESMIN_SUCCESS } from "../types/vehicleMinTypes";

export const getVehiclesMinAction = (customer_id) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getVehiclesMin(customer_id)
        .then(res => {
          if (res.vehicles) {
            dispatch({
              type: REQ_VEHICLESMIN_SUCCESS,
              payload: res.vehicles
            })
            resolve(res.vehicles)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}