import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsBrand from "../../../constants/actions/RetreadBrands";
import * as APIS from "../../../constants/apis";

function deleteRetreadModel(id, brand, bands, t) {
  if (window.confirm(t("alerts.r-tire-item-10"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_RETREADMODELS
        .replace("retreadbrand_id", brand.id)}/${id}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "DELETE"
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(t("alerts.r-tire-item-11"));
          }
          return response.json();
        })
        .then(json => {
          dispatch(onReqSuccess(brand, bands, json.message));
          return fetch(APIS.API_REST_RETREADMODELS.replace("retreadbrand_id",
            brand.id), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          });
        })
        .then(response => response.json())
        .then(json => {
          dispatch(onRetreadModelsSuccess({
            brand: brand,
            bands: json.RetreadBands
          }));
        })
        .catch(error => {
          dispatch(onReqError(brand, bands, error.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(onRetreadModelsSuccess({
        brand: brand,
        bands: bands
      }));
    };
  }
};

export function onRetreadModelsSuccess(json) {
  return {
    type: constantsBrand.GET_BRAND_SUCCESS,
    json
  };
};

export function onReqSuccess(brand, bands, message) {
  const json = {
    brand: brand,
    bands: bands,
    message: message
  };
  return {
    type: constantsBrand.REQUEST_SUCCESS,
    json
  };
};

export function onReqError(brand, bands, error) {
  const json = {
    brand: brand,
    bands: bands,
    error: error
  };
  return {
    type: constantsBrand.REQUEST_ERROR,
    json
  };
};

class RetreadModelDeleteItem extends React.Component {
  render() {
    const { brand, bands, bandDelete, deleteRetreadModel } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteRetreadModel(bandDelete.id, brand, bands, this.props.t)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({
  brand: state => state.brand,
  bands: state => state.bands
});

const mapDispatchToProps = { deleteRetreadModel };

export default connect(structuredSelector, mapDispatchToProps)(RetreadModelDeleteItem);
