import API from "../../constants/api";
import { REQ_TECHNICIAN_SUCCESS } from "../types/TechnicianTypes";

export const getTechnicianAction = (customer_id) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getTechnicians(customer_id)
        .then(res => {
          if (res.Users) {
            dispatch({
              type: REQ_TECHNICIAN_SUCCESS,
              payload: res.Users
            })
            resolve(res.Users)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}