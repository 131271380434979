import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as APIS from "../../../constants/apis";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createGroupsAction } from "../../../redux/actions/groupsAction";

const GroupNewItem = ({ t, location }) => {
  const [state, setState] = useState({
    customer_id: localStorage.getItem("GlobalCustomerId"),
    name: ""
  })

  const dispatch = useDispatch()
  const history = useHistory()

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createGroupsAction(state.customer_id, location.state.str, state, location.state.typecreate))
      .then(res => {
        if (res) {
          history.goBack()
        }
      })
      .catch(error => {
        if (error.response.status !== 201) {
          toast.error(error.response.data.message.toString())
        }
      })
  }

  const onChange = (value) => {
    setState(state => ({
      ...state,
      name: value
    }))
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className="container-fluid mt-100">
          <h3>{t("client-general.groups-new")}</h3>
          <br /><br />
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-2">
                  <label htmlFor="nameInput">{t("client-general.client-item-1")}</label>
                  <br></br><i className="text-info small">{t("globals.required")}</i>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    placeholder={t("client-general.client-item-1")}
                    id="nameInput"
                    name="name"
                    value={state.name}
                    onChange={(e) => onChange(e.target.value)}
                    required />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <button type="submit"
                  className="btn btn-primary">
                  <i className="fa fa-save"></i> {t("globals.simple-save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}


const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(GroupNewItem);