import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import GroupDeleteItem from "./GroupDeleteItem";
import Loading from "../../Loader/index"
import _debounce from "lodash/debounce";
import { getGroupsAction } from "../../../redux/actions/groupsAction";

const GroupsList = ({ t, str, reducer, type, typecreate, typeput, typedelete, title, }) => {
  const dispatch = useDispatch();
  const data = useSelector((selector) => selector);
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    customer_id: localStorage.getItem("GlobalCustomerId"),
    groups: data.groups[reducer]
  });

  useEffect(() => {
    if (data.groups[reducer].length === 0) {
      dispatch(getGroupsAction(state.customer_id, str, type))
        .then(res => {
          setState(state => ({
            ...state,
            groups: res
          }))
          setLoading(false)
        })
        .catch(error => {
          if (error.response.status !== 200) {
            toast.error(error.response.data.message.toString())
            setLoading(false)
          }
        })
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setState(state => ({
      ...state,
      groups: data.groups[reducer]
    }))
  }, [data.groups[reducer]])

  const debounceFn = _debounce((searchTerm) => {
    if (searchTerm !== "") {
      let fil = state.groups.filter(group => {
        return group.name.toLowerCase().search(searchTerm) >= 0;
      })
      if (fil.length > 0) {
        setState(state => ({
          ...state,
          groups: fil
        }))
      }
    } else {
      setState(state => ({
        ...state,
        groups: data.groups[reducer]
      }))
    }
  }, 1000);

  const handleSearch = (value) => {
    debounceFn(value.toLowerCase());
  };

  const getGroupsList = (groups, authUser) => {
    if (groups.length === 0) return null;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">
                {t("client-general.client-item-1")}
              </th>
              <th scope="col">
                {t("client-general.client-item-2")}
              </th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group) => {
              return (
                <tr key={group.id}>
                  <td>{group.name}</td>
                  <td>
                    {!!authUser.permissions.edit ? (
                      <Link
                        to={{
                          pathname: "/groups/edit/" + group.id,
                          state: {
                            str,
                            reducer,
                            typeput,
                          }
                        }}
                        className="btn btn-primary"
                      >
                        <i className="fa fa-edit"></i>{" "}
                        {t("globals.edit")}
                      </Link>
                    ) : null}{" "}
                    &nbsp;
                    {!!authUser.permissions.delete ? (
                      <GroupDeleteItem t={t} id={group.id} typedelete={typedelete} />
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div className="container-fluid mt-100">
          <div className="row">
            <div className="col-md-8">
              <h3>{t("client-general.groups-list")} {title}</h3>
            </div>
            <div className="col-md-4">
              <input
                className="form-control"
                type="text"
                id="search"
                placeholder={t("globals.search-input")}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <br />
          {!!authUser.permissions.create ? (
            <Link to={{
              pathname: "/groups/new",
              state: {
                str,
                reducer,
                typecreate,
              }
            }} className="btn btn-primary">
              <i className="fa fa-plus"></i> {t("globals.register")}
            </Link>
          ) : null}
          <br />
          <br />
          {getGroupsList(state.groups, authUser)}
        </div>
      )
      }
    </AuthUserContext.Consumer >
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(GroupsList);