import React, { Component } from "react";

import * as VARIABLES from "../../../constants/variables";
import * as APIS from "../../../constants/apis";
import * as ROLES from "../../../constants/roles";


class TypeFormTire extends Component {
  constructor(props) {
    super(props);
  }; a

  getTypesOptions = (isMultiple) => {
    let vars;

    (!!isMultiple && this.props.authUser.roles === ROLES.ADMIN || !!isMultiple && this.props.authUser.roles === ROLES.CUSTOMER) ? vars = [...VARIABLES.TYPES3] : (!!isMultiple && this.props.authUser.roles === ROLES.ANALYST) ? vars = [...VARIABLES.TYPES3OTHER] : vars = [...VARIABLES.TYPES];

    return vars.map(type => {
      return (
        <option key={type.value} value={type.value}>{this.props.t(`selectors.${type.name}`)}</option>
      )
    })
  };

  render() {

    return (
      <div className="row form-group">
        <div className="col-sm-2">
          <label htmlFor="typeSelect">{this.props.t("ins-one.ins-one-tire-form-2")}</label>
        </div>
        <div className="col-sm-10">
          <select className="form-control"
            id="typeSelect"
            name="type"
            value={this.props.type}
            onChange={this.props.onChange}>
            <option value="">
              {this.props.t("ins-one.ins-one-tire-form")}
            </option>
            {this.getTypesOptions(this.props.isMultiple)}
          </select>
        </div>
      </div>
    );
  }
};

export default TypeFormTire;
