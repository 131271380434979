import React from "react";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import Plan from "./Plan"

class PlanViewItem extends React.Component {
  constructor(props) {
    super(props);
  };
  
  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Plan isCurrent={false} />
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(PlanViewItem);
