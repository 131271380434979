import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { customersReducer } from "./redux/reducer/CustomersReducer";
import { inspectionOneReducer } from "./redux/reducer/InspectionOneReducer";
import { tiresReducer } from "./redux/reducer/TiresReducer";
import { vehicleReducer } from "./redux/reducer/VehicleReducer";
import { usersReducer } from "./redux/reducer/UsersReducer";
import { mapErrorReducer } from "./redux/reducer/MapErrorsReducer";
import { tireBrandsCatalogueReducer } from "./redux/reducer/TireBrandsCatalogueReducer";
import { retreadBrandsCatalogueReducer } from "./redux/reducer/RetreadBrandsCatalogueReducer";
import { warehousesReducer } from "./redux/reducer/TireWarehousesReducer";
import { technicianReducer } from "./redux/reducer/TechnicianReducer";
import { vehiclesMinReducer } from "./redux/reducer/vehicleMinReducer";
import { tiresMinReducer } from "./redux/reducer/tireMinReducer";
import { cataloguesReducer } from "./redux/reducer/cataloguesReducer";
import { globalsStatesReducer } from "./redux/reducer/globalsReducer";
import { groupsReducer } from "./redux/reducer/groupsReducer";

const rootReducer = combineReducers({
  users: usersReducer,
  customers: customersReducer,
  inspectionOne: inspectionOneReducer,
  customerTires: tiresReducer,
  error: mapErrorReducer,
  tireBrandsCatalogue: tireBrandsCatalogueReducer,
  retreadBrandsCatalogue: retreadBrandsCatalogueReducer,
  warehouses: warehousesReducer,
  customerVehicles: vehicleReducer,
  technicians: technicianReducer,
  vehiclesMin: vehiclesMinReducer,
  tiresMin: tiresMinReducer,
  catalogues: cataloguesReducer,
  globalStates: globalsStatesReducer,
  groups: groupsReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const generateStore = () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk.withExtraArgument()))
  );
  return store;
};
