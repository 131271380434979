import React from "react";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import Metabase from "../Metabase";

class Users extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Metabase dashboardId={832} />
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(Users);
