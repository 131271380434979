export const stonly = () => {
  const intervalWidget = setInterval(() => {
    const widget = document.querySelector(".eo30uvs0");
    if (widget) {
      clearInterval(intervalWidget);
      const intervalImage = setInterval(() => {
        const div = document.querySelector(".e1dzn77g0");
        if (div) {
          setChild(div);
          clearInterval(intervalImage);
        }
      }, 300);
    }
  }, 300);
};

const setChild = (div) => {
  let img = document.createElement("img");
  img.src = "https://jira-emails.s3.amazonaws.com/eva_Mesa+de+trabajo+1.png";
  img.style = "width: 100%;";
  div.appendChild(img);
  div.childNodes[0].style = "display: none";
};

export const stonlyConfig = (authUser) => {
  configTrial(authUser);
};

const configTrial = (authUser) => {
  const product = localStorage.getItem("GlobalCustomerProduct");
  const days = localStorage.getItem("GlobalCustomerDays");

  if (authUser) {
    trialTrigger(true, days ? parseInt(days) : 15, product ? product : "");
  } else {
    trialTrigger(false, 100, "");
  }
};

const trialTrigger = (auth, days, product) => {
  window.StonlyWidget("identify", "", {
    trial: true,
    is_auth: auth,
    customer_days: days,
    customer_product: product,
  });
};
