import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsPlan from "../../../constants/actions/Plans";
import * as APIS from "../../../constants/apis";

function deletePlan(name, planes, plan) {
  if (window.confirm("¿Estás seguro de eliminar este catálogo?")) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_PLANS_HISTORICS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        },
        method: "DELETE"
      })
        .then(response => {
          if (response.status !== 204) {
            throw new Error("No se pudo eliminar, inténtalo de nuevo");
          }
          return response.json();
        })
        .then(json => {
          dispatch(getReqSuccess(planes, plan,
            "Plan eliminado"));
          return fetch(APIS.API_REST_TIRECATALOGUES.replace("customer_id",
            localStorage.getItem("GlobalCustomerId")), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            }
          })
            .then(response => response.json())
            .then(json => {
              dispatch(getPlansSuccess({
                planes: json.Plan,
              }));
            })
            .catch(error => {
              dispatch(getReqError(planes,
                plan, error.toString()));
            });
        })
        .catch(error => {
          dispatch(getReqSuccess(planes, plan,
            "Plan eliminado"));
        }));
    };
  } else {
    return dispatch => {
      dispatch(getPlansSuccess({
        planes: planes,
      }));
    };
  }
};

export function getPlansSuccess(json) {
  return {
    type: constantsPlan.GET_PLANS_SUCCESS,
    json
  };
};

export function getReqSuccess(planes, plan, message) {
  const json = {
    planes: planes,
    plan: plan,
    message: message
  };
  return {
    type: constantsPlan.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(planes, plan, error) {
  const json = {
    planes: planes,
    plan: plan,
    error: error
  };
  return {
    type: constantsPlan.GET_REQUEST_ERROR,
    json
  };
};

class PlanDeleteItem extends React.Component {
  render() {
    const { planes, plan,
      planDelete, deletePlan } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deletePlan(
          planDelete.plan_id, planes, plan)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> Eliminar
        </button>
      </React.Fragment>
    );
  };
};

const structuredSelector = createStructuredSelector({
  planes: state => state.planes,
  plan: state => state.plan
});

const mapDispatchToProps = { deletePlan };

export default connect(structuredSelector, mapDispatchToProps)(PlanDeleteItem);
