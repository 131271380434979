import $ from "jquery";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as APIS from "../../../constants/apis";
import Autosuggest from "react-autosuggest";

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

class WorkNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: localStorage.getItem("GlobalCustomerId"),
      work_date: null,
      worktype: null,
      cost: null,
      km_id: null,
      km: null,
      kms: [],
      params: {},
      next_measurament: null,
      vehicle_id: null,
      isNewKm: false,
      order: "",
      observacion: "",
      pageP: null,
      pageN: null,
      technicians: [],
      technical: "",
      suggestionsTnc: [],
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ vehicle_id: id });
    this.getKms(id);
    this.getTnc();
  }

  getTnc = () => {
    trackPromise(
      fetch(
        APIS.API_REST_TECHNICIANS.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ technicians: json.Users });
        })
    );
  };

  getKms = (vehicle_id, page) => {
    var str = "?page=1&limit=20";
    if (page !== "0" && !!page) {
      str = "?page=" + page + "&limit=20";
    }
    return trackPromise(
      fetch(
        `${
          APIS.API_REST_FUELS_HISTORY.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("vehicle_id", vehicle_id) + str
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-1"));
          }
          return response.json();
        })
        .then((json) => {
          let pageP, pageN;
          if (!!json.previus) {
            str = json.previus;
            pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          if (!!json.next) {
            str = json.next;
            pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          this.setState({
            kms: json.combustibles,
            pageP: pageP,
            pageN: pageN,
          });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.work-tire-item-1"));
        })
    );
  };

  onSubmitWork(event, history) {
    event.preventDefault();

    var data = {
      ...this.state,
    };
    delete data.technicians;
    delete data.suggestionsTnc;
    data.work_date = moment(data.work_date).utc().format();
    data.next_measurament = parseInt(data.next_measurament);
    var date = moment(data.work_date).utc().format("YYYY-MM-DD");
    if (date > moment().utc().format("YYYY-MM-DD")) {
      toast.error(this.props.t("alerts.work-tire-item-12"));
    } else {
      if (!!data.km) {
        var kmdate = moment(
          data.kms.find((km) => km.id == data.km_id).fecha_inspeccion
        ).format("YYYY-MM-DD");
        if (date == kmdate) {
          trackPromise(
            fetch(
              `${APIS.API_REST_VEHICLE_WORK.replace(
                "customer_id",
                localStorage.getItem("GlobalCustomerId")
              ).replace("vehicle_id", this.state.vehicle_id)}`,
              {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Basic " + localStorage.getItem("firebaseAuthToken"),
                  "Accept-language": localStorage.getItem("LanguageSelected"),
                },
              }
            )
              .then((response) => response.json())
              .then((json) => {
                const status = json.message;
                if (!status) {
                  toast.success(this.props.t("alerts.work-tire-item-14"));
                  this.props.history.push(
                    "/works/vehicles/" + this.state.vehicle_id
                  );
                } else {
                  throw new Error(status);
                }
              })
              .catch((error) => {
                toast.error(error.toString());
              })
          );
        } else {
          toast.error(this.props.t("alerts.work-tire-item-13"));
        }
      } else {
        trackPromise(
          fetch(
            `${APIS.API_REST_VEHICLE_WORK.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            ).replace("vehicle_id", this.state.vehicle_id)}`,
            {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          )
            .then((response) => response.json())
            .then((json) => {
              const status = json.message;
              if (!status) {
                toast.success(this.props.t("alerts.work-tire-item-14"));
                this.props.history.push(
                  "/works/vehicles/" + this.state.vehicle_id
                );
              } else {
                throw new Error(status);
              }
            })
            .catch((error) => {
              toast.error(error.toString());
            })
        );
      }
    }
  }

  registerNewKm = (event) => {
    event.preventDefault();
    let kmsArr = this.state.kms;
    let data1 = {
      kilometers: document.getElementById("newKmInput").value,
      fecha_subida: moment().utc().format(),
      fecha_inspeccion: moment(document.getElementById("newDateInput").value)
        .utc()
        .format(),
    };
    fetch(
      APIS.API_REST_FUELS.replace(
        "customer_id",
        localStorage.getItem("GlobalCustomerId")
      ).replace("vehicle_id", this.state.vehicle_id),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "POST",
        body: JSON.stringify(data1),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (!!json.message) {
          toast.error(json.message);
          //throw new Error(json.message);
        } else {
          kmsArr.push({ ...data1, id: json.id });
          toast.success(this.props.t("alerts.work-tire-item-15"));
          this.setState({
            isNewKm: false,
            kms: kmsArr,
            km: document.getElementById("newKmInput").value,
            km_id: json.id,
          });
          $("#last10Km").modal("hide");
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        //throw new Error(error);
      });
  };

  chooseKm(kilometers, kilometers_id) {
    $("#last10Km").modal("hide");
    this.setState({ km: kilometers, km_id: kilometers_id });
  }

  onChange = (event) => {
    if (event.target.name === "cantidad") {
      var cxm = parseFloat(
        parseFloat(event.target.value) *
          parseFloat(document.getElementById("costInput").value)
      );
      let params = this.state.params;
      params.cantidad = event.target.value;
      params.costototal = cxm;
      this.setState({ params });
    } else if (
      event.target.name === "cost" &&
      this.state.worktype === "torqueo"
    ) {
      if (document.getElementById("divCantidad").offsetLeft >= 0) {
        var cxm = parseFloat(
          parseFloat(event.target.value) *
            parseFloat(document.getElementById("cantidadInput").value)
        );
        let params = this.state.params;
        params.costototal = cxm;
        this.setState({ params });
      }
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  getSuggestionsTnc = (value) => {
    let tnc = this.state.technicians;
    let reTnc = tnc.filter((item) => {
      const tncValue = escapeRegexCharacters(value.toLowerCase().trim());
      return (
        (item.name.toLowerCase().search(tncValue) >= 0 && item.active) ||
        (item.email.toLowerCase().search(tncValue) >= 0 && item.active)
      );
    });
    return reTnc;
  };

  onChangeTnc = (event, { newValue }) => {
    this.setState({
      technical: newValue,
    });
  };

  onSuggestionsFetchRequestedTnc = ({ value }) => {
    this.setState({
      suggestionsTnc: this.getSuggestionsTnc(value),
    });
  };

  onSuggestionsClearRequestedTnc = () => {
    this.setState({
      suggestionsTnc: [],
    });
  };

  getSuggestionValueTnc = (suggestion) => suggestion.name;

  renderSuggestionTnc = (suggestion) => (
    <div>
      <p>
        {suggestion.name}: {suggestion.email}
      </p>
    </div>
  );

  render() {
    const { history } = this.props;

    const inputProps = {
      placeholder: this.props.t("alerts.ins-one-item-14"),
      value: this.state.technical,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "100%",
        marginBottom: 0,
      },
      onChange: this.onChangeTnc,
    };

    console.log(localStorage.getItem("GlobalCustomerId"));

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("work-vehicle.client-item-19")}</h3>
            <br />
            <br />
            <form onSubmit={(event) => this.onSubmitWork(event, history)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="codigoInput">
                      {this.props.t("client-tires.client-item-27")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder="#"
                      id="orderInput"
                      name="order"
                      value={this.state.order}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="codigoInput">
                      {this.props.t("client-vehicles.client-item-7")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t(
                        "client-vehicles.client-observation"
                      )}
                      id="observacionInput"
                      name="observacion"
                      value={this.state.observacion}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="worktypeInput">
                      {this.props.t("work-vehicle.client-item-1")}
                    </label>
                    <br></br>
                    <i className="text-info small">
                      {this.props.t("globals.required")}
                    </i>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      id="worktypeInput"
                      name="worktype"
                      value={this.state.worktype}
                      onChange={this.onChange}
                      required
                    >
                      <option value="">
                        {this.props.t("work-vehicle.client-item-12")}
                      </option>
                      <option value="alineacion">
                        {this.props.t("work-vehicle.client-item-13")}
                      </option>
                      <option value="torqueo">
                        {this.props.t("work-vehicle.client-item-14")}
                      </option>
                      {localStorage.getItem("GlobalCustomerId") === "650" && (
                        <>
                          <option value="montaje / desmontaje">
                            {this.props.t("work-vehicle.client-item-25")}
                          </option>
                          <option value="calibración">
                            {this.props.t("work-vehicle.client-item-26")}
                          </option>
                          <option value="rotaciónes">
                            {this.props.t("work-vehicle.client-item-27")}
                          </option>
                          <option value="girada de rim">
                            {this.props.t("work-vehicle.client-item-28")}
                          </option>
                          <option value="cambio de neumáticos">
                            {this.props.t("work-vehicle.client-item-29")}
                          </option>
                          <option value="alineación / balanceo">
                            {this.props.t("work-vehicle.client-item-30")}
                          </option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              {this.state.worktype === "torqueo" ? (
                <div id="divCantidad" className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="cantidadInput">
                        {this.props.t("work-vehicle.client-item-4")}
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        name="cantidad"
                        type="number"
                        id="cantidadInput"
                        className="form-control"
                        value={this.state.params.cantidad}
                        min={0}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row form-group">
                <div className="col-sm-2">
                  <label htmlFor="inputCarKm">
                    {this.props.t("work-vehicle.client-item-2")}
                  </label>
                </div>
                <div className="col-sm-10 input-group">
                  <input
                    name="km"
                    type="number"
                    id="inputCarKm"
                    className="form-control"
                    value={this.state.km}
                    readOnly
                    min={this.state.initKm}
                    onChange={this.onChange}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary text-white"
                      type="button"
                      data-toggle="modal"
                      data-target={"#last10Km"}
                    >
                      <i className="fa fa-chart-line"></i>{" "}
                      {this.props.t("work-vehicle.client-item-15")}
                    </button>
                    <div
                      className="modal fade"
                      id="last10Km"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="last10KmLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              id="last10KmLabel"
                              className="modal-title text-dark"
                            >
                              {this.props.t("work-vehicle.client-item-16")}
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Cerrar"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body text-dark">
                            {this.state.isNewKm === false ? (
                              <div>
                                <button
                                  type="button"
                                  onClick={(e) =>
                                    this.setState({ isNewKm: true })
                                  }
                                  className="btn btn-primary float-right"
                                >
                                  {this.props.t("work-vehicle.client-item-20")}
                                </button>
                                <table className="table table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th>
                                        {this.props.t(
                                          "work-vehicle.client-item-2"
                                        )}
                                      </th>
                                      <th>
                                        {this.props.t(
                                          "work-vehicle.client-item-17"
                                        )}
                                      </th>
                                      <th>
                                        {this.props.t(
                                          "work-vehicle.client-item-7"
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.kms.map((km, i) => {
                                      return (
                                        <tr key={i}>
                                          <td>{km.kilometers}</td>
                                          <td>
                                            {moment
                                              .utc(km.fecha_inspeccion)
                                              .local()
                                              .locale("es")
                                              .format("LLL")}
                                          </td>
                                          <td>
                                            <a
                                              href="#"
                                              className="btn btn-primary"
                                              onClick={(e) =>
                                                this.chooseKm(
                                                  km.kilometers,
                                                  km.id
                                                )
                                              }
                                            >
                                              <i className="fa fa-link"></i>{" "}
                                              {this.props.t(
                                                "work-vehicle.client-item-18"
                                              )}
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td>
                                        {!!this.state.pageP ? (
                                          <button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={(e) =>
                                              this.getKms(
                                                this.state.vehicle_id,
                                                this.state.pageP
                                              )
                                            }
                                          >
                                            <i className="fa fa-arrow-left"></i>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-primary"
                                            disabled
                                          >
                                            <i className="fa fa-arrow-left"></i>
                                          </button>
                                        )}
                                      </td>
                                      <td>
                                        {!!this.state.pageN ? (
                                          <button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={(e) =>
                                              this.getKms(
                                                this.state.vehicle_id,
                                                this.state.pageN
                                              )
                                            }
                                          >
                                            <i className="fa fa-arrow-right"></i>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-primary"
                                            disabled
                                          >
                                            <i className="fa fa-arrow-right"></i>
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            ) : (
                              <div>
                                <button
                                  type="button"
                                  onClick={(e) =>
                                    this.setState({ isNewKm: false })
                                  }
                                  className="btn btn-primary float-right"
                                >
                                  {this.props.t("work-vehicle.client-item-21")}
                                </button>
                                <br />
                                <br />
                                <fieldset>
                                  <div className="row form-group">
                                    <div className="col-sm-2">
                                      <label htmlFor="newKmInput">
                                        {this.props.t(
                                          "work-vehicle.client-item-22"
                                        )}
                                      </label>
                                    </div>
                                    <div className="col-sm-10">
                                      <input
                                        name="km"
                                        type="text"
                                        id="newKmInput"
                                        className="form-control"
                                        placeholder="Nuevo Km"
                                      />
                                    </div>
                                  </div>
                                  <div className="row form-group">
                                    <div className="col-sm-2">
                                      <label htmlFor="newDateInput">
                                        {this.props.t(
                                          "work-vehicle.client-item-23"
                                        )}
                                      </label>
                                    </div>
                                    <div className="col-sm-10">
                                      <input
                                        name="date"
                                        type="datetime-local"
                                        id="newDateInput"
                                        className="form-control"
                                        placeholder="Fecha de revisión"
                                      />
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={(e) => this.registerNewKm(e)}
                                    className="btn btn-primary float-right"
                                  >
                                    {this.props.t("globals.register")}
                                  </button>
                                </fieldset>
                              </div>
                            )}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-dismiss="modal"
                              aria-label="Cerrar"
                            >
                              {this.props.t("globals.close")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="costInput">
                      {this.props.t("work-vehicle.client-item-3")}
                    </label>
                    <br></br>
                    <i className="text-info small">
                      {this.props.t("globals.required")}
                    </i>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("work-vehicle.client-item-3")}
                      id="costInput"
                      name="cost"
                      value={this.state.cost}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              {this.state.worktype === "torqueo" ? (
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="costototalInput">
                        {this.props.t("work-vehicle.client-item-24")}
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        name="costototal"
                        type="number"
                        id="costototalInput"
                        className="form-control"
                        value={this.state.params.costototal}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="workDate">
                      {this.props.t("work-vehicle.client-item-6")}
                    </label>
                    <br></br>
                    <i className="text-info small">
                      {this.props.t("globals.required")}
                    </i>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder="Fecha"
                      id="workDate"
                      name="work_date"
                      value={this.state.work_date}
                      type="datetime-local"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nextMeasurement">
                      {this.props.t("work-vehicle.client-item-5")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("work-vehicle.client-item-5")}
                      id="nextMeasurement"
                      name="next_measurament"
                      value={this.state.next_measurament}
                      type="number"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-2">
                  <label htmlFor="inputCarTechnician">
                    {this.props.t("ins-one.ins-one-31")}
                  </label>
                </div>
                <div className="col-sm-10">
                  <Autosuggest
                    suggestions={this.state.suggestionsTnc}
                    onSuggestionsFetchRequested={
                      this.onSuggestionsFetchRequestedTnc
                    }
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequestedTnc
                    }
                    shouldRenderSuggestions={() => true}
                    getSuggestionValue={this.getSuggestionValueTnc}
                    renderSuggestion={this.renderSuggestionTnc}
                    inputProps={inputProps}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    <i className="fa fa-save"></i>{" "}
                    {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(WorkNewItem);
