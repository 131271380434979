import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { toast } from "react-toastify";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../../constants/routes";
import { useTranslation } from "react-i18next";

var mixpanel = require("mixpanel-browser");
mixpanel.init("23ec03be15116366b7f719d96c50caa1");

var logo = "../../images/logo.png";
if (localStorage.getItem("logo")) {
  logo = localStorage.getItem("logo");
}

const SignInPage = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div style={{ background: "#f2f2f2", height: "100vh" }}>
      <div className="signin-content">
        <div className="shadow text-center p-5 mb-5 bg-body rounded signin-body">
          <p className="float-right" style={{ marginTop: "-20px" }}>
            v1.62
          </p>
          <img src={logo} alt="Ruedata" height="100" className="m-2 mb-5" />
          <SignInForm t={t} />
        </div>
      </div>
    </div>
  );
};

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  isShowAndHidePassword: false,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    mixpanel.track("Pagina Login");

    this.handleShowHidePassword = this.handleShowHidePassword.bind(this);
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        mixpanel.identify(localStorage.getItem("GlobalCustomerId"));
        mixpanel.people.set({
          $email: email,
          "Sign up date": new Date().toISOString(),
          USER_ID: localStorage.getItem("GlobalCustomerId"),
        });
        this.setState({ ...INITIAL_STATE });
        // this.props.history.push(ROUTES.LANDING);
        window.location.replace(ROUTES.LANDING);
      })
      .catch((error) => {
        toast.error(error.message);
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleShowHidePassword = (event) => {
    this.setState({
      isShowAndHidePassword: !this.state.isShowAndHidePassword,
    });
  };

  render() {
    const email = this.state.email;
    const password = this.state.password;
    const { isShowAndHidePassword } = this.state;

    const isInvalid = password === "" || email === "";
    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <label htmlFor="inputEmail" className="signin-label">
            {this.props.t("auth.email")}
          </label>
          <input
            name="email"
            type="email"
            className="form-control mb-3"
            id="inputEmail"
            value={email}
            onChange={this.onChange}
            placeholder={this.props.t("auth.email")}
          />
        </div>
        <div className="form-group" style={{ position: "relative" }}>
          <label htmlFor="inputPassword" className="signin-label">
            {this.props.t("auth.password")}
          </label>
          <input
            name="password"
            type={isShowAndHidePassword ? "text" : "password"}
            className="form-control"
            id="inputPassword"
            value={password}
            onChange={this.onChange}
            placeholder={this.props.t("auth.password")}
          />
          <button
            type="button"
            className="button-password"
            onClick={this.handleShowHidePassword}
          >
            {isShowAndHidePassword ? (
              <img src={process.env.PUBLIC_URL + "/images/invisible.png"} />
            ) : (
              <img src={process.env.PUBLIC_URL + "/images/ver.png"} />
            )}
          </button>
        </div>
        <div className="row">
          <PasswordForgetLink t={this.props.t} />
        </div>
        <br />
        <button
          type="submit"
          className="btn btn-primary signin-submit"
          disabled={isInvalid}
        >
          {this.props.t("auth.btn-signIn")}
        </button>
      </form>
    );
  }
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;

export { SignInForm };
