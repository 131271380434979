import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import * as constantsGroup from "../../../constants/actions/Groups";
import * as APIS from "../../../constants/apis";
import { deleteGroupAction } from "../../../redux/actions/groupsAction";
import { toast } from "react-toastify";


const GroupDeleteItem = ({ t, id, typedelete }) => {
  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteGroup = () => {
    setIsDeleting(true)
    dispatch(deleteGroupAction(localStorage.getItem("GlobalCustomerId"), id, typedelete))
      .then(res => {
        if (res) {
          toast.success(res.toString())
          setIsDeleting(false)
        }
      })
      .catch(error => {
        if (error.response.status !== 200) {
          toast.error(error.response.data.message.toString())
        }
      })
  }


  return (
    <>
      {
        isDeleting ?
          <button class="btn btn-danger" type="button" disabled={isDeleting ? true : false}>
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          </button>
          :
          <button onClick={() => deleteGroup()}
            className="btn btn-danger" >
            {/* <i className="fa fa-trash"></i> */}
            {t("globals.delete")}
          </button >
      }
    </>
  )
}

export default GroupDeleteItem
