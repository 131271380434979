import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsVehicleType from "../../constants/actions/VehicleTypes";
import * as APIS from "../../constants/apis";

function onSubmitVehicleType(event, state, vtypes, vtype, history, t) {
  event.preventDefault();

  return dispatch => {
    const data = {
      ...state,
      customer_id: localStorage.getItem("GlobalCustomerId"),
    };
    if (!localStorage.getItem("GlobalCustomerId")) return null;
    trackPromise(fetch(`${APIS.API_REST_TYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.status !== 201) {
          throw new Error(t("alerts.v-type-item-2"));
        }
        return response.json();
      })
      .then(json => {
        const status = json.message;
        if (!status) {
          return fetch(APIS.API_REST_TYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
            .then(response => response.json())
            .then(json => {
              history.push("/types");
              dispatch(getReqSuccess(vtypes, vtype, t("alerts.v-type-item-3")));
              dispatch(getVehicleTypesSuccess(json));
            })
            .catch(error => {
              history.push("/types");
              dispatch(getReqError(vtypes, vtype, error.toString()));
            });
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

export function getVehicleTypesSuccess(json) {
  return {
    type: constantsVehicleType.GET_VTYPES_SUCCESS,
    json
  };
};

export function getReqSuccess(vtypes, vtype, message) {
  const json = {
    vtypes: vtypes,
    vtype: vtype,
    message: message
  };
  return {
    type: constantsVehicleType.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(vtypes, vtype, error) {
  const json = {
    vtypes: vtypes,
    vtype: vtype,
    error: error
  };
  return {
    type: constantsVehicleType.GET_REQUEST_ERROR,
    json
  };
};

class VehicleTypeNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // code: ""
    };
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name, code } = this.state;
    const { vtype, vtypes, history } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <h3>{this.props.t("vehicles-type.type-register")}</h3>
            <br /><br />
            <form onSubmit={(event) => this.props.onSubmitVehicleType(event,
              this.state, vtypes, vtype, history, this.props.t)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">{this.props.t("vehicles-type.type-item-1")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("vehicles-type.type-item-1")}
                      id="nameInput"
                      name="name"
                      value={name}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                    className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  vtypes: state => state.vtypes,
  vtype: state => state.vtype
});

const mapDispatchToProps = { onSubmitVehicleType };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(VehicleTypeNewItem));
