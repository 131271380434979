import React from 'react'
import { Toast } from 'react-bootstrap';
import { AuthUserContext, withAuthorization } from '../../Auth/Session';
import * as APIS from '../../../constants/apis'
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

class MacrosNewItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            macro_name: null,
        }
    }

    setInput = (e) => {
        this.setState({macro_name: e.target.value})
    }

    onSubmit = async (e) => {
        e.preventDefault()
        let data = {
            name: this.state.macro_name
        }
        trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_NEW, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
                },
            method: "POST",
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
            if (json.message.name) throw new Error(json.message.name)

            toast.success(this.props.t("macros.m-item-36"))
            this.addSuperAdmin(json.data.id) 
        })
        .catch(err => {
            toast.error(err.message)
        }))
    }

    addSuperAdmin = (id) => {
        if(window.confirm("¿ Deseas asociar a los SUPER ADMINS ?")) {
            let str = "?type_account=mc_account"
            let data = {
                mc_mmc_id: id
            }
            trackPromise(fetch(APIS.API_REST_MACRO_POST_ADMIN + str, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                    "Accept-language": localStorage.getItem("LanguageSelected"),
                    },
                method: "POST",
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(json => {
                if (json.message != "El registro se guardado correctamente") throw new Error(json.message)
                toast.success("Super Admins Asociados")
                window.history.back();
            })
            .catch(err => {
                toast.error(err.message)
            }))
        } else {
            window.history.back();
        }
    }


    render() { 
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <div  className="container-fluid mt-100">
                        <form onSubmit={e => this.onSubmit(e)}>
                            <div className="d-flex mb-5">
                                <div className="mr-5">
                                    <label>{this.props.t("macros.m-item-33")} </label>
                                    <br></br>
                                    <i className="text-info small" style={{marginTop: "-14px"}}>{this.props.t("globals.required")}</i>
                                </div>
                                <input
                                    className="form-control mt-2 ml-3"
                                    type="text"
                                    id="search"
                                    onChange={(e) => this.setInput(e)}
                                    placeholder={this.props.t("macros.m-item-33")}
                                    required
                                    />
                            </div>
                            <button disabled={this.state.macro_name ? false : true} className="btn btn-primary mt-1 d-block" style={{height: "40px", margin: "0 auto"}}>{this.props.t("macros.m-item-37")}</button>
                        </form>
                    </div>
                )}
            </AuthUserContext.Consumer>

        );
    }
}



 
const condition = authUser => !!authUser;

export default withAuthorization(condition)(MacrosNewItem);