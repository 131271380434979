import React, { Component } from "react";
import { AuthUserContext, withAuthentication } from "../Auth/Session";
import * as VARIABLES from "../../constants/variables";
import * as ROUTES from "../../constants/routes";
import * as APIS from "../../constants/apis";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import Metabase from "../Reports/Metabase";

class Macro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRol: "",
      macroId: null,
      macroName: "",
      macroType: "",

      fleetsPerMacroAllId: [],
      conditionalMacro: ""
    }
  }



  componentDidMount() {
    this.setState({
      userRol: sessionStorage.getItem("GlobalMacroSelectedRol"),
      macroId: sessionStorage.getItem("GlobalMacroSelectedId"),
      macroName: sessionStorage.getItem("GlobalMacroSelectedName"),
      macroType: sessionStorage.getItem("GlobalMacroSelectedType"),
    })

    if (sessionStorage.getItem("GlobalMacroSelectedId")) {
      this.getFleetsPerMacro(sessionStorage.getItem("GlobalMacroSelectedId"))
    }
  }

  getFleetsPerMacro = (macroId) => {
    let str = `?type_account=mc_account`
    trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_GET_CLIENTS_MACRO.replace("macro_id", macroId) + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        let allIds = []
        for (let i = 0; i < json.client_macro_accounts.length; i++) {
          allIds.push(json.client_macro_accounts[i].client_id)
        }

        if (allIds.length > 0) {
          this.setState({ fleetsPerMacroAllId: allIds })
        }
      })
      .catch(err => {
        toast.error(err.message)
      }))
  }

  onChangeConditional = (value) => {
    this.setState({ conditionalMacro: value })
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid px-0" style={{ backgroundColor: '#fff' }}>
            <div className="d-flex justify-content-center">
              <ul style={{ marginTop: '77px' }} class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="false" onClick={() => this.onChangeConditional("home-0")}>
                    {this.props.t("landing.lan-item-8")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-1-tab" data-toggle="pill" href="#pills-home-1" role="tab" aria-controls="pills-home-1" aria-selected="false" onClick={() => this.onChangeConditional("home-1")}>
                    {this.props.t("landing.lan-item-9")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-2-tab" data-toggle="pill" href="#pills-home-2" role="tab" aria-controls="pills-home-2" aria-selected="false" onClick={() => this.onChangeConditional("home-2")}>
                    {this.props.t("landing.lan-item-10")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-3-tab" data-toggle="pill" href="#pills-home-3" role="tab" aria-controls="pills-home-3" aria-selected="false" onClick={() => this.onChangeConditional("home-3")}>
                    {this.props.t("landing.lan-item-11")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-4-tab" data-toggle="pill" href="#pills-home-4" role="tab" aria-controls="pills-home-4" aria-selected="false" onClick={() => this.onChangeConditional("home-4")}>
                    {this.props.t("landing.lan-item-12")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-5-tab" data-toggle="pill" href="#pills-home-5" role="tab" aria-controls="pills-home-5" aria-selected="false" onClick={() => this.onChangeConditional("home-5")}>
                    {this.props.t("landing.lan-item-13")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-6-tab" data-toggle="pill" href="#pills-home-6" role="tab" aria-controls="pills-home-6" aria-selected="false" onClick={() => this.onChangeConditional("home-6")}>
                    {this.props.t("landing.lan-item-15")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-7-tab" data-toggle="pill" href="#pills-home-7" role="tab" aria-controls="pills-home-7" aria-selected="false" onClick={() => this.onChangeConditional("home-7")}>
                    {this.props.t("landing.lan-item-16")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-8-tab" data-toggle="pill" href="#pills-home-8" role="tab" aria-controls="pills-home-8" aria-selected="false" onClick={() => this.onChangeConditional("home-8")}>
                    {this.props.t("landing.lan-item-17")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="pills-home-9-tab" data-toggle="pill" href="#pills-home-9" role="tab" aria-controls="pills-home-9" aria-selected="false" onClick={() => this.onChangeConditional("home-9")}>
                    {this.props.t("landing.lan-item-18")}
                  </a>
                </li>
                <li class="nav-item">
                  <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                    {this.props.t("landing.lan-item-19")}
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#" onClick={() => this.onChangeConditional('home-10')}>
                        {this.props.t("landing.lan-item-17")}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              {
                this.state.conditionalMacro === "home-0" ?
                  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <Metabase dashboardId={875} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-1" ?
                  <div class="tab-pane fade show active" id="pills-home-1" role="tabpanel" aria-labelledby="pills-home-1-tab">
                    <Metabase dashboardId={876} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-2" ?
                  <div class="tab-pane fade show active" id="pills-home-2" role="tabpanel" aria-labelledby="pills-home-2-tab">
                    <Metabase dashboardId={877} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-3" ?
                  <div class="tab-pane fade show active" id="pills-home-3" role="tabpanel" aria-labelledby="pills-home-3-tab">
                    <Metabase dashboardId={878} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-4" ?
                  <div class="tab-pane fade show active" id="pills-home-4" role="tabpanel" aria-labelledby="pills-home-4-tab">
                    <Metabase dashboardId={879} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-5" ?
                  <div class="tab-pane fade show active" id="pills-home-5" role="tabpanel" aria-labelledby="pills-home-5-tab">
                    <Metabase dashboardId={880} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-6" ?
                  <div class="tab-pane fade show active" id="pills-home-6" role="tabpanel" aria-labelledby="pills-home-6-tab">
                    <Metabase dashboardId={1015} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-7" ?
                  <div class="tab-pane fade show active" id="pills-home-7" role="tabpanel" aria-labelledby="pills-home-7-tab">
                    <Metabase dashboardId={1027} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-8" ?
                  <div class="tab-pane fade show active" id="pills-home-8" role="tabpanel" aria-labelledby="pills-home-8-tab">
                    <Metabase dashboardId={1055} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-9" ?
                  <div class="tab-pane fade show active" id="pills-home-9" role="tabpanel" aria-labelledby="pills-home-9-tab">
                    <Metabase dashboardId={1088} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {
                this.state.conditionalMacro === "home-10" ?
                  <div class="tab-pane fade show active" id="pills-home-10" role="tabpanel" aria-labelledby="pills-home-10-tab">
                    <Metabase dashboardId={1091} clientIds={this.state.fleetsPerMacroAllId} />
                  </div>
                  : null
              }
              {/* <div class="tab-pane fade" id="pills-home-6" role="tabpanel" aria-labelledby="pills-home-6-tab">
                    <Metabase dashboardId={834} clientIds={this.state.fleetsPerMacroAllId} />
                  </div> */}
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withAuthentication(Macro);
