const urlBase = process.env.REACT_APP_API_URL;

//Filters
export const FILTER_CITIES = urlBase + "/customers/customer_id/filter/cities";
export const FILTER_COSTCENTERS = urlBase + "/customers/customer_id/filter/costcenters";
export const FILTER_VEHICLEGROUPS = urlBase + "/customers/customer_id/filter/vehiclegroups";
export const FILTER_VEHICLETYPES = urlBase + "/customers/customer_id/filter/vehicletypes";
export const FILTER_VEHICLEBRANDS = urlBase + "/customers/customer_id/filter/vehiclebrands";
export const FILTER_VEHICLELINES = urlBase + "/customers/customer_id/filter/vehiclelinemodels";
export const FILTER_TIREBRANDS = urlBase + "/customers/customer_id/filter/tirebrands";
export const FILTER_TIREMODELS = urlBase + "/customers/customer_id/filter/tiremodels";
export const FILTER_TIREDIMENSIONS = urlBase + "/customers/customer_id/filter/tiredimentions";
export const FILTER_RETREADBANDS = urlBase + "/customers/customer_id/filter/retreadbands";

// Users
export const API_REST_USERS = urlBase + "/users";
export const API_REST_USERS_PER_ROL = urlBase + "/users/role/role_id";
export const API_REST_ROLES = urlBase + "/roles";
export const API_REST_PERMISSIONS = urlBase + "/permissions";
export const API_REST_LANGUAGE = urlBase + "/users/config/user_id";

// RESELLERS
export const API_REST_RESELLERS = urlBase + "/reseller"

// Customers
export const API_REST_CUSTOMERS = urlBase + "/customers";
export const API_REST_CUSTOMERS_USERS = urlBase + "/customers/customer_id/users";

// Technicians
export const API_REST_TECHNICIANS = urlBase + "/customers/customer_id/users/technitians";

//facturations
export const API_REST_INVOICES = urlBase + "/customers/customer_id/facturations";

// Dashboards
export const API_REST_DASHBOARDS = urlBase + "/customers/customer_id/dashboards";

// Vehicles Schemas
export const API_REST_SCHEMAS = urlBase + "/customers/customer_id/vehicleschemas";

// Vehicles Types
export const API_REST_TYPES = urlBase + "/customers/customer_id/vehicletypes";

// Vehicles Brands
export const API_REST_BRANDS = urlBase + "/customers/customer_id/vehiclebrands";

// Vehicles Lines
export const API_REST_LINES = urlBase + "/customers/customer_id/vehiclebrands/brand_id/vehiclelinemodels";
export const CUSTOMER_VEHICLELINES = urlBase + "/customers/customer_id/vehiclelines";

// Tire Brands
export const API_REST_TIREBRANDS = urlBase + "/tirebrands";
export const API_REST_TIREBRANDS_EXCEL = urlBase + "/tirebrands/export";
export const API_REST_CUSTOMER_TIREBRANDS = urlBase + "/customers/customer_id/tirebrands";
export const API_REST_TIREBRANDS_CATALOGUE = urlBase + "/customers/customer_id/tirebrands/tirecatalogues";
export const API_REST_TIREBRANDS_TIRES = urlBase + "/customers/customer_id/tirebrands/tirebrand_id/tires";

// Tire Models
export const CUSTOMER_TIREMODELS = urlBase + "/customers/customer_id/tiremodels";
export const API_REST_TIREMODELS = urlBase + "/tirebrands/tirebrand_id/tiremodels";
export const API_REST_CUSTOMER_TIREMODELS = urlBase + "/customers/customer_id/tirebrands/tirebrand_id/tiremodels";
export const API_REST_TIREMODELS_TIRES = urlBase + "/customers/customer_id/tirebrands/tirebrand_id/tiremodels/tiremodel_id/tires";

// Tire Dimensions
export const CUSTOMER_TIREDIMENSIONS = urlBase + "/customers/customer_id/tiredimentions";
export const API_REST_TIREDIMENSIONS = urlBase + "/tiremodels/tiremodel_id/tiredimentions";
export const API_REST_CUSTOMER_TIREDIMENSIONS = urlBase + "/customers/customer_id/tiremodels/tiremodel_id/tiredimentions";

// Retread Brands
export const API_REST_RETREADBRANDS = urlBase + "/retreadbrands";
export const API_REST_RETREADBRANDS_EXCEL = urlBase + "/retreadbrands/export";
export const API_REST_CUSTOMER_RETREADBRANDS = urlBase + "/customers/customer_id/retreadbrands";
export const API_REST_CUSTOMER_RETREADBANDS = urlBase + "/customers/customer_id/retreadrands";
export const API_REST_RETREADBRANDS_CATALOGUE = urlBase + "/customers/customer_id/retreadbrands/retreadcatalogues";
export const API_REST_RETREADBRANDS_TIRES = urlBase + "/customers/customer_id/retreadbrands/retreadbrand_id/tires";

// Retread Models
export const API_REST_RETREADMODELS = urlBase + "/retreadbrands/retreadbrand_id/retreadbands";
export const API_REST_CUSTOMER_RETREADMODELS = urlBase + "/customers/customer_id/retreadbrands/retreadbrand_id/retreadbands";
export const API_REST_RETREADBANDS_TIRES = urlBase + "/customers/customer_id/retreadbrands/retreadbrand_id/retreadbands/retreadband_id/tires";

// Retread Dimensions
export const API_REST_RETREADDIMENSIONS = urlBase + "/retreadbands/retreadband_id/retreadimentions";
export const API_REST_CUSTOMER_RETREADDIMENSIONS = urlBase + "/customers/customer_id/retreadbands/retreadband_id/retreadimentions";

// Tire Catalogue
export const API_REST_TIRECATALOGUES = urlBase + "/customers/customer_id/tirecatalogues";
export const API_REST_TIRECATALOGUES_MIN_INFO = urlBase + "/customers/customer_id/tirecatalogues/min_info";
export const API_REST_TIRECATALOGUES_EXCEL = urlBase + "/customers/customer_id/tirecatalogues/export";

// Retread Catalogue
export const API_REST_RETREADCATALOGUES = urlBase + "/customers/customer_id/retreadcatalogues";
export const API_REST_RETREADCATALOGUES_MIN_INFO = urlBase + "/customers/customer_id/retreadcatalogues/min_info";
export const API_REST_RETREADCATALOGUES_EXCEL = urlBase + "/customers/customer_id/retreadcatalogues/export";

// Warehouses
export const API_REST_WAREHOUSES = urlBase + "/customers/customer_id/warehouses";

// Cities
export const API_REST_CITIES = urlBase + "/customers/customer_id/cities";

// Cost Centers
export const API_REST_COSTCENTERS = urlBase + "/customers/customer_id/costcenters";

// Vehicles Groups
export const API_REST_GROUPS = urlBase + "/customers/customer_id/vehiclegroups";
export const API_REST_GROUPS_ONE = urlBase + "/customers/customer_id/vehiclegroups?type_group=group1";
export const API_REST_GROUPS_TWO = urlBase + "/customers/customer_id/vehiclegroups?type_group=group2";

// Vehicles 
export const API_REST_VEHICLES = urlBase + "/customers/customer_id/vehicles";
export const API_REST_VEHICLES_MIN_INFO = urlBase + "/customers/customer_id/vehicles/min_info";
export const API_REST_VEHICLES_EXCEL = urlBase + "/customers/customer_id/vehicles/export";
export const API_REST_VEHICLE_HISTORY = urlBase + "/customers/customer_id/vehicles/vehicle_id/inspections";
export const API_REST_VEHICLE_WORK = urlBase + "/customers/customer_id/vehicles/vehicle_id/works";
export const API_REST_VEHICLES_QUERY = urlBase + "/customers/customer_id/vehicles/consult";

// Tires
export const API_REST_TIRES = urlBase + "/customers/customer_id/tires";
export const API_REST_TIRES_MIN_INFO = urlBase + "/customers/customer_id/tires/min_info";
export const API_REST_TIRES_EXCEL = urlBase + "/customers/customer_id/tires/export";
export const API_REST_TIRES_VEHICLE = urlBase + "/customers/customer_id/vehicles/vehicle_id/tires";
export const API_REST_TIRE_WORK = urlBase + "/customers/customer_id/tires/tire_id/works";
export const API_REST_TIRES_QUERY = urlBase + "/customers/customer_id/tires/consult";

//Tires Freeze
export const API_REST_TIRES_FREEZE = urlBase + "/tires/freeze";

// Measurements
export const API_REST_FUELS = urlBase + "/customers/customer_id/vehicles/vehicle_id/uploadfuels";
export const API_REST_FUELS_HISTORY = urlBase + "/customers/customer_id/vehicles/vehicle_id/uploadfuels/historics";
export const API_REST_HOURS = urlBase + "/customers/customer_id/vehicles/vehicle_id/hours";

// Inspections
export const API_REST_INSPECTIONS = urlBase + "/customers/customer_id/tires/tire_id/inspections";
export const API_REST_INSPECTIONS_NOVELTIES = urlBase + "/customers/customer_id/tires/tire_id/inspections/novelties";
export const API_REST_INSPECTIONS_PREASSURE = process.env.REACT_APP_API_PRESSURE;
export const API_REST_INSPECTIONS_HISTORY = urlBase + "/customers/customer_id/tires/tire_id/inspections/lasts";
export const API_REST_INSPECTIONS_MADRE = urlBase + "/customers/customer_id/tires/tire_id/inspections/madre_id";
export const API_REST_INSPECTIONS_PENDING = urlBase + "/customers/customer_id/vehicles/vehicle_id/inspections/stand_by";
export const API_REST_INSPECTIONS_PENDING_SCRAPS = urlBase + "/scrap/inspections/update_review"
export const API_REST_INSPECTIONS_PENDING_ALL = urlBase + "/customers/customer_id/inspections/stand_by";
export const API_REST_INSPECTIONS_PENDING_COUNT = urlBase + "/customers/customer_id/standby_inspections/count";
export const API_REST_INSPECTIONS_PENDING_WITHOUT = urlBase + "/customers/customer_id/inspections/without_kilometers"
export const API_REST_INSPECTIONS_PENDING_WITHOUT_COUNT = urlBase + "/customers/customer_id/inspections/without_kilometers/count"
export const API_REST_INSPECTIONS_PENDING_SCRAP = urlBase + "/customers/customer_id/inspections/stand_by"
export const API_REST_INSPECTIONS_TECHNICIAN = urlBase + "/customers/customer_id/inspections/technical_inspections"
export const API_REST_INSPECTIONS_TECHNICIAN_ANALYST = urlBase + "/customers/customer_id/inspections/uploaded_technitian_analist"
export const API_REST_INSPECTIONS_TECHNICIAN_ANALYST_DELETE = urlBase + "/customers/customer_id/inspections/technical_inspections_delete"
export const API_REST_INSPECTIONS_COMPARE = urlBase + "/customers/customer_id/inspections/inspections_compare";
export const API_REST_INSPECTIONS_TECHNICIAN_UPLOADED = urlBase + "/customers/customer_id/inspections/uploaded_technitian";
export const API_REST_INSPECTIONS_PENDING_LIST = urlBase + "/customers/customer_id/inspections/pending_list";
export const API_REST_INSPECTIONS_PENDING_LIST_ANALIST = urlBase + "/customers/customer_id/inspections/pending_list_analyst";
export const API_REST_INSPECTIONS_HISTORY_POSITION = urlBase + "/customers/customer_id/inspections_by_position/vehicle_id/position_id";
export const API_REST_INSPECTIONS_EXPORT_PDF = urlBase + "/inspection/simple/export/customer_id/vehicles/vehicle_id";


// Reports
export const API_REST_REPORT_URL = urlBase + "/get_iframe_url";

// Catalogues
export const API_REST_NOVELTIES_LIST = urlBase + "/noveltys/catalogues";
export const API_REST_RECORDS_LIST = urlBase + "/antecedents/catalogues";
export const API_REST_WASTES_LIST = urlBase + "/discards/catalogues";
export const API_REST_MOVEMENTS_LIST = urlBase + "/movements/catalogues";

// Novelties
export const API_REST_NOVELTIES = urlBase + "/customers/customer_id/noveltys";
export const API_REST_NOVELTIES_TIRE = urlBase + "/customers/customer_id/tires/tire_id/noveltys";
export const API_REST_NOVELTIES_PENDING = urlBase + "/customers/customer_id/noveltys/stand_by";

// Plans
export const API_REST_CHANGES_AXLE = urlBase + "/customers/customer_id/changes_per_axle";
export const API_REST_CHANGES_AXLE_VEHICLE = urlBase + "/customers/customer_id/changes_per_axle_vehicle";
export const API_REST_WORKSHEET = urlBase + "/customers/customer_id/worksheet";
export const API_REST_ROTATIONS_VEHICLE = urlBase + "/customers/customer_id/rotations_by_vehicles";
export const API_REST_ROTATIONS_VEHICLETYPE = urlBase + "/customers/customer_id/rotations_by_vehicle_type";
export const API_REST_ACTIVITY_SUMMARY = urlBase + "/customers/customer_id/activity_summary";
export const API_REST_REQUIRED_TIRES = urlBase + "/customers/customer_id/required_tires";
export const API_REST_REQUIRED_TIRES_VEHICLE = urlBase + "/customers/customer_id/tires_required_per_vehicle";
export const API_REST_LOWERING_TIRES = urlBase + "/customers/customer_id/tires_for_lowering";
export const API_REST_LOWERING_VEHICLE = urlBase + "/customers/customer_id/tires_to_be_lowered_per_vehicle";
export const API_REST_DEPTHS = urlBase + "/customers/customer_id/state_depths";
export const API_REST_DEPTHS_VEHICLE = urlBase + "/customers/customer_id/state_depths_by_vehicles";
export const API_REST_ALERTS_SUMMARY = urlBase + "/customers/customer_id/alerts_summary";
export const API_REST_NOVELTIES_SUMMARY = urlBase + "/customers/customer_id/summary_novelties";
export const API_REST_PLANS = urlBase + "/customers/customer_id/maintenances";
export const API_REST_PLANS_HISTORICS = urlBase + "/customers/customer_id/maintenances/historics";

// Plans Catalogues
export const API_REST_PLANS_CATALOGUES_ACTIVITIES = urlBase + "/catalogues/activities";
export const API_REST_PLANS_CATALOGUES_ALERTS = urlBase + "/catalogues/alerts";
export const API_REST_PLANS_CATALOGUES_LIVES = urlBase + "/catalogues/lives";
export const API_REST_PLANS_CATALOGUES_WASTAGES = urlBase + "/catalogues/wastages";

// Indicators
export const API_REST_INDICATORS_WASTAGES = urlBase + "/customers/customer_id/indicators/wastages_by_position";
export const API_REST_INDICATORS_WASTAGES_TIRE = urlBase + "/customers/customer_id/indicators/new/wastage_status";
export const API_REST_INDICATORS_WASTAGES_RETREAD = urlBase + "/customers/customer_id/indicators/retread/wastage_status";
export const API_REST_INDICATORS_STATE = urlBase + "/customers/customer_id/indicators/indicators_by_state";
export const API_REST_INDICATORS_TIRE = urlBase + "/customers/customer_id/indicators/new/axe_dimention";
export const API_REST_INDICATORS_RETREAD = urlBase + "/customers/customer_id/indicators/retread/axe_dimention";
export const API_REST_INDICATORS_KMMM = urlBase + "/customers/customer_id/indicators/km_mm_month";
export const API_REST_INDICATORS_CPK = urlBase + "/customers/customer_id/indicators/cpk_month";
export const API_REST_INDICATORS_CONSUMPTION = urlBase + "/customers/customer_id/indicators/month_consumption";
export const API_REST_INDICATORS_MM = urlBase + "/customers/customer_id/indicators/mm_consumption_month";
export const API_REST_INDICATORS_VEHICLE = urlBase + "/customers/customer_id/indicators/vehicle_mm_consumption";
export const API_REST_INDICATORS_HISTORY = urlBase + "/customers/customer_id/indicators/historic_consumption";

// Informs
export const API_REST_INFORMS_KMS = urlBase + "/customers/customer_id/informs/total_kms";
export const API_REST_INFORMS_FULLFILLMENT = urlBase + "/customers/customer_id/informs/fulfillment";
export const API_REST_INFORMS_PCTRETREAD = urlBase + "/customers/customer_id/informs/pct_retread";
export const API_REST_INFORMS_MMCOST = urlBase + "/customers/customer_id/informs/total_mm_cost";
export const API_REST_INFORMS_CPK = urlBase + "/customers/customer_id/informs/total_cpk";
export const API_REST_INFORMS_KMMM = urlBase + "/customers/customer_id/informs/total_km_mm";
export const API_REST_INFORMS_MONTHKM = urlBase + "/customers/customer_id/informs/month_km";
export const API_REST_INFORMS_TYPEFULLFILLMENT = urlBase + "/customers/customer_id/informs/type_fulfillment";
export const API_REST_INFORMS_MONTHFULLFILLMENT = urlBase + "/customers/customer_id/informs/month_fulfillment";
export const API_REST_INFORMS_COSTCENTERFULLFILLMENT = urlBase + "/customers/customer_id/informs/costcenter_fulfillment";
export const API_REST_INFORMS_AVAILABLEMM = urlBase + "/customers/customer_id/informs/available_mm";
export const API_REST_INFORMS_MOUNTED = urlBase + "/customers/customer_id/informs/mounted_indicators";
export const API_REST_INFORMS_DISMOUNTED = urlBase + "/customers/customer_id/informs/dismounted_indicators";
export const API_REST_INFORMS_TIRESINJECTION = urlBase + "/customers/customer_id/informs/tires_injection_new";
export const API_REST_INFORMS_TIRESINJECTIONRETREAD = urlBase + "/customers/customer_id/informs/tires_injection_retread";
export const API_REST_INFORMS_HISTORICCONSUMPTION = urlBase + "/customers/customer_id/indicators/historic_consumption";
export const API_REST_INFORMS_INDICATORSBYLIFE = urlBase + "/customers/customer_id/informs/indicators_by_life";
export const API_REST_INFORMS_HISTORICINVENTORYMM = urlBase + "/customers/customer_id/informs/historic_inventory_milimeters";
export const API_REST_INFORMS_INVENTORYUSEFULMM = urlBase + "/customers/customer_id/informs/inventory_useful_milimeters";
export const API_REST_INFORMS_UNMARKEDTIRES = urlBase + "/customers/customer_id/informs/unmarked_tires";
export const API_REST_INFORMS_ALERTS = urlBase + "/customers/customer_id/maintenances/alerts";

// Informs Insepections
export const API_REST_INFORMS_INSPECTIONS_VEHICLES = urlBase + "/customers/customer_id/informs/inspections/vehicles_without_inspections";
export const API_REST_INFORMS_INSPECTIONS_TIRESVEHICLES = urlBase + "/customers/customer_id/informs/inspections/tires_vehicles";
export const API_REST_INFORMS_INSPECTIONS_MONTHFULLFILLMENT = urlBase + "/customers/customer_id/informs/inspections/month_fulfillment";
export const API_REST_INFORMS_INSPECTIONS_FULLFILLMENT = urlBase + "/customers/customer_id/informs/inspections/fulfillment";

// Top10
export const API_REST_TOP10_OVERCOST = urlBase + "/customers/customer_id/informs/top/over_cost";
export const API_REST_TOP10_LESSPERFOMANCE = urlBase + "/customers/customer_id/informs/top/top_less_performance";
export const API_REST_TOP10_ACELERATEDWASTAGE = urlBase + "/customers/customer_id/informs/top/accelerated_wastage";

// Discards
export const API_REST_DISCARDS = urlBase + "/customers/customer_id/informs/dicards/discard_by_time";
export const API_REST_DISCARDS_TOTAL = urlBase + "/customers/customer_id/informs/dicards/total_discard";
export const API_REST_DISCARDS_BRAND = urlBase + "/customers/customer_id/informs/dicards/discard_by_brand";
export const API_REST_DISCARDS_CAUSE = urlBase + "/customers/customer_id/informs/dicards/discard_by_cause";
export const API_REST_DISCARDS_BOUNDED = urlBase + "/customers/customer_id/informs/dicards/discard_by_bounded";
export const API_REST_DISCARDS_SECTION = urlBase + "/customers/customer_id/informs/dicards/discard_by_section";
export const API_REST_DISCARDS_TIRES = urlBase + "/customers/customer_id/informs/dicards/discarded_tires";

// Warehouses
export const API_REST_INFORMS_WAREHOUSES = urlBase + "/customers/customer_id/informs/warehouses";

// Inventory
export const API_REST_INVENTORY_MM_MOUNTED = urlBase + "/customers/customer_id/informs/inventory_mounted_milimeters";
export const API_REST_INVENTORY_MM_CHANGE = urlBase + "/customers/customer_id/informs/change_milimeters";
export const API_REST_INVENTORY_MM_USEFUL = urlBase + "/customers/customer_id/informs/inventory_useful_milimeters";
export const API_REST_INVENTORY_MM_HISTORIC = urlBase + "/customers/customer_id/informs/historic_inventory_milimeters";
export const API_REST_INVENTORY_MM_HISTORIC_TIRE = urlBase + "/customers/customer_id/informs/new/historic_inventory_milimeters";
export const API_REST_INVENTORY_MM_HISTORIC_RETREAD = urlBase + "/customers/customer_id/informs/retread/historic_inventory_milimeters";
export const API_REST_INVENTORY_TIRES = urlBase + "/customers/customer_id/informs/tires_number";

// Statistic Tires
export const API_REST_STATS_TIRES_BRAND = urlBase + "/customers/customer_id/statistics/tires/new_tires_by_brand";
export const API_REST_STATS_TIRES_BRANDTOTAL = urlBase + "/customers/customer_id/statistics/tires/total_new_tires_by_brand";
export const API_REST_STATS_TIRES_MODEL = urlBase + "/customers/customer_id/statistics/tires/new_tires_by_model";
export const API_REST_STATS_TIRES_MODELTOTAL = urlBase + "/customers/customer_id/statistics/tires/total_new_tires_by_model";
export const API_REST_STATS_TIRES_BAND = urlBase + "/customers/customer_id/statistics/tires/retread_tires_by_band";
export const API_REST_STATS_TIRES_BANDTOTAL = urlBase + "/customers/customer_id/statistics/tires/total_retread_tires_by_band";
export const API_REST_STATS_TIRES_DIMENTION = urlBase + "/customers/customer_id/statistics/tires/tires_by_dimention";
export const API_REST_STATS_TIRES_DIMENTIONTOTAL = urlBase + "/customers/customer_id/statistics/tires/total_tires_by_dimention";
export const API_REST_STATS_TIRES_RETREADTOTALPERCENTAGE = urlBase + "/customers/customer_id/statistics/tires/total_retread_percentage";
export const API_REST_STATS_TIRES_RETREADTOTALPERCENTAGEHISTORIC = urlBase + "/customers/customer_id/statistics/tires/historic_total_retread_percentage";
export const API_REST_STATS_TIRES_TOTALLIFE = urlBase + "/customers/customer_id/statistics/tires/total_tires_by_life";
export const API_REST_STATS_TIRES_WASTAGECURVE = urlBase + "/customers/customer_id/statistics/tires/wastage_state_curve";
export const API_REST_STATS_TIRES_CURVE = urlBase + "/customers/customer_id/statistics/tires/state_curve";
export const API_REST_STATS_TIRES_TOTALCURVE = urlBase + "/customers/customer_id/statistics/tires/total_state_curve";
export const API_REST_STATS_TIRES_DIMENTIONCURVE = urlBase + "/customers/customer_id/statistics/tires/milimeter_state_curve_by_dimention";
export const API_REST_STATS_TIRES_KMMM = urlBase + "/customers/customer_id/statistics/tires/km_vs_mm";

// Statistic Vehicles
export const API_REST_STATS_VEHICLES_TRAVELEDKM = urlBase + "/customers/customer_id/statistics/vehicle/vehicles_by_traveled_km";
export const API_REST_STATS_VEHICLES_TYPE = urlBase + "/customers/customer_id/statistics/vehicle/vehicles_by_type";
export const API_REST_STATS_VEHICLES_TYPETOTAL = urlBase + "/customers/customer_id/statistics/vehicle/total_types";
export const API_REST_STATS_VEHICLES_BRAND = urlBase + "/customers/customer_id/statistics/vehicle/vehicles_by_brand";
export const API_REST_STATS_VEHICLES_BRANDTOTAL = urlBase + "/customers/customer_id/statistics/vehicle/total_brands";
export const API_REST_STATS_VEHICLES_LINE = urlBase + "/customers/customer_id/statistics/vehicle/vehicles_by_line";
export const API_REST_STATS_VEHICLES_LINETOTAL = urlBase + "/customers/customer_id/statistics/vehicle/total_lines";
export const API_REST_STATS_VEHICLES_CITY = urlBase + "/customers/customer_id/statistics/vehicle/vehicles_by_citie";
export const API_REST_STATS_VEHICLES_CYTYTOTAL = urlBase + "/customers/customer_id/statistics/vehicle/total_cities";
export const API_REST_STATS_VEHICLES_FIXEDFRONT = urlBase + "/customers/customer_id/statistics/vehicle/vehicles_by_fixed_front";
export const API_REST_STATS_VEHICLES_FIXEDFRONTTOTAL = urlBase + "/customers/customer_id/statistics/vehicle/total_fixed_fronts";
export const API_REST_STATS_VEHICLES_OPERATIONALFRONT = urlBase + "/customers/customer_id/statistics/vehicle/vehicles_by_operational_front";
export const API_REST_STATS_VEHICLES_OPERATIONALFRONTTOTAL = urlBase + "/customers/customer_id/statistics/vehicle/total_operational_fronts";
export const API_REST_STATS_VEHICLES_FUELEFFICIENY = urlBase + "/customers/customer_id/statistics/vehicle/month_fuel_efficiency";
export const API_REST_STATS_VEHICLES_FUELEFFICIENYVHICLE = urlBase + "/customers/customer_id/statistics/vehicle/month_fuel_efficiency_by_vehicle";
export const API_REST_STATS_VEHICLES_ACTIVE = urlBase + "/customers/customer_id/statistics/vehicle/total_active_vehicles";
export const API_REST_STATS_VEHICLES_INACTIVE = urlBase + "/customers/customer_id/statistics/vehicle/total_inactive_vehicles";
export const API_REST_STATS_VEHICLES_OPERABLE = urlBase + "/customers/customer_id/statistics/vehicle/total_operable_tires";

// Performances
export const API_REST_PERFORMANCES_0 = urlBase + "/customers/customer_id/performances/indicators_by_new_tire";
export const API_REST_PERFORMANCES_1 = urlBase + "/customers/customer_id/performances/indicators_by_retread_tire";
export const API_REST_PERFORMANCES_2 = urlBase + "/customers/customer_id/performances/better_tire_by_axe_cpk";
export const API_REST_PERFORMANCES_3 = urlBase + "/customers/customer_id/performances/better_tire_by_axe_km_mm";
export const API_REST_PERFORMANCES_4 = urlBase + "/customers/customer_id/performances/better_band_by_axe_cpk";
export const API_REST_PERFORMANCES_5 = urlBase + "/customers/customer_id/performances/better_band_by_axe_km_mm";
export const API_REST_PERFORMANCES_6 = urlBase + "/customers/customer_id/performances/indicators_by_life";
export const API_REST_PERFORMANCES_7 = urlBase + "/customers/customer_id/performances/indicators_by_life_tire";
export const API_REST_PERFORMANCES_8 = urlBase + "/customers/customer_id/performances/indicators_by_new_life";
export const API_REST_PERFORMANCES_9 = urlBase + "/customers/customer_id/performances/indicators_by_retread_life";

// MULTI USERS

export const API_REST_MULTI_USERS = urlBase + "/clients_user"
// export const API_REST_MULTI_USERS_GET = urlBase + "/clients_user/:user_id"

// MACROS
export const API_REST_MACRO_ACCOUNT_NEW = urlBase + "/macro-account"
export const API_REST_MACRO_ACCOUNT = urlBase + "/macro-account"
export const API_REST_MACRO_ACCOUNT_EDIT = urlBase + "/macro-account/macro_id"
export const API_REST_MACRO_ACCOUNT_DELETE = urlBase + "/macro-account/macro_id"
export const API_REST_MACRO_ACCOUNT_GET_USERS_MACRO = urlBase + "/user-macro-account/all/macro_id"
export const API_REST_MACRO_ACCOUNT_GET_USER_ONE = urlBase + "/user-macro-account/user/user_id"
export const API_REST_MACRO_ACCOUNT_POST_USERS_MACRO = urlBase + "/user-macro-account/all"
export const API_REST_MACRO_ACCOUNT_GET_CLIENTS_MACRO = urlBase + "/client-macro-account/all/macro_id"
export const API_REST_MACRO_ACCOUNT_GET_CLIENTS_MACRO_NEW = urlBase + "/customers/macro-account/macro_id"
export const API_REST_MACRO_ACCOUNT_POST_CLIENTS_MACRO = urlBase + "/client-macro-account/all"
export const API_REST_MACRO_ACCOUNT_DELETE_USERS_PER_MACRO = urlBase + "/user-macro-account/macro_id"
export const API_REST_MACRO_ACCOUNT_DELETE_CLIENTS_PER_MACRO = urlBase + "/client-macro-account/macro_id"

export const API_REST_MACRO_ROLE = urlBase + "/mmc-role/mmc-macro/role_id"

export const API_REST_MACRO_POST_ADMIN = urlBase + "/macro-account-admin"

export const API_REST_MACRO_MACRO_ACCOUNT_ROELS = urlBase + "/mmc-role/mmc-macro/macro_id"

export const API_REST_MACRO_MACRO_ACCOUNT_NEW = urlBase + "/mmc-account"
export const API_REST_MACRO_MACRO_ACCOUNT = urlBase + "/mmc-account"
export const API_REST_MACRO_MACRO_ACCOUNT_EDIT = urlBase + "/mmc-account/macro_id"
export const API_REST_MACRO_MACRO_ACCOUNT_DELETE = urlBase + "/mmc-account/macro_id"
export const API_REST_MACRO_MACRO_ACCOUNT_GET_USERS_MACRO = urlBase + "/user-macro-account/all/macro_id"
export const API_REST_MACRO_MACRO_ACCOUNT_GET_USER_ONE = urlBase + "/user-macro-macro-account/user/user_id"
export const API_REST_MACRO_MACRO_ACCOUNT_POST_USERS_MACRO = urlBase + "/user-macro-account/all"
export const API_REST_MACRO_MACRO_ACCOUNT_GET_CLIENTS_MACRO = urlBase + "/client-macro-account/all/macro_id"
export const API_REST_MACRO_MACRO_ACCOUNT_POST_CLIENTS_MACRO = urlBase + "/client-macro-account/all" 
