import React, { Component } from "react";

class OutTable extends Component {
  constructor(props) {
    super(props);

    let images = [];
    for(let i = 0; i < props.data.length; i++) {
      images.push([]);
    }

    this.state = {
      images: images
    };
  };

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = error => reject(error);
  });

  handleChangeImages = async (event, data, idx) => {
    event.persist();

    // Empty images urls
    let stateImages = [...this.state.images];
    stateImages[idx].length = 0;
    this.setState({ images: stateImages });

    // Empty base64
    data[idx][data[idx].length-1].length = 0;

    const images = Array.from(event.target.files);
    for(let i = 0; i < images.length; i++) {
      data[idx][data[idx].length-1].push({
        img: await this.toBase64(images[i])
      });
    }

    const fmtImages = [];
    for(let i = 0; i < images.length; i++) {
      fmtImages.push(URL.createObjectURL(images[i]));
    }

    stateImages = [...this.state.images];
    stateImages[idx] = fmtImages;
    this.setState({ images: stateImages });
  };

  getViewsImages = (idx) => {
    const imagesViews = [];
    for(let i = 0; i < this.state.images[idx].length; i++) {
      imagesViews.push(<div key={i}
        className="col-md-6">
        <div className="card mt-1">
          <div className="card-body">
            <img
              className="img-fluid"
              alt={"image"+i}
              src={this.state.images[idx][i]}
            />
          </div>
        </div>
      </div>);
    }
    return imagesViews;
  };

  render() {
    const {type, data, cols, done, notDone} = this.props;

    if(!!data && !!data[0] && type === "Registrar con descarte") {
      if(data[0].indexOf("fotos") < 0) {
        data[0].push("fotos");
        for(let i = 1; i < data.length; i++) {
          data[i].push([]);
        }
      }
    }
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <tbody>
            {
              data.map((r, idx) => {
                if(r.length > 0) {
                  return <tr
                    className=
                      {!!idx ?
                        (done.indexOf(idx-1) > -1 ?
                        "table-success" :
                        (notDone.indexOf(idx-1) > -1 ?
                        "table-danger" : "")) :
                      ""}
                      key={idx}>
                    <td>{!!idx ? idx : ""}</td>
                    {
                      cols.map(c => {
                        return <td key={c.key}>
                          { !!r[c.key] ? r[c.key].toString() : r[c.key] }
                        </td>
                      })
                    }
                    { type === "Registrar con descarte" ?
                        !!idx && idx > 0 ?
                          <td className="waste">
                            <div className="row">
                              <div className="col-md-12">
                                <input
                                  className="form-control waste"
                                  type="file"
                                  id="images"
                                  name="images"
                                  accept="image/jpeg"
                                  onChange={(e) =>
                                    this.handleChangeImages(e, data, idx)}
                                  multiple
                                />
                              </div>
                            </div>
                            <div className="row">
                              { this.getViewsImages(idx).map(view => view) }
                            </div>
                          </td> :
                          <td>Subir fotos del desecho</td> :
                      null
                    }
                  </tr>
                } else {
                  return null;
                }
              })
            }
          </tbody>
        </table>
      </div>
    );
  };
};

export default OutTable;
