import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsCostCenter from "../../../constants/actions/CostCenters";
import * as APIS from "../../../constants/apis";

import CostCenterDeleteItem from "./CostCenterDeleteItem";

function getCostCenters(ccenters, ccenter, t) {
  let ccentersReq = [];
  return dispatch => {
    return trackPromise(fetch(APIS.API_REST_COSTCENTERS.replace("customer_id",
                     localStorage.getItem("GlobalCustomerId")), {
                      headers:{
                        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                        "Accept-language": localStorage.getItem("LanguageSelected"),
                      } })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(t("alerts.cost-item-1"));
        }
        return response.json(); })
      .then(json => {
        ccentersReq = json.costcenters;
        dispatch(getCostCentersSuccess({
          ccenters  : ccentersReq,
          ccenter   : ccenter
        })); })
      .catch(error => {
        dispatch(getCostCentersError({
          ccenters  : ccenters,
          ccenter   : ccenter,
          error     : error.message
        }));
      }));
  };
};

export function getCostCentersSuccess(json) {
  return {
    type: constantsCostCenter.GET_CCENTERS_SUCCESS,
    json
  };
};

export function getCostCentersError(json) {
  return {
    type: constantsCostCenter.GET_REQUEST_ERROR,
    json
  };
};

class CostCentersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  };

  componentDidMount() {
    const { ccenters, ccenter } = this.props;
    this.props.getCostCenters(ccenters, ccenter, this.props.t);
  };

  getCostCentersList = (ccenters, authUser) => {
    if(!ccenters) return null;
    const { search } = this.state;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("client-general.client-item-1")}</th>
              <th scope="col">{this.props.t("client-general.client-item-2")}</th>
            </tr>
          </thead>
          <tbody>
          {
            ccenters.filter(ccenter => {
              return ccenter.name.toLowerCase().search(search) >= 0;
            }).map(ccenter => {
              return <tr key={ccenter.id}>
                <td>
                  {ccenter.name}
                </td>
                <td>
                 { !!authUser.permissions.edit ? 
                  <Link to={"/costcenters/edit/"+ccenter.id}
                        className="btn btn-primary">
                    <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                  </Link> : null }&nbsp;
                 { !!authUser.permissions.delete ? 
                  <CostCenterDeleteItem
                    t={this.props.t}
                    ccenterDelete={ccenter}
                  /> : null }
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    );
  };

  showSuccessDialog = (message) => {
    if(!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if(!!error && !!toast) {
      toast.error(error);
    }
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render () {
    const { ccenters, message, error } =this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("client-general.cost-list")}</h3>
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder={this.props.t("globals.search-input")}
                  onChange={this.onChangeSearch}
                />
              </div>
            </div>
            <br/>
                 { !!authUser.permissions.create ? 
            <Link to={"/costcenters/new"}
                  className="btn btn-primary"><i className="fa fa-plus"></i> {this.props.t("globals.register")}</Link> : null }
            <br/><br/>
            {this.getCostCentersList(ccenters, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  ccenters  : state => state.ccenters,
  ccenter   : state => state.ccenter,
  message   : state => state.message,
  error     : state => state.error
});

const mapDispatchToProps = { getCostCenters };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(CostCentersList));
