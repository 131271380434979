import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from 'react-promise-tracker';
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsBrand from "../../constants/actions/Brands";
import * as APIS from "../../constants/apis";
import BrandDeleteItem from "./BrandDeleteItem";

function getBrands(brands, brand, t) {
  return dispatch => {
    dispatch({ type: constantsBrand.GET_BRANDS_REQUEST });
    if (!localStorage.getItem("GlobalCustomerId")) return null;
    return trackPromise(fetch(APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.brands-item-1"));
        }
        return response.json();
      })
      .then(json => dispatch(getBrandsSuccess(json)))
      .catch(error => {
        dispatch(getBrandsError({
          brands: brands,
          brand: brand,
          error: error.message
        }));
      }));
  };
};

export function getBrandsSuccess(json) {
  return {
    type: constantsBrand.GET_BRANDS_SUCCESS,
    json
  };
};

export function getBrandsError(json) {
  return {
    type: constantsBrand.GET_REQUEST_ERROR,
    json
  };
};

class BrandsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  };

  componentDidMount() {
    this.props.getBrands(this.props.brands, this.props.brand, this.props.t);
  };

  getBrandsList = (brands, authUser) => {
    if (!brands) return null;

    const { search } = this.state;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("brands.brands-item-1")}</th>
              <th scope="col">{this.props.t("brands.brands-item-2")}</th>
            </tr>
          </thead>
          <tbody>
            {
              brands.filter(brand => {
                return brand.name.toLowerCase().search(search) >= 0;
              }).map(brand => {
                return <tr key={brand.id}>
                  <td>
                    {brand.name}
                  </td>
                  <td>
                    {!!authUser.permissions.edit ?
                      <Link to={"/brands/edit/" + brand.id}
                        className="btn btn-primary">
                        <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                  </Link> : null} &nbsp;
                 {!!authUser.permissions.delete ?
                      <BrandDeleteItem brandDelete={brand} t={this.props.t}/> : null}
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render() {
    const { brands, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("brands.brands-list")}</h3>
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder={this.props.t("globals.search-input")}
                  onChange={this.onChangeSearch}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                {!!authUser.permissions.create ?
                  <Link
                    to="/brands/new"
                    className="btn btn-primary">
                    <i className="fa fa-plus"></i> {this.props.t("globals.register")}
                </Link> : null}
              </div>
            </div>
            <br /><br />
            {this.getBrandsList(brands, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  brands: state => state.brands,
  brand: state => state.brand,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getBrands };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(BrandsList));
