import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsSchema from "../../constants/actions/Schemas";
import * as APIS from "../../constants/apis";

function deleteSchema(name, schemas, schema, t) {
  if (window.confirm(t("alerts.schemas-item-4"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_SCHEMAS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "DELETE"
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(t("alerts.schemas-item-5"));
          }
          return response.json();
        })
        .then(json => {
          dispatch(getReqSuccess(schemas, schema, json.message));
          return fetch(APIS.API_REST_SCHEMAS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
            .then(response => response.json())
            .then(json => {
              dispatch(getSchemasSuccess({
                schemas: json.vehicleschemas
              }));
            })
            .catch(error => {
              dispatch(getReqError(schemas, schema, error.toString()));
            });
        })
        .catch(error => {
          dispatch(getReqError(schemas, schema, error.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(getSchemasSuccess({
        schema: schema,
        schemas: schemas
      }));
    };
  }
};

export function getSchemasSuccess(json) {
  return {
    type: constantsSchema.GET_SCHEMAS_SUCCESS,
    json
  };
};

export function getReqSuccess(schemas, schema, message) {
  const json = {
    schemas: schemas,
    schema: schema,
    message: message
  };
  return {
    type: constantsSchema.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(schemas, schema, error) {
  const json = {
    schemas: schemas,
    schema: schema,
    error: error
  };
  return {
    type: constantsSchema.GET_REQUEST_ERROR,
    json
  };
};

class SchemaDeleteItem extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { schemas, schema, schemaDelete, deleteSchema } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteSchema(schemaDelete.id,
          schemas, schema, this.props.t)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({
  schemas: state => state.schemas,
  schema: state => state.schema
});

const mapDispatchToProps = { deleteSchema };

export default connect(structuredSelector, mapDispatchToProps)(SchemaDeleteItem);
