import moment from "moment";
import $ from "jquery";
import "moment/locale/es";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import API from "../../../constants/api";
import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";
import Pagination from "../../pagination";

import TireRow from "../Form/TireRow";
import { Toast } from "bootstrap";
import { Modal } from "react-bootstrap";
import api from "../../../constants/api";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const escapeRegexCharacters = (str) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => (
  <div>{suggestion.plate + " - " + suggestion.vehiclenumber}</div>
);

const ValidateViewOldExportPdf = [
  "w.herrera@ruedata.com",
  "a.romero@ruedata.com",
  "sofia@ruedata.com",
  "jorge@ruedata.com",
  "m.barragan@ruedata.com",
  "p.camacho@ruedata.com",
];

const INITIAL_STATE = {
  byVehicle: true,
  vehicle_number: "",
  filter_by_tire_type: "pending",
  vehicle: {},
  car_code: "",
  cars: [],
  tires: [],
  novelty: null,
  inspections: [],
  suggestions: [],
  date: "",
  technician: "",
  km: null,
  initKm: null,
  error: null,
  vehicleInactive: false,
  noveltysCount: 0,
  isNewKm: false,
  result: null,
  waste: [],
  novelties: [],
  records: [],
  warehouses: [],
  technicians: [],
  page: 1,
  limit: 20,
  valueTnc: "",
  suggestionsTnc: [],
  askSimpleReview: 1,
  reviewCount: 1,
  cities: [],
  modalReviewErrors: false,
  modalReviewDataErrors: [],
  vehicle_groups: [],
  cost_center: [],
  city_id: null,
  vehicle_group_id: null,
  cost_center_id: null,
  showFastWarehouse: false,
  positionsRow: [],
};

class Pending extends Component {
  constructor(props) {
    super(props);
    this.references = {};

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.getTechnicians();
    this.getWarehouses();
    this.getCatalogues();
    this.getVehicles();
    this.getTires();
    this.getInspectionsTire();
    this.getCities();
    this.getCostCenters();
    this.getVehicleGroups();
  }

  getTechnicians = () => {
    trackPromise(
      fetch(
        APIS.API_REST_TECHNICIANS.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ technicians: json.Users });
        })
        .catch((error) => {
          let err = error;
          if (valConnection.indexOf(error.toString()) > -1) {
            err = this.props.t("alerts.ins-one-item-28");
          }
          toast.error(err.toString());
          this.setState({
            error: err,
          });
        })
    );
  };

  getCities = () => {
    trackPromise(
      fetch(
        APIS.FILTER_CITIES.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ cities: json.cities });
        })
        .catch((error) => {
          let err = error;
          err = this.props.t("alerts.ins-one-item-28");
          toast.error(err.toString());
        })
    );
  };

  getCostCenters = () => {
    trackPromise(
      fetch(
        APIS.FILTER_COSTCENTERS.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ cost_center: json.costcenters });
        })
        .catch((error) => {
          let err = error;
          err = this.props.t("alerts.ins-one-item-28");
          toast.error(err.toString());
        })
    );
  };

  getVehicleGroups = () => {
    trackPromise(
      fetch(
        APIS.FILTER_VEHICLEGROUPS.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ vehicle_groups: json.vehiclegroups });
        })
        .catch((error) => {
          let err = error;
          err = this.props.t("alerts.ins-one-item-28");
          toast.error(err.toString());
        })
    );
  };

  getNovelty = (ins) => {
    return !!ins ? (
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th>{this.props.t("ins-one.ins-one-noveltie-22")}</th>
                  <th>{this.props.t("ins-one.ins-one-noveltie-23")}</th>
                  <th>{this.props.t("ins-one.ins-one-noveltie-24")}</th>
                  <th>{this.props.t("ins-one.ins-one-noveltie-25")}</th>
                  <th>{this.props.t("ins-one.ins-one-noveltie-32")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{ins.tiponovedad}</td>
                  <td>{ins.inspector}</td>
                  <td>
                    {moment
                      .utc(ins.fecha_subida)
                      .local()
                      .locale("es")
                      .format("LLL")}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      type="button"
                      data-toggle="collapse"
                      data-target={"#novedadesList"}
                      aria-expanded="false"
                      aria-controls={"novedadesList"}
                    >
                      <i className="fa fa-eye"></i>{" "}
                      {this.props.t("ins-one.ins-one-noveltie-27")}
                    </button>
                    <div className="collapse mt-3" id={"novedadesList"}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>
                              {this.props.t("ins-one.ins-one-noveltie-28")}
                            </th>
                            <th>
                              {this.props.t("ins-one.ins-one-noveltie-29")}
                            </th>
                            <th>
                              {this.props.t("ins-one.ins-one-noveltie-30")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ins.novedades.map((novedad, j) => {
                            return (
                              <tr key={"div" + j}>
                                <td>{novedad.motivo}</td>
                                <td>{novedad.posible_causa}</td>
                                <td>{novedad.codigo_causa}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <td>
                    {!!ins.urlfoto &&
                      ins.urlfoto.map((photo, j) => {
                        return (
                          <a
                            key={"a-" + j}
                            href={photo.replace(/'/g, "")}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="btn btn-primary"
                          >
                            <i className="fa fa-image"></i>{" "}
                            {this.props.t("ins-one.ins-one-noveltie-27")}
                          </a>
                        );
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
        </div>
      </div>
    ) : null;
  };

  showNoveltys = (id) => {
    trackPromise(
      fetch(
        `${
          APIS.API_REST_NOVELTIES_PENDING.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ) +
          "/" +
          id
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "GET",
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          let message = json.message;
          if (!message) {
            this.setState({ novelty: json });
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  };

  handleByVehicleChange = (event) => {
    var val = event.currentTarget.querySelector("input").value === "true";
    this.setState({ byVehicle: val });

    this.reChangeVehicle();
    // city_id: null, cost_center_id: null, vehicle_group_id: null
    // let options = document.querySelectorAll('#my_select option');
    // for (let i = 0, l = options.length; i < l; i++) {
    //     options[i].selected = options[i].defaultSelected;
    // }
  };

  reChangeVehicle = () => {
    if (this.state.byVehicle) {
      this.getVehicles();
    } else {
      this.getInspectionsTire();
    }
  };

  chooseKm(kilometers) {
    $("#last10Km").modal("hide");
    this.setState({ km: kilometers });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getOrCreateRef = (id) => {
    if (!this.references.hasOwnProperty(id)) {
      this.references[id] = React.createRef();
    }
    return this.references[id];
  };

  setAllDefault = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeDefault();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  setAllUnmount = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeUnmount();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  setAllMount = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeMount();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  setAllInspection = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeInspection();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  setAllRotation = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeVehicleRotation();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  setAllPreasure = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypePreasure();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  exportOldPdf = () => {
    const input = document.getElementById("divInspection");
    var Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(input.innerHTML);
    pwa.document.close();
    pwa.focus();
    pwa.print();
  };

  exportPdf = () => {
    if (localStorage.getItem("GlobalCustomerId")) {
      return trackPromise(
        fetch(
          APIS.API_REST_INSPECTIONS_EXPORT_PDF.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("vehicle_id", this.state.vehicle_number),
          {
            headers: {
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
          }
        )
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(this.props.t("alerts.retread-item-2"));
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `inspection.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            toast.success(this.props.t("alerts.query-item-6"));
          })
          .catch((error) => {
            toast.error(error.toString());
          })
      );
    }
    // const input = document.getElementById('divInspection');
    // var Pagelink = "about:blank";
    // var pwa = window.open(Pagelink, "_new");
    // pwa.document.open();
    // pwa.document.write(input.innerHTML);
    // pwa.document.close();
    // pwa.focus();
    // pwa.print();
    /*
    input.style.display = "block"
    html2Canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg');
        const imgWidth = 210; 
        const pageHeight = 295;  
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        const pdf = new jsPDF('p', 'mm');
        let position = 0;
        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save("hoja_inspeccion.pdf");
        input.style.display = "none"
      })
    ;
    */
  };

  setFastWarehouses = (name, id) => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setAllTypeWarehouse(name, id);
      }
    }
  };

  changeStatusPositions = (position, bool) => {
    const fill = this.state.positionsRow.filter(
      (item) => item.position === position
    )[0];
    fill.selected = bool;
    this.state.positionsRow.findIndex((elemento, indice) => {
      if (elemento.position === position) {
        this.state.positionsRow.splice(indice, 1, fill);
      }
    });
  };

  getAllByVehiclePositions = () => {
    let arr = [];
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        if (references[key].current.state.type === "vehicle_rotation") {
          arr.push(Number(key));
        }
      }
    }
    return arr;
  };

  getAllPositions = (objects) => {
    console.log(objects);
    let arr = [];
    for (let i = 0; i < objects.length; i++) {
      let position = objects[i].posicion_montaje;
      arr.push({
        position,
        selected: false,
      });
    }
    this.setState({ positionsRow: arr });
  };

  getTableResult = (objects, authUser, firebaseUser) => {
    return (
      <div>
        <br />
        {!!authUser.permissions.create ? (
          <div className="dropdown">
            <button
              className="btn btn-primary dropdown-toggle float-left"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-bolt"></i> {this.props.t("ins-one.ins-one-1")}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="#"
                onClick={this.setAllDefault}
              >
                {this.props.t("ins-one.ins-one-2")}
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={this.setAllUnmount}
              >
                {this.props.t("ins-one.ins-one-3")}
              </a>
              <a className="dropdown-item" href="#" onClick={this.setAllMount}>
                {this.props.t("ins-one.ins-one-4")}
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={this.setAllInspection}
              >
                {this.props.t("ins-one.ins-one-5")}
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={this.setAllRotation}
              >
                {this.props.t("selectors.vehicle_rotation")}
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={this.setAllPreasure}
              >
                {this.props.t("selectors.preasure_inspection")}
              </a>
            </div>
          </div>
        ) : null}
        <div className="float-right d-flex">
          {this.state.showFastWarehouse == true ? (
            <div className="dropdown">
              <button
                className="btn btn-primary dropdown-toggle mr-2"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-bolt"></i>{" "}
                {this.props.t("ins-one.ins-one-63")}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {this.state.warehouses.map((item) => {
                  if (item.name !== "llanta_nueva") {
                    return (
                      <a
                        onClick={() =>
                          this.setFastWarehouses(item.name, item.id)
                        }
                        className="dropdown-item"
                        href="#"
                      >
                        {item.name}
                      </a>
                    );
                  }
                })}
              </div>
            </div>
          ) : null}
          <button
            className={
              this.state.byVehicle ? "btn btn-primary text-white" : "hidden"
            }
            onClick={() => this.getTires()}
            type="button"
          >
            <i className="fa fa-sync-alt"></i>{" "}
            {this.props.t("ins-pending.ins-pending-list-3")}
          </button>
          &nbsp;
          <button
            onClick={this.exportPdf}
            className="btn btn-primary"
            type="button"
          >
            <i className="fa fa-file"></i> {this.props.t("ins-one.ins-one-6")}
          </button>
          {ValidateViewOldExportPdf.includes(authUser.email) ? (
            <button
              onClick={this.exportOldPdf}
              className="btn btn-primary ml-3"
              type="button"
            >
              <i className="fa fa-file"></i> Hoja Anterior
            </button>
          ) : null}
        </div>
        <br></br>
        <br></br>
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th>{this.props.t("ins-one.ins-one-7")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-8")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-9")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-10")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-11")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-67")}</th>
              <th></th>
              <th>{this.props.t("ins-one.ins-one-12")}</th>
            </tr>
          </thead>
          <tbody>
            {objects.map((object, index) => {
              return (
                <TireRow
                  t={this.props.t}
                  key={index}
                  resetState={this.state.resetState}
                  warehouses={this.state.warehouses}
                  records={this.state.records}
                  waste={this.state.waste}
                  novelties={this.state.novelties}
                  ref={this.getOrCreateRef("" + 1 * object.posicion_montaje)}
                  tires={this.state.tires}
                  object={object}
                  getAllPositions={this.getAllPositions}
                  kms={this.state.combustibles}
                  date={this.state.date}
                  km={this.state.km}
                  technician={this.state.technician}
                  authUser={authUser}
                  firebaseUser={firebaseUser}
                  getVehicles={this.getVehicles}
                  onSubmitCar={this.onSubmit}
                  positionsRow={this.state.positionsRow}
                  changeStatusPositions={this.changeStatusPositions}
                  getAllByVehiclePositions={this.getAllByVehiclePositions}
                  carCode={this.state.car_code}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  updateTires = (event) => {
    if (!!event) event.preventDefault();
    if (isInternetConnected(toast)) return;

    const vehicle_number = this.state.vehicle_number;

    let result;
    trackPromise(
      fetch(
        APIS.API_REST_TIRES_VEHICLE.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", vehicle_number),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          if (!!json && !!json.message) {
            if (json.message.indexOf("does not have tires") >= 0) {
              throw new Error(this.props.t("alerts.ins-one-item-56"));
            }
            if (json.message.indexOf("does not exists") >= 0) {
              throw new Error(this.props.t("alerts.ins-one-item-57"));
            }
          }
          const tagged = json.tires_by_vehicle.reduce(
            (r, e) => {
              if (e.posicion_montaje < 0) {
                r.n.push(e);
              } else {
                r.p.push(e);
              }
              return r;
            },
            { p: [], n: [] }
          );
          result = [
            ...tagged.p.sort((a, b) => a.posicion_montaje - b.posicion_montaje),
            ...tagged.n.sort((a, b) => b.posicion_montaje - a.posicion_montaje),
          ];
          this.setState({
            result: result,
          });
          return true;
        })
        .catch((error) => {
          let err = error;
          if (valConnection.indexOf(error.toString()) > -1) {
            err = this.props.t("alerts.ins-one-item-28");
          }
          toast.error(err.toString());
          this.setState({ error: err });
        })
    );
  };

  showModalReview = () => {
    const handleClose = () =>
      this.setState({ modalReviewErrors: false, modalReviewDataErrors: {} });
    let dataErrors = this.state.modalReviewDataErrors;

    let dataState = this.state.byVehicle
      ? this.state.inspections
      : this.state.inspectionsTire;

    const findTire = (tire_id) => {
      let tire = dataState.find((tir) => tir.id === tire_id);
      return tire.codigo;
    };

    return (
      <>
        <Modal
          show={this.state.modalReviewErrors}
          onHide={handleClose}
          dialogClassName="modal-sm-mc"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div style={{ overflow: "auto" }}>
              <table className="table table-striped table-bordered table-sm">
                <thead>
                  <tr>
                    <td>Codigo</td>
                    <td>Mensaje</td>
                    <td>Error</td>
                  </tr>
                </thead>
                <tbody>
                  {dataErrors.map((item) => (
                    <tr>
                      <td>{findTire(item.id)}</td>
                      <td>{item.resp.message}</td>
                      <td>{item.resp.data}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  reviewInspection = (
    vehicle_id,
    inspection_id,
    inspection,
    isTire,
    isMultiple
  ) => {
    let message = this.props.t("alerts.ins-one-item-58");
    if (isTire) {
      message = this.props.t("alerts.ins-one-item-59");
    }

    if (isMultiple) {
      let data = {
        reviews: inspection,
      };
      trackPromise(
        API.putReviewInspectionsAll(
          localStorage.getItem("GlobalCustomerId"),
          data
        )
          .then((res) => {
            if (res.errors.length > 0) {
              this.setState({
                modalReviewData: res.errores,
                modalReviewErrors: true,
              });
            }

            if (res.success.length > 0) {
              let insp;
              if (isTire) {
                insp = this.state.inspectionsTire;
              } else {
                insp = this.state.inspections;
              }
              for (let i = 0; i < res.success.length; i++) {
                let flt = insp.filter((tire) => tire.id !== res.success[i].id);
                if (flt) {
                  insp = flt;
                }
              }

              if (isTire) {
                this.setState({ inspectionsTire: insp });
              } else {
                this.setState({ inspections: insp });
              }
              toast.success(this.props.t("alerts.ins-one-item-60"));
            }
          })
          .catch((err) => {
            toast.error(err.toString());
          })
      );
      // const changeUrls = vehicle_id
      //   ? APIS.API_REST_INSPECTIONS_PENDING.replace(
      //       "customer_id",
      //       localStorage.getItem("GlobalCustomerId")
      //     ).replace("vehicle_id", vehicle_id) +
      //     "/" +
      //     inspection_id
      //   : APIS.API_REST_INSPECTIONS_PENDING_SCRAPS.replace(
      //       "inspections",
      //       inspection_id
      //     );
      // inspection.review = true;
      // inspection.fc_ultima_inspeccion = moment(inspection.fc_ultima_inspeccion)
      //   .utc()
      //   .format();
      // return trackPromise(
      //   fetch(changeUrls, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
      //       "Accept-language": localStorage.getItem("LanguageSelected"),
      //     },
      //     method: "PUT",
      //     body: JSON.stringify(inspection),
      //   })
      //     .then((response) => response.json())
      //     .then(() => {
      //       toast.success("Inspeccion revisada");
      //       document.getElementById(
      //         "inspeccion" + inspection_id
      //       ).style.display = "none";
      //       return fetch(
      //         APIS.API_REST_INSPECTIONS_PENDING_COUNT.replace(
      //           "customer_id",
      //           localStorage.getItem("GlobalCustomerId")
      //         ),
      //         {
      //           headers: {
      //             Authorization:
      //               "Basic " + localStorage.getItem("firebaseAuthToken"),
      //             "Accept-language": localStorage.getItem("LanguageSelected"),
      //           },
      //         }
      //       );
      //     })
      //     .then((response) => {
      //       return response.json();
      //     })
      //     .then((json) => {
      //       localStorage.setItem("GlobalPendingCount", json.count);
      //       document.getElementById("pendingCount").innerHTML =
      //         "" + json.count + "";
      //     })
      //     .catch((error) => {
      //       toast.error(error.toString());
      //     })
      // );
    } else {
      if (window.confirm(message)) {
        const changeUrls = vehicle_id
          ? APIS.API_REST_INSPECTIONS_PENDING.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            ).replace("vehicle_id", vehicle_id) +
            "/" +
            inspection_id
          : APIS.API_REST_INSPECTIONS_PENDING_SCRAPS.replace(
              "inspections",
              inspection_id
            );
        inspection.review = true;
        inspection.fc_ultima_inspeccion = moment(
          inspection.fc_ultima_inspeccion
        )
          .utc()
          .format();
        return trackPromise(
          fetch(changeUrls, {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
            method: "PUT",
            body: JSON.stringify(inspection),
          })
            .then((response) => response.json())
            .then(() => {
              toast.success(this.props.t("alerts.ins-one-item-60"));
              document.getElementById(
                "inspeccion" + inspection_id
              ).style.display = "none";
              return fetch(
                APIS.API_REST_INSPECTIONS_PENDING_COUNT.replace(
                  "customer_id",
                  localStorage.getItem("GlobalCustomerId")
                ),
                {
                  headers: {
                    Authorization:
                      "Basic " + localStorage.getItem("firebaseAuthToken"),
                    "Accept-language": localStorage.getItem("LanguageSelected"),
                  },
                }
              );
            })
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              localStorage.setItem("GlobalPendingCount", json.count);
              document.getElementById("pendingCount").innerHTML =
                "" + json.count + "";
            })
            .catch((error) => {
              toast.error(error.toString());
            })
        );
      }
    }
  };

  reviewMultipleInspection = (
    event,
    data,
    authUser,
    isTireMulti,
    isVehicleMulti
  ) => {
    if (data.length === 0)
      return toast.info(this.props.t("alerts.ins-one-item-61"));
    let message;

    if (isTireMulti && !isVehicleMulti) {
      message = this.props.t("alerts.ins-one-item-62");
      if (window.confirm(message)) {
        for (let i = 0; i < data.length; i++) {
          data[i].review = true;
        }
        this.reviewInspection(null, null, data, true, true);
      }
    } else {
      message = this.props.t("alerts.ins-one-item-63");
      if (window.confirm(message)) {
        for (let i = 0; i < data.length; i++) {
          data[i].review = true;
        }
        this.reviewInspection(null, null, data, false, true);
      }
    }
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getInspections = (inspections, next, previus, page, limit) => {
    const vehicle_number = this.state.vehicle_number;
    var str = "";
    if (page !== "0") {
      str = "?page=" + page + "&limit=" + limit;
    }
    return trackPromise(
      fetch(
        APIS.API_REST_INSPECTIONS_PENDING.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", vehicle_number) + str,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({
            previus: json.previus,
            next: json.next,
            total: json.total,
            inspections: json.tires,
          });
        })
        .catch((error) => {})
    );
  };

  getInspectionsList = (inspections, next, previus, authUser) => {
    const vehicle_number = this.state.vehicle_number;
    if (!inspections) return null;
    let pageP, pageN, str;
    if (!!previus) {
      str = previus;
      pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    if (!!next) {
      str = next;
      pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    let pages = [];
    let start, end;
    let totalPages = Math.ceil(this.state.total / this.state.limit);
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
      start = Math.max(1, this.state.page - 4);
      end = Math.min(totalPages, this.state.page + 5);

      if (start === 1) {
        end = 10;
      } else if (end === totalPages) {
        start = totalPages - 9;
      }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("ins-pending.ins-pending-14")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-1")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-2")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-3")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-4")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-5")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-6")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-7")}</th>
              <th scope="col">{this.props.t("multiple.ins-item-11")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-8")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-9")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-10")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-11")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-19")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-12")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-18")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-13")}</th>
            </tr>
          </thead>
          <tbody>
            {inspections.map((inspection) => {
              return (
                <tr id={"inspeccion" + inspection.id} key={inspection.id}>
                  <td>
                    {inspection.validations ? (
                      <div>
                        <button
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target={"#validations" + inspection.id}
                        >
                          {this.props.t("globals.errors")}
                        </button>
                        <div
                          className="modal fade"
                          id={"validations" + inspection.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="NoveltysLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title text-dark"
                                  id="NoveltysLabel"
                                >
                                  Motivo PTE
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Cerrar"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              {Object.entries(inspection.validations.resp).map(
                                (item) => {
                                  if (item[1][1] == false) {
                                    return (
                                      <p
                                        className="px-4 py-2"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {" "}
                                        * {item[1][0]}
                                      </p>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {!!inspection.novedades_id ? (
                      <div>
                        <button
                          onClick={() =>
                            this.showNoveltys(inspection.novedades_id)
                          }
                          data-toggle="modal"
                          data-target={"#novedades" + inspection.id}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-bullhorn"></i>{" "}
                          {this.props.t("ins-pending.ins-pending-15")}
                        </button>
                        <div
                          className="modal fade"
                          id={"novedades" + inspection.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="NoveltysLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title text-dark"
                                  id="NoveltysLabel"
                                >
                                  {this.props.t("ins-pending.ins-pending-15")}
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Cerrar"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              {this.getNovelty(this.state.novelty)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    &nbsp;
                    {!!authUser.permissions.edit ? (
                      <button
                        onClick={() =>
                          this.reviewInspection(
                            vehicle_number,
                            inspection.id,
                            inspection,
                            false,
                            false
                          )
                        }
                        className="btn btn-primary"
                      >
                        <i className="fa fa-check"></i>{" "}
                        {this.props.t("ins-pending.ins-pending-16")}
                      </button>
                    ) : null}
                    &nbsp;
                    {!!authUser.permissions.delete ? null : null}
                  </td>
                  <td>{inspection.posicion_montaje}</td>
                  <td>{inspection.codigo}</td>
                  <td>
                    {inspection.marca +
                      " " +
                      inspection.modelo +
                      " " +
                      inspection.dimension +
                      " " +
                      inspection.banda_reencauche}
                  </td>
                  <td>{inspection.ult_prof_centro_exterior}</td>
                  <td>{inspection.ult_prof_centro}</td>
                  <td>{inspection.ult_prof_interior}</td>
                  <td>{inspection.ult_presion}</td>
                  <td>
                    {inspection.observaciones.replace(
                      /\\|"}"}|{"{"|{"|"}|[{}]/g,
                      ""
                    )}
                  </td>
                  <td>{!!inspection.is_scrap ? "Si" : "No"}</td>
                  <td>{inspection.regrabada ? "Si" : "No"}</td>
                  <td>{inspection.kilometers}</td>
                  <td>{inspection.dot}</td>
                  <td>{inspection.order === null ? "" : inspection.order}</td>
                  <td>
                    {moment
                      .utc(inspection.fc_ultima_inspeccion)
                      .local()
                      .locale("es")
                      .format("LLL")}
                  </td>
                  <td>{inspection.is_bluetooth}</td>
                  <td>{inspection.tecnico}</td>
                </tr>
              );
            })}
          </tbody>
          {inspections.length > 0 ? (
            <Pagination
              colspan="10"
              pageP={pageP}
              pageN={pageN}
              page={this.state.page}
              limit={this.state.limit}
              links={links}
              submit={(page, limit) => {
                this.setState({ page: page, limit: limit }, () => {
                  this.getInspections(inspections, next, previus, page, limit);
                });
              }}
            />
          ) : null}
        </table>
      </div>
    );
  };

  getInspectionsTire = () => {
    let { city_id, cost_center_id, vehicle_group_id } = this.state;
    let str = "?except=null";

    if (city_id) {
      str += `&city_id=${city_id}`;
    }

    if (cost_center_id) {
      str += `&cost_center_id=${cost_center_id}`;
    }

    if (vehicle_group_id) {
      str += `&vehicle_group_id=${vehicle_group_id}`;
    }

    return trackPromise(
      fetch(
        APIS.API_REST_INSPECTIONS_PENDING_ALL.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ) + str,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({
            inspectionsTire: json.inspections,
          });
        })
        .catch((error) => {})
    );
  };

  showPendingByErrors = (validations) => {
    return (
      <Modal>
        <Modal.Header>
          <Modal.Title>Hola</Modal.Title>
        </Modal.Header>
      </Modal>
    );
  };

  getInspectionsTireList = (inspections) => {
    if (!inspections) return null;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("ins-pending.ins-pending-14")}</th>
              <th scope="col">
                {this.props.t("ins-pending.ins-pending-tire-1")}
              </th>
              <th scope="col">
                {this.props.t("ins-pending.ins-pending-tire-2")}
              </th>
              <th scope="col">
                {this.props.t("ins-pending.ins-pending-tire-3")}
              </th>
              <th scope="col">
                {this.props.t("ins-pending.ins-pending-tire-4")}
              </th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-3")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-4")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-5")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-6")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-7")}</th>
              <th scope="col">{this.props.t("multiple.ins-item-11")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-8")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-9")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-10")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-11")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-19")}</th>
              <th scope="col">
                {this.props.t("ins-pending.ins-pending-tire-5")}
              </th>
              <th scope="col">
                {this.props.t("ins-pending.ins-pending-tire-6")}
              </th>
              <th scope="col">
                {this.props.t("ins-pending.ins-pending-tire-7")}
              </th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-12")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-18")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-13")}</th>
            </tr>
          </thead>
          <tbody>
            {inspections.map((inspection) => {
              return (
                <tr id={"inspeccion" + inspection.id} key={inspection.id}>
                  <td>
                    {inspection.validations ? (
                      <div>
                        <button
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target={"#validations" + inspection.id}
                        >
                          {this.props.t("globals.errors")}
                        </button>
                        <div
                          className="modal fade"
                          id={"validations" + inspection.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="NoveltysLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title text-dark"
                                  id="NoveltysLabel"
                                >
                                  Motivo PTE
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Cerrar"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              {Object.entries(inspection.validations.resp).map(
                                (item) => {
                                  if (item[1][1] == false) {
                                    return (
                                      <p
                                        className="px-4 py-2"
                                        style={{ fontSize: "18px" }}
                                      >
                                        {" "}
                                        * {item[1][0]}
                                      </p>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {!!inspection.novedades_id ? (
                      <div>
                        <button
                          onClick={() =>
                            this.showNoveltys(inspection.novedades_id)
                          }
                          data-toggle="modal"
                          data-target={"#novedades" + inspection.id}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-bullhorn"></i>{" "}
                          {this.props.t("ins-pending.ins-pending-15")}
                        </button>
                        <div
                          className="modal fade"
                          id={"novedades" + inspection.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="NoveltysLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title text-dark"
                                  id="NoveltysLabel"
                                >
                                  {this.props.t("ins-pending.ins-pending-15")}
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Cerrar"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              {this.getNovelty(this.state.novelty)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    &nbsp;
                    <button
                      onClick={(event) => {
                        this.setState(
                          {
                            vehicle_number: inspection.vehicle_id,
                            car_code:
                              inspection.vehicle_plate +
                              " - " +
                              inspection.vehicle_number,
                            byVehicle: true,
                          },
                          () => {
                            this.onSubmit(event, false);
                          }
                        );
                      }}
                      className="btn btn-primary"
                    >
                      <i className="fa fa-wrench"></i>{" "}
                      {this.props.t("ins-pending.ins-pending-17")}
                    </button>
                    &nbsp;
                    <button
                      onClick={() =>
                        this.reviewInspection(
                          inspection.vehicle_id,
                          inspection.id,
                          inspection,
                          true,
                          false
                        )
                      }
                      className="btn btn-primary"
                    >
                      <i className="fa fa-check"></i>{" "}
                      {this.props.t("ins-pending.ins-pending-16")}
                    </button>
                    &nbsp;
                  </td>
                  <td>{inspection.vehicle_number}</td>
                  <td>{inspection.vehicle_plate}</td>
                  <td>{inspection.posicion_montaje}</td>
                  <td>{inspection.codigo}</td>
                  <td>
                    {inspection.marca +
                      " " +
                      inspection.modelo +
                      " " +
                      inspection.dimension +
                      " " +
                      inspection.banda_reencauche}
                  </td>
                  <td>{inspection.ult_prof_centro_exterior}</td>
                  <td>{inspection.ult_prof_centro}</td>
                  <td>{inspection.ult_prof_interior}</td>
                  <td>{inspection.ult_presion}</td>
                  <td>
                    {inspection.observaciones.replace(
                      /\\|"}"}|{"{"|{"|"}|[{}]/g,
                      ""
                    )}
                  </td>
                  <td>{!!inspection.is_scrap ? "Si" : "No"}</td>
                  <td>{inspection.regrabada ? "Si" : "No"}</td>
                  <td>{inspection.kilometers}</td>
                  <td>{inspection.dot}</td>
                  <td>{inspection.order === null ? "" : inspection.order}</td>
                  <td>{inspection.sinester}</td>
                  <td>{inspection.operator}</td>
                  <td>
                    {inspection.journey
                      ? "Si"
                      : inspection.journey == false
                      ? "No"
                      : ""}
                  </td>
                  <td>
                    {moment
                      .utc(inspection.fc_ultima_inspeccion)
                      .local()
                      .locale("es")
                      .format("LLL")}
                  </td>
                  <td>{inspection.is_bluetooth}</td>
                  <td>{inspection.tecnico}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  getVehicles = () => {
    this.setState({
      action: "",
      state: "",
      use: "",
      fields: [],
      cars: [],
    });

    let { city_id, cost_center_id, vehicle_group_id } = this.state;

    let str = "?stand_by=true";
    if (city_id) {
      str += `&city_id=${city_id}`;
    }

    if (cost_center_id) {
      str += `&cost_center_id=${cost_center_id}`;
    }

    if (vehicle_group_id) {
      str += `&vehicle_group_id=${vehicle_group_id}`;
    }

    trackPromise(
      fetch(
        APIS.API_REST_VEHICLES_MIN_INFO.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ) + str,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ cars: json.vehicles });
        })
        .catch((error) => {
          let err = error;
          if (valConnection.indexOf(error.toString()) > -1) {
            err = this.props.t("alerts.ins-one-item-28");
          }
          toast.error(err.toString());
          this.setState({
            error: err,
          });
        })
    );
  };

  getTires = () => {
    let { city_id, cost_center_id, vehicle_group_id } = this.state;
    let str = "?stand_by=true";

    if (city_id) {
      str += `&city_id=${city_id}`;
    }

    if (cost_center_id) {
      str += `&cost_center_id=${cost_center_id}`;
    }

    if (vehicle_group_id) {
      str += `&vehicle_group_id=${vehicle_group_id}`;
    }

    trackPromise(
      fetch(
        APIS.API_REST_TIRES_MIN_INFO.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ) + str,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ tires: json.tires });
        })
        .catch((error) => {
          let err = error;
          if (valConnection.indexOf(error.toString()) > -1) {
            err = this.props.t("alerts.ins-one-item-28");
          }
          toast.error(err.toString());
          this.setState({
            error: err,
          });
        })
    );
  };

  setDefaultValueCitie = () => {
    this.setState({ city_id: null });
    // city_id: null, cost_center_id: null, vehicle_group_id: null
    $("#select_city option").prop("selected", function () {
      return this.defaultSelected;
    });
  };

  searchFilterCities = (event) => {
    this.setState({ city_id: event.target.value });
    if (this.state.byVehicle) {
      setTimeout(() => {
        this.getVehicles();
        this.getTires();
      }, 100);
    } else {
      setTimeout(() => {
        this.getInspectionsTire();
      }, 100);
    }
  };

  searchFilterCost = (event) => {
    this.setState({ cost_center_id: event.target.value });
    if (this.state.byVehicle) {
      setTimeout(() => {
        this.getVehicles();
        this.getTires();
      }, 100);
    } else {
      setTimeout(() => {
        this.getInspectionsTire();
      }, 100);
    }
  };

  searchFilterGroup = (event) => {
    this.setState({ vehicle_group_id: event.target.value });
    if (this.state.byVehicle) {
      setTimeout(() => {
        this.getVehicles();
        this.getTires();
      }, 100);
    } else {
      setTimeout(() => {
        this.getInspectionsTire();
      }, 100);
    }
  };

  getWarehouses = () => {
    trackPromise(
      API.getWarehouses(localStorage.getItem("GlobalCustomerId"))
        .then((res) => {
          this.setState({ warehouses: res.bodegas });
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  };

  getCatalogues = () => {
    let records,
      waste,
      novelties = [];
    trackPromise(
      fetch(`${APIS.API_REST_RECORDS_LIST}`, {
        headers: {
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
      })
        .then((response) => response.json())
        .then((json) => (records = json.catalogo_antecedentes))
        .then(() =>
          fetch(`${APIS.API_REST_NOVELTIES_LIST}`, {
            headers: {
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
          })
        )
        .then((response) => response.json())
        .then((json) => (novelties = json.catalogo_novedades))
        .then(() =>
          fetch(`${APIS.API_REST_WASTES_LIST}`, {
            headers: {
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
          })
        )
        .then((response) => response.json())
        .then((json) => (waste = json.catalogo_desechos))
        .then(() => {
          this.setState({
            records: records,
            waste: waste,
            novelties: novelties,
          });
        })
        .catch((error) => {
          toast.error(this.props.t("alerts.ins-one-item-64") + error);
        })
    );
  };

  onSelectPlate = (value) => {
    this.setState({ vehicle_number: value });
  };

  onChangePlate = (event, { newValue }) => {
    this.setState({ vehicle_number: newValue });
  };

  onChangePlate = (event, { newValue }) => {
    if (typeof newValue === "object" && newValue !== null) {
      this.setState({
        vehicle_number: newValue.id,
        car_code: newValue.plate + " - " + newValue.vehiclenumber,
      });
    } else {
      let cars = this.state.cars;
      for (let i = 0; i < cars.length; i++) {
        if (
          cars[i].plate.toLowerCase().trim() === newValue.toLowerCase().trim()
        ) {
          this.setState({
            vehicle_number: cars[i].id,
            car_code: cars[i].plate + " - " + cars[i].vehiclenumber,
          });
          break;
        } else {
          this.setState({
            vehicle_number: 0,
            car_code: newValue,
          });
        }
      }
    }
  };

  onPlatesFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onPlatesClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {};

  getSuggestions = (value) => {
    let cars = this.state.cars;
    let fmtSuggestions = cars.filter((car) => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return (
        car.plate.toLowerCase().search(fmtValue) >= 0 ||
        car.vehiclenumber.toLowerCase().search(fmtValue) >= 0
      );
    });
    return fmtSuggestions.slice(0, 20);
  };

  getFuels = (event, page) => {
    if (!!event) event.preventDefault();
    var str = "";
    if (page !== "0" && !!page) {
      str = "?page=" + page + "&limit=20";
    } else {
      str = "?page=1&limit=20";
    }
    return trackPromise(
      fetch(
        `${
          APIS.API_REST_FUELS_HISTORY.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("vehicle_id", this.state.vehicle_number) + str
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.ins-one-item-7"));
          }
          return response.json();
        })
        .then((json) => {
          let pageP, pageN;
          if (!!json.previus) {
            str = json.previus;
            pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          if (!!json.next) {
            str = json.next;
            pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          this.setState({
            combustibles: json.combustibles,
            pageP2: pageP,
            pageN2: pageN,
          });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.ins-one-item-8"));
        })
    );
  };

  onSubmit = (event, isSameCar) => {
    if (!!event) event.preventDefault();
    if (isInternetConnected(toast)) return;
    this.setState({ positionsRow: [] });

    const vehicle_number = this.state.vehicle_number;
    trackPromise(
      fetch(
        `${APIS.API_REST_INSPECTIONS_PENDING.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", vehicle_number)}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw new Error(this.props.t("alerts.ins-one-item-66"));
          }
          this.setState({
            previus: json.previus,
            next: json.next,
            total: json.total,
            inspections: json.tires,
          });
        })
        .then(() => {
          let vehicle;
          trackPromise(
            fetch(
              `${APIS.API_REST_VEHICLES.replace(
                "customer_id",
                localStorage.getItem("GlobalCustomerId")
              )}/${vehicle_number}`,
              {
                headers: {
                  Authorization:
                    "Basic " + localStorage.getItem("firebaseAuthToken"),
                  "Accept-language": localStorage.getItem("LanguageSelected"),
                },
              }
            )
              .then((response) => response.json())
              .then((json) => {
                if (!!json.message) {
                  throw new Error(json.message);
                }
                vehicle = json;
                if (!isSameCar) {
                  this.setState({
                    worktype: json.worktype,
                    vehicle: vehicle,
                    date: "",
                    technician: "",
                    km: "",
                    initKm: "",
                    resetState: null,
                  });
                }
                return true;
              })
              .then(() => {
                return fetch(
                  APIS.API_REST_TIRES_VEHICLE.replace(
                    "customer_id",
                    localStorage.getItem("GlobalCustomerId")
                  ).replace("vehicle_id", vehicle_number),
                  {
                    headers: {
                      Authorization:
                        "Basic " + localStorage.getItem("firebaseAuthToken"),
                      "Accept-language":
                        localStorage.getItem("LanguageSelected"),
                    },
                  }
                );
              })
              .then((response) => response.json())
              .then((json) => {
                if (!!json && !!json.message) {
                  if (json.message.indexOf("does not have tires") >= 0) {
                    throw new Error(this.props.t("alerts.ins-one-item-67"));
                  }
                  if (json.message.indexOf("does not exists") >= 0) {
                    throw new Error(this.props.t("alerts.ins-one-item-68"));
                  }
                }
                if (json.vehicle_info.inactive) {
                  toast.warning(this.props.t("alerts.ins-one-item-69"));
                  this.setState({ vehicleInactive: true });
                } else {
                  this.setState({ vehicleInactive: false });
                }
                const tagged = json.tires_by_vehicle.reduce(
                  (r, e) => {
                    if (e.posicion_montaje < 0) {
                      r.n.push(e);
                    } else {
                      r.p.push(e);
                    }
                    return r;
                  },
                  { p: [], n: [] }
                );
                const result = [
                  ...tagged.p.sort(
                    (a, b) => a.posicion_montaje - b.posicion_montaje
                  ),
                  ...tagged.n.sort(
                    (a, b) => b.posicion_montaje - a.posicion_montaje
                  ),
                ];
                trackPromise(
                  fetch(
                    `${APIS.API_REST_FUELS_HISTORY.replace(
                      "customer_id",
                      localStorage.getItem("GlobalCustomerId")
                    ).replace("vehicle_id", vehicle.id)}`,
                    {
                      headers: {
                        Authorization:
                          "Basic " + localStorage.getItem("firebaseAuthToken"),
                        "Accept-language":
                          localStorage.getItem("LanguageSelected"),
                      },
                    }
                  )
                    .then((response) => response.json())
                    .then((json) => {
                      if (!!json.message) {
                        this.setState({
                          result: result,
                          //km     : 1*vehicle.fuel_km_info.kilometers,
                          initKm: 1 * vehicle.fuel_km_info.kilometers,
                        });
                        throw new Error(this.props.t("alerts.ins-one-item-70"));
                      }
                      let pageP, pageN, str;
                      if (!!json.previus) {
                        str = json.previus;
                        pageP = str.substring(
                          str.indexOf("?") + 6,
                          str.indexOf("&")
                        );
                      }
                      if (!!json.next) {
                        str = json.next;
                        pageN = str.substring(
                          str.indexOf("?") + 6,
                          str.indexOf("&")
                        );
                      }
                      this.setState({
                        result: result,
                        //km     : 1*vehicle.fuel_km_info.kilometers,
                        initKm: 1 * vehicle.fuel_km_info.kilometers,
                        combustibles: json.combustibles,
                        pageP2: pageP,
                        pageN2: pageN,
                      });

                      this.getAllPositions(result);
                    })
                    .catch((error) => {
                      let err = error;
                      if (valConnection.indexOf(error.toString()) > -1) {
                        err = this.props.t("alerts.ins-one-item-28");
                      }
                      toast.error(err.toString());
                    })
                );
              })
              .catch((error) => {
                let err = error;
                if (valConnection.indexOf(error.toString()) > -1) {
                  err = this.props.t("alerts.ins-one-item-28");
                }
                toast.error(err.toString());
                this.setState({ error: err });
              })
          );
        })
        .catch((error) => {
          let err = error;
          console.log(err);
          if (valConnection.indexOf(error.toString()) > -1) {
            err = this.props.t("alerts.ins-one-item-28");
          }
          toast.error(err.toString());
          this.setState({ error: err });
        })
    );
  };

  registerNewKm = (event) => {
    event.preventDefault();
    let kmsArr = this.state.combustibles;
    let data1 = {
      kilometers: document.getElementById("newKmInput").value,
      fecha_subida: moment().utc().format(),
      fecha_inspeccion: moment(document.getElementById("newDateInput").value)
        .utc()
        .format(),
    };
    fetch(
      APIS.API_REST_FUELS.replace(
        "customer_id",
        localStorage.getItem("GlobalCustomerId")
      ).replace("vehicle_id", this.state.vehicle_number),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "POST",
        body: JSON.stringify(data1),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (!!json.message) {
          toast.error(json.message);
          //throw new Error(json.message);
        } else {
          kmsArr.push({ ...data1, id: json.id });
          toast.success("Km registrado");
          this.setState({
            isNewKm: false,
            combustibles: kmsArr,
            km: document.getElementById("newKmInput").value,
          });
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        //throw new Error(error);
      });
  };

  onSubmitItems = async (event) => {
    event.preventDefault();
    let promises = [];
    let showAlert = true;
    let references = this.references;
    let arr = [];
    for (let id in references) {
      let reference = references[id];
      if (!!reference && !!reference.current) {
        let form = reference.current.form;
        if (!!form && !!form.current) {
          let inspection = form.current.state.inspection;
          inspection.send_request = true;
          if (!!inspection.codigo) {
            arr.push(inspection.codigo);
          }
        }
      }
    }
    let countErrors = 0;
    if (arr.every((num) => arr.indexOf(num) === arr.lastIndexOf(num))) {
      for (let id in references) {
        let reference = references[id];
        if (!!reference && !!reference.current) {
          let form = reference.current.form;
          if (!!form && !!form.current) {
            let inspection = form.current.state.inspection;

            let validation = form.current.areValidFields(inspection);
            if (validation.isValid) {
              if (inspection.type === "vehicle_rotation") {
                const parent = {
                  ...references[inspection.pos_final].current.form.current.state
                    .inspection,
                };
                parent.fc_ultima_inspeccion = moment(
                  parent.fc_ultima_inspeccion
                )
                  .utc()
                  .format();
                if (parent.parent_rotation) {
                  delete parent.parent_rotation;
                }
                inspection.parent_rotation = parent;
              }
              if (inspection.send_request) {
                if (showAlert) {
                  if (
                    !form.current.getKilometerId(
                      inspection.kilometers,
                      inspection.fc_ultima_inspeccion
                    )
                  ) {
                    showAlert = false;
                    if (window.confirm(this.props.t("alerts.ins-one-item-9"))) {
                      promises.push(
                        await form.current
                          .onSubmit(
                            event,
                            true,
                            true,
                            this.setInitDataInspections
                          )
                          .then(function (result) {
                            return result;
                          })
                          .catch(function (error) {
                            countErrors++;
                          })
                      );
                    } else {
                      countErrors++;
                      break;
                    }
                  } else {
                    promises.push(
                      await form.current
                        .onSubmit(
                          event,
                          true,
                          true,
                          this.setInitDataInspections
                        )
                        .then(function (result) {
                          return result;
                        })
                        .catch(function (error) {
                          countErrors++;
                        })
                    );
                  }
                } else {
                  promises.push(
                    await form.current
                      .onSubmit(event, true, true, this.setInitDataInspections)
                      .then(function (result) {
                        return result;
                      })
                      .catch(function (error) {
                        countErrors++;
                      })
                  );
                }
              }
            } else {
              countErrors++;
              toast.info(
                this.props.t("alerts.ins-one-item-10") +
                  inspection.posicion_montaje +
                  this.props.t("alerts.ins-one-item-11") +
                  validation.field +
                  this.props.t("alerts.ins-one-item-12")
              );
            }
          }
        }
      }
      return await Promise.all(promises).then(() => {
        if (countErrors === 0) {
          this.onSubmit(event, true);
        }
      });
    } else {
      toast.info(this.props.t("alerts.ins-one-item-13"));
    }
  };

  generateExcel(isByVehicle) {
    let { city_id, cost_center_id, vehicle_group_id } = this.state;
    var str;
    if (isByVehicle) {
      str = `${
        APIS.API_REST_INSPECTIONS_PENDING.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", this.state.vehicle_number) + "?export=true"
      }`;
    } else {
      str =
        APIS.API_REST_INSPECTIONS_PENDING_ALL.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ) + "?export=true";
    }

    if (city_id) {
      str += `&city_id=${city_id}`;
    }

    if (cost_center_id) {
      str += `&cost_center_id=${cost_center_id}`;
    }

    if (vehicle_group_id) {
      str += `&vehicle_group_id=${vehicle_group_id}`;
    }

    return trackPromise(
      fetch(str, {
        headers: {
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.ins-one-item-73"));
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.props.t("alerts.ins-one-item-74")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success(this.props.t("alerts.ins-one-item-75"));
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  }

  getSuggestionsTnc = (value) => {
    let tnc = this.state.technicians;
    let reTnc = tnc.filter((item) => {
      const tncValue = escapeRegexCharacters(value.toLowerCase().trim());
      return (
        item.name.toLowerCase().search(tncValue) >= 0 ||
        item.email.toLowerCase().search(tncValue) >= 0
      );
    });
    return reTnc;
  };

  getSuggestionValueTnc = (suggestion) => suggestion.name;

  renderSuggestionTnc = (suggestion) => (
    <div>
      <p>
        {suggestion.name}: {suggestion.email}
      </p>
    </div>
  );

  onChangeTnc = (event, { newValue }) => {
    this.setState({
      technician: newValue,
    });
  };

  onSuggestionsFetchRequestedTnc = ({ value }) => {
    this.setState({
      suggestionsTnc: this.getSuggestionsTnc(value),
    });
  };

  onSuggestionsClearRequestedTnc = () => {
    this.setState({
      suggestionsTnc: [],
    });
  };

  render() {
    const {
      vehicle_number,
      car_code,
      inspections,
      inspectionsTire,
      result,
      suggestions,
      next,
      previus,
      total,
      combustibles,
      vehicle,
      technician,
      suggestionsTnc,
      cities,
      cost_center,
      vehicle_groups,
      byVehicle,
    } = this.state;

    let plateProps = {
      placeholder: this.props.t("alerts.ins-one-item-76"),
      value: car_code,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "1%",
        marginBottom: 0,
      },
      onChange: this.onChangePlate,
    };

    const inputProps = {
      placeholder: this.props.t("alerts.ins-one-item-77"),
      value: technician,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "100%",
        marginBottom: 0,
      },
      onChange: this.onChangeTnc,
    };

    let isInvalidCarCode = !vehicle_number;
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("ins-pending.ins-pending-list-1")}</h3>
              </div>
              <div className="col-md-4 pull-right">
                <button
                  className={
                    byVehicle ? "btn btn-primary text-white" : "hidden"
                  }
                  onClick={() => this.getVehicles()}
                  type="button"
                >
                  <i className="fa fa-sync-alt"></i>{" "}
                  {this.props.t("ins-pending.ins-pending-list-2")}
                </button>
                &nbsp;
                <button
                  className={
                    byVehicle ? "hidden" : "btn btn-primary text-white"
                  }
                  onClick={() =>
                    byVehicle ? this.getTires() : this.getInspectionsTire()
                  }
                  type="button"
                >
                  <i className="fa fa-sync-alt"></i>{" "}
                  {this.props.t("ins-pending.ins-pending-list-3")}
                </button>
              </div>
            </div>
            <br></br>
            <div className="form-group">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className="mr-4">
                  <div className="d-flex">
                    <label className="mr-2">
                      {this.props.t("ins-pending.ins-pending-list-52")}
                    </label>
                    <select
                      className="form-control"
                      id="select_city"
                      onChange={this.searchFilterCities}
                    >
                      <option value={null}></option>
                      {cities &&
                        cities.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="mr-4">
                  <div className="d-flex">
                    <label className="mr-2">
                      {this.props.t("ins-pending.ins-pending-list-53")}
                    </label>
                    <select
                      className="form-control"
                      id="my_select"
                      onChange={this.searchFilterCost}
                    >
                      <option value={null}></option>
                      {cost_center &&
                        cost_center.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="">
                  <div className="d-flex">
                    <label className="mr-2">
                      {this.props.t("ins-pending.ins-pending-list-54")}
                    </label>
                    <select
                      className="form-control"
                      id="my_select"
                      onChange={this.searchFilterGroup}
                      value={this.state.vehicle_group_id}
                    >
                      <option value={null} selected disabled></option>
                      <option value={null}></option>
                      {vehicle_groups &&
                        vehicle_groups.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <br></br>
            <br></br>
            <div className="row mt-3 mb-3">
              <div className="col-sm-12 text-center">
                <div
                  className="btn-group btn-group-toggle"
                  data-toggle="buttons"
                >
                  <label
                    className="btn btn-primary active"
                    onClick={this.handleByVehicleChange}
                  >
                    <input
                      type="radio"
                      name="byVehicle"
                      value={true}
                      autoComplete="off"
                    />
                    <i className="fa fa-car"></i>{" "}
                    {this.props.t("ins-pending.ins-pending-list-4")}
                  </label>
                  <label
                    className="btn btn-primary"
                    onClick={this.handleByVehicleChange}
                  >
                    <input
                      type="radio"
                      name="byVehicle"
                      value={false}
                      autoComplete="off"
                    />
                    <i className="fa fa-bullseye"></i>{" "}
                    {this.props.t("ins-pending.ins-pending-list-5")}
                  </label>
                </div>
              </div>
            </div>
            <div className={this.state.byVehicle ? "" : "hidden"}>
              <form onSubmit={(event) => this.onSubmit(event, false)}>
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="inputCarCode">
                      {this.props.t("ins-pending.ins-pending-list-6")}
                    </label>
                  </div>
                  <div className="col-sm-10 input-group mb-3">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onPlatesFetchRequested}
                      onSuggestionsClearRequested={this.onPlatesClearRequested}
                      onSuggestionSelected={this.onSuggestionSelected}
                      shouldRenderSuggestions={() => true}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={plateProps}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary text-white"
                        type="submit"
                        disabled={isInvalidCarCode}
                      >
                        <i className="fa fa-search"></i>{" "}
                        {this.props.t("ins-pending.ins-pending-list-7")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <p>
                    {this.props.t("ins-pending.ins-pending-list-8")}{" "}
                    <b>{!!total ? total : 0}</b>
                  </p>
                </div>
              </div>
              <br />
              <div className="d-flex justify-content-between">
                <div className="row">
                  <div className="col-md-12">
                    {!!this.state.vehicle_number ? (
                      <button
                        disabled=""
                        className="btn btn-success"
                        onClick={(e) => this.generateExcel(true)}
                      >
                        <i className="fa fa-file-export"></i>{" "}
                        {this.props.t("globals.exports")}
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {!!this.state.vehicle_number ? (
                      <button
                        className="btn btn-primary"
                        onClick={(e) =>
                          this.reviewMultipleInspection(
                            e,
                            inspections,
                            authUser,
                            false,
                            true
                          )
                        }
                      >
                        {this.props.t("ins-pending.ins-pending-list-9")}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <br />
              {this.getInspectionsList(inspections, next, previus, authUser)}
              <br />
              <br />
              {!!result && result.length > 0 ? (
                <form>
                  {!!this.state.vehicleInactive ? (
                    <div className="alert alert-warning" role="alert">
                      {this.props.t("ins-pending.ins-pending-list-10")}
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row form-group">
                        <div className="col-sm-2">
                          <label htmlFor="inputCarKm">
                            {this.props.t("ins-pending.ins-pending-list-11")}
                          </label>
                        </div>
                        <div className="col-sm-10 input-group">
                          <input
                            name="km"
                            type="number"
                            id="inputCarKm"
                            className="form-control"
                            value={this.state.km}
                            min={this.state.initKm}
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-primary text-white"
                              type="button"
                              data-toggle="modal"
                              data-target={"#last10Km"}
                            >
                              <i className="fa fa-chart-line"></i>{" "}
                              {this.props.t("ins-pending.ins-pending-list-12")}
                            </button>
                            <div
                              className="modal fade"
                              id="last10Km"
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="last10KmLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      id="last10KmLabel"
                                      className="modal-title text-dark"
                                    >
                                      {this.props.t(
                                        "ins-pending.ins-pending-list-13"
                                      )}
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Cerrar"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body text-dark">
                                    {this.state.isNewKm === false ? (
                                      <div>
                                        <button
                                          type="button"
                                          onClick={(e) =>
                                            this.setState({ isNewKm: true })
                                          }
                                          className="btn btn-primary float-right"
                                        >
                                          {this.props.t(
                                            "ins-pending.ins-pending-list-14"
                                          )}
                                        </button>
                                        <table className="table table-striped mb-0">
                                          <thead>
                                            <tr>
                                              <th>
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-15"
                                                )}
                                              </th>
                                              <th>
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-16"
                                                )}
                                              </th>
                                              <th>
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-17"
                                                )}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {combustibles.map((fuel, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td>{fuel.kilometers}</td>
                                                  <td>
                                                    {moment
                                                      .utc(
                                                        fuel.fecha_inspeccion
                                                      )
                                                      .local()
                                                      .locale("es")
                                                      .format("LLL")}
                                                  </td>
                                                  <td>
                                                    <a
                                                      href="#"
                                                      className="btn btn-primary"
                                                      onClick={(e) =>
                                                        this.chooseKm(
                                                          fuel.kilometers
                                                        )
                                                      }
                                                    >
                                                      <i className="fa fa-link"></i>{" "}
                                                      {this.props.t(
                                                        "ins-pending.ins-pending-list-12"
                                                      )}
                                                    </a>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                        <div className="d-flex justify-content-center">
                                          <div className="m-3">
                                            {!!this.state.pageP2 ? (
                                              <button
                                                className="btn btn-primary"
                                                onClick={(e) =>
                                                  this.getFuels(
                                                    e,
                                                    this.state.pageP2
                                                  )
                                                }
                                              >
                                                <i className="fa fa-arrow-left"></i>
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-primary"
                                                disabled
                                              >
                                                <i className="fa fa-arrow-left"></i>
                                              </button>
                                            )}
                                          </div>
                                          <div className="m-3">
                                            {!!this.state.pageN2 ? (
                                              <button
                                                className="btn btn-primary"
                                                onClick={(e) =>
                                                  this.getFuels(
                                                    e,
                                                    this.state.pageN2
                                                  )
                                                }
                                              >
                                                <i className="fa fa-arrow-right"></i>
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-primary"
                                                disabled
                                              >
                                                <i className="fa fa-arrow-right"></i>
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <button
                                          type="button"
                                          onClick={(e) =>
                                            this.setState({ isNewKm: false })
                                          }
                                          className="btn btn-primary float-right"
                                        >
                                          {this.props.t(
                                            "ins-pending.ins-pending-list-18"
                                          )}
                                        </button>
                                        <br />
                                        <br />
                                        <fieldset>
                                          <div className="row form-group">
                                            <div className="col-sm-2">
                                              <label htmlFor="newKmInput">
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-19"
                                                )}
                                              </label>
                                            </div>
                                            <div className="col-sm-10">
                                              <input
                                                name="km"
                                                type="text"
                                                id="newKmInput"
                                                className="form-control"
                                                placeholder="Nuevo Km"
                                              />
                                            </div>
                                          </div>
                                          <div className="row form-group">
                                            <div className="col-sm-2">
                                              <label htmlFor="newDateInput">
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-20"
                                                )}
                                              </label>
                                            </div>
                                            <div className="col-sm-10">
                                              <input
                                                name="date"
                                                type="datetime-local"
                                                id="newDateInput"
                                                className="form-control"
                                                placeholder="Fecha de revisión"
                                              />
                                            </div>
                                          </div>
                                          <button
                                            type="button"
                                            onClick={(e) =>
                                              this.registerNewKm(e)
                                            }
                                            className="btn btn-primary float-right"
                                          >
                                            {this.props.t(
                                              "ins-pending.ins-pending-list-21"
                                            )}
                                          </button>
                                        </fieldset>
                                      </div>
                                    )}
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      data-dismiss="modal"
                                      aria-label="Cerrar"
                                    >
                                      {this.props.t("globals.close")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-2">
                          <label htmlFor="inputCarDate">
                            {this.props.t("ins-pending.ins-pending-list-22")}
                          </label>
                        </div>
                        <div className="col-sm-10">
                          <input
                            name="date"
                            type="datetime-local"
                            id="inputCarDate"
                            className="form-control"
                            value={this.state.date}
                            onChange={this.onChange}
                            placeholder="Fecha de revisión"
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-2">
                          <label htmlFor="inputCarTechnician">
                            {this.props.t("ins-pending.ins-pending-list-23")}
                          </label>
                        </div>
                        <div className="col-sm-12">
                          <Autosuggest
                            suggestions={suggestionsTnc}
                            onSuggestionsFetchRequested={
                              this.onSuggestionsFetchRequestedTnc
                            }
                            onSuggestionsClearRequested={
                              this.onSuggestionsClearRequestedTnc
                            }
                            shouldRenderSuggestions={() => true}
                            getSuggestionValue={this.getSuggestionValueTnc}
                            renderSuggestion={this.renderSuggestionTnc}
                            inputProps={inputProps}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-24")}
                            </th>
                            <td>{vehicle.centrocosto}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-25")}
                            </th>
                            <td>{vehicle.schema}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-26")}
                            </th>
                            <td>{vehicle.ciudad}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-27")}
                            </th>
                            <td>{vehicle.vehiclebrand}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-28")}
                            </th>
                            <td>{vehicle.fuel}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-29")}
                            </th>
                            <td>{vehicle.vehiclenumber}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-30")}
                            </th>
                            <td>{vehicle.group}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-31")}
                            </th>
                            <td>{vehicle.vehicletype}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-32")}
                            </th>
                            <td>{vehicle.linemodel}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-33")}
                            </th>
                            <td>{vehicle.year}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              ) : null}
              {!!result && result.length > 0 ? (
                <div className="mt-5">
                  <div id="divInspection" style={{ display: "none" }}>
                    <table
                      style={{ "font-size": "10 px; important" }}
                      border="0"
                      width="100%"
                    >
                      <tr>
                        <td width="30%">
                          <center>
                            <img src="/images/logo.png" width="220" />
                          </center>
                          <center>
                            <h2>
                              {this.props.t("ins-pending.ins-pending-list-34")}:{" "}
                              {localStorage.getItem("GlobalCustomerName")}
                            </h2>
                          </center>
                        </td>
                        <td width="70%">
                          <center>
                            <h3>
                              {this.props.t("ins-pending.ins-pending-list-35")}
                            </h3>
                          </center>
                          <table border="1" width="100%">
                            <tbody>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-tire-2"
                                  )}
                                </th>
                                <td>{vehicle.plate}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-tire-1"
                                  )}
                                </th>
                                <td>{vehicle.vehiclenumber}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-25"
                                  )}
                                </th>
                                <td>{vehicle.schema}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-28"
                                  )}
                                </th>
                                <td>{vehicle.fuel}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-27"
                                  )}
                                </th>
                                <td>{vehicle.vehiclebrand}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-32"
                                  )}
                                </th>
                                <td>{vehicle.linemodel}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-33"
                                  )}
                                </th>
                                <td>{vehicle.year}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-31"
                                  )}
                                </th>
                                <td>{vehicle.vehicletype}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-24"
                                  )}
                                </th>
                                <td>{vehicle.centrocosto}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-26"
                                  )}
                                </th>
                                <td>{vehicle.ciudad}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-30"
                                  )}
                                </th>
                                <td>{vehicle.group}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-36"
                                  )}
                                </th>
                                <td>{vehicle.spares}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <center>
                      <h3>{this.props.t("ins-pending.ins-pending-list-37")}</h3>
                    </center>
                    <table border="1" width="100%">
                      <thead>
                        <tr>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-38")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-39")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-40")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-41")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-42")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-43")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-44")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-45")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-46")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {result.map((object, index) => {
                          return (
                            <tr>
                              <td>{object.posicion_montaje}</td>
                              <td>{object.codigo}</td>
                              <td>
                                {!!object.marca
                                  ? object.marca +
                                    " " +
                                    object.modelo +
                                    " " +
                                    object.dimension
                                  : ""}
                                <b>
                                  {typeof object.retreadband == "undefined"
                                    ? ""
                                    : object.retreadband}{" "}
                                  {typeof object.retreadband == "undefined"
                                    ? ""
                                    : object.retreadimention}{" "}
                                  {/* {!!object.codigo
                              ? !!object.retreadbrand &&
                                !!object.retreadband &&
                                !!object.retreadimention
                                ? "R"
                                : "N"
                              : ""} */}
                                  {/* {object.vida} {!!object.regrabada ? "RS" : null} */}
                                  {!!object.codigo
                                    ? object.regrabada
                                      ? "RG"
                                      : !!object.retreadband &&
                                        !!object.retreadbrand &&
                                        !!object.retreadimention
                                      ? "R"
                                      : "N"
                                    : ""}
                                  {object.regrabada
                                    ? object.regrabado
                                    : object.vida}
                                </b>
                              </td>
                              <td>
                                {moment
                                  .utc(object.fc_ultima_inspeccion)
                                  .local()
                                  .locale("es")
                                  .format("DD[/]MM[/]YY")}
                              </td>
                              <td>
                                <center>
                                  {object.ult_prof_centro_exterior}
                                </center>
                              </td>
                              <td>
                                <center>{object.ult_prof_centro}</center>
                              </td>
                              <td>
                                <center>{object.ult_prof_interior}</center>
                              </td>
                              <td>
                                {!!object.ult_presion ? object.ult_presion : ""}
                              </td>
                              <td>{object.kilometers}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colspan="9">
                            {this.props.t("ins-pending.ins-pending-list-47")}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="9">
                            {this.props.t("ins-pending.ins-pending-list-48")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p>
                    {result.filter((tire) => !!tire.codigo).length +
                      this.props.t("alerts.ins-one-item-78")}
                  </p>
                  <div className="text-center v-scroll">
                    {this.getTableResult(result, authUser, this.props.firebase)}
                  </div>
                  <br />
                  {!!authUser.permissions.create ? (
                    <form onSubmit={this.onSubmitItems}>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-block"
                        >
                          <i className="fa fa-save"></i>{" "}
                          {this.props.t("globals.save-all")}
                        </button>
                      </div>
                    </form>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className={this.state.byVehicle ? "hidden" : "mt-5"}>
              <div className="d-flex justify-content-between mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn btn-success"
                      onClick={(e) => this.generateExcel(false)}
                    >
                      <i className="fa fa-file-export"></i>{" "}
                      {this.props.t("globals.exports")}
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <button
                      className="btn btn-primary"
                      onClick={(e) =>
                        this.reviewMultipleInspection(
                          e,
                          inspectionsTire,
                          authUser,
                          true,
                          false
                        )
                      }
                    >
                      {this.props.t("ins-pending.ins-pending-list-9")}
                    </button>
                  </div>
                </div>
              </div>
              <br />
              {this.state.inspections
                ? this.getInspectionsTireList(inspectionsTire)
                : null}
              {this.state.modalReviewErrors ? this.showModalReview() : null}
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Pending);
