import { GLOBAL_PILOT_COUNT } from "../types/globalsTypes";

const INITIAL_STATE = {
  pilotCount: null,
};

export const globalsStatesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_PILOT_COUNT:
      return {
        ...state,
        pilotCount: action.payload,
      };
    default:
      return state;
  }
};
