import React from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as APIS from "../../../constants/apis";

class CostCenterNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id : localStorage.getItem("GlobalCustomerId"),
      name        : "",
      // code        : ""
    };
  };

  onSubmitCostCenter(event, history) {
    event.preventDefault();
  
    const data = {
      ...this.state,
    };
  
    trackPromise(fetch(`${APIS.API_REST_COSTCENTERS.replace("customer_id",
                                            localStorage.getItem("GlobalCustomerId"))}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers:{
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        } })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if(!status) {
          history.push("/costcenters");
        } else {
          if(status.indexOf("Key (code)") > -1) {
            throw new Error(`${this.props.t("alerts.cities-item-1") + data.code + this.props.t("alerts.brands-item-9")}`);
          }
          if(status.indexOf("Costcenter with name")) {
            throw new Error(`${this.props.t("alerts.cities-item-2") + data.name + this.props.t("alerts.brands-item-9")}`);
          }
          throw new Error(status);
        } })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onChange = event =>{
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name, code } = this.state;
    const { history } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            
            <h3>{this.props.t("client-general.cost-new")}</h3>
            <br/><br/>
            <form onSubmit={(event) => this.onSubmitCostCenter(event, history)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">{this.props.t("client-general.client-item-1")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("client-general.client-item-1")}
                           id="nameInput"
                           name="name"
                           value={name}
                           onChange={this.onChange}
                           required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                          className="btn btn-primary">
                          <i className="fa fa-save"/><i/> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(CostCenterNewItem);
