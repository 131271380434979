import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import * as constantsCostCenters from "../../../constants/actions/CostCenters";

const initialState = {
  ccenters  : [],
  ccenter   : {},
  message   : null,
  error     : null
};

function rootReducer(state, action) {
  switch(action.type) {
    case constantsCostCenters.GET_CCENTERS_SUCCESS:
      return {
        ccenters  : action.json.ccenters,
        ccenter   : {},
        error     : null
      };
    case constantsCostCenters.GET_CCENTER_SUCCESS:
      return {
        ccenter  : action.json.ccenter
      };
    case constantsCostCenters.GET_REQUEST_SUCCESS:
      return {
        ccenters  : action.json.ccenters,
        ccenter   : action.json.ccenter,
        message   : action.json.message,
      };
    case constantsCostCenters.GET_REQUEST_ERROR:
      return {
        ccenters  : action.json.ccenters,
        ccenter   : action.json.ccenter,
        error     : action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
