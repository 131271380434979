import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

import $, { event } from "jquery";
import * as APIS from "../../../constants/apis.js";

import NoveltyList from "./NoveltyList";
import NoveltyForm from "./NoveltyForm";

import moment from "moment";


class Novelty extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.noveltyList = React.createRef();
    this.state = {
      records: [],
      waste: [],
      novelties: [],
      insNovelties: [],
      insImages: [],
      operator: "",
      sinester: "",
      journey: null,
      noveltyType: "Desecho",
      DOT: "",
      fecha_subida: moment().format().substr(0, 16),
      mode: "list",
      idx: null,
      codigo: null,
      vehicleGroups: [],
    };
  };


  getVehicleGroups = () => {
    trackPromise(fetch(APIS.FILTER_VEHICLEGROUPS
      .replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Unreachable endpoint");
        }
        return response.json();
      })
      .then(json => {
        if (!!json.message) {
          throw new Error("Unreachable endpoint");
        }
        this.setState({ vehicleGroups: json.vehiclegroups })
      })
      .catch(error => {
        let err = error;
        toast.error(err.toString());
      }));
  }

  componentDidMount() {
    this.getVehicleGroups()
    this.setState({
      records: this.props.records,
      waste: this.props.waste,
      novelties: this.props.novelties,
      codigo: this.props.codigo,
      movements: this.props.movements
    });
  };

  resetState = () => {
    this.setState({
      insNovelties: [],
      insImages: [],
      noveltyType: "Novedad",
      mode: "list",
      idx: null
    });
    this.noveltyList.current.resetState();
  };

  onChangeMode = (mode, idx) => {
    if (mode === "form" && idx == null) {
      this.form.current.setEmptyFields();
    } else if (mode === "form" && idx != null) {
      this.form.current.setFields(idx, this.state.insNovelties);
    }
    this.setState({
      mode: mode,
      idx: idx
    });
  };

  onChangeNoveltyType = event => {
    this.setState({
      noveltyType: event.target.value
    });
  };

  onChangeDot = event => {
    this.setState({
      DOT: event.target.value
    });
  };

  onChange = event => {
    this.setState({
      fecha_subida: event.target.value
    });
  };

  onChangeOperator = event => {
    let value = event.target.value
    this.setState({
      operator: value.toUpperCase()
    });
  };

  onChangeSinister = event => {
    let value = parseInt(event.target.value)
    this.setState({
      sinester: value
    });
  };

  onChangeJourney = event => {
    if (event.target.value == "true") {
      this.setState({
        journey: true
      });
    } else if (event.target.value == "false") {
      this.setState({
        journey: false
      });
    } else {
      this.setState({
        journey: null
      });
    }
  };

  onCloseNoveltiesModal = () => {
    if (!!this.props.openMoreInfoModal && !this.props.onlyNovelties) {
      this.props.openMoreInfoModal();
    }
  };

  deleteElement = (idx) => {
    const novelties = [...this.state.insNovelties];
    novelties.splice(idx, 1);
    this.setState({
      insNovelties: novelties
    });
  };

  render() {
    const { posicion } = this.props;
    const { novelties, records, waste, movements, noveltyType, insNovelties, insImages, vehicleGroups,
      mode, idx } = this.state;

    return (
      <div className="modal fade"
        id={"posicionNovelties" + (1 * posicion)}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="MoreInfoCarInfoLabel"
        aria-hidden="true">
        <div className="modal-dialog"
          role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark"
                id="MoreInfoCarInfoLabel">
                {this.state.noveltyType === "Desecho" ? this.props.t("ins-noveltie.ins-nov-1") :
                  this.state.noveltyType === "Movimiento" ? this.props.t("ins-noveltie.ins-nov-29") : this.props.t("ins-noveltie.ins-nov-2")} {`${this.state.codigo ? "para la llanta " + this.state.codigo : "para la posicion " + (1 * posicion)}`}
              </h5>
              <button type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Cerrar"
                onClick={this.onCloseNoveltiesModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-dark">
              <div className={this.state.mode === "list" ? "row form-group" : "row  form-group hidden"}>
                <div className="col-sm-2">
                  <label htmlFor="noveltyType">{this.props.t("ins-noveltie.ins-nov-3")} {this.state.noveltyType === "Desecho" ? this.props.t("ins-noveltie.ins-nov-4") : this.props.t("ins-noveltie.ins-nov-5")}</label>
                </div>
                <div className="col-sm-10">
                  <select className="form-control"
                    name="noveltyType"
                    id={"noveltyTypePos" + (1 * posicion)}
                    value={this.state.noveltyType}
                    onChange={this.onChangeNoveltyType}>
                    <option value="Desecho">{this.props.t("ins-noveltie.ins-nov-6")}</option>
                    <option value="Novedad">{this.props.t("ins-noveltie.ins-nov-7")}</option>
                    <option value="Antecedente">{this.props.t("ins-noveltie.ins-nov-8")}</option>
                    <option value="Movimiento">{this.props.t("ins-noveltie.ins-nov-29")}</option>
                  </select>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-2">
                  <label htmlFor="fecha_subida">{this.props.t("ins-noveltie.ins-nov-9")}</label>
                </div>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    id="fecha_subida"
                    placeholder={this.props.t("ins-noveltie.ins-nov-9")}
                    type="datetime-local"
                    value={this.state.fecha_subida}
                    onChange={this.onChange} />
                </div>
              </div>
              <div id={"DOT" + (1 * posicion)} className={this.state.mode === "list" && this.state.noveltyType === "Desecho" ? "row form-group" : "row  form-group hidden"}>
                <div className="col-sm-2">
                  <label htmlFor="DOT">{this.props.t("ins-noveltie.ins-nov-10")}</label>
                </div>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={this.props.t("ins-noveltie.ins-nov-10")}
                    value={this.state.DOT}
                    onChange={this.onChangeDot} />
                </div>
              </div>
              <div id={"journey" + (1 * posicion)} className={this.state.mode === "list" && this.state.noveltyType === "Desecho" ? "row form-group" : "row  form-group hidden"}>
                <div className="col-sm-2">
                  <label htmlFor="journey">{this.props.t("ins-noveltie.ins-nov-11")}</label>
                  <i className="text-info small ml-2">(Opcional)</i>
                </div>
                <div className="col-sm-10">
                  <select className="form-control"
                    name="journey"
                    id="journey"
                    value={this.state.journey}
                    onChange={this.onChangeJourney}>
                    <option value={null}></option>
                    <option value={true}>Si</option>
                    <option value={false}>No</option>
                  </select>
                </div>
              </div>
              <div id={"operator" + (1 * posicion)} className={this.state.mode === "list" && this.state.noveltyType === "Desecho" ? "row form-group" : "row  form-group hidden"}>
                <div className="col-sm-2">
                  <label htmlFor="operator">{this.props.t("ins-noveltie.ins-nov-12")}</label>
                  <i className="text-info small ml-2">(Opcional)</i>
                </div>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    id="operator"
                    placeholder={this.props.t("ins-noveltie.ins-nov-12")}
                    type="text"
                    value={this.state.operator}
                    style={{ transform: "uppsercase" }}
                    onChange={this.onChangeOperator} />
                </div>
              </div>
              <div id={"sinester" + (1 * posicion)} className={this.state.mode === "list" && this.state.noveltyType === "Desecho" ? "row form-group" : "row  form-group hidden"}>
                <div className="col-sm-2">
                  <label htmlFor="sinester">{this.props.t("ins-noveltie.ins-nov-13")}</label>
                  <i className="text-info small ml-2">(Opcional)</i>
                </div>
                <div className="col-sm-10">
                  <select className="form-control"
                    name="sinester"
                    id="sinester"
                    value={this.state.sinister}
                    onChange={this.onChangeSinister}>
                    {
                      vehicleGroups && vehicleGroups.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <NoveltyList
                t={this.props.t}
                ref={this.noveltyList}
                mode={mode}
                noveltyType={noveltyType}
                posicion={posicion}
                novelties={insNovelties}
                movements={movements}
                images={insImages}
                deleteElement={this.deleteElement}
                onChangeMode={this.onChangeMode}
              />
              <NoveltyForm
                t={this.props.t}
                ref={this.form}
                idx={idx}
                mode={mode}
                noveltyType={noveltyType}
                waste={waste}
                records={records}
                movements={movements}
                noveltiesList={novelties}
                novelties={insNovelties}
                onChangeMode={this.onChangeMode}
              />
              <div className={this.state.mode === "list" ? "row form-group" : "row  form-group hidden"}>
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      if (insNovelties.length > 0) {
                        $("#posicionNovelties" + this.props.posicion).modal('toggle');
                        this.onCloseNoveltiesModal();
                      } else {
                        var str = "una novedad"
                        if (this.state.noveltyType === "Desecho") {
                          str = "un motivo de desecho"
                        } else if (this.state.noveltyType === "Movimiento") {
                          str = "un motivo de movimiento"
                        }
                        toast.error("Por favor agrega al menos " + str)
                      }
                    }}>
                    <i className="fa fa-save"></i> {this.props.t("ins-noveltie.ins-nov-4")} {this.state.noveltyType === "Desecho" ? this.props.t("ins-noveltie.ins-nov-1") :
                      this.state.noveltyType === "Movimiento" ? this.props.t("ins-noveltie.ins-nov-29") : this.props.t("ins-noveltie.ins-nov-2")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default Novelty;