import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const initialState = {
};

function rootReducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
