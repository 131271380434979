import React, { Component } from "react";

class DragDropFile extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  };

  suppress(event) {
    event.stopPropagation();
    event.preventDefault();
  };

  onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      this.props.handleFile(files[0]);
    }
  };

  render() {
    return (
      <div onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}>
        {this.props.children}
      </div>
    );
  };
};

export default DragDropFile;
