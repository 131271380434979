
import API from "../../constants/api";
import { REQ_CATA_MOVEMENTS_SUCCESS, REQ_CATA_NOVELTIES_SUCCESS, REQ_CATA_RECORDS_SUCCESS, REQ_CATA_WASTE_SUCCESS } from "../types/cataloguesTypes";

export const getCatalogueNovelties = () => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getNoveltiesCatalogue()
        .then(res => {
          if (res.catalogo_novedades) {
            dispatch({
              type: REQ_CATA_NOVELTIES_SUCCESS,
              payload: res.catalogo_novedades
            })
            resolve(res.catalogo_novedades)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}

export const getCatalogueRecords = () => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getRecordCatalogue()
        .then(res => {
          if (res.catalogo_antecedentes) {
            dispatch({
              type: REQ_CATA_RECORDS_SUCCESS,
              payload: res.catalogo_antecedentes
            })
            resolve(res.catalogo_antecedentes)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}

export const getCatalogueWaste = () => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getWasteCatalogue()
        .then(res => {
          if (res.catalogo_desechos) {
            dispatch({
              type: REQ_CATA_WASTE_SUCCESS,
              payload: res.catalogo_desechos
            })
            resolve(res.catalogo_desechos)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}

export const getCatalogueMovements = () => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getMovementsCatalogue()
        .then(res => {
          if (res.catalogo_movimiento) {
            dispatch({
              type: REQ_CATA_MOVEMENTS_SUCCESS,
              payload: res.catalogo_movimiento
            })
            resolve(res.catalogo_movimiento)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}