import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import API from "../../constants/api";
import { KEYS_MASSIVES } from "../../constants/variables";

import { withFirebase } from "../Auth/Firebase";
const service =
  window.location.hostname === "testing.ruedata.com" ||
    window.location.hostname === "localhost"
    ? "dev/"
    : "prod/";

const StandByMultiple = ({ firebase }) => {
  const [isShow, setIsShow] = useState(false);
  const [files, setFiles] = useState([]);
  const db = firebase.doApp();

  const saveData = (data) => {
    let array = [];
    if (data) {
      let claves = Object.keys(data);
      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        array.push(data[clave]);
      }
      setFiles(array);
    } else {
      setFiles([])
    }
  };

  useEffect(() => {
    const dbRef = db.db.ref(service + db.userId);
    dbRef.on("value", (snapshot) => {
      saveData(snapshot.val());
    });
  }, []);

  const downloadExcelsFn = (file) => {
    const url = "https://ruedatamassive.s3.amazonaws.com/";
    window.location.href = url + file;
  };

  const deleteRow = (bulk) => {
    trackPromise(
      API
        .deleteRowMassiveStandby(db.userId, bulk)
        .then(res => {
          toast.success("Eliminado correctamente")
        })
        .catch(err => {
          console.log(err.response)
          if (err.response.status != 204) {
            toast.error(err.response.data.message)
          }
        })
    )
  }

  // const goToAdmin = () => {
  //   setIsShow(false)
  //   history.push("/massive/admin")
  // }

  const showModal = () => {
    const handleClose = () => setIsShow(false);

    return (
      <>
        <Modal show={isShow} onHide={handleClose} dialogClassName="modal-sm-mc">
          <Modal.Header closeButton>
            <Modal.Title>Listado / Cargue masivos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {files.length > 0 ? (
              files.map((item, index) => {
                const typeMassive = KEYS_MASSIVES.filter(
                  (i) => i.id === item.type
                );
                return (
                  <div key={index} className="uploading-list">
                    <img src="../../images/excel.png" alt="image_excel" />
                    <div>
                      <h5>
                        Masivos de {typeMassive[0].value}{" "}
                        <span
                          className={
                            item.status === 0
                              ? "badge badge-danger"
                              : item.status > 0 && item.status < 99
                                ? "badge badge-info"
                                : "badge badge-success"
                          }
                        >
                          {item.status === 0
                            ? "Pendiente"
                            : item.status > 0 && item.status < 99
                              ? "Subiendo"
                              : "Listo"}
                        </span>
                      </h5>
                      <div className="uploading_body">
                        <div className="progress_items">
                          <div>
                            <span className="customer">
                              <i class="fas fa-truck-moving"></i>:{" "}
                              {item.name_customer}
                            </span>
                            <span className="customer">
                              <i class="fas fa-calendar"></i>: {item.init_date}
                            </span>
                            {item.end_date ? (
                              <span className="customer">
                                <i class="fas fa-calendar-check"></i>:{" "}
                                {item.end_date}
                              </span>
                            ) : null}
                          </div>
                          <div>
                            <span className="files">
                              <i class="fas fa-folder"></i>: {item.total_row}
                            </span>
                            <span className="check">
                              <i class="fas fa-check"></i>: {item.done_row}
                            </span>
                            <span className="errors">
                              <i class="fas fa-times"></i>: {item.error_row}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="progress">
                        <div
                          class={`progress-bar ${item.status !== 100
                            ? "progress-bar-striped progress-bar-animated"
                            : ""
                            }`}
                          style={{ width: item.status + "%" }}
                          role="progressbar"
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>

                      <div>
                        <p>
                          {item.status}% Cargado...{" "}
                          {item.url_file_recover
                            ? "con errores"
                            : "sin errores"}
                        </p>
                        <div>
                          {item.url_file_recover ? (
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                downloadExcelsFn(item.url_file_recover)
                              }
                            >
                              Descargar Errores
                            </button>
                          ) : null}
                          {item.url_file ? (
                            <>
                              <button
                                className="btn btn-success"
                                onClick={() => downloadExcelsFn(item.url_file)}
                              >
                                Descargar Original
                              </button>
                              <button
                                className="btn btn-danger"
                                onClick={() => deleteRow(item.bulk)}
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-center">
                No has cargado masivos en el ultimo mes
              </p>
            )}
          </Modal.Body>
          <Modal.Footer
            style={{ display: "flex", justifyContent: "center" }}
          ></Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <div className="bell-upload">
      <div className="bell-icon" onClick={() => setIsShow(true)}>
        <i class="fas fa-cloud-upload-alt"></i>
        {files.length > 0 ? (
          <span className="badge badge-danger">{files.length}</span>
        ) : null}
      </div>
      {isShow ? showModal() : null}
    </div>
  );
};

export default withFirebase(StandByMultiple);
