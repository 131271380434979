import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsSchema from "../../constants/actions/Schemas";
import * as APIS from "../../constants/apis";

function onSubmitSchema(event, state, schemas, schema, history, t) {
  event.preventDefault();

  return dispatch => {
    const data = {
      ...state,
      customer_id: localStorage.getItem("GlobalCustomerId"),
    };
    if (!localStorage.getItem("GlobalCustomerId")) return null;
    trackPromise(fetch(`${APIS.API_REST_SCHEMAS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.status !== 201) {
          throw new Error(t("alerts.schemas-item-1"));
        }
        return response.json();
      })
      .then(json => {
        const status = json.message;
        if (!status) {
          return fetch(APIS.API_REST_SCHEMAS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
            .then(response => response.json())
            .then(json => {
              history.push("/schemas");
              dispatch(getReqSuccess(schemas, schema, t("alerts.schemas-item-3")));
              dispatch(getSchemasSuccess(json));
            })
            .catch(error => {
              history.push("/schemas");
              dispatch(getReqError(schemas, schema, error.toString()));
            });
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

export function getSchemasSuccess(json) {
  return {
    type: constantsSchema.GET_SCHEMAS_SUCCESS,
    json
  };
};

export function getReqSuccess(schemas, schema, message) {
  const json = {
    schemas: schemas,
    schema: schema,
    message: message
  };
  return {
    type: constantsSchema.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(schemas, schema, error) {
  const json = {
    schemas: schemas,
    schema: schema,
    error: error
  };
  return {
    type: constantsSchema.GET_REQUEST_ERROR,
    json
  };
};

class SchemaNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // code: "",
      axesnumber: "",
      tiresnumber: "",
      spares: "",
    };
  };

  onChange = event => {
    if (event.target.name === 'tiresnumber') {
      this.setState({ name: document.getElementById("axesNumberInput").value + '_ejes_' + event.target.value + '_llantas' });
    }
    if (event.target.name === 'axesnumber') {
      this.setState({ name: event.target.value + '_ejes_' + document.getElementById("tiresNumberInput").value + '_llantas' });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name, code, axesnumber, tiresnumber, spares } = this.state;
    const { schema, schemas, history } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("schema.schema-register")}</h3>
            <br /><br />
            <form onSubmit={(event) => this.props.onSubmitSchema(event,
              this.state, schemas, schema, history, this.props.t)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">{this.props.t("schema.schema-th-1")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("schema.schema-th-1")}
                      id="nameInput"
                      readOnly={true}
                      name="name"
                      value={name}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="axesNumberInput">{this.props.t("schema.schema-axles-number")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("schema.schema-axles-number")}
                      id="axesNumberInput"
                      name="axesnumber"
                      type="number"
                      value={axesnumber}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="tiresNumberInput">{this.props.t("schema.schema-tires-number")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("schema.schema-tires-number")}
                      id="tiresNumberInput"
                      name="tiresnumber"
                      type="number"
                      value={tiresnumber}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="sparesInput">{this.props.t("schema.schema-spare-parts")}</label>
                    <br></br><i className="text-info small"></i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                            id="sparesInput"
                            name="spares"
                            type="number"
                            value={spares}
                            onChange={this.onChange}/> 
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                    className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  schemas: state => state.schemas,
  schema: state => state.schema
});

const mapDispatchToProps = { onSubmitSchema };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(SchemaNewItem));
