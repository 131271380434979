import moment from "moment";
import chunk from "lodash/chunk";
import React from "react";
import * as APIS from "../../../constants/apis";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import $ from "jquery";

const numberOfItems = 20;

class NoveltysList extends React.Component {

  constructor(props) {
    super(props);
    this.state = { idNov: null, novelties2: [], causes: [], records: [], waste: [], novelties: [], isEditing: false, isAdding: false, novTemp: {}, novelty: {}, noveltys: [], noveltysCount: 0 };
  }

  componentDidMount() {
    if (this.props.noveltysCount === "") {
      this.getNoveltys()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.tireId !== prevProps.tireId) {
      this.getNoveltys()
    }
  }

  loadCatalogues = () => { 
    if(this.state.records.length === 0){
      let records, waste, novelties = [];
      trackPromise(fetch(`${APIS.API_REST_RECORDS_LIST}`, {
        headers:{
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        } })
        .then(response => response.json())
        .then(json => records = json.catalogo_antecedentes)
        .then(() => fetch(`${APIS.API_REST_NOVELTIES_LIST}`, {
          headers:{
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          } }))
        .then(response => response.json())
        .then(json => novelties = json.catalogo_novedades)
        .then(() => fetch(`${APIS.API_REST_WASTES_LIST}`, {
          headers:{
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          } }))
        .then(response => response.json())
        .then(json => waste = json.catalogo_desechos)
        .then(() => {
          this.setState({
            records   : records,
            waste     : waste,
            novelties : novelties
          }); })
        .catch(error => {
          toast.error(this.props.t("alerts.ins-one-item-46") + error);
        }));
    }
  }

  getNoveltys = () => {
    let tire_id = this.props.tireId
    if (tire_id !== null) {
      trackPromise(fetch(`${APIS.API_REST_NOVELTIES_TIRE.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id", tire_id)}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "GET"
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          let message = json.message;
          if (!message) {
            this.setState({ noveltys: json.novedades, noveltysCount: json.novedades.length });
          }
        })
        .catch(error => {
          toast.error(error.toString());
        }));
    }
  };

  deleteNovelty = (id) => {
    if (window.confirm(this.props.t("alerts.ins-one-item-47"))) {
      let tire_id = this.props.tireId
      if (tire_id !== null) {
        trackPromise(fetch(`${APIS.API_REST_NOVELTIES_TIRE.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id", tire_id)}/${id}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "DELETE"
        })
          .then(response => {
            return response.json();
          })
          .then(json => {
            let message = json.message;
            if (!!message) {
              toast.success(message);
              let arr = this.state.noveltys.filter(novelty => novelty.id != id)
              let count = Number(this.state.noveltysCount) - 1
              this.setState({ noveltys: arr, noveltysCount: count });
            } else {
              toast.error(this.props.t("alerts.ins-one-item-48"));
            }
          })
          .catch(error => {
            toast.error(error.toString());
          }));
      }
    }
  };

  getBase64Image = (img) => {
    var canvas = document.getElementById('myCanvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  };

  editNovelty = () => {
    let tire_id = this.props.tireId
    let nov = this.state.novelty;
    nov.pictures = [];
    nov.urlfoto.forEach(picture => {
      if(!picture.includes("https")){
        if(picture.includes("data:image/png")){
          nov.pictures.push({img: picture.replace("data:image/png;base64,", "")})
        }else{
          nov.pictures.push({img: picture.replace("data:image/jpeg;base64,", "")})
        }
      }else{
        nov.pictures.push({img: picture})
      }
    });
    delete nov.urlfoto;
    nov.noveltys = nov.novedades;
    delete nov.novedades;
    const data = {
      ...nov,
    };
    data.fecha_subida = this.convertDateToUTC(data.fecha_subida);
    trackPromise(fetch(`${APIS.API_REST_NOVELTIES_TIRE.replace("customer_id",localStorage.getItem("GlobalCustomerId")).replace("tire_id", tire_id)}/${this.state.idNov}`, {
      headers:{
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
        "Content-Type": "application/json",
      },
        method: "PUT",
        body: JSON.stringify(data) })
        .then(response => {
          return response.json(); 
        })
        .then(json => {
          let message = json.message;
          toast.success(this.props.t("alerts.ins-one-item-49"));
          this.setState({ isEditing: false, novelty: {}, novTemp: {}, isAdding: false })   
          $("#novedades" + this.props.divId).modal('hide') 
        })
        .catch(error => {
          toast.error(error.toString());
        }));
  };

  getNovelties = (seccion, list) => {
    for(let i = 0; i < list.length; i++) {
      if(list[i].seccion_llanta === seccion) {
        return list[i].novedades;
      }
    }
    return [];
  };

  getCodes = (seccion, list) => {
    for(let i = 0; i < list.length; i++) {
      if(list[i].seccion_llanta === seccion) {
        return list[i].novedades;
      }
    }
    return [];
  };

  getCauses = (novedad, list) => {
    console.log(novedad)
    for(let i = 0; i < list.length; i++) {
      if(list[i].motivo + " | " + list[i].ligado_a === novedad) {
        return list[i].posibles_causas;
      }
    }
    return [];
  };

  onChangeItem = event => {
    this.setState({ novelty: {...this.state.novelty, [event.target.name]: event.target.value} });    
  };

  onChangeDependent = (event, list) => {    
    this.setState({ novTemp: {...this.state.novTemp, [event.target.name]: event.target.value} }); 
    if(event.target.name === "seccion") {
      this.setState({
        novelties2 : this.getNovelties(event.target.value, list),
        causes : []
      });
    }
    if(event.target.name === "novedad") {
      this.setState({
        causes  : this.getCauses(event.target.value, list)
      });
    }
  };

  onChangePicture = (event, index) => {    
    let that = this;
    let novelty = this.state.novelty;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      novelty.urlfoto[index] = reader.result;
      that.setState({ novelty }); 
    };
    reader.onerror = function (error) {
    };
  };

  getLastInspectionDate = (date) => {
    let dateTemp = "";
    dateTemp = date.replace("T", " ");
    dateTemp = dateTemp.replace("Z", "");
    let fmtDate = dateTemp;
    if(date.includes("T")){
      var f1 = new Date(dateTemp);
      f1.setHours(f1.getHours() - 5);
      var month = (f1.getMonth()<9?'0':'') + (f1.getMonth() + 1);
      var day = (f1.getDate()<10?'0':'') + f1.getDate();
      var hour = (f1.getHours()<10?'0':'') + f1.getHours();
      var minute = (f1.getMinutes()<10?'0':'') + f1.getMinutes();
      //var second = (f1.getSeconds()<10?'0':'') + f1.getSeconds();
      fmtDate = f1.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute;
    }
    return fmtDate;
  };

  convertDateToUTC = (date) => {
    var f1 = new Date(date);
    f1.setHours(f1.getHours() + 5);
    var month = (f1.getMonth()<9?'0':'') + (f1.getMonth() + 1);
    var day = (f1.getDate()<10?'0':'') + f1.getDate();
    var hour = (f1.getHours()<10?'0':'') + f1.getHours();
    var minute = (f1.getMinutes()<10?'0':'') + f1.getMinutes();
    var second = (f1.getSeconds()<10?'0':'') + f1.getSeconds();
    let fmtDate = f1.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second + "Z";
    return fmtDate;
  }

  getNovelty = () => {
    const { novTemp, novelty, novelties2, causes } = this.state;
    let list = [];
    if(novelty.tiponovedad === "Novedad"){
      list = this.state.novelties;
    }else if(novelty.tiponovedad === "Desecho"){
      list = this.state.waste;
    }else{
      list = this.state.records;
    }
    return (
      <div className="modal-body text-dark">
        <canvas style={{display: "none"}} id="myCanvas"/>
        <button type="button" onClick={(e) => this.setState({ isEditing: false, novelty: {} })} className="btn btn-primary float-right">{this.props.t("ins-one.ins-one-noveltie-1")}</button>
        <br /><br /><br />
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{this.props.t("ins-one.ins-one-noveltie-2")}</h5>
            <fieldset>
              <div className="row form-group">
                <div className="col-sm-2">
                  <label htmlFor="typeInput">{this.props.t("ins-one.ins-one-noveltie-3")}</label>
                </div>
                <div className="col-sm-10">
                  <select className="form-control" name="tiponovedad" id="typeInput" value={novelty.tiponovedad} onChange={this.onChangeItem}>
                    <option value="Novedad">{this.props.t("ins-one.ins-one-noveltie-4")}</option>
                    <option value="Desecho">{this.props.t("ins-one.ins-one-noveltie-5")}</option>
                    <option value="Antecedente">{this.props.t("ins-one.ins-one-noveltie-6")}</option>
                  </select>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-2">
                  <label htmlFor="dateInput">{this.props.t("ins-one.ins-one-noveltie-7")}</label>
                </div>
                <div className="col-sm-10">
                  <input name="fecha_subida" type="text" id="dateInput" className="form-control" value={this.getLastInspectionDate(novelty.fecha_subida)} onChange={this.onChangeItem} placeholder="Fecha" />
                </div>
              </div>
              { this.state.isAdding === false ?
              <div>
                <button type="button" onClick={(e) => this.setState({ isAdding: true, novTemp: {seccion: "", novedad: "", causacod: ""}})} className="mb-2 btn btn-primary float-right">{this.props.t("ins-one.ins-one-noveltie-8")}</button>
                <table className="table">
                  <thead>
                    <tr>
                      <th>{this.props.t("ins-one.ins-one-noveltie-9")}</th>
                      <th>{this.props.t("ins-one.ins-one-noveltie-10")}</th>
                      <th>{this.props.t("ins-one.ins-one-noveltie-11")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    !!novelty.novedades && novelty.novedades.map((novedad, j) => {  
                      console.log(novelty.novedades)
                      return (
                        <tr key={"div" + j}>
                          <td>{novedad.motivo}</td>
                          <td>{novedad.posible_causa}</td>
                          <td>{novedad.codigo_causa}</td>
                          <td>
                            <button
                              className="btn btn-danger float-left"
                              onClick={(e) => { this.setState({ novelty: {...this.state.novelty, novedades: this.state.novelty.novedades.filter(novelty => novelty != this.state.novelty.novedades[j])} }) }}>
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
              </div>
              :
              <div className="row form-group">
                <div className="col-sm-12">
                  <button type="button" onClick={(e) => this.setState({ isAdding: false, novTemp: {seccion: "", novedad: "", causacod: ""}})} className="btn btn-primary float-right">{this.props.t("ins-one.ins-one-noveltie-12")}</button>
                </div>
                <div className="col-sm-4 mt-2">
                  <label>{this.props.t("ins-one.ins-one-noveltie-13")}</label>
                  <br />
                  <select className="form-control" name="seccion" value={novTemp.seccion} onChange={(e) =>this.onChangeDependent(e,list)}>
                    <option value="">{this.props.t("ins-one.ins-one-noveltie-14")}</option>
                    {
                    list.map(novelty => {
                      return <option
                        key={novelty.seccion_llanta}>
                        {novelty.seccion_llanta}
                      </option>
                    })
                    }
                  </select>
                </div>
                <div className="col-sm-4">
                  <label>{this.props.t("ins-one.ins-one-noveltie-15")}</label>
                  <br />
                  <select className="form-control" name="novedad" value={novTemp.novedad} onChange={(e) =>this.onChangeDependent(e,novelties2)}>
                    <option value="">{this.props.t("ins-one.ins-one-noveltie-16")}</option>
                    {
                    novelties2.map(novelty => {
                      return <option
                        key={novelty.motivo}
                        value={novelty.motivo + " | " + novelty.ligado_a}>
                        {novelty.motivo}
                      </option>
                    })
                    }
                  </select>
                </div>
                <div className="col-sm-4">
                  <label>{this.props.t("ins-one.ins-one-noveltie-17")}</label>
                  <br />
                  <select className="form-control" name="causacod" value={novTemp.causacod} onChange={(e) =>this.onChangeDependent(e)}>
                    <option value="">{this.props.t("ins-one.ins-one-noveltie-18")}</option>
                    {
                    causes.map(novelty => {
                      return <option
                        key={novelty.posible_causa}>
                        {novelty.posible_causa + " | " + novelty.codigo_causa}
                      </option>
                    })
                    }
                  </select>
                </div>
                <div className="col-sm-12">
                  <button type="button" onClick={(e) => {
                    let obj = { ligado_a: novTemp.novedad.split("|")[1], seccion_llanta: novTemp.seccion, motivo: novTemp.novedad.split("|")[0], posible_causa: novTemp.causacod.split("|")[0], codigo_causa: novTemp.causacod.split("|")[1] }
                    this.setState({ novelty: {...this.state.novelty, novedades: [...this.state.novelty.novedades, obj]} })
                    this.setState({ isAdding: false, novTemp: {seccion: "", novedad: "", causacod: ""}});
                  }} className="btn btn-primary float-right">{this.props.t("ins-one.ins-one-noveltie-19")}</button>
                </div>
              </div>
              }
              <div className="row form-group">
                <div className="col-sm-12 mb-2">
                  <button type="button" onClick={(e) => this.setState({ novelty: {...this.state.novelty, urlfoto: [...this.state.novelty.urlfoto, null]} }) } className="btn btn-primary float-right">{this.props.t("ins-one.ins-one-noveltie-20")}</button>                          
                </div>
              {
                  !!novelty.urlfoto && novelty.urlfoto.map((photo, index) => {
                    return (
                        <div key={"div" + index} className="col-sm-3">
                          <button type="button" onClick={(e) => this.setState({ novelty: {...this.state.novelty, urlfoto: this.state.novelty.urlfoto.splice(0, index)} }) } className="btn btn-danger float-right"><i className="fa fa-trash"></i></button>                          
                          <div id={"img" + index}>
                            <img id={"img-" + index} className="img-thumbnail" alt="image" src={photo} />
                          </div>
                          <input type="file" className="form-control" onChange={(e) => this.onChangePicture(e,index)} />
                        </div>
                    )
                  })
              }
              </div>
              <button type="button" onClick={(e) => this.editNovelty()} className="btn btn-primary float-right">{this.props.t("ins-one.ins-one-noveltie-21")}</button>
            </fieldset>      
          </div>
        </div>
      </div>
    );
  };

  getNoveltysList = (result) => {
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th>{this.props.t("ins-one.ins-one-noveltie-22")}</th>
                  <th>{this.props.t("ins-one.ins-one-noveltie-23")}</th>
                  <th>{this.props.t("ins-one.ins-one-noveltie-24")}</th>
                  <th>{this.props.t("ins-one.ins-one-noveltie-25")}</th>
                  <th>{this.props.t("ins-one.ins-one-noveltie-26")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.getRows(result, 1).map((ins, i) => {
                    return <tr key={i}>
                      <td>{ins.tiponovedad}</td>
                      <td>{ins.inspector}</td>
                      <td>{moment.utc(ins.fecha_subida).local().locale("es").format("LLL")}</td>
                      <td>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target={"#novedades" + i} aria-expanded="false" aria-controls={"novedades" + i}>
                          <i className="fa fa-eye"></i> {this.props.t("ins-one.ins-one-noveltie-27")}
                        </button>
                        <div className="collapse mt-3" id={"novedades" + i}>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>{this.props.t("ins-one.ins-one-noveltie-28")}</th>
                                <th>{this.props.t("ins-one.ins-one-noveltie-29")}</th>
                                <th>{this.props.t("ins-one.ins-one-noveltie-30")}</th>
                              </tr>
                            </thead>
                            <tbody>
                            {
                              ins.novedades.map((novedad, j) => {  
                                return (
                                  <tr key={"div" + j}>
                                    <td>{novedad.motivo}</td>
                                    <td>{novedad.posible_causa}</td>
                                    <td>{novedad.codigo_causa}</td>
                                  </tr>
                                )
                              })
                            }
                            </tbody>
                          </table>
                        </div>
                      </td>
                      <td>
                        {
                          !!ins.urlfoto && ins.urlfoto.map((photo, j) => {
                            return <a key={"a" + i + "-" + j} href={photo.replace(/'/g, '')} rel="noopener noreferrer" target="_blank" className="btn btn-primary"><i className="fa fa-image"></i> {this.props.t("ins-one.ins-one-noveltie-27")}</a>
                          })
                        }</td>
                      <td>
                        {!!this.props.authUser.permissions.edit ?
                          <button
                            className="btn btn-primary float-left mr-2"
                            onClick={(e) => { 
                              let nov = this.state.noveltys.slice().find(novelty => novelty.id === ins.id);  
                              this.setState({ isEditing: true, novelty: nov, idNov: ins.id }); 
                              this.loadCatalogues(); 
                            }}>
                            <i className="fa fa-edit"></i>
                          </button>
                          : null}
                        {!!this.props.authUser.permissions.delete ?
                          <button
                            className="btn btn-danger float-left"
                            onClick={(e) => this.deleteNovelty(ins.id)}>
                            <i className="fa fa-trash"></i>
                          </button>
                          : null}
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
          <br />
        </div>
      </div>
    );
  };

  getRows = (rows, page) => {
    const orderedRows = rows.sort((a, b) => {
      return (new Date(b.fecha_subida) -
        new Date(a.fecha_subida)) ||
        (new Date(b.fecha_subida) -
          new Date(a.fecha_subida));
    });
    const chunks = chunk(orderedRows, numberOfItems);
    if (!!chunks[page - 1]) {
      return chunks[page - 1];
    }
    return [];
  };

  render() {
    return (
      <div className="col-sm-6 text-center">
        <button className="btn btn-primary text-white"
          data-toggle="tooltip" 
                data-placement="top" 
                title="Novedades"
          onClick={(e) => {
            $("#novedades" + this.props.divId).modal("show");this.getNoveltys() }}>
          <i className="fa fa-bullhorn"></i> {this.props.noveltysCount === "" ? this.state.noveltysCount : this.props.noveltysCount}
        </button>
        <div className="modal fade"
          id={"novedades" + this.props.divId}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="NoveltysLabel"
          aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark"
                  id="NoveltysLabel">
                  {this.props.t("ins-one.ins-one-noveltie-31")}
                  </h5>
                <button type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {!this.state.isEditing ?
                <div className="modal-body text-dark">
                  {this.getNoveltysList(this.state.noveltys)}
                </div>
                :
                this.getNovelty()
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default NoveltysList;
