import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import API from "../../../constants/api";
import _debounce from "lodash/debounce";
import "../notifications.css";
import { toast } from "react-toastify";
import { withFirebase } from "../../Auth/Firebase";

const services = ["plataforma.ruedata.com"];
const env = services.includes(window.location.hostname)
  ? "production"
  : "development";
const ref = `notifications/suspensions/${env}/`;

const BillingItem = ({ firebase, authUser, t }) => {
  const db = firebase.doNotifications();
  const [state, setState] = useState({
    customers: [],
    notifications: [],
    loadingC: false,
    loadingN: true,
  });

  useEffect(() => {
    const dbRef = db.ref(ref);
    dbRef.on("value", (snapshot) => {
      saveData(snapshot.val());
    });
  }, []);

  const saveData = (data) => {
    let array = [];
    if (data) {
      let claves = Object.keys(data);
      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        array.push(data[clave]);
      }
      setState((state) => ({
        ...state,
        notifications: array,
        loadingN: false,
      }));
    } else {
      setState((state) => ({
        ...state,
        notifications: [],
        loadingN: false,
      }));
    }
  };

  const handleSearch = (value) => {
    debounceFn(value.toLowerCase(), true);
  };

  const sumarDias = (fecha, dias) => {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  };

  const addCustomerNotify = (id, name) => {
    const validateId = state.notifications.filter(
      (item) => item.notifications_customer_id === id
    )[0];
    if (!validateId) {
      db.ref(ref + id).set({
        notification_date: new Date().toISOString().slice(0, 10),
        notification_finish_date: sumarDias(new Date(), 10)
          .toISOString()
          .slice(0, 10),
        notification_by: authUser.email,
        notifications_customer: name,
        notifications_customer_id: id,
      });
      toast.success("Cliente " + name + " " + "Agregado");
    }
  };

  const deleteCustomerNotify = (id, name) => {
    const dbRef = db.ref(ref + id);
    dbRef.remove();
    toast.success("Cliente " + name + " " + "Eliminado");
  };

  const debounceFn = _debounce((searchTerm, active) => {
    setState((state) => ({ ...state, loadingC: true }));
    if (searchTerm !== "") {
      let str = `?search=${searchTerm}&active=${active}`;
      API.getCustomers(str)
        .then((response) => {
          setState((state) => ({
            ...state,
            customers: response.Customers.slice(0, 5),
            loadingC: false,
          }));
        })
        .catch((error) => {
          if (error.response.status != 200) {
            toast.error(error.response.data.message);
            setState((state) => ({ ...state, loadingC: false }));
          }
        });
    } else {
      setState((state) => ({
        ...state,
        customers: [],
        loadingC: false,
      }));
    }
  }, 1000);

  const loader = () => {
    return (
      <div class="spinner-border bl-loader" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    );
  };

  return (
    <div className="bl-content">
      <h2># {t("notifications.billing")}</h2>
      <div className="row bl-body">
        <div className="col-md-5">
          <h4>{t("notifications.accounts-list")}</h4>
          <div>
            <input
              placeholder={t("notifications.search-accounts")}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {state.loadingC ? (
              loader()
            ) : state.customers.length ? (
              <div className="bl-body-items-container">
                {state.customers.map((item) => (
                  <p
                    onClick={() => addCustomerNotify(item.id, item.name)}
                    className="bl-body-items"
                  >
                    {item.name}
                  </p>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-5">
          <h4>{t("notifications.notificated")}</h4>
          <div>
            {state.loadingN ? (
              loader()
            ) : state.notifications.length ? (
              <div className="bl-body-items-container">
                {state.notifications.map((item) => (
                  <p
                    onClick={() =>
                      deleteCustomerNotify(
                        item.notifications_customer_id,
                        item.notifications_customer
                      )
                    }
                    className="bl-body-items"
                  >
                    {item.notifications_customer}
                  </p>
                ))}
              </div>
            ) : (
              <p className="text-center mt-4">
                {t("notifications.not-notificated")}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(BillingItem);
