import { REQ_VEHICLESMIN_SUCCESS } from "../types/vehicleMinTypes"

const INITIAL_STATE = {
  vehiclesMin: []
}

export const vehiclesMinReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQ_VEHICLESMIN_SUCCESS:
      return {
        ...state,
        vehiclesMin: action.payload
      }
    default:
      return state
  }
}