import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsBrand from "../../../constants/actions/Brands";
import * as constantsLine from "../../../constants/actions/Lines";
import * as APIS from "../../../constants/apis";

function deleteLine(name, brand, lines, t) {
  if (window.confirm(t("alerts.brands-item-13"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_LINES.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("brand_id", brand.id)}/${name}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }, method: "DELETE"
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(t("alerts.brands-item-5"));
          }
          return response.json();
        })
        .then(json => {
          dispatch(getReqSuccess(brand, lines, json.message));
          return fetch(APIS.API_REST_LINES.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("brand_id",
            brand.id), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          });
        })
        .then(response => response.json())
        .then(json => {
          dispatch(getLinesSuccess({
            brand: brand,
            lines: json.vehiclelinemodels
          }));
        })
        .catch(error => {
          dispatch(getReqError(brand, lines, error.toString()));
        }));
    }
  } else {
    return dispatch => {
      dispatch(getLinesSuccess({
        brand: brand,
        lines: lines
      }));
    };
  }
}

export function getLinesSuccess(json) {
  return {
    type: constantsLine.GET_LINES_SUCCESS,
    json
  };
};

export function getReqSuccess(brand, lines, message) {
  const json = {
    brand: brand,
    lines: lines,
    message: message
  };
  return {
    type: constantsBrand.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(brand, lines, error) {
  const json = {
    brand: brand,
    lines: lines,
    error: error
  };
  return {
    type: constantsBrand.GET_REQUEST_ERROR,
    json
  };
};

class LineDeleteItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { brand, lines, lineDelete, deleteLine } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteLine(lineDelete.oldName, brand, lines, this.props.t)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({
  brand: state => state.brand,
  lines: state => state.lines
});

const mapDispatchToProps = { deleteLine };

export default connect(structuredSelector, mapDispatchToProps)(LineDeleteItem);
