import React, { Component } from "react";

const SheetJSFT = ["xlsx"].map(function(x) { return "." + x; }).join(",");

class DataInput extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  };

  handleChange = event => {
    const files = event.target.files;
    if(files && files[0]) {
      this.props.handleFile(files[0]);
    }
  };

  render() {
    return (
      <div className="form-group">
        <div className="row">
          <div className="col-md-2">
            <label htmlFor="file">{this.props.t("globals.file-register-text")}</label>
          </div>
          <div className="col-md-6">
            <input type="file"
                   key={this.props.inputKey}
                   className="form-control"
                   id="file"
                   style={{ height: "calc(1.5em + .75rem + 7px)" }}
                   accept={SheetJSFT}
                   onChange={this.handleChange} />
          </div>
          <div className="col-md-4">
            <button className="btn btn-primary"
                    onClick={this.props.exportTemplate}
                    style={{ width: "100%" }} >
              <i className="fa fa-download"></i> {this.props.t("globals.download-excel")}
            </button>
          </div>
        </div>
      </div>
    );
  };
};

export default DataInput;
