import React from "react";
import resizer from "iframe-resizer";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
const $ = require("jquery");
var jwt = require("jsonwebtoken");
var METABASE_SITE_URL = "https://metabase.ruedata.com";
var METABASE_SECRET_KEY =
  "742e49bc7d02a55639b6772a5c5fbdea1035b4e7be74bd2e02baf2b51a4de802";

let customer = localStorage.getItem("GlobalCustomerId");
// const dashGroupsContent = [862, 835, 736, 850, 812, 809, 772, 795, 713, 794, 796, 861, 813, 776, 779, 858, 860, 778, 889, 887, 888, 890, 926, 930, 931, 911, 910, 925, 924, 947, 928, 933, 920, 922, 948, 914]
const dashGroupsContent = [];

class Metabase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { iframeUrl: null };
  }

  componentDidMount() {
    let parms = { cliente: customer };

    if (this.props.dashboardId == 838 || this.props.dashboardId == 843)
      return (parms = {});

    if (this.props.clientIds) {
      parms["cliente"] = this.props.clientIds;
    }

    if (dashGroupsContent.includes(this.props.dashboardId)) {
      parms["typegroup0"] = "group0";
      parms["typegroup1"] = "group1";
      parms["typegroup2"] = "group2";
    }

    // if (dashGroupsContent.includes(this.props.dashboardId)) {
    //   parms['typegroup0'] = 'group0'
    //   parms['typegroup1'] = 'group1'
    //   parms['typegroup2'] = 'group2'
    // }

    var payload = {
      resource: { dashboard: this.props.dashboardId },
      params: parms,
      exp: Math.round(Date.now() / 1000) + 10 * 60,
    };
    var token = jwt.sign(payload, METABASE_SECRET_KEY);

    this.setState({
      iframeUrl:
        METABASE_SITE_URL +
        `/embed/dashboard/` +
        token +
        "#bordered=false&titled=true",
    });
  }

  resizeIframe() {
    resizer.iframeResize(
      {
        enablePublicMethods: true,
        checkOrigin: false,
        sizeHeight: true,
        heightCalculationMethod: "max",
        scrolling: true,
      },
      ".reportIframe"
    );
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid" style={{ marginTop: "20px" }}>
            <iframe
              className="reportIframe"
              src={this.state.iframeUrl}
              frameBorder="0"
              width="800"
              height="1500"
              allowtransparency="true"
              onLoad={() => this.resizeIframe()}
            ></iframe>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Metabase);
