import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as APIS from "../../../constants/apis";

class VehicleHistoryList extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 20,
      next: null,
      previus: null,
      inspections: [],
      vehicle_number: "",
      vehicle_plate: "",
      worktype: "",
    };
  }

  componentDidMount() {
    const vehicle_id = this.props.match.params.id;
    this.getHistory(vehicle_id, "");
  }

  getHistory = (vehicle_id, page) => {
    var str = "";
    if (page !== "") {
      str = "?page=" + page + "&limit=" + this.state.limit;
    }
    return trackPromise(
      fetch(
        APIS.API_REST_VEHICLES.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ) +
          "/" +
          vehicle_id,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.c-vehicle-item-21"));
          }
          return response.json();
        })
        .then((json) => {
          this.setState({
            vehicle_number: json.vehiclenumber,
            vehicle_plate: json.plate,
            worktype: json.worktype
          });
          return fetch(
            `${APIS.API_REST_VEHICLE_HISTORY.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            ).replace("vehicle_id", vehicle_id)}` + str,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.c-vehicle-item-22"));
          }
          return response.json();
        })
        .then((json) => {
          this.setState({
            total: json.total,
            next: json.next,
            previus: json.previus,
            inspections: json.inspections,
          });
        })
        .catch((error) => {
          toast.error(error.message.toString());
        })
    );
  };

  getLastInspectionsView = (result, worktype) => {
    let next = this.state.next;
    let previus = this.state.previus;
    if (!result) return null;
    let pageP, pageN, str;
    if (!!previus) {
      str = previus;
      pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    if (!!next) {
      str = next;
      pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    let pages = [];
    let start, end;
    let totalPages = Math.ceil(this.state.total / this.state.limit);
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
      start = Math.max(1, this.state.page - 4);
      end = Math.min(totalPages, this.state.page + 5);

      if (start === 1) {
        end = 10;
      } else if (end === totalPages) {
        start = totalPages - 9;
      }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th>{this.props.t("client-vehicles.client-his-code")}</th>
                  <th>{this.props.t("client-vehicles.client-his-tire")}</th>
                  <th>{this.props.t("client-vehicles.client-his-type")}</th>
                  <th>{this.props.t("client-vehicles.client-his-ext")}</th>
                  <th>{this.props.t("client-vehicles.client-his-cent")}</th>
                  <th>{this.props.t("client-vehicles.client-his-int")}</th>
                  <th>{this.props.t("client-vehicles.client-his-low")}</th>
                  <th>{this.props.t("client-vehicles.client-his-current")}</th>
                  <th>{this.props.t("client-vehicles.client-his-route")}</th>
                  <th>{this.props.t("client-vehicles.client-his-kmmm")}</th>
                  <th>{this.props.t("client-vehicles.client-his-cost-km")}</th>
                  <th>{this.props.t("client-vehicles.client-his-current-hour")}</th>
                  <th>{this.props.t("client-vehicles.client-his-route-hour")}</th>
                  <th>{this.props.t("client-vehicles.client-his-hour-km")}</th>
                  <th>{this.props.t("client-vehicles.client-his-cost-hour")}</th>
                  <th>{this.props.t("client-vehicles.client-his-spent")}</th>
                  <th>{this.props.t("client-vehicles.client-his-cost-mm")}</th>
                  <th>{this.props.t("client-vehicles.client-his-ubi-init")}</th>
                  <th>{this.props.t("client-vehicles.client-his-ubi-end")}</th>
                  <th>{this.props.t("client-vehicles.client-his-doc-num")}</th>
                  <th>{this.props.t("client-vehicles.client-his-modify")}</th>
                  <th>{this.props.t("client-vehicles.client-his-position-init")}</th>
                  <th>{this.props.t("client-vehicles.client-his-position-end")}</th>
                  <th>{this.props.t("client-vehicles.client-his-date-ins")}</th>
                </tr>
              </thead>
              <tbody>
                {result.map((ins, i) => {
                  return (
                    <tr key={i}>
                      <td>{ins.codigo_llanta}</td>
                      <td>
                        {ins.marca + " " + ins.modelo + " " + ins.dimension}
                        <br></br>
                        <b>
                          {typeof ins.retreadband == "undefined"
                            ? ""
                            : ins.retreadband}{" "}
                          {typeof ins.retreadband == "undefined"
                            ? ""
                            : ins.retreadimention}{" "}
                          {!!ins.codigo_llanta
                            ? !!ins.retreadbrand &&
                            !!ins.retreadband &&
                            !!ins.retreadimention
                            ? "R"
                            : "N"
                            : ""}
                          {ins.vida}
                        </b>
                      </td>
                      <td>{ins.tipo_inspeccion}</td>
                      <td>{ins.ult_prof_centro_exterior}</td>
                      <td>{ins.ult_prof_centro}</td>
                      <td>{ins.ult_prof_interior}</td>
                      <td>{ins.prof_mas_baja}</td>
                      <td>{ins.kilometers}</td>
                      <td>{ins.km_recorrido}</td>
                      <td>{ins.km_mm === null ? null : ins.km_mm.toFixed(2)}</td>
                      <td>{ins.km_cost === null ? null : ins.km_cost.toFixed(2)}</td>
                      <td>{ins.hours}</td>
                      <td>{ins.hour_traveled}</td>
                      <td>{ins.hour_mm === null ? null : ins.hour_mm.toFixed(2)}</td>
                      <td>{ins.hour_cost === null ? null : ins.hour_cost.toFixed(2)}</td>
                      <td>{ins.mm_gastados}</td>
                      <td>{ins.mm_cost}</td>
                      <td>{ins.ubicacion_inicial}</td>
                      <td>{ins.ubicacion_final}</td>
                      <td>{ins.order === null ? "" : ins.order}</td>
                      <td>{ins.updated_by ? ins.updated_by : ins.created_by}</td>
                      <td>{ins.posicion_inicial}</td>
                      <td>{ins.posicion_final}</td>
                      <td>
                        {moment
                          .utc(ins.fc_ultima_inspeccion)
                          .local()
                          .locale("es")
                          .format("LLL")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="22">
                    <nav aria-label="Navigation">
                      <ul class="mt-3 pagination justify-content-center">
                        <li class="page-item">
                          {!!previus ? (
                            <button
                              className="page-link"
                              onClick={(e) =>
                                this.getHistory(
                                  this.props.match.params.id,
                                  pageP
                                )
                              }
                            >
                              <i className="fa fa-arrow-left"></i>
                            </button>
                          ) : (
                            <button className="page-link" disabled>
                              <i className="fa fa-arrow-left"></i>
                            </button>
                          )}
                        </li>
                        {links.map((page) => {
                          return (
                            <li class="page-item">
                              <button
                                class="page-link"
                                onClick={(e) =>
                                  this.getHistory(
                                    this.props.match.params.id,
                                    page
                                  )
                                }
                              >
                                {page}
                              </button>
                            </li>
                          );
                        })}
                        <li class="page-item">
                          {!!next ? (
                            <button
                              className="page-link"
                              onClick={(e) =>
                                this.getHistory(
                                  this.props.match.params.id,
                                  pageN
                                )
                              }
                            >
                              <i className="fa fa-arrow-right"></i>
                            </button>
                          ) : (
                            <button className="page-link" disabled>
                              <i className="fa fa-arrow-right"></i>
                            </button>
                          )}
                        </li>
                        <li className="page-item">
                          <div class="btn-group" role="group">
                            <button
                              id="btnGroupDrop1"
                              type="button"
                              class="page-link dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {this.props.t("tire-brands.tire-showing-1")}{" "}
                              {this.state.limit}{" "}
                              {this.props.t("tire-brands.tire-showing-2")}
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="btnGroupDrop1"
                            >
                              <button
                                class="dropdown-item"
                                onClick={(e) => {
                                  this.setState({ limit: 20 }, () => {
                                    this.getHistory(
                                      this.props.match.params.id,
                                      1
                                    );
                                  });
                                }}
                              >
                                {this.props.t("tire-brands.tire-show")} 20
                              </button>
                              <button
                                class="dropdown-item"
                                onClick={(e) => {
                                  this.setState({ limit: 50 }, () => {
                                    this.getHistory(
                                      this.props.match.params.id,
                                      1
                                    );
                                  });
                                }}
                              >
                                {this.props.t("tire-brands.tire-show")} 50
                              </button>
                              <button
                                class="dropdown-item"
                                onClick={(e) => {
                                  this.setState({ limit: 100 }, () => {
                                    this.getHistory(
                                      this.props.match.params.id,
                                      1
                                    );
                                  });
                                }}
                              >
                                {this.props.t("tire-brands.tire-show")} 100
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <br />
        </div>
      </div>
    );
  };

  generateExcel(t) {
    var str = "/export";
    return trackPromise(
      fetch(
        `${
          APIS.API_REST_VEHICLE_HISTORY.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("vehicle_id", this.props.match.params.id) + str
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(t("alerts.retread-item-2"));
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${t("alerts.c-vehicle-item-20")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success(t("alerts.retread-item-4"));
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-6">
                <h3>{this.props.t("client-vehicles.client-his-list")}</h3>
              </div>
              <div className="col-md-6 text-right">
                <button
                  className="btn btn-success"
                  onClick={(event) => this.generateExcel(this.props.t)}
                >
                  <i className="fa fa-file-export"></i>{" "}
                  {this.props.t("globals.exports")}
                </button>
              </div>
            </div>
            <br />
            <div className="text-center v-scroll">
              <table className="table table-striped table-bordered table-sm mb-0">
                <tbody>
                  <tr>
                    <td className="w-50">
                      {this.props.t("client-vehicles.client-item-1")}:
                    </td>
                    <td className="w-50">{this.state.vehicle_plate}</td>
                  </tr>
                  <tr>
                    <td className="w-50">
                      {this.props.t("client-vehicles.client-item-2")}:
                    </td>
                    <td className="w-50">{this.state.vehicle_number}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            {!!this.state.inspections && this.state.inspections.length > 0
              ? this.getLastInspectionsView(
                  this.state.inspections,
                  this.state.worktype
                )
              : null}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(VehicleHistoryList);
