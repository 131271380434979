import $ from "jquery";
import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsVehicle from "../../../constants/actions/Vehicles";
import * as APIS from "../../../constants/apis";

import BrandForm from "./VehicleForms/BrandForm";
import LineForm from "./VehicleForms/LineForm";
import moment from "moment";
import api from "../../../constants/api";

function getVehicle(name, t) {
  let vehicle = {};
  let brands, schemas, groups, types, cities, costcenters, lines = [];
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_VEHICLES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        vehicle = json;
        return fetch(APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.c-vehicle-item-13"));
        }
        return response.json();
      })
      .then(json => brands = json.vehiclebrands)
      .then(() => fetch(APIS.API_REST_LINES.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("brand_id",
        vehicle.brand_id), {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      }))
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.c-vehicle-item-19"));
        }
        return response.json();
      })
      .then(json => lines = json.vehiclelinemodels)
      .then(() => fetch(APIS.API_REST_SCHEMAS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      }))
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.c-vehicle-item-14"));
        }
        return response.json();
      })
      .then(json => schemas = json.vehicleschemas)
      .then(() => fetch(APIS.API_REST_GROUPS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      }))
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.c-vehicle-item-15"));
        }
        return response.json();
      })
      .then(json => groups = json.vehiclegroups)
      .then(() => fetch(APIS.API_REST_TYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      }))
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.c-vehicle-item-16"));
        }
        return response.json();
      })
      .then(json => types = json.vehicletypes)
      .then(() => fetch(APIS.API_REST_COSTCENTERS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      }))
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.c-vehicle-item-17"));
        }
        return response.json();
      })
      .then(json => costcenters = json.costcenters)
      .then(() => fetch(APIS.API_REST_CITIES.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      }))
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.c-vehicle-item-18"));
        }
        return response.json();
      })
      .then(json => {
        cities = json.cities;
        dispatch(getVehicleAndListsSuccess({
          vehicle: vehicle,
          lines: lines,
          brands: brands,
          schemas: schemas,
          groups: groups,
          types: types,
          cities: cities,
          costcenters: costcenters
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };
};

function getLinesList(brandName, props) {
  return dispatch => {
    if (!brandName) {
      dispatch(getVehicleAndListsSuccess({
        lines: [],
        vehicle: props.vehicle,
        brands: props.brands,
        schemas: props.schemas,
        groups: props.groups,
        types: props.types,
        cities: props.cities,
        costcenters: props.costcenters
      }));
    } else {
      trackPromise(fetch(APIS.API_REST_LINES.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("brand_id", brandName), {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(props.t("alerts.c-vehicle-item-13"));
          }
          return response.json();
        })
        .then(json => {
          dispatch(getVehicleAndListsSuccess({
            vehicle: props.vehicle,
            lines: json.vehiclelinemodels,
            brands: props.brands,
            schemas: props.schemas,
            groups: props.groups,
            types: props.types,
            cities: props.cities,
            costcenters: props.costcenters
          }));
        })
        .catch(error => {
          toast.error(error.toString());
        }));
    }
  }
};

function onSaveBrand(context, props, brandForm) {
  let brandReq = {};
  return dispatch => {
    trackPromise(brandForm.current.saveBrand()
      .then(brand => {
        brandReq = brand;
        return fetch(APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(props.t("alerts.c-vehicle-item-13"));
        }
        return response.json();
      })
      .then(json => {
        $("#modalMarca").modal("hide");
        context.setState({
          brand: brandReq.id + "|" + brandReq.name,
          brand_id: brandReq.id
        });
        dispatch(getVehicleAndListsSuccess({
          brands: json.vehiclebrands,
          lines: brandReq.vehiclemodels,
          vehicle: props.vehicle,
          schemas: props.schemas,
          groups: props.groups,
          types: props.types,
          cities: props.cities,
          costcenters: props.costcenters
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  }
};

function onSaveLine(context, brandName, props, lineForm) {
  let lineReq = {};
  return dispatch => {
    trackPromise(lineForm.current.saveLine()
      .then(line => {
        lineReq = line;
        return fetch(APIS.API_REST_LINES.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("brand_id", brandName), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(props.t("alerts.c-vehicle-item-19"));
        }
        return response.json();
      })
      .then(json => {
        $("#modalLinea").modal("hide");
        context.setState({
          linemodel_id: lineReq.id
        });
        dispatch(getVehicleAndListsSuccess({
          lines: json.vehiclelinemodels,
          brands: props.brands,
          vehicle: props.vehicle,
          schemas: props.schemas,
          groups: props.groups,
          types: props.types,
          cities: props.cities,
          costcenters: props.costcenters
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  }
};

export function getVehicleAndListsSuccess(json) {
  return {
    type: constantsVehicle.GET_LISTS_SUCCESS,
    json
  };
};

export function getReqSuccess(vehicles, vehicle, message) {
  const json = {
    vehicles: vehicles,
    vehicle: vehicle,
    message: message
  };
  return {
    type: constantsVehicle.GET_REQUEST_SUCCESS,
    json
  };
};

class VehicleEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.brandForm = React.createRef();
    this.lineForm = React.createRef();
    this.state = {
      fuel: "",
      centrocosto_id: "",
      ciudad_id: "",
      customer_id: localStorage.getItem("GlobalCustomerId"),
      group_id: "",
      linemodel_id: "",
      plate: "",
      schema_id: "",
      brand: "",
      brand_id: "",
      vehiclenumber: "",
      vehicletype_id: "",
      year: "",
      worktype: "",
      spares: 0,
      inactive: false,
      created_at: "",
      group1_id: null,
      group2_id: null,
      groupsOne: [],
      groupsTwo: [],
      vin: "",
      sap: "",
      number_of_curtains: null,
      useful_load: null,
    };

  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getVehicle(id, this.props.t);
    this.getExtraGroups()
  };

  getExtraGroups = () => {
    let gp1, gp2;
    api
      .getGroupsOne(this.state.customer_id)
      .then(res => {
        if (res.vehiclegroups) {
          gp1 = res.vehiclegroups
          api
            .getGroupsTwo(this.state.customer_id)
            .then(res2 => {
              if (res2.vehiclegroups) {
                gp2 = res2.vehiclegroups
                this.setState({ groupsOne: gp1, groupsTwo: gp2 })
              }
            })
        }
      })
  }


  componentDidUpdate(prevProps) {
    if (!prevProps.vehicle || (this.props.vehicle !== prevProps.vehicle)) {
      if (this.props.vehicle && prevProps.vehicle) {
        this.setState({
          fuel: this.props.vehicle.fuel,
          centrocosto_id: this.props.vehicle.centrocosto_id,
          ciudad_id: this.props.vehicle.ciudad_id,
          customer_id: this.props.vehicle.customer_id,
          group_id: !!this.props.vehicle.group_id ?
            this.props.vehicle.group_id : "",
          linemodel_id: this.props.vehicle.linemodel_id,
          plate: this.props.vehicle.plate,
          schema_id: this.props.vehicle.schema_id,
          brand: this.props.vehicle.brand_id + "|" +
            this.props.vehicle.vehiclebrand,
          brand_id: this.props.vehicle.brand_id,
          vehiclenumber: this.props.vehicle.vehiclenumber,
          vehicletype_id: this.props.vehicle.vehicletype_id,
          year: this.props.vehicle.year,
          worktype: this.props.vehicle.worktype,
          spares: this.props.vehicle.spares,
          inactive: this.props.vehicle.inactive,
          created_at: moment(this.props.vehicle.created_at).format("YYYY-MM-DDTHH:mm:SS"),
          group1_id: this.props.vehicle.group1_id,
          group2_id: this.props.vehicle.group2_id,
          vin: this.props.vehicle.vin,
          sap: this.props.vehicle.sap,
          number_of_curtains: this.props.vehicle.number_of_curtains,
          useful_load: this.props.vehicle.useful_load,
        });
      } else {
        window.location.reload()
      }
    };
  };

  formatJson = data => {
    let json = {};
    for (let key in data) {
      if (!!data[key] && !isNaN(data[key]) && key !== "vehiclenumber") {
        json[key] = 1 * data[key];
      } else {
        if (data[key] === null) {
          json[key] = null;
        } else {
          json[key] = data[key];
        }
      }
    }
    return json;
  };

  onSubmitVehicle = (event, vehicles, vehicle, history, getReqSuccess, t) => {
    event.preventDefault();

    const name = this.props.match.params.id;

    const data = {
      ...this.state,
    };

    delete data.groupsOne
    delete data.groupsTwo
    data.created_at = moment.utc(data.created_at).format()
    return trackPromise(fetch(`${APIS.API_REST_VEHICLES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "PUT",
      body: JSON.stringify(this.formatJson(data))
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          history.push("/vehicles");
          getReqSuccess(
            vehicles,
            vehicle,
          );
          return toast.success(this.props.t("alerts.c-vehicle-item-23"))
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };

  onChange = event => {
    switch (event.target.name) {
      case "brand":
        this.setState({
          [event.target.name]: event.target.value,
          brand_id: event.target.value.split("|")[0]
        });
        this.props.getLinesList(event.target.value.split("|")[0], this.props);
        break;
      default:
        this.setState({
          [event.target.name]: event.target.value
        });
      // Do nothing
    };
  };

  render() {
    const { vehicle, vehicles, brands, lines, schemas, groups, cities,
      types, costcenters, history, getReqSuccess } = this.props;
    const { fuel, plate, vehiclenumber, linemodel_id, schema_id, group_id, year,
      vehicletype_id, ciudad_id, centrocosto_id, brand, worktype, spares, inactive, created_at, group1_id, group2_id, groupsOne, groupsTwo, sap, vin, number_of_curtains, useful_load } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!vehicle ?
            <div className="container-fluid mt-100">
              <h3>{this.props.t("client-vehicles.client-edit")}</h3>
              <br /><br />
              <form onSubmit={(event) => this.onSubmitVehicle(event, vehicles, vehicle, history,
                getReqSuccess, this.props.t)}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="dateInput">{this.props.t("client-vehicles.client-item-6")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-6")}
                        id="dateInput"
                        name="created_at"
                        type="datetime-local"
                        value={created_at}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="plateInput">{this.props.t("client-vehicles.client-item-1")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-1")}
                        id="plateInput"
                        name="plate"
                        value={plate}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="numberInput">{this.props.t("client-vehicles.client-item-2")}</label>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-2")}
                        id="numberInput"
                        name="vehiclenumber"
                        value={vehiclenumber}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="vehicletypeInput">{this.props.t("client-vehicles.client-item-8")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-8")}
                        id="vehicletypeInput"
                        name="vehicletype_id"
                        value={vehicletype_id}
                        onChange={this.onChange}
                        required>
                        <option value="">Selecciona el tipo</option>
                        {
                          !!types && types.map(type => {
                            return <option key={type.code}
                              value={type.id}>
                              {type.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="brandInput">{this.props.t("client-vehicles.client-item-16")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-9">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-16")}
                        id="brandInput"
                        name="brand"
                        value={brand}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("client-vehicles.client-select-brand")}</option>
                        {
                          !!brands && brands.map(brand => {
                            return <option key={brand.id}
                              value={brand.id + "|" + brand.name}>
                              {brand.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                    <div className="col-sm-1">
                      <button
                        className="btn btn-primary btn-block"
                        type="button"
                        data-toggle="modal"
                        data-target="#modalMarca">
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="lineInput">{this.props.t("client-vehicles.client-item-9")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-9">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-9")}
                        id="lineInput"
                        name="linemodel_id"
                        value={linemodel_id}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("client-vehicles.client-select-line")}</option>
                        {
                          !!lines && lines.map(line => {
                            return <option key={line.id}
                              value={line.id}>
                              {line.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                    <div className="col-sm-1">
                      <button
                        className="btn btn-primary btn-block"
                        type="button"
                        data-toggle="modal"
                        data-target="#modalLinea"
                        disabled={!brand}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="yearInput">{this.props.t("client-vehicles.client-item-17")}</label>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("client-vehicles.cient-item-17")}
                        id="yearInput"
                        name="year"
                        type="number"
                        value={year}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="schemaInput">{this.props.t("client-vehicles.client-item-10")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-10")}
                        id="schemaInput"
                        name="schema_id"
                        value={schema_id}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("client-vehicles.client-select-scheme")}</option>
                        {
                          !!schemas && schemas.map(schema => {
                            return <option key={schema.id}
                              value={schema.id}>
                              {schema.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="groupInput">{this.props.t("client-vehicles.client-item-11")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-11")}
                        id="groupInput"
                        name="group_id"
                        value={group_id}
                        onChange={this.onChange}>
                        <option value="">{this.props.t("client-vehicles.client-select-group")}</option>
                        {
                          !!groups && groups.map(group => {
                            return <option key={group.id}
                              value={group.id}>
                              {group.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="groupInput">{this.props.t("client-vehicles.client-item-11")} 1</label>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-11")}
                        id="groupInput"
                        name="group1_id"
                        value={group1_id}
                        onChange={this.onChange}>
                        <option value="">{this.props.t("client-vehicles.client-select-group")} 1</option>
                        {
                          !!groupsOne && groupsOne.map(group => {
                            return <option key={group.id}
                              value={group.id}>
                              {group.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="groupInput">{this.props.t("client-vehicles.client-item-11")} 2</label>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-11")}
                        id="groupInput"
                        name="group2_id"
                        value={group2_id}
                        onChange={this.onChange}>
                        <option value="">{this.props.t("client-vehicles.client-select-group")} 2</option>
                        {
                          !!groupsTwo && groupsTwo.map(group => {
                            return <option key={group.id}
                              value={group.id}>
                              {group.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="fuelInput">{this.props.t("client-vehicles.client-item-12")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-12")}
                        id="fuelInput"
                        name="fuel"
                        value={fuel}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("client-vehicles.client-select-measure")}</option>
                        <option value="gasolina magna">{this.props.t("client-vehicles.client-magna")}</option>
                        <option value="acpm/diesel">{this.props.t("client-vehicles.client-acpm")}</option>
                        <option value="gasolina">{this.props.t("client-vehicles.client-fuel")}</option>
                        <option value="electrico">{this.props.t("client-vehicles.client-electric")}</option>
                        <option value="gas natural licuado">{this.props.t("client-vehicles.client-gnl")}</option>
                        <option value="gas natural vehicular">{this.props.t("client-vehicles.client-gnv")}</option>
                        <option value="gas licuado del petroleo">{this.props.t("client-vehicles.client-glp")}</option>
                        <option value="hibrido">{this.props.t("client-vehicles.client-hybrid")}</option>
                        <option value="no_definido">{this.props.t("client-vehicles.client-undefined")}</option>
                        <option value="no_aplica">{this.props.t("client-vehicles.client-apply")}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="cityInput">{this.props.t("client-vehicles.client-item-13")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-13")}
                        id="cityInput"
                        name="ciudad_id"
                        value={ciudad_id}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("client-vehicles.client-select-city")}</option>
                        {
                          !!cities && cities.map(city => {
                            return <option key={city.id}
                              value={city.id}>
                              {city.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="ccostInput">{this.props.t("client-vehicles.client-item-14")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-14")}
                        id="ccostInput"
                        name="centrocosto_id"
                        value={centrocosto_id}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("client-vehicles.client-select-cost")}</option>
                        {
                          !!costcenters && costcenters.map(ccost => {
                            return <option key={ccost.id}
                              value={ccost.id}>
                              {ccost.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="worktypeInput">{this.props.t("client-vehicles.client-item-15")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("client-vehicles.client-item-15")}
                        id="worktypeInput"
                        name="worktype"
                        value={worktype}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("client-vehicles.client-select-measure")}</option>
                        <option value="km">{this.props.t("client-vehicles.client-km")}</option>
                        <option value="hour">{this.props.t("client-vehicles.client-hr")}</option>
                        <option value="miles">{this.props.t("client-vehicles.client-miles")}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="sparesInput">{this.props.t("client-vehicles.client-number-spare")}</label>
                      <br></br><i className="text-info small"></i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        id="sparesInput"
                        name="spares"
                        type="number"
                        value={spares}
                        onChange={this.onChange} />
                      <input classname="form-control"
                        type="hidden"
                        name="inactive"
                        value={inactive}
                        onChange={this.onChange} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="vinInput">VIN</label>
                    <br></br><i className="text-info small"></i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      id="vinInput"
                      name="vin"
                      value={vin}
                      onChange={this.onChange} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="sapInput">SAP</label>
                    <br></br><i className="text-info small"></i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      id="sapInput"
                      name="sap"
                      value={sap}
                      onChange={this.onChange} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="curtainsInput">{this.props.t("client-vehicles.client-number-of-curtains")}</label>
                    <br></br><i className="text-info small"></i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      id="curtainsInput"
                      name="number_of_curtains"
                      type="number"
                      value={number_of_curtains}
                      onChange={this.onChange} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="useful_loadInput">{this.props.t("client-vehicles.client-useful-load")}</label>
                    <br></br><i className="text-info small"></i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      id="useful_loadInput"
                      name="useful_load"
                      type="number"
                      value={useful_load}
                      onChange={this.onChange} />
                  </div>
                </div>
              </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button type="submit"
                      className="btn btn-primary">
                      <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                    </button>
                  </div>
                </div>
              </form>
              <div
                className="modal fade"
                id="modalMarca"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalMarcaTitle"
                aria-hidden="true">
                <div
                  className="modal-dialog"
                  role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="modalMarcaTitle">{this.props.t("client-vehicles.client-save-brand")}
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <BrandForm t={this.props.t} ref={this.brandForm} />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.props.onSaveBrand(this, this.props,
                          this.brandForm)}>
                        <i className="fa fa-save"></i> {this.props.t("globals.edit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="modalLinea"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLineaTitle"
                aria-hidden="true">
                <div
                  className="modal-dialog"
                  role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="modalLineaTitle">
                        {this.props.t("client-vehicles.client-save-line")} {brand.split("|")[1]}
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <LineForm t={this.props.t} brand={brand} ref={this.lineForm} />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.props.onSaveLine(this,
                          brand.split("|")[1], this.props,
                          this.lineForm)}>
                        <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  vehicles: state => state.vehicles,
  vehicle: state => state.vehicle,
  brands: state => state.brands,
  lines: state => state.lines,
  schemas: state => state.schemas,
  groups: state => state.groups,
  types: state => state.types,
  cities: state => state.cities,
  costcenters: state => state.costcenters
});

const mapDispatchToProps = {
  getVehicle, getLinesList, onSaveBrand, onSaveLine,
  getReqSuccess
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(VehicleEditItem));
