import API from "../../constants/api";

export const getGroupsAction = (customer_id, str, typedis) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getGroups(customer_id, str)
        .then(res => {
          if (res.vehiclegroups) {
            dispatch({
              type: typedis,
              payload: res.vehiclegroups
            })
            resolve(res.vehiclegroups)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}

export const createGroupsAction = (customer_id, str, data, typecreate) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .createGroups(customer_id, str, data)
        .then(res => {
          if (res) {
            dispatch({
              type: typecreate,
              payload: res
            })
            resolve(res)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}

export const putGroupAction = (customer_id, id, str, data, typeupdate) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .putGroup(customer_id, id, str, data)
        .then(res => {
          if (res) {
            dispatch({
              type: typeupdate,
              payload: res
            })
            resolve(res)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}

export const deleteGroupAction = (customer_id, id, typedelete) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .deleteGroup(customer_id, id)
        .then(res => {
          if (res.message) {
            dispatch({
              type: typedelete,
              payload: id
            })
            resolve(res.message)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}