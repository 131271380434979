import React, { Component } from "react";
import { AuthUserContext } from "../Auth/Session";
import * as VARIABLES from "../../constants/variables";
import * as ROUTES from "../../constants/routes";
import * as APIS from "../../constants/apis";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";

class ValidateMacros extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            authUser: null,
            search: "",

            MacroDataFleets: [],
            MacroMacroDataFleets: []

        }
    }


    componentDidMount() {
        let authUser = this.props.authUser
        this.setState({ authUser: authUser })

        if(authUser.isMc) {
            this.getMacroFleet(authUser.id)
        }
        if(authUser.isMmc) {
            this.getMacroMacroFleet(authUser.id)
        }
    }


    getMacroFleet = (userId) => {
        trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_GET_USER_ONE.replace("user_id", userId), {
            headers:{
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            },
        })
        .then(response => response.json())
        .then(json => {
            this.setState({ MacroDataFleets: json.client_macro_account })
        })
        .catch(err => {
            toast.error(err.message)
        }))
    }

    getMacroMacroFleet = (userId) => {
        trackPromise(fetch(APIS.API_REST_MACRO_MACRO_ACCOUNT_GET_USER_ONE.replace("user_id", userId), {
            headers:{
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            },
        })
        .then(response => response.json())
        .then(json => {
            this.setState({ MacroMacroDataFleets: json.client_mmc_account })
        })
        .catch(err => {
            toast.error(err.message)
        }))
    }


    saveMacroSelected = (name, id, type, rol) => {
        if (id != null && name != "" && type != "") {
            sessionStorage.setItem("GlobalMacroSelectedName", name)
            sessionStorage.setItem("GlobalMacroSelectedRol", rol)
            sessionStorage.setItem("GlobalMacroSelectedId", id)
            sessionStorage.setItem("GlobalMacroSelectedType", type)
        }


        if (sessionStorage.getItem("GlobalMacroSelectedId")) {
            if(type === "mc") {
                window.location.replace(ROUTES.MACROS_MACRO_ACCOUNT_VIEW_REPORTS)
            } else {
                window.location.replace(ROUTES.MACROS_MACRO_MACRO_ACCOUNT_VIEW_REPORTS)
            }
        }
    }


    renderTableElements = () => {
        const { MacroDataFleets, MacroMacroDataFleets } = this.state;
        return (
            <div style={{ backgroundColor: '#fff'}}>
                <div className="mt-5">
                    {
                    MacroDataFleets && MacroDataFleets.length > 0 || MacroMacroDataFleets && MacroMacroDataFleets.length > 0?
                    <>
                        <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">{this.props.t("macros.m-item-47")}</th>
                                        <th scope="col">{this.props.t("macros.m-item-48")}</th>
                                        <th scope="col">Rol</th>
                                        <th scope="col">{this.props.t("macros.m-item-49")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        MacroDataFleets.length > 0 ?
                                        MacroDataFleets.map(item => (
                                            <tr className="mt-3">
                                                <td>Macro</td>
                                                <td>{item.name_mc}</td>
                                                <td>{item.name_role ? item.name_role : "Gerente"}</td>
                                                <td>
                                                    <button onClick={() => this.saveMacroSelected(item.name_mc, item.macro_account_id, "mc", item.name_role)} className="btn btn-primary">{this.props.t("macros.m-item-50")}</button>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        null
                                    }
                                    {
                                        MacroMacroDataFleets.length > 0 ?
                                        MacroMacroDataFleets.map(item => (
                                            <tr className="mt-3">
                                                <td>Macro Macro</td>
                                                <td>{item.name_mmc}</td>
                                                <td>{item.name_role ? item.name_role : "Rol no asignado"}</td>
                                                <td>
                                                    <button onClick={() => this.saveMacroSelected(item.name_mmc, item.macro_macro_account_id, "mmc", item.name_role)} className="btn btn-primary">{this.props.t("macros.m-item-50")}</button>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        null
                                    }
                                </tbody>
                        </table>
                    </>
                    :
                    null 
                    }
                </div>
            </div>
        )
    }


    inputSearch = (e) => {
        this.setState({ search: e.target.value })
    }


    render() { 
        return ( 
            <div className="container-fluid mt-100">
                <div className="row mb-5">
                    <h2 className="col-md-6">{this.props.t("macros.m-item-51")}</h2>
                    {/* <div className="col-md-5">
                        <input
                        className="form-control mt-2"
                        type="text"
                        id="search"
                        placeholder="Buscar..."
                        onChange={(e) => this.inputSearch(e)}
                        />
                    </div> */}
                </div>

                {this.renderTableElements()}
            </div>
         );
    }
}
 
export default ValidateMacros;