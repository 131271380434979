import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";

import * as APIS from "../../../../constants/apis";

class LineForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name : "",
      code : ""
    };
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  saveLine = () => {
    const data = {
      customer_id: localStorage.getItem("GlobalCustomerId"),
      brand_id: this.props.brand.split("|")[0],
      ...this.state,
    };
    return trackPromise(fetch(`${APIS.API_REST_LINES.replace("customer_id",localStorage.getItem("GlobalCustomerId")).replace("brand_id",this.props.brand.split("|")[0])}`, {
      headers:{
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
        method: "POST",
        body: JSON.stringify(data) })
      .then(response => {
        if(response.status !== 201) {
          throw new Error(this.props.t("alerts.c-vehicle-item-2"));
        }
        return response.json(); })
      .then(json => {
        const status = json.message;
        if(!status) {
          this.setState({
            name: "",
            code: ""
          });
          return Promise.resolve(json);
        } else {
          throw new Error(status);
        } })
      .catch(error => {
        return Promise.reject(error);
      }));
  };

  render() {
    const { name, code } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="codeInput">{this.props.t("gobals.code")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("gobals.code")}
                      id="codeInput"
                      name="code"
                      value={code}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">{this.props.t("client-general.client-item-1")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("client-general.client-item-1")}
                      id="nameInput"
                      name="name"
                      value={name}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
};

export default LineForm;
