import React, { Component } from "react";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import * as APIS from "../../constants/apis";

const INITIAL_STATE = {
  name: "",
  codigo: "",
  tipo: "",
};

class DashboardNewItem extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  };

  onSubmit = event => {
    event.preventDefault();
    trackPromise(fetch(`${APIS.API_REST_DASHBOARDS.replace("customer_id", this.props.match.params.uid)}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify({ ...this.state })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        let message = json.message;
        if (!message) {
          toast.success(this.props.t("alerts.dash-item-7"));
          this.props.history.push(ROUTES.DASHBOARDS.replace(":uid", this.props.match.params.uid))
        } else {
          toast.error(message);
        }
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      name,
      codigo,
      tipo
    } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("dashboard.dash-item-12")}</h3>
            <br /><br />
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="codigoInput">{this.props.t("dashboard.dash-item-13")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      id="codigoInput"
                      name="codigo"
                      value={codigo}
                      onChange={this.onChange}
                      type="tert"
                      className="form-control"
                      placeholder={this.props.t("dashboard.dash-item-13")}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">{this.props.t("dashboard.dash-item-14")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      id="nameInput"
                      name="name"
                      value={name}
                      onChange={this.onChange}
                      type="text"
                      className="form-control"
                      placeholder={this.props.t("dashboard.dash-item-14")}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="tipoInput">{this.props.t("dashboard.dash-item-15")}</label>
                  </div>
                  <div className="col-sm-10">
                    <select className="form-control"
                      placeholder={this.props.t("dashboard.dash-item-15")}
                      id="tipoInput"
                      name="tipo"
                      value={tipo}
                      onChange={this.onChange}>
                      <option value="">
                      {this.props.t("dashboard.dash-item-16")}
                      </option>
                      <option value="reporte">{this.props.t("dashboard.dash-item-17")}</option>
                      <option value="historico">{this.props.t("dashboard.dash-item-18")}</option>
                      <option value="consulta">{this.props.t("dashboard.dash-item-19")}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                    className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const condition = authUser =>
  !!authUser && authUser.roles === ROLES.ADMIN;

export default withAuthorization(condition)(DashboardNewItem);
