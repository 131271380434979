import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../Auth/Session";

import * as constantsCustomer from "../../constants/actions/Customers";
import * as APIS from "../../constants/apis";
import * as ROLES from "../../constants/roles";
import * as VARIABLES from "../../constants/variables";
import { isSuperAdmin } from "../../constants/validations";
import { Modal } from "react-bootstrap";
import Firebase, { withFirebase } from "../Auth/Firebase";

function getCustomer(name, t) {
  return (dispatch) => {
    return trackPromise(
      fetch(`${APIS.API_REST_CUSTOMERS}/${name}`, {
        headers: {
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
      })
        .then((response) => response.json())
        .then((json) => dispatch(getCustomerSuccess({ customer: json })))
        .catch((error) => {
          toast.error(t("alerts.customer-item-10"));
        })
    );
  };
}

export function getCustomerSuccess(json) {
  return {
    type: constantsCustomer.GET_CUSTOMER_SUCCESS,
    json,
  };
}

export function getReqSuccess(customers, customer, message) {
  const json = {
    customers: customers,
    customer: customer,
    message: message,
  };
  return {
    type: constantsCustomer.GET_REQUEST_SUCCESS,
    json,
  };
}

class CustomerEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      resellers: [],
      name: "",
      analista: "",
      max_hours: "",
      maxtravel: "",
      client_type: "",
      product: "",
      active: null,
      reseller_name: null,
      is_reseller: null,
      new_reseller: false,
      reseller_id: null,
      new_reseller_name: "",
      free_trial: null,
      account_manager: "",
      country: "",
      product_state: "",
    };
  }

  async componentDidMount() {
    let responseUsers = await fetch(
      `${APIS.API_REST_USERS_PER_ROL.replace("role_id", 3)}`,
      {
        headers: {
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
      }
    );
    let jsonUsers = await responseUsers.json();
    this.setState({ users: jsonUsers.Users });
    this.getResellers();

    const id = this.props.match.params.id;
    this.props.getCustomer(id, this.props.t);
  }

  getResellers = async () => {
    let responseResellers = await fetch(`${APIS.API_REST_RESELLERS}`, {
      headers: {
        Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
    });
    let jsonResellers = await responseResellers.json();
    this.setState({ resellers: jsonResellers.reseller });
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.customer || this.props.customer !== prevProps.customer) {
      this.setState({
        analista: this.props.customer.analista,
        name: this.props.customer.name,
        max_hours: this.props.customer.max_hours,
        maxtravel: this.props.customer.maxtravel,
        product: this.props.customer.product,
        client_type: this.props.customer.client_type,
        active: this.props.active,
        reseller_name: this.props.customer.reseller_name,
        is_reseller: this.props.customer.reseller_id ? true : null,
        reseller_id: this.props.customer.reseller_id,
        free_trial: this.props.customer.free_trial
          ? this.props.customer.free_trial.replace(/ /g, ".").split(".")[0]
          : null,
        account_manager: this.props.customer.account_manager,
        country: this.props.customer.country,
        product_state: this.props.customer.product_state,
      });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { getReqSuccess, customer, customers } = this.props;

    const name = this.props.match.params.id;
    const data = {
      ...this.state,
    };

    if (data.is_reseller === false) {
      data.reseller_id = null;
    }

    delete data.new_reseller;
    delete data.new_reseller_name;
    delete data.is_reseller;
    delete data.resellers;
    delete data.reseller_name;

    if (data.product === "Demo 2 meses" || data.product === "Demo 14 dias") {
      data.free_trial = data.free_trial + "T23:59:00Z";
    } else {
      data.free_trial = null;
    }

    return trackPromise(
      fetch(`${APIS.API_REST_CUSTOMERS}/${name}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "PUT",
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((json) => {
          const status = json.message;
          if (!status) {
            this.props.history.push("/customers");
            getReqSuccess(customers, customer);
            return toast.success(this.props.t("alerts.customer-item-11"));
          } else {
            throw new Error(status);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeReseller = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  runValidationsCreate = (event) => {
    event.preventDefault();
    const { name, analista, max_hours, maxtravel, client_type, product } =
      this.state;

    if (name === "") {
      return toast.warn(this.props.t("customers.customer-item-10"));
    } else if (max_hours === "") {
      return toast.warn(this.props.t("customers.customer-item-12"));
    } else if (maxtravel === "") {
      return toast.warn(this.props.t("customers.customer-item-13"));
    }

    if (max_hours <= 0 || max_hours > 24)
      return toast.error(this.props.t("customers.customer-item-16"));
    if (maxtravel <= 0 || maxtravel > 2000)
      return toast.error(this.props.t("customers.customer-item-17"));

    this.onSubmit(event);
  };

  onChangeNewReseller = () => {
    this.setState({ new_reseller: true });
  };

  onChangeResellerId = (event) => {
    this.setState({ [event.target.name]: parseInt(event.target.value) });
  };

  onCreateReseller = (event) => {
    event.preventDefault();

    if (this.state.new_reseller_name != "") {
      trackPromise(
        fetch(`${APIS.API_REST_RESELLERS}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "POST",
          body: JSON.stringify({
            name: this.state.new_reseller_name,
          }),
        })
          .then((response) => {
            if (response.status != 201) {
              throw new Error("Error al crear Reseller");
            }
            return response.json();
          })
          .then((json) => {
            if (json.data) {
              toast.success(this.props.t("macros.m-item-36"));
              this.setState({ new_reseller: false, new_reseller_name: "" });
              this.getResellers();
            }
          })
          .catch((error) => {
            toast.error(error.toString());
          })
      );
    }
  };

  modalNewReseller = () => {
    const handleClose = () =>
      this.setState({ new_reseller: false, new_reseller_name: "" });

    const onChangeName = (event) => {
      this.setState({ new_reseller_name: event.target.value });
    };

    return (
      <>
        <Modal
          show={this.state.new_reseller}
          onHide={handleClose}
          dialogClassName="modal-sm-mc"
        >
          <Modal.Header closeButton>
            <Modal.Title>Creacion de Reseller</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.onCreateReseller}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">Nombre</label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder="Nombre del reseller"
                      id="nameInput"
                      name="new_reseller_name"
                      value={this.state.new_reseller_name}
                      onChange={(e) => onChangeName(e)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  render() {
    const { customer } = this.props;
    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          !!customer ? (
            <div className="container-fluid mt-100">
              <h3>{this.props.t("customers.customer-edit")}</h3>
              <br />
              <br />
              <form onSubmit={(event) => this.runValidationsCreate(event)}>
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ||
                authUser.roles === ROLES.ANALYST ? (
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="nameInput">
                          {this.props.t("customers.customer-new-name")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          placeholder={this.props.t(
                            "customers.customer-new-name"
                          )}
                          id="nameInput"
                          name="name"
                          value={this.state.name}
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="hoursInput">
                        {this.props.t("customers.customer-max-hour")}
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        placeholder={this.props.t(
                          "customers.customer-max-hour"
                        )}
                        id="hoursInput"
                        name="max_hours"
                        type="number"
                        value={this.state.max_hours}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="travelInput">
                        {this.props.t("customers.customer-max-route")}
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        className="form-control"
                        placeholder={this.props.t(
                          "customers.customer-max-route"
                        )}
                        id="travelInput"
                        name="maxtravel"
                        type="number"
                        value={this.state.maxtravel}
                        onChange={this.onChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ||
                authUser.roles === ROLES.ANALYST ? (
                  <div className="form-group" id="groupCustomerId">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="customerIdInput">
                          {this.props.t("customers.customer-item-3")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <select
                          className="form-control"
                          placeholder={this.props.t("customers.customer-rol")}
                          id="customerIdInput"
                          name="analista"
                          value={this.state.analista}
                          onChange={this.onChange}
                        >
                          <option value={null}>N/A</option>
                          {!!this.state.users &&
                            this.state.users.map((user) => {
                              return (
                                <option key={user.id} value={user.display_name}>
                                  {user.display_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group mt-3" id="groupCustomerId">
                      <div className="row">
                        <div className="col-sm-2">
                          <label htmlFor="account_manager">
                            {this.props.t("customers.customer-item-24")}
                          </label>
                        </div>
                        <div className="col-sm-10">
                          <select
                            className="form-control"
                            placeholder={this.props.t(
                              "customers.customer-select-manager"
                            )}
                            id="account_manager"
                            name="account_manager"
                            value={this.state.account_manager}
                            onChange={this.onChange}
                          >
                            <option value="">
                              {this.props.t(
                                "customers.customer-select-manager"
                              )}
                            </option>
                            <option value={null}>N/A</option>
                            {VARIABLES.managerEmails.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group" id="groupCustomerId">
                      <div className="row">
                        <div className="col-sm-2">
                          <label htmlFor="customerIdInput">
                            {this.props.t("customers.customer-item-25")}
                          </label>
                        </div>
                        <div className="col-sm-10">
                          <select
                            className="form-control"
                            placeholder={this.props.t(
                              "customers.customer-country"
                            )}
                            id="customerIdInput"
                            name="country"
                            value={this.state.country}
                            onChange={this.onChange}
                          >
                            <option value="">
                              {this.props.t(
                                "customers.customer-select-country"
                              )}
                            </option>
                            <option value={null}>N/A</option>
                            {VARIABLES.COUNTRIES.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-2">
                        <label htmlFor="customerIdInput">
                          {this.props.t("customers.customer-item-4")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <select
                          className="form-control"
                          placeholder={this.props.t("customers.customer-rol")}
                          id="customerIdInput"
                          name="client_type"
                          value={this.state.client_type}
                          onChange={this.onChange}
                        >
                          <option value={null}>N/A</option>
                          {VARIABLES.CLIENT_TYPE.map((item) => {
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-2">
                        <label htmlFor="customerIdInput">
                          {this.props.t("customers.customer-item-5")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <select
                          className="form-control"
                          placeholder={this.props.t("customers.customer-rol")}
                          id="customerIdInput"
                          name="product"
                          value={this.state.product}
                          onChange={this.onChange}
                        >
                          <option value={null}>N/A</option>
                          {VARIABLES.CLIENT_PRODUCT.map((item) => {
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="form-group mt-3" id="groupCustomerId">
                      <div className="row">
                        <div className="col-sm-2">
                          <label htmlFor="customerIdInput">
                            {this.props.t("customers.customer-item-26")}
                          </label>
                        </div>
                        <div className="col-sm-10">
                          <select
                            className="form-control"
                            placeholder={this.props.t("customers.customer-26")}
                            id="customerIdInput"
                            name="product_state"
                            value={this.state.product_state}
                            onChange={this.onChange}
                          >
                            <option value="">
                              {this.props.t(
                                "customers.customer-select-service-state"
                              )}
                            </option>
                            <option value={null}>N/A</option>
                            {VARIABLES.PRODUCT_STATE.map((item) => {
                              return (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    {this.state.product === "Demo 2 meses" ||
                    this.state.product === "Demo 14 dias" ? (
                      <div className="row mt-3">
                        <div className="col-sm-2">
                          <label htmlFor="permissionIdInput">
                            Fecha de terminación demo
                          </label>
                        </div>
                        <div className="col-sm-10">
                          <input
                            id="nameInput"
                            name="free_trial"
                            value={this.state.free_trial}
                            onChange={this.onChange}
                            type="date"
                            className="form-control"
                            placeholder="dias del trial"
                            required={
                              this.state.product === "Demo 2 meses" ||
                              this.state.product === "Demo 14 dias"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2 mt-4">
                      <label htmlFor="permissionIdInput">¿ Reseller ?</label>
                    </div>
                    <div className="col-sm-10 mt-4">
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          name="is_reseller"
                          checked={this.state.is_reseller}
                          onChange={this.onChangeReseller}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          {this.props.t("users.users-yes-no")}{" "}
                          {this.state.is_reseller ? (
                            <a
                              onClick={this.onChangeNewReseller}
                              href="#"
                              className="ml-4"
                            >
                              ¿ No ves un reseller ? crea uno !
                            </a>
                          ) : null}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.is_reseller ? (
                  <div className="form-group" id="groupCustomerId">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="customerIdInput">
                          {this.props.t("customers.customer-item-18")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <select
                          className="form-control"
                          placeholder={this.props.t("customers.customer-18")}
                          id="customerIdInput"
                          name="reseller_id"
                          value={this.state.reseller_id}
                          onChange={this.onChangeResellerId}
                        >
                          <option value="">
                            {this.props.t("customers.customer-item-19")}
                          </option>
                          {this.state.resellers.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button type="submit" className="btn btn-primary">
                      {this.props.t("globals.simple-save")}
                    </button>
                  </div>
                </div>
              </form>
              {this.state.new_reseller ? this.modalNewReseller() : null}
            </div>
          ) : null
        }
      </AuthUserContext.Consumer>
    );
  }
}

const structuredSelector = createStructuredSelector({
  customers: (state) => state.customers,
  customer: (state) => state.customer,
});

const mapDispatchToProps = { getCustomer, getReqSuccess };

const condition = (authUser) =>
  !!authUser &&
  (authUser.roles === ROLES.ADMIN ||
    authUser.roles === ROLES.ANALYST ||
    authUser.roles === ROLES.CUSTOMER);

export default withAuthorization(condition)(
  connect(structuredSelector, mapDispatchToProps)(CustomerEditItem)
);
