import React, { Component } from "react";


const INITIAL_STATE = {
  images: []
};

class NoveltyList extends Component {
  constructor(props) {
    super(props)

    this.state = INITIAL_STATE;
  };

  resetState = () => {
    this.setState(INITIAL_STATE);
  };

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = error => reject(error);
  });

  handleChangeImages = async event => {
    event.persist();

    this.props.images.splice(0, this.props.images.length);
    for(let i = 0; i < this.state.images.length; i++) {
      URL.revokeObjectURL(this.state.images[i]);
    }

    this.state.images.splice(0, this.state.images.length);
    const images = Array.from(event.target.files);
    for(let i = 0; i < images.length; i++) {
      this.props.images.push({ img: await this.toBase64(images[i]) });
    }

    const fmtImages = [];
    for(let i = 0; i < images.length; i++) {
      fmtImages.push(URL.createObjectURL(images[i]));
    }

    this.setState({ images: fmtImages });
  };

  getViewsImages = () => {
    const imagesViews = [];
    for(let i = 0; i < this.state.images.length; i++) {
      imagesViews.push(<div key={i}
        className="col-md-3">
        <div className="card mt-1">
          <div className="card-body">
            <button className="btn btn-danger" onClick={(e) => this.setState({ images: this.state.images.splice(0,i)})}><i className="fa fa-trash"></i></button>
            <img
              className="img-fluid"
              alt={"image"+i}
              src={this.state.images[i]}
            />
          </div>
        </div>
      </div>);
    }
    return imagesViews;
  };

  render() {
    const { novelties, mode } = this.props;
    const { images } = this.state;

    return (
      <div className={mode === "list" ? "row" : "row hidden"}>
        <div className="col-sm-12 mt-5 pull-left">
          <button type="button"
            className="btn btn-primary"
            onClick={() => this.props.onChangeMode("form")}>
            <i className="fa fa-plus"></i> {this.props.t("ins-noveltie.ins-nov-23")}
          </button>
        </div>
        <div className={novelties.length > 0 ?
          "col-sm-12 text-center v-scroll mt-2" :
          "col-sm-12 text-center v-scroll mt-2 hidden"}>
          <table className="table table-striped table-bordered table-sm mb-0">
            <thead>
              <tr>
                <th scope="col">{this.props.t("ins-noveltie.ins-nov-24")}</th>
                <th scope="col">{this.props.t("ins-noveltie.ins-nov-19")}</th>
                <th scope="col">{this.props.t("ins-one.ins-one-22")}</th>
              </tr>
            </thead>
            <tbody>
              {
                novelties.map((novelty, idx) => {
                  return <tr key={idx}>
                    <td>
                      {novelty.novedad.split("|")[1]}
                    </td>
                    <td>
                      {novelty.causa}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.props.onChangeMode("form",
                          idx)}>
                        <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                      </button>&nbsp;
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.props.deleteElement(idx)}>
                        <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
                      </button>
                    </td>
                  </tr>;
                })
              }
            </tbody>
          </table>
        </div>
        <div className={novelties.length > 0 ? "col-sm-12 text-center hidden" :
                                               "col-sm-12 text-center"}>
          <p>{this.props.t("ins-one.ins-one-25")} {this.props.noveltyType === "Desecho" ? this.props.t("ins-one.ins-one-1") : this.props.t("ins-one.ins-one-2")} {this.props.t("ins-one.ins-one-26")}</p>
        </div>
        <div className={novelties.length > 0 ? "col-sm-12 mt-5" :
                                                "col-sm-12 mt-5 hidden"}>
          <div className="row">
            <div className="col-sm-2 pull-left">
              <label htmlFor="images">{this.props.t("ins-one.ins-one-27")}</label>
            </div>
            <div className="col-sm-10">
              <input
                className="form-control"
                type="file"
                id="images"
                name="images"
                accept="image/jpeg"
                onChange={this.handleChangeImages}
                multiple
              />
            </div>
          </div>
        </div>
        <br />
        <div className={novelties.length > 0 ? "col-md-12 mt-3" : "col-md-12 hidden"}>
          <div className={images.length > 0 ? "row mt-5" : "row mt-5 hidden"}>
            { this.getViewsImages().map(view => view) }
          </div>
          <p className={images.length > 0 ? "hidden" : ""}>
          {this.props.t("ins-one.ins-one-28")}
          </p>
        </div>
      </div>
    );
  }
};

export default NoveltyList;     