import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import * as constantsCity from "../../../constants/actions/Cities";
import * as APIS from "../../../constants/apis";

function deleteCity(id, cities, city, t) {
  if(window.confirm("¿Estás seguro de eliminar esta ciudad?")) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_CITIES.replace("customer_id",
                                            localStorage.getItem("GlobalCustomerId"))}/${id}`, {
                                              headers:{
                                                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                "Accept-language": localStorage.getItem("LanguageSelected"),
                                              },
        method: "DELETE" })
        .then(response => {
          if(response.status !== 200) {
            throw new Error(t("alerts.brands-item-5"));
          }
          return response.json(); })
        .then(json => {
          dispatch(getReqSuccess(cities, city, json.message))
              return fetch(APIS.API_REST_CITIES.replace("customer_id",
              localStorage.getItem("GlobalCustomerId")), {
                                                              headers:{
                                                                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                                "Accept-language": localStorage.getItem("LanguageSelected"),
                                                              } })
                .then(response => response.json())
                .then(json => {
                  dispatch(getCitiesSuccess({
                    cities    : json.cities
                  })); })
                .catch(error => {
                  dispatch(getReqError(cities,
                                       city, error.toString()))
                }); })
        .catch(error => {
          dispatch(getReqError(cities, city,
                               error.toString()));
        }));
    };  
  } else {
    return dispatch => {
      dispatch(getCitiesSuccess({
        cities    : cities,
        city      : city
      }));
    };
  }
};

export function getCitiesSuccess(json) {
  return {
    type: constantsCity.GET_CITIES_SUCCESS,
    json
  };
};

export function getReqSuccess(cities, city, message) {
  const json = {
    cities    : cities,
    city      : city,
    message   : message
  };
  return {
    type: constantsCity.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(cities, city, error) {
  const json = {
    cities    : cities,
    city      : city,
    error     : error
  };
  return {
    type: constantsCity.GET_REQUEST_ERROR,
    json
  };
};

class CityDeleteItem extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { cities, city, cityDelete,
            deleteCity } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteCity(cityDelete.id, cities, city, this.props.t)}
                className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({
  cities    : state => state.cities,
  city      : state => state.city
});

const mapDispatchToProps = { deleteCity };

export default connect(structuredSelector, mapDispatchToProps)(CityDeleteItem);
