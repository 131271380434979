import React from "react";
import { validateMetaLanguage } from "../../../utils/validateMetaLanguage";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import Metabase from "../Metabase";

class StatGestion extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    let dash_id_es = 850;
    let dash_id_pt = 890;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Metabase dashboardId={validateMetaLanguage(dash_id_es, dash_id_pt)} />
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(StatGestion);
