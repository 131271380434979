import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import * as constantsVehicleTypes from "../../constants/actions/VehicleTypes";

const initialState = {
  vtypes: [],
  vtype: {},
  message: null,
  error: null
};

function rootReducer(state, action) {
  switch (action.type) {
    case constantsVehicleTypes.GET_VTYPES_SUCCESS:
      return {
        vtypes: action.json.vtypes,
        vtype: {},
        error: null
      };
    case constantsVehicleTypes.GET_VTYPE_SUCCESS:
      return {
        vtype: action.json.vtype,
        vtypes: [],
        error: null
      };
    case constantsVehicleTypes.GET_REQUEST_SUCCESS:
      return {
        vtypes: action.json.vtypes,
        vtype: action.json.vtype,
        message: action.json.message
      };
    case constantsVehicleTypes.GET_REQUEST_ERROR:
      return {
        vtypes: action.json.vtypes,
        vtype: action.json.vtype,
        error: action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
