import $ from "jquery";
import moment from "moment";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import React, { Component } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { trackPromise } from "react-promise-tracker";

import Novelties from "../Novelty";

import * as VALIDATIONS from "../../../constants/validations";
import * as APIS from "../../../constants/apis";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const INITIAL_STATE = {
  mode: "last",
  page: 1,
  resultInspections: [],
  currentData: {},
  updateData: {},
  novelty: null,
  error: null,
};

const numberOfItems = 20;

class MoreInfoTireInfo extends Component {
  constructor(props) {
    super(props);

    this.novelties = React.createRef();
    this.state = { ...INITIAL_STATE };
  }

  getHistoricTire = () => {
    if (isInternetConnected(toast)) return;
    trackPromise(fetch(APIS.API_REST_INSPECTIONS
      .replace("customer_id", localStorage.getItem("GlobalCustomerId"))
      .replace("tire_id", this.props.object.id), { headers: { "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'), "Accept-language": localStorage.getItem("LanguageSelected") } })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Unreachable endpoint");
        }
        return response.json();
      })
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        this.setState({
          resultInspections: json.Inspecciones
        });
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.ins-one-item-28");
        }
        toast.error(err.toString());
        this.setState({ error: err });
      }));
  };

  getLastInspectionDate = (date) => {
    let dateTemp = "";
    dateTemp = date.replace("T", " ");
    dateTemp = dateTemp.replace("Z", "");
    let fmtDate = dateTemp;
    if (date.includes("T")) {
      var f1 = new Date(dateTemp);
      f1.setHours(f1.getHours() - 5);
      var month = (f1.getMonth() < 9 ? '0' : '') + (f1.getMonth() + 1);
      var day = (f1.getDate() < 10 ? '0' : '') + f1.getDate();
      var hour = (f1.getHours() < 10 ? '0' : '') + f1.getHours();
      var minute = (f1.getMinutes() < 10 ? '0' : '') + f1.getMinutes();
      //var second = (f1.getSeconds()<10?'0':'') + f1.getSeconds();
      fmtDate = f1.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute;
    }
    return fmtDate;
  };

  convertDateToUTC = (date) => {
    var f1 = new Date(date);
    f1.setHours(f1.getHours() + 5);
    var month = (f1.getMonth() < 9 ? '0' : '') + (f1.getMonth() + 1);
    var day = (f1.getDate() < 10 ? '0' : '') + f1.getDate();
    var hour = (f1.getHours() < 10 ? '0' : '') + f1.getHours();
    var minute = (f1.getMinutes() < 10 ? '0' : '') + f1.getMinutes();
    var second = (f1.getSeconds() < 10 ? '0' : '') + f1.getSeconds();
    let fmtDate = f1.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second + "Z";
    return fmtDate;
  };

  getLastInspection = (date) => {
    return moment.utc(date).local().locale("es").format("LLL");
  };

  changeMode = (mode, updateData) => {
    this.setState({ ...INITIAL_STATE });
    this.setState({
      mode: mode,
      currentData: cloneDeep(updateData),
      updateData: cloneDeep(updateData),
    });

    switch (mode) {
      case "last":
        this.getHistoricTire();
        break;
      case "form":
        if (!!updateData.novedad_id) {
          if (isInternetConnected(toast)) return;

          trackPromise(fetch(`${APIS.API_REST_NOVELTIES_TIRE
            .replace("customer_id", localStorage.getItem("GlobalCustomerId"))
            .replace("tire_id", this.props.object.id)
            }/${updateData.novedad_id}`, {
            headers: {
              "Accept-language": localStorage.getItem("LanguageSelected"),
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken')
            }
          })
            .then(response => {
              if (response.status !== 200) {
                throw new Error(this.props.t("alerts.ins-one-item-45"));
              }
              return response.json();
            })
            .then(json => {
              this.setState({ novelty: json });
            })
            .catch(error => {
              toast.error(error.message);
            }));
        }
        break;
      default:
      // Do nothing
    }
  };

  getRows = (rows, page) => {
    const orderedRows = rows.sort((a, b) => {
      return b.fc_ultima_inspeccion - a.fc_ultima_inspeccion;
    });
    const chunks = chunk(orderedRows, numberOfItems);
    if (!!chunks[page - 1]) {
      return chunks[page - 1];
    }
    return [];
  };

  onPageChange = event => {
    this.setState({ page: event.selected + 1 });
  };

  getUpdateData = (data) => {
    return {
      id: data.id,
      tecnico: (data.tecnico || ""),
      order: data.order,
      analista: this.props.authUser.email,
      //fc_ultima_inspeccion     : this.props.object.fc_ultima_inspeccion,
      customer_id: this.props.object.customer_id,
      tirecode: this.props.object.codigo,
      vehiculo: this.props.object.vehiculo,
      fc_ultima_inspeccion: data.fc_ultima_inspeccion,
      consecutivo_inspecciones: data.consecutivo_inspecciones,
      kilometers: !!data.kilometers ? data.kilometers : "",
      type: data.tipo_inspeccion,
      ult_prof_centro: data.ult_prof_centro,
      ult_prof_centro_exterior: data.ult_prof_centro_exterior,
      ult_prof_interior: data.ult_prof_interior,
      ult_presion: data.ult_presion,
      novedad_id: (1 * data.novedades_id || null),
      DOT: this.props.object.DOT,
      observaciones:
        !!data.observaciones &&
          !!data.observaciones[0] &&
          !!data.observaciones[0].observaciones ?
          data.observaciones[0].observaciones : "",
      observaciones_analista:
        !!data.observaciones &&
          !!data.observaciones[0] &&
          !!data.observaciones[0].observaciones_analista ?
          data.observaciones[0].observaciones_analista : ""
    };
  };

  deleteIns = () => {
    if (window.confirm(this.props.t("alerts.ins-one-item-37"))) {
      const codeTire = this.props.object.id;
      trackPromise(fetch(`${APIS.API_REST_INSPECTIONS
        .replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id", codeTire)}`, {
        method: "DELETE", headers: { "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'), "Accept-language": localStorage.getItem("LanguageSelected"), }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.ins-one-item-38"));
          }
          return response.json();
        })
        .then(() => {
          return fetch(APIS.API_REST_INSPECTIONS
            .replace("customer_id", localStorage.getItem("GlobalCustomerId"))
            .replace("tire_id", codeTire), {
            headers: { "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'), "Accept-language": localStorage.getItem("LanguageSelected"), }
          });
        })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.ins-one-item-39"));
          }
          return response.json();
        })
        .then(json => this.setState({ resultInspections: json.Inspecciones }))
        .catch(error => {
          toast.error(error.message);
        }));
    }
  };

  getLastInspectionsView = result => {
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th>{this.props.t("ins-one.ins-one-more-tire-1")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-2")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-3")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-4")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-5")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-6")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-7")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-8")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-9")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-10")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-11")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-12")}</th>
                  <th>{this.props.t("client-tires.client-his-16")}</th>
                  <th>{this.props.t("client-tires.client-his-18")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-13")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-14")}</th>
                  <th>{this.props.t("client-tires.client-his-17")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-15")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-16")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-17")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-18")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.getRows(result, this.state.page).map((ins, i) => {
                    return <tr key={i}>
                      <td>{ins.tipo_inspeccion}</td>
                      <td>{ins.ult_prof_centro_exterior}</td>
                      <td>{ins.ult_prof_centro}</td>
                      <td>{ins.ult_prof_interior}</td>
                      <td>{ins.worktype}</td>
                      <td>{ins.kilometers}</td>
                      <td>{ins.km_recorrido}</td>
                      <td>{ins.km_mm === null ? null : ins.km_mm.toFixed(2)}</td>
                      <td>{ins.km_cost === null ? null : ins.km_cost.toFixed(2)}</td>
                      <td>{ins.mm_cost}</td>
                      <td>{ins.mm_gastados}</td>
                      <td>{!!ins.vehicle_plate ? "Montada" : ins.ubicacion_final}</td>
                      <td>{!!ins.vehiculo ? ins.vehiculo : "No aplica"}</td>
                      <td>{!!ins.vehicle_plate ? ins.vehicle_plate : "No aplica"}</td>
                      <td>{!!ins.posicion_final ? ins.posicion_final : this.props.t("alerts.ins-one-item-40")}</td>
                      <td>{ins.ult_presion}</td>
                      <td>{ins.order === null ? "No aplica" : ins.order}</td>
                      <td>{ins.updated_by ? ins.updated_by : ins.created_by}</td>
                      <td>{moment.utc(ins.fc_ultima_inspeccion).local()
                        .locale("es").format("LLL")}</td>
                      <td>{moment.utc(ins.fecha_actualizacion).local()
                        .locale("es").format("LLL")}</td>
                      <td>
                        {!!this.props.authUser.permissions.edit ?
                          <button className="btn btn-primary"
                            onClick={() => this.changeMode("form",
                              this.getUpdateData(ins))}>
                            <i className="fa fa-edit"></i>
                          </button> : null}&nbsp;
                        {
                          i === 0 ?
                            !!this.props.authUser.permissions.delete ?
                              <button
                                className="btn btn-danger"
                                onClick={this.deleteIns}>
                                <i className="fa fa-trash"></i>
                              </button> : null
                            : null
                        }
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
          <br />
          {
            Math.ceil(result.length / numberOfItems) > 1 ?
              <ReactPaginate
                pageCount={Math.ceil(result.length / numberOfItems)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={3}
                onPageChange={this.onPageChange}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link text-white"}
                previousLinkClassName={"page-link text-white"}
                nextLinkClassName={"page-link text-white"}
                activeClassName={"active"} /> :
              null
          }
        </div>
      </div>
    );
  };

  onSubmitUpdateInspection = event => {
    event.preventDefault();
    if (isInternetConnected(toast)) return;

    let finalUpdateData = cloneDeep(this.state.updateData);

    finalUpdateData.fc_ultima_inspeccion = this.convertDateToUTC(finalUpdateData.fc_ultima_inspeccion);

    const inspectionData = {
      ...finalUpdateData,
      customer_id: this.props.object.customer_id,
      codigo: this.props.object.codigo,
      tireid: this.props.object.id,
      tecnico: finalUpdateData.tecnico
    };

    const data = {
      ...inspectionData,
      observaciones: [
        {
          observaciones: inspectionData.observaciones,
          observaciones_analista: inspectionData.observaciones_analista
        }
      ]
    };
    delete inspectionData.observaciones;
    delete inspectionData.observaciones_analista;
    delete data.observaciones_analista;

    trackPromise(this.saveNovelties(data)
      .then(noveltiesId => {
        return fetch(`${APIS.API_REST_INSPECTIONS_MADRE.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id", data.tireid).replace("madre_id", data.id)}`, {
          method: "PUT",
          body: JSON.stringify({
            ...data,
            novedades_id: !!noveltiesId ? noveltiesId : null,
          }),
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Content-Type": "application/json",
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        toast.success(this.props.t("alerts.ins-one-item-41"));
        this.props.onSubmitTire();
        this.changeMode("last");
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.ins-one-item-28");
        }
        toast.error(err.toString());
        this.setState({ error: err });
      }));
  };

  onChangeForm = event => {
    let updateData = { ...this.state.updateData };
    updateData[event.target.name] = event.target.value;
    this.setState({ updateData: updateData });
  };

  closeMoreInfoModal = () => {
    $("#tireHistory").modal("toggle");
  };

  openMoreInfoModal = () => {
    $("#tireHistory").modal("show");
  };

  getFormView = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <form onSubmit={this.onSubmitUpdateInspection}>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="ult_prof_centro_exterior">{this.props.t("ins-one.ins-one-more-tire-19")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="number"
                    id="ult_prof_centro_exterior"
                    name="ult_prof_centro_exterior"
                    placeholder={this.state.currentData.ult_prof_centro_exterior}
                    value={this.state.updateData.ult_prof_centro_exterior}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="ult_prof_centro">{this.props.t("ins-one.ins-one-more-tire-20")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="number"
                    id="ult_prof_centro"
                    name="ult_prof_centro"
                    placeholder={this.state.currentData.ult_prof_centro}
                    value={this.state.updateData.ult_prof_centro}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="ult_prof_interior">{this.props.t("ins-one.ins-one-more-tire-21")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="number"
                    id="ult_prof_interior"
                    name="ult_prof_interior"
                    placeholder={this.state.currentData.ult_prof_interior}
                    value={this.state.updateData.ult_prof_interior}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="kilometers">{this.props.t("ins-one.ins-one-more-tire-22")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="number"
                    id="kilometers"
                    name="kilometers"
                    placeholder={this.state.currentData.kilometers}
                    value={this.state.updateData.kilometers}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="presion">{this.props.t("ins-one.ins-one-more-tire-23")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="number"
                    id="ult_presion"
                    name="ult_presion"
                    placeholder={this.state.currentData.ult_presion}
                    value={this.state.updateData.ult_presion}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="order">{this.props.t("ins-one.ins-one-more-tire-38")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="string"
                    id="order"
                    name="order"
                    placeholder={this.state.currentData.order}
                    value={this.state.updateData.order}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="observaciones">{this.props.t("ins-one.ins-one-more-tire-24")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="text"
                    id="observaciones"
                    name="observaciones"
                    placeholder={this.state.currentData.observaciones}
                    value={this.state.updateData.observaciones}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="observaciones_analista">
                    {this.props.t("ins-one.ins-one-more-tire-25")}
                  </label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="text"
                    id="observaciones_analista"
                    name="observaciones_analista"
                    placeholder={this.state.currentData.observaciones_analista}
                    value={this.state.updateData.observaciones_analista}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="tecnico">{this.props.t("ins-one.ins-one-more-tire-26")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="text"
                    id="tecnico"
                    name="tecnico"
                    placeholder={this.state.currentData.tecnico}
                    value={this.state.updateData.tecnico}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="tecnico">{this.props.t("ins-one.ins-one-more-tire-27")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="text"
                    id="fc_ultima_inspeccion"
                    name="fc_ultima_inspeccion"
                    placeholder={this.state.currentData.fc_ultima_inspeccion}
                    value={this.getLastInspectionDate(this.state.updateData.fc_ultima_inspeccion)}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              {
                !!this.state.novelty ?
                  <div className="col-md-12 mt-3 mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <h3>{this.props.t("ins-one.ins-one-more-tire-28")}</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p>{this.props.t("ins-one.ins-one-more-tire-29")}:</p>
                      </div>
                      <div className="col-md-6">
                        {this.state.novelty.name}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p>{this.props.t("ins-one.ins-one-more-tire-30")}:</p>
                      </div>
                      <div className="col-md-6">
                        {this.state.novelty.tiponovedad}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p>{this.props.t("ins-one.ins-one-more-tire-31")}</p>
                      </div>
                      <div className="col-md-6">
                        {this.state.novelty.cause}
                      </div>
                    </div>
                    {
                      !!this.state.currentData.DOT ?
                        <div className="row">
                          <div className="col-md-6">
                            <p>{this.props.t("ins-one.ins-one-more-tire-32")}</p>
                          </div>
                          <div className="col-md-6">
                            {this.state.currentData.DOT}
                          </div>
                        </div> : null
                    }
                    {/* <div className="row">
                  {
                    !!this.state.novelty.urlfoto.replace(" ", "") &&
                      JSON.parse(this.state.novelty.urlfoto.replace(" ", "")
                                 .replace(/'/g, "\""))
                      .map((imageUrl, idx) => {
                      return <div key={idx} className="col-md-4">
                        <div className="card mt-1">
                          <div className="card-body">
                            <img
                              src={imageUrl}
                              alt={"image"+idx}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    })
                  }
                  </div> */}
                  </div> : null
              }
              <div className="row">
                <div className="col-sm-12 text-center">
                  <button type="button"
                    className="btn btn-primary text-white"
                    data-toggle="modal"
                    data-target={"#posicionNovelties" +
                      (1 * this.props.object.posicion_montaje)}
                    onClick={() => {
                      this.closeMoreInfoModal();
                      $("#posicionNovelties" + (1 * this.props.object.posicion_montaje)).modal()
                      $("#noveltyTypePos" + (1 * this.props.object.posicion_montaje) + " option[value=Desecho]").hide();
                      $("#DOT" + (1 * this.props.object.posicion_montaje)).hide();
                      $("#noveltyTypePos" + (1 * this.props.object.posicion_montaje) + " option[value=Novedad]").show();
                      $("#noveltyTypePos" + (1 * this.props.object.posicion_montaje) + " option[value=Antecedente]").show();
                      $("#noveltyTypePos" + (1 * this.props.object.posicion_montaje) + " option[value=Movimiento]").show();
                      $("#noveltyTypePos" + (1 * this.props.object.posicion_montaje)).val("Novedad");
                      let state = this.novelties.current.state;
                      state.noveltyType = "Novedad"
                    }}>
                    <i className="fa fa-bullhorn"></i> {this.props.t("ins-one.ins-one-more-tire-33")}
                  </button>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-6 pull-left">
                  <button className="btn btn-primary"
                    onClick={() => this.changeMode("last")}>
                    <i className="fa fa-arrow-left"></i> {this.props.t("ins-one.ins-one-more-tire-34")}
                  </button>
                </div>
                <div className="col-sm-6 pull-right">
                  <button type="submit"
                    className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("ins-one.ins-one-more-tire-35")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  getCurrentView = (mode, resultInspections, updateData) => {
    if (mode === "last") {
      return this.getLastInspectionsView(resultInspections);
    }
    if (mode === "form") {
      return this.getFormView(updateData);
    }
    return null;
  };

  getFmtNovelties = (novelties) => {
    let fmtNovelties = [];

    for (let i = 0; i < novelties.length; i++) {
      fmtNovelties.push({
        seccion_llanta: novelties[i].seccion.split("|")[1],
        motivo: novelties[i].novedad.split("|")[1],
        ligado_a: novelties[i].novedad.split("|")[2],
        codigo_causa: novelties[i].causa.split("|")[0],
        posible_causa: novelties[i].causa.split("|")[1]
      });
    }

    return fmtNovelties;
  };

  getFmtdJsonNovelties = (data) => {
    const state = this.novelties.current.state;

    const images = state.insImages;
    const noveltyType = state.noveltyType;
    const fecha_subida = moment(state.fecha_subida).utc().format();
    const DOT = state.DOT;
    const novelties = this.getFmtNovelties(state.insNovelties);

    return {
      //fecha_subida: moment().utc().format(),
      fecha_subida: fecha_subida,
      DOT: DOT,
      tiponovedad: noveltyType,
      customer_id: data.customer_id,
      codigo: data.tirecode,
      inspector: data.tecnico,
      noveltys: novelties,
      pictures: images
    }
  };

  saveNovelties = (data) => {
    const fmtNovelties = this.getFmtdJsonNovelties(data);
    if (!fmtNovelties.tiponovedad || fmtNovelties.noveltys.length === 0) {
      return Promise.resolve(false);
    }

    return trackPromise(fetch(`${APIS.API_REST_NOVELTIES_TIRE.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id", data.tireid)}`, {
      method: "POST",
      body: JSON.stringify(this.getFmtdJsonNovelties(data)),
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        this.novelties.current.resetState();
        return json.novedades[0].id;
      })
      .catch(error => {
        throw new Error(error);
      }));
  };

  render() {
    const { mode, resultInspections, updateData } = this.state;

    return (
      <div className="col-sm-6 text-center">
        <button className="btn btn-primary text-white"
          data-toggle="modal"
          data-target="#tireHistory"
          onClick={this.getHistoricTire}>
          <i className="fa fa-chart-line"></i> {this.props.t("ins-one.ins-one-more-tire-36")}
        </button>
        <div className="modal fade"
          id="tireHistory"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="moreInfoModalLabel"
          aria-hidden="true">
          <div className="modal-dialog"
            role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark"
                  id="moreInfoModalLabel">
                  {this.props.t("ins-one.ins-one-more-tire-37")}
                </h5>
                <button type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Cerrar">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-dark">
                {this.getCurrentView(mode, resultInspections, updateData)}
              </div>
            </div>
          </div>
        </div>
        <Novelties
          t={this.props.t}
          ref={this.novelties}
          records={this.props.records}
          waste={this.props.waste}
          novelties={this.props.novelties}
          movements={this.props.movements}
          openMoreInfoModal={this.openMoreInfoModal}
          posicion={1 * this.props.object.posicion_montaje}
        />
      </div>
    );
  }
};

export default MoreInfoTireInfo;