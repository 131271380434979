import {
  REQ_TIRES_SUCCES,
  REQ_TIRES_ERROR,
  CLEAR_TIRES_ERROR,
  CLEAR_TIRES_SUCCESS,
  REQ_TIRE_SUCCESS,
  PUT_TIRE_SUCCESS,
  DELETE_TIRE_SUCCESS,
} from "../types/TiresTypes";

const INITIAL_STATE = {
  tires: [],
  total: null,
  next: null,
  previus: null,
  error: null,
  tire: [],
};

export const tiresReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQ_TIRES_SUCCES:
      return {
        ...state,
        tires: action.payload.tires,
        total: action.payload.total,
        next: action.payload.next,
        previus: action.payload.previus,
      };
    case REQ_TIRES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case REQ_TIRE_SUCCESS:
      return {
        ...state,
        tire: action.payload,
      };
    case PUT_TIRE_SUCCESS:
      if (state.tires.length > 0) {
        state.tires.findIndex((elemento, indice) => {
          if (elemento.id === action.payload.id) {
            state.tires.splice(indice, 1, action.payload);
          }
        });
      }
      return {
        ...state,
      };
    case DELETE_TIRE_SUCCESS:
      let newTires = state.tires.filter(
        (tire) => tire.id !== action.payload.id
      );
      return {
        ...state,
        tires: newTires,
      };
    case CLEAR_TIRES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_TIRES_SUCCESS:
      return {
        ...state,
        tires: action.payload,
      };
    default:
      return state;
  }
};
