import React, { useState } from "react";
import { cuseDispatch, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import * as APIS from "../../constants/apis";
import { deleteUserAction } from "../../redux/actions/UsersAction";



const GroupDeleteItem = ({ t, id, clearDelete }) => {
  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteUser = () => {
  if (window.confirm(t("alerts.users-item-1"))) {
    setIsDeleting(true)
    dispatch(deleteUserAction(id))
      .then(res => {
        // if (res.message.includes("Usuario Eliminado")) {
          toast.success(t("alerts.users-item-3"));
          setIsDeleting(false)
          clearDelete(id)
        // }
      })
      .catch(error => {
        if (error.response.status !== 200) {
          toast.error(error.response.data.message.toString())
          setIsDeleting(false)
        }
      })
    }
  }

  if (isDeleting) {
    return (
      <button class="btn btn-danger" type="button" disabled={isDeleting ? true : false}>
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
      </button>
    )
  }

  return (
    <button onClick={() => deleteUser()}
      className="btn btn-danger" >
      {/* <i className="fa fa-trash"></i> */}
      {t("globals.delete")}
    </button >
  )
}

export default GroupDeleteItem
