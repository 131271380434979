import React from 'react'
import { AuthUserContext, withAuthorization } from '../Auth/Session'

const emailPermission = ["w.herrera@ruedata.com"]

const AdminMassive = () => {
  return (
    <AuthUserContext>
      {authUser => (
        <div className='container-fluid mt-100'>Admin</div>
      )}
    </AuthUserContext>
  )
}

const condition = authUser =>
  !!authUser && emailPermission.includes(authUser.email);

export default withAuthorization(condition)(AdminMassive)