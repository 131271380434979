import moment from "moment";
import "moment/locale/es";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import * as APIS from "../../constants/apis";
import * as VALIDATIONS from "../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const INITIAL_STATE = {
  inspectionDate: "",
  combustibleType: "",
  totalValue: "",
  totalQuantity: "",
  kilometers: "",
  suggestions: []
};

class MeasurementNew extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    if(!!this.props.inspectionDate){
      this.setState({inspectionDate: this.props.inspectionDate})
    }
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    event.preventDefault();
    if (isInternetConnected(toast)) return;

    let data = {
      fecha_inspeccion: moment(this.state.inspectionDate).utc().format(),
      fueltype: this.state.combustibleType,
      valor_total: 1 * this.state.totalValue,
      quantity: 1 * this.state.totalQuantity,
      kilometers: 1 * this.state.kilometers
    };

    const vehicleNumber = this.props.carNumber;
    let label = "Medición";
    if(this.props.type === "fuel"){
      label = "Combustible"
    }
    trackPromise(fetch(APIS.API_REST_FUELS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))
    .replace("vehicle_id", vehicleNumber), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        this.props.onSubmitCar(event,0);
        toast.success(label + this.props.t("alerts.fuel-item-4"));
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.fuel-item-5");
        }
        toast.error(err.toString());
        this.setState({ error: err });
      }));
  };

  render() {
    let display = this.props.type === "fuel" ? "block" : "none";
    return (
      <form onSubmit={this.onSubmit}
        className="mb-5">
        <div className="row form-group">
          <div className="col-sm-2">
            <label htmlFor="inputInspectionDate">{this.props.t("measure.client-item-9")}</label>
            <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
          </div>
          <div className="col-sm-10">
            <input name="inspectionDate"
              type="datetime-local"
              id="inputInspectionDate"
              className="form-control"
              value={this.state.inspectionDate}
              onChange={this.onChange}
              placeholder={this.props.t("measure.client-item-9")}
              required />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-2">
            <label htmlFor="inputKilometers">{this.props.t("measure.client-item-17")}</label>
            <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
          </div>
          <div className="col-sm-10">
            <input name="kilometers"
              type="number"
              id="inputKilometers"
              className="form-control"
              value={this.state.kilometers}
              onChange={this.onChange}
              placeholder="" />
          </div>
        </div>
        <div style={{ display: display}}>
          <div className="row form-group">
            <div className="col-sm-2">
              <label htmlFor="inputCombustibleType">{this.props.t("measure.client-item-18")}</label>
              <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
            </div>
            <div className="col-sm-10">
              <select name="combustibleType"
                id="inputCombustibleValue"
                className="form-control"
                value={this.state.combustibleType}
                onChange={this.onChange}>
                <option value="">{this.props.t("measure.client-item-21")}</option>
                      <option value="acpm/diesel">{this.props.t("client-vehicles.client-acpm")}</option>
                      <option value="gasolina">{this.props.t("client-vehicles.client-fuel")}</option>
                      <option value="electrico">{this.props.t("client-vehicles.client-electric")}</option>
                      <option value="gas natural licuado">{this.props.t("client-vehicles.client-gnl")}</option>
                      <option value="gas natural vehicular">{this.props.t("client-vehicles.client-gnv")}</option>
                      <option value="gas licuado del petroleo">{this.props.t("client-vehicles.client-glp")}</option>
                      <option value="hibrido">{this.props.t("client-vehicles.client-hybrid")}</option>
                      <option value="no_definido">{this.props.t("client-vehicles.client-undefined")}</option>
                      <option value="no_aplica">{this.props.t("client-vehicles.client-apply")}</option>
              </select>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-2">
              <label htmlFor="inputTotalValue">{this.props.t("measure.client-item-19")}</label>
              <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
            </div>
            <div className="col-sm-10">
              <input name="totalValue"
                type="number"
                id="inputTotalValue"
                className="form-control"
                value={this.state.totalValue}
                onChange={this.onChange}
                placeholder={this.props.t("measure.client-item-19")} />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-2">
              <label htmlFor="inputTotalQuantity">{this.props.t("measure.client-item-20")}</label>
              <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
            </div>
            <div className="col-sm-10">
              <input name="totalQuantity"
                type="number"
                id="inputTotalQuantity"
                className="form-control"
                value={this.state.totalQuantity}
                onChange={this.onChange}
                placeholder={this.props.t("measure.client-item-20")} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <button className="btn btn-primary"
              type="submit">
              <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
              </button>
          </div>
        </div>
      </form>
    );
  }
};

export default MeasurementNew;
