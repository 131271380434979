import React, { Component } from "react";

class Loader2 extends Component {
  render() {
    return (
    <div class="spinner-border text-primary text-center" role="status">
        <span class="sr-only">Cargando...</span>
    </div>
    );
  };
};

export default Loader2;