import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsRetreadCatalogue from
  "../../../constants/actions/RetreadCatalogues";
import * as APIS from "../../../constants/apis";
import * as ROLES from "../../../constants/roles";

import RetreadCatalogueDeleteItem from "./RetreadCatalogueDeleteItem";
import moment from "moment";

function getRetreadCatalogues(catalogues, next, previus, catalogue, page, inactive, search, limit, t) {
  let previusReq, nextReq, cataloguesReq = [], total = 0;
  return dispatch => {
    var str = "";
    if (page !== "0") {
      str = "?page=" + page + "&limit=" + limit;
      if (inactive)
        str = str + "&inactive=" + inactive
      if (search !== "")
        str = str + "&query=" + search
    }
    return trackPromise(fetch(APIS.API_REST_RETREADCATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.retread-item-1"))
        }
        return response.json();
      })
      .then(json => {
        cataloguesReq = json.RetreadCatalogue
        previusReq = json.previus
        nextReq = json.next
        total = json.total
        dispatch(getRetreadCataloguesSuccess({
          previus: previusReq,
          next: nextReq,
          total: total,
          catalogues: cataloguesReq,
          catalogue: catalogue
        }));
      })
      .catch(error => {
        dispatch(getReqError({
          catalogues: catalogues,
          catalogue: catalogue,
          previus: previus,
          next: next,
          total: total,
          error: error.message
        }));
      }));
  };
};

export function getRetreadCataloguesSuccess(json) {
  return {
    type: constantsRetreadCatalogue.GET_RECATALOGUES_SUCCESS,
    json
  };
};

export function getReqError(json) {
  return {
    type: constantsRetreadCatalogue.GET_REQUEST_ERROR,
    json
  };
};

class RetreadCataloguesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterInactive: "",
      search: "",
      page: 1,
      limit: 20
    };
  };

  componentDidMount() {
    const { catalogues, next, previus, catalogue } = this.props;
    this.props.getRetreadCatalogues(catalogues, next, previus, catalogue, 1, false, "", this.state.limit, this.props.t);
  };

  getRetreadCataloguesList = (catalogues, next, previus, catalogue, inactive, authUser) => {
    const { search } = this.state;
    if (!catalogues) return null;
    let pageP, pageN, str;
    if (!!previus) {
      str = previus;
      pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    if (!!next) {
      str = next;
      pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    let pages = [];
    let start, end;
    let totalPages = Math.ceil(this.props.total / this.state.limit);
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i)
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
      start = Math.max(1, (this.state.page - 4));
      end = Math.min(totalPages, (this.state.page + 5));

      if (start === 1) {
        end = 10;
      } else if (end === totalPages) {
        start = (totalPages - 9);
      }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("tire-catalogue.tire-item-1")}</th>
              <th scope="col">{this.props.t("tire-catalogue.tire-item-2")}</th>
              <th scope="col">{this.props.t("tire-catalogue.tire-item-3")}</th>
              <th scope="col">{this.props.t("tire-catalogue.tire-item-4")}</th>
              <th scope="col">{this.props.t("tire-catalogue.tire-item-15")}</th>
              <th scope="col">{this.props.t("tire-catalogue.tire-item-14")}</th>
              <th scope="col">{this.props.t("tire-catalogue.tire-item-5")}</th>
            </tr>
          </thead>
          <tbody>
            {
              catalogues.map(catalogue => {
                return <tr key={catalogue.id}>
                  <td>
                    {catalogue.marca}
                  </td>
                  <td>
                    {catalogue.modelo}
                  </td>
                  <td>
                    {catalogue.dimention}
                  </td>
                  <td>
                    {catalogue.precio}
                  </td>
                  <td>
                    {catalogue.updated_by}
                  </td>
                  <td>
                    {
                      catalogue.updated_at ? moment.utc(catalogue.updated_at).local()
                        .locale("es").format("LLL") : null
                    }
                  </td>
                  <td>
                    <Link to={"/retreadcatalogues/" + catalogue.id}
                      className="btn btn-primary">
                      <i className="fa fa-eye"></i> {this.props.t("globals.see")}
                    </Link>&nbsp;
                    {!!authUser.permissions.edit ?
                      <Link to={"/retreadcatalogues/edit/" +
                        catalogue.id}
                        className="btn btn-primary">
                        <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                      </Link> : null}&nbsp;
                    {!!authUser.permissions.delete ?
                      <RetreadCatalogueDeleteItem
                        t={this.props.t}
                        catalogueDelete={catalogue}
                      /> : null}
                  </td>
                </tr>
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="6">
                <nav aria-label="Navigation">
                  <ul class="mt-3 pagination justify-content-center">
                    <li class="page-item">
                      {
                        !!previus ?
                          <button
                            className="page-link"
                            onClick={(e) => { this.setState({ page: pageP }); this.props.getRetreadCatalogues(catalogues, next, previus, catalogue, pageP, inactive, search, this.state.limit) }}>
                            <i className="fa fa-arrow-left"></i>
                          </button>
                          :
                          <button
                            className="page-link"
                            disabled>
                            <i className="fa fa-arrow-left"></i>
                          </button>
                      }
                    </li>
                    {
                      links.map((page, index) => {
                        let clase = (page == this.state.page) ? "active" : ""
                        return <li className={"page-item " + clase}>
                          <button class="page-link" onClick={(e) => { this.setState({ page: page }); this.props.getRetreadCatalogues(catalogues, next, previus, catalogue, page, inactive, search, this.state.limit) }}>{page}</button>
                        </li>
                      })
                    }
                    <li class="page-item">
                      {
                        !!next ?
                          <button
                            className="page-link"
                            onClick={(e) => { this.setState({ page: pageN }); this.props.getRetreadCatalogues(catalogues, next, previus, catalogue, pageN, inactive, search, this.state.limit) }}>
                            <i className="fa fa-arrow-right"></i>
                          </button>
                          :
                          <button
                            className="page-link"
                            disabled>
                            <i className="fa fa-arrow-right"></i>
                          </button>
                      }
                    </li>
                    <li className="page-item">
                      <div class="btn-group" role="group">
                        <button id="btnGroupDrop1" type="button" class="page-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {this.props.t("tire-brands.tire-showing-1")} {this.state.limit} {this.props.t("tire-brands.tire-showing-2")}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <button class="dropdown-item"
                            onClick={(e) => { this.setState({ limit: 20 }, () => { this.props.getRetreadCatalogues(catalogues, next, previus, catalogue, 1, inactive, search, this.state.limit) }) }}>{this.props.t("tire-brands.tire-show")} 20</button>
                          <button class="dropdown-item"
                            onClick={(e) => { this.setState({ limit: 50 }, () => { this.props.getRetreadCatalogues(catalogues, next, previus, catalogue, 1, inactive, search, this.state.limit) }) }}>{this.props.t("tire-brands.tire-show")} 50</button>
                          <button class="dropdown-item" onClick={(e) => { this.setState({ limit: 100 }, () => { this.props.getRetreadCatalogues(catalogues, next, previus, catalogue, 1, inactive, search, this.state.limit) }) }}>{this.props.t("tire-brands.tire-show")} 100</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  generateExcel() {
    return trackPromise(fetch(APIS.API_REST_RETREADCATALOGUES_EXCEL.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(this.props.t("alerts.retread-item-2"));
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.props.t("alerts.retread-item-3")}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success(this.props.t("alerts.retread-item-4"));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onChange = (event) => {
    this.setState({
      search: event.target.value
    });
  };

  render() {
    const { search } = this.state;
    const { catalogues, catalogue, next, previus, total, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("tire-catalogue.tire-catalogue-list")}</h3>
              </div>
              <div className="col-md-4 pull-right">
                <div className="input-group mb-3 flex-nowrap">
                  <input
                    aria-describedby="btnSearch"
                    className="form-control"
                    type="text"
                    id="search"
                    placeholder={this.props.t("globals.search-input")}
                    onChange={this.onChange}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" id="btnSearch"
                      onClick={(event) => this.props.getRetreadCatalogues(catalogues, next, previus, catalogue, 1, false, this.state.search, this.state.limit)}>
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                {!!authUser.permissions.create ?
                  <Link to={"/retreadcatalogues/new"}
                    className="btn btn-primary">
                    <i className="fa fa-plus"></i> {this.props.t("globals.register")}
                  </Link> : null}
              </div>
              <div className="col-md-6 text-right">
                {authUser.roles === ROLES.ADMIN || authUser.roles === ROLES.ANALYST || authUser.roles === ROLES.CUSTOMER ?
                  <Link to={"/retreadcatalogues/multiple"}
                    className="btn btn-primary">
                    <i className="fa fa-upload"></i> {this.props.t("globals.register-massive")}
                  </Link> : null}&nbsp;
                <button className="btn btn-success"
                  onClick={(event) => this.generateExcel()}>
                  <i className="fa fa-file-export"></i> {this.props.t("globals.exports")}
                </button>
              </div>
            </div>
            <br /><br />
            <div className="row">
              <div className="col-md-12">
                <p>{this.props.t("tire-brands.tire-total-catalogues")} <b>{!!total ? total : 0}</b></p>
              </div>
            </div>
            <br />
            {this.getRetreadCataloguesList(catalogues, next, previus, catalogue, this.state.filterInactive, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  catalogues: state => state.catalogues,
  catalogue: state => state.catalogue,
  next: state => state.next,
  previus: state => state.previus,
  total: state => state.total,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getRetreadCatalogues };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(RetreadCataloguesList));
