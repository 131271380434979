import { trackPromise } from 'react-promise-tracker'
import api from '../../constants/api'
import { DATA_CUSTOMERS_SUCCESS, DATA_REQUEST_ERROR, SET_LOADING_CUSTOMER, SET_LOADING_CUSTOMER_SUCCES, DATA_CUSTOMERS_MACRO_SUCCES, DATA_CUSTOMERS_SEARCH_SUCCESS, DATA_CUSTOMERS_SEARCH_CLEAR_SUCCESS, DATA_CUSTOMERS_PAGINATION_SUCCESS } from '../types/CustomerTypes'

export const getCusomerAction = () => {
    return (dispatch) => {
        trackPromise(
            api
                .getCustomers()
                .then((data) => {
                    dispatch({
                        type: DATA_CUSTOMERS_SUCCESS,
                        payload: data.Customers
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        )
    }
}

export const getCustomersAction = (str) => {
    return dispatch =>
        new Promise((resolve, reject) => {
            api
                .getCustomers2(str)
                .then(res => {
                    if (res) {
                        dispatch({
                            type: DATA_CUSTOMERS_PAGINATION_SUCCESS,
                            payload: res
                        })
                        resolve(res)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
}

export const registerCustomerAction = (data) => {
    return (dispatch) => {
        trackPromise(
            api
                .registerCustomers(data)
                .then((data) => {
                    // dispatch({
                    //     type: DATA_CUSTOMERS_SUCCESS,
                    //     payload: data.Customers
                    // })
                })
                .catch((err) => {
                    console.log(err)
                })
        )
    }
}
export const getCusomerMacroAction = (id, str) => {
    return dispatch =>
        new Promise((resolve, reject) => {
            api
                .getCustomerMacro(id, str)
                .then((data) => {
                    if (data) {
                        dispatch({
                            type: DATA_CUSTOMERS_MACRO_SUCCES,
                            payload: data.Customers
                        })
                        resolve(data.Customers)
                    }
                })
                .catch((err) => {
                    reject(err)
                })
        })
}

export const getCustomerSearchAction = (text, active) => {
    return (dispatch) => {
        api
            .getSearchCustomers(text, active)
            .then((data) => {
                dispatch({
                    type: DATA_CUSTOMERS_SEARCH_SUCCESS,
                    payload: data.Customers
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }
}

export const setCustomerLoadingAction = () => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING_CUSTOMER,
            payload: true
        })
    }
}

export const setCustomerSearchClearAction = () => {
    return (dispatch) => {
        dispatch({
            type: DATA_CUSTOMERS_SEARCH_CLEAR_SUCCESS,
            payload: []
        })
    }
}

export const setCustomerLoadingActionSucces = () => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING_CUSTOMER_SUCCES,
            payload: false
        })
    }
}