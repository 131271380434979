import React, { Component } from "react";
import { AuthUserContext, withAuthentication } from "../Auth/Session";
import * as VARIABLES from "../../constants/variables";
import * as ROUTES from "../../constants/routes";
import * as APIS from "../../constants/apis";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import Metabase from "../Reports/Metabase";

class MacroMacro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRol: "",
      macroId: null,
      macroName: "",
      macroType: "",

      fleetsPerMacroAllId: [],
      conditionalMacro: "",


      conditionalMacro: "",
      isShowGerente: true,
      isShowJefe: true,
    }
  }

  componentDidMount() {
    this.setState({
      userRol: sessionStorage.getItem("GlobalMacroSelectedRol"),
      macroId: sessionStorage.getItem("GlobalMacroSelectedId"),
      macroName: sessionStorage.getItem("GlobalMacroSelectedName"),
      macroType: sessionStorage.getItem("GlobalMacroSelectedType"),
    })

    if (sessionStorage.getItem("GlobalMacroSelectedId")) {
      this.getFleetsPerMacro(sessionStorage.getItem("GlobalMacroSelectedId"))
    }
  }

  getFleetsPerMacro = (macroId) => {
    let str = `?type_account=mmc_account`
    trackPromise(fetch(APIS.API_REST_MACRO_ACCOUNT_GET_CLIENTS_MACRO.replace("macro_id", macroId) + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        let allIds = []
        for (let i = 0; i < json.client_macro_accounts.length; i++) {
          allIds.push(json.client_macro_accounts[i].client_id)
        }

        if (allIds.length > 0) {
          this.setState({ fleetsPerMacroAllId: allIds })
        }
      })
      .catch(err => {
        toast.error(err.message)
      }))
  }

  onChangeConditional = (value) => {
    this.setState({ conditionalMacro: value })
  }

  onChangeConditional = (value) => {
    this.setState({ conditionalMacro: value });
  };

  onChangeIsShowGerente = (event, value) => {
    event.persist();
    this.setState({ isShowGerente: value });
  };

  onChangeIsShowJefe = (event, value) => {
    event.persist();
    this.setState({ isShowJefe: value });
  };

  renderMacroGerente = (authUser) => {
    return (
      <div style={{ backgroundColor: "#fff", marginTop: "100px" }}>
        <div className="justify-content-center">
          {this.state.isShowGerente ? (
            <div>
              <h5 style={{ textAlign: "center", paddingTop: "100px" }}>
                {this.props.t("landing.lan-item-4")}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {this.props.t("landing.lan-item-3")}
                </span>
              </h5>
              <ul
                class="nav nav-pills mb-3 justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class=" nav-link"
                    data-toggle="pill"
                    href="#pills-zona"
                    role="tab"
                    aria-controls="pills-zona"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("zona-0")}
                  >
                    {this.props.t("landing.lan-item-8")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class=" nav-link"
                    id="pills-zona-1-tab"
                    data-toggle="pill"
                    href="#pills-zona-1"
                    role="tab"
                    aria-controls="pills-zona-1"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("zona-1")}
                  >
                    {this.props.t("landing.lan-item-9")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class=" nav-link"
                    id="pills-zona-2-tab"
                    data-toggle="pill"
                    href="#pills-zona-2"
                    role="tab"
                    aria-controls="pills-zona-2"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("zona-2")}
                  >
                    {this.props.t("landing.lan-item-10")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class=" nav-link"
                    id="pills-zona-3-tab"
                    data-toggle="pill"
                    href="#pills-zona-3"
                    role="tab"
                    aria-controls="pills-zona-3"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("zona-3")}
                  >
                    {this.props.t("landing.lan-item-11")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class=" nav-link"
                    id="pills-zona-4-tab"
                    data-toggle="pill"
                    href="#pills-zona-4"
                    role="tab"
                    aria-controls="pills-zona-4"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("zona-4")}
                  >
                    {this.props.t("landing.lan-item-12")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class=" nav-link"
                    id="pills-zona-5-tab"
                    data-toggle="pill"
                    href="#pills-zona-5"
                    role="tab"
                    aria-controls="pills-zona-5"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("zona-5")}
                  >
                    {this.props.t("landing.lan-item-13")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class=" nav-link"
                    id="pills-zona-6-tab"
                    data-toggle="pill"
                    href="#pills-zona-6"
                    role="tab"
                    aria-controls="pills-zona-6"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("zona-6")}
                  >
                    {this.props.t("landing.lan-item-15")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class=" nav-link"
                    id="pills-zona-7-tab"
                    data-toggle="pill"
                    href="#pills-zona-7"
                    role="tab"
                    aria-controls="pills-zona-7"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("zona-7")}
                  >
                    {this.props.t("landing.lan-item-16")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class=" nav-link"
                    id="pills-zona-8-tab"
                    data-toggle="pill"
                    href="#pills-zona-8"
                    role="tab"
                    aria-controls="pills-zona-8"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("zona-8")}
                  >
                    {this.props.t("landing.lan-item-18")}
                  </a>
                </li>
                <li class="nav-item">
                  <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                    {this.props.t("landing.lan-item-19")}
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#pills-zona-9" role="tab" aria-controls="pills-zona-9" onClick={() => this.onChangeConditional('zona-9')}>
                        {this.props.t("landing.lan-item-17")}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              {/* <a class="text-center nav-link" id="pills-zona-6-tab" data-toggle="pill" href="#pills-zona-6" role="tab" aria-controls="pills-zona-6" aria-selected="false">
                            {this.props.t("landing.lan-item-2")}
                            </a> */}
            </div>
          ) : (
            <div>
              <h5 style={{ textAlign: "center", paddingTop: "100px" }}>
                {this.props.t("landing.lan-item-4")}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {this.props.t("landing.lan-item-5")}
                </span>
              </h5>

              <ul
                class="nav nav-pills mb-3 justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-region-tab"
                    data-toggle="pill"
                    href="#pills-region"
                    role="tab"
                    aria-controls="pills-region"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("region-0")}
                  >
                    {this.props.t("landing.lan-item-8")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-region-1-tab"
                    data-toggle="pill"
                    href="#pills-region-1"
                    role="tab"
                    aria-controls="pills-region-1"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("region-1")}
                  >
                    {this.props.t("landing.lan-item-9")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-region-2-tab"
                    data-toggle="pill"
                    href="#pills-region-2"
                    role="tab"
                    aria-controls="pills-region-2"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("region-2")}
                  >
                    {this.props.t("landing.lan-item-10")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-region-3-tab"
                    data-toggle="pill"
                    href="#pills-region-3"
                    role="tab"
                    aria-controls="pills-region-3"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("region-3")}
                  >
                    {this.props.t("landing.lan-item-11")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-region-4-tab"
                    data-toggle="pill"
                    href="#pills-region-4"
                    role="tab"
                    aria-controls="pills-region-4"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("region-4")}
                  >
                    {this.props.t("landing.lan-item-12")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-region-5-tab"
                    data-toggle="pill"
                    href="#pills-region-5"
                    role="tab"
                    aria-controls="pills-region-5"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("region-5")}
                  >
                    {this.props.t("landing.lan-item-13")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-region-6-tab"
                    data-toggle="pill"
                    href="#pills-region-6"
                    role="tab"
                    aria-controls="pills-region-6"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("region-6")}
                  >
                    {this.props.t("landing.lan-item-15")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-region-7-tab"
                    data-toggle="pill"
                    href="#pills-region-7"
                    role="tab"
                    aria-controls="pills-region-7"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("region-7")}
                  >
                    {this.props.t("landing.lan-item-16")}
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-region-8-tab"
                    data-toggle="pill"
                    href="#pills-region-8"
                    role="tab"
                    aria-controls="pills-region-8"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("region-8")}
                  >
                    {this.props.t("landing.lan-item-18")}
                  </a>
                </li>
                <li class="nav-item">
                  <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                    {this.props.t("landing.lan-item-19")}
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#pills-region-9" role="tab" aria-controls="pills-region-9" aria-selected='false' onClick={() => this.onChangeConditional('region-9')}>
                        {this.props.t("landing.lan-item-17")}
                      </a>
                    </div>
                  </div>
                </li>
                {/* <a class="text-center nav-link" id="pills-region-6-tab" data-toggle="pill" href="#pills-region-6" role="tab" aria-controls="pills-region-6" aria-selected="false">
                            {this.props.t("landing.lan-item-2")}
                            </a> */}
              </ul>
            </div>
          )}
        </div>

        <div
          className="container-fluid px-0"
          style={{ backgroundColor: "#fff" }}
        >
          <div class="tab-content" id="pills-tabContent">
            {
              this.state.isShowGerente ?
                <div>
                  {/* ZONA */}
                  {this.state.conditionalMacro === "zona-0" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona"
                      role="tabpanel"
                      aria-labelledby="pills-zona-tab"
                    >
                      <Metabase
                        dashboardId={863}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "zona-1" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona-1"
                      role="tabpanel"
                      aria-labelledby="pills-zona-1-tab"
                    >
                      <Metabase
                        dashboardId={864}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "zona-2" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona-2"
                      role="tabpanel"
                      aria-labelledby="pills-zona-2-tab"
                    >
                      <Metabase
                        dashboardId={865}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "zona-3" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona-3"
                      role="tabpanel"
                      aria-labelledby="pills-zona-3-tab"
                    >
                      <Metabase
                        dashboardId={866}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "zona-4" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona-4"
                      role="tabpanel"
                      aria-labelledby="pills-zona-4-tab"
                    >
                      <Metabase
                        dashboardId={867}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "zona-5" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona-5"
                      role="tabpanel"
                      aria-labelledby="pills-zona-5-tab"
                    >
                      <Metabase
                        dashboardId={868}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "zona-6" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona-6"
                      role="tabpanel"
                      aria-labelledby="pills-zona-6-tab"
                    >
                      <Metabase
                        dashboardId={1012}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "zona-7" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona-7"
                      role="tabpanel"
                      aria-labelledby="pills-zona-7-tab"
                    >
                      <Metabase
                        dashboardId={1025}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "zona-8" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona-8"
                      role="tabpanel"
                      aria-labelledby="pills-zona-8-tab"
                    >
                      <Metabase
                        dashboardId={1085}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "zona-9" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-zona-9"
                      role="tabpanel"
                      aria-labelledby="pills-zona-9-tab"
                    >
                      <Metabase
                        dashboardId={1090}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}

                  {/* <div class="tab-pane fade" id="pills-zona-6" role="tabpanel" aria-labelledby="pills-zona-6-tab">
                                    <Metabase dashboardId={834} clientIds={VARIABLES.MACRO_ACCOUNTS_CEMEX_PERMISSIONS.users.gerente.find(row => row.email.includes(authUser.email)).clientIds} />
                                </div> */}
                  {/* ZONA */}
                </div>
                :
                <div>
                  {/* REGION */}
                  {this.state.conditionalMacro === "region-0" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region"
                      role="tabpanel"
                      aria-labelledby="pills-region-tab"
                    >
                      <Metabase
                        dashboardId={869}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "region-1" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region-1"
                      role="tabpanel"
                      aria-labelledby="pills-region-1-tab"
                    >
                      <Metabase
                        dashboardId={870}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "region-2" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region-2"
                      role="tabpanel"
                      aria-labelledby="pills-region-2-tab"
                    >
                      <Metabase
                        dashboardId={871}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "region-3" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region-3"
                      role="tabpanel"
                      aria-labelledby="pills-region-3-tab"
                    >
                      <Metabase
                        dashboardId={872}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "region-4" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region-4"
                      role="tabpanel"
                      aria-labelledby="pills-region-4-tab"
                    >
                      <Metabase
                        dashboardId={873}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "region-5" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region-5"
                      role="tabpanel"
                      aria-labelledby="pills-region-5-tab"
                    >
                      <Metabase
                        dashboardId={874}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "region-6" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region-6"
                      role="tabpanel"
                      aria-labelledby="pills-region-6-tab"
                    >
                      <Metabase
                        dashboardId={1014}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "region-7" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region-7"
                      role="tabpanel"
                      aria-labelledby="pills-region-7-tab"
                    >
                      <Metabase
                        dashboardId={874}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "region-8" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region-8"
                      role="tabpanel"
                      aria-labelledby="pills-region-8-tab"
                    >
                      <Metabase
                        dashboardId={1086}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "region-9" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-region-9"
                      role="tabpanel"
                      aria-labelledby="pills-region-9-tab"
                    >
                      <Metabase
                        dashboardId={1083}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}

                  {/* <div class="tab-pane fade" id="pills-region-6" role="tabpanel" aria-labelledby="pills-region-6-tab">
                                        <Metabase dashboardId={834} clientIds={VARIABLES.MACRO_ACCOUNTS_CEMEX_PERMISSIONS.users.gerente.find(row => row.email.includes(authUser.email)).clientIds} />
                                    </div> */}
                  {/* REGION */}
                </div>
            }
          </div>
        </div>
      </div>
    );
  };

  renderMacroJefe = (authUser) => {
    return (
      <div style={{ backgroundColor: "#fff", marginTop: "100px" }}>
        <div className="justify-content-center">
          {this.state.isShowJefe ? (
            <div>
              <h5 style={{ textAlign: "center", paddingTop: "100px" }}>
                {this.props.t("landing.lan-item-4")}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {this.props.t("landing.lan-item-6")}
                </span>
              </h5>

              <ul
                class="nav nav-pills mb-3 justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-plaza-tab"
                    data-toggle="pill"
                    href="#pills-plaza"
                    role="tab"
                    aria-controls="pills-plaza"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("plaza-0")}
                  >
                    {this.props.t("landing.lan-item-8")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-plaza-1-tab"
                    data-toggle="pill"
                    href="#pills-plaza-1"
                    role="tab"
                    aria-controls="pills-plaza-1"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("plaza-1")}
                  >
                    {this.props.t("landing.lan-item-9")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-plaza-2-tab"
                    data-toggle="pill"
                    href="#pills-plaza-2"
                    role="tab"
                    aria-controls="pills-plaza-2"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("plaza-2")}
                  >
                    {this.props.t("landing.lan-item-10")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-plaza-3-tab"
                    data-toggle="pill"
                    href="#pills-plaza-3"
                    role="tab"
                    aria-controls="pills-plaza-3"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("plaza-3")}
                  >
                    {this.props.t("landing.lan-item-11")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-plaza-4-tab"
                    data-toggle="pill"
                    href="#pills-plaza-4"
                    role="tab"
                    aria-controls="pills-plaza-4"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("plaza-4")}
                  >
                    {this.props.t("landing.lan-item-12")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-plaza-5-tab"
                    data-toggle="pill"
                    href="#pills-plaza-5"
                    role="tab"
                    aria-controls="pills-plaza-5"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("plaza-5")}
                  >
                    {this.props.t("landing.lan-item-13")}
                  </a>
                </li>


                {/* <a class="nav-link" id="pills-plaza-6-tab" data-toggle="pill" href="#pills-plaza-6" role="tab" aria-controls="pills-plaza-6" aria-selected="false">
                            {this.props.t("landing.lan-item-2")}
                            </a> */}
              </ul>
            </div>
          ) : (
            <div>
              <h5 style={{ textAlign: "center", paddingTop: "100px" }}>
                {this.props.t("landing.lan-item-4")}{" "}
                <span style={{ fontWeight: "bold" }}>
                  {this.props.t("landing.lan-item-7")}
                </span>
              </h5>

              <ul
                class="nav nav-pills mb-3 justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-regional-tab"
                    data-toggle="pill"
                    href="#pills-regional"
                    role="tab"
                    aria-controls="pills-regional"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("regional-0")}
                  >
                    {this.props.t("landing.lan-item-8")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-regional-1-tab"
                    data-toggle="pill"
                    href="#pills-regional-1"
                    role="tab"
                    aria-controls="pills-regional-1"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("regional-1")}
                  >
                    {this.props.t("landing.lan-item-9")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-regional-2-tab"
                    data-toggle="pill"
                    href="#pills-regional-2"
                    role="tab"
                    aria-controls="pills-regional-2"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("regional-2")}
                  >
                    {this.props.t("landing.lan-item-10")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-regional-3-tab"
                    data-toggle="pill"
                    href="#pills-regional-3"
                    role="tab"
                    aria-controls="pills-regional-3"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("regional-3")}
                  >
                    {this.props.t("landing.lan-item-11")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-regional-4-tab"
                    data-toggle="pill"
                    href="#pills-regional-4"
                    role="tab"
                    aria-controls="pills-regional-4"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("regional-4")}
                  >
                    {this.props.t("landing.lan-item-12")}
                  </a>
                </li>


                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-regional-5-tab"
                    data-toggle="pill"
                    href="#pills-regional-5"
                    role="tab"
                    aria-controls="pills-regional-5"
                    aria-selected="false"
                    onClick={() => this.onChangeConditional("regional-5")}
                  >
                    {this.props.t("landing.lan-item-13")}
                  </a>
                </li>


                {/* <li class="nav-item">
                          <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
                          {this.props.t("landing.lan-item-2")}
                          </a>
                        </li> */}
              </ul>
            </div>
          )}
        </div>
        <div
          className="container-fluid px-0"
          style={{ backgroundColor: "#fff" }}
        >
          <div class="tab-content" id="pills-tabContent">
            {
              this.state.isShowJefe ?
                <div>
                  {/* ZONA   */}
                  {this.state.conditionalMacro === "plaza-0" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-plaza"
                      role="tabpanel"
                      aria-labelledby="pills-plaza-tab"
                    >
                      <Metabase
                        dashboardId={863}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "plaza-1" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-plaza-1"
                      role="tabpanel"
                      aria-labelledby="pills-plaza-1-tab"
                    >
                      <Metabase
                        dashboardId={864}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "plaza-2" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-plaza-2"
                      role="tabpanel"
                      aria-labelledby="pills-plaza-2-tab"
                    >
                      <Metabase
                        dashboardId={865}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "plaza-3" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-plaza-3"
                      role="tabpanel"
                      aria-labelledby="pills-plaza-3-tab"
                    >
                      <Metabase
                        dashboardId={866}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "plaza-4" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-plaza-4"
                      role="tabpanel"
                      aria-labelledby="pills-plaza-4-tab"
                    >
                      <Metabase
                        dashboardId={867}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "plaza-5" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-plaza-5"
                      role="tabpanel"
                      aria-labelledby="pills-plaza-5-tab"
                    >
                      <Metabase
                        dashboardId={868}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}

                  {/* <div class="tab-pane fade" id="pills-plaza-6" role="tabpanel" aria-labelledby="pills-plaza-6-tab">
                            <Metabase dashboardId={834} clientIds={VARIABLES.MACRO_ACCOUNTS_CEMEX_PERMISSIONS.users.jefeMantenimiento.find(row => row.email.includes(authUser.email)).clientIds} />
                            </div> */}
                  {/* ZONA */}
                </div>
                :
                <div>
                  {/* REGION */}
                  {this.state.conditionalMacro === "regional-0" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-regional"
                      role="tabpanel"
                      aria-labelledby="pills-regional-tab"
                    >
                      <Metabase
                        dashboardId={875}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "regional-1" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-regional-1"
                      role="tabpanel"
                      aria-labelledby="pills-regional-1-tab"
                    >
                      <Metabase
                        dashboardId={876}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "regional-2" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-regional-2"
                      role="tabpanel"
                      aria-labelledby="pills-regional-2-tab"
                    >
                      <Metabase
                        dashboardId={877}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "regional-3" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-regional-3"
                      role="tabpanel"
                      aria-labelledby="pills-regional-3-tab"
                    >
                      <Metabase
                        dashboardId={878}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "regional-4" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-regional-4"
                      role="tabpanel"
                      aria-labelledby="pills-regional-4-tab"
                    >
                      <Metabase
                        dashboardId={879}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}
                  {this.state.conditionalMacro === "regional-5" ? (
                    <div
                      class="tab-pane fade show active"
                      id="pills-regional-5"
                      role="tabpanel"
                      aria-labelledby="pills-regional-5-tab"
                    >
                      <Metabase
                        dashboardId={880}
                        clientIds={this.state.fleetsPerMacroAllId}
                      />
                    </div>
                  ) : null}

                  {/* <div class="tab-pane fade" id="pills-region-profile" role="tabpanel" aria-labelledby="pills-region-profile-tab">
                                <Metabase dashboardId={834} clientIds={VARIABLES.MACRO_ACCOUNTS_CEMEX_PERMISSIONS.users.jefeMantenimiento.find(row => row.email.includes(authUser.email)).clientIds} />
                            </div> */}
                  {/* REGION */}
                </div>
            }
          </div>
        </div>
      </div>
    );
  };


  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <>
            {this.state.userRol === "Gerente" ? (
              <>
                <div>
                  <div
                    className="btn-group btn-group-toggle float-right mt-3 mr-5"
                    data-toggle="buttons"

                  >
                    <label
                      className="btn btn-primary active"
                      onClick={(e) => this.onChangeIsShowGerente(e, true)}
                    >
                      <input
                        type="radio"
                        name="isActive"
                        value={true}
                        autoComplete="off"
                      />
                      {this.props.t("landing.lan-item-14")}{" "}
                      {this.props.t("landing.lan-item-3")}
                    </label>
                    <label
                      className="btn btn-primary"
                      onClick={(e) => this.onChangeIsShowGerente(e, false)}
                    >
                      <input
                        type="radio"
                        name="isActive"
                        value={false}
                        autoComplete="off"
                      />{" "}
                      {this.props.t("landing.lan-item-14")}{" "}
                      {this.props.t("landing.lan-item-5")}
                    </label>
                  </div>
                </div>
                {this.renderMacroGerente(authUser)}
              </>
            ) : this.state.userRol === "Jefe Mantenimiento" ? (
              <>
                <div>
                  <div
                    className="btn-group btn-group-toggle float-right mt-3 mr-5"
                    data-toggle="buttons"
                  >
                    <label
                      className="btn btn-primary active"
                      onClick={(e) => this.onChangeIsShowJefe(e, true)}
                    >
                      <input
                        type="radio"
                        name="isActive"
                        value={true}
                        autoComplete="off"
                      />
                      {this.props.t("landing.lan-item-14")}{" "}
                      {this.props.t("landing.lan-item-6")}
                    </label>
                    <label
                      className="btn btn-primary"
                      onClick={(e) => this.onChangeIsShowJefe(e, false)}
                    >
                      <input
                        type="radio"
                        name="isActive"
                        value={false}
                        autoComplete="off"
                      />{" "}
                      {this.props.t("landing.lan-item-14")}{" "}
                      {this.props.t("landing.lan-item-7")}
                    </label>
                  </div>
                </div>
                {this.renderMacroJefe(authUser)}
              </>
            ) : null}
          </>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withAuthentication(MacroMacro);
