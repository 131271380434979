import moment from "moment";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import React, { Component } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { trackPromise } from "react-promise-tracker";
import $ from "jquery";
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';


import * as VALIDATIONS from "../../../constants/validations";
import * as APIS from "../../../constants/apis";

const valConnection       = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const INITIAL_STATE = {
  mode              : "",
  page              : 1,
  resultInspections : [],
  resultTireInspections: [],
  currentData       : {},
  updateData        : {},
  error             : null
};

const numberOfItems = 20;

class MoreInfoCarInfo extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE }; 
  }

  getLastInspectionDate = (date) => {
    let dateTemp = "";
    dateTemp = date.replace("T", " ");
    dateTemp = dateTemp.replace("Z", "");
    let fmtDate = dateTemp;
    if(date.includes("T")){
      var f1 = new Date(dateTemp);
      f1.setHours(f1.getHours() - 5);
      var month = (f1.getMonth()<9?'0':'') + (f1.getMonth() + 1);
      var day = (f1.getDate()<10?'0':'') + f1.getDate();
      var hour = (f1.getHours()<10?'0':'') + f1.getHours();
      var minute = (f1.getMinutes()<10?'0':'') + f1.getMinutes();
      //var second = (f1.getSeconds()<10?'0':'') + f1.getSeconds();
      fmtDate = f1.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute;
    }
    return fmtDate;
  };

  convertDateToUTC = (date) => {
    var f1 = new Date(date);
    f1.setHours(f1.getHours() + 5);
    var month = (f1.getMonth()<9?'0':'') + (f1.getMonth() + 1);
    var day = (f1.getDate()<10?'0':'') + f1.getDate();
    var hour = (f1.getHours()<10?'0':'') + f1.getHours();
    var minute = (f1.getMinutes()<10?'0':'') + f1.getMinutes();
    var second = (f1.getSeconds()<10?'0':'') + f1.getSeconds();
    let fmtDate = f1.getFullYear() + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + second + "Z";
    return fmtDate;
  };

  getLastInspection = (date) => {
    return moment.utc(date).local().locale("es").format("LLL");
  };

  changeMode = (mode, updateData) => {
    $("#llanta"+ this.props.object.id).modal("show")
    this.setState({...INITIAL_STATE});
    this.setState({
      mode        : mode,
      currentData : cloneDeep(updateData),
      updateData  : cloneDeep(updateData),
    });

    switch(mode) {
      case "last":
        if(isInternetConnected(toast)) return;
          if(this.props.object.id !== null) {
          trackPromise(fetch(APIS.API_REST_INSPECTIONS
                .replace("customer_id", localStorage.getItem("GlobalCustomerId"))
                .replace("tire_id", this.props.object.id),{
                  headers:{
                    "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                    "Accept-language": localStorage.getItem("LanguageSelected"),
                  }
                })
            .then(response => {
              if(response.status !== 200) {
                throw new Error("Unreachable endpoint");
              }
              return response.json(); })
            .then(json => {
              if(!!json.message) {
                throw new Error("Unreachable endpoint");
              }

              const data = json.Inspecciones
              for (let i = 0; i < data.length; i++) {
                if (data[i].tipo_inspeccion === 'rotation_vehicle' || data[i].tipo_inspeccion === 'rotation_rin') {
                  data.splice(i+1, 1)
                  data.splice(i-1, 1)
                }
              }
              this.setState({ resultInspections: data }); })
            .catch(error => {
              let err = error;
              if(valConnection.indexOf(error.toString()) > -1) {
                err = this.props.t("alerts.ins-one-item-28");
              }
              toast.error(err.toString());
              this.setState({ error: err });
            }));
          }
       break;
      case "form":
        break;
      default:
        // Do nothing
    }
  };

  getRows = (rows, page) => {
    const orderedRows = rows.sort((a, b) => {
      return b.fc_ultima_inspeccion - a.fc_ultima_inspeccion;
    });
    const chunks = chunk(orderedRows, numberOfItems);
    if(!!chunks[page-1]){
      return chunks[page-1];
    }
    return [];
  };

  onPageChange = event => {
    this.setState({ page: event.selected+1 });
  };

  getUpdateData = (data) => {
    return {
      id                       : data.id,
      tecnico                  : (data.tecnico || ""),
      analista                 : this.props.authUser.email,
      //fc_ultima_inspeccion     : this.props.object.fc_ultima_inspeccion,
      customer_id              : this.props.object.customer_id,
      tirecode                 : this.props.object.codigo,      
      vehiculo                 : this.props.object.vehiculo,
      fc_ultima_inspeccion     : data.fc_ultima_inspeccion,
      consecutivo_inspecciones : data.consecutivo_inspecciones,
      kilometers               : !!data.kilometers ? data.kilometers : "",
      hours                    : !!data.hours ? data.hours : "",
      type                     : data.tipo_inspeccion,
      ult_prof_centro          : data.ult_prof_centro,
      ult_prof_centro_exterior : data.ult_prof_centro_exterior,
      ult_prof_interior        : data.ult_prof_interior,
      ult_presion              : data.ult_presion,
      order                    : data.order,
      novedad_id               : (1*data.novedades_id || null),
      DOT                      : this.props.object.DOT,
      observaciones            :
        !!data.observaciones &&
        !!data.observaciones[0] &&
        !!data.observaciones[0].observaciones ?
          data.observaciones[0].observaciones : "",
      observaciones_analista   :
        !!data.observaciones &&
        !!data.observaciones[0] &&
        !!data.observaciones[0].observaciones_analista ?
          data.observaciones[0].observaciones_analista : ""
    };
  };

  deleteIns = () => {
    if(window.confirm(this.props.t("alerts.ins-one-item-37"))){
    const codeTire = this.props.object.id;
    trackPromise(fetch(`${APIS.API_REST_INSPECTIONS
             .replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id",codeTire)}`,{
              headers:{
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected")
              },
        method: "DELETE" })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(this.props.t("alerts.ins-one-item-38"));
        }
        return response.json(); })
      .then(() => {
        return fetch(APIS.API_REST_INSPECTIONS
                     .replace("customer_id", localStorage.getItem("GlobalCustomerId"))
                     .replace("tire_id", codeTire),{
                      headers:{
                        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                        "Accept-language": localStorage.getItem("LanguageSelected")
                      }
                     }); })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(this.props.t("alerts.ins-one-item-39"));
        }
        return response.json(); })
      .then(json => this.setState({ resultInspections: json.Inspecciones }))
      .catch(error => {
        toast.error(error.message);
      }));
    }
  };

  getLastInspectionsView = result => {
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th>{this.props.t("ins-one.ins-one-more-tire-1")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-2")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-3")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-4")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-5")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-6")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-7")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-8")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-9")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-10")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-11")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-12")}</th>
                  <th>{this.props.t("client-tires.client-his-16")}</th>
                  <th>{this.props.t("client-tires.client-his-17")}</th>
                  <th>{this.props.t("client-tires.client-his-18")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-13")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-14")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-15")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-16")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-17")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-tire-18")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.getRows(result, this.state.page).map((ins, i) => {
                    return <tr key={i}>
                      <td>{
                        ins.tipo_inspeccion === "rotation_vehicle" ? this.props.t("ins-one.ins-one-64") : 
                        ins.tipo_inspeccion === 'pressure' ? this.props.t('ins-one.ins-one-65') :
                        ins.tipo_inspeccion === "rotation_rin" ? this.props.t("ins-one.ins-one-68") :
                        ins.tipo_inspeccion
                      }</td>
                      <td>{ins.ult_prof_centro_exterior}</td>
                      <td>{ins.ult_prof_centro}</td>
                      <td>{ins.ult_prof_interior}</td>
                      <td>{ins.worktype}</td>
                      <td>{ins.kilometers}</td>
                      <td>{ins.km_recorrido}</td>
                      <td>{ins.km_mm === null ? null : ins.km_mm.toFixed(2)}</td>
                      <td>{ins.km_cost === null ? null : ins.km_cost.toFixed(2)}</td>
                      <td>{ins.mm_cost}</td>
                      <td>{ins.mm_gastados}</td>
                      <td>{!!ins.vehicle_plate ? "Montada" : ins.ubicacion_final}</td>
                      <td>{!!ins.vehiculo ? ins.vehiculo : "No aplica"}</td>
                      <td>{ins.order === null ? "No aplica" : ins.order}</td>
                      <td>{!!ins.vehicle_plate ? ins.vehicle_plate : "No aplica"}</td>
                      <td>{!!ins.posicion_final ? ins.posicion_final : this.props.t("alerts.ins-one-item-40")}</td>
                      <td>{ins.ult_presion}</td>
                      <td>{ins.updated_by ? ins.updated_by : ins.created_by}</td>
                      <td>{moment.utc(ins.fc_ultima_inspeccion).local()
                                 .locale("es").format("LLL")}</td>
                      <td>{moment.utc(ins.fecha_actualizacion).local()
                                 .locale("es").format("LLL")}</td>
                      <td>
                        { !!this.props.authUser.permissions.edit ?
                        <button className="btn btn-primary"
                                onClick={() => this.changeMode("form",
                                               this.getUpdateData(ins))}>
                          <i className="fa fa-edit"></i>
                        </button>: null }&nbsp;
                        {
                          i === 0 ?
                          !!this.props.authUser.permissions.delete ?
                          <button
                            className="btn btn-danger"
                            onClick={this.deleteIns}>
                            <i className="fa fa-trash"></i>
                          </button> : null
                        : null }
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
          <br />
          {
            Math.ceil(result.length/numberOfItems) > 1 ?
              <ReactPaginate
                pageCount={Math.ceil(result.length/numberOfItems)}
                pageRangeDisplayed={3}
                marginPagesDisplayed={3}
                onPageChange={this.onPageChange}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link text-white"}
                previousLinkClassName={"page-link text-white"}
                nextLinkClassName={"page-link text-white"}
                activeClassName={"active"} /> :
              null
          }
        </div>
      </div>
    );
  };

  onSubmitUpdateInspection = event => {
    event.preventDefault();
    if(isInternetConnected(toast)) return;

    let finalUpdateData = cloneDeep(this.state.updateData);

    finalUpdateData.fc_ultima_inspeccion = this.convertDateToUTC(finalUpdateData.fc_ultima_inspeccion);
    
    const inspectionData = {
      ...finalUpdateData,
      customer_id : this.props.object.customer_id,
      codigo      : this.props.object.codigo,
      tireid      : this.props.object.id,
      tecnico     : finalUpdateData.tecnico
    };


    const data = {
      ...inspectionData,
      observaciones: [
        {
          observaciones: inspectionData.observaciones,
          observaciones_analista: inspectionData.observaciones_analista
        }
      ]
    };
    
    delete inspectionData.observaciones;
    delete inspectionData.observaciones_analista;
    delete data.observaciones_analista;

    trackPromise(fetch(`${APIS.API_REST_INSPECTIONS_MADRE.replace("customer_id",localStorage.getItem("GlobalCustomerId")).replace("tire_id",data.tireid).replace("madre_id",data.id)}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers:{
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected")
        } })
      .then(response => response.json())
      .then(json => {
        if(!!json.message) {
          throw new Error(json.message);
        }
        toast.success(this.props.t("alerts.ins-one-item-41"));
        this.props.onSubmitCar(event,true);
        this.changeMode("last"); })
      .catch(error => {
        let err = error;
        if(valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.ins-one-item-42");
        }
        toast.error(err.toString());
        this.setState({ error: err });
      }));
  };

  onChangeForm = event => {
    let updateData = {...this.state.updateData};
    updateData[event.target.name] = event.target.value;
    this.setState({ updateData: updateData });
  };

  getFormView = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <form onSubmit={this.onSubmitUpdateInspection}>
            <div className="form-group">
             <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="ult_prof_centro_exterior">{this.props.t("ins-one.ins-one-more-tire-19")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                         type="number"
                         id="ult_prof_centro_exterior"
                         name="ult_prof_centro_exterior"
                         placeholder={this.state.currentData.ult_prof_centro_exterior}
                         value={this.state.updateData.ult_prof_centro_exterior}
                         onChange={this.onChangeForm} />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="ult_prof_centro">{this.props.t("ins-one.ins-one-more-tire-20")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                         type="number"
                         id="ult_prof_centro"
                         name="ult_prof_centro"
                         placeholder={this.state.currentData.ult_prof_centro}
                         value={this.state.updateData.ult_prof_centro}
                         onChange={this.onChangeForm} />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="ult_prof_interior">{this.props.t("ins-one.ins-one-more-tire-21")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                         type="number"
                         id="ult_prof_interior"
                         name="ult_prof_interior"
                         placeholder={this.state.currentData.ult_prof_interior}
                         value={this.state.updateData.ult_prof_interior}
                         onChange={this.onChangeForm} />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="kilometers">{this.props.t("ins-one.ins-one-more-tire-22")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                         type="number"
                         id="kilometers"
                         name="kilometers"
                         placeholder={this.state.currentData.kilometers}
                         value={this.state.updateData.kilometers}
                         onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="presion">{this.props.t("ins-one.ins-one-more-tire-23")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                         type="number"
                         id="ult_presion"
                         name="ult_presion"
                         placeholder={this.state.currentData.ult_presion}
                         value={this.state.updateData.ult_presion}
                         onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="order">{this.props.t("ins-one.ins-one-more-tire-38")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                    type="string"
                    id="order"
                    name="order"
                    placeholder={this.state.currentData.order}
                    value={this.state.updateData.order}
                    onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="observaciones">{this.props.t("ins-one.ins-one-more-tire-24")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                         type="text"
                         id="observaciones"
                         name="observaciones"
                         placeholder={this.state.currentData.observaciones}
                         value={this.state.updateData.observaciones}
                         onChange={this.onChangeForm} />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="observaciones_analista">
                  {this.props.t("ins-one.ins-one-more-tire-25")}
                  </label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                         type="text"
                         id="observaciones_analista"
                         name="observaciones_analista"
                         placeholder={this.state.currentData.observaciones_analista}
                         value={this.state.updateData.observaciones_analista}
                         onChange={this.onChangeForm} />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="tecnico">{this.props.t("ins-one.ins-one-more-tire-26")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                         type="text"
                         id="tecnico"
                         name="tecnico"
                         placeholder={this.state.currentData.tecnico}
                         value={this.state.updateData.tecnico}
                         onChange={this.onChangeForm} />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-sm-2 pull-left">
                  <label htmlFor="fc_ultima_inspeccion">{this.props.t("ins-one.ins-one-more-tire-27")}</label>
                </div>
                <div className="col-sm-10">
                  <input className="form-control"
                         type="text"
                         id="fc_ultima_inspeccion"
                         name="fc_ultima_inspeccion"
                         placeholder={this.state.currentData.fc_ultima_inspeccion}
                         value={this.getLastInspectionDate(this.state.updateData.fc_ultima_inspeccion)}
                         onChange={this.onChangeForm} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-6 pull-left">
                  <button className="btn btn-primary"
                      onClick={() => this.changeMode("last")}>
                    <i className="fa fa-arrow-left"></i> {this.props.t("ins-one.ins-one-more-tire-34")}
                  </button>
                </div>
                <div className="col-sm-6 pull-right">
                  <button type="submit"
                          className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("ins-one.ins-one-more-tire-35")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  getCurrentView = (mode, resultInspections, updateData) => {
    if(mode === "last") {
      return this.getLastInspectionsView(resultInspections);
    }
    if(mode === "form") {
      return this.getFormView(updateData);
    }
    return null;
  };

  formatDate = (date) => {
    return moment.utc(date).local().locale("es").format("LLL")
  }

  getTireHistory = () => {
    $("#posicion"+(1*this.props.object.posicion_montaje)).modal("show")
    if(isInternetConnected(toast)) return;
          if(this.props.object.posicion_montaje !== null) {
          trackPromise(fetch(APIS.API_REST_INSPECTIONS_HISTORY_POSITION
                .replace("customer_id", localStorage.getItem("GlobalCustomerId"))
                .replace("vehicle_id", this.props.object.vehicle_id)
                .replace("position_id", this.props.object.posicion_montaje),{
                  headers:{
                    "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                    "Accept-language": localStorage.getItem("LanguageSelected")
                  }
                })
            .then(response => {
              if(response.status !== 200) {
                throw new Error("Unreachable endpoint");
              }
              return response.json(); })
            .then(json => {
              if(!!json.message) {
                throw new Error("Unreachable endpoint");
              }
              const data = json.inspecciones
              for (let i = 0; i < data.length; i++) {
                if (data[i].Tipo === 'rotation_vehicle' || data[i].Tipo === 'rotation_rin') {    
                  data.filter((it, index) => {
                    if (this.formatDate(it.Fecha_actualizacion) === this.formatDate(data[i].Fecha_actualizacion)) {
                      if (it.Tipo === 'montaje' || it.Tipo === 'desmonte') {
                        data.splice(index, 1)
                      }
                    }
                  })
                }
                if (i > 0 && data[i].Tipo === 'inspeccion' && data[i - 1].Tipo === 'rotation_vehicle') {
                  if (data[i - 2] && data[i - 2].Tipo === "rotation_vehicle") {
                    if (data[i]["Codigo al calor"] === data[i - 2]["Codigo al calor"]) {
                      const h1 = data[i - 1]
                      const h2 = data[i - 2]
                      data.splice(i - 1, 1, h2);
                      data.splice(i - 2, 1, h1);
                    } 
                  }
                } 
                
              }
              this.setState({ resultTireInspections: data }); })
            .catch(error => {
              let err = error;
              if(valConnection.indexOf(error.toString()) > -1) {
                err = "Error en el servidor, inténtalo de nuevo";
              }
              toast.error(err.toString());
              this.setState({ error: err });
            }));
          }
  };

  getTireHistoryView = result => {
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th>{this.props.t("ins-one.ins-one-more-car-1")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-2")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-3")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-4")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-5")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-6")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-7")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-8")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-9")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-10")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-11")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-12")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-13")}</th>
                  <th>{this.props.t("ins-one.ins-one-more-car-14")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  result.map((ins, i) => {
                    return <tr key={i}>
                      <td>{
                        ins.Tipo === "rotation_vehicle" ? this.props.t("ins-one.ins-one-64") :
                        ins.Tipo === 'pressure' ? this.props.t('ins-one.ins-one-65') :
                        ins.Tipo === "rotation_rin" ? this.props.t("ins-one.ins-one-68") :
                        ins.Tipo
                      }</td>
                      <td>{ins.Exterior}</td>
                      <td>{ins.Centro}</td>
                      <td>{ins.Interior}</td>
                      <td>{ins.Medicion}</td>
                      <td>{ins.Recorrido}</td>
                      <td>{ins["Codigo al calor"]	}</td>
                      <td>{ins.Marca}</td>
                      <td>{ins["Modelo o banda"]	}</td>
                      <td>{ins.Dimension}</td>
                      <td>{ins.created_by ? ins.created_by : ins.update_by}</td>
                      <td>{moment.utc(ins.Fecha).local()
                                 .locale("es").format("LLL")}</td>
                      <td>{ins.order === null ? "No aplica" : ins.order}</td>
                      <td>{moment.utc(ins.Fecha_actualizacion).local()
                                 .locale("es").format("LLL")}</td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { mode, resultInspections, resultTireInspections, updateData } = this.state;

    return (
      <div className="col-sm-6 text-center">
        <button className="btn btn-primary text-white"
                data-toggle="tooltip" 
                data-placement="top" 
                title={this.props.t("alerts.ins-one-item-43")}
                onClick={() => this.getTireHistory(resultTireInspections)}>
          <i className="fa fa-bullseye"></i> 
        </button>
        <div className="modal fade"
               id={"posicion"+(1*this.props.object.posicion_montaje)}
               tabIndex="-1"
               role="dialog"
               aria-labelledby="MoreInfoTireHistoryLabel"
               aria-hidden="true">
            <div className="modal-dialog"
                 role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-dark"
                      id="MoreInfoTireHistory">
                    {this.props.t("ins-one.ins-one-more-car-15")} {(1*this.props.object.posicion_montaje)}
                  </h5>
                  <button type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label={this.props.t("globas.close")}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-dark">
                  {this.getTireHistoryView(resultTireInspections)
                  }
                </div>
              </div>
            </div>
          </div>
          &nbsp;
          {!!this.props.object.marca ? 
        <button className="btn btn-primary text-white"
                data-toggle="tooltip" 
                data-placement="top" 
                title={this.props.t("alerts.ins-one-item-44")}
                onClick={() => this.changeMode("last")}>
          <i className="fa fa-info-circle"></i> 
        </button>
              : null }
        <div className="modal fade"
               id={"llanta"+this.props.object.id}
               tabIndex="-1"
               role="dialog"
               aria-labelledby="MoreInfoCarInfoLabel"
               aria-hidden="true">
            <div className="modal-dialog"
                 role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-dark"
                      id="MoreInfoCarInfoLabel">
                    {this.props.t("ins-one.ins-one-more-car-16")} {this.props.object.codigo}
                  </h5>
                  <button type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label={this.props.t("globals.close")}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-dark">
                  {this.getCurrentView(mode, resultInspections, updateData)}
                </div>
              </div>
            </div>
          </div>
    </div>
  )}
};

export default MoreInfoCarInfo;