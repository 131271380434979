import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsBrand from "../../../constants/actions/TireBrands";
import * as APIS from "../../../constants/apis";

function deleteTireModel(id, brand, models, t) {
  if (window.confirm(t("alerts.b-tire-item-12"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_TIREMODELS
        .replace("tirebrand_id", brand.id)}/${id}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "DELETE"
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(t("alerts.b-tire-item-13"));
          }
          return response.json();
        })
        .then(json => {
          dispatch(onReqSuccess(brand, models, json.message));
          return fetch(APIS.API_REST_TIREMODELS.replace("tirebrand_id",
            brand.id), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          });
        })
        .then(response => response.json())
        .then(json => {
          dispatch(onTireModelsSuccess({
            brand: brand,
            models: json.Tiremodels
          }));
        })
        .catch(error => {
          dispatch(onReqError(brand, models, error.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(onTireModelsSuccess({
        brand: brand,
        models: models
      }));
    };
  }
};

export function onTireModelsSuccess(json) {
  return {
    type: constantsBrand.GET_BRAND_SUCCESS,
    json
  };
};

export function onReqSuccess(brand, models, message) {
  const json = {
    brand: brand,
    models: models,
    message: message
  };
  return {
    type: constantsBrand.REQUEST_SUCCESS,
    json
  };
};

export function onReqError(brand, models, error) {
  const json = {
    brand: brand,
    models: models,
    error: error
  };
  return {
    type: constantsBrand.REQUEST_ERROR,
    json
  };
};

class TireModelDeleteItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { brand, models, modelDelete, deleteTireModel } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteTireModel(modelDelete.id, brand, models, this.props.t)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({
  brand: state => state.brand,
  models: state => state.models
});

const mapDispatchToProps = { deleteTireModel };

export default connect(structuredSelector, mapDispatchToProps)(TireModelDeleteItem);
