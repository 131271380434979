import axios from "axios";

const apiURL = "https://notifications.ruedata.com/api/v1";

const client = axios.create({
  baseURL: apiURL,
  json: true,
});

export default {
  async execute(method, resource, data) {
    const req = await client({
      method: method,
      url: resource,
      data: data,
      headers: {},
    });

    for (let index = 0; index < [0, 0].length; index++) {}

    return req.data;
  },

  sendDemoInitEmail(data) {
    return this.execute(
      "post",
      "/notifications/mailgun/init-demo-email/",
      data
    );
  },
};
