import React from "react";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as APIS from "../../../constants/apis";
import MeasurementNew from "../../Measurement/MeasurementNew";
import moment from "moment";
import "moment/locale/es";
import * as VALIDATIONS from "../../../constants/validations";
import MeasurementList from "../../Measurement/MeasurementList";
import Pagination from "../../pagination";
import { toast } from "react-toastify";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

class Pending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 20,
      inspections: [],
      total: 0,
      next: null,
      previus: null,
      inspectionDate: null,
      type: "km",
      vehicle_number: "",
      result: null,
      gas_list: [],
      meassurement: {
        pageN: null,
        pageP: null,
        page: 1,
        limit: 20,
        total: 0,
      },
    };
  }

  componentDidMount() {
    this.getInspections(0);
  }

  getInspections = (page, limit) => {
    var str = "";
    if (page != "0") {
      str = "?page=" + page + "&limit=" + limit;
    }
    return trackPromise(
      fetch(
        APIS.API_REST_INSPECTIONS_PENDING_WITHOUT.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ) + str,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({
            inspections: json.without_kilometers,
            total: json.total,
            next: json.next,
            previus: json.previus,
          });
        })
    );
  };

  getInspectionsList = (inspections, next, previus, authUser) => {
    if (!inspections) return null;
    let pageP, pageN, str;
    if (!!previus) {
      str = previus;
      pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    if (!!next) {
      str = next;
      pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    let pages = [];
    let start, end;
    let totalPages = Math.ceil(this.state.total / this.state.limit);
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
      start = Math.max(1, this.state.page - 4);
      end = Math.min(totalPages, this.state.page + 5);

      if (start === 1) {
        end = 10;
      } else if (end === totalPages) {
        start = totalPages - 9;
      }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("ins-without.ins-item-1")}</th>
              <th scope="col">{this.props.t("client-tires.client-his-16")}</th>
              <th scope="col">{this.props.t("ins-without.ins-item-2")}</th>
              <th scope="col">{this.props.t("ins-without.ins-item-3")}</th>
              <th scope="col">{this.props.t("ins-without.ins-item-4")}</th>
              <th scope="col">{this.props.t("ins-without.ins-item-5")}</th>
              <th scope="col">{this.props.t("ins-without.ins-item-6")}</th>
              <th scope="col">{this.props.t("ins-without.ins-item-7")}</th>
              <th scope="col">{this.props.t("ins-without.ins-item-8")}</th>
              <th scope="col">{this.props.t("ins-without.ins-item-9")}</th>
              <th scope="col">{this.props.t("ins-without.ins-item-10")}</th>
            </tr>
          </thead>
          <tbody>
            {inspections.map((inspection) => {
              var banda = !!inspection.retreadbrand
                ? inspection.retreadbrand +
                  " " +
                  inspection.retreadband +
                  " " +
                  inspection.retreadimention
                : "";
              return (
                <tr id={"inspeccion" + inspection.id} key={inspection.id}>
                  <td>{inspection.vehicle_plate}</td>
                  <td>{inspection.vehiculo}</td>
                  <td>{inspection.codigo_llanta}</td>
                  <td>
                    {inspection.marca +
                      " " +
                      inspection.modelo +
                      " " +
                      inspection.dimension +
                      " " +
                      banda}
                  </td>
                  <td>{inspection.mm_gastados}</td>
                  <td>{inspection.tecnico}</td>
                  <td>
                    {moment
                      .utc(inspection.fc_ultima_inspeccion)
                      .local()
                      .locale("es")
                      .format("LLL")}
                  </td>
                  <td>{inspection.tipo_inspeccion}</td>
                  <td>{inspection.ultima_medicion}</td>
                  <td>
                    {moment
                      .utc(inspection.fecha_medicion)
                      .local()
                      .locale("es")
                      .format("LLL")}
                  </td>
                  <td>
                    {!!authUser.permissions.edit ? (
                      <button
                        onClick={(event) => {
                          this.setState(
                            {
                              vehicle_number: inspection.vehicle_id,
                              inspectionDate: inspection.fc_ultima_inspeccion,
                            },
                            () => {
                              this.onSubmitCar(
                                event,
                                this.state.meassurement.page,
                                this.state.meassurement.limit
                              );
                            }
                          );
                        }}
                        className="btn btn-primary"
                      >
                        <i className="fa fa-edit"></i>{" "}
                        {this.props.t("ins-without.ins-item-11")}
                      </button>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <Pagination
            colspan="10"
            pageP={pageP}
            pageN={pageN}
            page={this.state.page}
            limit={this.state.limit}
            links={links}
            submit={(page, limit) => {
              this.setState({ page: page, limit: limit }, () => {
                this.getInspections(page, limit);
              });
            }}
          />
        </table>
      </div>
    );
  };

  onSubmitCar = (event, page, limit) => {
    if (!!event) event.preventDefault();
    if (isInternetConnected(toast)) return;

    const vehicle_number = this.state.vehicle_number;
    trackPromise(
      fetch(
        `${APIS.API_REST_VEHICLES.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        )}/${vehicle_number}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw new Error(this.props.t("alerts.ins-one-item-68"));
          }
          this.setState({ result: json }, () =>
            document
              .getElementById("frmMeasurement")
              .scrollIntoView({ behavior: "smooth" })
          );
        })
        .then(() => {
          this.setState((state) => ({
            ...state,
            meassurement: {
              ...state.meassurement,
              page: page,
              limit: limit,
            },
          }));
          let str = `?page=${page}&limit=${limit}`;
          return fetch(
            `${
              APIS.API_REST_FUELS_HISTORY.replace(
                "customer_id",
                localStorage.getItem("GlobalCustomerId")
              ).replace("vehicle_id", vehicle_number) + str
            }`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw new Error(json.message);
          }
          let pageP, str, pageN;
          if (!!json.previus) {
            str = json.previus;
            pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          if (!!json.next) {
            str = json.next;
            pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          this.setState((state) => ({
            ...state,
            gas_list: json.combustibles,
            meassurement: {
              ...state.meassurement,
              pageP: pageP,
              pageN: pageN,
              total: json.total,
            },
          }));
        })
        .catch((error) => {
          let err = error;
          if (valConnection.indexOf(error.toString()) > -1) {
            err = this.props.t("alerts.ins-one-item-28");
          }
          toast.error(err.toString());
          this.setState({ error: err });
        })
    );
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onMeasurementReady = (event) => {
    this.setState({
      result: null,
      inspectionDate: null,
      gas_list: [],
      vehicle_number: null,
    });
    this.getInspections(1, 20);
  };

  setListGas = (fuels) => {
    this.setState({ gas_list: fuels });
  };

  generateExcel() {
    return trackPromise(
      fetch(
        APIS.API_REST_INSPECTIONS_PENDING_WITHOUT.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ) + "?export=true",
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.ins-one-item-73"));
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.props.t("alerts.ins-one-item-85")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success(this.props.t("alerts.ins-one-item-75"));
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  }

  render() {
    const {
      inspections,
      next,
      previus,
      inspectionDate,
      type,
      vehicle_number,
      result,
      gas_list,
    } = this.state;
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("ins-without.ins-item-12")}</h3>
              </div>
              <div className="col-md-4 pull-right">
                <button
                  className="btn btn-success text-white"
                  onClick={() => this.generateExcel()}
                  type="button"
                >
                  <i className="fa fa-file-excel"></i>{" "}
                  {this.props.t("globals.exports")}
                </button>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                {this.getInspectionsList(inspections, next, previus, authUser)}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" id="frmMeasurement">
                {!!result ? (
                  <div className="card mb-5 text-center">
                    <div className="card-header">
                      {this.props.t("ins-without.ins-item-13")}
                    </div>
                    <div className="card-body">
                      <ul className="list-group">
                        <li className="list-group-item">
                          {this.props.t("ins-without.ins-item-14")}:{" "}
                          {!!result.fuel_km_info.kilometers
                            ? result.fuel_km_info.kilometers
                            : 0}
                        </li>
                        <li className="list-group-item">
                          {this.props.t("ins-without.ins-item-15")}:{" "}
                          {result.worktype === "km" ? "Kilometraje" : "Hora"}
                        </li>
                      </ul>
                    </div>
                    <div className="card-footer text-muted">
                      {this.props.t("ins-without.ins-item-16")}:{" "}
                      {!!result.fuel_km_info.fecha_inspeccion
                        ? moment
                            .utc(result.fuel_km_info.fecha_inspeccion)
                            .local()
                            .locale("es")
                            .format("LLL")
                        : "No disponible"}
                    </div>
                  </div>
                ) : null}
                {!!result ? (
                  !!authUser.permissions.create ? (
                    <MeasurementNew
                      t={this.props.t}
                      type={type}
                      inspectionDate={inspectionDate}
                      carNumber={vehicle_number}
                      currentKm={result.fuel_km_info.kilometers}
                      onSubmitCar={this.onMeasurementReady}
                    />
                  ) : null
                ) : null}
              </div>
              {!!result && !!gas_list ? (
                <MeasurementList
                  t={this.props.t}
                  type={type}
                  pageN={this.state.meassurement.pageN}
                  pageP={this.state.meassurement.pageP}
                  page={this.state.meassurement.page}
                  limit={this.state.meassurement.limit}
                  total={this.state.meassurement.total}
                  fuels={gas_list}
                  worktype={result.worktype}
                  carNumber={vehicle_number}
                  setListGas={this.setListGas}
                  authUser={authUser}
                  onSubmitCar={(e, page, limit) =>
                    this.onSubmitCar(e, page, limit)
                  }
                />
              ) : null}
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Pending);
