import React from "react";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import Metabase from "../Metabase";
import { validateMetaLanguage } from "../../../utils/validateMetaLanguage";

class TireProjection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <Metabase dashboardId={validateMetaLanguage(937, 950)} />
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(TireProjection);
