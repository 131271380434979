import Q from "q";
import xlsx from "xlsx";
import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import ErrorsModal from "./ErrorsModal";
import DragDropFile from "./DragDropFile";
import DataInput from "./DataInput";
import OutTable from "./OutTable";
import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;
const randomString = Math.random().toString(36);

const INITIAL_STATE = {
  filePath: "",
  inputKey: randomString,
  type: "",
  fields: [],
  done: [],
  notDone: [],
  data: [],
  cols: [],
  errors: [],
  tires: [],
  // Novelties
  novelties: [],
  wastes: [],
  antecedents: [],
  tiresAllinspections: []
};

class MultipleInspections extends Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();

    this.serverErrors = [];
    this.handleFile = this.handleFile.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.state = { ...INITIAL_STATE };
  };

  // fetch(`${APIS.API_REST_TIRES_MIN_INFO
  //   .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
  //   headers: {
  //     "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
  //   }
  // })
  //   .then(response => response.json())
  //   .then(json => {
  //     if (!!json.message) {
  //       throw Error(json.message);
  //     }
  //     tires = json.tires;
  //   })
  //   .then(() => 

  componentDidMount() {
    let tires, novelties, wastes, antecedents = [];
    trackPromise(fetch(`${APIS.API_REST_NOVELTIES_LIST}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        novelties = json.catalogo_novedades;
      })
      .then(() => fetch(`${APIS.API_REST_WASTES_LIST}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      }))
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        wastes = json.catalogo_desechos;
      })
      .then(() => fetch(`${APIS.API_REST_RECORDS_LIST}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      }))
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        antecedents = json.catalogo_antecedentes;
        this.setState({
          tires: tires,
          antecedents: antecedents,
          wastes: wastes,
          novelties: novelties
        });
      })
      .catch(error => {
        toast.error(error.message);
      }));
  };

  onChange = event => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "type") {
      let elements;
      if (event.target.value === "Desecho") {
        elements = [
          this.props.t("multiple.nov-item-1"),
          this.props.t("multiple.nov-item-2"),
          this.props.t("multiple.nov-item-3"),
          this.props.t("multiple.nov-item-4"),
          this.props.t("multiple.nov-item-5"),
          this.props.t("multiple.nov-item-6"),
          this.props.t("multiple.nov-item-7"),
          this.props.t("multiple.nov-item-8"),
          this.props.t("multiple.nov-item-9")
        ];
      } else {
        elements = [
          this.props.t("multiple.nov-item-1"),
          this.props.t("multiple.nov-item-2"),
          this.props.t("multiple.nov-item-4"),
          this.props.t("multiple.nov-item-5"),
          this.props.t("multiple.nov-item-6"),
          this.props.t("multiple.nov-item-7"),
          this.props.t("multiple.nov-item-8"),
          this.props.t("multiple.nov-item-9")
        ];
      }
      this.setState({ fields: elements });
    }
  };

  makeCols = refstr => {
    let o = [],
      C = xlsx.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = {
        name: xlsx.utils.encode_col(i),
        key: i
      }
    }
    return o;
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };

  noveltyExists = (novelty) => {
    const novelties = this.state.novelties;
    for (let i = 0; i < novelties.length; i++) {
      if (novelties[i].seccion_llanta.toUpperCase() === novelty.seccion) {
        const noveltiesRuedata = novelties[i].novedades;
        for (let j = 0; j < noveltiesRuedata.length; j++) {
          if (noveltiesRuedata[j].codigo_novedad === novelty.cod_ruedata) {
            if (noveltiesRuedata[j].motivo === novelty.motivo) {
              return true;
              /*
              const causes = noveltiesRuedata[j].posibles_causas;
              for(let k = 0; k < causes.length; k++) {
                if(causes[k].posible_causa === novelty.causa) {
                  return true;
                }
              }
              */
            }
          }
        }
      }
    }

    return false;
  };

  getTireId = (codigo) => {
    const tires = this.state.tiresAllinspections;
    for (let i = 0; i < tires.length; i++) {
      if (tires[i].codigo === codigo) {
        return tires[i].id;
      }
    }
    return null;
  };

  getDataTireIns = async (cols, data) => {
    let tires = []
    for (let i = 1; i < data.length; i++) {
      let fields = this.state.fields
      for (let j = 0; j < fields.length; j++) {
        if (data[0][j] === this.props.t("multiple.nov-item-1")) {
          tires.push(data[i][j])
        }
      }
    }

    const responseTire = await fetch(`${APIS.API_REST_TIRES_MIN_INFO
      .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: 'POST',
      body: JSON.stringify({
        "tires_code": tires
      })
    })
    let resTire = await responseTire.json()
    let result_tire = await resTire.tires

    return data = {
      result_tire,
    }
  }

  getDataErrors = (cols, data) => {
    let errors = [];

    // Número de columnas
    let fields = this.state.fields;
    if (cols.length !== fields.length) {
      errors.push({
        message: this.props.t("multiple.global-item-1")
      });
    }

    // Número de datos
    if (data.length < 2) {
      errors.push({
        message: this.props.t("multiple.global-item-2")
      });
    }

    // Columnas necesarias
    for (let i = 0; i < fields.length; i++) {
      if (data[0].indexOf(fields[i]) === -1) {
        errors.push({
          message: this.props.t("multiple.global-item-3") + fields[i] + this.props.t("multiple.global-item-4") + this.props.t("multiple.global-item-5")
        });
      }
    }

    // Validaciones de los datos
    for (let i = 1; i < data.length; i++) {
      let novelty = {
        cod_ruedata: "",
        causa: "",
        novedad: "",
        seccion: ""
      };
      for (let j = 0; j < fields.length; j++) {
        if (data[0][j] === this.props.t("multiple.nov-item-1")) {
          const tire = this.getTireId(data[i][j]);
          if (!tire) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.nov-item-10") + data[i][j] +
                this.props.t("multiple.nov-item-11")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.nov-item-7")) {
          if (moment(data[i][j], "YYYY-MM-DD").format("YYYY-MM-DD") !==
            data[i][j]) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.nov-item-12")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.nov-item-8")) {
          let reg = new RegExp("^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$");
          if (!reg.test(data[i][j])) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.nov-item-13")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.nov-item-9")) {
          if (!data[i][j]) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.nov-item-14")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.nov-item-3")) {
          novelty.cod_ruedata = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.nov-item-6")) {
          novelty.causa = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.nov-item-5")) {
          novelty.motivo !== "" ? novelty.motivo = data[i][j].toUpperCase() : novelty.motivo = "";
        }
        if (data[0][j] === this.props.t("multiple.nov-item-4")) {
          novelty.seccion !== "" ? novelty.seccion = data[i][j].toUpperCase() : novelty.seccion = "";
        }
      }
    }
    return errors;
  };

  handleFile = file => {
    this.setState({ data: [], cols: [], done: [], notDone: [], errors: [] });

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = async (event) => {
      const bstr = event.target.result;
      const wb = xlsx.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1, raw: false });
      const cols = this.makeCols(ws["!ref"]);
      const tireListIns = await this.getDataTireIns(cols, data)
      this.setState({ tiresAllinspections: tireListIns.result_tire })
      let errors;
      if (tireListIns) {
        errors = await this.getDataErrors(cols, data);
      }
      if (errors.length === 0) {
        this.setState({
          data: data,
          cols: this.makeCols(ws["!ref"]),
          inputKey: Math.random().toString(36)
        });
      } else {
        this.modal.current.showModal(errors);
        this.setState({
          errors: [],
          data: [],
          cols: [],
          inputKey: Math.random().toString(36)
        });
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  exportTemplate = event => {
    event.preventDefault();

    let header = this.state.fields;
    const ws = xlsx.utils.aoa_to_sheet([header]);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Plantilla");
    xlsx.writeFile(wb, this.props.t("multiple.nov-item-15") + this.state.type + ".xlsx")
  };

  formatData = (register, authUser) => {
    let inspection = {
      brandInfo: {},
      retreadInfo: {}
    };

    // Set customer and type
    inspection.type = this.state.type;
    inspection.customer_id = localStorage.getItem("GlobalCustomerId");

    // Set general values
    let header = this.state.data[0];
    for (let i = 0; i < header.length; i++) {
      if (header[i] === this.props.t("multiple.nov-item-1")) {
        inspection.numero_al_calor = register[i]
      } else if (header[i] === this.props.t("multiple.nov-item-2")) {
        inspection.dot = register[i]
      } else if (header[i] === this.props.t("multiple.nov-item-3")) {
        inspection.cod_ruedata = register[i]
      } else if (header[i] === this.props.t("multiple.nov-item-4")) {
        inspection.seccion = register[i]
      } else if (header[i] === this.props.t("multiple.nov-item-5")) {
        inspection.motivo = register[i]
      } else if (header[i] === this.props.t("multiple.nov-item-6")) {
        inspection.causa = register[i]
      } else if (header[i] === this.props.t("multiple.nov-item-7")) {
        inspection.fecha_subida = register[i]
      } else if (header[i] === this.props.t("multiple.nov-item-8")) {
        inspection.hora_subida = register[i]
      } else if (header[i] === this.props.t("multiple.nov-item-9")) {
        inspection.tecnico = register[i]
      }
    }

    inspection.analista = authUser.email;

    return inspection;
  };

  getNoveltiesData = (register) => {
    return {
      noveltys: [
        {
          seccion_llanta: register.seccion,
          motivo: register.motivo,
          codigo_causa: register.cod_ruedata,
          posible_causa: register.causa
        }
      ],
      fecha_subida: moment(register.fecha_subida + " " + register.hora_subida).utc().format(),
      customer_id: localStorage.getItem("GlobalCustomerId"),
      tiponovedad: this.state.type,
      inspector: (register.tecnico || null),
      DOT: (register.dot || null),
      pictures: register.fotos
    }
  };

  runValidations = (json) => {
    if (!!json.message) {
      if (json.message === "Fallo La Validacion") {
        let msg = "";
        Object.keys(json.resp).forEach((key) => {
          if (!json.resp[key][1]) {
            msg = msg + json.resp[key][0] + "\n";
          }
        });
        throw new Error(msg);
      } else {
        throw new Error(json.message);
      }
    }
  };

  saveRegister = (idx, register, authUser) => {
    let deferred = Q.defer();
    if (isInternetConnected(toast)) deferred.reject();
    const data = this.formatData(register, authUser);
    let tire_id = this.getTireId(data.numero_al_calor);
    let noveltiesData = this.getNoveltiesData(data);
    trackPromise(fetch(APIS.API_REST_NOVELTIES_TIRE.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id", tire_id), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(noveltiesData),
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.runValidations(json);
        return json;
      })
      .then(json => {
        let done = [...this.state.done];
        done.push(idx);
        this.setState({ done: done });
        deferred.resolve(json);
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("multiple.global-item-6") +
            (idx + 1);
        } else {
          err = this.props.t("multiple.global-item-7") + (idx + 1) + ". " + error.toString();
        }
        this.serverErrors.push({ message: err });
        let notDone = [...this.state.notDone];
        notDone.push(idx);
        this.setState({ notDone: notDone });
        deferred.reject(this.serverErrors);
      }));

    return deferred.promise;
  };

  getCleanedRegisters = registers => {
    let newRegisters = [];

    for (let i = 1; i < registers.length; i++) {
      if (registers[i].length > 0) {
        newRegisters.push(registers[i]);
      }
    }

    return newRegisters;
  };

  async saveRegisters(registers, authUser) {
    let deferred = Q.defer();

    let newRegisters = this.getCleanedRegisters(registers);
    for (let i = 0; i < newRegisters.length; i++) {
      if (this.state.done.indexOf(i) === -1) {
        await this.saveRegister(i, newRegisters[i], authUser)
          .then(() => {
            return true;
          })
          .catch(errors => {
            deferred.reject(errors);
            return deferred.promise;
          });
      }
    }

    deferred.resolve();
    return deferred.promise;
  };

  onSubmit = (event, authUser) => {
    event.preventDefault();
    this.serverErrors = [];
    this.setState({ notDone: [] });

    this.saveRegisters(this.state.data, authUser)
      .then(() => {
        toast.success(this.props.t("multiple.global-item-8"));
        this.setState({
          type: "",
          data: [],
          cols: [],
          done: [],
          notDone: [],
          errors: []
        });
      })
      .catch(errors => {
        this.modal.current.showModal(errors);
      });
  };

  render() {
    const { data, cols, done, notDone, fields, novelties,
      type } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <ErrorsModal ref={this.modal} t={this.props.t} />
            <h3>{this.props.t("distance.client-noveltie-1")}</h3>
            <br></br>
            <DragDropFile handleFile={this.handleFile} t={this.props.t}>
              <form>
                <div className="row form-group">
                  <div className="col-sm-2">
                    <label htmlFor="typeSelect">{this.props.t("distance.client-noveltie-2")}</label>
                  </div>
                  <div className="col-sm-10">
                    <select className="form-control"
                      id="typeSelect"
                      name="type"
                      value={type}
                      onChange={this.onChange}>
                      <option value="">
                        {this.props.t("distance.client-noveltie-3")}
                      </option>
                      <option value="Novedad">{this.props.t("distance.client-noveltie-4")}</option>
                      <option value="Antecedente">{this.props.t("distance.client-noveltie-5")}</option>
                      <option value="Desecho">{this.props.t("distance.client-noveltie-6")}</option>
                    </select>
                  </div>
                </div>
                <br />
                {
                  !!type ?
                    <div>
                      <DataInput
                        t={this.props.t}
                        type={type}
                        novelties={novelties}
                        handleFile={this.handleFile}
                        exportTemplate={this.exportTemplate}
                        header={fields}
                        inputKey={this.state.inputKey || ""}
                      />
                      {
                        data.length > 1 ?
                          <OutTable
                            t={this.props.t}
                            type={type}
                            data={data}
                            cols={cols}
                            done={done}
                            notDone={notDone}
                          /> : null
                      }
                      {
                        data.length > 1 ?
                          <div className="row">
                            <div className="col-md-12 mt-5">
                              <button type="submit"
                                className="btn btn-primary"
                                style={{ width: "100%" }}
                                onClick={(event) => this.onSubmit(event, authUser)}>
                                <i className="fa fa-save"></i> {this.props.t("distance.client-noveltie-7")}
                              </button>
                            </div>
                          </div> : null
                      }
                    </div> : null
                }
              </form>
            </DragDropFile>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(MultipleInspections);
