import { MAP_ERROR_SUCCESS, MAP_CLEAR_ERROR_SUCCESS } from '../types/MapErrorsTypes'


const INITIAL_STATE = {
  error: null
}

export const mapErrorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAP_ERROR_SUCCESS:
     return {
       ...state,
       error: action.payload
     }
    case MAP_CLEAR_ERROR_SUCCESS:
     return {
       ...state,
       error: action.payload
     }
    default:
      return state
  }
}