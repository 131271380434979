import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import CarInfo from "./CarInfo";
import TireInfo from "./TireInfo";
import { useDispatch, useSelector } from "react-redux";
import { getWarehousesSubAction } from "../../../redux/actions/TireWarehousesAction";
import Loading from "../../Loader";
import { getTechnicianAction } from "../../../redux/actions/TechnicianAction";
import { getVehiclesMinAction } from "../../../redux/actions/vehicleMinAction";
import { getTiresMinAction } from "../../../redux/actions/tireMinAction";
import { getCatalogueMovements, getCatalogueNovelties, getCatalogueRecords, getCatalogueWaste } from "../../../redux/actions/cataloguesAction";
import { getTireBrandsCatalogueAction2, getTireBrandsCatalogueMinAction2 } from "../../../redux/actions/TireBrandsCatalogueAction";
import { getRetreadBrandsCatalogueAction2, getRetreadBrandsCatalogueMinAction2 } from "../../../redux/actions/RetreadBrandsCatalogueAction";
import { trackPromise } from "react-promise-tracker";

const validateDataExists = (data) => {
  let validate = []
  if (data.warehouses.warehouses.length === 0) validate.push("ws")
  if (data.technicians.technicians.length === 0) validate.push("th")
  if (data.vehiclesMin.vehiclesMin.length === 0) validate.push("vhmin")
  if (data.tiresMin.tiresMin.length === 0) validate.push("trmin")
  if (data.catalogues.catNovelties.length === 0) validate.push("ctnv")
  if (data.catalogues.catWastes.length === 0) validate.push("ctws")
  if (data.catalogues.catRecords.length === 0) validate.push("ctrd")
  if (data.catalogues.catMovements.length === 0) validate.push("ctm")
  if (data.tireBrandsCatalogue.tireBrandsCatalogue.length === 0) validate.push("tcat")
  if (data.tireBrandsCatalogue.tireBrandsCatalogueMin.length === 0) validate.push("tcatmin")
  if (data.retreadBrandsCatalogue.retreadBrandsCatalogue.length === 0) validate.push("trcat")
  if (data.retreadBrandsCatalogue.retreadBrandsCatalogueMin.length === 0) validate.push("trcatmin")
  return validate
}

const Inspection = ({ firebase, t }) => {
  const data = useSelector(sl => sl)
  const dispatch = useDispatch()
  const [byVehicle, setByVehicle] = useState(true)
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState({
    customer_id: localStorage.getItem("GlobalCustomerId"),
    vehiclesMin: data.vehiclesMin.vehiclesMin,
    tiresMin: data.tiresMin.tiresMin,
    waste: data.catalogues.catWastes,
    novelties: data.catalogues.catNovelties,
    records: data.catalogues.catRecord,
    technicians: data.technicians.technicians,
    warehouses: data.warehouses.warehouses,
    movements: data.catalogues.catMovements
  });
  const [cataloguesTire, setCataloguesTire] = useState({
    Registrar: data.tireBrandsCatalogue.tireBrandsCatalogue,
    reencauche: data.retreadBrandsCatalogue.retreadBrandsCatalogue,
    catNew: data.tireBrandsCatalogue.tireBrandsCatalogueMin,
    catRetread: data.retreadBrandsCatalogue.retreadBrandsCatalogueMin
  })

  useEffect(() => {
    const val = validateDataExists(data)
    if (val.length > 0) {
      getRequiredInitData(val)
    } else {
      setLoading(false)
    }
  }, [])

  const getRequiredInitData = async (val) => {
    let callMultiple = [
      val.includes("ws") ? dispatch(getWarehousesSubAction(state.customer_id)) : null,
      val.includes("th") ? dispatch(getTechnicianAction(state.customer_id)) : null,
      val.includes("vhmin") ? dispatch(getVehiclesMinAction(state.customer_id)) : null,
      val.includes("trmin") ? dispatch(getTiresMinAction(state.customer_id)) : null,
      val.includes("ctnv") ? dispatch(getCatalogueNovelties()) : null,
      val.includes("ctrd") ? dispatch(getCatalogueRecords()) : null,
      val.includes("ctws") ? dispatch(getCatalogueWaste()) : null,
      val.includes("tcat") ? dispatch(getTireBrandsCatalogueAction2(state.customer_id)) : null,
      val.includes("tcatmin") ? dispatch(getTireBrandsCatalogueMinAction2(state.customer_id)) : null,
      val.includes("trcat") ? dispatch(getRetreadBrandsCatalogueAction2(state.customer_id)) : null,
      val.includes("trcatmin") ? dispatch(getRetreadBrandsCatalogueMinAction2(state.customer_id)) : null,
      val.includes("ctm") ? dispatch(getCatalogueMovements()) : null,
    ]
    try {
      const response = await Promise.all(callMultiple)
      setState(state => ({
        ...state,
        warehouses: response[0] ? response[0] : data.warehouses.warehouses,
        technicians: response[1] ? response[1] : data.technicians.technicians,
        vehiclesMin: response[2] ? response[2] : data.vehiclesMin.vehiclesMin,
        tiresMin: response[3] ? response[3] : data.tiresMin.tiresMin,
        novelties: response[4] ? response[4] : data.catalogues.catNovelties,
        records: response[5] ? response[5] : data.catalogues.catRecord,
        waste: response[6] ? response[6] : data.catalogues.catWastes,
        movements: response[11] ? response[11] : data.catalogues.catMovements,
      }))
      setCataloguesTire(state2 => ({
        ...state2,
        Registrar: response[7] ? response[7] : data.tireBrandsCatalogue.tireBrandsCatalogue,
        catNew: response[8] ? response[8] : data.tireBrandsCatalogue.tireBrandsCatalogueMin,
        reencauche: response[9] ? response[9] : data.retreadBrandsCatalogue.retreadBrandsCatalogue,
        catRetread: response[10] ? response[10] : data.retreadBrandsCatalogue.retreadBrandsCatalogueMin
      }))
      setLoading(false)
    } catch (error) {
      if (error.response.status !== 200) {
        toast.error(error.response.data.message)
        setLoading(false)
      }
    }
  }

  const getVehicles = () => {
    setLoading(true)
    dispatch(getVehiclesMinAction(state.customer_id))
      .then(res => {
        if (res) {
          setState(state => ({
            ...state,
            vehiclesMin: res
          }))
          setLoading(false)
        }
      })
  }

  const getTires = () => {
    trackPromise(
      dispatch(getTiresMinAction(state.customer_id))
        .then(res => {
          if (res) {
            setState(state => ({
              ...state,
              tiresMin: res
            }))
          }
        })
    )
  }

  const changePage = (perVehicle) => {
    if (perVehicle) {
      setByVehicle(true)
    } else {
      if (data.tiresMin.tiresMin.length === 0) {
        getTires()
      }
      setByVehicle(false)
    }
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  const renderElements = (authUser) => {
    return (
      <div>
        <div className="row mt-3 mb-3">
          <div className="col-sm-12 text-center">
            <div className="btn-group btn-group-toggle"
              data-toggle="buttons">
              <label className="btn btn-primary active"
                onClick={() => changePage(true)}
              >
                <input type="radio"
                  autoComplete="off" /><i className="fa fa-car"></i>  {t("globals.per-vehicle")}
              </label>
              <label className="btn btn-primary"
                onClick={() => changePage(false)}
              >
                <input type="radio"
                  autoComplete="off" /><i className="fa fa-bullseye"></i>  {t("globals.per-tire")}
              </label>
            </div>
          </div>
        </div>
        <div className={byVehicle ? "row" : "row hidden"}>
          <CarInfo
            t={t}
            cars={state.vehiclesMin}
            tires={state.tiresMin}
            warehouses={state.warehouses}
            technicians={state.technicians}
            records={state.records}
            waste={state.waste}
            novelties={state.novelties}
            movements={state.movements}
            getVehicles={getVehicles}
            authUser={authUser}
            firebaseUser={firebase}
            getTires={getTires}
          />
        </div>
        <div className={byVehicle ? "hidden" : ""}>
          <TireInfo
            t={t}
            cars={state.vehiclesMin}
            tires={state.tiresMin}
            warehouses={state.warehouses}
            records={state.records}
            waste={state.waste}
            novelties={state.novelties}
            movements={state.movements}
            cataloguesTire={cataloguesTire}
            customerId={state.customer_id}
            getTires={getTires}
            authUser={authUser}
            firebaseUser={firebase}
          />
        </div>
      </div>
    )
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className="container-fluid mt-100">
          <div className="row">
            <div className="col-md-8">
              <h3>{t("ins-one.ins-one")}</h3>
            </div>
            <div className="col-md-4 pull-right">
              {
                byVehicle ?
                  <button className="btn btn-primary text-white" onClick={() => getVehicles()} type="button"><i className="fa fa-sync-alt"></i> {t("globals.act-vehicle")}</button>
                  :
                  <button className="btn btn-primary text-white" onClick={() => getTires()} type="button"><i className="fa fa-sync-alt"></i> {t("globals.act-tire")}</button>
              }
            </div>
          </div>
          {renderElements(authUser)}
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Inspection);
