import moment from "moment";
import $ from "jquery";
import "moment/locale/es";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import API from "../../../constants/api";
import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";
import Pagination from "../../pagination";

import TireRow from "../Form/TireRow";
import TireInfo from "../Form/TireInfo";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const escapeRegexCharacters = (str) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => (
  <div>{suggestion.plate + " - " + suggestion.vehiclenumber}</div>
);

const ValidateViewOldExportPdf = [
  "w.herrera@ruedata.com",
  "a.romero@ruedata.com",
  "sofia@ruedata.com",
  "jorge@ruedata.com",
  "m.barragan@ruedata.com",
  "p.camacho@ruedata.com",
];

const INITIAL_STATE = {
  byVehicle: false,
  byInspectionOne: false,
  vehicle_number: "",
  vehicle: {},
  car_code: "",
  cars: [],
  tires: [],
  novelty: null,
  inspections: [],
  suggestions: [],
  date: "",
  technician: "",
  km: null,
  initKm: null,
  error: null,
  vehicleInactive: false,
  noveltysCount: 0,
  isNewKm: false,
  result: null,
  waste: [],
  novelties: [],
  records: [],
  warehouses: [],
  technicians: [],
  page: 1,
  limit: 20,
  valueTnc: "",
  suggestionsTnc: [],
  code: "",
  cataloguesTire: [],
};

class Pending extends Component {
  constructor(props) {
    super(props);
    this.references = {};

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.getInspectionsTire();
  }


  getOrCreateRef = (id) => {
    if (!this.references.hasOwnProperty(id)) {
      this.references[id] = React.createRef();
    }
    return this.references[id];
  };


  exportOldPdf = () => {
    const input = document.getElementById("divInspection");
    var Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(input.innerHTML);
    pwa.document.close();
    pwa.focus();
    pwa.print();
  };

  exportPdf = () => {
    if (localStorage.getItem("GlobalCustomerId")) {
      return trackPromise(
        fetch(
          APIS.API_REST_INSPECTIONS_EXPORT_PDF.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("vehicle_id", this.state.vehicle_number),
          {
            headers: {
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
          }
        )
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(this.props.t("alerts.retread-item-2"));
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `inspection.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            toast.success(this.props.t("alerts.query-item-6"));
          })
          .catch((error) => {
            toast.error(error.toString());
          })
      );
    }
  };


  reviewInspection = (vehicle_id, inspection_id, inspection, isTire) => {
    let message = this.props.t("alerts.ins-one-item-91");
    if (window.confirm(message)) {
      const changeUrls = vehicle_id
        ? APIS.API_REST_INSPECTIONS_PENDING.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", vehicle_id) +
        "/" +
        inspection_id
        : APIS.API_REST_INSPECTIONS_PENDING_SCRAPS.replace(
          "inspections",
          inspection_id
        );
      inspection.review = true;
      inspection.fc_ultima_inspeccion = moment(
        inspection.fc_ultima_inspeccion
      )
        .utc()
        .format();
      return trackPromise(
        fetch(changeUrls, {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "PUT",
          body: JSON.stringify(inspection),
        })
          .then((response) => response.json())
          .then(() => {
            toast.success(this.props.t("alerts.ins-one-item-60"));
            document.getElementById(
              "inspeccion" + inspection_id
            ).style.display = "none";
            return fetch(
              APIS.API_REST_INSPECTIONS_PENDING_COUNT.replace(
                "customer_id",
                localStorage.getItem("GlobalCustomerId")
              ),
              {
                headers: {
                  Authorization:
                    "Basic " + localStorage.getItem("firebaseAuthToken"),
                  "Accept-language": localStorage.getItem("LanguageSelected"),
                },
              }
            );
          })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            toast.success(this.props.t("alerts.ins-one-item-90"));
            const ins1 = document.getElementById("inspeccion" + inspection_id)
            ins1.style.display = 'none'
            document.getElementById(ins1.dataset.parent).style.display = 'none'
          })
          .catch((error) => {
            toast.error(error.toString());
          })
      );
    };
  }


  getInspections = (inspections, next, previus, page, limit) => {
    const vehicle_number = this.state.vehicle_number;
    var str = "";
    if (page !== "0") {
      str = "?page=" + page + "&limit=" + limit;
    }
    return trackPromise(
      fetch(
        APIS.API_REST_INSPECTIONS_PENDING.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", vehicle_number) + str,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({
            previus: json.previus,
            next: json.next,
            total: json.total,
            inspections: json.tires,
          });
        })
        .catch((error) => { })
    );
  };


  getInspectionsList = (inspections, next, previus, authUser) => {
    const vehicle_number = this.state.vehicle_number;
    if (!inspections) return null;
    let pageP, pageN, str;
    if (!!previus) {
      str = previus;
      pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    if (!!next) {
      str = next;
      pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    let pages = [];
    let start, end;
    let totalPages = Math.ceil(this.state.total / this.state.limit);
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
      start = Math.max(1, this.state.page - 4);
      end = Math.min(totalPages, this.state.page + 5);

      if (start === 1) {
        end = 10;
      } else if (end === totalPages) {
        start = totalPages - 9;
      }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("ins-pending.ins-pending-14")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-1")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-2")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-3")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-4")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-5")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-6")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-7")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-8")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-9")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-10")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-11")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-12")}</th>
              <th scope="col">{this.props.t("ins-pending.ins-pending-13")}</th>
            </tr>
          </thead>
          <tbody>
            {inspections.map((inspection) => {
              return (
                <tr id={"inspeccion" + inspection.id} key={inspection.id}>
                  <td>
                    {!!inspection.novedades_id ? (
                      <div>
                        <button
                          onClick={() =>
                            this.showNoveltys(inspection.novedades_id)
                          }
                          data-toggle="modal"
                          data-target={"#novedades" + inspection.id}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-bullhorn"></i>{" "}
                          {this.props.t("ins-pending.ins-pending-15")}
                        </button>
                        <div
                          className="modal fade"
                          id={"novedades" + inspection.id}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="NoveltysLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title text-dark"
                                  id="NoveltysLabel"
                                >
                                  {this.props.t("ins-pending.ins-pending-15")}
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Cerrar"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              {this.getNovelty(this.state.novelty)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    &nbsp;
                    {!!authUser.permissions.edit ? (
                      <button
                        onClick={() =>
                          this.reviewInspection(
                            vehicle_number,
                            inspection.id,
                            inspection,
                            false,
                            1
                          )
                        }
                        className="btn btn-primary"
                      >
                        <i className="fa fa-check"></i>{" "}
                        {this.props.t("ins-pending.ins-pending-16")}
                      </button>
                    ) : null}
                    &nbsp;
                    {!!authUser.permissions.delete ? null : null}
                  </td>
                  <td>{inspection.posicion_montaje}</td>
                  <td>{inspection.codigo}</td>
                  <td>
                    {inspection.marca +
                      " " +
                      inspection.modelo +
                      " " +
                      inspection.dimension +
                      " " +
                      inspection.banda_reencauche}
                  </td>
                  <td>{inspection.ult_prof_centro_exterior}</td>
                  <td>{inspection.ult_prof_centro}</td>
                  <td>{inspection.ult_prof_interior}</td>
                  <td>{inspection.ult_presion}</td>
                  <td>{!!inspection.is_scrap ? "Si" : "No"}</td>
                  <td>{inspection.regrabada ? "Si" : "No"}</td>
                  <td>{inspection.kilometers}</td>
                  <td>{inspection.dot}</td>
                  <td>
                    {moment
                      .utc(inspection.fc_ultima_inspeccion)
                      .local()
                      .locale("es")
                      .format("LLL")}
                  </td>
                  <td>{inspection.tecnico}</td>
                </tr>
              );
            })}
          </tbody>
          {inspections.length > 0 ? (
            <Pagination
              colspan="10"
              pageP={pageP}
              pageN={pageN}
              page={this.state.page}
              limit={this.state.limit}
              links={links}
              submit={(page, limit) => {
                this.setState({ page: page, limit: limit }, () => {
                  this.getInspections(inspections, next, previus, page, limit);
                });
              }}
            />
          ) : null}
        </table>
      </div>
    );
  };

  getInspectionsTire = () => {
    let str = "?except=null&rotation=vehicle_rotation";
    return trackPromise(
      fetch(
        APIS.API_REST_INSPECTIONS_PENDING_ALL.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ) + str,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({
            inspectionsTire: json.inspections,
          });
        })
        .catch((error) => { })
    );
  };



  fixProfRotation = (id, parent_id) => {
    const tr1 = document.getElementById("inspeccion" + id).childNodes
    const tr2 = document.getElementById("inspeccion" + parent_id).childNodes

    const inspection = this.state.inspectionsTire.filter(item => item.id === id)[0]

    let newData = [
      {
        ult_prof_centro_exterior: "",
        ult_prof_interior: "",
        ult_prof_centro: "",
      },
      {
        ult_prof_centro_exterior: "",
        ult_prof_interior: "",
        ult_prof_centro: "",
      },
    ]

    const createInput = (value, propertie, inputId) => {
      let input = document.createElement('input')
      input.value = value
      input.type = 'number'
      input.name = propertie
      input.id = inputId
      input.className = "form-control"
      input.style.width = "80px"
      input.onchange = (e) => {
        newData[e.target.id][e.target.name] = e.target.value
      }
      return input
    }

    const clearInputs = () => {
      for (let i = 0; i < tr1.length; i++) {
        if (tr1[i].id === "container_buttons") {
          tr1[i].childNodes[0].style.display = "block"
          tr1[i].childNodes[1].removeChild(tr1[i].childNodes[1].childNodes[0])
          tr1[i].childNodes[1].removeChild(tr1[i].childNodes[1].childNodes[0])
        }
        if (tr1[i].id === "ult_prof_centro_exterior" || tr1[i].id === "ult_prof_interior" || tr1[i].id === "ult_prof_centro") {
          tr1[i].childNodes[0].style.display = 'block'
          tr1[i].removeChild(tr1[i].childNodes[1])
        }
      }

      for (let i = 0; i < tr2.length; i++) {
        if (tr2[i].id === "ult_prof_centro_exterior" || tr2[i].id === "ult_prof_interior" || tr2[i].id === "ult_prof_centro") {
          tr2[i].childNodes[0].style.display = 'block'
          tr2[i].removeChild(tr2[i].childNodes[1])
        }
      }
    }

    const createButton = () => {
      let button = document.createElement('button')
      button.className = "btn btn-primary m-2"
      button.innerText = "Actualizar Prof"
      button.onclick = (e) => {
        if (window.confirm("¿ Estas seguro de actualizar las profundidades ?")) {
          inspection["ult_prof_centro_exterior"] = Number(newData[0].ult_prof_centro_exterior)
          inspection["ult_prof_centro"] = Number(newData[0].ult_prof_centro)
          inspection["ult_prof_interior"] = Number(newData[0].ult_prof_interior)
          inspection["parent_rotation"]["ult_prof_centro"] = Number(newData[1].ult_prof_centro)
          inspection["parent_rotation"]["ult_prof_centro_exterior"] = Number(newData[1].ult_prof_centro_exterior)
          inspection["parent_rotation"]["ult_prof_interior"] = Number(newData[1].ult_prof_interior)

          if (this.state.inspectionsTire.length > 0) {
            this.state.inspectionsTire.findIndex((elemento, indice) => {
              if (elemento.id === inspection.id) {
                this.state.inspectionsTire.splice(indice, 1, inspection);
                this.setState({ inspectionsTire: this.state.inspectionsTire })
              }
            });
          }
          clearInputs()
        }
      }
      return button
    }

    const cancelButton = () => {
      let button = document.createElement('button')
      button.className = "btn btn-secondary m-2"
      button.innerText = "Cancelar"
      button.onclick = () => clearInputs()
      return button
    }

    for (let i = 0; i < tr1.length; i++) {
      if (tr1[i].id === "container_buttons") {
        tr1[i].childNodes[0].style.display = "none"
        tr1[i].childNodes[1].appendChild(cancelButton())
        tr1[i].childNodes[1].appendChild(createButton())
      }
      if (tr1[i].id === "ult_prof_centro_exterior" || tr1[i].id === "ult_prof_interior" || tr1[i].id === "ult_prof_centro") {
        newData[0][tr1[i].id] = tr1[i].innerText
        tr1[i].appendChild(createInput(tr1[i].innerText, tr1[i].id, "0"))
        tr1[i].childNodes[0].style.display = 'none'
      }
    }

    for (let i = 0; i < tr2.length; i++) {
      if (tr2[i].id === "ult_prof_centro_exterior" || tr2[i].id === "ult_prof_interior" || tr2[i].id === "ult_prof_centro") {
        newData[1][tr2[i].id] = tr2[i].innerText
        tr2[i].appendChild(createInput(tr2[i].innerText, tr2[i].id, "1"))
        tr2[i].childNodes[0].style.display = 'none'
      }
    }
  }

  getInspectionsTireList = (inspections, authUser, firebaseUser) => {
    if (!inspections) return null;
    console.log(inspections);
    return (
      <>
        {this.state.byInspectionOne ? (
          <>
            <TireInfo
              cars={this.state.cars}
              tires={this.state.tires}
              warehouses={this.state.warehouses}
              records={this.state.records}
              waste={this.state.waste}
              novelties={this.state.novelties}
              cataloguesTire={this.state.cataloguesTire}
              customerId={localStorage.getItem("GlobalCustomerId")}
              getTires={this.getTires}
              authUser={authUser}
              codigo={this.state.code}
              findNow={true}
              firebaseUser={firebaseUser}
            />
          </>
        ) : (
          <>
            {this.state.byVehicle ? null : (
              <div className="text-center v-scroll">
                <table className="table table-striped table-bordered table-sm mb-0">
                  <thead>
                    <tr>
                      <th scope="col">{this.props.t("ins-pending.ins-pending-14")}</th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-tire-1")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-tire-2")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-tire-3")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-tire-4")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-3")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-4")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-5")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-6")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-7")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-8")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-9")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-10")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-11")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-tire-5")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-tire-6")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-tire-7")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-12")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-13")}
                      </th>
                      <th scope="col">
                        {this.props.t("ins-pending.ins-pending-14")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inspections.map((inspection, index) => {
                      if (inspection.parent_rotation) {
                        return (
                          <>
                            <tr id={"inspeccion" + inspection.id} data-parent={"inspeccion" + index} key={inspection.id}>
                              <td id="container_buttons" rowspan="2">
                                <div>
                                  {
                                    inspection.validations ? (
                                      <div>
                                        <button
                                          className="btn btn-primary"
                                          data-toggle="modal"
                                          data-target={"#validations" + inspection.id}>{this.props.t("globals.errors")}</button>
                                        <div
                                          className="modal fade"
                                          id={"validations" + inspection.id}
                                          tabIndex="-1"
                                          role="dialog"
                                          aria-labelledby="NoveltysLabel"
                                          aria-hidden="true"
                                        >
                                          <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title text-dark"
                                                  id="NoveltysLabel"
                                                >
                                                  Motivo PTE
                                                </h5>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Cerrar"
                                                >
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                              </div>
                                              {
                                                Object.entries(inspection.validations.resp).map(item => {
                                                  if (item[1][1] == false) {
                                                    return <p className="px-4 py-2" style={{ fontSize: "18px" }}> * {item[1][0]}</p>
                                                  }
                                                })
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    ) : null
                                  }
                                  {!!inspection.parent_rotation ? (
                                    <div>
                                      <button
                                        onClick={() => this.fixProfRotation(inspection.id, index)}
                                        className="btn btn-primary"
                                      >
                                        <i class="fas fa-tools"></i>{" "}
                                        Corregir
                                      </button>
                                    </div>
                                  ) : null}
                                  {!!inspection.novedades_id ? (
                                    <div>
                                      <button
                                        onClick={() =>
                                          this.showNoveltys(inspection.novedades_id)
                                        }
                                        data-toggle="modal"
                                        data-target={"#novedades" + inspection.id}
                                        className="btn btn-primary"
                                      >
                                        <i className="fa fa-bullhorn"></i>{" "}
                                        {this.props.t("ins-pending.ins-pending-15")}
                                      </button>
                                      <div
                                        className="modal fade"
                                        id={"novedades" + inspection.id}
                                        tabIndex="-1"
                                        role="dialog"
                                        aria-labelledby="NoveltysLabel"
                                        aria-hidden="true"
                                      >
                                        <div className="modal-dialog" role="document">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title text-dark"
                                                id="NoveltysLabel"
                                              >
                                                {this.props.t("ins-pending.ins-pending-15")}
                                              </h5>
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Cerrar"
                                              >
                                                <span aria-hidden="true">&times;</span>
                                              </button>
                                            </div>
                                            {this.getNovelty(this.state.novelty)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  &nbsp;
                                  <button
                                    onClick={() =>
                                      this.reviewInspection(
                                        inspection.vehicle_id,
                                        inspection.id,
                                        inspection,
                                        true,
                                        false
                                      )
                                    }
                                    className="btn btn-primary mt-2"
                                  >
                                    <i className="fa fa-check"></i>{" "}
                                    Rotar
                                  </button>
                                  &nbsp;
                                </div>
                                <div>

                                </div>
                              </td>
                              <td>{inspection.vehicle_number}</td>
                              <td>{inspection.vehicle_plate}</td>
                              <td>{inspection.posicion_montaje}</td>
                              <td>{inspection.codigo}</td>
                              <td>
                                {inspection.marca +
                                  " " +
                                  inspection.modelo +
                                  " " +
                                  inspection.dimension +
                                  " " +
                                  inspection.banda_reencauche}
                              </td>
                              <td id="ult_prof_centro_exterior"><p>{inspection.ult_prof_centro_exterior}</p></td>
                              <td id="ult_prof_centro"><p>{inspection.ult_prof_centro}</p></td>
                              <td id="ult_prof_interior"><p>{inspection.ult_prof_interior}</p></td>
                              <td>{inspection.ult_presion}</td>
                              <td>{inspection.observaciones.replace(/\\|"}"}|{"{"|{"|"}|[{}]/g, '')}</td>
                              <td>{!!inspection.is_scrap ? "Si" : "No"}</td>
                              <td>{inspection.regrabada ? "Si" : "No"}</td>
                              <td>{inspection.kilometers}</td>
                              <td>{inspection.dot}</td>
                              <td>{inspection.order === null ? "" : inspection.order}</td>
                              <td>{inspection.sinester}</td>
                              <td>{inspection.operator}</td>
                              <td>
                                {inspection.journey
                                  ? "Si"
                                  : inspection.journey == false
                                    ? "No"
                                    : ""}
                              </td>
                              <td>
                                {moment
                                  .utc(inspection.fc_ultima_inspeccion)
                                  .local()
                                  .locale("es")
                                  .format("LLL")}
                              </td>
                              <td>{inspection.is_bluetooth}</td>
                              <td>{inspection.tecnico}</td>
                            </tr>
                            <tr id={"inspeccion" + index} key={inspection.parent_rotation.id}>
                              <td>{inspection.parent_rotation.vehicle_number}</td>
                              <td>{inspection.parent_rotation.vehicle_plate}</td>
                              <td>{inspection.parent_rotation.posicion_montaje}</td>
                              <td>{inspection.parent_rotation.codigo}</td>
                              <td>
                                {inspection.parent_rotation.marca +
                                  " " +
                                  inspection.parent_rotation.modelo +
                                  " " +
                                  inspection.parent_rotation.dimension +
                                  " " +
                                  inspection.parent_rotation.banda_reencauche}
                              </td>
                              <td id="ult_prof_centro_exterior"><p>{inspection.parent_rotation.ult_prof_centro_exterior}</p></td>
                              <td id="ult_prof_centro"><p>{inspection.parent_rotation.ult_prof_centro}</p></td>
                              <td id="ult_prof_interior"><p>{inspection.parent_rotation.ult_prof_interior}</p></td>
                              <td>{inspection.parent_rotation.ult_presion}</td>
                              <td>{inspection.parent_rotation.observaciones.replace(/\\|"}"}|{"{"|{"|"}|[{}]/g, '')}</td>
                              <td>{!!inspection.parent_rotation.is_scrap ? "Si" : "No"}</td>
                              <td>{inspection.parent_rotation.regrabada ? "Si" : "No"}</td>
                              <td>{inspection.parent_rotation.kilometers}</td>
                              <td>{inspection.parent_rotation.dot}</td>
                              <td>{inspection.parent_rotation.order === null ? "" : inspection.parent_rotation.order}</td>
                              <td>{inspection.parent_rotation.sinester}</td>
                              <td>{inspection.parent_rotation.operator}</td>
                              <td>
                                {inspection.parent_rotation.journey
                                  ? "Si"
                                  : inspection.parent_rotation.journey == false
                                    ? "No"
                                    : ""}
                              </td>
                              <td>
                                {moment
                                  .utc(inspection.parent_rotation.fc_ultima_inspeccion)
                                  .local()
                                  .locale("es")
                                  .format("LLL")}
                              </td>
                              <td>{inspection.parent_rotation.is_bluetooth}</td>
                              <td>{inspection.parent_rotation.tecnico}</td>
                            </tr>
                          </>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  generateExcel(isByVehicle) {
    var str;
    if (isByVehicle) {
      str = `${APIS.API_REST_INSPECTIONS_PENDING.replace(
        "customer_id",
        localStorage.getItem("GlobalCustomerId")
      ).replace("vehicle_id", this.state.vehicle_number) + "?export=true"
        }`;
    } else {
      str =
        APIS.API_REST_INSPECTIONS_PENDING_ALL.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ) + "?export=true&type_inspection=desecho";
    }
    return trackPromise(
      fetch(str, {
        headers: {
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.ins-one-item-73"));
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.props.t("alerts.ins-one-item-84")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success(this.props.t("alerts.ins-one-item-75"));
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  }

  render() {
    const {
      vehicle_number,
      car_code,
      inspections,
      inspectionsTire,
      result,
      suggestions,
      next,
      previus,
      total,
      combustibles,
      vehicle,
      technician,
      suggestionsTnc,
    } = this.state;

    let plateProps = {
      placeholder: this.props.t("alerts.ins-one-item-76"),
      value: car_code,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "1%",
        marginBottom: 0,
      },
      onChange: this.onChangePlate,
    };

    const inputProps = {
      placeholder: this.props.t("alerts.ins-one-item-77"),
      value: technician,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "100%",
        marginBottom: 0,
      },
      onChange: this.onChangeTnc,
    };

    let isInvalidCarCode = !vehicle_number;
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-8">
                <h3>
                  {this.state.byInspectionOne
                    ? this.props.t("ins-pending.ins-pending-list-55")
                    : this.props.t("ins-pending.ins-pending-list-56")}
                </h3>
              </div>
              <div className="col-md-4 pull-right">
                <button
                  className="btn btn-primary text-white"
                  onClick={() => this.getInspectionsTire()}
                  type="button"
                >
                  <i className="fa fa-sync-alt"></i>{" "}
                  {this.props.t("ins-pending.ins-pending-list-3")}
                </button>
              </div>
            </div>
            <br></br>
            <div className={this.state.byVehicle ? "" : "hidden"}>
              <form onSubmit={(event) => this.onSubmit(event, false)}>
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="inputCarCode">
                      {this.props.t("ins-pending.ins-pending-list-6")}
                    </label>
                  </div>
                  <div className="col-sm-10 input-group mb-3">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onPlatesFetchRequested}
                      onSuggestionsClearRequested={this.onPlatesClearRequested}
                      onSuggestionSelected={this.onSuggestionSelected}
                      shouldRenderSuggestions={() => true}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      inputProps={plateProps}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary text-white"
                        type="submit"
                        disabled={isInvalidCarCode}
                      >
                        <i className="fa fa-search"></i>{" "}
                        {this.props.t("ins-pending.ins-pending-list-7")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <p>
                    {this.props.t("ins-pending.ins-pending-list-8")}{" "}
                    <b>{!!total ? total : 0}</b>
                  </p>
                </div>
              </div>
              <br />
              {!!this.state.vehicle_number ? (
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-primary text-white"
                    onClick={(e) =>
                      this.setState({
                        byVehicle: false,
                        byInspectionOne: false,
                      })
                    }
                  >
                    <i className="fa fa-arrow-left"></i>{" "}
                    {this.props.t("ins-pending.ins-pending-list-51")}
                  </button>
                  <button
                    disabled=""
                    className="btn btn-success"
                    onClick={(e) => this.generateExcel(true)}
                  >
                    <i className="fa fa-file-export"></i>{" "}
                    {this.props.t("globals.exports")}
                  </button>
                </div>
              ) : null}
              <br />
              {this.getInspectionsList(inspections, next, previus, authUser)}
              <br />
              <br />
              {!!result && result.length > 0 ? (
                <form>
                  {!!this.state.vehicleInactive ? (
                    <div className="alert alert-warning" role="alert">
                      {this.props.t("ins-pending.ins-pending-list-10")}
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row form-group">
                        <div className="col-sm-2">
                          <label htmlFor="inputCarKm">
                            {this.props.t("ins-pending.ins-pending-list-11")}
                          </label>
                        </div>
                        <div className="col-sm-10 input-group">
                          <input
                            name="km"
                            type="number"
                            id="inputCarKm"
                            className="form-control"
                            value={this.state.km}
                            min={this.state.initKm}
                            readOnly
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-primary text-white"
                              type="button"
                              data-toggle="modal"
                              data-target={"#last10Km"}
                            >
                              <i className="fa fa-chart-line"></i>{" "}
                              {this.props.t("ins-pending.ins-pending-list-12")}
                            </button>
                            <div
                              className="modal fade"
                              id="last10Km"
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="last10KmLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      id="last10KmLabel"
                                      className="modal-title text-dark"
                                    >
                                      {this.props.t(
                                        "ins-pending.ins-pending-list-13"
                                      )}
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Cerrar"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body text-dark">
                                    {this.state.isNewKm === false ? (
                                      <div>
                                        <button
                                          type="button"
                                          onClick={(e) =>
                                            this.setState({ isNewKm: true })
                                          }
                                          className="btn btn-primary float-right"
                                        >
                                          {this.props.t(
                                            "ins-pending.ins-pending-list-14"
                                          )}
                                        </button>
                                        <table className="table table-striped mb-0">
                                          <thead>
                                            <tr>
                                              <th>
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-15"
                                                )}
                                              </th>
                                              <th>
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-16"
                                                )}
                                              </th>
                                              <th>
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-17"
                                                )}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {combustibles.map((fuel, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td>{fuel.kilometers}</td>
                                                  <td>
                                                    {moment
                                                      .utc(
                                                        fuel.fecha_inspeccion
                                                      )
                                                      .local()
                                                      .locale("es")
                                                      .format("LLL")}
                                                  </td>
                                                  <td>
                                                    <a
                                                      href="#"
                                                      className="btn btn-primary"
                                                      onClick={(e) =>
                                                        this.chooseKm(
                                                          fuel.kilometers
                                                        )
                                                      }
                                                    >
                                                      <i className="fa fa-link"></i>{" "}
                                                      {this.props.t(
                                                        "ins-pending.ins-pending-list-12"
                                                      )}
                                                    </a>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    ) : (
                                      <div>
                                        <button
                                          type="button"
                                          onClick={(e) =>
                                            this.setState({ isNewKm: false })
                                          }
                                          className="btn btn-primary float-right"
                                        >
                                          {this.props.t(
                                            "ins-pending.ins-pending-list-18"
                                          )}
                                        </button>
                                        <br />
                                        <br />
                                        <fieldset>
                                          <div className="row form-group">
                                            <div className="col-sm-2">
                                              <label htmlFor="newKmInput">
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-19"
                                                )}
                                              </label>
                                            </div>
                                            <div className="col-sm-10">
                                              <input
                                                name="km"
                                                type="text"
                                                id="newKmInput"
                                                className="form-control"
                                                placeholder="Nuevo Km"
                                              />
                                            </div>
                                          </div>
                                          <div className="row form-group">
                                            <div className="col-sm-2">
                                              <label htmlFor="newDateInput">
                                                {this.props.t(
                                                  "ins-pending.ins-pending-list-20"
                                                )}
                                              </label>
                                            </div>
                                            <div className="col-sm-10">
                                              <input
                                                name="date"
                                                type="datetime-local"
                                                id="newDateInput"
                                                className="form-control"
                                                placeholder={this.props.t(
                                                  "ins-pending.ins-pending-list-20"
                                                )}
                                              />
                                            </div>
                                          </div>
                                          <button
                                            type="button"
                                            onClick={(e) =>
                                              this.registerNewKm(e)
                                            }
                                            className="btn btn-primary float-right"
                                          >
                                            {this.props.t(
                                              "ins-pending.ins-pending-list-21"
                                            )}
                                          </button>
                                        </fieldset>
                                      </div>
                                    )}
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      data-dismiss="modal"
                                      aria-label="Cerrar"
                                    >
                                      {this.props.t("globals.close")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-2">
                          <label htmlFor="inputCarDate">
                            {this.props.t("ins-pending.ins-pending-list-22")}
                          </label>
                        </div>
                        <div className="col-sm-10">
                          <input
                            name="date"
                            type="datetime-local"
                            id="inputCarDate"
                            className="form-control"
                            value={this.state.date}
                            onChange={this.onChange}
                            placeholder="Fecha de revisión"
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-2">
                          <label htmlFor="inputCarTechnician">
                            {this.props.t("ins-pending.ins-pending-list-23")}
                          </label>
                        </div>
                        <div className="col-sm-12">
                          <Autosuggest
                            suggestions={suggestionsTnc}
                            onSuggestionsFetchRequested={
                              this.onSuggestionsFetchRequestedTnc
                            }
                            onSuggestionsClearRequested={
                              this.onSuggestionsClearRequestedTnc
                            }
                            shouldRenderSuggestions={() => true}
                            getSuggestionValue={this.getSuggestionValueTnc}
                            renderSuggestion={this.renderSuggestionTnc}
                            inputProps={inputProps}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-24")}
                            </th>
                            <td>{vehicle.centrocosto}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-25")}
                            </th>
                            <td>{vehicle.schema}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-26")}
                            </th>
                            <td>{vehicle.ciudad}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-27")}
                            </th>
                            <td>{vehicle.vehiclebrand}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-28")}
                            </th>
                            <td>{vehicle.fuel}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-29")}
                            </th>
                            <td>{vehicle.vehiclenumber}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-30")}
                            </th>
                            <td>{vehicle.group}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-31")}
                            </th>
                            <td>{vehicle.vehicletype}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-32")}
                            </th>
                            <td>{vehicle.linemodel}</td>
                            <th scope="row">
                              {this.props.t("ins-pending.ins-pending-list-33")}
                            </th>
                            <td>{vehicle.year}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              ) : null}
              {!!result && result.length > 0 ? (
                <div className="mt-5">
                  <div id="divInspection" style={{ display: "none" }}>
                    <table
                      style={{ "font-size": "10 px; important" }}
                      border="0"
                      width="100%"
                    >
                      <tr>
                        <td width="30%">
                          <center>
                            <img src="/images/logo.png" width="220" />
                          </center>
                          <center>
                            <h2>
                              {this.props.t("ins-pending.ins-pending-list-34")}:{" "}
                              {localStorage.getItem("GlobalCustomerName")}
                            </h2>
                          </center>
                        </td>
                        <td width="70%">
                          <center>
                            <h3>
                              {this.props.t("ins-pending.ins-pending-list-35")}
                            </h3>
                          </center>
                          <table border="1" width="100%">
                            <tbody>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-tire-2"
                                  )}
                                </th>
                                <td>{vehicle.plate}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-tire-1"
                                  )}
                                </th>
                                <td>{vehicle.vehiclenumber}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-25"
                                  )}
                                </th>
                                <td>{vehicle.schema}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-28"
                                  )}
                                </th>
                                <td>{vehicle.fuel}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-27"
                                  )}
                                </th>
                                <td>{vehicle.vehiclebrand}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-32"
                                  )}
                                </th>
                                <td>{vehicle.linemodel}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-33"
                                  )}
                                </th>
                                <td>{vehicle.year}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-31"
                                  )}
                                </th>
                                <td>{vehicle.vehicletype}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-24"
                                  )}
                                </th>
                                <td>{vehicle.centrocosto}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-26"
                                  )}
                                </th>
                                <td>{vehicle.ciudad}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-30"
                                  )}
                                </th>
                                <td>{vehicle.group}</td>
                                <th scope="row">
                                  {this.props.t(
                                    "ins-pending.ins-pending-list-36"
                                  )}
                                </th>
                                <td>{vehicle.spares}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <center>
                      <h3>{this.props.t("ins-pending.ins-pending-list-37")}</h3>
                    </center>
                    <table border="1" width="100%">
                      <thead>
                        <tr>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-38")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-39")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-40")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-41")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-42")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-43")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-44")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-45")}
                          </th>
                          <th scope="col">
                            {this.props.t("ins-pending.ins-pending-list-46")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {result.map((object, index) => {
                          return (
                            <tr>
                              <td>{object.posicion_montaje}</td>
                              <td>{object.codigo}</td>
                              <td>
                                {object.marca +
                                  " " +
                                  object.modelo +
                                  " " +
                                  object.dimension}
                                <b>
                                  {typeof object.retreadband == "undefined"
                                    ? ""
                                    : object.retreadband}{" "}
                                  {typeof object.retreadband == "undefined"
                                    ? ""
                                    : object.retreadimention}{" "}
                                  {!!object.codigo
                                    ? !!object.retreadbrand &&
                                      !!object.retreadband &&
                                      !!object.retreadimention
                                      ? "R"
                                      : "N"
                                    : ""}
                                  {object.vida}
                                </b>
                              </td>
                              <td>
                                {moment
                                  .utc(object.fc_ultima_inspeccion)
                                  .local()
                                  .locale("es")
                                  .format("DD[/]MM[/]YY")}
                              </td>
                              <td>
                                <center>
                                  {object.ult_prof_centro_exterior}
                                </center>
                              </td>
                              <td>
                                <center>{object.ult_prof_centro}</center>
                              </td>
                              <td>
                                <center>{object.ult_prof_interior}</center>
                              </td>
                              <td>
                                {!!object.ult_presion ? object.ult_presion : ""}
                              </td>
                              <td>{object.kilometers}</td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colspan="9">
                            {this.props.t("ins-pending.ins-pending-list-47")}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="9">
                            {this.props.t("ins-pending.ins-pending-list-48")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p>
                    {result.filter((tire) => !!tire.codigo).length +
                      this.props.t("alerts.ins-one-item-78")}
                  </p>
                  <div className="text-center v-scroll">
                    {this.getTableResult(result, authUser, this.props.firebase)}
                  </div>
                  <br />
                  {!!authUser.permissions.create ? (
                    <form onSubmit={this.onSubmitItems}>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-block"
                        >
                          <i className="fa fa-save"></i>{" "}
                          {this.props.t("globas.save-all")}
                        </button>
                      </div>
                    </form>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div>
              {this.state.byInspectionOne ? (
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <button
                      className="btn btn-primary text-white"
                      onClick={(e) =>
                        this.setState({
                          byVehicle: false,
                          byInspectionOne: false,
                        })
                      }
                    >
                      <i className="fa fa-arrow-left"></i>{" "}
                      {this.props.t("ins-pending.ins-pending-list-48")}
                    </button>
                  </div>
                </div>
              ) : (
                <div className={this.state.byVehicle ? "hidden" : "row"}>
                  <div className="col-md-12">
                    <button
                      className="btn btn-success"
                      onClick={(e) => this.generateExcel(false)}
                    >
                      <i className="fa fa-file-export"></i>{" "}
                      {this.props.t("globals.exports")}
                    </button>
                  </div>
                </div>
              )}
              <br />
              {this.getInspectionsTireList(
                inspectionsTire,
                authUser,
                this.props.firebase
              )}
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Pending);
