import API from '../../constants/api'
import { REQ_WAREHOUSES_SUCCESS } from '../types/TireWarehousesTypes'
import { setErrorAction } from './MapErrorsAction'


export const getWarehousesAction = (customer_id) => {
  return (dispatch) => {
    API
      .getWarehouses(customer_id)
      .then(res => {
        dispatch({
          type: REQ_WAREHOUSES_SUCCESS,
          payload: res.bodegas
        })
      })
      .catch(error => dispatch(setErrorAction(error.toString())))
  }
}


export const getWarehousesSubAction = (customer_id) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getWarehouses(customer_id)
        .then(res => {
          if (res.bodegas) {
            dispatch({
              type: REQ_WAREHOUSES_SUCCESS,
              payload: res.bodegas
            })
            resolve(res.bodegas)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}