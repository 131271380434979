import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsGroup from "../../../constants/actions/Groups";
import * as APIS from "../../../constants/apis";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../Loader";
import API from "../../../constants/api";
import { putGroupAction } from "../../../redux/actions/groupsAction";

const GroupEditItem = ({ t, location }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true)
  const [state, setState] = useState({
    id: "",
    name: "",
    code: "",
    customer_id: ""
  })

  const dispatch = useDispatch()
  const history = useHistory()

  console.log(location.state)

  useEffect(() => {
    API
      .getGroup(localStorage.getItem("GlobalCustomerId"), id)
      .then(res => {
        if (res) {
          setState(state => ({
            ...state,
            id: res.id,
            name: res.name,
            code: res.code,
            customer_id: res.customer_id
          }))
          setLoading(false)
        }
      })
      .catch(error => {
        if (error.response.status !== 200) {
          toast.error(error.response.data.message.toString())
          setLoading(false)
        }
      })
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(putGroupAction(state.customer_id, state.id, location.state.str, state, location.state.typeput))
      .then(res => {
        if (res) {
          history.goBack()
        }
      })
      .catch(error => {
        if (error.response.status !== 200) {
          toast.error(error.response.data.message.toString())
        }
      })
  }

  const onChange = (value) => {
    setState(state => ({
      ...state,
      name: value
    }))
  }

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div className="container-fluid mt-100">
          <h3>{t("client-general.groups-edit")}</h3>
          <br />
          <br />
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-2">
                  <label htmlFor="nameInput">
                    {t("client-general.client-item-1")}
                  </label>
                  <br></br>
                  <i className="text-info small">
                    {t("globals.required")}
                  </i>
                </div>
                <div className="col-sm-10">
                  <input
                    className="form-control"
                    placeholder={t("client-general.client-item-1")}
                    id="nameInput"
                    name="name"
                    value={state.name}
                    onChange={(e) => onChange(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <button type="submit" className="btn btn-primary">
                  <i className="fa fa-save"></i>{" "}
                  {t("globals.simple-save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(GroupEditItem);

// function getGroup(id, t) {
//   return dispatch => {
//     return trackPromise(fetch(`${APIS.API_REST_GROUPS.replace("customer_id",
//     localStorage.getItem("GlobalCustomerId"))}/${id}`, {
//                                                   headers:{
//                                                     "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
//                                                     "Accept-language": localStorage.getItem("LanguageSelected"),
//                                                   } })
//       .then(response => {
//         if(response.status !== 200) {
//           throw new Error(t("alerts.groups-item-2"));
//         }
//         return response.json(); })
//       .then(json => {
//         dispatch(getGroupSuccess({
//           group    : json
//         })); })
//       .catch(error => {
//         toast.error(error.message.toString());
//       }));
//   };
// };

// export function getGroupSuccess(json) {
//   return {
//     type: constantsGroup.GET_GROUP_SUCCESS,
//     json
//   };
// };

// export function getReqSuccess(groups, group, message) {
//   const json = {
//     groups    : groups,
//     group     : group,
//     message   : message
//   };
//   return {
//     type: constantsGroup.GET_REQUEST_SUCCESS,
//     json
//   };
// };

// class GroupEditItem extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       name: "",
//       // code: ""
//     };
//   };

//   componentDidMount() {
//     const id = this.props.match.params.id;
//     this.props.getGroup(id, this.props.t);
//   };

//   componentDidUpdate(prevProps) {
//     if(!prevProps.group || (this.props.group !== prevProps.group)) {
//       this.setState({
//         customer_id : 1*this.props.group.customer_id,
//         name        : this.props.group.name,
//         // code        : this.props.group.code
//       });
//     };
//   };

//   onSubmit = event => {
//     event.preventDefault();
//     const { getReqSuccess, group, groups } = this.props;

//     const id = this.props.match.params.id;
//     const data = {
//       ...this.state,
//     };

//     if(group.id === data.id && group.code === data.code) {
//       this.props.history.push("/groups");
//       getReqSuccess(groups, group,
//         this.props.t("alerts.groups-item-2"));
//       return Promise.resolve();
//     }

//     return trackPromise(fetch(APIS.API_REST_GROUPS.replace("customer_id",
//                                                  localStorage.getItem("GlobalCustomerId")) + "/" + id, {
//                                                   headers:{
//                                                     "Content-Type": "application/json",
//                                                     "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
//                                                     "Accept-language": localStorage.getItem("LanguageSelected"),
//                                                   },
//         method: "PUT",
//         body: JSON.stringify(data) })
//       .then(response => response.json())
//       .then(json => {
//         const status = json.message;
//         if(!status) {
//           this.props.history.push("/groups");
//           getReqSuccess(groups, group, this.props.t("alerts.groups-item-2"));
//         } else {
//           throw new Error(status);
//         } })
//       .catch(error => {
//         toast.error(error.toString());
//       }));
//   };

//   onChange = event =>{
//     this.setState({ [event.target.name]: event.target.value });
//   };

//   showSuccessDialog = (message) => {
//     if(!!message && !!toast) {
//       toast.success(message);
//     }
//   };

//   showErrorDialog = (error) => {
//     if(!!error && !!toast) {
//       toast.error(error);
//     }
//   };

//   render () {
//     const { group, message, error } = this.props;

//     this.showErrorDialog(error);
//     this.showSuccessDialog(message);
//     return (
//       <AuthUserContext.Consumer>
//         {authUser => (
//           !!group ?
//           <div className="container-fluid mt-100">

//             <h3>{this.props.t("client-general.groups-edit")}</h3>
//             <br/><br/>
//             <form onSubmit={this.onSubmit}>
//               <div className="form-group">
//                 <div className="row">
//                   <div className="col-sm-2">
//                     <label htmlFor="nameInput">{this.props.t("client-general.client-item-1")}</label>
//                     <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
//                   </div>
//                   <div className="col-sm-10">
//                     <input className="form-control"
//                            placeholder={this.props.t("client-general.client-item-1")}
//                            id="nameInput"
//                            name="name"
//                            value={this.state.name}
//                            onChange={this.onChange}
//                            required />
//                   </div>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-md-12 text-center">
//                   <button type="submit"
//                           className="btn btn-primary">
//                     <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div> : null
//         )}
//       </AuthUserContext.Consumer>
//     );
//   };
// }

// const structuredSelector = createStructuredSelector({
//   customer  : state => state.customer,
//   customers : state => state.customers,
//   groups    : state => state.groups,
//   group     : state => state.group,
//   message   : state => state.message,
//   error     : state => state.error
// });

// const mapDispatchToProps = { getGroup, getReqSuccess };

// const condition = authUser =>
//   !!authUser;

// export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(GroupEditItem));
