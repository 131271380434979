import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsSchema from "../../constants/actions/Schemas";
import * as APIS from "../../constants/apis";
import SchemaDeleteItem from "./SchemaDeleteItem";

function getSchemas(schemas, schema, t) {
  return dispatch => {
    if (!localStorage.getItem("GlobalCustomerId")) return null;
    return trackPromise(fetch(APIS.API_REST_SCHEMAS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.schemas-item-1"));
        }
        return response.json();
      })
      .then(json => {
        dispatch(getSchemasSuccess({
          schemas: json.vehicleschemas
        }));
      })
      .catch(error => {
        dispatch(getSchemasError({
          schemas: schemas,
          schema: schema,
          error: error.message
        }));
      }));
  };
};

export function getSchemasSuccess(json) {
  return {
    type: constantsSchema.GET_SCHEMAS_SUCCESS,
    json
  };
};

export function getSchemasError(json) {
  return {
    type: constantsSchema.GET_REQUEST_ERROR,
    json
  };
};

class SchemasList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  };

  componentDidMount() {
    this.props.getSchemas(this.props.schemas, this.props.schema, this.props.t);
  };

  getSchemasList = (schemas, authUser) => {
    if (!schemas) return null;
    const { search } = this.state;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("schema.schema-th-1")}</th>
              <th scope="col">{this.props.t("schema.schema-th-2")}</th>
              <th scope="col">{this.props.t("schema.schema-th-3")}</th>
              <th scope="col">{this.props.t("schema.schema-th-4")}</th>
            </tr>
          </thead>
          <tbody>
            {
              schemas.filter(schema => {
                return schema.name.toLowerCase().search(search) >= 0;
              }).map(schema => {
                return <tr key={schema.id}>
                  <td>
                    {schema.name}
                  </td>
                  <td>
                    {schema.axesnumber}
                  </td>
                  <td>
                    {schema.tiresnumber}
                  </td>
                  <td>
                    {!!authUser.permissions.edit ?
                      <Link to={"/schemas/edit/" + schema.id}
                        className="btn btn-primary">
                        <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                  </Link> : null}&nbsp;
                 {!!authUser.permissions.delete ?
                      <SchemaDeleteItem schemaDelete={schema} t={this.props.t}/> : null}
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render() {
    const { schemas, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("schema.schema-list")}</h3>
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder={this.props.t("globals.search-input")}
                  onChange={this.onChangeSearch}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                {!!authUser.permissions.create ?
                  <Link
                    to="/schemas/new"
                    className="btn btn-primary">
                    <i className="fa fa-plus"></i> {this.props.t("globals.register")}
                </Link> : null}
              </div>
            </div>
            <br /><br />
            {this.getSchemasList(schemas, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  schemas: state => state.schemas,
  schema: state => state.schema,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getSchemas };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(SchemasList));
