import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from 'react-promise-tracker';
import * as constantsBrand from "../../constants/actions/Brands";
import * as APIS from "../../constants/apis";

function deleteBrand(name, brands, brand, t) {
  if (window.confirm(t("alerts.brands-item-6"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }, method: "DELETE"
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(t("alerts.brands-item-5"));
          }
          return response.json();
        })
        .then(json => {
          dispatch(getReqSuccess(brands, brand, json.message));
          dispatch({ type: constantsBrand.GET_BRANDS_REQUEST });
          return fetch(APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
            .then(response => response.json())
            .then(json => {
              dispatch(getBrandsSuccess(json));
            })
            .catch(error => {
              dispatch(getReqError(brands, brand, error.toString()));
            });
        })
        .catch(error => {
          dispatch(getReqError(brands, brand, error.toString()));
        }));
    }
  } else {
    return dispatch => {
      dispatch(getBrandsSuccess({
        vehiclebrands: brands,
        brand: brand
      }));
    };
  }
};

export function getBrandsSuccess(json) {
  return {
    type: constantsBrand.GET_BRANDS_SUCCESS,
    json
  };
};

export function getReqSuccess(brands, brand, message) {
  const json = {
    brands: brands,
    brand: brand,
    message: message
  };
  return {
    type: constantsBrand.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(brands, brand, error) {
  const json = {
    brands: brands,
    brand: brand,
    error: error
  };
  return {
    type: constantsBrand.GET_REQUEST_ERROR,
    json
  };
};

class BrandDeleteItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { brands, brand, brandDelete, deleteBrand } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteBrand(brandDelete.id,
          brands, brand, this.props.t)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({
  brands: state => state.brands,
  brand: state => state.brand
});

const mapDispatchToProps = { deleteBrand };

export default connect(structuredSelector, mapDispatchToProps)(BrandDeleteItem);
