import React, { useState } from "react";
import API from "../../constants/api";
import { toast } from "react-toastify";

const CustomerDeleteItem = ({ t, id, clearDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteCustomer = () => {
    if (id) {
      setIsDeleting(true)
      API.deleteCustomer(id)
        .then(response => {
          if (response.message.includes("Cliente Eliminado")) {
            toast.success(t("alerts.users-item-3"));
            setIsDeleting(false)
            clearDelete(id)
          }
        })
        .catch(error => {
          if (error.response.status !== 200) {
            toast.error(error.response.data.message.toString())
            setIsDeleting(false)
          }
        })
    }
  }

  if (isDeleting) {
    return (
      <button class="btn btn-danger" type="button" disabled={isDeleting ? true : false}>
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
      </button>
    )
  }

  return (
    <button onClick={() => deleteCustomer()}
      className="btn btn-danger" >
      {/* <i className="fa fa-trash"></i> */}
      {t("globals.delete")}
    </button >
  )
}

export default CustomerDeleteItem
