import {
  REQ_VEHICLES_SUCCES,
  REQ_VEHICLES_ERROR,
  REQ_VEHICLES_ACTIVE_SUCCES,
} from "../types/VehicleTypes";

const INITIAL_STATE = {
  vehicles: [],
  next: null,
  previus: null,
  total: null,
  error: null,
};

export const vehicleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQ_VEHICLES_SUCCES:
      return {
        ...state,
        vehicles: action.payload.vehicles,
        total: action.payload.total,
        previus: action.payload.previus,
        next: action.payload.next,
      };
    case REQ_VEHICLES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case REQ_VEHICLES_ACTIVE_SUCCES:
      let newVechiles = state.vehicles.filter(
        (vehicle) => vehicle.id !== action.payload.id
      );
      return {
        ...state,
        vehicles: newVechiles,
      };
    default:
      return state;
  }
};
