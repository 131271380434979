import React from 'react'
import { AuthUserContext, withAuthorization } from '../../Auth/Session';
import * as APIS from '../../../constants/apis'
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';

class MacroEditItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            macro_name: "",
            macro_id: "",
         }

    }

    componentDidMount() {
        this.getMacroId(this.props.match.params.id)
    }

    getMacroId = async (id) => {
        const ids = parseInt(id)
        trackPromise(fetch(APIS.API_REST_MACRO_MACRO_ACCOUNT_EDIT.replace("macro_id", ids), {
            headers: {
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            },
        })
        .then(response => response.json())
        .then(json => {
            if (!json) throw new Error(this.props.t("macros.m-item-31"))
            const macro = json.mmc_account
            this.setState({ macro_name: macro.name, macro_id: macro.id })
        })
        .catch(err => {
            toast.error(err.message)
        }))
    }

    setInput = (e) => {
        this.setState({macro_name: e.target.value})
    }

    onSubmit = (e) => {
        e.preventDefault()
        const ids = parseInt(this.props.match.params.id)
        let data = {
            name: this.state.macro_name
        }
        trackPromise(fetch(APIS.API_REST_MACRO_MACRO_ACCOUNT_EDIT.replace("macro_id", ids), {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
            },
            method: "PUT",
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
            if (json.message.name) throw new Error(json.message.name)


            toast.success(this.props.t("macros.m-item-32"))
            window.history.back();
        })
        .catch(err => {
            toast.error(err.message)
        }))
    }


    render() { 
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <div  className="container-fluid mt-100">
                    <form onSubmit={e => this.onSubmit(e)}>
                        <div className="d-flex mb-5">
                            <div className="mr-5">
                                <label>{this.props.t("macros.m-item-33")} </label>
                                <br></br>
                                <i className="text-info small" style={{marginTop: "-14px"}}>{this.props.t("globals.required")}</i>
                            </div>
                            <input
                                className="form-control mt-2 ml-3"
                                type="text"
                                id="search"
                                value={this.state.macro_name}
                                onChange={(e) => this.setInput(e)}
                                placeholder={this.props.t("macros.m-item-35")}
                                required
                                />
                        </div>
                        <button onClick={this.createMacro} disabled={this.state.macro_name ? false : true} className="btn btn-primary mt-1 d-block" style={{height: "40px", margin: "0 auto"}}>{this.props.t("macros.m-item-34")}</button>
                    </form>
                </div>
                )}
            </AuthUserContext.Consumer>

        );
    }
}



 
const condition = authUser => !!authUser;

export default withAuthorization(condition)(MacroEditItem);