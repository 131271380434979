import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import readXlsxFile from "read-excel-file";
import xlsx from "xlsx";
import DropView from "./DropView";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { trackPromise } from "react-promise-tracker";
import API from "../../constants/api";
import { toast } from "react-toastify";

const DropZone = ({ t, title, fields, type, isSelector, type_str }) => {
  const [isError, setIsError] = useState("");
  const [isSuccesResponse, setIsSuccessResponse] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileStructure, setFileStructure] = useState([]);
  const [cols, setCols] = useState(null);
  const [headers, setHeader] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    validateFormat(acceptedFiles);
  }, []);

  const validateFormat = (file) => {
    let format = file[0].name.split(".").pop();
    if (format === "xlsx") {
      validateInfo(file[0]);
    } else {
      setErrorsFn("Formato invalido, solo se admite 'xlsx'");
    }
  };

  const makeCols = (refstr) => {
    let o = [],
      C = xlsx.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = {
        name: xlsx.utils.encode_col(i),
        key: i,
      };
    }
    return o;
  };

  const validateInfo = (file) => {
    setFileStructure([]);
    setFile(null);

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = xlsx.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const rows = xlsx.utils.sheet_to_json(ws, { header: 1, raw: false });
      const cols = makeCols(ws["!ref"]);
      if (rows.length >= 10) {
        for (let j = 0; j < fields.length; j++) {
          const vue = rows[0].includes(fields[j]);
          if (!vue) {
            return setErrorsFn(
              "El campo '" + fields[j] + "', no esta presente en el archivo"
            );
          }
        }
      } else {
        return setErrorsFn(
          "El archivo contiene menos de 10 registros, Minimo 10"
        );
      }

      if (rows.length >= 10) {
        setSuccessFn();
        setFile(file);
        setFileStructure(rows);
        setCols(cols);
        setHeader(fields);
      }
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const setErrorsFn = (message) => {
    const drops = document.getElementById("dropzone");
    const dropsIcon = document.getElementById("dropicon");
    drops.classList.add("drop_error");
    drops.classList.remove("drop_success");
    dropsIcon.classList.add("drop_icon_error");
    dropsIcon.classList.remove("drop_icon_success");
    setIsError(message);
    setIsSuccessResponse("");
    setFile(null);
  };

  const setSuccessFn = () => {
    const drops = document.getElementById("dropzone");
    const dropsIcon = document.getElementById("dropicon");
    drops.classList.add("drop_success");
    drops.classList.remove("drop_error");
    dropsIcon.classList.add("drop_icon_success");
    dropsIcon.classList.remove("drop_icon_error");
    setIsError("");
    setIsSuccessResponse("");
    setFile(null);
  };

  const exportTemplate = (event) => {
    event.preventDefault();
    let header = fields;
    const ws = xlsx.utils.aoa_to_sheet([header]);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Plantilla");
    xlsx.writeFile(wb, `${title}.xlsx`);
  };

  const submitExcel = (event) => {
    event.preventDefault();
    const data = {
      file: file,
      type_massive: type,
      total_row: fileStructure.length,
      customer_id: localStorage.getItem("GlobalCustomerId"),
    };
    const fd = new FormData();
    fd.append("file", file);
    fd.append("customer_name", localStorage.getItem("GlobalCustomerName"));
    fd.append("customer_id", localStorage.getItem("GlobalCustomerId"));
    fd.append("total_row", fileStructure.length);
    fd.append("type_massive", type);

    let exStr = "";
    if (isSelector) {
      exStr = '?type=' + type_str
    }
    if (file) {
      trackPromise(
        API.massiveBulk(fd, exStr)
          .then((response) => {
            let status =
              response.status === "pending"
                ? "Pendiente"
                : response.status === "process"
                  ? "En Progreso"
                  : response.status === "success"
                    ? "Listo"
                    : response.status === "queue"
                      ? "En Cola"
                      : null;
            if (response.bulk_id) {
              setIsError("");
              setIsSuccessResponse(
                "El archivo se subio correctamente, Estado: " + status
              );
              setFile(null);
            }
          })
          .catch((error) => {
            if (error.response.status != 201) {
              toast.error(error.response.message.toString());
            }
          })
      );
    }
  };

  const showModalFn = () => {
    setShowModal(true);
    setIsError("");
    setIsSuccessResponse("");
    setFile(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const launchModal = () => {
    const handleClose = () => {
      setShowModal(false);
      setIsError(false);
      setFile(null);
    };

    return (
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="modal-sm-mc"
      >
        <Modal.Header>
          <Modal.Title style={{ flex: 1 }}>{title}</Modal.Title>
          <button onClick={exportTemplate} className="btn btn-outline-primary">
            <i className="fa fa-download"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              id="dropzone"
              className={
                isDragActive
                  ? "drop_zone drop_active rounded"
                  : "drop_zone rounded"
              }
            >
              <div className="drop_body">
                <i id="dropicon" class="far fa-file-excel mb-4 drop_icon"></i>
                {isDragActive ? (
                  <div className="drop_zone_body_1">
                    <p>Suelta tu documento de Excel en este espacio</p>
                  </div>
                ) : (
                  <div className="drop_zone_body_2">
                    <p>
                      Arrastra o presiona para seleccionar el documento de Excel
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          {isError ? (
            <div
              class="alert alert-danger"
              role="alert"
              style={{ fontWeight: "bold" }}
            >
              {isError}
            </div>
          ) : isSuccesResponse ? (
            <div
              class="alert alert-success"
              role="alert"
              style={{ fontWeight: "bold" }}
            >
              {isSuccesResponse}
            </div>
          ) : null}
          {file ? (
            <div className="drop_after_success">
              <DropView
                fileName={file.name}
                fileData={fileStructure}
                cols={cols}
                header={headers}
              />
              <div>
                <button
                  onClick={handleClose}
                  className="btn btn-outline-primary"
                >
                  Cancelar
                </button>
                <button onClick={submitExcel} className="btn btn-success">
                  Subir Excel
                </button>
              </div>
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <button onClick={() => showModalFn()} className="btn btn-info mr-3">
        <i className="fa fa-upload"></i> {t("globals.register-massive")} beta
      </button>
      {showModal ? launchModal() : null}
    </>
  );
};

export default DropZone;
