import React from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Autosuggest from "react-autosuggest";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as APIS from "../../../constants/apis";
import Pagination from "../../pagination";
import { WORK_TYPES } from "../../../constants/variables";

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => (
  <div>
    {suggestion.plate +
      " - " +
      suggestion.vehiclenumber +
      (suggestion.sap ? " - " + suggestion.sap : "")}
  </div>
);

class WorksList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 20,
      suggestions: [],
      vehicles: [],
      car_code: "",
      vehicle_id: "",
      vehicle: null,
      works: [],
      pageP: null,
      pageN: null,
      total: 0,
    };
  }

  componentDidMount() {
    this.getVehicles();
    if (this.props.match.params.id !== ":id") {
      this.setState({ vehicle_id: this.props.match.params.id });
      this.getWorks(this.props.match.params.id, 1);
    } else {
      this.setState({ vehicle_id: "", works: "", total: 0 });
    }
  }

  getSuggestions = (value) => {
    let vehicles = this.state.vehicles;
    let fmtSuggestions = vehicles.filter((vehicle) => {
      const fmtValue = escapeRegexCharacters(value);
      return (
        vehicle.plate.toLowerCase().search(fmtValue) >= 0 ||
        vehicle.vehiclenumber.toLowerCase().search(fmtValue) >= 0 ||
        (vehicle.sap !== null &&
          vehicle.sap.toLowerCase().search(fmtValue) >= 0)
      );
    });
    return fmtSuggestions.slice(0, 20);
  };

  onPlatesFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onPlatesClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  getVehicles = () => {
    return trackPromise(
      fetch(
        `${APIS.API_REST_VEHICLES_MIN_INFO.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        )}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-1"));
          }
          return response.json();
        })
        .then((json) => {
          this.setState({ vehicles: json.vehicles });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.work-tire-item-1"));
        })
    );
  };

  getWorks = (vehicle_id, page, limit) => {
    var str = "";
    let pageP, pageN, works, total;
    if (page !== "0" && !!page) {
      str = "?page=" + page + "&limit=" + limit;
    } else {
      str = "?page=1&limit=50";
    }
    return trackPromise(
      fetch(
        `${
          APIS.API_REST_VEHICLE_WORK.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("vehicle_id", vehicle_id) + str
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-2"));
          }
          return response.json();
        })
        .then((json) => {
          if (!!json.previus) {
            str = json.previus;
            pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          if (!!json.next) {
            str = json.next;
            pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          works = json.works;
          total = json.total;
        })
        .then(() =>
          fetch(
            `${APIS.API_REST_VEHICLES.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            )}/${vehicle_id}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          )
        )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-3"));
          }
          return response.json();
        })
        .then((json) => {
          this.setState({
            works: works,
            pageP: pageP,
            pageN: pageN,
            total: total,
            vehicle: json,
          });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.work-tire-item-2"));
        })
    );
  };

  deleteWork = (vehicle_id, id) => {
    return trackPromise(
      fetch(
        `${APIS.API_REST_VEHICLE_WORK.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", vehicle_id)}/${id}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "DELETE",
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-4"));
          }
          return response.json();
        })
        .then((json) => {
          toast.success(this.props.t("alerts.work-tire-item-10"));
          this.getWorks(vehicle_id, 1);
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.work-tire-item-1"));
        })
    );
  };

  getWorksList = (works, pageP, pageN, authUser) => {
    if (!works) return null;
    const { search } = this.state;
    let pages = [];
    let start, end;
    let totalPages = Math.ceil(this.state.total / this.state.limit);
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
      start = Math.max(1, this.state.page - 4);
      end = Math.min(totalPages, this.state.page + 5);

      if (start === 1) {
        end = 10;
      } else if (end === totalPages) {
        start = totalPages - 9;
      }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("work-vehicle.client-item-1")}</th>
              <th scope="col">{this.props.t("work-vehicle.client-item-2")}</th>
              <th scope="col">{this.props.t("work-vehicle.client-item-3")}</th>
              <th scope="col">{this.props.t("work-vehicle.client-item-4")}</th>
              <th scope="col">{this.props.t("work-vehicle.client-item-5")}</th>
              <th scope="col">{this.props.t("work-vehicle.client-item-6")}</th>
              <th scope="col">{this.props.t("work-vehicle.client-item-7")}</th>
            </tr>
          </thead>
          <tbody>
            {works.map((work) => {
              return (
                <tr key={work.id}>
                  <td>
                    {WORK_TYPES.map((type) => {
                      if (type.value === work.worktype.toLowerCase()) {
                        return this.props.t(`selectors.${type.name}`);
                      }
                      return null;
                    })}
                  </td>
                  <td>{work.kilometers}</td>
                  <td>{work.cost}</td>
                  <td>
                    {!!work.params
                      ? !!work.params[0].cantidad
                        ? work.params[0].cantidad
                        : null
                      : null}
                  </td>
                  <td>{work.next_measurament}</td>
                  <td>
                    {moment
                      .utc(work.work_date)
                      .local()
                      .locale("es")
                      .format("LLL")}
                  </td>
                  <td>
                    {!!authUser.permissions.edit ? (
                      <Link
                        to={
                          "/works/vehicles/edit/" +
                          this.state.vehicle_id +
                          "/" +
                          work.id
                        }
                        className="btn btn-primary"
                      >
                        <i className="fa fa-edit"></i>{" "}
                        {this.props.t("globals.edit")}
                      </Link>
                    ) : null}
                    &nbsp;
                    {!!authUser.permissions.delete ? (
                      <button
                        onClick={() =>
                          this.deleteWork(this.state.vehicle_id, work.id)
                        }
                        className="btn btn-danger"
                      >
                        <i className="fa fa-trash"></i>{" "}
                        {this.props.t("globals.delete")}
                      </button>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
          {works.length > 0 ? (
            <Pagination
              colspan="4"
              pageP={pageP}
              pageN={pageN}
              page={this.state.page}
              limit={this.state.limit}
              links={links}
              submit={(page, limit) => {
                this.setState({ page: page, limit: limit }, () => {
                  this.getWorks(this.state.vehicle_id, page, limit);
                });
              }}
            />
          ) : null}
        </table>
      </div>
    );
  };

  onChange = (event, { newValue }) => {
    if (typeof newValue === "object" && newValue !== null) {
      this.setState({ car_code: newValue.plate });
    } else {
      let vehicles = this.state.vehicles;
      for (let i = 0; i < vehicles.length; i++) {
        if (vehicles[i].plate === newValue) {
          this.setState({ car_code: vehicles[i].plate });
          break;
        } else {
          this.setState({
            car_code: newValue,
          });
        }
      }
    }
  };

  onSelectPlate = () => {
    let vehicles = this.state.vehicles;
    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].plate === this.state.car_code) {
        this.props.history.push("/works/vehicles/" + vehicles[i].id);
        this.setState({ vehicle_id: vehicles[i].id });
        this.getWorks(vehicles[i].id, 1);
        break;
      }
    }
  };

  render() {
    const plateProps = {
      placeholder: this.props.t("alerts.work-tire-item-11"),
      value: this.state.car_code,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "1%",
        marginBottom: 0,
      },
      onChange: this.onChange,
    };

    let isInvalidCarCode = this.state.car_code === "";
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("work-vehicle.client-item-8")}</h3>
              </div>
              <div className="col-md-4 input-group">
                <Autosuggest
                  suggestions={this.state.suggestions}
                  onSuggestionsFetchRequested={this.onPlatesFetchRequested}
                  onSuggestionsClearRequested={this.onPlatesClearRequested}
                  shouldRenderSuggestions={() => true}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={plateProps}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary text-white"
                    onClick={this.onSelectPlate}
                    disabled={isInvalidCarCode}
                  >
                    <i className="fa fa-search"></i>{" "}
                    {this.props.t("work-vehicle.client-item-9")}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                {!!authUser.permissions.create ? (
                  !!this.state.vehicle_id ? (
                    <Link
                      to={"/works/vehicles/new/" + this.state.vehicle_id}
                      className="btn btn-primary"
                    >
                      <i className="fa fa-plus"></i>{" "}
                      {this.props.t("globals.register")}
                    </Link>
                  ) : null
                ) : null}
              </div>
              <div className="col-md-6">
                <p className="pull-right">
                  {!!this.state.vehicle
                    ? "Placa: " +
                      this.state.vehicle.plate +
                      " Numero: " +
                      this.state.vehicle.vehiclenumber
                    : null}
                </p>
                <p className="pull-right">
                  {!!this.state.vehicle
                    ? "Ultima medicion: " +
                      this.state.vehicle.fuel_km_info.kilometers
                    : null}
                </p>
                <p className="pull-right">
                  <b>
                    {!!this.state.vehicle
                      ? this.state.vehicle.vehiclebrand +
                        " " +
                        this.state.vehicle.linemodel +
                        " " +
                        this.state.vehicle.year
                      : null}
                  </b>
                </p>
                <p className="pull-right">
                  {this.props.t("work-vehicle.client-item-10")}{" "}
                  <b>{!!this.state.total ? this.state.total : 0}</b>
                </p>
              </div>
            </div>
            <br></br>
            {this.getWorksList(
              this.state.works,
              this.state.pageP,
              this.state.pageN,
              authUser
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(WorksList);
