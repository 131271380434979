import {
  REQ_VEHICLES_SUCCES,
  REQ_VEHICLES_ERROR,
  REQ_VEHICLES_ACTIVE_SUCCES,
} from "../types/VehicleTypes";
import API from "../../constants/api";
import { setErrorAction } from "./MapErrorsAction";

export const getVehiclesAction = (
  page,
  inactive,
  search,
  limit,
  customerId
) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      var str = "";
      if (page !== "0") {
        str = "?page=" + page + "&limit=" + limit;
        if (inactive) str = str + "&inactive=" + inactive;
        if (search) str = str + "&placa=" + search;
      }
      API.getAllVehicles(customerId, str)
        .then((res) => {
          dispatch({
            type: REQ_VEHICLES_SUCCES,
            payload: res,
          });
          resolve(res)
        })
        .catch((error) => {
          dispatch({
            type: REQ_VEHICLES_ERROR,
            payload: error.response.message.toString(),
          });
        });
    })
};

export const getVehiclesSearchAction = (
  page,
  inactive,
  search,
  limit,
  customerId
) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      var str = "";
      if (page !== "0") {
        str = "?page=" + page + "&limit=" + limit;
        if (inactive) str = str + "&inactive=" + inactive;
        if (search) str = str + "&placa=" + search;
      }
      API.getAllVehicles(customerId, str)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        });
    })
};

export const putVehiclesActiveAction = (customer_id, vehicle_id, vehicle) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      API.putVehicle(customer_id, vehicle_id, vehicle)
        .then((res) => {
          dispatch({
            type: REQ_VEHICLES_ACTIVE_SUCCES,
            payload: res,
          });
          resolve(res);
        })
        .catch((error) => {
          dispatch({
            type: REQ_VEHICLES_ERROR,
            payload: error.response.message.toString(),
          });
          reject(error);
        });
    });
};

// export const getTireAction = (customerId, id) => {
//   return (dispatch) => {
//     API.getTire(customerId, id)
//       .then((res) => {
//         dispatch({
//           type: REQ_TIRE_SUCCESS,
//           payload: res,
//         });
//       })
//       .catch((error) => dispatch(setErrorAction(error.toString())));
//   };
// };

// export const putTireAction = (customerId, id, data) => {
//   return (dispatch) => {
//     trackPromise(
//       API.putTire(customerId, id, data)
//         .then((res) => {
//           dispatch({
//             type: PUT_TIRE_SUCCESS,
//             payload: res,
//           });
//           window.history.back();
//           toast.success("Llanta actualizada");
//         })
//         .catch((error) => {
//           if (error.response.status != 200) {
//             dispatch(setErrorAction(error.response.data.message.toString()));
//           }
//         })
//     );
//   };
// };

// export const deleteTireAction = (customerId, tire) => {
//   return (dispatch) => {
//     trackPromise(
//       API.deleteTire(customerId, tire.id)
//         .then((res) => {
//           dispatch({
//             type: DELETE_TIRE_SUCCESS,
//             payload: tire,
//           });
//         })
//         .catch((error) => {
//           if (error.response.status != 200) {
//             dispatch(setErrorAction(error.response.data.message.toString()));
//           }
//         })
//     );
//   };
// };

// export const clearTiresErrorAction = () => {
//   return (dispatch) => {
//     dispatch({
//       type: CLEAR_TIRES_ERROR,
//       payload: null,
//     });
//   };
// };

// export const clearTiresAction = () => {
//   return (dispatch) => {
//     dispatch({
//       type: CLEAR_TIRES_SUCCESS,
//       payload: [],
//     });
//   };
// };
