import React from "react";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import Metabase from "../Reports/Metabase";
import * as VARIABLES from "../../constants/variables";
import ZonalCuentaZonas from "./ZonalCuentaZonas";
import ValidateMacros from "./ValidateMacros";



class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {  }
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <>
            {
            authUser.isMc === true || authUser.isMmc === true ?
              <ValidateMacros authUser={authUser} t={this.props.t}/>
            :
            <div className="container-fluid px-0" style={{ backgroundColor: '#fff'}}>
              { localStorage.getItem("logo") ? 
                <div style={{height: "100vh", backgroundColor: "white"}}></div> :
                <img src={localStorage.getItem("LanguageSelected") === "es" ? "/images/banner_es.jpg" : "/images/banner_pt.jpg"}
                  alt="Ruedata - Banner"
                  className="img-fluid"
                  style={{width: "100%"}} />
              }
            </div> 
            }
          </> 
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(Landing);