import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsBrand from "../../constants/actions/TireBrands";
import * as APIS from "../../constants/apis";

import TireBrandDeleteItem from "./TireBrandDeleteItem";

function getBrands(brands, next, previus, page, search,limit) {
  return dispatch => {
    var str = "";
    if (page !== "0") {
      str = "?page=" + page + "&limit="+limit;
      if (search !== "")
        str = str + "&name=" + search
    }
    return trackPromise(fetch(APIS.API_REST_TIREBRANDS + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        dispatch(onBrandsSuccess({
          previus: json.previus,
          next: json.next,
          total: json.total,
          brands: json.Tirebrands
        }))
      })
      .catch(error => {
        dispatch(onRequestError({
          brands: brands,
          previus: next,
          next: previus,
          total: 0,
          error: error.message
        }));
      }));
  };
};

export function onBrandsSuccess(json) {
  return {
    type: constantsBrand.GET_BRANDS_SUCCESS,
    json
  };
};

export function onRequestError(json) {
  return {
    type: constantsBrand.REQUEST_ERROR,
    json
  };
};

class BrandsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      page: 1,
      limit: 20
    };
  };

  componentDidMount() {
    const { brands, next, previus } = this.props;
    this.props.getBrands(brands, next, previus, 1, "", this.state.limit);
  };

  generateExcel() {
    return trackPromise(fetch(APIS.API_REST_TIREBRANDS_EXCEL, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(this.props.t("alerts.b-tire-item-1"));
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.props.t("alerts.b-tire-item-2")}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success(this.props.t("alerts.b-tire-item-3"));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  getBrandsList = (brands, next, previus, authUser) => {
    if (!brands) return null;

    const { search } = this.state;
    let pageP, pageN, str;
    if (!!previus) {
      str = previus;
      pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    if (!!next) {
      str = next;
      pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    let pages = [];
    let start,end;
    let totalPages = Math.ceil(this.props.total/this.state.limit);
    for(var i=1;i<=totalPages;i++){
      pages.push(i)
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
        start = Math.max(1, (this.state.page - 4));
        end = Math.min(totalPages, (this.state.page + 5));
    
        if (start === 1) {
            end = 10;
        } else if (end === totalPages) {
            start = (totalPages - 9);
        }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("tire-brands.tire-item-1")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-2")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-3")}</th>
            </tr>
          </thead>
          <tbody>
            {
              brands.map(brand => {
                return <tr key={brand.id}>
                  <td>
                    {brand.name}
                  </td>
                  <td>
                    {
                      !!brand.active ? "Activo" : "Inactivo"
                    }
                  </td>
                  <td>
                      <Link to={"/tirebrands/" + brand.id + "/tires"}
                        className="btn btn-primary">
                        <i className="fa fa-bullseye"></i> {this.props.t("tire-brands.tire-text")}
                      </Link>&nbsp;
                    {!!authUser.permissions.edit && authUser.roles != "ANALYST" ?
                      <Link to={"/tirebrands/edit/" + brand.id}
                        className="btn btn-primary">
                        <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                  </Link> :
                  <Link to={"/tirebrands/edit/" + brand.id}
                     className="btn btn-primary">
                      <i className="fa fa-eye"></i> {this.props.t("globals.see")}
                   </Link>
                  }&nbsp;
                 {!!authUser.permissions.delete && authUser.roles != "ANALYST" ?
                      <TireBrandDeleteItem brandDelete={brand} t={this.props.t} /> : null}
                  </td>
                </tr>
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="3">
                <nav aria-label="Navigation">
                  <ul class="mt-3 pagination justify-content-center">
                    <li class="page-item">
                    {
                      !!previus ?
                        <button
                          className="page-link"
                          onClick={(e) => {this.setState({page:pageP});this.props.getBrands(brands, next, previus, pageP, search, this.state.limit)}}>
                          <i className="fa fa-arrow-left"></i>
                        </button>
                        :
                        <button
                          className="page-link"
                          disabled>
                          <i className="fa fa-arrow-left"></i>
                        </button>
                    }
                    </li>
                    {
                      links.map((page, index) => {
                        let clase = (page == this.state.page) ? "active" : ""
                        return <li className={"page-item " + clase}>
                              <button class="page-link" onClick={(e) => {this.setState({page:page});this.props.getBrands(brands, next, previus, page, search, this.state.limit)}}>{page}</button>
                              </li>
                      })
                    }
                    <li class="page-item">
                    {
                      !!next ?
                        <button
                          className="page-link"
                          onClick={(e) => {this.setState({page:pageN});this.props.getBrands(brands, next, previus, pageN, search, this.state.limit)}}>
                          <i className="fa fa-arrow-right"></i>
                        </button>
                        :
                        <button
                          className="page-link"
                          disabled>
                          <i className="fa fa-arrow-right"></i>
                        </button>
                    }
                    </li>
                    <li className="page-item">
                      <div class="btn-group" role="group">
                        <button id="btnGroupDrop1" type="button" class="page-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {this.props.t("tire-brands.tire-showing-1")} { this.state.limit} {this.props.t("tire-brands.tire-showing-2")}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <button class="dropdown-item" 
                          onClick={(e) => {this.setState({limit:20}, () => {this.props.getBrands(brands, next, previus, 1, search, this.state.limit)})}}>{this.props.t("tire-brands.tire-show")} 20</button>
                          <button class="dropdown-item" 
                          onClick={(e) => {this.setState({limit:50}, () => {this.props.getBrands(brands, next, previus, 1, search, this.state.limit)})}}>{this.props.t("tire-brands.tire-show")} 50</button>
                          <button class="dropdown-item" onClick={(e) => {this.setState({limit:100}, () => { this.props.getBrands(brands, next, previus, 1, search, this.state.limit)})}}>{this.props.t("tire-brands.tire-show")} 100</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  onChange = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render() {
    const { brands, message, error, next, previus, total } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            {console.log(authUser)}
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("tire-brands.tire-list")}</h3>
              </div>
              <div className="col-md-4 pull-right">
                <div className="input-group mb-3 flex-nowrap">
                  <input
                    aria-describedby="btnSearch"
                    className="form-control"
                    type="text"
                    id="search"
                    placeholder={this.props.t("globals.search-input")}
                    onChange={this.onChange}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" id="btnSearch"
                      onClick={(event) => this.props.getBrands(brands, next, previus, 1, this.state.search)}>
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                {!!authUser.permissions.create && authUser.roles != "ANALYST" ?
                  <Link
                    to="/tirebrands/new"
                    className="btn btn-primary">
                    <i className="fa fa-plus"></i> {this.props.t("globals.register")}
                </Link> : null}
              </div>
              <div className="col-md-6">
                <div className="float-right">
                  <button className="btn btn-success"
                    onClick={(event) =>
                      this.generateExcel()}>
                    <i className="fa fa-file-export"></i> {this.props.t("globals.exports")}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <p>{this.props.t("tire-brands.tire-total-catalogues")} <b>{!!total ? total : 0}</b></p>
              </div>
            </div>
            <br />
            {this.getBrandsList(brands, next, previus, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  brands: state => state.brands,
  message: state => state.message,
  next: state => state.next,
  previus: state => state.previus,
  total: state => state.total,
  error: state => state.error
});

const mapDispatchToProps = { getBrands };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(BrandsList));
