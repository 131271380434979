import React, { Component } from "react";
import InspectionVlForm from "./InspectionVlForm";
import TypeForm from "./TypeForm";
import MoreInfoCarInfo from "./MoreInfoCarInfo";
import moment from "moment";
import * as VARIABLES from "../../../constants/variables";
import NoveltysList from "./NoveltysList";

const fieldsInspection = VARIABLES.INSPECTION_FIELDS;

const INITIAL_STATE = {
  fields: [],
  type: "",
  error: "",
};

class TireRow extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.modal = React.createRef();
    this.state = { ...INITIAL_STATE };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.object !== this.props.object) {
      this.setState({ ...INITIAL_STATE });
    }
  }

  resetInitialState = () => {
    this.setState({ ...INITIAL_STATE });
  };

  setTypeDefault = () => {
    this.setState({
      fields: [],
      type: "",
    });
  };

  setTypeUnmount = () => {
    let cfields = [...fieldsInspection];
    this.setState({
      type: "desmonte",
      fields: cfields.filter(
        (st) =>
          st !== "marca_id" &&
          st !== "modelo_id" &&
          st !== "dimension_id" &&
          st !== "prof_original" &&
          st !== "prof_minima" &&
          st !== "vida" &&
          st !== "diseno" &&
          st !== "ciudad" &&
          st !== "centro_costo" &&
          st !== "banda_reencauche" &&
          st !== "precio_reencauche" &&
          st !== "precio_regrabado" &&
          st !== "precio_catalogo" &&
          st !== "sin_uso" &&
          st !== "pos_final" &&
          st !== "pos_initial"
      ),
    });
  };

  setTypeMount = () => {
    let cfields = [...fieldsInspection];
    this.setState({
      type: "montaje",
      fields: cfields.filter(
        (st) =>
          st !== "marca_id" &&
          st !== "modelo_id" &&
          st !== "dimension_id" &&
          st !== "prof_original" &&
          st !== "prof_minima" &&
          st !== "vida" &&
          st !== "diseno" &&
          st !== "ciudad" &&
          st !== "centro_costo" &&
          st !== "ubicacion" &&
          st !== "banda_reencauche" &&
          st !== "precio_reencauche" &&
          st !== "precio_regrabado" &&
          st !== "tipo_trabajo" &&
          st !== "costo" &&
          st !== "precio_catalogo" &&
          st !== "sin_uso" &&
          st !== "pos_final" &&
          st !== "pos_initial"
      ),
    });
  };

  setTypeInspection = () => {
    let cfields = [...fieldsInspection];
    this.setState({
      type: "inspeccion",
      fields: cfields.filter(
        (st) =>
          st !== "marca_id" &&
          st !== "modelo_id" &&
          st !== "dimension_id" &&
          st !== "prof_original" &&
          st !== "prof_minima" &&
          st !== "vida" &&
          st !== "diseno" &&
          st !== "ciudad" &&
          st !== "centro_costo" &&
          st !== "ubicacion" &&
          st !== "banda_reencauche" &&
          st !== "precio_reencauche" &&
          st !== "precio_regrabado" &&
          st !== "tipo_trabajo" &&
          st !== "costo" &&
          st !== "precio_catalogo" &&
          st !== "sin_uso" &&
          st !== "pos_final" &&
          st !== "pos_initial"
      ),
    });
  };

  setTypePreasure = () => {
    let cfields = [...fieldsInspection];
    this.setState({
      type: "preasure_inspection",
      fields: cfields.filter(
        (st) =>
          st !== "marca_id" &&
          st !== "modelo_id" &&
          st !== "dimension_id" &&
          st !== "prof_original" &&
          st !== "prof_minima" &&
          st !== "vida" &&
          st !== "diseno" &&
          st !== "ciudad" &&
          st !== "centro_costo" &&
          st !== "ubicacion" &&
          st !== "banda_reencauche" &&
          st !== "precio_reencauche" &&
          st !== "precio_regrabado" &&
          st !== "tipo_trabajo" &&
          st !== "costo" &&
          st !== "precio_catalogo" &&
          st !== "sin_uso" &&
          st !== "pos_final" &&
          st !== "pos_initial" &&
          st !== "order" &&
          st !== "ult_prof_centro" &&
          st !== "ult_prof_centro_exterior" &&
          st !== "ult_prof_interior"
      ),
    });
  };

  setTypeVehicleRotation = (final_position, have_cancel) => {
    let cfields = [...fieldsInspection];
    this.setState({
      type: "vehicle_rotation",
      fields: cfields.filter(
        (st) =>
          st !== "marca_id" &&
          st !== "modelo_id" &&
          st !== "dimension_id" &&
          st !== "prof_original" &&
          st !== "prof_minima" &&
          st !== "vida" &&
          st !== "diseno" &&
          st !== "ciudad" &&
          st !== "centro_costo" &&
          st !== "ubicacion" &&
          st !== "banda_reencauche" &&
          st !== "precio_reencauche" &&
          st !== "precio_regrabado" &&
          st !== "tipo_trabajo" &&
          st !== "costo" &&
          st !== "precio_catalogo" &&
          st !== "sin_uso"
      ),
    });
  };

  setAllTypeWarehouse = (name, id) => {
    this.form.current.onChangeFastWarehouse(name, id);
  };

  onChange = (event) => {
    let cfields = [...fieldsInspection];
    switch (event.target.value) {
      case "desmonte":
        cfields = cfields.filter(
          (st) =>
            st !== "marca_id" &&
            st !== "modelo_id" &&
            st !== "dimension_id" &&
            st !== "prof_original" &&
            st !== "prof_minima" &&
            st !== "vida" &&
            st !== "diseno" &&
            st !== "ciudad" &&
            st !== "centro_costo" &&
            st !== "banda_reencauche" &&
            st !== "precio_reencauche" &&
            st !== "precio_regrabado" &&
            st !== "precio_catalogo" &&
            st !== "sin_uso" &&
            st !== "pos_final" &&
            st !== "pos_initial"
        );
        break;
      case "montaje":
        cfields = cfields.filter(
          (st) =>
            st !== "marca_id" &&
            st !== "modelo_id" &&
            st !== "dimension_id" &&
            st !== "prof_original" &&
            st !== "prof_minima" &&
            st !== "vida" &&
            st !== "diseno" &&
            st !== "ciudad" &&
            st !== "centro_costo" &&
            st !== "ubicacion" &&
            st !== "banda_reencauche" &&
            st !== "precio_reencauche" &&
            st !== "precio_regrabado" &&
            st !== "tipo_trabajo" &&
            st !== "costo" &&
            st !== "precio_catalogo" &&
            st !== "sin_uso" &&
            st !== "pos_final" &&
            st !== "pos_initial"
        );
        break;
      case "inspeccion":
        cfields = cfields.filter(
          (st) =>
            st !== "marca_id" &&
            st !== "modelo_id" &&
            st !== "dimension_id" &&
            st !== "prof_original" &&
            st !== "prof_minima" &&
            st !== "vida" &&
            st !== "diseno" &&
            st !== "ciudad" &&
            st !== "centro_costo" &&
            st !== "ubicacion" &&
            st !== "banda_reencauche" &&
            st !== "precio_reencauche" &&
            st !== "precio_regrabado" &&
            st !== "tipo_trabajo" &&
            st !== "costo" &&
            st !== "precio_catalogo" &&
            st !== "sin_uso" &&
            st !== "pos_final" &&
            st !== "pos_initial"
        );
        break;
      case "vehicle_rotation":
        cfields = cfields.filter(
          (st) =>
            st !== "marca_id" &&
            st !== "modelo_id" &&
            st !== "dimension_id" &&
            st !== "prof_original" &&
            st !== "prof_minima" &&
            st !== "vida" &&
            st !== "diseno" &&
            st !== "ciudad" &&
            st !== "centro_costo" &&
            st !== "ubicacion" &&
            st !== "banda_reencauche" &&
            st !== "precio_reencauche" &&
            st !== "precio_regrabado" &&
            st !== "tipo_trabajo" &&
            st !== "costo" &&
            st !== "precio_catalogo" &&
            st !== "sin_uso"
        );
        break;
      case "rin_rotation":
        cfields = cfields.filter(
          (st) =>
            st !== "marca_id" &&
            st !== "modelo_id" &&
            st !== "dimension_id" &&
            st !== "prof_original" &&
            st !== "prof_minima" &&
            st !== "vida" &&
            st !== "diseno" &&
            st !== "ciudad" &&
            st !== "centro_costo" &&
            st !== "ubicacion" &&
            st !== "banda_reencauche" &&
            st !== "precio_reencauche" &&
            st !== "precio_regrabado" &&
            st !== "tipo_trabajo" &&
            st !== "costo" &&
            st !== "precio_catalogo" &&
            st !== "sin_uso" &&
            st !== "pos_final" &&
            st !== "pos_initial"
        );
        break;
      case "preasure_inspection":
        cfields = cfields.filter(
          (st) =>
            st !== "marca_id" &&
            st !== "modelo_id" &&
            st !== "dimension_id" &&
            st !== "prof_original" &&
            st !== "prof_minima" &&
            st !== "vida" &&
            st !== "diseno" &&
            st !== "ciudad" &&
            st !== "centro_costo" &&
            st !== "ubicacion" &&
            st !== "banda_reencauche" &&
            st !== "precio_reencauche" &&
            st !== "precio_regrabado" &&
            st !== "tipo_trabajo" &&
            st !== "costo" &&
            st !== "precio_catalogo" &&
            st !== "sin_uso" &&
            st !== "pos_final" &&
            st !== "pos_initial" &&
            st !== "order" &&
            st !== "ult_prof_centro" &&
            st !== "ult_prof_centro_exterior" &&
            st !== "ult_prof_interior"
        );
        break;
      default:
        cfields = [];
    }
    this.setState({
      fields: cfields,
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const {
      object,
      getAllPositions,
      objects,
      km,
      kms,
      hour,
      hours,
      date,
      technician,
      tires,
      warehouses,
      order,
      authUser,
      onSubmitCar,
      positionsRow,
      changeStatusPositions,
      getAllByVehiclePositions,
    } = this.props;
    const { type, fields } = this.state;

    /*
    !!object.codigo ?
              (!!object.retreadbrand &&
               !!object.retreadband &&
               !!object.retreadimention ?
                "R" : "N") : ""
                */

    return (
      <tr className={!object.codigo ? "bg-info" : ""}>
        <td id={"tire_type_" + object.posicion_montaje}>
          {!!this.props.authUser.permissions.create ? (
            <TypeForm
              type={type}
              t={this.props.t}
              typeform={"vehicle"}
              delCreate={true}
              showRotations={
                this.props.isPending || this.props.isScrap ? false : true
              }
              onChange={this.onChange}
            />
          ) : null}
        </td>
        <td>
          {1 * object.posicion_montaje < 0
            ? 1 * object.posicion_montaje + " (Rep)"
            : 1 * object.posicion_montaje}
        </td>
        <td>{object.codigo}</td>
        <td>
          {!!object.marca
            ? object.marca + " " + object.modelo + " " + object.dimension
            : ""}
          <br></br>
          <b>
            {typeof object.retreadband == "undefined" ? "" : object.retreadband}{" "}
            {typeof object.retreadband == "undefined"
              ? ""
              : object.retreadimention}{" "}
            {!!object.codigo
              ? !!object.retreadbrand &&
                !!object.retreadband &&
                !!object.retreadimention
                ? "R"
                : "N"
              : ""}
            {object.vida}{" "}
            {object.regrabada == null
              ? ""
              : object.regrabado > 0
              ? `RG${object.regrabado}`
              : ""}
          </b>
        </td>
        <td>
          {!!object.fc_ultima_inspeccion
            ? moment
                .utc(object.fc_ultima_inspeccion)
                .local()
                .locale("es")
                .format("LLL")
            : ""}
        </td>
        <td>
          {!!object.fc_ult_pressure
            ? moment
                .utc(object.fc_ult_pressure)
                .local()
                .locale("es")
                .format("LLL")
            : ""}
        </td>
        <td>
          <div className="row">
            <MoreInfoCarInfo
              t={this.props.t}
              ref={this.modal}
              records={this.props.records}
              waste={this.props.waste}
              movements={this.props.movements}
              novelties={this.props.novelties}
              object={object}
              plateCode={object.vehiculo}
              authUser={authUser}
              onSubmitCar={onSubmitCar}
            />
            {!!object.marca ? (
              <NoveltysList
                t={this.props.t}
                authUser={authUser}
                tireId={object.id}
                noveltysCount=""
                divId={object.id}
              />
            ) : null}
          </div>
        </td>
        <td>
          {!!fields && fields.length > 0 ? (
            <InspectionVlForm
              t={this.props.t}
              ref={this.form}
              records={this.props.records}
              waste={this.props.waste}
              novelties={this.props.novelties}
              movements={this.props.movements}
              object={object}
              fields={fields}
              tires={tires}
              warehouses={warehouses}
              date={date}
              kms={kms}
              hours={hours}
              km={km}
              hour={hour}
              technician={technician}
              order={order}
              getAllPositions={getAllPositions}
              objects={objects}
              customerId={object.customer_id}
              type={type}
              resetState={this.resetInitialState}
              authUser={authUser}
              firebaseUser={this.props.firebaseUser}
              getVehicles={this.props.getVehicles}
              onSubmitCar={onSubmitCar}
              positionsRow={positionsRow}
              changeStatusPositions={changeStatusPositions}
              getAllByVehiclePositions={getAllByVehiclePositions}
              carCode={this.props.carCode}
            />
          ) : null}
        </td>
      </tr>
    );
  }
}

export default TireRow;
