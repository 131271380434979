import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsCostCenter from "../../../constants/actions/CostCenters";
import * as APIS from "../../../constants/apis";

function getCostCenter(name, t) {
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_COSTCENTERS.replace("customer_id",
    localStorage.getItem("GlobalCustomerId"))}/${name}`, {
                                                    headers:{
                                                      "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                      "Accept-language": localStorage.getItem("LanguageSelected"),
                                                    } })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(t("alerts.cost-item-2"));
        }
        return response.json(); })
      .then(json => {
        dispatch(getCostCenterSuccess({
          ccenter  : json
        })); })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };
};

export function getCostCenterSuccess(json) {
  return {
    type: constantsCostCenter.GET_CCENTER_SUCCESS,
    json
  };
};

export function getReqSuccess(ccenters, ccenter, message) {
  const json = {
    ccenters  : ccenters,
    ccenter   : ccenter,
    message   : message
  };
  return {
    type: constantsCostCenter.GET_REQUEST_SUCCESS,
    json
  };
};

class CostCenterEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // code: ""
    };
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getCostCenter(id, this.props.t);
  };

  componentDidUpdate(prevProps) {
    if(!prevProps.ccenter || (this.props.ccenter !== prevProps.ccenter)) {
      this.setState({
        name        : this.props.ccenter.name,
        // code        : this.props.ccenter.code
      });
    };
  };

  onSubmit = event => {
    event.preventDefault();
    const { getReqSuccess, ccenter, ccenters } = this.props;

    const name = this.props.match.params.id;
    const data = {
      ...this.state,
    };

    if(ccenter.name === data.name && ccenter.code === data.code) {
      this.props.history.push("/costcenters");
      getReqSuccess(ccenters, ccenter,
        this.props.t("alerts.cost-item-2"));
      return Promise.resolve();
    }

    return trackPromise(fetch(`${APIS.API_REST_COSTCENTERS.replace("customer_id",
    localStorage.getItem("GlobalCustomerId"))}/${name}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers:{
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        } })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if(!status) {
          this.props.history.push("/costcenters");
          getReqSuccess(ccenters, ccenter,
            this.props.t("alerts.cost-item-2"));
        } else {
          throw new Error(status);
        } })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onChange = event =>{
    this.setState({ [event.target.name]: event.target.value });
  };

  showSuccessDialog = (message) => {
    if(!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if(!!error && !!toast) {
      toast.error(error);
    }
  };

  render () {
    const { ccenter, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!ccenter ?
          <div className="container-fluid mt-100">
            
            <h3>{this.props.t("client-general.cost-edit")}</h3>
            <br/><br/>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">{this.props.t("client-general.client-item-1")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("client-general.client-item-1")}
                           id="nameInput"
                           name="name"
                           value={this.state.name}
                           onChange={this.onChange}
                           required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                          className="btn btn-primary">
                    <i className="fa fa-save"/><i/> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  ccenters  : state => state.ccenters,
  ccenter   : state => state.ccenter,
  message   : state => state.message,
  error     : state => state.error
});

const mapDispatchToProps = { getCostCenter, getReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(CostCenterEditItem));
