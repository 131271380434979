import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withAuthorization } from "../Auth/Session";
import * as APIS from "../../constants/apis";
import GetWasteModal from '../Customers/Vehicles/Tires/Multiple/Wastels'
import { trackPromise } from "react-promise-tracker";

const Formats = ({t}) => {
    const [discards, setDiscards] = useState([])
    const [antecedents, setAntecedents] = useState([])
    const [noveltys, setNoveltys] = useState([])
    const [isView, setIsView] = useState(false)

    useEffect(() => {
        trackPromise(fetch(`${APIS.API_REST_WASTES_LIST}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
        .then(response => response.json())
        .then(json => {
          if (!!json.message) {
            throw Error(json.message);
          }
          setDiscards(json.catalogo_desechos);
        })
        .then(() => {
          return fetch(`${APIS.API_REST_RECORDS_LIST}`, {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
          .then(response => response.json())
          .then(json => {
            if(!!json.message) {
              throw Error(json.message)
            }
            setAntecedents(json.catalogo_antecedentes)
          })
          .then(() => {
            return fetch(`${APIS.API_REST_NOVELTIES_LIST}`, {
              headers: {
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              }
            })
            .then(response => response.json())
            .then(json => {
              if(!!json.message) {
                throw Error(json.message)
              }
              setNoveltys(json.catalogo_novedades)
            })
          })
        })
        )
    }, [])

    const getData = (wastel, anteceden, novelties) => {
      if (wastel && !anteceden && !novelties) {
        setIsView("1")
      }
      else if(!wastel && anteceden && !novelties) {
        setIsView("2")
      }
      else if(!wastel && !anteceden && novelties) {
        setIsView("3")
      }
    }

    return (
  <div className="container-fluid mt-100">
    <div className="row">
        <div className="col-md-12">
            <h3>{t("formats.formats-list")}</h3>
        </div>
    </div>
    <br />
    <div className="row">
        <div className="col-md-12">
            <Link className="btn btn-primary"
                to={"/LLANTAS NUEVAS.xlsx"}
                target="_blank">
                <i className="fa fa-download"></i> {t("formats.formats-new-tire")}
            </Link>
            <br />
            <br />
            <Link className="btn btn-primary"
                to={"/BANDAS DE REENCAUCHE.xlsx"}
                target="_blank">
                <i className="fa fa-download"></i> {t("formats.formats-retreading-brand")}
            </Link>
            <br />
            <br />
            <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#wasteModal"
            onClick={() => getData(true, false, false)}
            >
            <i className="fa fa-trash"></i> {t("formats.formats-dictionary-scrap")}
            </button>
            <br />
            <br />
            <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#wasteModal"
            onClick={() => getData(false, true, false)}
            >
            <i className="fa fa-address-book"></i> {t("formats.formats-dictionary-background")}
            </button>
            <br />
            <br />
            <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#wasteModal"
            onClick={() => getData(false, false, true)}
            >
            <i className="fa fa-bell"></i> {t("formats.formats-dictionary-novelties")}
            </button>
        </div>
        {isView && <GetWasteModal discards={discards} t={t} antecedents={antecedents} noveltys={noveltys} int={isView}/>}
    </div>
  </div>
    )
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Formats);
