import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as constantsBrand from "../../../constants/actions/TireBrands";
import * as constantsModel from "../../../constants/actions/TireModels";
import * as APIS from "../../../constants/apis";

import TireDimensionList from "./TireDimensions/TireDimensionsList";

function getModel(brandId, modelId) {
  let model = {};
  let dimensions = [];
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_TIREMODELS.replace("tirebrand_id", brandId)}/${modelId}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        model = json;
        return fetch(APIS.API_REST_TIREDIMENSIONS.replace("tiremodel_id", model.id), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        dimensions = json.Dimentions;
        dispatch(onModelSuccess({
          model: model,
          dimensions: dimensions
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  }
}

export function onModelSuccess(json) {
  return {
    type: constantsModel.GET_MODEL_SUCCESS,
    json
  }
}

export function onReqSuccess(models, model, message) {
  const json = {
    models: models,
    model: model,
    message: message
  };
  return {
    type: constantsBrand.REQUEST_SUCCESS,
    json
  };
};

class TireDimensionEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      tirebrand_id: this.props.match.params.brandid,
      customer_id: localStorage.getItem('GlobalCustomerId')
    };
  };

  componentDidMount() {
    const modelId = this.props.match.params.id;
    const brandId = this.props.match.params.brandid;
    this.props.getModel(brandId, modelId);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.model || (!!this.props.model &&
      this.props.model !== prevProps.model)) {
      this.setState({
        name: this.props.model.name,
        tirebrand_id: this.props.match.params.brandid
      });
    };
  };

  onSubmit = event => {
    event.preventDefault();
    const { onReqSuccess, models, model } = this.props;

    const modelId = this.props.match.params.id;
    const data = {
      ...this.state,
    };

    const brandId = this.props.match.params.brandid;
    return trackPromise(fetch(`${APIS.API_REST_TIREMODELS.replace("tirebrand_id", brandId)}/${modelId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "PUT",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          window.location.reload(false);
          onReqSuccess(models, model, this.props.t("alerts.b-tire-item-11"));
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { model, dimensions } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!model ?
            <div className="container-fluid mt-100">
              <h3>{authUser.roles != "ANALYST" ? this.props.t("tire-brands.tire-model-edit") : this.props.t("tire-brands.tire-model-see")}</h3>
              <br /><br />
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="nameInput">{this.props.t("tire-brands.tire-item-1")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-brands.tire-item-1")}
                        id="nameInput"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        required />
                      <input type="hidden"
                        name="customer_id"
                        value={this.state.customer_id} />
                    </div>
                  </div>
                </div>
                {
                  authUser.roles != "ANALYST" ?
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <button type="submit"
                          className="btn btn-primary">
                          <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                      </button>
                      </div>
                    </div>
                  :
                  <div className="row">
                      <div className="col-md-12 text-center">
                        <button type="button"
                          onClick={() => window.history.back()}
                          className="btn btn-primary">
                          <i className="fa fa-arrow-left"></i> {this.props.t("globals.back")}
                      </button>
                      </div>
                    </div>
                }
              </form>
              <br />
              <hr />
              <TireDimensionList
                authUser={authUser}
                t={this.props.t}
                model={model}
                dimensions={dimensions}
              />
            </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  models: state => state.models,
  model: state => state.model,
  dimensions: state => state.dimensions,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getModel, onReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(TireDimensionEditItem));
