import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import * as constantsGroups from "../../../constants/actions/Groups";

const initialState = {
  groups    : [],
  group     : {},
  message   : null,
  error     : null
};

function rootReducer(state, action) {
  switch(action.type) {
    case constantsGroups.GET_GROUPS_SUCCESS:
      return {
        groups    : action.json.groups,
        group     : {},
        error     : null
      };
    case constantsGroups.GET_GROUP_SUCCESS:
      return {
        group    : action.json.group
      };
    case constantsGroups.GET_REQUEST_SUCCESS:
      return {
        groups    : action.json.groups,
        group     : action.json.group,
        message   : action.json.message,
      };
    case constantsGroups.GET_REQUEST_ERROR:
      return {
        groups    : action.json.groups,
        group     : action.json.group,
        error     : action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
