import Q from "q";
import xlsx from "xlsx";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../../Auth/Session";

import ErrorsModal from "./ErrorsModal";
import DragDropFile from "./DragDropFile";
import DataInput from "./DataInput";
import OutTable from "./OutTable";

import * as APIS from "../../../../constants/apis";
import * as VALIDATIONS from "../../../../constants/validations";
import moment from "moment";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;
const randomString = Math.random().toString(36);

const INITIAL_STATE = {
  // Multiple
  inputKey: randomString,
  done: [],
  notDone: [],
  data: [],
  cols: [],
  errors: [],
  // Customers
  customer_id: "",
  customers: [],
  brands: [],
  lines: [],
  types: [],
  schemas: [],
  groups: [],
  groups_one: [],
  groups_two: [],
  centros: [],
  // Ciudades
  ciudad_id: "",
  ciudades: []
};

class MultipleVehicles extends Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();

    this.serverErrors = [];
    this.handleFile = this.handleFile.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    let groups, centros, ciudades, types, schemas, brands, groups1, groups2 = [];
    trackPromise(fetch(`${APIS.API_REST_GROUPS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        groups = json.vehiclegroups;
      })
      .then(() => {
        return fetch(`${APIS.API_REST_CITIES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        ciudades = json.cities;
      })
      .then(() => {
        return fetch(`${APIS.API_REST_COSTCENTERS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw Error(json.message);
        }
        centros = json.costcenters;
      })
      .then(() => {
        return fetch(`${APIS.API_REST_TYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        types = json.vehicletypes;
      })
      .then(() => {
        return fetch(`${APIS.API_REST_SCHEMAS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        schemas = json.vehicleschemas;
      })
      .then(() => {
        return fetch(`${APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        brands = json.vehiclebrands;
      })
      .then(() => {
        return fetch(`${APIS.API_REST_GROUPS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}` + "?type_group=group1", {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message)
        }
        groups1 = json.vehiclegroups
      })
      .then(() => {
        return fetch(`${APIS.API_REST_GROUPS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}` + "?type_group=group2", {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message)
        }
        groups2 = json.vehiclegroups
        this.setState({
          groups: groups,
          groups_one: groups1,
          groups_two: groups2,
          types: types,
          schemas: schemas,
          brands: brands,
          centros: centros,
          ciudades: ciudades,
          customer_id: localStorage.getItem("GlobalCustomerId") + "|" + localStorage.getItem("GlobalCustomerName")
        });
      })
      .catch(error => {
        toast.error(error);
      }));
  };

  makeCols = refstr => {
    let o = [],
      C = xlsx.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = {
        name: xlsx.utils.encode_col(i),
        key: i
      }
    }
    return o;
  };

  getRepeatedRows = (rowsObjects) => {
    if (rowsObjects.length > 1) {
      let result = Object.values(rowsObjects.reduce((c, v) => {
        let k = v.plate;
        c[k] = c[k] || [];
        c[k].push(v);
        return c;
      }, {})).reduce((c, v) => v.length > 1 ? c.concat(v) : c, []);
      return result;
    } else {
      return [];
    }
  };

  getDataErrors = (cols, data) => {
    let errors = [];
    let nonRepeatedFields = [];

    // Número de columnas
    if (cols.length !== 20) {
      errors.push({
        message: this.props.t("multiple.global-item-1")
      });
    }

    // Número de datos
    if (data.length < 2) {
      errors.push({
        message: this.props.t("multiple.global-item-2")
      });
    }

    // Columnas necesarias
    let fields = [
      this.props.t("multiple.vehicle-item-1"),
      this.props.t("multiple.vehicle-item-2"),
      this.props.t("multiple.vehicle-item-3"),
      "Sap",
      "Vin",
      this.props.t("multiple.vehicle-item-4"),
      this.props.t("multiple.vehicle-item-5"),
      this.props.t("multiple.vehicle-item-6"),
      this.props.t("multiple.vehicle-item-7"),
      this.props.t("multiple.vehicle-item-36"),
      this.props.t("multiple.vehicle-item-8"),
      this.props.t("multiple.vehicle-item-37"),
      this.props.t("multiple.vehicle-item-9"),
      this.props.t("multiple.vehicle-item-10"),
      this.props.t("multiple.vehicle-item-11"),
      this.props.t("multiple.vehicle-item-12"),
      this.props.t("multiple.vehicle-item-32"),
      this.props.t("multiple.vehicle-item-33"),
      this.props.t("multiple.vehicle-item-13"),
      this.props.t("multiple.vehicle-item-14")
    ];
    for (let i = 0; i < fields.length; i++) {
      if (data[0].indexOf(fields[i]) === -1) {
        errors.push({
          message: this.props.t("multiple.global-item-3") + fields[i] + this.props.t("multiple.global-item-4") + this.props.t("multiple.global-item-5")
        });
      }

    }

    // Validaciones de los datos
    for (let i = 1; i < data.length; i++) {
      let rowObject = {
        plate: "",
        idx: (i + 1)
      };
      let brandIndex = null;
      for (let j = 0; j < fields.length; j++) {
        if (data[0][j] === this.props.t("multiple.vehicle-item-36") || data[0][j] === this.props.t("multiple.vehicle-item-37")) {
          if (!!data[i][j] && isNaN(data[i][j])) {
            errors.push({
              message: this.props.t("multiple.ins-item-23") + (i + 1) + this.props.t("multiple.ins-item-24") +
                this.props.t("multiple.ins-item-25") + data[0][j]
            });
          }
        }

        if (data[0][j] === this.props.t("multiple.vehicle-item-6")) {
          const reg = new RegExp("^\\d{4}$");
          if (!reg.test(data[i][j])) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-16")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-9")) {
          const validFuels = ["acpm/diesel", "gasolina", "electrico", "gas natural licuado", "gas natural vehicular", "gas licuado del petroleo", "hibrido", "no_aplica", "no_definido", "gasolina magna"];
          if (!!data[i][j]) {
            if (validFuels.indexOf(data[i][j].toLowerCase().trim()) === -1) {
              errors.push({
                message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-17") + "acpm/diesel, gasolina, electrico, gas natural licuado, Gasolina Magna, gas natural vehicular, gas licuado del petroleo, hibrido, no_aplica, " +
                  "no_definido."
              });
            }
          }
        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-4")) {
          brandIndex = this.state.brands.findIndex(br => {
            return br.name === data[i][j];
          });
          if (brandIndex < 0) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-18") +
                this.state.brands.map(br => br.name).join("\n")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-5") && brandIndex !== -1) {
          const linemodels = this.state.brands[brandIndex].vehiclemodels;
          if (linemodels.findIndex(ln => ln.name === data[i][j]) < 0) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-19") +
                linemodels.map(ln => ln.name).join("\n")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-8")) {
          if (this.state.schemas.findIndex(sq => sq.name === data[i][j]) < 0) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-20") +
                this.state.schemas.map(sq => sq.name).join("\n")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-7")) {
          if (this.state.types.findIndex(tp => tp.name === data[i][j]) < 0) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-21") +
                this.state.types.map(tp => tp.name).join("\n")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-10")) {
          if (this.state.centros.findIndex(cn => cn.name === data[i][j]) < 0) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-22") +
                this.state.customer_id.split("|")[1] + this.props.t("multiple.vehicle-item-23") +
                this.state.centros.map(cn => cn.name).join("\n")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-11")) {
          if (this.state.ciudades.findIndex(cd => cd.name === data[i][j]) < 0) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-24") +
                this.state.customer_id.split("|")[1] + this.props.t("multiple.vehicle-item-23") +
                this.state.ciudades.map(cd => cd.name).join("\n")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-12")) {
          if (this.state.groups.findIndex(gr => gr.name === data[i][j]) < 0) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-25") +
                this.state.customer_id.split("|")[1] + " " + this.props.t("multiple.vehicle-item-13") + " " +
                this.state.groups.map(gr => gr.name).join("\n")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-32")) {
          if (data[i][j] !== undefined) {
            if (this.state.groups_one.findIndex(gr => gr.name === data[i][j]) < 0) {
              errors.push({
                message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-34") +
                  this.state.customer_id.split("|")[1] + " " + this.props.t("multiple.vehicle-item-13") + " " +
                  this.state.groups_one.map(gr => gr.name).join("\n")
              });
            }
          }

        }
        if (data[0][j] === this.props.t("multiple.vehicle-item-33")) {
          if (data[i][j] !== undefined) {
            if (this.state.groups_two.findIndex(gr => gr.name === data[i][j]) < 0) {
              errors.push({
                message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-35") +
                  this.state.customer_id.split("|")[1] + " " + this.props.t("multiple.vehicle-item-13") + " " +
                  this.state.groups_two.map(gr => gr.name).join("\n")
              });
            }
          }
        }

        if (data[0][j] === this.props.t("multiple.vehicle-item-3")) {
          rowObject.plate = data[i][j];
        }

        if (data[0][j] === this.props.t("multiple.vehicle-item-13")) {
          rowObject.spares = data[i][j];
        }

        if (data[0][j] === this.props.t("multiple.vehicle-item-1")) {
          if (moment(data[i][j], "YYYY-MM-DD").format("YYYY-MM-DD") !== data[i][j]) {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-26")
            });
          }
        }

        if (data[0][j] === this.props.t("multiple.vehicle-item-14")) {
          if (data[i][j] !== undefined) {
            if (data[i][j].toUpperCase() === "HORA") {
              rowObject.worktype = "hour";
            } else if (data[i][j].toUpperCase() === "KM") {
              rowObject.worktype = "km";
            } else if (data[i][j].toUpperCase() === "MILLAS") {
              rowObject.worktype = "miles";
            } else {
              errors.push({
                message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-27") + data[i][j].toUpperCase()
              });
            }
          } else {
            errors.push({
              message: this.props.t("multiple.vehicle-item-15") + (i + 1) + this.props.t("multiple.vehicle-item-28")
            });
          }
        }
      }
      nonRepeatedFields.push(rowObject);
    }

    if (errors.length === 0) {
      const repeated = this.getRepeatedRows(nonRepeatedFields);
      if (repeated.length > 0) {
        let indexes = "";
        for (let i = 0; i < repeated.length; i++) {
          indexes += ((i === 0) ? "" :
            ((i === repeated.length - 1) ? " y " : ", ")) +
            repeated[i].idx;
        }
        errors.push({
          message: this.props.t("multiple.vehicle-item-29") + indexes +
            this.props.t("multiple.vehicle-item-30")
        });
      }
    }

    return errors;
  };

  handleFile = file => {
    this.setState({ data: [], cols: [], done: [], notDone: [], errors: [] });

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = xlsx.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1, raw: false });
      const cols = this.makeCols(ws["!ref"]);
      const errors = this.getDataErrors(cols, data);
      if (errors.length === 0) {
        this.setState({
          data: data,
          cols: this.makeCols(ws["!ref"]),
          inputKey: Math.random().toString(36)
        });
      } else {
        this.modal.current.showModal(errors);
        this.setState({
          errors: [],
          data: [],
          cols: [],
          inputKey: Math.random().toString(36)
        });
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  exportTemplate = event => {
    event.preventDefault();
    let header = [
      this.props.t("multiple.vehicle-item-1"),
      this.props.t("multiple.vehicle-item-2"),
      this.props.t("multiple.vehicle-item-3"),
      "Sap",
      "Vin",
      this.props.t("multiple.vehicle-item-4"),
      this.props.t("multiple.vehicle-item-5"),
      this.props.t("multiple.vehicle-item-6"),
      this.props.t("multiple.vehicle-item-7"),
      this.props.t("multiple.vehicle-item-36"),
      this.props.t("multiple.vehicle-item-8"),
      this.props.t("multiple.vehicle-item-37"),
      this.props.t("multiple.vehicle-item-9"),
      this.props.t("multiple.vehicle-item-10"),
      this.props.t("multiple.vehicle-item-11"),
      this.props.t("multiple.vehicle-item-12"),
      this.props.t("multiple.vehicle-item-32"),
      this.props.t("multiple.vehicle-item-33"),
      this.props.t("multiple.vehicle-item-13"),
      this.props.t("multiple.vehicle-item-14")
    ];
    const ws = xlsx.utils.aoa_to_sheet([header]);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Plantilla");
    xlsx.writeFile(wb, `${this.props.t("multiple.vehicle-item-31")}.xlsx`)
  };

  getBrand = (name) => {
    const brands = this.state.brands;
    for (let i = 0; i < brands.length; i++) {
      if (brands[i].name === name) {
        return brands[i];
      }
    }
    return null;
  };

  getLineModelId = (brandName, name) => {
    const brand = this.getBrand(brandName);
    const lines = brand.vehiclemodels;
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].name === name) {
        return lines[i].id;
      }
    }
    return null;
  };

  getVehicleTypeId = (name) => {
    const types = this.state.types;
    for (let i = 0; i < types.length; i++) {
      if (types[i].name === name) {
        return types[i].id;
      }
    }
    return null;
  };

  getVehicleSchemaId = (name) => {
    const schemas = this.state.schemas;
    for (let i = 0; i < schemas.length; i++) {
      if (schemas[i].name === name) {
        return schemas[i].id;
      }
    }
    return null;
  };

  getCostCenterId = (name) => {
    const centros = this.state.centros;
    for (let i = 0; i < centros.length; i++) {
      if (centros[i].name === name) {
        return centros[i].id;
      }
    }
    return null;
  };

  getCityId = (name) => {
    const ciudades = this.state.ciudades;
    for (let i = 0; i < ciudades.length; i++) {
      if (ciudades[i].name === name) {
        return ciudades[i].id;
      }
    }
    return null;
  };

  getGroupId = (name, group_option) => {
    const groups = group_option;
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].name === name) {
        return groups[i].id;
      }
    }
    return null;
  };

  formatData = (register) => {
    const { customer_id } = this.state;
    let data = {
      customer_id: customer_id.split("|")[0],
      vehicletype_id: "",
      schema_id: "",
      group_id: "",
      linemodel_id: "",
      brand_id: "",
      centrocosto_id: "",
      ciudad_id: "",
      plate: "",
      vehiclenumber: "",
      fuel: "",
      year: "",
      spares: 0,
      worktype: "",
      created_at: "",
      vin: "",
      sap: "",
      number_of_curtains: null,
      useful_load: null,
    };

    let brand = null;
    let header = this.state.data[0];
    for (let i = 0; i < header.length; i++) {
      if (header[i] === this.props.t("multiple.vehicle-item-1")) {
        data.created_at = moment.utc(register[i]).set({ hour: 6, minute: 0, second: 0, millisecond: 0 }).format();
      } else if (header[i] === this.props.t("multiple.vehicle-item-3")) {
        data.plate = register[i];
      } else if (header[i] === this.props.t("multiple.vehicle-item-2")) {
        data.vehiclenumber = register[i]
      } else if (header[i] === this.props.t("multiple.vehicle-item-6")) {
        data.year = register[i];
      } else if (header[i] === this.props.t("multiple.vehicle-item-9")) {
        data.fuel = register[i];
      } else if (header[i] === this.props.t("multiple.vehicle-item-4")) {
        brand = register[i];
        data.brand_id = this.getBrand(register[i]).id;
      } else if (header[i] === this.props.t("multiple.vehicle-item-5")) {
        data.linemodel_id = this.getLineModelId(brand, register[i]);
      } else if (header[i] === "Vin") {
        data.vin = register[i]
      } else if (header[i] === "Sap") {
        data.sap = register[i]
      } else if (header[i] === this.props.t("multiple.vehicle-item-36")) {
        data.number_of_curtains = register[i]
      } else if (header[i] === this.props.t("multiple.vehicle-item-37")) {
        data.useful_load = register[i]
      } else if (header[i] === this.props.t("multiple.vehicle-item-7")) {
        data.vehicletype_id = this.getVehicleTypeId(register[i]);
      } else if (header[i] === this.props.t("multiple.vehicle-item-8")) {
        data.schema_id = this.getVehicleSchemaId(register[i]);
      } else if (header[i] === this.props.t("multiple.vehicle-item-10")) {
        data.centrocosto_id = this.getCostCenterId(register[i]);
      } else if (header[i] === this.props.t("multiple.vehicle-item-11")) {
        data.ciudad_id = this.getCityId(register[i]);
      } else if (header[i] === this.props.t("multiple.vehicle-item-12")) {
        data.group_id = this.getGroupId(register[i], this.state.groups);
      } else if (header[i] === this.props.t("multiple.vehicle-item-32")) {
        data.group1_id = this.getGroupId(register[i], this.state.groups_one);
      } else if (header[i] === this.props.t("multiple.vehicle-item-33")) {
        data.group2_id = this.getGroupId(register[i], this.state.groups_two);
      } else if (header[i] === this.props.t("multiple.vehicle-item-13")) {
        data.spares = register[i];
      } else if (header[i] === this.props.t("multiple.vehicle-item-14")) {
        data.worktype = register[i];
        register[i].toUpperCase() == "HORA" ? data.worktype = "hour" : register[i].toUpperCase() == "KM" ? data.worktype = "km" : data.worktype = "miles"
      }
    }

    return data;
  };

  saveRegister = (idx, register, authUser) => {
    let deferred = Q.defer();

    if (isInternetConnected(toast)) {
      deferred.reject();
      return deferred.promise;
    }
    let that = this;
    authUser.doGenerateToken().then(function (idToken) {
      localStorage.setItem('firebaseAuthToken', idToken);
    })
    const data = that.formatData(register);
    trackPromise(fetch(`${APIS.API_REST_VEHICLES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        const done = [...that.state.done];
        done.push(idx);
        that.setState({ done: done });
        deferred.resolve(json);
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("multiple.global-item-6") +
            (idx + 1);
        } else {
          err = this.props.t("multiple.global-item-7") + (idx + 1) + ". " + error.toString();
        }
        that.serverErrors.push({ message: err });
        const notDone = [...that.state.notDone];
        notDone.push(idx);
        that.setState({ notDone: notDone });
        deferred.reject(that.serverErrors);
      }));

    return deferred.promise;
  };

  getCleanedRegisters = registers => {
    let newRegisters = [];

    for (let i = 1; i < registers.length; i++) {
      if (registers[i].length > 0) {
        newRegisters.push(registers[i]);
      }
    }

    return newRegisters;
  };

  async saveRegisters(registers, authUser) {
    let deferred = Q.defer();

    let newRegisters = this.getCleanedRegisters(registers);
    for (let i = 0; i < newRegisters.length; i++) {
      if (this.state.done.indexOf(i) === -1) {
        await this.saveRegister(i, newRegisters[i], authUser)
          .then(() => {
            return true;
          })
          .catch(error => {
            deferred.reject(error);
          });
      }
    }

    deferred.resolve();
    return deferred.promise;
  };

  onSubmit = (event, authUser) => {
    event.preventDefault();
    this.serverErrors = [];
    this.setState({ notDone: [] });

    trackPromise(this.saveRegisters(this.state.data, authUser)
      .then(() => {
        toast.success(this.props.t("multiple.global-item-8"));
        this.setState({
          data: [],
          cols: [],
          done: [],
          notDone: [],
          errors: []
        });
      })
      .catch(errors => {
        this.modal.current.showModal(errors);
      }));
  };

  getModels = (id) => {
    const brands = this.state.brands;
    for (let i = 0; i < brands.length; i++) {
      if (brands[i].id === 1 * id) {
        return brands[i].vehiclemodels;
      }
    }
    return [];
  };

  onChange = event => {
    event.persist();
    switch (event.target.name) {
      case "customer_id":
        if (!!event.target.value) {
          let centros, ciudades, groups = [];
          trackPromise(fetch(`${APIS.API_REST_GROUPS
            .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
            .then(response => response.json())
            .then(json => {
              if (!!json.message) {
                throw new Error(json.message);
              }
              groups = json.vehiclegroups;
            })
            .then(() => {
              return fetch(`${APIS.API_REST_CITIES
                .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
                headers: {
                  "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                  "Accept-language": localStorage.getItem("LanguageSelected"),
                }
              })
            })
            .then(response => response.json())
            .then(json => {
              if (!!json.message) {
                throw new Error(json.message);
              }
              ciudades = json.cities;
            })
            .then(() => {
              return fetch(`${APIS.API_REST_COSTCENTERS
                .replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
                headers: {
                  "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                  "Accept-language": localStorage.getItem("LanguageSelected"),
                }
              })
            })
            .then(response => response.json())
            .then(json => {
              if (!!json.message) {
                throw new Error(json.message);
              }
              centros = json.costcenters;
              this.setState({
                centros: centros,
                ciudades: ciudades,
                groups: groups
              });
            })
            .catch(error => {
              toast.error(error);
            }));
        } else {
          this.setState({ groups: [] })
        }
        break;
      default:
      // Do nothing
    }
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { data, cols, done, notDone } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <ErrorsModal ref={this.modal} t={this.props.t} />
            <h3>{this.props.t("client-vehicles.client-massive")}</h3>
            <br />
            <DragDropFile handleFile={this.handleFile}>
              <form>
                <div>
                  <DataInput handleFile={this.handleFile}
                    t={this.props.t}
                    exportTemplate={this.exportTemplate}
                    inputKey={this.state.inputKey || ""} />
                  <OutTable data={data}
                    cols={cols}
                    done={done}
                    notDone={notDone} />
                  {data.length > 1 ?
                    <div className="row">
                      <div className="col-md-12 mt-5">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{ width: "100%" }}
                          onClick={(event) => this.onSubmit(event, this.props.firebase)}>
                          <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                        </button>
                      </div>
                    </div> : null}
                </div>
              </form>
            </DragDropFile>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(MultipleVehicles);
