import React from "react";
import { Link } from "react-router-dom";
import TireModelDeleteItem from "./TireModelDeleteItem";

class LinesList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      search: ""
    };
  };

  getModelsList = (brand, models) => {
    const { search } = this.state;
    if (!models) return null;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("tire-brands.tire-item-1")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-2")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-3")}</th>
            </tr>
          </thead>
          <tbody>
            {
              models.filter(model => {
                return model.name.toLowerCase().search(search.toLowerCase()) >= 0
              }).map((model, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {model.name}
                    </td>
                    <td>
                      {
                        !!model.active ? "Activo" : "Inactivo"
                      }
                    </td>
                    <td>
                      <Link to={"/tirebrands/" + brand.id + "/tiremodels/" + model.id + "/tires"}
                        className="btn btn-primary">
                        <i className="fa fa-bullseye"></i> {this.props.t("tire-brands.tire-text")}
                      </Link>&nbsp;
                      {
                        this.props.authUser.roles != "ANALYST" ?
                          <>
                          <Link to={"/tiremodels/" + brand.id +
                            "/edit/" + model.id}
                            className="btn btn-primary">
                            <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                          </Link>&nbsp;
                          <TireModelDeleteItem modelDelete={model} t={this.props.t}/>
                          </>
                        :
                        <>
                        <Link to={"/tiremodels/" + brand.id +
                            "/edit/" + model.id}
                            className="btn btn-primary">
                            <i className="fa fa-eye"></i> {this.props.t("globals.see")}
                        </Link>&nbsp;
                        </>
                      }
                    </td>
                  </tr>)
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  onChange = (event) => {
    this.setState({
      search: event.target.value
    });
  };

  render() {
    const { brand, models } = this.props;
    const { search } = this.state;

    return (
      <div className="row">
        <div className="col-md-8">
          <h3> {this.props.t("tire-brands.tire-model-text")} </h3>
        </div>
        <div className="col-md-4">
          <input
            className="form-control"
            id="search"
            placeholder={this.props.t("globals.search-input")}
            value={search}
            onChange={this.onChange}
          />
        </div>
        <div className="col-md-12">
          <br /><br />
          {this.getModelsList(brand, models)}
        </div>
      </div>
    );
  };
};

export default LinesList;
