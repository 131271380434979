import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../../Auth/Session";
import moment from "moment";
import { Link } from "react-router-dom";
import * as constantsTire from "../../../../constants/actions/Vehicles";
import API from "../../../../constants/api";
import * as ROLES from "../../../../constants/roles";
import TireDeleteItem from "./TireDeleteItem";
import {
  clearTiresAction,
  clearTiresErrorAction,
  getTiresAction,
} from "../../../../redux/actions/TiresAction";
import Loading from "../../../Loader";
import Pagination from "../../../pagination";
import { paginationEstructure } from "../../../../constants/paginationCode";
import DropZone from "../../../Multiple/DropZone";

let email = [
  "w.herrera@ruedata.com",
  "a.roberto@ruedata.com",
  "a.romero@ruedata.com",
  "b.salgado@ruedata.com",
  "w.aguirre@ruedata.com",
];

const getTires = (customerId, page, limit, search, searchType, searchEqual) => {
  return (dispatch) => {
    dispatch(
      getTiresAction(customerId, page, limit, search, searchType, searchEqual)
    );
  };
};

const clearTiresError = () => {
  return (dispatch) => {
    dispatch(clearTiresErrorAction());
  };
};

const clearTiresSearch = () => {
  return (dispatch) => {
    dispatch(clearTiresAction());
  };
};

class TiresList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // utils
      loading: true,

      // data
      customer_id: localStorage.getItem("GlobalCustomerId"),
      tires: this.props.tires,
      error: null,
      search: "",
      isSearchingPagination: false,
      searchEqual: false,
      searchType: "codigo",
      temp: "Cargando Temporal...",

      // pagination
      total: this.props.total,
      next: this.props.next,
      previus: this.props.previus,
      page: 1,
      limit: 20,
    };
  }

  componentDidMount() {
    const {
      customer_id,
      tires,
      total,
      page,
      limit,
      search,
      searchType,
      searchEqual,
    } = this.state;

    if (tires.length > 0) {
      this.setState({ loading: false });
    } else {
      if (tires.length === 0) {
        this.props.getTires(
          customer_id,
          page,
          limit,
          search,
          searchType,
          searchEqual
        );
      }
    }

    if (total === 0) {
      this.setTiresNotFount(null, total);
    }
    this.getLastTemp();
  }

  componentDidUpdate(preProps, preState) {
    if (
      preProps.tires.length !== this.props.tires.length &&
      this.state.tires.length === 0
    ) {
      this.setState({
        tires: this.props.tires,
        next: this.props.next,
        total: this.props.total,
        previus: this.props.previus,
        error: null,
        haveTires: true,
        loading: false,
      });
    }

    if (
      preProps.total === null &&
      this.props.total === 0 &&
      this.state.total === null
    ) {
      this.setTiresNotFount(preProps.total, this.props.total);
    }

    if (this.props.error && this.state.error === null) {
      this.setState({ error: this.props.error, loading: false });
      this.errorToast(this.props.error);
    }
  }

  setTiresNotFount = (preTotal, propsTotal) => {
    if (!preTotal && propsTotal === 0) {
      this.setState({ loading: false });
    }
  };

  errorToast = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
      this.setState({ error: null });
      this.props.clearTiresError();
    }
  };

  onChangeSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  paginationSubmit = (page, limit, authUser) => {
    const { customer_id, search, searchType, searchEqual } = this.state;
    this.setState({ page: page, limit: limit, loading: true, tires: [] });
    this.props.clearTiresSearch();
    this.props.getTires(
      customer_id,
      page,
      limit,
      search,
      searchType,
      searchEqual
    );
  };

  searchSumbit = () => {
    const { customer_id, search, limit, searchType, searchEqual } = this.state;
    this.setState({ loading: true, page: 1, search: "" });
    this.getSearchTires(customer_id, 1, limit, search, searchType, searchEqual);
  };

  clearSearch = () => {
    this.setState({ loading: true, page: 1, search: "", tires: this.props.tires });
    this.setState({ loading: false });
  };

  getSearchTires = (customerId, page, limit, search, searchType, searchEqual) => {
    var str = "";
    if (page !== "0") {
      str = "?page=" + page + "&limit=" + limit;
      if (search) {
        str = str + "&" + searchType + "=" + search
      }
      if (searchEqual) {
        str = str + "&igual=true"
      }
    }
    API
      .getAllTires(customerId, str)
      .then(res => {
        console.log(res)
        if (res.tires.length > 0) {
          this.setState({ loading: false, tires: res.tires })
        } else {
          this.setState({ loading: false, tires: this.props.tires })
          toast.info(this.props.t("alerts.item"))
        }
      })
      .catch(error => {
        if (error.response.status !== 200) {
          toast.error(error.response.data.message.toString())
          this.setState({ loading: false })
        }
      })
  }

  generateExcel = () => {
    return trackPromise(
      API.getTiresExcel(this.state.customer_id)
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "llantas.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          toast.success("Excel generado exitosamente");
        })
        .catch((error) => {
          this.errorToast(error.toString());
        })
    );
  };
  getLastTemp = () => {
    API.getLastTemp(this.state.customer_id).then((res) =>
      this.setState({ temp: res.tmp })
    );
  };

  getTireList = (authUser) => {
    const {
      tires,
      next,
      previus,
      total,
      limit,
      page,
      haveTires,
      search,
      searchType,
      searchEqual,
    } = this.state;
    const pagination = paginationEstructure(next, previus, total, limit, page);

    return (
      <div className="table-responsive mt-5">
        {tires.length > 0 ? (
          <>
            <table className="table table-striped table-bordered table-sm">
              <thead>
                <tr>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-1")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-2")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-3")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-4")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-5")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-6")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-7")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-8")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-9")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-10")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-11")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-12")}
                  </th>
                  <th scope="col">
                    {this.props.t("client-tires.client-item-13")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tires.map((tire, idx) => (
                  <tr key={idx}>
                    <td>{tire.posicion_montaje}</td>
                    <td>{!!tire.vehiculo ? tire.vehiculo : ""}</td>
                    <td>{!!tire.vehicle_plate ? tire.vehicle_plate : ""}</td>
                    <td>
                      {!!tire.ubicacion && !tire.vehiculo ? tire.ubicacion : ""}
                    </td>
                    <td>{tire.bodega}</td>
                    <td>{tire.codigo}</td>
                    <td>{tire.marca}</td>
                    <td>{tire.modelo}</td>
                    <td>{tire.dimension}</td>
                    <td>
                      {!!tire.retreadbrand
                        ? tire.retreadbrand +
                        " " +
                        tire.retreadband +
                        " R" +
                        tire.vida
                        : null}
                    </td>
                    <td>{tire.precio}</td>
                    <td>
                      {moment
                        .utc(tire.fc_ultima_inspeccion)
                        .local()
                        .locale("es")
                        .format("LLL")}
                    </td>
                    <td>
                      <Link
                        to={"/tires/history/" + tire.id}
                        className="btn btn-primary"
                      >
                        <i className="fa fa-chart-line"></i>{" "}
                        {this.props.t("globals.history")}
                      </Link>
                      &nbsp;
                      {!!authUser.permissions.edit ? (
                        <Link
                          to={"/tires/edit/" + tire.id}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-edit"></i>{" "}
                          {this.props.t("globals.edit")}
                        </Link>
                      ) : null}
                      &nbsp;
                      {!!authUser.permissions.delete ? (
                        <TireDeleteItem t={this.props.t} tireDelete={tire} />
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              {tires.length > 0 ? (
                <Pagination
                  colspan="10"
                  pageP={pagination.pageP}
                  pageN={pagination.pageN}
                  page={page}
                  limit={limit}
                  links={pagination.links}
                  submit={(page, limit) =>
                    this.paginationSubmit(page, limit, authUser)
                  }
                />
              ) : null}
            </div>
          </>
        ) : (
          <div class="alert alert-info text-center w-50 mx-auto" role="alert">
            No hay llantas registradas
          </div>
        )}
      </div>
    );
  };

  render() {
    let header = [
      this.props.t("multiple.tire-item-1"),
      this.props.t("multiple.tire-item-2"),
      this.props.t("multiple.tire-item-3"),
      this.props.t("multiple.tire-item-4"),
      this.props.t("multiple.tire-item-5"),
      this.props.t("multiple.tire-item-6"),
      this.props.t("multiple.tire-item-7"),
      this.props.t("multiple.tire-item-8"),
      this.props.t("multiple.tire-item-46"),
      this.props.t("multiple.tire-item-47"),
      this.props.t("multiple.tire-item-48"),
      this.props.t("multiple.tire-item-49"),
      this.props.t("multiple.tire-item-9"),
      this.props.t("multiple.tire-item-10"),
      this.props.t("multiple.tire-item-11"),
      this.props.t("multiple.tire-item-12"),
      this.props.t("multiple.tire-item-13"),
      this.props.t("multiple.tire-item-14"),
      this.props.t("multiple.tire-item-15"),
      this.props.t("multiple.tire-item-16"),
      this.props.t("multiple.tire-item-17"),
      this.props.t("multiple.tire-item-18"),
      this.props.t("multiple.tire-item-19"),
      this.props.t("multiple.tire-item-20"),
      this.props.t("multiple.tire-item-21"),
      this.props.t("multiple.tire-item-22"),
      this.props.t("multiple.tire-item-23"),
      this.props.t("multiple.tire-item-24"),
      this.props.t("multiple.tire-item-25"),
      this.props.t("multiple.tire-item-26"),
      this.props.t("multiple.tire-item-27"),
      this.props.t("multiple.tire-item-28"),
      this.props.t("multiple.tire-item-29"),
    ];

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <div className="row">
              <h3 className="col-md-4">
                {this.props.t("client-tires.client-list")}
              </h3>
              <div className="col-md-8">
                {this.state.tires.length > 0 ? (
                  <>
                    <div className="input-group mb-3 flex-nowrap">
                      <div
                        className="btn-group btn-group-toggle mr-3"
                        data-toggle="buttons"
                      >
                        <label className="btn btn-primary active">
                          <input
                            type="radio"
                            name="isActive"
                            onClick={(event) =>
                              this.setState({ searchEqual: false })
                            }
                            value={true}
                            autoComplete="off"
                          />{" "}
                          {this.props.t("client-tires.client-item-14")}
                        </label>
                        <label className="btn btn-primary">
                          <input
                            type="radio"
                            name="isActive"
                            onClick={(event) =>
                              this.setState({ searchEqual: true })
                            }
                            value={false}
                            autoComplete="off"
                          />{" "}
                          {this.props.t("client-tires.client-item-15")}
                        </label>
                      </div>

                      <div
                        className="btn-group btn-group-toggle mr-3"
                        data-toggle="buttons"
                      >
                        <label className="btn btn-primary active">
                          <input
                            type="radio"
                            name="isActive"
                            onClick={(event) =>
                              this.setState({ searchType: "codigo" })
                            }
                            value={true}
                            autoComplete="off"
                          />{" "}
                          {this.props.t("client-tires.client-item-6")}
                        </label>
                        <label className="btn btn-primary">
                          <input
                            type="radio"
                            name="isActive"
                            onClick={(event) =>
                              this.setState({ searchType: "ubicacion" })
                            }
                            value={false}
                            autoComplete="off"
                          />{" "}
                          {this.props.t("client-tires.client-item-4")}
                        </label>
                        <label className="btn btn-primary">
                          <input
                            type="radio"
                            name="isActive"
                            onClick={(event) =>
                              this.setState({ searchType: "banda" })
                            }
                            value={false}
                            autoComplete="off"
                          />{" "}
                          {this.props.t("client-tires.client-item-10")}
                        </label>
                        <label className="btn btn-primary">
                          <input
                            type="radio"
                            name="isActive"
                            onClick={(event) =>
                              this.setState({ searchType: "diseno" })
                            }
                            value={false}
                            autoComplete="off"
                          />{" "}
                          {this.props.t("client-tires.client-item-8")}
                        </label>
                      </div>
                      <div className="input-group mb-3 flex-nowrap">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.clearSearch()}
                        >
                          <i class="fas fa-times"></i>
                        </button>
                        <input
                          aria-describedby="btnSearch"
                          className="form-control"
                          type="text"
                          id="search"
                          value={this.state.search}
                          placeholder={this.props.t("globals.search-input")}
                          onChange={this.onChangeSearch}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.searchSumbit()}
                            id="btnSearch"
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="">
                {!!authUser.permissions.create ? (
                  <Link to={"/tires/new"} className="btn btn-primary">
                    <i className="fa fa-plus"></i>{" "}
                    {this.props.t("globals.register")}
                  </Link>
                ) : null}
              </div>
              <div className="">
                {authUser.roles === ROLES.ANALYST ||
                  authUser.roles === ROLES.ADMIN ? (
                  <DropZone
                    t={this.props.t}
                    title={this.props.t("client-tires.client-massive")}
                    fields={header}
                    type="tire_massive"
                    isSelector={false}
                  />
                ) : null}
                <Link to={"/tires/multiple"} className="btn btn-primary mr-2">
                  <i className="fa fa-upload"></i>{" "}
                  {this.props.t("globals.register-massive")}
                </Link>
                {this.state.tires.length > 0 ? (
                  <button
                    className="btn btn-success"
                    onClick={(event) => this.generateExcel()}
                  >
                    <i className="fa fa-file-export"></i>{" "}
                    {this.props.t("globals.exports")}
                  </button>
                ) : null}
              </div>
            </div>
            <div className="mt-3">
              <p className="fw-bold">
                Mayor numero temporal creado:
                <b>{this.state.temp}</b>
              </p>
              <p>
                {this.props.t("client-tires.client-total")}{" "}
                <b>{!!this.state.total ? this.state.total : 0}</b>
              </p>
            </div>
            {this.state.loading ? <Loading /> : this.getTireList(authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const structuredSelector = createStructuredSelector({
  tires: (state) => state.customerTires.tires,
  total: (state) => state.customerTires.total,
  next: (state) => state.customerTires.next,
  previus: (state) => state.customerTires.previus,
  error: (state) => state.customerTires.error,
});

const mapDispatchToProps = { getTires, clearTiresError, clearTiresSearch };

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(
  connect(structuredSelector, mapDispatchToProps)(TiresList)
);
