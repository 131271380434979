import React, { Component } from "react";

class Loading extends Component {
  render() {
    return (
      <div className="d-flex align-items-center">
        <strong>Cargando datos...</strong>
        <div className="spinner-border m-5 ml-auto" role="status" aria-hidden="true"></div>
      </div>
    );
  };
};

export default Loading;