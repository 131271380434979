import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import * as constantsBrand from "../../constants/actions/TireBrands";
import * as constantsModel from "../../constants/actions/TireModels";
import * as constantsDimension from "../../constants/actions/TireDimensions";

const initialState = {
  previus: "",
  next: "",
  total: "",
  brands: [],
  brand: {},
  models: [],
  model: {},
  dimensions: [],
  message: null,
  error: null
};

function rootReducer(state, action) {
  switch (action.type) {
    case constantsBrand.GET_BRANDS_SUCCESS:
      return {
        previus: action.json.previus,
        next: action.json.next,
        total: action.json.total,
        brands: action.json.brands
      };
    case constantsBrand.GET_BRAND_SUCCESS:
      return {
        brand: action.json.brand,
        models: action.json.models
      };
    case constantsModel.GET_MODEL_SUCCESS:
      return {
        model: action.json.model,
        dimensions: action.json.dimensions
      };
    case constantsDimension.GET_DIMENSION_SUCCESS:
      return {
        dimension: action.json.dimension
      };
    case constantsBrand.REQUEST_SUCCESS:
      return {
        brands: action.json.brands,
        brand: action.json.brand,
        models: action.json.models,
        model: action.json.model,
        dimensions: action.json.dimensions,
        message: action.json.message
      };
    case constantsBrand.REQUEST_ERROR:
      return {
        previus: action.json.previus,
        next: action.json.next,
        total: action.json.total,
        brands: action.json.brands,
        brand: action.json.brand,
        models: action.json.models,
        model: action.json.model,
        dimensions: action.json.dimensions,
        error: action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
