import React from "react";
import { Modal } from "react-bootstrap";
import BillingItem from "./itemsComponents/BillingItem";
import "./notifications.css";

const Notifications = ({ t, authUser, showModal, hideModal }) => {
  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          onHide={hideModal}
          dialogClassName="modal-sm-mc-view"
        >
          <Modal.Body style={{ padding: "0px" }}>
            <BillingItem authUser={authUser} t={t} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Notifications;
