import React, { Component } from "react";
import { toast } from "react-toastify";

class NoveltyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      novedad: !!props.novelties && props.idx != null ?
        props.novelties[props.idx].novedad : "",
      seccion: !!props.novelties && props.idx != null ?
        props.novelties[props.idx].seccion : "",
      causa: !!props.novelties && props.idx != null ?
        props.novelties[props.idx].causa : "",
      novelties: [],
      causes: []
    };
  };

  setEmptyFields = () => {
    this.setState({
      novedad: "",
      causa: "",
      seccion: ""
    });
  };

  setFields = (idx, novelties) => {
    this.setState({
      seccion: novelties[idx].seccion,
      causa: novelties[idx].causa,
      novedad: novelties[idx].novedad
    });
  };

  getNovelties = (seccion, list) => {
    for (let i = 0; i < list.length; i++) {
      if (list[i].id + "|" + list[i].seccion_llanta === seccion) {
        return list[i].novedades;
      }
    }
    return [];
  };

  getCauses = (motivo, list) => {
    for (let i = 0; i < list.length; i++) {
      if (list[i].codigo_novedad + "|" + list[i].motivo + "|" + list[i].ligado_a === motivo) {
        return list[i].posibles_causas;
      }
    }
    return [];
  };

  onChange = (event, list) => {
    if (event.target.name === "causa") {
      this.setState({
        causa: event.target.value
      });
    }

    if (event.target.name === "seccion") {
      this.setState({
        novelties: this.getNovelties(event.target.value, list),
        seccion: event.target.value
      });
    }
    if (event.target.name === "novedad") {
      this.setState({
        causes: this.getCauses(event.target.value, list),
        novedad: event.target.value
      });
    }
  };

  addNovelty = () => {
    if (this.state.novedad === "" || this.state.causa === "" ||
      this.state.seccion === "") {
      if (!this.state.seccion) {
        toast.info(this.props.t("alerts.ins-one-item-53"));
      }
      if (!this.state.novedad) {
        toast.info(this.props.t("alerts.ins-one-item-54"));
      }
      if (!this.state.causa) {
        toast.info(this.props.t("alerts.ins-one-item-55"));
      }
    } else {
      if (this.props.idx != null) {
        this.props.novelties[this.props.idx].novedad = this.state.novedad;
        this.props.novelties[this.props.idx].seccion = this.state.seccion;
        this.props.novelties[this.props.idx].causa = this.state.causa;
      } else {
        this.props.novelties.push({
          novedad: this.state.novedad,
          seccion: this.state.seccion,
          causa: this.state.causa
        });
      }
      this.props.onChangeMode("list");
    }
  };

  render() {
    const { mode } = this.props;
    const { novelties, causes } = this.state;

    const list = (this.props.noveltyType === "Novedad") ?
      this.props.noveltiesList :
      (this.props.noveltyType === "Antecedente") ?
        this.props.records :
        (this.props.noveltyType === "Desecho") ?
          this.props.waste :
          this.props.noveltyType === "Movimiento" ?
            this.props.movements : [];

    return (
      <div className={mode === "form" ? "row" : "row hidden"}>
        <div className="col-sm-12">
          <div className="form-group">
            <div className="row">
              <div className="col-sm-2 pull-left">
                <label htmlFor="seccion">{this.props.t("ins-noveltie.ins-nov-15")}</label>
              </div>
              <div className="col-sm-10">
                <select className="form-control"
                  name="seccion"
                  id="seccion"
                  value={this.state.seccion}
                  onChange={(event) => this.onChange(event, list)}>
                  <option value="">{this.props.t("ins-noveltie.ins-nov-16")}</option>
                  {
                    !!list && list.map(novelty => {
                      return <option
                        key={novelty.id}
                        value={novelty.id + "|" + novelty.seccion_llanta}>
                        {novelty.codigo_seccion + " | " + this.props.t(`response-codes.${novelty.seccion_llanta_key}`)}
                      </option>
                    })
                  }
                </select>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-2 pull-left">
                <label htmlFor="novedad">{this.props.t("ins-noveltie.ins-nov-17")}</label>
              </div>
              <div className="col-sm-10">
                <select className="form-control"
                  name="novedad"
                  id="novedad"
                  value={this.state.novedad}
                  onChange={(event) => this.onChange(event,
                    this.state.novelties)}>
                  <option value="">{this.props.t("ins-noveltie.ins-nov-18")}</option>
                  {
                    !!novelties && novelties.map(novelty => {
                      return <option
                        key={novelty.codigo_novedad}
                        value={novelty.codigo_novedad + "|" + novelty.motivo + "|" + novelty.ligado_a}>
                        {novelty.codigo_novedad + " | " + this.props.t(`response-codes.${novelty.motivo_key}`)}
                      </option>
                    })
                  }
                </select>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-2 pull-left">
                <label htmlFor="causa">{this.props.t("ins-noveltie.ins-nov-19")}</label>
              </div>
              <div className="col-sm-10">
                <select className="form-control"
                  name="causa"
                  id="causa"
                  value={this.state.causa}
                  onChange={this.onChange}>
                  <option value="">{this.props.t("ins-noveltie.ins-nov-20")}</option>
                  {
                    causes.map(causa => {
                      return <option
                        key={causa.posible_causa}
                        value={causa.codigo_causa + "|" + causa.posible_causa}>
                        {causa.codigo_causa + " | " + this.props.t(`response-codes.${causa.posible_causa_key}`)}
                      </option>
                    })
                  }
                </select>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-6 pull-left">
                <button type="button"
                  onClick={() => this.props.onChangeMode("list")}
                  className="btn btn-primary">
                  <i className="fa fa-arrow-left"></i> {this.props.t("ins-noveltie.ins-nov-21")}
                </button>
              </div>
              <div className="col-sm-6 pull-right">
                <button type="button"
                  onClick={this.addNovelty}
                  className="btn btn-primary">
                  <i className="fa fa-save"></i> {this.props.idx != null ? this.props.t("ins-noveltie.ins-nov-22") : this.props.t("ins-noveltie.ins-nov-23")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default NoveltyForm;