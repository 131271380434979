import React from "react";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import Metabase from "../Reports/Metabase";
import { validateMetaLanguage } from "../../utils/validateMetaLanguage";

class Vehicles extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <Metabase dashboardId={validateMetaLanguage(811, 1124)} />
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Vehicles);
