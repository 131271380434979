import React, { Component } from "react";

class Loader extends Component {
  render() {
    return (
      <div id="loader-transparent">
        <div className="loader">
          <div className="loader-img">
          </div>
        </div>
      </div>
    );
  };
};

export default Loader;
