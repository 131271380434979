import { REQ_RETREADBRANDS_SUCCESS, REQ_RETREADBRANDS_MIN_SUCCESS } from '../types/RetreadBrandsCatalogueTypes'


const INITIAL_STATE = {
  retreadBrandsCatalogue: [],
  retreadBrandsCatalogueMin: []
}

export const retreadBrandsCatalogueReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQ_RETREADBRANDS_SUCCESS:
     return {
       ...state,
       retreadBrandsCatalogue: action.payload
     }
    case REQ_RETREADBRANDS_MIN_SUCCESS:
     return {
       ...state,
       retreadBrandsCatalogueMin: action.payload
     }
    default:
      return state
  }
}