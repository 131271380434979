import moment from "moment";
import React from "react";
import chunk from "lodash/chunk";
import ReactPaginate from "react-paginate";
import * as APIS from "../../../../constants/apis";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

import { Link } from "react-router-dom";

import TireDeleteItem from "./TireDeleteItem";

class TiresList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      page: 1
    };
  };

  generateExcel(t) {
    return trackPromise(fetch(APIS.API_REST_TIRES_EXCEL.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.retread-item-2"));
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${t("alerts.c-tire-item-6")}.xls`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success(t("alerts.retread-item-4"));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  getTiresPage = (rows, page) => {
    const chunks = chunk(rows, 20);
    if (!!chunks[page - 1]) {
      return chunks[page - 1];
    }
    return [];
  };

  onPageChange = event => {
    this.setState({ page: event.selected + 1 });
  };

  getTiresList = (tires, tiresS) => {
    if (!tires) return null;
    let fmtTires;
    if (!!tiresS) {
      fmtTires = tiresS.sort((t1, t2) => {
        return (t2.vehiculo - t1.vehiculo) ||
          ((1 * t1.posicion_montaje > 1 * t2.posicion_montaje) ? 1 : -1)
      }).filter(tire => !!tire.codigo).filter(tire => {
        return tire.codigo.toLowerCase().search(this.state.search) >= 0;
      });
    } else {
      fmtTires = tires.sort((t1, t2) => {
        return (t2.vehiculo - t1.vehiculo) ||
          ((1 * t1.posicion_montaje > 1 * t2.posicion_montaje) ? 1 : -1)
      }).filter(tire => !!tire.codigo).filter(tire => {
        return tire.codigo.toLowerCase().search(this.state.search) >= 0;
      });
    }
    return (
      <div className="mt-5">
        <div className="row">
          <div className="col-md-6">
            {!!tiresS ? <p>
              {tiresS.length + " llantas en total"}
            </p> : null}
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <div className="float-right">
                  <button className="btn btn-success"
                    onClick={(event) =>
                      this.generateExcel(this.props.t)}>
                    <i className="fa fa-file-export"></i> Exportar llantas
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder="Buscar..."
                  onChange={this.onChangeSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        {
          fmtTires.length > 0 ?
            <div className="table-responsive">
              <table className="table table-striped table-bordered table-sm">
                <thead>
                  <tr>
                    <th scope="col">{this.props.t("client-tires.client-tire-1")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-2")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-3")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-4")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-5")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-6")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-7")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-8")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-9")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-10")}</th>
                    <th scope="col">{this.props.t("client-tires.client-tire-11")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.getTiresPage(fmtTires, this.state.page).map((tire, idx) => {
                      return <tr key={idx}>
                        <td>{1 * tire.posicion_montaje}</td>
                        <td>{!!tire.vehiculo ? tire.vehiculo : ""}</td>
                        <td>{!!tire.vehicle_plate ? tire.vehicle_plate : ""}</td>
                        <td>{!!tire.ubicacion && !tire.vehiculo ? tire.ubicacion : ""}</td>
                        <td>{tire.codigo}</td>
                        <td>{tire.marca}</td>
                        <td>{tire.modelo}</td>
                        <td>{tire.dimension}</td>
                        <td>{!!tire.retreadbrand ? tire.retreadbrand + " " + tire.retreadband + " R" + tire.vida : null}</td>
                        <td>{moment.utc(tire.fc_ultima_inspeccion).local()
                          .locale("es").format("LLL")}</td>
                        <td>
                          <Link to={"/tires/history/" + tire.id}
                            className="btn btn-primary">
                            <i className="fa fa-chart-line"></i> <th scope="col">{this.props.t("globals.history")}</th>
                          </Link>&nbsp;
                          <Link to={"/tires/edit/" + tire.id}
                            className="btn btn-primary">
                            <i className="fa fa-edit"></i> <th scope="col">{this.props.t("globals.edit")}</th>
                          </Link>&nbsp;
                          <TireDeleteItem
                            t={this.props.t}
                            tireDelete={tire}
                          />
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div> : null
        }
        <br />
        {
          Math.ceil(fmtTires.length / 20) > 1 ?
            <ReactPaginate
              pageCount={Math.ceil(fmtTires.length / 20)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={3}
              onPageChange={this.onPageChange}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link text-white"}
              previousLinkClassName={"page-link text-white"}
              nextLinkClassName={"page-link text-white"}
              activeClassName={"active"} /> :
            null
        }
      </div>
    );
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render() {
    const { tires, tiresS } = this.props;

    return (this.getTiresList(tires, tiresS));
  };
};

export default TiresList;
