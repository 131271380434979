import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsCity from "../../../constants/actions/Cities";
import * as APIS from "../../../constants/apis";

import CityDeleteItem from "./CityDeleteItem";

function getCities(cities, city, t) {
  let citiesReq = [];
  return dispatch => {
    return trackPromise(fetch(APIS.API_REST_CITIES.replace("customer_id",
                   localStorage.getItem("GlobalCustomerId")), {
                      headers:{
                        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                        "Accept-language": localStorage.getItem("LanguageSelected"),
                      } })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(t("alerts.cities-item-1"));
        }
        return response.json(); })
      .then(json => {
        citiesReq = json.cities;
        dispatch(getCitiesSuccess({
          cities    : citiesReq,
          city      : city
        })); })
      .catch(error => {
        dispatch(getCitiesError({
          cities    : cities,
          city      : city,
          error     : error.message
        }));
      }));
  };
};

export function getCitiesSuccess(json) {
  return {
    type: constantsCity.GET_CITIES_SUCCESS,
    json
  };
};

export function getCitiesError(json) {
  return {
    type: constantsCity.GET_REQUEST_ERROR,
    json
  };
};

class CitiesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  };

  componentDidMount() {
    const { cities, city } = this.props;
    this.props.getCities(cities, city, this.props.t);
  };

  getCitiesList = (cities, authUser) => {
    if(!cities) return null;
    const { search } = this.state;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("client-general.client-item-1")}</th>
              <th scope="col">{this.props.t("client-general.client-item-2")}</th>
            </tr>
          </thead>
          <tbody>
          {
            cities.filter(city => {
              return city.name.toLowerCase().search(search) >= 0;
            }).map(city => {
              return <tr key={city.id}>
                <td>
                  {city.name}
                </td>
                <td>
                 { !!authUser.permissions.edit ? 
                  <Link to={"/cities/edit/"+city.id}
                        className="btn btn-primary">
                    <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                  </Link> : null }&nbsp;
                 { !!authUser.permissions.delete ? 
                  <CityDeleteItem
                    t={this.props.t}
                    cityDelete={city}
                  /> : null }
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    );
  };

  showSuccessDialog = (message) => {
    if(!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if(!!error && !!toast) {
      toast.error(error);
    }
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render () {
    const { cities, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("client-general.cities-list")}</h3>
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder={this.props.t("globals.search-input")}
                  onChange={this.onChangeSearch}
                />
              </div>
            </div>
            <br/>
                 { !!authUser.permissions.create ? 
            <Link to={"/cities/new"}
                  className="btn btn-primary"><i className="fa fa-plus"></i> {this.props.t("globals.register")}</Link> : null }
            <br/><br/>
            {this.getCitiesList(cities, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  cities    : state => state.cities,
  city      : state => state.city,
  message   : state => state.message,
  error     : state => state.error
});

const mapDispatchToProps = { getCities };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(CitiesList));
