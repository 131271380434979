import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import * as APIS from "../../../../../constants/apis";

export const getWasteTable = (novelties, t) => (
    <table className="table table-striped table-bordered text-center">
        <thead>
        <tr>
            <th scope="col">{t("formats.formats-modal-th-1")}</th>
            <th scope="col">{t("formats.formats-modal-th-2")}</th>
            <th scope="col">{t("formats.formats-modal-th-3")}</th>
            <th scope="col">{t("formats.formats-modal-th-4")}</th>
        </tr>
        </thead>
        <tbody>
        {
            novelties.map((waste, idx1) => {
            return waste.novedades.map((novelty, idx2) => {
                return novelty.posibles_causas.map((cause, idx3) => {
                return <tr key={(""+idx1)+(""+idx2)+(""+idx3)}>
                    <td>{novelty.codigo_novedad}</td>
                    <td>{t(`response-codes.${waste.seccion_llanta_key}`)}</td>
                    <td>{t(`response-codes.${novelty.motivo_key}`)}</td>
                    <td>{t(`response-codes.${cause.posible_causa_key}`)}</td>
                </tr>
                })
            })
            })
        }
        </tbody>
    </table>
);

const generateExcel = (int) => {
    var str = "?export=true"
    let url;

    if(int == "1") {
      url = APIS.API_REST_WASTES_LIST;
    } else if (int == "2") {
      url = APIS.API_REST_RECORDS_LIST;
    } else if (int == "3") {
      url = APIS.API_REST_NOVELTIES_LIST;
    }

    return trackPromise(fetch(`${url + str}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Error en el servidor generando el excel");
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${int == "1" ? "Diccionario de desechos" : int == "2" ? "Diccionario de antecedentes" : int == "3" ? "Diccionario de novedades" : null}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success("Excel generado exitosamente");
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

const GetWasteModal = ({discards, antecedents, noveltys, int ,t}) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
      setShow(true)
    }, [int])
    

    const handleClose = () =>  setShow(false);

    const data = int == "1" ? discards : int == "2" ? antecedents : int == "3" ? noveltys : [];

    return (
      <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{flex: 1}}>{int == "1" ? t("formats.formats-dictionary-scrap") : int == "2" ? t("formats.formats-dictionary-background") : int == "3" ? t("formats.formats-dictionary-novelties") : null}</Modal.Title>
          <button className="btn btn-success"
            onClick={(event) => generateExcel(int)}>
            <i className="fa fa-file-export"></i> {t("globals.exports")}
          </button>
        </Modal.Header>
        <Modal.Body>
          <h5 className="mb-4 text-center">{int == "1" ? t("formats.formats-dictionary-scrap") : int == "2" ? t("formats.formats-dictionary-background") : int == "3" ? t("formats.formats-dictionary-novelties") : null}</h5>
          { getWasteTable(data, t) }
        </Modal.Body>
      </Modal>
      </>
    )
}

export default GetWasteModal

