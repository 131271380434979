import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../../Auth/Session";
import * as constantsBrand from "../../../../constants/actions/RetreadBrands";
import * as constantsDim from "../../../../constants/actions/RetreadDimensions";
import * as APIS from "../../../../constants/apis";

function getDimension(dimId, bandId) {
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_RETREADDIMENSIONS
      .replace("retreadband_id", bandId)}/${dimId}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        dispatch(onDimensionSuccess({
          dimension: json
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };
};

export function onDimensionSuccess(json) {
  return {
    type: constantsDim.GET_DIMENSION_SUCCESS,
    json
  };
};

export function onReqSuccess(bands, band, message) {
  const json = {
    bands: bands,
    band: band,
    message: message
  };
  return {
    type: constantsBrand.REQUEST_SUCCESS,
    json
  };
};

class RetreadDimensionEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      customer_id: localStorage.getItem('GlobalCustomerId'),
      dimention: "",
      profundidad_original: "",
      profundidad_minima: "",
      presion_maxima: "",
      presion_minima: ""
    };
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    const bandId = this.props.match.params.bandid;
    this.props.getDimension(id, bandId);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.dimension || (!!this.props.dimension &&
      this.props.dimension !== prevProps.dimension)) {
      this.setState({
        code: this.props.dimension.code,
        dimention: this.props.dimension.dimention,
        profundidad_original: this.props.dimension.profundidad_original,
        profundidad_minima: this.props.dimension.profundidad_minima,
        presion_maxima: this.props.dimension.presion_maxima,
        presion_minima: this.props.dimension.presion_minima
      });
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const { onReqSuccess, band, dimensions } = this.props;

    const name = this.props.match.params.id;
    const bandid = this.props.match.params.bandid;
    const data = {
      retreadband_id: bandid,
      ...this.state,
    };

    return trackPromise(fetch(`${APIS.API_REST_RETREADDIMENSIONS
      .replace("retreadband_id", bandid)}/${name}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "PUT",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          this.props.history.goBack();
          onReqSuccess(band, dimensions, this.props.t("alerts.r-tire-item-13"));
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  render() {
    const { dimension, error } = this.props;

    this.showErrorDialog(error);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!dimension ?
            <div className="container-fluid mt-100">
              <h3>{ authUser.roles != "ANALYST" ? this.props.t("tire-brands.tire-dimensions-edit") : this.props.t("tire-brands.tire-dimensions-see")}</h3>
              <br /><br />
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="codeInput">
                        {this.props.t("globals.code")}
                    </label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("globals.code")}
                        id="codeInput"
                        name="code"
                        value={this.state.code}
                        onChange={this.onChange}
                        required />
                      <input type="hidden"
                        name="customer_id"
                        value={this.state.customer_id} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="dimentionInput">
                      {this.props.t("tire-brands.tire-item-1")}
                    </label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-brands.tire-item-1")}
                        id="dimentionInput"
                        name="dimention"
                        value={this.state.dimention}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="orDepth">{this.props.t("tire-brands.tire-item-4")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-brands.tire-item-4")}
                        id="orDepthInput"
                        name="profundidad_original"
                        value={this.state.profundidad_original}
                        onChange={this.onChange}
                        type="number"
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="minDepth">{this.props.t("tire-brands.tire-item-5")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-brands.tire-item-5")}
                        id="minDepthInput"
                        name="profundidad_minima"
                        value={this.state.profundidad_minima}
                        onChange={this.onChange}
                        type="number"
                        required />
                    </div>
                  </div>
                </div>
                <div style={{ display: "none" }} className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="minPressInput">{this.props.t("tire-brands.tire-item-6")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-brands.tire-item-6")}
                        id="minPressInput"
                        name="presion_minima"
                        value={this.state.presion_minima}
                        onChange={this.onChange}
                        type="number" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "none" }} className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="maxPressInput">{this.props.t("tire-brands.tire-item-7")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-brands.tire-item-7")}
                        id="maxPressInput"
                        name="presion_maxima"
                        value={this.state.presion_maxima}
                        onChange={this.onChange}
                        type="number" />
                    </div>
                  </div>
                </div>
                {
                  authUser.roles != "ANALYST" ?
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <button type="submit"
                          className="btn btn-primary">
                          <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                      </button>
                      </div>
                    </div>
                  :
                  <div className="row">
                      <div className="col-md-12 text-center">
                        <button type="button"
                          onClick={() => window.history.back()}
                          className="btn btn-primary">
                          <i className="fa fa-arrow-left"></i> {this.props.t("globals.back")}
                      </button>
                      </div>
                    </div>
                }
              </form>
            </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  dimension: state => state.dimension,
  band: state => state.band,
  error: state => state.error
});

const mapDispatchToProps = { getDimension, onReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(RetreadDimensionEditItem));
