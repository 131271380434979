import Q from "q";
import xlsx from "xlsx";
import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../../../Auth/Session";

import ErrorsModal from "./ErrorsModal";
import DragDropFile from "./DragDropFile";
import DataInput from "./DataInput";
import OutTable from "./OutTable";

import * as APIS from "../../../../../constants/apis";
import * as VALIDATIONS from "../../../../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;
const randomString = Math.random().toString(36);

const INITIAL_STATE = {
  filePath: "",
  inputKey: randomString,
  done: [],
  notDone: [],
  data: [],
  cols: [],
  errors: [],
  warehouses: [],
  // Brands
  brands: [],
  retreadbrands: [],
  tirecatalogues: [],
  retreadcatalogues: [],
  // Novelties
  novelties: [],
};

class MultipleTires extends Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();

    this.serverErrors = [];
    this.handleFile = this.handleFile.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    let warehouses,
      brands,
      retreadbrands,
      tirecatalogues,
      retreadcatalogues,
      novelties = [];
    trackPromise(
      fetch(
        `${APIS.API_REST_WAREHOUSES.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        )}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw Error(json.message);
          }
          warehouses = json.bodegas;
        })
        .then(() => {
          return fetch(
            `${APIS.API_REST_CUSTOMER_TIREBRANDS.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            )}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw Error(json.message);
          }
          brands = json.Tirebrands;
        })
        .then(() => {
          return fetch(
            `${APIS.API_REST_CUSTOMER_RETREADBRANDS.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            )}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw Error(json.message);
          }
          retreadbrands = json.Retreadbrands;
        })
        .then(() => {
          return fetch(
            `${APIS.API_REST_TIRECATALOGUES_MIN_INFO.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            )}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw Error(json.message);
          }
          tirecatalogues = json.TireCatalogue;
        })
        .then(() => {
          return fetch(
            `${APIS.API_REST_RETREADCATALOGUES_MIN_INFO.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            )}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw Error(json.message);
          }
          retreadcatalogues = json.RetreadCatalogue;
        })
        .then(() =>
          fetch(`${APIS.API_REST_WASTES_LIST}`, {
            headers: {
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
          })
        )
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw Error(json.message);
          }
          novelties = json.catalogo_desechos;
          this.setState({
            brands: brands,
            retreadbrands: retreadbrands,
            tirecatalogues: tirecatalogues,
            retreadcatalogues: retreadcatalogues,
            warehouses: warehouses,
            novelties: novelties,
          });
        })
        .catch((error) => {
          toast.error(error.message);
        })
    );
  }

  makeCols = (refstr) => {
    let o = [],
      C = xlsx.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = {
        name: xlsx.utils.encode_col(i),
        key: i,
      };
    }
    return o;
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };

  noveltyExists = (novelty) => {
    const novelties = this.state.novelties;
    for (let i = 0; i < novelties.length; i++) {
      if (novelties[i].seccion_llanta.toUpperCase() === novelty.seccion) {
        const noveltiesRuedata = novelties[i].novedades;
        for (let j = 0; j < noveltiesRuedata.length; j++) {
          if (noveltiesRuedata[j].codigo_novedad === novelty.cod_ruedata) {
            if (noveltiesRuedata[j].motivo === novelty.motivo) {
              return true;
              /*
              const causes = noveltiesRuedata[j].posibles_causas;
              for(let k = 0; k < causes.length; k++) {
                if(causes[k].posible_causa === novelty.causa) {
                    return true;
                  }
                }
                */
            }
            //   if (noveltiesRuedata[j].order === novelty.order) {
          }
        }
      }
    }

    return false;
  };

  getCurrentTireInfo = (brandInfo, type) => {
    let curBrand = {
      marca_id: "",
      modelo_id: "",
      dimension_id: "",
    };

    if (!brandInfo.marca || !brandInfo.modelo || !brandInfo.dimension) {
      return curBrand;
    }

    const labelBrands = type === "nueva" ? "brands" : "retreadbrands";
    const labelModels = type === "nueva" ? "tiremodels" : "retreadbands";
    const labelDims = type === "nueva" ? "tiredimentions" : "retreadimentions";

    const brands = this.state[labelBrands];

    for (let i = 0; i < brands.length; i++) {
      if (String(brands[i].name).trim() === String(brandInfo.marca).trim()) {
        const models = brands[i][labelModels];
        curBrand.marca_id = brands[i].id;
        for (let j = 0; j < models.length; j++) {
          if (
            String(models[j].name).trim() === String(brandInfo.modelo).trim()
          ) {
            const dimensions = models[j][labelDims];
            curBrand.modelo_id = models[j].id;
            for (let k = 0; k < dimensions.length; k++) {
              if (
                String(dimensions[k].dimention).trim() ===
                String(brandInfo.dimension).trim()
              ) {
                curBrand.dimension_id = dimensions[k].id;
                return curBrand;
              }
            }
          }
        }
      }
    }
    return null;
  };

  getWarehouse = (codigo) => {
    const warehouses = this.state.warehouses;
    for (let i = 0; i < warehouses.length; i++) {
      if (warehouses[i].name === codigo) {
        return warehouses[i].id;
      }
    }
    return null;
  };

  getCatalogue = (codigo) => {
    const catalogue = this.state.tirecatalogues;
    for (let i = 0; i < catalogue.length; i++) {
      if (catalogue[i].codigo === codigo) {
        return catalogue[i].id;
      }
    }
    return null;
  };

  getRetreadcatalogue = (codigo) => {
    const catalogue = this.state.retreadcatalogues;
    for (let i = 0; i < catalogue.length; i++) {
      if (catalogue[i].codigo === codigo) {
        return catalogue[i].id;
      }
    }
    return null;
  };

  getDataErrors = (cols, data) => {
    let errors = [];

    // Número de columnas
    let fields = [
      this.props.t("multiple.tire-item-1"),
      this.props.t("multiple.tire-item-2"),
      this.props.t("multiple.tire-item-3"),
      this.props.t("multiple.tire-item-4"),
      this.props.t("multiple.tire-item-5"),
      this.props.t("multiple.tire-item-6"),
      this.props.t("multiple.tire-item-7"),
      this.props.t("multiple.tire-item-8"),
      this.props.t("multiple.tire-item-46"),
      this.props.t("multiple.tire-item-47"),
      this.props.t("multiple.tire-item-48"),
      this.props.t("multiple.tire-item-49"),
      this.props.t("multiple.tire-item-9"),
      this.props.t("multiple.tire-item-10"),
      this.props.t("multiple.tire-item-11"),
      this.props.t("multiple.tire-item-12"),
      this.props.t("multiple.tire-item-13"),
      this.props.t("multiple.tire-item-14"),
      this.props.t("multiple.tire-item-15"),
      this.props.t("multiple.tire-item-16"),
      this.props.t("multiple.tire-item-50"),
      this.props.t("multiple.tire-item-17"),
      this.props.t("multiple.tire-item-18"),
      this.props.t("multiple.tire-item-19"),
      this.props.t("multiple.tire-item-20"),
      this.props.t("multiple.tire-item-21"),
      this.props.t("multiple.tire-item-22"),
      this.props.t("multiple.tire-item-23"),
      this.props.t("multiple.tire-item-24"),
      this.props.t("multiple.tire-item-25"),
      this.props.t("multiple.tire-item-26"),
      this.props.t("multiple.tire-item-27"),
      this.props.t("multiple.tire-item-28"),
      this.props.t("multiple.tire-item-29"),
    ];

    if (cols.length !== fields.length) {
      errors.push({
        message: this.props.t("multiple.global-item-1"),
      });
    }

    // Número de datos
    if (data.length < 2) {
      errors.push({
        message: this.props.t("multiple.global-item-2"),
      });
    }

    // Columnas necesarias
    for (let i = 0; i < fields.length; i++) {
      if (data[0].indexOf(fields[i]) === -1) {
        errors.push({
          message:
            this.props.t("multiple.global-item-3") +
            fields[i] +
            this.props.t("multiple.global-item-4") +
            this.props.t("multiple.global-item-5"),
        });
      }
    }

    // Validaciones de los datos
    for (let i = 1; i < data.length; i++) {
      let brandInfo = {
        marca: "",
        modelo: "",
        dimension: "",
      };
      let novelty = {
        cod_ruedata: "",
        DOT: "",
        order: "",
        causa: "",
        motivo: "",
        ligado_a: "",
        seccion: "",
      };
      let brandInfoRetread = {
        marca: "",
        modelo: "",
        dimension: "",
        use: "",
      };
      for (let j = 0; j < fields.length; j++) {
        if (
          data[0][j] === this.props.t("multiple.tire-item-13") ||
          data[0][j] === this.props.t("multiple.tire-item-15") ||
          data[0][j] === this.props.t("multiple.tire-item-14") ||
          data[0][j] === "ult_presion" ||
          data[0][j] === "kilometers" ||
          data[0][j] === "posicion_montaje"
        ) {
          if (!!data[i][j] && isNaN(data[i][j])) {
            errors.push({
              message:
                this.props.t("multiple.r-tire-item-12") +
                (i + 1) +
                this.props.t("multiple.r-tire-item-13") +
                data[0][j] +
                this.props.t("multiple.r-tire-item-14"),
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.tire-item-5")) {
          const warehouse = this.getWarehouse(data[i][j]);
          if (!warehouse) {
            errors.push({
              message:
                this.props.t("multiple.tire-item-30") +
                (i + 1) +
                this.props.t("multiple.tire-item-31") +
                data[i][j] +
                this.props.t("multiple.tire-item-32"),
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.tire-item-24")) {
          let cat = data[i][j];
          if (
            brandInfoRetread.marca &&
            brandInfoRetread.modelo &&
            brandInfoRetread.dimension
          ) {
            if (!!cat) {
              const catalogue = this.getRetreadcatalogue(data[i][j]);
              if (!catalogue) {
                errors.push({
                  message:
                    this.props.t("multiple.tire-item-30") +
                    (i + 1) +
                    this.props.t("multiple.tire-item-33") +
                    data[i][j] +
                    this.props.t("multiple.tire-item-32"),
                });
              }
            } else {
              errors.push({
                message:
                  this.props.t("multiple.tire-item-30") +
                  (i + 1) +
                  this.props.t("multiple.tire-item-33") +
                  this.props.t("multiple.tire-item-34"),
              });
            }
          }
        }

        if (data[0][j] === this.props.t("multiple.tire-item-23")) {
          let cat = data[i][j];
          if (brandInfo.marca && brandInfo.modelo && brandInfo.dimension) {
            if (!!cat) {
              const catalogue = this.getCatalogue(data[i][j]);
              if (!catalogue) {
                errors.push({
                  message:
                    this.props.t("multiple.tire-item-30") +
                    (i + 1) +
                    this.props.t("multiple.tire-item-35") +
                    data[i][j] +
                    this.props.t("multiple.tire-item-32"),
                });
              }
            } else {
              errors.push({
                message:
                  this.props.t("multiple.tire-item-30") +
                  (i + 1) +
                  this.props.t("multiple.tire-item-35") +
                  this.props.t("multiple.tire-item-34"),
              });
            }
          }
        }

        if (data[0][j] === this.props.t("multiple.tire-item-1")) {
          if (
            moment(data[i][j], "YYYY-MM-DD").format("YYYY-MM-DD") !== data[i][j]
          ) {
            errors.push({
              message:
                this.props.t("multiple.tire-item-30") +
                (i + 1) +
                this.props.t("multiple.tire-item-36"),
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.tire-item-2")) {
          let reg = new RegExp("^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$");
          if (!reg.test(data[i][j])) {
            errors.push({
              message:
                this.props.t("multiple.tire-item-30") +
                (i + 1) +
                this.props.t("multiple.tire-item-37"),
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.tire-item-25")) {
          if (!data[i][j]) {
            errors.push({
              message:
                this.props.t("multiple.tire-item-30") +
                (i + 1) +
                this.props.t("multiple.tire-item-38"),
            });
          }
        }
        if (
          data[0][j] === this.props.t("multiple.tire-item-13") ||
          data[0][j] === this.props.t("multiple.tire-item-14") ||
          data[0][j] === this.props.t("multiple.tire-item-15")
        ) {
          if (data[i][j] == null && /*USO*/ data[i][7] === "U") {
            errors.push({
              message:
                this.props.t("multiple.tire-item-30") +
                (i + 1) +
                this.props.t("multiple.tire-item-39") +
                data[0][j] +
                this.props.t("multiple.tire-item-40"),
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.tire-item-47")) {
          brandInfo.marca = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-9")) {
          brandInfoRetread.marca = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-48")) {
          brandInfo.modelo = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-10")) {
          brandInfoRetread.modelo = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-49")) {
          brandInfo.dimension = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-11")) {
          brandInfoRetread.dimension = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-46")) {
          brandInfoRetread.estado = data[i][j];
        }

        if (data[0][j] === this.props.t("multiple.tire-item-8")) {
          if (data[i][j]) {
            if (
              data[i][j].toUpperCase() === "U" ||
              data[i][j].toUpperCase() === "S"
            ) {
              brandInfoRetread.uso = data[i][j];
            } else {
              errors.push({
                message:
                  this.props.t("multiple.tire-item-30") +
                  (i + 1) +
                  this.props.t("multiple.tire-item-41") +
                  data[i][j].toUpperCase(),
              });
            }
          }
        }
        if (data[0][j] === this.props.t("multiple.tire-item-16")) {
          novelty.cod_ruedata = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-4")) {
          novelty.DOT = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-50")) {
          novelty.order = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-20")) {
          novelty.causa = data[i][j];
        }
        if (data[0][j] === this.props.t("multiple.tire-item-18")) {
          novelty.motivo !== ""
            ? (novelty.motivo = data[i][j].toUpperCase())
            : (novelty.motivo = "");
        }
        if (data[0][j] === this.props.t("multiple.tire-item-19")) {
          novelty.ligado_a = novelty.ligado_a;
        }
        if (data[0][j] === this.props.t("multiple.tire-item-17")) {
          novelty.seccion !== ""
            ? (novelty.seccion = data[i][j].toUpperCase())
            : (novelty.seccion = "");
        }
        if (data[0][j] === this.props.t("multiple.tire-item-29")) {
          if (data[i][j]) {
            let rs = data[i][j];
            if (rs.toUpperCase() === "S" || rs.toUpperCase() === "N") {
              data[i][j] = data[i][j];
            } else {
              errors.push({
                message:
                  this.props.t("multiple.tire-item-30") +
                  (i + 1) +
                  this.props.t("multiple.tire-item-42") +
                  data[i][j].toUpperCase(),
              });
            }
          } else {
            errors.push({
              message:
                this.props.t("multiple.tire-item-30") +
                (i + 1) +
                this.props.t("multiple.tire-item-42") +
                data[i][j],
            });
          }
        }
      }
      let tireInfo = this.getCurrentTireInfo(brandInfoRetread, "reencauche");
      if (!!tireInfo) {
        if (
          (!tireInfo.marca_id ||
            !tireInfo.modelo_id ||
            !tireInfo.dimension_id) &&
          brandInfoRetread.uso === "R"
        ) {
          errors.push({
            message:
              this.props.t("multiple.tire-item-30") +
              (i + 1) +
              this.props.t("multiple.tire-item-43"),
          });
        }
      } else {
        errors.push({
          message:
            this.props.t("multiple.tire-item-30") +
            (i + 1) +
            this.props.t("multiple.tire-item-43"),
        });
      }
      if (!this.getCurrentTireInfo(brandInfo, "nueva")) {
        errors.push({
          message:
            this.props.t("multiple.tire-item-30") +
            (i + 1) +
            this.props.t("multiple.tire-item-44"),
        });
      }
    }

    return errors;
  };

  handleFile = (file) => {
    this.setState({ data: [], cols: [], done: [], notDone: [], errors: [] });

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = xlsx.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1, raw: false });
      const cols = this.makeCols(ws["!ref"]);
      const errors = this.getDataErrors(cols, data);
      if (errors.length === 0) {
        this.setState({
          data: data,
          cols: this.makeCols(ws["!ref"]),
          inputKey: Math.random().toString(36),
        });
      } else {
        this.modal.current.showModal(errors);
        this.setState({
          errors: [],
          data: [],
          cols: [],
          inputKey: Math.random().toString(36),
        });
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  exportTemplate = (event) => {
    event.preventDefault();

    let header = [
      this.props.t("multiple.tire-item-1"),
      this.props.t("multiple.tire-item-2"),
      this.props.t("multiple.tire-item-3"),
      this.props.t("multiple.tire-item-4"),
      this.props.t("multiple.tire-item-5"),
      this.props.t("multiple.tire-item-6"),
      this.props.t("multiple.tire-item-7"),
      this.props.t("multiple.tire-item-8"),
      this.props.t("multiple.tire-item-46"),
      this.props.t("multiple.tire-item-47"),
      this.props.t("multiple.tire-item-48"),
      this.props.t("multiple.tire-item-49"),
      this.props.t("multiple.tire-item-9"),
      this.props.t("multiple.tire-item-10"),
      this.props.t("multiple.tire-item-11"),
      this.props.t("multiple.tire-item-12"),
      this.props.t("multiple.tire-item-13"),
      this.props.t("multiple.tire-item-14"),
      this.props.t("multiple.tire-item-15"),
      this.props.t("multiple.tire-item-16"),
      this.props.t("multiple.tire-item-50"),
      this.props.t("multiple.tire-item-17"),
      this.props.t("multiple.tire-item-18"),
      this.props.t("multiple.tire-item-19"),
      this.props.t("multiple.tire-item-20"),
      this.props.t("multiple.tire-item-21"),
      this.props.t("multiple.tire-item-22"),
      this.props.t("multiple.tire-item-23"),
      this.props.t("multiple.tire-item-24"),
      this.props.t("multiple.tire-item-25"),
      this.props.t("multiple.tire-item-26"),
      this.props.t("multiple.tire-item-27"),
      this.props.t("multiple.tire-item-28"),
      this.props.t("multiple.tire-item-29"),
    ];

    const ws = xlsx.utils.aoa_to_sheet([header]);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Plantilla");
    xlsx.writeFile(wb, `${this.props.t("multiple.tire-item-45")}.xlsx`);
  };

  formatData = (register, authUser) => {
    let inspection = {
      brandInfo: {},
      retreadInfo: {},
    };

    // Set customer
    inspection.customer_id = localStorage.getItem("GlobalCustomerId");

    // Set general values
    let header = this.state.data[0];
    for (let i = 0; i < header.length; i++) {
      inspection[header[i].toLowerCase()] = register[i];
      if (header[i] === this.props.t("multiple.tire-item-1")) {
        inspection.fc_ultima_inspeccion = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-2")) {
        inspection.hora = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-3")) {
        inspection.codigo_actual = register[i];
        inspection.numero_al_calor = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-4")) {
        inspection.DOT = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-5")) {
        inspection.ubicacion = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-8")) {
        inspection.use = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-46")) {
        inspection.state = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-12")) {
        inspection.vida = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-50")) {
        inspection.order = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-17")) {
        inspection.seccion = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-18")) {
        inspection.motivo = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-19")) {
        inspection.ligado_a = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-20")) {
        inspection.causa = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-25")) {
        inspection.tecnico = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-29")) {
        inspection.regrabada = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-9")) {
        inspection.marca_renovado = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-10")) {
        inspection.banda_renovado = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-11")) {
        inspection.dimension_renovado = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-17")) {
        inspection.seccion = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-18")) {
        inspection.motivo = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-21")) {
        inspection.fecha_subida = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-22")) {
        inspection.hora_subida = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-24")) {
        inspection.catalogo_renovado = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-26")) {
        inspection.precio_original = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-27")) {
        inspection.precio_renovado = register[i];
      }
      if (header[i] === this.props.t("multiple.tire-item-28")) {
        inspection.precio_regrabado = register[i];
      }
    }
    const brandInfoRetread = {
      marca: inspection.marca_renovado || null,
      modelo: inspection.banda_renovado || null,
      dimension: inspection.dimension_renovado || null,
    };
    const brandInfo = {
      marca: inspection.marca || null,
      modelo: inspection.modelo || null,
      dimension: inspection.dimension || null,
    };
    const trInfo = this.getCurrentTireInfo(brandInfoRetread, "reencauche");
    const brInfo = this.getCurrentTireInfo(brandInfo, "nueva");
    inspection.brandInfo.marca_id = brInfo.marca_id || null;
    inspection.brandInfo.modelo_id = brInfo.modelo_id || null;
    inspection.brandInfo.dimension_id = brInfo.dimension_id || null;
    if (inspection.estado === "R") {
      inspection.retreadInfo.marca_id = trInfo.marca_id || null;
      inspection.retreadInfo.modelo_id = trInfo.modelo_id || null;
      inspection.retreadInfo.dimension_id = trInfo.dimension_id || null;
    }
    // Set last date and analyst
    inspection.fc_ultima_inspeccion = moment(
      inspection.fc_ultima_inspeccion + " " + inspection.hora
    )
      .utc()
      .format();
    // Set last date and analyst
    inspection.fecha_subida = !inspection.fecha_subida
      ? null
      : moment(inspection.fecha_subida + " " + inspection.hora_subida)
          .utc()
          .format();
    inspection.analista = authUser.email;

    return inspection;
  };

  // Methods only when creating and editing a tire
  cleanData = (data) => {
    for (let key in data) {
      if (data[key] == null) {
        delete data[key];
      }
    }
    return data;
  };

  getCreateOrUpdateData = (register, use) => {
    let regrab = false;
    if (register.regrabada.toUpperCase() === "S") {
      regrab = true;
    }

    let data = {
      fc_ultima_inspeccion: register.fc_ultima_inspeccion || null,
      customer_id: localStorage.getItem("GlobalCustomerId"),
      codigo_actual: register.codigo_actual || null,
      codigo: register.numero_al_calor || null,
      DOT: register.dot || null,
      prof_original: register.prof_original || null,
      prof_minima: register.prof_minima || null,
      state: register.state || null,
      use: register.use || null,
      marca_id: register.brandInfo.marca_id || null,
      modelo_id: register.brandInfo.modelo_id || null,
      dimension_id: register.brandInfo.dimension_id || null,
      retreadbrand_id: register.retreadInfo.marca_id || null,
      retreadband_id: register.retreadInfo.modelo_id || null,
      retreadimention_id: register.retreadInfo.dimension_id || null,
      tirecatalogue_id: this.getCatalogue(register.catalogo),
      retreadcatalogue_id: this.getRetreadcatalogue(register.catalogo_renovado),
      analista: register.analista,
      tecnico: register.tecnico,
      vida: register.vida,
      order: register.num_documento,
      precio_original: register.precio_original,
      precio_reencauche: register.precio_renovado,
      precio_regrabado: register.precio_regrabado,
      regrabada: regrab,
      bodega_id: this.getWarehouse(register.ubicacion),
    };

    if (!!use) {
      if (use.toUpperCase() === "U") {
        data.ult_prof_centro_exterior = register.ult_prof_centro_exterior;
        data.ult_prof_centro = register.ult_prof_centro;
        data.ult_prof_interior = register.ult_prof_interior;
      } else if (use.toUpperCase() === "S") {
        data.ult_prof_centro_exterior = register.prof_original;
        data.ult_prof_centro = register.prof_original;
        data.ult_prof_interior = register.prof_original;
      }
    }

    return data;
  };

  getNoveltiesData = (register) => {
    return {
      noveltys: [
        {
          seccion_llanta: register.seccion,
          motivo: register.motivo,
          ligado_a: register.ligado_a,
          codigo_causa: register.cod_ruedata,
          order: register.order,
          posible_causa: register.causa,
        },
      ],
      fecha_subida: moment(register.fecha_subida).utc().format(),
      customer_id: localStorage.getItem("GlobalCustomerId"),
      tiponovedad: "Desecho",
      inspector: register.tecnico || null,
      DOT: register.dot || null,
      pictures: register.fotos,
    };
  };

  saveRegister = (idx, register, authUser) => {
    let deferred = Q.defer();
    if (isInternetConnected(toast)) deferred.reject();
    const data = this.formatData(register, authUser);
    let createData = this.getCreateOrUpdateData(data, data.uso);
    let that = this;
    authUser.doGenerateToken().then(function (idToken) {
      localStorage.setItem("firebaseAuthToken", idToken);
    });

    let status, status2;
    if (!!data.motivo) {
      trackPromise(
        fetch(
          `${APIS.API_REST_TIRES.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
            method: "POST",
            body: JSON.stringify(createData),
          }
        )
          .then((response) => {
            status = response.status;
            return response.json();
          })
          .then((json) => {
            if (status !== 201) {
              throw new Error(json.message);
            }
            let noveltiesData = that.getNoveltiesData(data);
            return fetch(
              `${APIS.API_REST_NOVELTIES_TIRE.replace(
                "customer_id",
                localStorage.getItem("GlobalCustomerId")
              ).replace("tire_id", json.id)}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Basic " + localStorage.getItem("firebaseAuthToken"),
                  "Accept-language": localStorage.getItem("LanguageSelected"),
                },
                method: "POST",
                body: JSON.stringify(noveltiesData),
              }
            );
          })
          .then((response) => {
            status2 = response.status;
            return response.json();
          })
          .then((json) => {
            if (status2 !== 201) {
              throw new Error(json.message);
            }
            let done = [...that.state.done];
            done.push(idx);
            that.setState({ done: done });
            deferred.resolve(json);
          })
          .catch((error) => {
            let err = error;
            if (valConnection.indexOf(error.toString()) > -1) {
              err = this.props.t("multiple.global-item-6") + (idx + 1);
            } else {
              err =
                this.props.t("multiple.global-item-7") +
                (idx + 1) +
                ". " +
                error.toString();
            }
            that.serverErrors.push({ message: err });
            let notDone = [...that.state.notDone];
            notDone.push(idx);
            that.setState({ notDone: notDone });
            deferred.reject(that.serverErrors);
          })
      );
    } else {
      trackPromise(
        fetch(
          `${APIS.API_REST_TIRES.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          )}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
            method: "POST",
            body: JSON.stringify(createData),
          }
        )
          .then((response) => {
            status = response.status;
            return response.json();
          })
          .then((json) => {
            if (status !== 201) {
              throw new Error(json.message);
            } else {
              let done = [...that.state.done];
              done.push(idx);
              that.setState({ done: done });
              deferred.resolve(json);
            }
          })
          .catch((error) => {
            let err = error;
            if (valConnection.indexOf(error.toString()) > -1) {
              err = this.props.t("multiple.global-item-6") + (idx + 1);
            } else {
              err =
                this.props.t("multiple.global-item-7") +
                (idx + 1) +
                ". " +
                error.toString();
            }
            that.serverErrors.push({ message: err });
            let notDone = [...that.state.notDone];
            notDone.push(idx);
            that.setState({ notDone: notDone });
            deferred.reject(that.serverErrors);
          })
      );
    }
    return deferred.promise;
  };

  getCleanedRegisters = (registers) => {
    let newRegisters = [];

    for (let i = 1; i < registers.length; i++) {
      if (registers[i].length > 0) {
        newRegisters.push(registers[i]);
      }
    }

    return newRegisters;
  };

  async saveRegisters(registers, authUser) {
    let deferred = Q.defer();
    let newRegisters = this.getCleanedRegisters(registers);
    for (let i = 0; i < newRegisters.length; i++) {
      if (this.state.done.indexOf(i) === -1) {
        await this.saveRegister(i, newRegisters[i], authUser)
          .then(() => {
            return true;
          })
          .catch((errors) => {
            deferred.reject(errors);
            return deferred.promise;
          });
      }
    }

    deferred.resolve();
    return deferred.promise;
  }

  onSubmit = (event, authUser) => {
    event.preventDefault();
    this.serverErrors = [];
    this.setState({ notDone: [] });

    this.saveRegisters(this.state.data, authUser)
      .then(() => {
        toast.success(this.props.t("multiple.global-item-8"));
        this.setState({
          data: [],
          cols: [],
          done: [],
          notDone: [],
          errors: [],
        });
      })
      .catch((errors) => {
        this.modal.current.showModal(errors);
      });
  };

  render() {
    const { data, cols, done, notDone, novelties } = this.state;

    let fields = [
      this.props.t("multiple.tire-item-1"),
      this.props.t("multiple.tire-item-2"),
      this.props.t("multiple.tire-item-3"),
      this.props.t("multiple.tire-item-4"),
      this.props.t("multiple.tire-item-5"),
      this.props.t("multiple.tire-item-6"),
      this.props.t("multiple.tire-item-7"),
      this.props.t("multiple.tire-item-8"),
      this.props.t("multiple.tire-item-46"),
      this.props.t("multiple.tire-item-47"),
      this.props.t("multiple.tire-item-48"),
      this.props.t("multiple.tire-item-49"),
      this.props.t("multiple.tire-item-9"),
      this.props.t("multiple.tire-item-10"),
      this.props.t("multiple.tire-item-11"),
      this.props.t("multiple.tire-item-12"),
      this.props.t("multiple.tire-item-13"),
      this.props.t("multiple.tire-item-14"),
      this.props.t("multiple.tire-item-15"),
      this.props.t("multiple.tire-item-16"),
      this.props.t("multiple.tire-item-50"),
      this.props.t("multiple.tire-item-17"),
      this.props.t("multiple.tire-item-18"),
      this.props.t("multiple.tire-item-19"),
      this.props.t("multiple.tire-item-20"),
      this.props.t("multiple.tire-item-21"),
      this.props.t("multiple.tire-item-22"),
      this.props.t("multiple.tire-item-23"),
      this.props.t("multiple.tire-item-24"),
      this.props.t("multiple.tire-item-25"),
      this.props.t("multiple.tire-item-26"),
      this.props.t("multiple.tire-item-27"),
      this.props.t("multiple.tire-item-28"),
      this.props.t("multiple.tire-item-29"),
    ];

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <ErrorsModal ref={this.modal} t={this.props.t} />
            <h3>{this.props.t("client-tires.client-massive")}</h3>
            <br></br>
            <DragDropFile t={this.props.t} handleFile={this.handleFile}>
              <form>
                <div>
                  <DataInput
                    t={this.props.t}
                    novelties={novelties}
                    handleFile={this.handleFile}
                    exportTemplate={this.exportTemplate}
                    header={fields}
                    inputKey={this.state.inputKey || ""}
                  />
                  {data.length > 1 ? (
                    <OutTable
                      data={data}
                      cols={cols}
                      done={done}
                      notDone={notDone}
                    />
                  ) : null}
                  {data.length > 1 ? (
                    <div className="row">
                      <div className="col-md-12 mt-5">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{ width: "100%" }}
                          onClick={(event) =>
                            this.onSubmit(event, this.props.firebase)
                          }
                        >
                          <i className="fa fa-save"></i>{" "}
                          {this.props.t("globals.simple-save")}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </form>
            </DragDropFile>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(MultipleTires);
