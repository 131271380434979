import Q from "q";
import moment from "moment";
import chunk from "lodash/chunk";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import React, { Component } from "react";
import MeasurementEdit from "./MeasurementEdit";
import * as APIS from "../../constants/apis";
import * as VALIDATIONS from "../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const numberOfItems = 10;

class LastFuelsInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      done: [],
      notDone: [],
      kmsSelected: [],
      isDeleting: false
    };
  };

  getKmObject = fuel => {
    return {
      fecha_inspeccion: fuel.fecha_inspeccion,
      consecutivo: fuel.id,
      km_old: fuel.kilometers,
      km_new: fuel.kilometers
    }
  };

  checkRow = event => {
    let kms = this.state.kmsSelected;
    if (event.target.checked) {
      kms.push(event.target.value)
    } else {
      kms.splice(kms.indexOf(event.target.value), 1);
    }
    this.setState({ kmsSelected: kms })
  };

  deleteKms = event => {
    let label = "Mediciones";
    if (this.props.type === "fuel") {
      label = "Combustibles"
    }
    if (window.confirm(this.props.t("alerts.fuel-item-1") + label + "?")) {
      event.preventDefault();
      this.serverErrors = [];
      this.setState({ notDone: [] });

      trackPromise(this.deleteRegisters()
        .then(() => {
          toast.success(this.props.t("alerts.fuel-item-2") + label + this.props.t("alerts.fuel-item-3"));
          this.setState({
            isDeleting: false,
            kmsSelected: [],
          });
        })
        .catch(errors => {
          this.modal.current.showModal(errors);
        }));
    }
  };

  async deleteRegisters() {
    let deferred = Q.defer();

    for (let i = 0; i < this.state.kmsSelected.length; i++) {
      if (this.state.done.indexOf(i) === -1) {
        await this.deleteRegister(i, this.state.kmsSelected[i])
          .then(() => {
            return true;
          })
          .catch(errors => {
            deferred.reject(errors);
            return deferred.promise;
          });
      }
    }

    deferred.resolve();
    return deferred.promise;
  };

  deleteRegister = (idx, register) => {
    let deferred = Q.defer();
    let that = this;
    if (isInternetConnected(toast)) {
      deferred.reject();
      return deferred.promise;
    }
    fetch(APIS.API_REST_FUELS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("vehicle_id", this.props.carNumber) + "/" + register, {
      method: "DELETE",
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        const done = [...that.state.done];
        done.push(idx);
        that.setState({ done: done });
        document.getElementById(register).style.display = 'none';
        deferred.resolve(json);
      })
      .catch(error => {
        toast.error(error.message);
      });

    return deferred.promise;
  };

  getLastFuelsView = result => {
    const { authUser, schema, type } = this.props;
    if (!result) return null
    let pages = [];
    let start, end;
    let totalPages = Math.ceil(this.props.total / this.props.limit);
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i)
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
      start = Math.max(1, (this.props.page - 4));
      end = Math.min(totalPages, (this.props.page + 5));

      if (start === 1) {
        end = 10;
      } else if (end === totalPages) {
        start = (totalPages - 9);
      }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }
    return (
      <div className="table-responsive text-center mt-5">
        <h4>{this.props.t("measure.client-item-1")} {this.props.worktype === "km" ? this.props.t("measure.client-item-2") : this.props.t("measure.client-item-3")}</h4>
        {!this.state.isDeleting ?
          <button className="btn btn-danger float-left" onClick={(event) => this.setState({ isDeleting: true })}><i className="fa fa-trash"></i> {this.props.t("measure.client-item-4")}</button>
          :
          <div>
            <button className="btn btn-primary float-left" onClick={(event) => this.setState({ isDeleting: false, kmsSelected: [] })}><i className="fa fa-times"></i> {this.props.t("globals.cancel")}</button>
            <button className="btn btn-danger float-left" onClick={(event) => this.deleteKms(event)}><i className="fa fa-trash"></i> {this.props.t("globals.delete")}</button>
          </div>
        }
        <br /><br />
        <table className="table table-striped mb-0">
          <thead>
            <tr>
              <th></th>
              <th>{this.props.t("measure.client-item-5")}</th>
              <th>{this.props.t("measure.client-item-6")}</th>
              <th>{this.props.t("measure.client-item-7")}</th>
              <th>{this.props.t("measure.client-item-8")}</th>
              <th>{this.props.t("measure.client-item-9")}</th>
              <th>{this.props.t("measure.client-item-10")}</th>
              <th scope="col">{this.props.t("measure.client-item-11")}</th>
            </tr>
          </thead>
          <tbody>
            {
              result.map((fuel, i) => {
                return <tr id={fuel.id} key={i}>
                  <td>
                    {!!this.state.isDeleting ?
                      <input type="checkbox" value={fuel.id} onChange={(event) => this.checkRow(event)} />
                      : null
                    }
                  </td>
                  <td>{fuel.kilometers}</td>
                  <td>{fuel.fuel_type}</td>
                  <td>{fuel.fuel_quantity}</td>
                  <td>{!!fuel.fuel_efficiency ? Number.parseFloat(fuel.fuel_efficiency).toFixed(2) : null}</td>
                  <td>{moment.utc(fuel.fecha_inspeccion).local()
                    .locale("es").format("LLL")}</td>
                  <td>{moment.utc(fuel.fecha_subida).local()
                    .locale("es").format("LLL")}</td>
                  <MeasurementEdit
                    t={this.props.t}
                    type={this.props.type}
                    fuelId={fuel.id}
                    carNumber={this.props.carNumber}
                    object={this.getKmObject(fuel)}
                    authUser={authUser}
                    page={this.props.page}
                    limit={this.props.limit}
                    fuels={this.props.fuels}
                    setListGas={this.props.setListGas}
                    getLastFuelsView={this.getLastFuelsView}
                    onSubmitCar={(e) => this.props.onSubmitCar(e, this.props.page, this.props.limit)}
                  />
                </tr>
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="8">
                <nav aria-label="Navigation">
                  <ul class="mt-3 pagination justify-content-center">
                    <li class="page-item">
                      {
                        !!this.props.pageP ?
                          <button
                            className="page-link"
                            onClick={(e) => this.props.onSubmitCar(e, this.props.pageP, this.props.limit)}>
                            <i className="fa fa-arrow-left"></i> {this.props.pageP}
                          </button> :
                          <button
                            className="page-link"
                            disabled>
                            <i className="fa fa-arrow-left"></i> {this.props.pageP}
                          </button>
                      }
                    </li>
                    {
                      links.map(page => {
                        return <li class="page-item">
                          <button class="page-link" onClick={(e) => this.props.onSubmitCar(e, page, this.props.limit)}>{page}</button>
                        </li>
                      })
                    }
                    <li class="page-item">
                      {
                        !!this.props.pageN ?
                          <button
                            className="page-link"
                            onClick={(e) => this.props.onSubmitCar(e, this.props.pageN, this.props.limit)}>
                            <i className="fa fa-arrow-right"></i> {this.props.pageN}
                          </button> :
                          <button
                            className="page-link"
                            disabled>
                            <i className="fa fa-arrow-right"></i> {this.props.pageN}
                          </button>
                      }
                    </li>
                    <li className="page-item">
                      <div class="btn-group" role="group">
                        <button id="btnGroupDrop1" type="button" class="page-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {this.props.t("measure.client-item-12")} {this.state.limit} {this.props.t("measure.client-item-13")}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <button class="dropdown-item"
                            onClick={(e) => this.props.onSubmitCar(e, 1, 20)}>{this.props.t("measure.client-item-14")} 20</button>
                          <button class="dropdown-item"
                            onClick={(e) => this.props.onSubmitCar(e, 1, 50)}>{this.props.t("measure.client-item-14")} 50</button>
                          <button class="dropdown-item"
                            onClick={(e) => this.props.onSubmitCar(e, 1, 100)}>{this.props.t("measure.client-item-14")} 100</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  render() {
    const { fuels } = this.props;

    return (
      <>
        {fuels ? this.getLastFuelsView(fuels) : null}
      </>
    );
  }
};

export default LastFuelsInfo;
