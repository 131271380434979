import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import * as constantsPlan from "../../../constants/actions/Plans";

const initialState = {
  previus: "",
  next: "",
  total: "",
  planes: [],
  plan: {},
  message: null,
  error: null
};

function rootReducer(state, action) {
  switch (action.type) {
    case constantsPlan.GET_PLANS_SUCCESS:
      return {
        previus: action.json.previus,
        next: action.json.next,
        total: action.json.total,
        planes: action.json.planes,
      };
    case constantsPlan.GET_PLAN_SUCCESS:
      return {
        plan: action.json.plan
      };
    case constantsPlan.GET_REQUEST_SUCCESS:
      return {
        planes: action.json.planes,
        plan: action.json.plan,
        message: action.json.message
      };
    case constantsPlan.GET_REQUEST_ERROR:
      return {
        previus: action.json.previus,
        next: action.json.next,
        total: action.json.total,
        planes: action.json.planes,
        plan: action.json.plan,
        error: action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
