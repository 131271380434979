import React from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsBrand from "../../constants/actions/Brands";
import * as APIS from "../../constants/apis";
import LineNewItem from "./Lines/LineNewItem";
import LinesList from "./Lines/LinesList";

function getBrand(name, t) {
  let brand = {};
  let lines = [];
  return dispatch => {
    if (!localStorage.getItem("GlobalCustomerId")) return null;
    return trackPromise(fetch(`${APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.brands-item-1"));
        }
        return response.json();
      })
      .then(json => {
        brand = json;
        return fetch(APIS.API_REST_LINES.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("brand_id", brand.id), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.brands-item-3"));
        }
        return response.json();
      })
      .then(json => {
        lines = json.vehiclelinemodels;
        dispatch(getBrandSuccess({
          brand: brand,
          lines: lines
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  }
}

export function getBrandSuccess(json) {
  return {
    type: constantsBrand.GET_BRAND_SUCCESS,
    json
  }
}

export function getReqSuccess(brands, brand, message) {
  const json = {
    brands: brands,
    brand: brand,
    message: message
  };
  return {
    type: constantsBrand.GET_REQUEST_SUCCESS,
    json
  };
};

class BrandEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // code: ""
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getBrand(id, this.props.t);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.brand || (this.props.brand !== prevProps.brand)) {
      this.setState({
        name: this.props.brand.name,
        // code: this.props.brand.code
      });
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const { getReqSuccess, brand, brands } = this.props;

    const name = this.props.match.params.id;
    const data = {
      ...this.state,
    };

    return trackPromise(fetch(`${APIS.API_REST_BRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }, method: "PUT",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          this.props.history.push("/brands");
          getReqSuccess(brands, brand, this.props.t("alerts.brands-item-4"));
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  render() {
    const { brand, lines, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    $("input#nameInput").on({
      keydown: function (e) {
        if (e.which === 32)
          return false;
      },
      change: function () {
        this.value = this.value.replace(/\s/g, "");
      }
    });
    $("input#codeInput").on({
      keydown: function (e) {
        if (e.which === 32)
          return false;
      },
      change: function () {
        this.value = this.value.replace(/\s/g, "");
      }
    });
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!brand ?
            <div className="container-fluid mt-100">

              <h3>{this.props.t("brands.brands-edit")}</h3>
              <br /><br />
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="nameInput">{this.props.t("brands.brands-item-1")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("brands.brands-item-1")}
                        id="nameInput"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button type="submit"
                      className="btn btn-primary">
                      <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                  </div>
                </div>
              </form>
              <br />
              <h3> {this.props.t("brands.brands-lines")} </h3>
              <LineNewItem t={this.props.t}/>
              <br />
              <LinesList
                t={this.props.t}
                lines={lines}
                brand={brand}
              />
            </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  brands: state => state.brands,
  brand: state => state.brand,
  lines: state => state.lines,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getBrand, getReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(BrandEditItem));
