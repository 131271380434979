import React from 'react'
import { Link } from 'react-router-dom';
import { AuthUserContext, withAuthorization } from '../../Auth/Session';
import * as ROUTES from '../../../constants/routes'
import * as APIS from '../../../constants/apis'
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';

class MacroMacroActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    deleteMacro = (e, mName, mId) => {
        if(window.confirm(this.props.t("macros.m-item-38") + " " + mName)) {
            trackPromise(fetch(APIS.API_REST_MACRO_MACRO_ACCOUNT_DELETE.replace("macro_id", mId), {
                headers: {
                    "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                    "Accept-language": localStorage.getItem("LanguageSelected"),
                },
                method: "DELETE"
            })
            .then(response => response.json())
            .then(json => {
                if(json.message != "Eliminación exitosa") throw new Error(json.message)

                toast.success(this.props.t("macros.m-item-40"))
                this.props.updateMacro()
            })
            .catch(err => {
                toast.error(err.message)
            }))
        }
    }


    render() { 
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                <div className="d-flex justify-content-center">
                    <Link to={ROUTES.MACROS_MACRO_MACRO_ACCOUNT_EDIT.replace(":id", this.props.mId)} className="btn btn-primary mx-1"><i className="fa fa-edit"></i> {this.props.t("macros.m-item-41")}</Link>
                    <button className="btn btn-danger mx-1" onClick={(e) => this.deleteMacro(e, this.props.mName, this.props.mId)}><i className="fa fa-trash"></i> {this.props.t("macros.m-item-42")}</button>
                </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}
 
const condition = authUser => !!authUser;
export default withAuthorization(condition)(MacroMacroActions)