import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../Auth/Session";

import * as constantsCustomer from "../../constants/actions/Customers";
import * as APIS from "../../constants/apis";
import * as ROLES from "../../constants/roles";
import * as VARIABLES from "../../constants/variables";
import { Modal } from "react-bootstrap";
import moment from "moment";

function onSubmitCustomer(
  event,
  state,
  customers,
  customer,
  history,
  t,
  authUser
) {
  event.preventDefault();

  return (dispatch) => {
    const data = {
      ...state,
    };

    delete data.new_reseller;
    delete data.new_reseller_name;
    delete data.is_reseller;
    delete data.resellers;
    delete data.users;

    if (data.product === "Demo 2 meses" || data.product === "Demo 14 dias") {
      data.free_trial = data.free_trial + "T23:59:00Z";
    } else {
      data.free_trial = null;
    }

    trackPromise(
      fetch(`${APIS.API_REST_CUSTOMERS}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((response) => {
          // if(response.status !== 201) {
          //   throw new Error(t("alerts.customer-item-3"));
          // }
          return response.json();
        })
        .then((json) => {
          const status = json.message;
          if (!status) {
            runAddToNewCustomer(json, authUser, history, t);
            dispatch({ type: constantsCustomer.GET_CUSTOMERS_REQUEST });
            return fetch(APIS.API_REST_CUSTOMERS, {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            })
              .then((response) => response.json())
              .then((json) => {
                dispatch(
                  getReqSuccess(
                    customers,
                    customer,
                    t("alerts.customer-item-9")
                  )
                );
                dispatch(getCustomersSuccess(json));
              })
              .catch((error) => {
                history.push("/customers");
                dispatch(
                  getReqSuccess(
                    customers,
                    customer,
                    t("alerts.customer-item-9")
                  )
                );
                dispatch(getReqError(customers, customer, error.toString()));
              });
          } else {
            throw new Error(status);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  };
}

const runAddToNewCustomer = (json, authUser, history, t) => {
  if (authUser.roles === ROLES.ADMIN && authUser.restricted_admin) {
    if (window.confirm("¿ Deseas que esta flota se asocie a tu cuenta ?")) {
      trackPromise(
        fetch(`${APIS.API_REST_MULTI_USERS}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "POST",
          body: JSON.stringify({
            customer_id: json.id,
            user_id: authUser.id,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            let message = json.message;
            if (!message) {
              toast.success(t("alerts.users-item-11"));
              history.push("/customers");
            } else {
              toast.error(message);
            }
          })
          .catch((error) => {
            toast.error(error.toString());
          })
      );
    }
  } else {
    history.push("/customers");
    toast.success(t("alerts.customer-item-9"));
  }
};

export function getCustomersSuccess(json) {
  return {
    type: constantsCustomer.GET_CUSTOMERS_SUCCESS,
    json,
  };
}

export function getReqSuccess(customers, customer, message) {
  const json = {
    customers: customers,
    customer: customer,
    message: message,
  };
  return {
    type: constantsCustomer.GET_REQUEST_SUCCESS,
    json,
  };
}

export function getReqError(customers, customer, error) {
  const json = {
    customers: customers,
    customer: customer,
    error: error,
  };
  return {
    type: constantsCustomer.GET_REQUEST_ERROR,
    json,
  };
}

class CustomerNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      resellers: [],
      name: "",
      analista: "",
      max_hours: "",
      maxtravel: "",
      client_type: "",
      product: "",
      active: true,
      is_reseller: false,
      new_reseller: false,
      new_reseller_name: "",
      reseller_id: null,
      free_trial: null,
      account_manager: "",
      country: "",
      product_state: "",
    };
  }

  async componentDidMount() {
    let responseUsers = await fetch(
      `${APIS.API_REST_USERS_PER_ROL.replace("role_id", 3)}`,
      {
        headers: {
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
      }
    );
    let jsonUsers = await responseUsers.json();
    this.setState({ users: jsonUsers.Users });
    this.getResellers();
  }

  getResellers = async () => {
    let responseResellers = await fetch(`${APIS.API_REST_RESELLERS}`, {
      headers: {
        Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
    });
    let jsonResellers = await responseResellers.json();
    this.setState({ resellers: jsonResellers.reseller });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeReseller = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onChangeNewReseller = () => {
    this.setState({ new_reseller: true });
  };

  onChangeResellerId = (event) => {
    this.setState({ [event.target.name]: parseInt(event.target.value) });
  };

  onCreateReseller = (event) => {
    event.preventDefault();

    if (this.state.new_reseller_name != "") {
      trackPromise(
        fetch(`${APIS.API_REST_RESELLERS}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "POST",
          body: JSON.stringify({
            name: this.state.new_reseller_name,
          }),
        })
          .then((response) => {
            if (response.status != 201) {
              throw new Error("Error al crear Reseller");
            }
            return response.json();
          })
          .then((json) => {
            if (json.data) {
              toast.success(this.props.t("macros.m-item-36"));
              this.setState({ new_reseller: false, new_reseller_name: "" });
              this.getResellers();
            }
          })
          .catch((error) => {
            toast.error(error.toString());
          })
      );
    }
  };

  runValidationsCreate = (event, authUser) => {
    event.preventDefault();
    const { name, analista, max_hours, maxtravel, client_type, product } =
      this.state;
    const { customer, customers, history } = this.props;

    if (name === "") {
      return toast.warn(this.props.t("customers.customer-item-10"));
    } else if (max_hours === "") {
      return toast.warn(this.props.t("customers.customer-item-12"));
    } else if (maxtravel === "") {
      return toast.warn(this.props.t("customers.customer-item-13"));
    }

    if (max_hours <= 0 || max_hours > 24)
      return toast.error(this.props.t("customers.customer-item-16"));
    if (maxtravel <= 0 || maxtravel > 2000)
      return toast.error(this.props.t("customers.customer-item-17"));

    this.props.onSubmitCustomer(
      event,
      this.state,
      customers,
      customer,
      history,
      this.props.t,
      authUser
    );
  };

  modalNewReseller = () => {
    const handleClose = () =>
      this.setState({ new_reseller: false, new_reseller_name: "" });

    const onChangeName = (event) => {
      this.setState({ new_reseller_name: event.target.value });
    };

    return (
      <>
        <Modal
          show={this.state.new_reseller}
          onHide={handleClose}
          dialogClassName="modal-sm-mc"
        >
          <Modal.Header closeButton>
            <Modal.Title>Creacion de Reseller</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.onCreateReseller}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">Nombre</label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder="Nombre del reseller"
                      id="nameInput"
                      name="new_reseller_name"
                      value={this.state.new_reseller_name}
                      onChange={(e) => onChangeName(e)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  render() {
    const {
      name,
      maxtravel,
      max_hours,
      analista,
      users,
      is_reseller,
      resellers,
      reseller_id,
      free_trial,
      product,
      client_type,
      account_manager,
      country,
      product_state,
    } = this.state;

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("customers.customer-new")}</h3>
            <br />
            <br />
            <form
              onSubmit={(event) => this.runValidationsCreate(event, authUser)}
            >
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">
                      {this.props.t("customers.customer-new-name")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("customers.customer-new-name")}
                      id="nameInput"
                      name="name"
                      value={name}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="hoursInput">
                      {this.props.t("customers.customer-max-hour")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("customers.customer-max-hour")}
                      id="hoursInput"
                      name="max_hours"
                      type="number"
                      value={max_hours}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="travelInput">
                      {this.props.t("customers.customer-max-route")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("customers.customer-max-route")}
                      id="travelInput"
                      name="maxtravel"
                      type="number"
                      value={maxtravel}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group" id="groupCustomerId">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="customerIdInput">
                      {this.props.t("customers.customer-item-3")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      placeholder={this.props.t("customers.customer-rol")}
                      id="customerIdInput"
                      name="analista"
                      value={analista}
                      onChange={this.onChange}
                    >
                      <option value="">
                        {this.props.t("customers.customer-select-analist")}
                      </option>
                      <option value={null}>N/A</option>
                      {!!users &&
                        users.map((user) => {
                          return (
                            <option key={user.id} value={user.display_name}>
                              {user.display_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group" id="groupCustomerId">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="account_manager">
                      {this.props.t("customers.customer-item-24")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      placeholder={this.props.t(
                        "customers.customer-select-manager"
                      )}
                      id="account_manager"
                      name="account_manager"
                      value={account_manager}
                      onChange={this.onChange}
                    >
                      <option value="">
                        {this.props.t("customers.customer-select-manager")}
                      </option>
                      <option value={null}>N/A</option>
                      {VARIABLES.managerEmails.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group" id="groupCustomerId">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="customerIdInput">
                      {this.props.t("customers.customer-item-25")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      placeholder={this.props.t("customers.customer-item-25")}
                      id="customerIdInput"
                      name="country"
                      value={country}
                      onChange={this.onChange}
                    >
                      <option value="">
                        {this.props.t("customers.customer-select-country")}
                      </option>
                      <option value={null}>N/A</option>
                      {VARIABLES.COUNTRIES.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group" id="groupCustomerId">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="customerIdInput">
                      {this.props.t("customers.customer-item-4")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      placeholder={this.props.t("customers.customer-rol")}
                      id="customerIdInput"
                      name="client_type"
                      value={client_type}
                      onChange={this.onChange}
                    >
                      <option value="">
                        {this.props.t("customers.customer-select-type")}
                      </option>
                      <option value={null}>N/A</option>
                      {VARIABLES.CLIENT_TYPE.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group" id="groupCustomerId">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="customerIdInput">
                      {this.props.t("customers.customer-item-5")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      placeholder={this.props.t("customers.customer-rol")}
                      id="customerIdInput"
                      name="product"
                      value={product}
                      onChange={this.onChange}
                    >
                      <option value="">
                        {this.props.t("customers.customer-select-service")}
                      </option>
                      <option value={null}>N/A</option>
                      {VARIABLES.CLIENT_PRODUCT.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group" id="groupCustomerId">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="customerIdInput">
                      {this.props.t("customers.customer-item-26")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      placeholder={this.props.t("customers.customer-26")}
                      id="customerIdInput"
                      name="product_state"
                      value={product_state}
                      onChange={this.onChange}
                    >
                      <option value="">
                        {this.props.t(
                          "customers.customer-select-service-state"
                        )}
                      </option>
                      <option value={null}>N/A</option>
                      {VARIABLES.PRODUCT_STATE.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {product === "Demo 2 meses" || product === "Demo 14 dias" ? (
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="permissionIdInput">
                        Fecha de terminación demo
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        id="nameInput"
                        name="free_trial"
                        value={free_trial}
                        onChange={this.onChange}
                        type="date"
                        className="form-control"
                        placeholder="dias del trial"
                        required={
                          product === "Demo 2 meses" ||
                          product === "Demo 14 dias"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2 mt-4">
                    <label htmlFor="permissionIdInput">¿ Reseller ?</label>
                  </div>
                  <div className="col-sm-10 mt-4">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        name="is_reseller"
                        checked={is_reseller}
                        onChange={this.onChangeReseller}
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                      >
                        {this.props.t("users.users-yes-no")}{" "}
                        {is_reseller ? (
                          <a
                            onClick={this.onChangeNewReseller}
                            href="#"
                            className="ml-4"
                          >
                            ¿ No ves un reseller ? crea uno !
                          </a>
                        ) : null}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {is_reseller ? (
                <div className="form-group" id="groupCustomerId">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="customerIdInput">
                        {this.props.t("customers.customer-item-18")}
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <select
                        className="form-control"
                        placeholder={this.props.t("customers.customer-18")}
                        id="customerIdInput"
                        name="reseller_id"
                        value={this.state.reseller_id}
                        onChange={this.onChangeResellerId}
                      >
                        <option value="">
                          {this.props.t("customers.customer-item-19")}
                        </option>
                        {resellers.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
            {this.state.new_reseller ? this.modalNewReseller() : null}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const structuredSelector = createStructuredSelector({
  customers: (state) => state.customers,
  customer: (state) => state.customer,
});

const mapDispatchToProps = { onSubmitCustomer };

const condition = (authUser) =>
  !!authUser &&
  (authUser.roles === ROLES.ADMIN || authUser.roles === ROLES.ANALYST);

export default withAuthorization(condition)(
  connect(structuredSelector, mapDispatchToProps)(CustomerNewItem)
);
