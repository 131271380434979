import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsVehicleType from "../../constants/actions/VehicleTypes";
import * as APIS from "../../constants/apis";

function getVehicleType(name, t) {
  return dispatch => {
    if (!localStorage.getItem("GlobalCustomerId")) return null;
    return trackPromise(fetch(`${APIS.API_REST_TYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.v-type-item-1"));
        }
        return response.json();
      })
      .then(json => {
        dispatch(getVehicleTypeSuccess({
          vtype: json
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };
};

export function getVehicleTypeSuccess(json) {
  return {
    type: constantsVehicleType.GET_VTYPE_SUCCESS,
    json
  };
};

export function getReqSuccess(vtypes, vtype, message) {
  const json = {
    vtypes: vtypes,
    vtype: vtype,
    message: message
  };
  return {
    type: constantsVehicleType.GET_REQUEST_SUCCESS,
    json
  };
};

class VehicleTypeEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // code: ""
    };
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getVehicleType(id, this.props.t);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.vtype || (this.props.vtype !== prevProps.vtype)) {
      this.setState({
        name: this.props.vtype.name,
        // code: this.props.vtype.code
      });
    };
  };

  onSubmit = event => {
    event.preventDefault();
    const { getReqSuccess, vtype, vtypes } = this.props;

    const name = this.props.match.params.id;
    const data = {
      ...this.state,
    };

    return trackPromise(fetch(`${APIS.API_REST_TYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "PUT",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          this.props.history.push("/types");
          getReqSuccess(vtypes, vtype, this.props.t("alerts.v-type-item-4"));
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  render() {
    const { vtype, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!vtype ?
            <div className="container-fluid mt-100">

              <h3>{this.props.t("vehicles-type.type-edit")}</h3>
              <br /><br />
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="nameInput">{this.props.t("vehicles-type.type-item-1")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("vehicles-type.type-item-1")}
                        id="nameInput"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button type="submit"
                      className="btn btn-primary">
                      <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                  </div>
                </div>
              </form>
            </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  vtypes: state => state.vtypes,
  vtype: state => state.vtype,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getVehicleType, getReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(VehicleTypeEditItem));
