import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsVehicleType from "../../constants/actions/VehicleTypes";
import * as APIS from "../../constants/apis";

function deleteVehicleType(name, vtypes, vtype, t) {
  if (window.confirm(t("alerts.v-type-item-5"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_TYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "DELETE"
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(t("alerts.v-type-item-6"));
          }
          return response.json();
        })
        .then(json => {
          dispatch(getReqSuccess(vtypes, vtype, json.message));
          return fetch(APIS.API_REST_TYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          })
            .then(response => response.json())
            .then(json => {
              dispatch(getVehicleTypesSuccess({
                vtypes: json.vehicletypes
              }));
            })
            .catch(error => {
              dispatch(getReqError(vtypes, vtype, error.toString()));
            });
        })
        .catch(error => {
          dispatch(getReqError(vtypes, vtype, error.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(getVehicleTypesSuccess({
        vtypes: vtypes
      }));
    };
  }
};

export function getVehicleTypesSuccess(json) {
  return {
    type: constantsVehicleType.GET_VTYPES_SUCCESS,
    json
  };
};

export function getReqSuccess(vtypes, vtype, message) {
  const json = {
    vtypes: vtypes,
    vtype: vtype,
    message: message
  };
  return {
    type: constantsVehicleType.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(vtypes, vtype, error) {
  const json = {
    vtypes: vtypes,
    vtype: vtype,
    error: error
  };
  return {
    type: constantsVehicleType.GET_REQUEST_ERROR,
    json
  };
};

class VehicleTypeDeleteItem extends React.Component {
  render() {
    const { vtypes, vtype, vtypeDelete, deleteVehicleType } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteVehicleType(vtypeDelete.id,
          vtypes, vtype, this.props.t)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({
  vtypes: state => state.vtypes,
  vtype: state => state.vtype
});

const mapDispatchToProps = { deleteVehicleType };

export default connect(structuredSelector, mapDispatchToProps)(VehicleTypeDeleteItem);
