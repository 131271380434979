import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import TypeFormTire from '../../Inspection/Form/TypeFormTire'
import DropZone from '../DropZone'

const Inspections = ({ t, authUser, setShowModalMassive, showModalMassive }) => {
  const [state, setState] = useState({
    type: ""
  })

  const onChange = (event) => {
    let value = event.target.value
    if (event.target) {
      setState(state => ({
        ...state,
        type: value
      }))
    }
  }

  const getTypeHeader = (type) => {
    let elements = [];
    switch (type) {
      case "desmonte":
        elements = [
          t("multiple.ins-item-1"),
          t("multiple.ins-item-2"),
          t("multiple.ins-item-3"),
          t("multiple.ins-item-4"),
          t("multiple.ins-item-5"),
          t("multiple.ins-item-6"),
          t("multiple.ins-item-7"),
          t("multiple.ins-item-8"),
          t("multiple.ins-item-9"),
          t("multiple.ins-item-10"),
          t("multiple.ins-item-11"),
          t("multiple.ins-item-12"),
          t("multiple.ins-item-13")
        ];
        break;
      case "montaje":
        elements = [
          t("multiple.ins-item-1"),
          t("multiple.ins-item-2"),
          t("multiple.ins-item-3"),
          t("multiple.ins-item-4"),
          t("multiple.ins-item-14"),
          t("multiple.ins-item-6"),
          t("multiple.ins-item-7"),
          t("multiple.ins-item-8"),
          t("multiple.ins-item-9"),
          t("multiple.ins-item-10"),
          t("multiple.ins-item-11"),
          t("multiple.ins-item-12"),
          t("multiple.ins-item-13")
        ];
        break;
      case "inspeccion":
        elements = [
          t("multiple.ins-item-1"),
          t("multiple.ins-item-2"),
          t("multiple.ins-item-3"),
          t("multiple.ins-item-4"),
          t("multiple.ins-item-14"),
          t("multiple.ins-item-6"),
          t("multiple.ins-item-7"),
          t("multiple.ins-item-8"),
          t("multiple.ins-item-9"),
          t("multiple.ins-item-10"),
          t("multiple.ins-item-11"),
          t("multiple.ins-item-12"),
          t("multiple.ins-item-13")
        ];
        break;
      case "reencauche":
        elements = [
          t("multiple.ins-item-1"),
          t("multiple.ins-item-2"),
          t("multiple.ins-item-6"),
          t("multiple.ins-item-15"),
          t("multiple.ins-item-16"),
          t("multiple.ins-item-17"),
          t("multiple.ins-item-18"),
          t("multiple.ins-item-19"),
          t("multiple.ins-item-7"),
          t("multiple.ins-item-8"),
          t("multiple.ins-item-9"),
          t("multiple.ins-item-10"),
          t("multiple.ins-item-11"),
          t("multiple.ins-item-12"),
          t("multiple.ins-item-13"),
          t("multiple.ins-item-20"),
        ];
        break;
      case "regrabado":
        elements = [
          t("multiple.ins-item-1"),
          t("multiple.ins-item-2"),
          t("multiple.ins-item-6"),
          t("multiple.ins-item-15"),
          t("multiple.ins-item-7"),
          t("multiple.ins-item-8"),
          t("multiple.ins-item-9"),
          t("multiple.ins-item-10"),
          t("multiple.ins-item-11"),
          t("multiple.ins-item-12"),
          t("multiple.ins-item-21"),
          t("multiple.ins-item-22"),
          t("multiple.ins-item-13")
        ];
        break;
      default:
      // Do nothing
    };
    return elements
  }

  const modalSelect = () => {
    const handleClose = () => {
      setShowModalMassive(false)
    }

    return (
      <Modal show={showModalMassive} onHide={handleClose} dialogClassName="modal-sm-mc">
        <Modal.Header closeButton>
          <Modal.Title>Masivo Inspecciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='p-4'>
            <TypeFormTire isMultiple={true}
              type={state.type}
              t={t}
              onChange={onChange}
              delCreate={false}
              massiveInspections={true}
              authUser={authUser} />
          </div>
          {
            state.type != "" ?
              <div className='text-center'>
                <DropZone
                  t={t}
                  title="Masivo de inspecciones"
                  fields={getTypeHeader(state.type)}
                  type="inspection_massive"
                  type_str={state.type}
                  isSelector={true}
                />
              </div>
              :
              null
          }
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      {modalSelect()}
    </>
  )
}

export default Inspections