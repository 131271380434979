import { trackPromise } from 'react-promise-tracker'
import { toast } from 'react-toastify'
import api from '../../constants/api'
import { DATA_USERS_SUCCESS, DATA_USERS_ERROR, SET_LOADING, DATA_USERS_SEARCH_SUCCESS, DATA_USERS_SEARCH_CLEAR_SUCCESS, DATA_ROLES_SUCCESS, DATA_PERMISSION_SUCCESS, DATA_USER_SUCCESS, DATA_SET_USER_SUCCESS, REGISTER_USER_SUCCES, DATA_USER_PAGINATION_SUCCESS, DELETE_USER_SUCCESS } from '../types/UsersTypes'


export const getUsersAction = () => {
    return dispatch =>
        new Promise((resolve, reject) => {
            api
                .getUsers()
                .then((data) => {
                    if (data.Users) {
                        dispatch({
                            type: DATA_USERS_SUCCESS,
                            payload: data.Users
                        })
                        resolve(data.Users)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
}

export const getUsersPaginationAction = (str) => {
    return dispatch =>
        new Promise((resolve, reject) => {
            api
                .getUsersPagination(str)
                .then((data) => {
                    if (data.Users) {
                        dispatch({
                            type: DATA_USER_PAGINATION_SUCCESS,
                            payload: data
                        })
                        resolve(data)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
}

export const registerUsersAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: REGISTER_USER_SUCCES,
            payload: data
        })
    }
}
export const getUserAction = (id) => {
    return (dispatch) => {
        trackPromise(
            api
                .getUser(id)
                .then((data) => {
                    dispatch({
                        type: DATA_USER_SUCCESS,
                        payload: data
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        )
    }
}

export const getUsersSearchAction = (text, active) => {
    return (dispatch) => {
        api
            .getSearchUsers(text, active)
            .then((data) => {
                dispatch({
                    type: DATA_USERS_SEARCH_SUCCESS,
                    payload: data.Users
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }
}

export const getAllRoles = () => {
    return (dispatch) => {
        trackPromise(
            api
                .getRoles()
                .then((data) => {
                    dispatch({
                        type: DATA_ROLES_SUCCESS,
                        payload: data.Roles
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        )
    }
}

export const getAllPermission = () => {
    return (dispatch) => {
        trackPromise(
            api
                .getPermission()
                .then((data) => {
                    dispatch({
                        type: DATA_PERMISSION_SUCCESS,
                        payload: data.Permissions
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        )
    }
}

export const deleteUserAction = (id) => {
    return dispatch =>
        new Promise((resolve, reject) => {
            api
                .deleteUser(id)
                .then(res => {
                    if (res.message) {
                        dispatch({
                            type: DELETE_USER_SUCCESS,
                            payload: id
                        })
                        resolve(res)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
}

// export const setUserAction = (id, data, t, history) => {
//     return (dispatch) => {
//         trackPromise(
//             api
//                 .putUser(id, data)
//                 .then((data) => {
//                     const message = data.mesasge
//                     if (!message) {
//                         toast.success(t("alerts.users-item-11"));
//                         setTimeout(() => {
//                           history.push("/users") 
//                         }, 1000);
//                         dispatch({
//                             type: DATA_SET_USER_SUCCESS,
//                             payload: data
//                         })
//                     }
//                 })
//                 .catch((err) => {
//                     toast.error(err.toString())
//                 })
//         )
//     }
// }

export const setUsersSearchClearAction = () => {
    return (dispatch) => {
        dispatch({
            type: DATA_USERS_SEARCH_CLEAR_SUCCESS,
            payload: []
        })
    }
}

export const setUserLoadingAction = () => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADING,
            payload: true
        })
    }
}