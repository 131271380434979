import React from "react";
import "moment/locale/es";
import moment from "moment";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../../Auth/Session";
import Loading from "../../../Loader";
import {
  getTireBrandsCatalogueAction,
  getTireBrandsCatalogueMinAction,
} from "../../../../redux/actions/TireBrandsCatalogueAction";
import {
  getRetreadBrandsCatalogueAction,
  getRetreadBrandsCatalogueMinAction,
} from "../../../../redux/actions/RetreadBrandsCatalogueAction";
import { clearErrorAction } from "../../../../redux/actions/MapErrorsAction";
import { getWarehousesAction } from "../../../../redux/actions/TireWarehousesAction";
import API from "../../../../constants/api";
import * as ROUTES from "../../../../constants/routes";
const $ = require("jquery");

const getTireBrandsCatalogue = (customer_id) => {
  return (dispatch) => {
    dispatch(getTireBrandsCatalogueAction(customer_id));
  };
};

const getRetredBrandsCatalogue = (customer_id) => {
  return (dispatch) => {
    dispatch(getRetreadBrandsCatalogueAction(customer_id));
  };
};

const getTireCatalogueMin = (customer_id) => {
  return (dispatch) => {
    dispatch(getRetreadBrandsCatalogueMinAction(customer_id));
  };
};
const getRetreadCatalogueMin = (customer_id) => {
  return (dispatch) => {
    dispatch(getTireBrandsCatalogueMinAction(customer_id));
  };
};

const getWarehouses = (customer_id) => {
  return (dispatch) => {
    dispatch(getWarehousesAction(customer_id));
  };
};

const clearError = () => {
  return (dispatch) => {
    dispatch(clearErrorAction());
  };
};

class TireNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      // isDataLoading: 0,

      // data
      tbds: this.props.tbds,
      rbds: this.props.rbds,
      tctgs: this.props.tctgs,
      rctgs: this.props.rctgs,
      warehouses: this.props.warehouses,

      Atctgs: [],
      Arctgs: [],
      modelsA: [],
      dimentionsA: [],
      bandsA: [],
      retreadDimentionsA: [],
      customer_id: localStorage.getItem("GlobalCustomerId"),
      codigo: null,
      marca_id: null,
      modelo_id: null,
      dimension_id: null,
      prof_original: null,
      prof_minima: null,
      retreadband_id: null,
      retreadbrand_id: null,
      retreadimention_id: null,
      tirecatalogue_id: null,
      retreadcatalogue_id: null,
      bodega_id: null,
      vida: null,
      DOT: null,
      precio_original: null,
      precio_reencauche: null,
      precio_regrabado: null,
      regrabada: false,
      precioOriginal: false,
      precioReencauche: false,
      fc_ultima_inspeccion: null,
      ult_prof_interior: null,
      ult_prof_centro: null,
      ult_prof_centro_exterior: null,
      regrabado: 0,
      order: "",
      new: true,
    };
  }

  componentDidMount() {
    const { customer_id, tbds, rbds, warehouses, tctgs, rctgs } = this.state;
    if (
      tbds.length > 0 &&
      warehouses.length > 0 &&
      // rbds.length > 0 &&
      tctgs.length > 0
      // rctgs.length > 0
    ) {
      this.setState({ loading: false });
    } else {
      if (tbds.length === 0) {
        this.props.getTireBrandsCatalogue(customer_id);
      }
      if (rbds.length === 0) {
        this.props.getRetredBrandsCatalogue(customer_id);
      }
      if (warehouses.length === 0) {
        this.props.getWarehouses(customer_id);
      }
      if (tctgs.length === 0) {
        this.props.getTireCatalogueMin(customer_id);
      }
      if (rctgs.length === 0) {
        this.props.getRetreadCatalogueMin(customer_id);
      }
    }
  }

  componentDidUpdate(preProps) {
    if (
      preProps.tbds.length !== this.props.tbds.length &&
      this.state.tbds.length === 0
    ) {
      this.setState({ loading: true });
      if (this.props.tbds.length > 0) {
        this.setState({
          tbds: this.props.tbds,
          loading: false,
        });
      }
    }

    if (
      preProps.rbds.length !== this.props.rbds.length &&
      this.state.rbds.length === 0
    ) {
      this.setState({ loading: true });
      if (this.props.rbds.length > 0) {
        this.setState({
          rbds: this.props.rbds,
          loading: false,
        });
      }
    }

    if (
      preProps.warehouses.length !== this.props.warehouses.length &&
      this.state.warehouses.length === 0
    ) {
      this.setState({ loading: true });
      if (this.props.warehouses.length > 0) {
        this.setState({
          warehouses: this.props.warehouses,
          loading: false,
        });
      }
    }

    if (
      preProps.rctgs.length !== this.props.rctgs.length &&
      this.state.rctgs.length === 0
    ) {
      this.setState({ loading: true });
      if (this.props.rctgs.length > 0) {
        this.setState({
          rctgs: this.props.rctgs,
          loading: false,
        });
      }
    }

    if (
      preProps.tctgs.length !== this.props.tctgs.length &&
      this.state.tctgs.length === 0
    ) {
      this.setState({ loading: true });
      if (this.props.tctgs.length > 0) {
        this.setState({
          tctgs: this.props.tctgs,
          loading: false,
        });
      }
    }

    if (this.props.error && this.state.error === null) {
      this.setState({ error: this.props.error, loading: false });
      this.errorToast(this.props.error);
    }
  }

  errorToast = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
      this.setState({ error: null });
      this.props.clearError();
    }
    this.props.getTireLists(this.props.t);
  };

  showInputs = (event) => {
    document.getElementById("groupProfExt").style.display = "block";
    document.getElementById("groupProfCent").style.display = "block";
    document.getElementById("groupProfInt").style.display = "block";
    this.setState((state) => ({
      ...state,
      new: false,
      regrabada: false,
      precio_regrabado: null,
    }));
    // let arr = Object.values(document.getElementById("warehouseInput").options);
    // let arr = this.state.warehouses.find(
    //   (element) => element.name === "1/2 vida"
    // );
    // this.setState({
    //   bodega_id: arr.id,
    // });
  };

  hideInputs = (event) => {
    document.getElementById("groupProfExt").style.display = "none";
    document.getElementById("groupProfCent").style.display = "none";
    document.getElementById("groupProfInt").style.display = "none";
    let arr = this.state.warehouses.find(
      (element) => element.name === "llanta_nueva"
    );
    this.setState({
      bodega_id: arr.id,
      new: true,
      regrabada: false,
      precio_regrabado: null,
    });
  };

  formatJson = (data) => {
    for (let key in data) {
      if (!data[key] && data[key] !== 0) {
        data[key] = null;
      }
      if (!!data[key] && !isNaN(data[key]) && key !== "codigo") {
        data[key] = 1 * data[key];
      }
    }
    return data;
  };

  onChangeCheckbox = (event) => {
    let arrTctgs = this.props.tctgs;
    let arrRctgs = this.props.rctgs;
    if (event.target.name === "precioOriginalCheck") {
      if (event.target.checked) {
        var arra = arrTctgs.filter(
          (arr) => arr.id == this.state.tirecatalogue_id
        );
        if (arra.length > 0) {
          this.setState({ precio_original: arra[0].precio });
        }
      } else {
        this.setState({ precio_original: "" });
      }
    }
    if (event.target.name === "precioReencaucheCheck") {
      if (event.target.checked) {
        var arra1 = arrRctgs.filter(
          (arr) => arr.id == this.state.retreadcatalogue_id
        );
        if (arra1.length > 0) {
          this.setState({ precio_reencauche: arra1[0].precio });
        }
      } else {
        this.setState({ precio_reencauche: "" });
      }
    }
    this.setState({ [event.target.name]: event.target.checked });
  };

  onChange = (event) => {
    let arrTctgs = this.props.tctgs;
    let arrRctgs = this.props.rctgs;
    if (
      (event.target.name === "marca_id" ||
        event.target.name === "modelo_id" ||
        event.target.name === "dimension_id" ||
        event.target.name === "retreadbrand_id" ||
        event.target.name === "retreadband_id" ||
        event.target.name === "retreadimention_id" ||
        event.target.name === "tirecatalogue_id" ||
        event.target.name === "retreadcatalogue_id") &&
      event.target.value !== this.state[event.target.name]
    ) {
      if (event.target.name === "marca_id") {
        this.setState({
          marca_id: event.target.value,
          modelo_id: "",
          dimension_id: "",
        });
        if (event.target.value != "") {
          this.setState(
            {
              Atctgs: arrTctgs.filter(
                (arr) => arr.marca_id == event.target.value
              ),
            },
            () => $("#catTireInput").selectpicker("refresh")
          );
          this.setState(
            {
              modelsA: this.props.tbds.filter((brand) => {
                return brand.id === 1 * event.target.value;
              })[0].tiremodels,
            },
            () => $("#modeloInput").selectpicker("refresh")
          );
        } else {
          this.setState({ Atctgs: [] }, () =>
            $("#catTireInput").selectpicker("refresh")
          );
          this.setState({ modelsA: [] }, () =>
            $("#modeloInput").selectpicker("refresh")
          );
        }
        this.setState({ dimentionsA: [] }, () =>
          $("#dimensionInput").selectpicker("refresh")
        );
      }
      if (event.target.name === "modelo_id") {
        this.setState({
          modelo_id: event.target.value,
          dimension_id: "",
        });
        if (event.target.value != "") {
          this.setState(
            {
              Atctgs: arrTctgs.filter(
                (arr) => arr.modelo_id == event.target.value
              ),
            },
            () => $("#catTireInput").selectpicker("refresh")
          );
          this.setState(
            {
              dimentionsA: this.props.tbds
                .filter((brand) => {
                  return brand.id === 1 * this.state.marca_id;
                })[0]
                .tiremodels.filter((model) => {
                  return model.id === 1 * event.target.value;
                })[0].tiredimentions,
            },
            () => $("#dimensionInput").selectpicker("refresh")
          );
        } else {
          this.setState(
            {
              Atctgs: arrTctgs.filter(
                (arr) => arr.marca_id == this.state.marca_id
              ),
            },
            () => $("#catTireInput").selectpicker("refresh")
          );
          this.setState({ dimentionsA: [] }, () =>
            $("#dimensionInput").selectpicker("refresh")
          );
        }
      }
      if (event.target.name === "dimension_id") {
        this.setState({
          dimension_id: event.target.value,
        });
        if (event.target.value != "") {
          this.setState(
            {
              Atctgs: arrTctgs.filter(
                (arr) => arr.dimension_id == event.target.value
              ),
            },
            () => $("#catTireInput").selectpicker("refresh")
          );
        } else {
          this.setState(
            {
              Atctgs: arrTctgs.filter(
                (arr) => arr.modelo_id == this.state.modelo_id
              ),
            },
            () => $("#catTireInput").selectpicker("refresh")
          );
        }
      }
      if (event.target.name === "retreadbrand_id") {
        this.setState({
          retreadbrand_id: event.target.value,
          retreadband_id: "",
          retreadimention_id: "",
        });
        if (event.target.value != "") {
          this.setState(
            {
              Arctgs: arrRctgs.filter(
                (arr) => arr.marca_id == event.target.value
              ),
            },
            () => $("#catRetreadInput").selectpicker("refresh")
          );
          this.setState(
            {
              bandsA: this.props.rbds.filter((brand) => {
                return brand.id === 1 * event.target.value;
              })[0].retreadbands,
            },
            () => $("#retreadbandInput").selectpicker("refresh")
          );
        } else {
          this.setState({ Arctgs: [] }, () =>
            $("#catRetreadInput").selectpicker("refresh")
          );
          this.setState({ bandsA: [] }, () =>
            $("#retreadbandInput").selectpicker("refresh")
          );
        }
        this.setState({ retreadDimentionsA: [] }, () =>
          $("#retreadimentionInput").selectpicker("refresh")
        );
      }
      if (event.target.name === "retreadband_id") {
        this.setState({
          retreadband_id: event.target.value,
          retreadimention_id: "",
        });
        if (event.target.value != "") {
          this.setState(
            {
              Arctgs: arrRctgs.filter(
                (arr) => arr.modelo_id == event.target.value
              ),
            },
            () => $("#catRetreadInput").selectpicker("refresh")
          );
          this.setState(
            {
              retreadDimentionsA: this.props.rbds
                .filter((brand) => {
                  return brand.id === 1 * this.state.retreadbrand_id;
                })[0]
                .retreadbands.filter((band) => {
                  return band.id === 1 * event.target.value;
                })[0].retreadimentions,
            },
            () => $("#retreadimentionInput").selectpicker("refresh")
          );
        } else {
          this.setState(
            {
              Arctgs: arrRctgs.filter(
                (arr) => arr.marca_id == this.state.retreadbrand_id
              ),
            },
            () => $("#catRetreadInput").selectpicker("refresh")
          );
          this.setState({ retreadDimentionsA: [] }, () =>
            $("#retreadimentionInput").selectpicker("refresh")
          );
        }
      }
      if (event.target.name === "retreadimention_id") {
        this.setState({
          retreadimention_id: event.target.value,
        });
        if (event.target.value != "") {
          this.setState(
            {
              Arctgs: arrRctgs.filter(
                (arr) => arr.dimension_id == event.target.value
              ),
            },
            () => $("#catRetreadInput").selectpicker("refresh")
          );
        } else {
          this.setState(
            {
              Arctgs: arrRctgs.filter(
                (arr) => arr.modelo_id == this.state.retreadband_id
              ),
            },
            () => $("#catRetreadInput").selectpicker("refresh")
          );
        }
      }
      var p_ori = "";
      var p_min = "";
      var p_ori1 = "";
      var p_min1 = "";
      var arr_2;
      var arr1_2;
      if (event.target.name === "tirecatalogue_id") {
        arr_2 = this.state.Atctgs.filter((arr) => arr.id == event.target.value);
        if (arr_2.length > 0) {
          p_ori = arr_2[0].prof_original;
          p_min = arr_2[0].prof_minima;
        }
        this.setState({
          tirecatalogue_id: event.target.value,
          prof_original: p_ori,
          prof_minima: p_min,
        });
      }
      if (event.target.name === "retreadcatalogue_id") {
        arr1_2 = this.state.Arctgs.filter(
          (arr1) => arr1.id == event.target.value
        );
        if (arr1_2.length > 0) {
          p_ori1 = arr1_2[0].profundidad_original;
          p_min1 = arr1_2[0].profundidad_minima;
        }
        this.setState({
          retreadcatalogue_id: event.target.value,
          prof_original: p_ori1,
          prof_minima: p_min1,
        });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    const data = { ...this.state };
    if (data.regrabada) {
      data.regrabado = 1;
    }
    data.fc_ultima_inspeccion = moment(data.fc_ultima_inspeccion)
      .utc()
      .format();
    trackPromise(
      API.registerTires(this.state.customer_id, data)
        .then((res) => {
          this.props.history.push(ROUTES.TIRES);
          toast.success(this.props.t("macros.m-item-36"));
        })
        .catch((error) => {
          if (error.response.status !== 201) {
            toast.error(error.response.data.message.toString());
          }
        })
    );
  };

  render() {
    const { tbds, warehouses, rbds } = this.state;
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            {this.state.loading ? (
              <Loading />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-8">
                    <h3>{this.props.t("client-tires.client-new")}</h3>
                  </div>
                </div>
                <br />
                <br />
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="codigoInput">
                          {this.props.t("client-tires.client-item-27")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          placeholder="#"
                          id="orderInput"
                          name="order"
                          value={this.state.order}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="codigoInput">
                          {this.props.t("client-tires.client-item-6")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          placeholder={this.props.t("globals.code")}
                          id="codigoInput"
                          name="codigo"
                          value={this.state.codigo}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="dotInput">
                          {this.props.t("client-tires.client-item-16")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-item-16"
                          )}
                          id="dotInput"
                          name="DOT"
                          value={this.state.DOT}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "none" }} className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="profOrInput">
                          {this.props.t("client-tires.client-item-17")}
                        </label>
                        <br></br>
                        <i className="text-info small">
                          {this.props.t("globals.required")}
                        </i>
                      </div>
                      <div className="col-sm-10">
                        {this.state.tirecatalogue_id ||
                        this.state.retreadcatalogue_id ? (
                          <input
                            className="form-control"
                            placeholder={this.props.t(
                              "client-tires.client-item-17"
                            )}
                            id="profOrInput"
                            name="prof_original"
                            value={this.state.prof_original}
                            readOnly={true}
                            required
                          />
                        ) : (
                          <input
                            className="form-control"
                            placeholder={this.props.t(
                              "client-tires.client-item-17"
                            )}
                            id="profOrInput"
                            name="prof_original"
                            value={this.state.prof_original}
                            onChange={this.onChange}
                            required
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "none" }} className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="profMinInput">
                          {this.props.t("client-tires.client-item-18")}
                        </label>
                        <br></br>
                        <i className="text-info small">
                          {this.props.t("globals.required")}
                        </i>
                      </div>
                      <div className="col-sm-10">
                        {this.state.tirecatalogue_id ||
                        this.state.retreadcatalogue_id ? (
                          <input
                            className="form-control"
                            placeholder={this.props.t(
                              "client-tires.client-item-18"
                            )}
                            id="profMinInput"
                            name="prof_minima"
                            value={this.state.prof_minima}
                            readOnly={true}
                            required
                          />
                        ) : (
                          <input
                            className="form-control"
                            placeholder={this.props.t(
                              "client-tires.client-item-18"
                            )}
                            id="profMinInput"
                            name="prof_minima"
                            value={this.state.prof_minima}
                            onChange={this.onChange}
                            required
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="fcUltimaInspeccion">
                          {this.props.t("client-tires.client-item-19")}
                        </label>
                        <br></br>
                        <i className="text-info small">
                          {this.props.t("globals.required")}
                        </i>
                      </div>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-item-19"
                          )}
                          id="fcUltimaInspeccion"
                          name="fc_ultima_inspeccion"
                          value={this.state.fc_ultima_inspeccion}
                          type="datetime-local"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <h2 className="col-sm-2"></h2>
                      <div className="col-sm-10 ">
                        <div
                          className="btn-group btn-group-toggle"
                          data-toggle="buttons"
                        >
                          <label
                            className="btn btn-primary active"
                            onClick={this.hideInputs}
                          >
                            <input
                              type="radio"
                              name="useTire"
                              value={true}
                              autoComplete="off"
                            />
                            {this.props.t("client-tires.client-use-1")}
                          </label>
                          <label
                            className="btn btn-primary"
                            onClick={this.showInputs}
                          >
                            <input
                              type="radio"
                              name="useTire"
                              value={false}
                              autoComplete="off"
                            />{" "}
                            {this.props.t("client-tires.client-use-2")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "none" }}
                    id="groupProfExt"
                    className="form-group"
                  >
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="ultProfInterior">
                          {this.props.t("client-tires.client-item-20")}
                        </label>
                        <br></br>
                        <i className="text-info small">
                          {this.props.t("globals.required")}
                        </i>
                      </div>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-item-20"
                          )}
                          id="groupProfExt"
                          name="ult_prof_centro_exterior"
                          value={this.state.ult_prof_centro_exterior}
                          type="number"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "none" }}
                    id="groupProfCent"
                    className="form-group"
                  >
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="ultProfCentro">
                          {this.props.t("client-tires.client-item-21")}
                        </label>
                        <br></br>
                        <i className="text-info small">
                          {this.props.t("globals.required")}
                        </i>
                      </div>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-item-21"
                          )}
                          id="ultProfCentro"
                          name="ult_prof_centro"
                          value={this.state.ult_prof_centro}
                          type="number"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "none" }}
                    id="groupProfInt"
                    className="form-group"
                  >
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="ultProfInterior">
                          {this.props.t("client-tires.client-item-22")}
                        </label>
                        <br></br>
                        <i className="text-info small">
                          {this.props.t("globals.required")}
                        </i>
                      </div>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-item-22"
                          )}
                          id="ultProfInterior"
                          name="ult_prof_interior"
                          value={this.state.ult_prof_interior}
                          type="number"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="warehouseInput">
                          {this.props.t("client-tires.client-item-23")}
                        </label>
                        <br></br>
                        <i className="text-info small">
                          {this.props.t("globals.required")}
                        </i>
                      </div>
                      <div className="col-sm-10">
                        <select
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-item-23"
                          )}
                          id="warehouseInput"
                          name="bodega_id"
                          value={this.state.bodega_id}
                          onChange={this.onChange}
                          required
                        >
                          <option value="">
                            {this.props.t(
                              "client-tires.client-select-ubication"
                            )}
                          </option>
                          {!!warehouses &&
                            warehouses.map((warehouse) => {
                              if (warehouse.name !== "descarte") {
                                return (
                                  <option
                                    key={warehouse.id}
                                    value={warehouse.id}
                                  >
                                    {warehouse.name}
                                  </option>
                                );
                              }
                              return null;
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h3>{this.props.t("client-tires.client-tire-info")}</h3>
                  <br />
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="marcaInput">
                          {this.props.t("client-tires.client-item-7")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <select
                          data-live-search="true"
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-db-text"
                          )}
                          id="marcaInput"
                          name="marca_id"
                          value={this.state.marca_id}
                          onChange={this.onChange}
                          required
                        >
                          <option value="">
                            {this.props.t("client-tires.client-select-brand")}
                          </option>
                          {!!tbds &&
                            tbds.map((brand) => {
                              return (
                                <option
                                  data-tokens={brand.name}
                                  key={brand.id}
                                  value={brand.id}
                                >
                                  {brand.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="modeloInput">
                          {this.props.t("client-tires.client-item-8")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <select
                          data-live-search="true"
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-model-text"
                          )}
                          id="modeloInput"
                          name="modelo_id"
                          value={this.state.modelo_id}
                          onChange={this.onChange}
                          required
                        >
                          <option value="">
                            {this.props.t("client-tires.client-select-model")}
                          </option>
                          {
                            //!!this.state.marca_id && !!tbds && tbds.filter(brand => {
                            this.state.modelsA.map((model) => {
                              return (
                                <option
                                  data-tokens={model.name}
                                  key={model.id}
                                  value={model.id}
                                >
                                  {model.name}
                                </option>
                              );
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="dimensionInput">
                          {this.props.t("client-tires.client-item-9")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <select
                          data-live-search="true"
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-dimention-text"
                          )}
                          id="dimensionInput"
                          name="dimension_id"
                          value={this.state.dimension_id}
                          onChange={this.onChange}
                        >
                          <option value="">
                            {this.props.t(
                              "client-tires.client-select-dimention"
                            )}
                          </option>
                          {this.state.dimentionsA.map((d) => {
                            return (
                              <option
                                data-tokens={d.dimention}
                                key={d.id}
                                value={d.id}
                              >
                                {d.dimention}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="catTireInput">
                          {this.props.t("client-tires.client-item-24")}
                        </label>
                        <br></br>
                        <i className="text-info small">
                          {this.props.t("globals.required")}
                        </i>
                      </div>
                      <div className="col-sm-10">
                        <select
                          data-live-search="true"
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-catalogue-text"
                          )}
                          id="catTireInput"
                          name="tirecatalogue_id"
                          value={this.state.tirecatalogue_id}
                          onChange={this.onChange}
                        >
                          <option value="">
                            {this.props.t(
                              "client-tires.client-select-catalogue"
                            )}
                          </option>
                          {this.state.Atctgs.map((cat) => {
                            return (
                              <option
                                data-tokens={
                                  cat.marca +
                                  " " +
                                  cat.modelo +
                                  " " +
                                  cat.dimention +
                                  " Proveedor: " +
                                  cat.distribuidor
                                }
                                key={cat.id}
                                value={cat.id}
                              >
                                {cat.marca +
                                  " " +
                                  cat.modelo +
                                  " " +
                                  cat.dimention +
                                  " "}
                                &bull; {"Proveedor: " + cat.distribuidor + " "}
                                &bull; {"Precio: " + cat.precio}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="precioOriginalCheck">
                          {this.props.t("client-tires.client-catalogue-equals")}
                        </label>
                      </div>
                      <div className="col-sm-10">
                        <input
                          id="precioOriginalCheck"
                          name="precioOriginalCheck"
                          type="checkbox"
                          checked={this.state.precioOriginalCheck}
                          onChange={this.onChangeCheckbox}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <label htmlFor="precioOriginal">
                          {this.props.t("client-tires.client-item-11")}
                        </label>
                        <br></br>
                        <i className="text-info small">
                          {this.props.t("globals.required")}
                        </i>
                      </div>
                      <div className="col-sm-10">
                        <input
                          className="form-control"
                          placeholder={this.props.t(
                            "client-tires.client-item-11"
                          )}
                          id="precioOriginal"
                          name="precio_original"
                          value={this.state.precio_original}
                          type="number"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h3>{this.props.t("client-tires.client-info-retread")}</h3>
                  <br />
                  {rbds.length > 0 ? (
                    <>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label htmlFor="retreadbrandInput">
                              {this.props.t("client-tires.client-item-7")}
                            </label>
                          </div>
                          <div className="col-sm-10">
                            <select
                              data-live-search="true"
                              className="form-control"
                              placeholder={this.props.t(
                                "client-tires.client-brand-text"
                              )}
                              id="retreadbrandInput"
                              name="retreadbrand_id"
                              value={this.state.retreadbrand_id}
                              onChange={this.onChange}
                            >
                              <option value="">
                                {this.props.t(
                                  "client-tires.client-select-retread"
                                )}
                              </option>
                              {!!rbds &&
                                rbds.map((brand) => {
                                  return (
                                    <option
                                      data-tokens={brand.name}
                                      key={brand.id}
                                      value={brand.id}
                                    >
                                      {brand.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label htmlFor="retreadbandInput">
                              {this.props.t("client-tires.client-item-10")}
                            </label>
                          </div>
                          <div className="col-sm-10">
                            <select
                              data-live-search="true"
                              className="form-control"
                              placeholder="Banda de la llanta"
                              id="retreadbandInput"
                              name="retreadband_id"
                              value={this.state.retreadband_id}
                              onChange={this.onChange}
                            >
                              <option value="">
                                {this.props.t(
                                  "client-tires.client-select-band"
                                )}
                              </option>
                              {this.state.bandsA.map((band) => {
                                return (
                                  <option
                                    data-tokens={band.name}
                                    key={band.id}
                                    value={band.id}
                                  >
                                    {band.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label htmlFor="retreadimentionInput">
                              {this.props.t("client-tires.client-item-9")}
                            </label>
                          </div>
                          <div className="col-sm-10">
                            <select
                              data-live-search="true"
                              className="form-control"
                              placeholder="Dimension reencauche de la llanta"
                              id="retreadimentionInput"
                              name="retreadimention_id"
                              value={this.state.retreadimention_id}
                              onChange={this.onChange}
                            >
                              <option value="">
                                {this.props.t(
                                  "client-tires.client-select-r-dimention"
                                )}
                              </option>
                              {this.state.retreadDimentionsA.map((d) => {
                                return (
                                  <option
                                    data-tokens={d.dimention}
                                    key={d.id}
                                    value={d.id}
                                  >
                                    {d.dimention}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label htmlFor="catRetreadInput">
                              {this.props.t("client-tires.client-item-24")}
                            </label>
                            <br></br>
                            <i className="text-info small">
                              {this.props.t("globals.required")}{" "}
                              {this.props.t(
                                "client-tires.client-catalogue-required"
                              )}
                            </i>
                          </div>
                          <div className="col-sm-10">
                            <select
                              data-live-search="true"
                              className="form-control"
                              placeholder="Catálogo reencauche de la llanta"
                              id="catRetreadInput"
                              name="retreadcatalogue_id"
                              value={this.state.retreadcatalogue_id}
                              onChange={this.onChange}
                            >
                              <option value="">
                                {this.props.t(
                                  "client-tires.client-select-r-catalogue"
                                )}
                              </option>
                              {this.state.Arctgs.map((cat) => {
                                return (
                                  <option
                                    data-tokens={
                                      cat.modelo +
                                      " " +
                                      cat.dimention +
                                      " Proveedor: " +
                                      cat.distribuidor
                                    }
                                    key={cat.id}
                                    value={cat.id}
                                  >
                                    {cat.modelo + " " + cat.dimention + " "}
                                    &bull;{" "}
                                    {"Proveedor: " + cat.distribuidor + " "}
                                    &bull; {"Precio: " + cat.precio}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label htmlFor="vidaInput">
                              {this.props.t("client-tires.client-item-26")}
                            </label>
                            <br></br>
                            <i className="text-info small">
                              {this.props.t("globals.required")}{" "}
                              {this.props.t(
                                "client-tires.client-catalogue-required"
                              )}
                            </i>
                          </div>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              placeholder={this.props.t(
                                "client-tires.client-item-26"
                              )}
                              id="vidaInput"
                              name="vida"
                              value={this.state.vida}
                              type="number"
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label htmlFor="precioReencaucheCheck">
                              {this.props.t(
                                "client-tires.client-catalogue-equals"
                              )}
                            </label>
                          </div>
                          <div className="col-sm-10">
                            <input
                              id="precioReencaucheCheck"
                              name="precioReencaucheCheck"
                              type="checkbox"
                              checked={this.state.precioReencaucheCheck}
                              onChange={this.onChangeCheckbox}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label htmlFor="precioReencauche">
                              {this.props.t("client-tires.client-item-11")}
                            </label>
                            <br></br>
                            <i className="text-info small">
                              {this.props.t("globals.required")}{" "}
                              {this.props.t(
                                "client-tires.client-catalogue-required"
                              )}
                            </i>
                          </div>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              placeholder={this.props.t(
                                "client-tires.client-item-11"
                              )}
                              id="precioReencauche"
                              name="precio_reencauche"
                              value={this.state.precio_reencauche}
                              type="number"
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>{this.props.t("client-tires.client-not-retread")}</p>
                  )}

                  {!this.state.new && (
                    <>
                      <hr />
                      <h3>
                        {this.props.t("client-tires.client-info-recorder")}
                      </h3>
                      <br />
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-2">
                            <label htmlFor="regrabadaInput">
                              {this.props.t("client-tires.client-is-recorder")}
                            </label>
                          </div>
                          <div className="col-sm-10">
                            <input
                              id="regrabadaInput"
                              name="regrabada"
                              type="checkbox"
                              checked={this.state.regrabada}
                              onChange={this.onChangeCheckbox}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {!!this.state.regrabada ? (
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-2">
                          <label htmlFor="precioRegrabado">
                            {this.props.t("client-tires.client-item-11")}
                          </label>
                          <br></br>
                          <i className="text-info small">
                            {this.props.t("globals.required")}{" "}
                            {this.props.t(
                              "client-tires.client-catalogue-recorder"
                            )}
                          </i>
                        </div>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            placeholder={this.props.t(
                              "client-tires.client-item-11"
                            )}
                            id="precioRegrabado"
                            name="precio_regrabado"
                            value={this.state.precio_regrabado}
                            type="number"
                            onChange={this.onChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-save"></i>{" "}
                        {this.props.t("globals.simple-save")}
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const structuredSelector = createStructuredSelector({
  tbds: (state) => state.tireBrandsCatalogue.tireBrandsCatalogue,
  tctgs: (state) => state.tireBrandsCatalogue.tireBrandsCatalogueMin,
  rbds: (state) => state.retreadBrandsCatalogue.retreadBrandsCatalogue,
  rctgs: (state) => state.retreadBrandsCatalogue.retreadBrandsCatalogueMin,
  warehouses: (state) => state.warehouses.warehouses,
  error: (state) => state.error.error,
});

const mapDispatchToProps = {
  getTireBrandsCatalogue,
  getRetredBrandsCatalogue,
  clearError,
  getWarehouses,
  getTireCatalogueMin,
  getRetreadCatalogueMin,
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(
  connect(structuredSelector, mapDispatchToProps)(TireNewItem)
);
