import React from "react";
import { memo } from "react";
import { withFirebase } from "../../Auth/Firebase";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { validateMetaLanguage } from "../../../utils/validateMetaLanguage";
import { useTranslation } from "react-i18next";

const imgs = {
  initial: {
    es: "https://jira-emails.s3.amazonaws.com/payments/payment_10_es.jpg",
    pt: "https://jira-emails.s3.amazonaws.com/payments/payment_10_pt.jpg",
  },
  halft: {
    es: "https://jira-emails.s3.amazonaws.com/payments/payment_5_es.jpg",
    pt: "https://jira-emails.s3.amazonaws.com/payments/payment_5_pt.jpg",
  },
  end: {
    es: "https://jira-emails.s3.amazonaws.com/payments/payment_0_es.jpg",
    pt: "https://jira-emails.s3.amazonaws.com/payments/payment_0_pt.jpg",
  },
};

const services = ["plataforma.ruedata.com"];
const env = services.includes(window.location.hostname)
  ? "production"
  : "development";

const Suspensions = memo(({ firebase, t }) => {
  const [days, setDays] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { i18n } = useTranslation();
  let customer_id = localStorage.getItem("GlobalCustomerId");
  const db = firebase.doNotifications();

  useEffect(() => {
    if (customer_id) {
      const ref = `notifications/suspensions/${env}/${customer_id}`;
      const dbRef = db.ref(ref);
      dbRef.on("value", (snapshot) => {
        if (snapshot.val()) {
          const { notification_finish_date } = snapshot.val();
          const today = new Date().toISOString().slice(0, 10);
          const suspensionDate = notification_finish_date
            .replace(/ /g, ".")
            .split(".")[0];
          const startDate = new Date(today).getTime();
          const endDate = new Date(suspensionDate).getTime();
          setDays(Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)));
        }
      });
    }
  }, [customer_id, db]);

  useEffect(() => {
    if (days === 10 || days === 5) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [days]);

  const imageConditions = (status) => {
    return imgs[status][i18n.language.split("-")[0]];
  };

  const setDefault = () => {
    localStorage.removeItem("GlobalCustomerId");
    localStorage.removeItem("GlobalCustomerName");
    localStorage.removeItem("GlobalCustomerProduct");
    localStorage.removeItem("GlobalPendingAnalist");
    localStorage.removeItem("GlobalPendingWithoutKmCount");
    localStorage.removeItem("GlobalPendingCount");
    localStorage.removeItem("GlobalPendingCountScrap");
    window.location.reload();
  };

  if (days === 0 || days < 0) {
    return (
      <Modal show={true} dialogClassName="modal-sx-mc-notifications">
        <img alt="banner" src={imageConditions("end")} />
        <div
          onClick={() => {
            setDefault();
          }}
        >
          <i
            className="fa fa-times"
            style={{ width: "20px", height: "20px" }}
          ></i>
        </div>
      </Modal>
    );
  }
  return (
    <>
      {days ? (
        <>
          <Modal show={showModal} dialogClassName="modal-sm-mc-notifications">
            <img
              alt="banner"
              src={imageConditions(days === 10 ? "initial" : "halft")}
            />
            <div
              onClick={() => {
                setShowModal(false);
              }}
            >
              <i
                className="fa fa-times"
                style={{ width: "20px", height: "20px" }}
              ></i>
            </div>
          </Modal>

          <div className="pilotTrial">
            <div>
              <h4>{days && days}</h4>
              <span>/Dias</span>
            </div>
            <p>{t("customers.customer-item-23")}</p>
          </div>
        </>
      ) : null}
    </>
  );
});

export default withFirebase(Suspensions);
