import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "moment/locale/es";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as ROLES from "../../../constants/roles";
import VehicleDeleteItem from "./VehicleDeleteItem";
import {
  getVehiclesAction,
  getVehiclesSearchAction,
  putVehiclesActiveAction,
} from "../../../redux/actions/VehicleAction";
import Loading from "../../Loader";
import { paginationEstructure } from "../../../constants/paginationCode";
import Pagination from "../../pagination";
import { Modal } from "react-bootstrap";
import DropZone from "../../Multiple/DropZone";
import { trackPromise } from "react-promise-tracker";
import api from "../../../constants/api";
import { API_REST_VEHICLES_EXCEL } from "../../../constants/apis";

const VehiclesList = ({ t }) => {
  const dispatch = useDispatch();
  const vehiclesData = useSelector((selector) => selector.customerVehicles);
  const [vehicles, setVehicles] = useState(vehiclesData.vehicles);
  const [loading, setLoading] = useState(true);
  const [filterActive, setFilterActive] = useState(false);
  const [showModalActive, setShowModalActive] = useState(false);
  const [state, setState] = useState({
    status: null,
    page: 1,
    limit: 20,
    search: null,
    modalVehicle: null,
    modalAction: null,
  });
  const [sendDataActive, setSendDataActive] = useState({
    fecha_cambio: "",
    observaciones: "",
  });

  useEffect(() => {
    if (vehiclesData.vehicles.length === 0) {
      getVehicles(state.page, filterActive, state.search, state.limit);
    } else {
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    setVehicles(vehiclesData.vehicles);
    if (vehiclesData.vehicles.length > 0) {
      setLoading(false);
    }
  }, [vehiclesData.vehicles]);

  const getVehicles = (page, inactive, search, limit) => {
    dispatch(
      getVehiclesAction(
        page,
        inactive,
        search,
        limit,
        localStorage.getItem("GlobalCustomerId")
      )
    )
      .then(res => {
        if (res.vehicles.length === 0 && res.total === 0) {
          setLoading(false)
        }
      })
  };

  const paginationSubmit = (page, limit, authUser) => {
    state.limit = limit;
    state.page = page;
    setVehicles([]);
    setLoading(true);
    setState({ ...state });
    getVehicles(state.page, filterActive, state.search, state.limit);
  };

  const filterActiveFn = (active) => {
    setFilterActive(active);
    setLoading(true);
    getVehicles(state.page, active, state.search, state.limit);
  };

  const submitSearch = () => {
    setLoading(true);
    setVehicles([]);
    dispatch(getVehiclesSearchAction(state.page, filterActive, state.search, state.limit, localStorage.getItem("GlobalCustomerId")))
      .then((res) => {
        if (res.vehicles.length > 0) {
          setVehicles(res.vehicles)
        } else {
          setVehicles(vehiclesData.vehicles)
          toast.info(t("alerts.item"))
        }
        setLoading(false)
      })
      .catch(error => {
        if (error.response.status !== 200) {
          toast.error(error.response.data.message.toString())
          setLoading(false)
        }
      })
  };

  const onChangeSearch = (text) => {
    state.search = text;
    setState({ ...state });
  };

  const clearSearch = () => {
    state.search = null;
    setState({ ...state });
    setLoading(true);
    setVehicles(vehiclesData.vehicles);
    setLoading(false)
  };

  const modalActive = () => {
    const handleClose = () => {
      setShowModalActive(false);
      setSendDataActive({
        fecha_cambio: "",
        observaciones: "",
      });
    };

    const onChangeActive = (e) => {
      sendDataActive[e.target.name] = e.target.value;
    };

    const onSubmitActive = () => {
      setLoading(true);
      let vehicle = state.modalVehicle;
      vehicle.inactive = !state.modalAction;
      vehicle.fecha_cambio = moment(sendDataActive.fecha_cambio).utc().format();
      vehicle.observaciones = [
        {
          observacion: sendDataActive.observaciones,
        },
      ];
      dispatch(
        putVehiclesActiveAction(
          localStorage.getItem("GlobalCustomerId"),
          vehicle.id,
          vehicle
        )
      ).then((res) => {
        if (res) {
          setLoading(false);
          handleClose();
          toast.success(t("alerts.c-vehicle-item-5"));
        }
      });
    };
    return (
      <Modal
        show={showModalActive}
        onHide={handleClose}
        dialogClassName="modal-sm-mc"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ flex: 1 }}>
            <h5>Cambio de estado de vehículo</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label
                className="control-label col-md-2 float-left label"
                for="observaciones"
              >
                {t("client-vehicles.client-item-7")}
              </label>
              <input
                type="text"
                className="form-control col-md-9"
                name="observaciones"
                placeholder={t("client-vehicles.client-observation")}
                onChange={(e) => onChangeActive(e)}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label col-md-2 float-left label"
                for="fecha_cambio"
              >
                {t("client-vehicles.client-item-6")}
              </label>
              <input
                type="datetime-local"
                className="form-control col-md-9"
                name="fecha_cambio"
                onChange={(e) => onChangeActive(e)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-primary"
            onClick={() => handleClose()}
          >
            Cancelar
          </button>
          <button className="btn btn-primary" onClick={() => onSubmitActive()}>
            {state.modalAction ? "Activar" : "Inactivar"}
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const getVehiclesList = (
    vehicles,
    authUser,
    next,
    previus,
    total,
    limit,
    page
  ) => {
    const pagination = paginationEstructure(next, previus, total, limit, page);

    return (
      <div className="text-center v-scroll">
        {vehicles.length > 0 ? (
          <>
            <table className="table table-striped table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th scope="col">{t("client-vehicles.client-item-1")}</th>
                  <th scope="col">{t("client-vehicles.client-item-2")}</th>
                  <th scope="col">Sap</th>
                  <th scope="col">{t("client-vehicles.client-item-3")}</th>
                  <th scope="col">{t("client-vehicles.client-item-4")}</th>
                  <th scope="col">{t("client-vehicles.client-item-5")}</th>
                </tr>
              </thead>
              <tbody>
                {vehicles &&
                  vehicles.map((vehicle) => (
                    <tr key={vehicle.vehiclenumber}>
                      <td>{vehicle.plate}</td>
                      <td>{vehicle.vehiclenumber}</td>
                      <td>{vehicle.sap ? vehicle.sap : ''}</td>
                      <td>
                        {!!vehicle.fecha_activacion
                          ? moment
                            .utc(vehicle.fecha_activacion)
                            .local()
                            .locale("es")
                            .format("LLL")
                          : null}
                      </td>
                      <td>
                        {!!vehicle.fecha_inactivacion
                          ? moment
                            .utc(vehicle.fecha_inactivacion)
                            .local()
                            .locale("es")
                            .format("LLL")
                          : null}
                      </td>
                      <td>
                        <Link
                          to={"/vehicles/history/" + vehicle.id}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-chart-line"></i>{" "}
                          {t("globals.history")}
                        </Link>
                        &nbsp;
                        <Link
                          to={"/vehicles/" + vehicle.id}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-eye"></i> {t("globals.see")}
                        </Link>
                        &nbsp;
                        {!!authUser.permissions.edit ? (
                          <Link
                            to={"/vehicles/edit/" + vehicle.id}
                            className="btn btn-primary"
                          >
                            <i className="fa fa-edit"></i> {t("globals.edit")}
                          </Link>
                        ) : null}
                        &nbsp;
                        {vehicle.inactive === true ? (
                          <button
                            className="btn btn-info"
                            onClick={(e) => {
                              state.modalAction = true;
                              state.modalVehicle = vehicle;
                              setShowModalActive(true);
                            }}
                          >
                            <i className="fa fa-exclamation-triangle"></i>{" "}
                            {t("globals.active")}
                          </button>
                        ) : (
                          <button
                            className="btn btn-info"
                            onClick={(e) => {
                              state.modalAction = false;
                              state.modalVehicle = vehicle;
                              setShowModalActive(true);
                            }}
                          >
                            <i className="fa fa-exclamation-triangle"></i>{" "}
                            {t("globals.deactive")}
                          </button>
                        )}
                        &nbsp;
                        {!!authUser.permissions.delete ? (
                          <VehicleDeleteItem t={t} vehicleDelete={vehicle} />
                        ) : null}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              {vehicles.length > 0 ? (
                <Pagination
                  colspan="10"
                  pageP={pagination.pageP}
                  pageN={pagination.pageN}
                  page={page}
                  limit={limit}
                  links={pagination.links}
                  submit={(page, limit) =>
                    paginationSubmit(page, limit, authUser)
                  }
                />
              ) : null}
            </div>
          </>
        ) : (
          <div class="alert alert-info text-center w-50 mx-auto" role="alert">
            No hay vehiculos registrados
          </div>
        )}
      </div>
    );
  };

  const generateExcel = () => {
    return trackPromise(fetch(API_REST_VEHICLES_EXCEL.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Error en el servidor generando el excel");
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'vehiculos.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success("Excel generado exitosamente");
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  let header = [
    t("multiple.vehicle-item-1"),
    t("multiple.vehicle-item-2"),
    t("multiple.vehicle-item-3"),
    t("multiple.vehicle-item-4"),
    t("multiple.vehicle-item-5"),
    t("multiple.vehicle-item-6"),
    t("multiple.vehicle-item-7"),
    t("multiple.vehicle-item-8"),
    t("multiple.vehicle-item-9"),
    t("multiple.vehicle-item-10"),
    t("multiple.vehicle-item-11"),
    t("multiple.vehicle-item-12"),
    t("multiple.vehicle-item-32"),
    t("multiple.vehicle-item-33"),
    t("multiple.vehicle-item-13"),
    t("multiple.vehicle-item-14")
  ];

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className="container-fluid mt-100">
          <div className="row">
            <div className="col-md-6">
              <h3>{t("client-vehicles.client-list")}</h3>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 pull-right">
                  <div className="input-group mb-3 flex-nowrap">
                    <div
                      className="btn-group btn-group-toggle mr-3"
                      data-toggle="buttons"
                    >
                      <label
                        className="btn btn-primary"
                        onClick={() => filterActiveFn(false)}
                      >
                        <input
                          type="radio"
                          name="searchType"
                          value={false}
                          autoComplete="off"
                        />{" "}
                        {t("client-vehicles.client-operative")}
                      </label>
                      <label
                        className="btn btn-primary"
                        onClick={() => filterActiveFn(true)}
                      >
                        <input
                          type="radio"
                          name="searchType"
                          value={true}
                          autoComplete="off"
                        />{" "}
                        {t("client-vehicles.client-deoperative")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pull-right">
                  <div className="input-group mb-3 flex-nowrap">
                    <button
                      className="btn btn-primary"
                      onClick={() => clearSearch()}
                    >
                      <i class="fas fa-times"></i>
                    </button>
                    <input
                      aria-describedby="btnSearch"
                      className="form-control"
                      type="text"
                      value={state.search}
                      id="search"
                      placeholder={t("globals.search-input")}
                      onChange={(e) => onChangeSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        id="btnSearch"
                        onClick={() => submitSearch()}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="d-flex align-items-center justify-content-between mt-3 w-100">
              <div className="">
                {!!authUser.permissions.create ?
                  <Link to={"/vehicles/new"}
                    className="btn btn-primary">
                    <i className="fa fa-plus"></i> {t("globals.register")}
                  </Link> : null}
              </div>
              <div className="">
                {authUser.roles === ROLES.ANALYST ||
                  authUser.roles === ROLES.ADMIN ? (
                  <DropZone
                    t={t}
                    title={t("client-tires.client-massive-vehicle")}
                    fields={header}
                    type="vehicle_massive"
                    isSelector={false}
                  />
                ) : null}
                {authUser.roles === ROLES.ADMIN || authUser.roles === ROLES.ANALYST || authUser.roles === ROLES.CUSTOMER ?
                  <Link to={"/vehicles/multiple"}
                    className="btn btn-primary">
                    <i className="fa fa-upload"></i> {t("globals.register-massive")}
                  </Link> : null}&nbsp;
                <button className="btn btn-success"
                  onClick={(event) => generateExcel()}>
                  <i className="fa fa-file-export"></i> {t("globals.exports")}
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-12">
              <p>
                {t("client-vehicles.client-total")}{" "}
                <b>{!!vehiclesData.total ? vehiclesData.total : 0}</b>
              </p>
            </div>
          </div>
          <br />
          {loading ? (
            <Loading />
          ) : (
            getVehiclesList(
              vehicles,
              authUser,
              vehiclesData.next,
              vehiclesData.previus,
              vehiclesData.total,
              state.limit,
              state.page
            )
          )}
          {showModalActive ? modalActive() : null}
        </div>
      )
      }
    </AuthUserContext.Consumer >
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(VehiclesList);






