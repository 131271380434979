import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../../Auth/Session";
import * as constantsBrand from "../../../../constants/actions/RetreadBrands";
import * as APIS from "../../../../constants/apis";

export function onReqSuccess(band, dimensions, message) {
  const json = {
    band: band,
    dimensions: dimensions,
    message: message
  };
  return {
    type: constantsBrand.REQUEST_SUCCESS,
    json
  };
};

class RetreadDimensionNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      customer_id: localStorage.getItem('GlobalCustomerId'),
      dimention: "",
      profundidad_original: "",
      profundidad_minima: "",
      presion_maxima: "",
      presion_minima: ""
    };
  };

  onSubmit = event => {
    event.preventDefault();
    const { band, dimensions, onReqSuccess } = this.props;

    const bandid = this.props.match.params.bandid;
    const data = {
      retreadband_id: bandid,
      ...this.state,
    };

    return trackPromise(fetch(`${APIS.API_REST_RETREADDIMENSIONS.replace("retreadband_id", bandid)}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }, method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          this.props.history.goBack();
          onReqSuccess(band, dimensions, this.props.t("alerts.r-tire-item-12"));
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  render() {
    const { error } = this.props;

    this.showErrorDialog(error);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <h3>{this.props.t("tire-brands.tire-dimensions-new")}</h3>
            <br /><br />
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="codeInput">
                      {this.props.t("globals.code")}
                    </label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("globals.code")}
                      id="codeInput"
                      name="code"
                      value={this.state.code}
                      onChange={this.onChange}
                      required />
                    <input type="hidden"
                      name="customer_id"
                      value={this.state.customer_id} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="dimentionInput">
                    {this.props.t("tire-brands.tire-item-1")}
                    </label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-brands.tire-item-1")}
                      id="dimentionInput"
                      name="dimention"
                      value={this.state.dimention}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="orDepth">{this.props.t("tire-brands.tire-item-4")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-brands.tire-item-4")}
                      id="orDepthInput"
                      name="profundidad_original"
                      value={this.state.profundidad_original}
                      onChange={this.onChange}
                      type="number"
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="minDepth">{this.props.t("tire-brands.tire-item-5")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-brands.tire-item-5")}
                      id="minDepthInput"
                      name="profundidad_minima"
                      value={this.state.profundidad_minima}
                      onChange={this.onChange}
                      type="number"
                      required />
                  </div>
                </div>
              </div>
              <div style={{ display: "none" }} className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="minPressInput">{this.props.t("tire-brands.tire-item-6")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-brands.tire-item-6")}
                      id="minPressInput"
                      name="presion_minima"
                      value={this.state.presion_minima}
                      onChange={this.onChange}
                      type="number" />
                  </div>
                </div>
              </div>
              <div style={{ display: "none" }} className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="maxPressInput">{this.props.t("tire-brands.tire-item-7")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("tire-brands.tire-item-7")}
                      id="maxPressInput"
                      name="presion_maxima"
                      value={this.state.presion_maxima}
                      onChange={this.onChange}
                      type="number" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                    className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const structuredSelector = createStructuredSelector({
  dimensions: state => state.dimensions,
  band: state => state.band,
  error: state => state.error
});

const mapDispatchToProps = { onReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(RetreadDimensionNewItem));
