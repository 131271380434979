import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import * as constantsWarehouse from "../../../constants/actions/Warehouses";
import * as APIS from "../../../constants/apis";

function deleteWarehouse(name, warehouses, warehouse, t) {
  if(window.confirm(t("alerts.warehouse-item-4"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_WAREHOUSES.replace("customer_id",
                                              localStorage.getItem("GlobalCustomerId"))}/${name}`, {
                                                headers:{
                                                  "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                  "Accept-language": localStorage.getItem("LanguageSelected"),
                                                },method: "DELETE" })
        .then(response => {
          if(response.status !== 200) {
            throw new Error(t("alerts.brands-item-5"));
          }
          return response.json(); })
        .then(json => {
          dispatch(getReqSuccess(warehouses, warehouse, json.message))
              return fetch(APIS.API_REST_WAREHOUSES.replace("customer_id",
              localStorage.getItem("GlobalCustomerId")), {
                                                                headers:{
                                                                  "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                                  "Accept-language": localStorage.getItem("LanguageSelected"),
                                                                } })
                .then(response => response.json())
                .then(json => {
                  dispatch(getWarehousesSuccess({
                    warehouses: json.bodegas
                  })); })
                .catch(error => {
                  dispatch(getReqError(warehouses, warehouse,
                                      error.toString()));
                }); })
        .catch(error => {
          dispatch(getReqError(warehouses, warehouse,
                              error.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(getWarehousesSuccess({
        warehouses: warehouses
      }));
    };
  }
};

export function getWarehousesSuccess(json) {
  return {
    type:  constantsWarehouse.GET_WAREHOUSES_SUCCESS,
    json
  };
};

export function getReqSuccess(warehouses, warehouse, message) {
  const json = {
    warehouses: warehouses,
    warehouse : warehouse,
    message   : message
  };
  return {
    type:  constantsWarehouse.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(warehouses, warehouse, error) {
  const json = {
    warehouses: warehouses,
    warehouse : warehouse,
    error     : error
  };
  return {
    type: constantsWarehouse.GET_REQUEST_ERROR,
    json
  };
};

class WarehouseDeleteItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { warehouses, warehouse, warehouseDelete,
            deleteWarehouse } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteWarehouse(warehouseDelete.id, warehouses, warehouse, this.props.t)}
                className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
};

const structuredSelector = createStructuredSelector({
  warehouses: state => state.warehouses,
  warehouse : state => state.warehouse
});

const mapDispatchToProps = { deleteWarehouse };

export default connect(structuredSelector, mapDispatchToProps)(WarehouseDeleteItem);
