import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import * as constantsCatalogue from "../../../constants/actions/RetreadCatalogues";

const initialState = {
  previus     : "",
  next        : "",
  total       : "",
  catalogues  : [],
  catalogue   : {},
  brands      : [],
  bands       : [],
  dimensions  : [],
  message     : null,
  error       : null
};

function rootReducer(state, action) {
  switch(action.type) {
    case constantsCatalogue.GET_RECATALOGUES_SUCCESS:
      return {
        previus   : action.json.previus,
        next      : action.json.next,
        total     : action.json.total,
        catalogues : action.json.catalogues,
      };
    case constantsCatalogue.GET_RECATALOGUE_SUCCESS:
      return {
        catalogue : action.json.catalogue
      };
    case constantsCatalogue.GET_LISTS_SUCCESS:
      return {
        catalogue   : action.json.catalogue,
        brands      : action.json.brands,
        bands       : action.json.bands,
        dimensions  : action.json.dimensions
      };
    case constantsCatalogue.GET_REQUEST_SUCCESS:
      return {
        catalogues : action.json.catalogues,
        catalogue  : action.json.catalogue,
        message    : action.json.message
      };
    case constantsCatalogue.GET_REQUEST_ERROR:
      return {
        previus   : action.json.previus,
        next      : action.json.next,
        total     : action.json.total,
        catalogues : action.json.catalogues,
        catalogue  : action.json.catalogue,
        error      : action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
