import $ from "jquery";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as APIS from "../../../constants/apis";
import Autosuggest from "react-autosuggest";

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

class WorkEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      customer_id: localStorage.getItem("GlobalCustomerId"),
      work_date: null,
      worktype: null,
      cost: null,
      km_id: null,
      km: null,
      kms: [],
      params: {},
      next_measurament: null,
      vehicle_id: null,
      order: "",
      observacion: "",
      technicians: [],
      technical: "",
      suggestionsTnc: [],
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    const vehicle_id = this.props.match.params.vehicle_id;
    this.setState({ id: id, vehicle_id: vehicle_id });
    this.getWork(vehicle_id, id);
    this.getKms(vehicle_id);
    this.getTnc();
  }

  getTnc = () => {
    trackPromise(
      fetch(
        APIS.API_REST_TECHNICIANS.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          this.setState({ technicians: json.Users });
        })
    );
  };

  getKms = (vehicle_id) => {
    return trackPromise(
      fetch(
        `${APIS.API_REST_FUELS_HISTORY.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", vehicle_id)}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-1"));
          }
          return response.json();
        })
        .then((json) => {
          this.setState({ kms: json.combustibles });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.work-tire-item-1"));
        })
    );
  };

  getWork = (vehicle_id, id) => {
    return trackPromise(
      fetch(
        `${APIS.API_REST_VEHICLE_WORK.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", vehicle_id)}/${id}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.work-tire-item-8"));
          }
          return response.json();
        })
        .then((json) => {
          this.setState({
            params: json.params,
            next_measurament: json.next_measurament,
            worktype: json.worktype,
            cost: json.cost,
            order: json.order,
            observacion: json.observacion,
            work_date: moment
              .utc(json.work_date)
              .local()
              .locale("es")
              .format("yyyy-MM-DD HH:mm:ss"),
            km_id: json.km_id,
            km: json.kilometers,
            technical: json.technical ? json.technical : "",
          });
        })
        .catch((error) => {
          toast.error(error.message.toString());
        })
    );
  };

  onSubmitWork(event, history) {
    event.preventDefault();

    var data = {
      ...this.state,
    };

    delete data.technicians;
    delete data.suggestionsTnc;
    data.work_date = moment(data.work_date).utc().format();
    data.next_measurament = parseInt(data.next_measurament);
    var date = moment(data.work_date).utc().format("YYYY-MM-DD");
    if (date > moment().utc().format("YYYY-MM-DD")) {
      toast.error(this.props.t("alerts.work-tire-item-12"));
    } else {
      if (!!data.km) {
        var kmdate = moment(
          data.kms.find((km) => km.id == data.km_id).fecha_inspeccion
        ).format("YYYY-MM-DD");
        console.log(kmdate);
        if (date == kmdate) {
          trackPromise(
            fetch(
              `${APIS.API_REST_VEHICLE_WORK.replace(
                "customer_id",
                localStorage.getItem("GlobalCustomerId")
              ).replace("vehicle_id", this.state.vehicle_id)}/${this.state.id}`,
              {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Basic " + localStorage.getItem("firebaseAuthToken"),
                  "Accept-language": localStorage.getItem("LanguageSelected"),
                },
              }
            )
              .then((response) => response.json())
              .then((json) => {
                const status = json.message;
                if (!status) {
                  toast.success(this.props.t("alerts.work-tire-item-9"));
                  this.props.history.push(
                    "/works/vehicles/" + this.state.vehicle_id
                  );
                } else {
                  throw new Error(status);
                }
              })
              .catch((error) => {
                toast.error(error.toString());
              })
          );
        } else {
          toast.error(this.props.t("alerts.work-tire-item-13"));
        }
      } else {
        trackPromise(
          fetch(
            `${APIS.API_REST_VEHICLE_WORK.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            ).replace("vehicle_id", this.state.vehicle_id)}/${this.state.id}`,
            {
              method: "PUT",
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          )
            .then((response) => response.json())
            .then((json) => {
              const status = json.message;
              if (!status) {
                toast.success(this.props.t("alerts.work-tire-item-9"));
                this.props.history.push(
                  "/works/vehicles/" + this.state.vehicle_id
                );
              } else {
                throw new Error(status);
              }
            })
            .catch((error) => {
              toast.error(error.toString());
            })
        );
      }
    }
  }

  chooseKm(kilometers, kilometers_id) {
    $("#last10Km").modal("hide");
    this.setState({ km: kilometers, km_id: kilometers_id });
  }

  onChange = (event) => {
    if (event.target.name === "cantidad") {
      let params = this.state.params;
      params.cantidad = event.target.value;
      this.setState({ params });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  getSuggestionsTnc = (value) => {
    let tnc = this.state.technicians;
    let reTnc = tnc.filter((item) => {
      const tncValue = escapeRegexCharacters(value.toLowerCase().trim());
      return (
        (item.name.toLowerCase().search(tncValue) >= 0 && item.active) ||
        (item.email.toLowerCase().search(tncValue) >= 0 && item.active)
      );
    });
    return reTnc;
  };

  onChangeTnc = (event, { newValue }) => {
    this.setState({
      technical: newValue,
    });
  };

  onSuggestionsFetchRequestedTnc = ({ value }) => {
    this.setState({
      suggestionsTnc: this.getSuggestionsTnc(value),
    });
  };

  onSuggestionsClearRequestedTnc = () => {
    this.setState({
      suggestionsTnc: [],
    });
  };

  getSuggestionValueTnc = (suggestion) => suggestion.name;

  renderSuggestionTnc = (suggestion) => (
    <div>
      <p>
        {suggestion.name}: {suggestion.email}
      </p>
    </div>
  );
  render() {
    const { history } = this.props;

    const inputProps = {
      placeholder: this.props.t("alerts.ins-one-item-14"),
      value: this.state.technical,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "100%",
        marginBottom: 0,
      },
      onChange: this.onChangeTnc,
    };

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("work-vehicle.client-item-11")}</h3>
            <br />
            <br />
            <form onSubmit={(event) => this.onSubmitWork(event, history)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="codigoInput">
                      {this.props.t("client-tires.client-item-27")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder="#"
                      id="orderInput"
                      name="order"
                      value={this.state.order}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="codigoInput">
                      {this.props.t("client-vehicles.client-item-7")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t(
                        "client-vehicles.client-observation"
                      )}
                      id="observacionInput"
                      name="observacion"
                      value={this.state.observacion}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="worktypeInput">
                      {this.props.t("work-vehicle.client-item-1")}
                    </label>
                    <br></br>
                    <i className="text-info small">
                      {this.props.t("globals.required")}
                    </i>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      id="worktypeInput"
                      name="worktype"
                      value={this.state.worktype}
                      onChange={this.onChange}
                      required
                    >
                      <option value="">
                        {this.props.t("work-vehicle.client-item-12")}
                      </option>
                      <option value="alineacion">
                        {this.props.t("work-vehicle.client-item-13")}
                      </option>
                      <option value="torqueo">
                        {this.props.t("work-vehicle.client-item-14")}
                      </option>
                      {localStorage.getItem("GlobalCustomerId") === "650" && (
                        <>
                          <option value="montaje / desmontaje">
                            {this.props.t("work-vehicle.client-item-25")}
                          </option>
                          <option value="calibración">
                            {this.props.t("work-vehicle.client-item-26")}
                          </option>
                          <option value="rotaciónes">
                            {this.props.t("work-vehicle.client-item-27")}
                          </option>
                          <option value="girada de rim">
                            {this.props.t("work-vehicle.client-item-28")}
                          </option>
                          <option value="cambio de neumáticos">
                            {this.props.t("work-vehicle.client-item-29")}
                          </option>
                          <option value="alineación / balanceo">
                            {this.props.t("work-vehicle.client-item-30")}
                          </option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              {this.state.worktype === "torqueo" ? (
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="cantidadInput">
                        {this.props.t("work-vehicle.client-item-4")}
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        name="cantidad"
                        type="number"
                        id="cantidadInput"
                        className="form-control"
                        value={this.state.params.cantidad}
                        min={0}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row form-group">
                <div className="col-sm-2">
                  <label htmlFor="inputCarKm">
                    {this.props.t("work-vehicle.client-item-2")}
                  </label>
                </div>
                <div className="col-sm-10 input-group">
                  <input
                    name="km"
                    type="number"
                    id="inputCarKm"
                    className="form-control"
                    value={this.state.km}
                    min={this.state.initKm}
                    onChange={this.onChange}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary text-white"
                      type="button"
                      data-toggle="modal"
                      data-target={"#last10Km"}
                    >
                      <i className="fa fa-chart-line"></i>{" "}
                      {this.props.t("work-vehicle.client-item-15")}
                    </button>
                    <div
                      className="modal fade"
                      id="last10Km"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="last10KmLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              id="last10KmLabel"
                              className="modal-title text-dark"
                            >
                              {this.props.t("work-vehicle.client-item-16")}
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Cerrar"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body text-dark">
                            <table className="table table-striped mb-0">
                              <thead>
                                <tr>
                                  <th>
                                    {this.props.t("work-vehicle.client-item-2")}
                                  </th>
                                  <th>
                                    {this.props.t(
                                      "work-vehicle.client-item-17"
                                    )}
                                  </th>
                                  <th>
                                    {this.props.t("work-vehicle.client-item-7")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.kms.map((km, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{km.kilometers}</td>
                                      <td>
                                        {moment
                                          .utc(km.fecha_inspeccion)
                                          .local()
                                          .locale("es")
                                          .format("LLL")}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={(e) =>
                                            this.chooseKm(km.kilometers, km.id)
                                          }
                                        >
                                          <i className="fa fa-link"></i>{" "}
                                          {this.props.t(
                                            "work-vehicle.client-item-18"
                                          )}
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-dismiss="modal"
                              aria-label="Cerrar"
                            >
                              {this.props.t("globals.close")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="costInput">
                      {this.props.t("work-vehicle.client-item-3")}
                    </label>
                    <br></br>
                    <i className="text-info small">
                      {this.props.t("globals.required")}
                    </i>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("work-vehicle.client-item-3")}
                      id="costInput"
                      name="cost"
                      value={this.state.cost}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="workDate">
                      {this.props.t("work-vehicle.client-item-6")}
                    </label>
                    <br></br>
                    <i className="text-info small">
                      {this.props.t("globals.required")}
                    </i>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("work-vehicle.client-item-6")}
                      id="workDate"
                      name="work_date"
                      value={this.state.work_date}
                      type="text"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nextMeasurement">
                      {this.props.t("work-vehicle.client-item-5")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      className="form-control"
                      placeholder={this.props.t("work-vehicle.client-item-5")}
                      id="nextMeasurement"
                      name="next_measurament"
                      value={this.state.next_measurament}
                      type="number"
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-2">
                  <label htmlFor="inputCarTechnician">
                    {this.props.t("ins-one.ins-one-31")}
                  </label>
                </div>
                <div className="col-sm-10">
                  <Autosuggest
                    suggestions={this.state.suggestionsTnc}
                    onSuggestionsFetchRequested={
                      this.onSuggestionsFetchRequestedTnc
                    }
                    onSuggestionsClearRequested={
                      this.onSuggestionsClearRequestedTnc
                    }
                    shouldRenderSuggestions={() => true}
                    getSuggestionValue={this.getSuggestionValueTnc}
                    renderSuggestion={this.renderSuggestionTnc}
                    inputProps={inputProps}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    <i className="fa fa-save"></i>{" "}
                    {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(WorkEditItem);
