import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsRetreadCatalogue from "../../../constants/actions/RetreadCatalogues";
import * as APIS from "../../../constants/apis";

function getRetreadCatalogue(name, t) {
  let catalogue = {};
  let brands, bands, dimensions = [];
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_RETREADCATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers:{
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      } })
      .then(response => response.json())
      .then(json => {
        catalogue = json;
        return fetch(APIS.API_REST_CUSTOMER_RETREADBRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId")) , {
          headers:{
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          } }); })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(t("alerts.retread-item-19"));
        }
        return response.json(); })
      .then(json => {
        brands = json.Retreadbrands;
        return fetch(APIS.API_REST_CUSTOMER_RETREADMODELS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("retreadbrand_id",
                                                             catalogue.marca_id), {
                                                              headers:{
                                                                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                                "Accept-language": localStorage.getItem("LanguageSelected"),
                                                              } })})
      .then(response => {
        if(response.status !== 200) {
          throw new Error(t("alerts.retread-item-15"));
        }
        return response.json(); })
      .then(json => {
        bands = json.RetreadBands;
        return fetch(APIS.API_REST_CUSTOMER_RETREADDIMENSIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId"))
                      .replace("retreadband_id", catalogue.modelo_id), {
                        headers:{
                          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                          "Accept-language": localStorage.getItem("LanguageSelected"),
                        } })})
      .then(response => {
        if(response.status !== 200) {
          throw new Error(t("alerts.retread-item-16"));
        }
        return response.json(); })
      .then(json => {
        dimensions = json.RetreadDimentions;
        dispatch(getRetreadCatalogueAndListsSuccess({
          catalogue  : catalogue,
          brands     : brands,
          bands      : bands,
          dimensions : dimensions
        })); })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };
};

function getBandsList(brandName, props) {
  return dispatch => {
    if(!brandName) {
      return dispatch(getRetreadCatalogueAndListsSuccess({
        catalogue   : props.catalogue,
        brands      : props.brands,
        bands       : [],
        dimensions  : []
      }));
    }
    trackPromise(fetch(APIS.API_REST_CUSTOMER_RETREADMODELS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("retreadbrand_id", brandName), {
      headers:{
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      } })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(props.t("alerts.retread-item-15"));
        }
        return response.json(); })
      .then(json => {
        dispatch(getRetreadCatalogueAndListsSuccess({
          catalogue   : props.catalogue,
          brands      : props.brands,
          bands       : json.RetreadBands,
          dimensions  : props.dimensions
        })); })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

function getDimsList(bandId, props) {
  return dispatch => {
    if(!bandId) {
      return dispatch(getRetreadCatalogueAndListsSuccess({
        catalogue   : props.catalogue,
        brands      : props.brands,
        bands       : props.bands,
        dimensions  : []
      }));
    }
    trackPromise(fetch(APIS.API_REST_CUSTOMER_RETREADDIMENSIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("retreadband_id", bandId), {
      headers:{
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      } })
      .then(response => {
        if(response.status !== 200) {
          throw new Error(props.t("alerts.retread-item-16"));
        }
        return response.json(); })
      .then(json => {
        dispatch(getRetreadCatalogueAndListsSuccess({
          catalogue   : props.catalogue,
          brands      : props.brands,
          bands       : props.bands,
          dimensions  : json.RetreadDimentions
        })); })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

export function getRetreadCatalogueAndListsSuccess(json) {
  return {
    type: constantsRetreadCatalogue.GET_LISTS_SUCCESS,
    json
  };
};

export function getReqSuccess(catalogues, catalogue, message) {
  const json = {
    catalogues : catalogues,
    catalogue  : catalogue,
    message    : message
  };
  return {
    type: constantsRetreadCatalogue.GET_REQUEST_SUCCESS,
    json
  };
};

class RetreadCatalogueEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      marca             : "",
      modelo            : "",
      dimension_id      : "",
      prof_original     : "",
      prof_minima       : "",
      precio            : "",
      distribuidor      : "",
      presion_minima    : "",
      presion_maxima    : "",
      costo_milimetraje : "",
      customer_id       : ""
    };
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getRetreadCatalogue(id, this.props.t);
  };

  componentDidUpdate(prevProps) {
    if(!prevProps.catalogue || (this.props.catalogue !== prevProps.catalogue)) {
      this.setState({
        marca             : this.props.catalogue.marca_id + "|" +
                            this.props.catalogue.marca,
        modelo            : this.props.catalogue.modelo_id + "|" +
                            this.props.catalogue.modelo,
        dimension_id      : this.props.catalogue.dimension_id,
        prof_original     : this.props.catalogue.profundidad_original,
        prof_minima       : this.props.catalogue.profundidad_minima,
        precio            : this.props.catalogue.precio,
        distribuidor      : this.props.catalogue.distribuidor,
        presion_minima    : this.props.catalogue.presion_minima,
        presion_maxima    : this.props.catalogue.presion_maxima,
        costo_milimetraje : this.props.catalogue.costo_milimetraje,
        customer_id       : this.props.catalogue.customer_id
      });
    };
  };

  onSubmitRetreadCatalogue = (event, catalogues, catalogue,
                              history, getReqSuccess) => {
    event.preventDefault();
    
    if(window.confirm(this.props.t("alerts.retread-item-17"))){

    const name = this.props.match.params.id;
    const data = {
      marca_id  : this.state.marca.split("|")[0],
      modelo_id : this.state.modelo.split("|")[0],
      ...this.state,
    };

    return trackPromise(fetch(`${APIS.API_REST_RETREADCATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers:{
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
        method: "PUT",
        body: JSON.stringify(data) })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if(!status) {
          history.push("/retreadcatalogues");
          getReqSuccess(catalogues, catalogue, this.props.t("alerts.retread-item-18"));
        } else {
          throw new Error(status);
        } })
      .catch(error => {
        toast.error(error.message.toString());
      }));
    }
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    switch(event.target.name) {
      case "marca":
        const brandId = event.target.value.split("|")[0];
        this.setState({ 
          modelo: "", 
          dimension_id: "",
          prof_original: "", 
          presion_minima: "", 
          presion_maxima: "", 
         });
        this.props.getBandsList(brandId, this.props);
        break;
      case "modelo":
        const modelId = event.target.value.split("|")[0];
        this.setState({ 
          dimension_id: "",
          prof_original: "", 
          presion_minima: "", 
          presion_maxima: "", 
        });
        this.props.getDimsList(modelId, this.props);
        break;
      case "dimension_id":
        var arr = this.props.dimensions;
        var arr2 = arr.filter(arr => arr.id == event.target.value);
        var p_ori = "";
        var p_min = "";
        var p_max = "";
        if(arr2.length > 0){
          p_ori = arr2[0].profundidad_original;
          p_min = arr2[0].presion_minima;
          p_max = arr2[0].presion_maxima;
        }
        this.setState({ 
          prof_original: p_ori, 
          presion_minima: p_min, 
          presion_maxima: p_max, 
        });
        break;
      case "precio":
        if(this.state.prof_minima != "" && this.state.prof_original != "") {
          var cxm = parseFloat(event.target.value / parseFloat(this.state.prof_original - this.state.prof_minima));
          this.setState({ costo_milimetraje: cxm })
        }
        break;
      default: 
        // Do nothing
    };
  };

  render() {
    const { catalogue, catalogues, brands, bands, dimensions, history,
            getReqSuccess } = this.props;
    const { marca, modelo, dimension_id, prof_original, prof_minima,
            precio, distribuidor, presion_minima, presion_maxima,
            costo_milimetraje } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!catalogue ?
          <div className="container-fluid mt-100">
            
            <h3>{this.props.t("tire-catalogue.tire-edit-2")}</h3>
            <br/><br/>
            <form onSubmit={(event) => this.onSubmitRetreadCatalogue(event,
                                       catalogues, catalogue,
                                       history, getReqSuccess)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="cataloguebrandInput">{this.props.t("tire-catalogue.tire-item-1")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <select className="form-control"
                            placeholder={this.props.t("tire-catalogue.tire-item-1")}
                            id="cataloguebrandInput"
                            name="marca"
                            value={marca}
                            onChange={this.onChange}
                            required>
                      <option value="">{this.props.t("tire-catalogue.tire-select-brand")}</option>
                      {
                        !!brands && brands.map(brand => {
                          return <option key={brand.id}
                                         value={brand.id+"|"+brand.name}>
                            {brand.name}
                          </option>
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="cataloguebandInput">{this.props.t("tire-catalogue.tire-item-13")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <select className="form-control"
                            placeholder="Banda "
                            id="cataloguebandInput"
                            name="modelo"
                            value={modelo}
                            onChange={this.onChange}
                            required>
                      <option value="">{this.props.t("tire-catalogue.tire-select-band")}</option>
                      {
                        !!bands && bands.map(band => {
                          return <option key={band.id}
                                         value={band.id+"|"+band.name}>
                            {band.name}
                          </option>
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="cataloguedimensionInput">{this.props.t("tire-catalogue.tire-item-3")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <select className="form-control"
                            placeholder="Dimensión "
                            id="cataloguedimensionInput"
                            name="dimension_id"
                            value={dimension_id}
                            onChange={this.onChange}
                            required>
                      <option value="">{this.props.t("tire-catalogue.tire-select-dimensions")}</option>
                      {
                        !!dimensions && dimensions.map(dimension => {
                          return <option key={dimension.id}
                                         value={dimension.id}>
                            {dimension.dimention}
                          </option>
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="profOrInput">{this.props.t("tire-catalogue.tire-item-6")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("tire-catalogue.tire-item-6")}
                           id="profOrInput"
                           name="prof_original"
                           type="number"
                           value={prof_original}
                           readOnly={true}
                           required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="profMinInput">{this.props.t("tire-catalogue.tire-item-7")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("tire-catalogue.tire-item-7")}
                           id="profMinInput"
                           name="prof_minima"
                           type="number"
                           value={prof_minima}
                           onChange={this.onChange}
                           required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="priceInput">{this.props.t("tire-catalogue.tire-item-10")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("tire-catalogue.tire-item-10")}
                           id="priceInput"
                           name="precio"
                           type="number"
                           value={precio}
                           onChange={this.onChange}
                           required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="providerInput">{this.props.t("tire-catalogue.tire-item-11")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("tire-catalogue.tire-item-11")}
                           id="providerInput"
                           name="distribuidor"
                           type="text"
                           value={distribuidor}
                           onChange={this.onChange}
                           required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="pressMinInput">{this.props.t("tire-catalogue.tire-item-8")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("tire-catalogue.tire-item-8")}
                           id="pressMinInput"
                           name="presion_minima"
                           type="number"
                           value={presion_minima}
                           readOnly={true}
                           required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="pressMaxInput">{this.props.t("tire-catalogue.tire-item-9")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("tire-catalogue.tire-item-9")}
                           id="pressMaxInput"
                           name="presion_maxima"
                           type="number"
                           value={presion_maxima}
                           readOnly={true}
                           required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="costInput">{this.props.t("tire-catalogue.tire-item-12")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                           placeholder={this.props.t("tire-catalogue.tire-item-12")}
                           id="costInput"
                           name="costo_milimetraje"
                           type="number"
                           value={costo_milimetraje}
                           readOnly={true}
                           required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                          className="btn btn-primary">
                    <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                  </button>
                </div>
              </div>
            </form>
          </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  catalogues  : state => state.catalogues,
  catalogue   : state => state.catalogue,
  brands      : state => state.brands,
  bands       : state => state.bands,
  dimensions  : state => state.dimensions
});

const mapDispatchToProps = { getRetreadCatalogue, getBandsList,
                             getDimsList, getReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(RetreadCatalogueEditItem));
