import React from "react";
import Chart from "react-google-charts";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import * as APIS from "../../../constants/apis";
import API from "../../../constants/api";
import * as VARIABLES from "../../../constants/variables";
import * as FUNCIONES from "../../../constants/funciones";
import Loading from "../Loading"
const $ = require('jquery');
$.DataTable = require('datatables.net');
window.JSZip = require('jszip');

class Plan extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDefinitive: false, name: "", vehiculo: null, tipo_vehiculo: null, ciudad: null, frente_fijo: null, frente_operacional: null, vida: null, desgaste: null, actividad: null, alerta: null, catVehicleTypes: [], catCities: [], catFront1: [], catFront2: [], catActivities: [], catAlerts: [], catLives: [], catWastages: [], planByWorksheet: null, planByChange: null, planByChangeVehicle: null, planByRotationVehicletype: null, planByRotationVehicle: null, planByActivities: null, planByNovelties: null, planByAlerts: null, planByDepths: null, planByDepthsVehicle: null, planByRequiredTires: null, planByRequiredTiresVehicle: null, planByLoweringTires: null, planByLoweringTiresVehicle: null };
  };

  componentDidMount() {
    this.getVehicles();
    this.getCatalogues();
    this.getData();
  };

  getVehicleId = (plate) => {
    let vehicle = this.state.vehicles.find(x => x.plate === plate);
    if (vehicle) {
      return vehicle.id;
    } else {
      vehicle = this.state.vehicles.find(x => x.vehiclenumber === plate);
      if (vehicle) {
        return vehicle.id;
      } else {
        return null;
      }
    }
  };

  getVehicles = () => {
    trackPromise(
        API.getVehiclesMin(localStorage.getItem('GlobalCustomerId')).then(res => {
          this.setState({ vehicles: res.vehicles });
        })
        .catch(error => {
          toast.error(error.toString());
        })
      )
  };

  generatePlan() {
    return trackPromise(fetch(APIS.API_REST_PLANS_HISTORICS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      method: "POST",
      body: '{"name": "' + this.state.name + '", "definitive": ' + this.state.isDefinitive + '}',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => {
        if (response.status !== 201) {
          throw new Error("Error en el servidor guardando el plan de mantenimiento");
        }
        return response.json();
      })
      .then(json => {
        $('#savePlan').modal('hide')
        toast.success("Plan de mantenimiento guardado exitosamente");
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  getCatalogues() {
    //CATALOGUES
    fetch(APIS.FILTER_CITIES.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json())
      .then(json => {
        this.setState({ catCities: json.cities });
        $('#catCities').selectpicker({width: 'fit'});
      })
    fetch(APIS.FILTER_VEHICLETYPES.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ catVehicleTypes: json.vehicletypes });
        $('#catVehicleTypes').selectpicker({width: 'fit'});
      })
    fetch(APIS.FILTER_VEHICLEGROUPS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ catFront1: json.vehiclegroups });
        $('#catFront1').selectpicker({width: 'fit'});
      })
    fetch(APIS.FILTER_COSTCENTERS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ catFront2: json.costcenters });
        $('#catFront2').selectpicker({width: 'fit'});
      })

    fetch(APIS.API_REST_PLANS_CATALOGUES_ACTIVITIES, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ catActivities: json.activities });
        $('#catActivities').selectpicker({width: 'fit'});
      })
    fetch(APIS.API_REST_PLANS_CATALOGUES_ALERTS, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ catAlerts: json.alerts });
        $('#catAlerts').selectpicker({width: 'fit'});
      })
    fetch(APIS.API_REST_PLANS_CATALOGUES_LIVES, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ catLives: json.lives });
        $('#catLives').selectpicker({width: 'fit'});
      })
    fetch(APIS.API_REST_PLANS_CATALOGUES_WASTAGES, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ catWastages: json.wastages });
        $('#catWastages').selectpicker({width: 'fit'});
      })
  }

  getData = () => {
    this.setState({ planByWorksheet: null, planByChange: null, planByChangeVehicle: null, planByRotationVehicletype: null, planByRotationVehicle: null, planByActivities: null, planByNovelties: null, planByAlerts: null, planByDepths: null, planByDepthsVehicle: null, planByRequiredTires: null, planByRequiredTiresVehicle: null, planByLoweringTires: null, planByLoweringTiresVehicle: null });
    let { vehiculo, tipo_vehiculo, ciudad, frente_fijo, frente_operacional, actividad, alerta, vida, desgaste } = this.state;
    let str = "?";
    if (!!vehiculo) {
      if(vehiculo.includes(",")){
        for(var i=0;i<vehiculo.split(",");i++){
          if (this.getVehicleId(vehiculo.split(",")[i]) === null) {
            toast.error("el vehículo " + vehiculo.split(",")[i] + " tiene una placa/número que no existe");
          }else{
            str = str + "vehicle=" + this.getVehicleId(vehiculo.split(",")[i]) + "&";
          }
        }
      }else{
        if (this.getVehicleId(vehiculo) === null) {
          toast.error("el vehículo " + vehiculo + " tiene una placa/número que no existe");
        }else{
          str = str + "vehicle=" + this.getVehicleId(vehiculo) + "&";
        }
      }
    }
    if (!!tipo_vehiculo) {
      str = str + "vehicle_type=" + tipo_vehiculo + "&";
    }
    if (!!ciudad) {
      str = str + "city=" + ciudad + "&";
    }
    if (!!frente_fijo) {
      str = str + "cost_center=" + frente_fijo + "&";
    }
    if (!!frente_operacional) {
      str = str + "vehicle_group=" + frente_operacional + "&";
    }
    if (!!vida) {
      str = str + "lifetime=" + vida + "&";
    }
    if (!!desgaste) {
      str = str + "wear=" + desgaste + "&";
    }
    if (!!actividad) {
      str = str + "activity=" + actividad + "&";
    }
    if (!!alerta) {
      str = str + "alerta=" + alerta + "&";
    }
    let route1 = !this.props.isCurrent ? "/" + this.props.match.params.id : ''
    fetch(APIS.API_REST_WORKSHEET.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route1 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByWorksheet: json.worksheet });
        
      })
    let route2 = !this.props.isCurrent ? "/" + this.props.match.params.id : ""
    fetch(APIS.API_REST_CHANGES_AXLE.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route2 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByChange: json.changes_per_axle });
        this.setDatatable();
      })
      fetch(APIS.API_REST_CHANGES_AXLE_VEHICLE.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route2 + str, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        }
      })
        .then(response => response.json() )
        .then(json => {
          this.setState({ planByChangeVehicle: json.changes_per_axle_vehicle });
          this.setDatatable();
        })
      fetch(APIS.API_REST_ROTATIONS_VEHICLE.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route2 + str, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        }
      })
        .then(response => response.json() )
        .then(json => {
          this.setState({ planByRotationVehicle: json.rotations_by_vehicles });
          this.setDatatable();
        })
      fetch(APIS.API_REST_ROTATIONS_VEHICLETYPE.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route2 + str, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        }
      })
        .then(response => response.json() )
        .then(json => {
          this.setState({ planByRotationVehicletype: json.rotations_by_vehicle_type });
          this.setDatatable();
        })


    let route3 = !this.props.isCurrent ? "/" + this.props.match.params.id : ""
    fetch(APIS.API_REST_ACTIVITY_SUMMARY.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route3 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByActivities: json.activity_summary });
        this.setDatatable();
      })
    let route4 = !this.props.isCurrent ? "/" + this.props.match.params.id : ""
    fetch(APIS.API_REST_LOWERING_TIRES.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route4 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByLoweringTires: json.tires_for_lowering });
        this.setDatatable();
      })
    fetch(APIS.API_REST_LOWERING_VEHICLE.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route4 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByLoweringTiresVehicle: json.tires_to_be_lowered_per_vehicle });
        this.setDatatable();
      })
    let route5 = !this.props.isCurrent ? "/" + this.props.match.params.id : ""
    fetch(APIS.API_REST_REQUIRED_TIRES.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route5 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByRequiredTires: json.required_tires });
        this.setDatatable();
      })
    fetch(APIS.API_REST_REQUIRED_TIRES_VEHICLE.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route5 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByRequiredTiresVehicle: json.tires_required_per_vehicle });
        this.setDatatable();
      })
    let route6 = !this.props.isCurrent ? "/" + this.props.match.params.id : ""
    fetch(APIS.API_REST_DEPTHS.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route6 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByDepths: json.state_depths });
        this.setDatatable();
      })
    fetch(APIS.API_REST_DEPTHS_VEHICLE.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route6 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByDepthsVehicle: json.state_depths_by_vehicles });
        this.setDatatable();
      })
    let route7 = !this.props.isCurrent ? "/" + this.props.match.params.id : ""
    fetch(APIS.API_REST_ALERTS_SUMMARY.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route7 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByAlerts: json.alerts_summary });
        this.setDatatable();
      })
    let route8 = !this.props.isCurrent ? "/" + this.props.match.params.id : ""
    fetch(APIS.API_REST_NOVELTIES_SUMMARY.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route8 + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => response.json() )
      .then(json => {
        this.setState({ planByNovelties: json.summary_novelties });
        this.setDatatable();
      })
  };

  clearData = () => {
    this.setState({ fecha: null, vehiculo: null, tipo_vehiculo: null, ciudad: null, frente_fijo: null, frente_operacional: null, actividad: null, alerta: "", vida: null, desgaste: "" }, function () {
      $('#catVehicleTypes').val('').selectpicker('refresh');
      $('#catCities').val('').selectpicker('refresh');
      $('#catFront1').val('').selectpicker('refresh');
      $('#catFront2').val('').selectpicker('refresh');
      $('#catActivities').val('').selectpicker('refresh')
      $('#catAlerts').val('').selectpicker('refresh');
      $('#catLives').val('').selectpicker('refresh');;
      $('#catWastages').val('').selectpicker('refresh');
      this.getData();
    });
  }

  clearItemData = (item, itemid) => {
    this.setState({ [item]: "" }, function () {
      if(!!itemid){
        $('#' + itemid).val('').selectpicker('refresh');
      }
      this.getData();
    });
  }

  getPlanByWorksheet = () => {
    let { planByWorksheet, prevTires, nextTires, totalTires } = this.state;
    let pageP, pageN, str;
    if (!!prevTires) {
      str = prevTires;
      pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    if (!!nextTires) {
      str = nextTires;
      pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <h6 className="card-header">
              Hoja de Trabajo
              <div className="float-right">
                <small className="text-muted">Total: {totalTires}</small>&nbsp;
              {
                  !!prevTires ?
                    <button
                      className="btn btn-primary"
                      onClick={(e) => this.getPlans(pageP, this.props.match.params.id)}>
                      <i className="fa fa-arrow-left"></i>
                    </button>
                    :
                    <button
                      className="btn btn-primary"
                      disabled>
                      <i className="fa fa-arrow-left"></i>
                    </button>
                }&nbsp;
              {
                  !!nextTires ?
                    <button
                      className="btn btn-primary"
                      onClick={(e) => this.getPlans(pageN, this.props.match.params.id)}>
                      <i className="fa fa-arrow-right"></i>
                    </button>
                    :
                    <button
                      className="btn btn-primary"
                      disabled>
                      <i className="fa fa-arrow-right"></i>
                    </button>
                }&nbsp;
                <button
                  className="btn btn-success"
                  onClick={(e) => this.exportPlan()}>
                  <i className="fa fa-file-excel"></i>
                </button>
              </div>
            </h6>
            {
            !!planByWorksheet ?
            <div className="card-body">
              <div className="table-responsive" style={{ height: "400px" }}>
                {
                planByWorksheet.length > 0 ?
                <table className="table table-sm table-fit table-bordered">
                  <thead>
                    <tr>
                      {
                        planByWorksheet.length > 0 ?
                          Object.entries(planByWorksheet[0]).map(plan => {
                            if(plan[0] === "vehiculo"){
                              return <th scope="col">PLACA</th>
                            }else{
                              return <th scope="col">{plan[0].replace(/_/g, ' ').toUpperCase()}</th>
                            }
                          })
                          :
                          null
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                        planByWorksheet.map((plan, index) => {
                          return <tr key={index}>
                            {
                              Object.entries(plan).map(pl => {
                                if (pl[0].includes("pos")) {
                                  if (pl[1] <= 3) {
                                    return <td className="bg-red">{pl[1]}</td>
                                  } else if (pl[1] > 3 && pl[1] <= 4) {
                                    return <td className="bg-yellow">{pl[1]}</td>
                                  } else if (pl[1] > 4 && pl[1] <= 5) {
                                    return <td className="bg-blue">{pl[1]}</td>
                                  } else {
                                    return <td>{pl[1]}</td>
                                  }
                                }else {
                                  return <td>{pl[1]}</td>
                                }
                              })
                            }
                          </tr>
                        })
                    }
                  </tbody>
                </table>
                : null
                }
              </div>
            </div>
            :
            <Loading />
            }
          </div>
        </div>
      </div>
    );
  };

  getTable = (plans, isDepths) => {
    if (!plans) return null;
    return (
      <div className="table-responsive" style={{ height: "300px" }}>
        {
        plans.length > 0 ?
        <table className="table table-order table-sm">
          <thead>
            {
            !!isDepths ?
            <tr>
              <th scope="col">Desgaste</th>
              <th scope="col">0</th>
              <th scope="col">1</th>
              <th scope="col">2</th>
              <th scope="col">3</th>
              <th scope="col">3+</th>
              <th scope="col">Total</th>
            </tr>
            : 
            <tr>
              {
                  Object.entries(plans[0]).map(plan => {
                    if(plan[0] === "vehiculo"){
                      return <th scope="col">PLACA</th>
                    }else{
                      return <th scope="col">{plan[0].replace(/_/g, ' ').toUpperCase()}</th>
                    }
                  })
              }
            </tr>
            }
          </thead>
          <tbody>
            {
                plans.map((plan, index) => {
                  if(!!isDepths){
                    return <tr key={index}>
                        <td>{plan["desgaste"]}</td>
                        <td>{plan["0"]}</td>
                        <td>{plan["1"]}</td>
                        <td>{plan["2"]}</td>
                        <td>{plan["3"]}</td>
                        <td>{plan["+3"]}</td>
                        <td>{plan["total"]}</td>
                    </tr>
                  }else{  
                    return <tr key={index}>
                    {
                      Object.entries(plan).map(pl => {
                        if(typeof pl[1] == 'number'){
                          let num;
                          if(pl[1].toString().includes('.')){
                            num = pl[1].toFixed(2)
                            if(num.substr(0,num.indexOf('.')).length >= 4){
                              return <td>{FUNCIONES.numberWithCommas(num)}</td>
                            }else{
                              return <td>{num}</td>
                            }
                          }else{
                            num = pl[1].toFixed(0)
                            if(num.length >= 4){
                              return <td>{FUNCIONES.numberWithCommas(num)}</td>
                            }else{
                              return <td>{num}</td>
                            }
                          }
                        }else{
                          return <td>{pl[1]}</td>
                        }
                      })
                    }
                    </tr>
                  }
                })
            }
          </tbody>
        </table>
        :
        null 
        }
      </div>
    );
  };

  getChart = (plans, type, isDonut, isDepths) => {
    if (!plans) return null;
    let data = [];
    let options = {colors: VARIABLES.GRAPH_COLORS}
    if(!!isDonut){
      options.pieHole = 0.4;
    }
    if (type === "Bar") {
      options.legend = { position: 'top' };
      let rowHeader = [];
      if (plans.length > 0) {
        if(!!isDepths){
          rowHeader = ["Desgaste", "0", "1", "2", "3" , "3+"]
        }else{
          Object.entries(plans[0]).map(plan => {
            if (plan[0].toLowerCase() !== "total") {
              rowHeader.push(plan[0]);
            }
          })
        }
        data.push(rowHeader);
      }
      if (plans.length > 0) {
        plans.map((plan, index) => {
          if(!!isDepths){
            if (plan.desgaste !== "total") {
              var planVar = [plan["desgaste"], plan["0"], plan["1"], plan["2"], plan["3"], plan["3+"]]
              data.push(planVar);
            }
          }else{
            var planVar = Object.assign({}, plan);
            delete planVar.total;
            if (plan.desgaste !== "total" && plan.dimension !== "total") {
              data.push(Object.values(planVar));
            }
          }
        })
      }
    } else {
      data.push(["Nombre", "Cantidad"]);
      if (plans.length > 0) {
        plans.map((plan, index) => {
          if (plan["Código"] !== "Total" && plan.dimension !== "total") {
            data.push([Object.values(plan)[1], Object.values(plan)[2]]);
          }
        })
      }
    }
    return (
      <Chart
        width={'100%'}
        height={'300px'}
        chartType={type}
        loader={<div>Cargando...</div>}
        data={data}
        options={options}
        rootProps={{ 'data-testid': '1' }}
      />
    );
  };

  onChange = event => {
    console.log(this.state[event.target.name])
    if(event.target.className === "selectpicker"){
      if(!!this.state[event.target.name]){
        this.setState({ [event.target.name]: this.state[event.target.name] + "," + event.target.value });
      }else{
        this.setState({ [event.target.name]: event.target.value });
      }
    }else{
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  setDatatable = () => {
    $('.table-order').DataTable({
      ordering: false,
      destroy: true,
      paging:   false,
      info:     false,
      searching:false,
      dom: 'Bfrtip',
      buttons: [
        { extend: 'excelHtml5', className: 'btn-primary' } 
      ]
    });
  }

  exportPlan() {
    let route = !this.props.isCurrent ? "/" + this.props.match.params.id : ''

    let { vehiculo, tipo_vehiculo, ciudad, frente_fijo, frente_operacional, actividad, alerta, vida, desgaste } = this.state;
    let str = "?";
    if (!!vehiculo) {
      if(vehiculo.includes(",")){
        for(var i=0;i<vehiculo.split(",");i++){
          if (this.getVehicleId(vehiculo.split(",")[i]) === null) {
            toast.error("el vehículo " + vehiculo.split(",")[i] + " tiene una placa/número que no existe");
          }else{
            str = str + "vehicle=" + this.getVehicleId(vehiculo.split(",")[i]) + "&";
          }
        }
      }else{
        if (this.getVehicleId(vehiculo) === null) {
          toast.error("el vehículo " + vehiculo + " tiene una placa/número que no existe");
        }else{
          str = str + "vehicle=" + this.getVehicleId(vehiculo) + "&";
        }
      }
    }
    if (!!tipo_vehiculo) {
      str = str + "vehicle_type=" + tipo_vehiculo + "&";
    }
    if (!!ciudad) {
      str = str + "city=" + ciudad + "&";
    }
    if (!!frente_fijo) {
      str = str + "cost_center=" + frente_fijo + "&";
    }
    if (!!frente_operacional) {
      str = str + "vehicle_group=" + frente_operacional + "&";
    }
    if (!!vida) {
      str = str + "lifetime=" + vida + "&";
    }
    if (!!desgaste) {
      str = str + "wear=" + desgaste + "&";
    }
    if (!!actividad) {
      str = str + "activity=" + actividad + "&";
    }
    if (!!alerta) {
      str = str + "alerta=" + alerta + "&";
    }


    return trackPromise(fetch(APIS.API_REST_WORKSHEET.replace("customer_id", localStorage.getItem("GlobalCustomerId")) + route + "/export" + str, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Error en el servidor generando el excel");
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'hoja de trabajo.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success("Excel generado exitosamente");
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  render() {
    const { catCities, catVehicleTypes, catFront1, catFront2, catActivities, catAlerts, catLives, catWastages, planByActivities, planByNovelties, planByAlerts, planByDepths, planByDepthsVehicle, planByRequiredTires, planByRequiredTiresVehicle, planByLoweringTires, planByLoweringTiresVehicle, planByChange, planByChangeVehicle, planByRotationVehicle, planByRotationVehicletype } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-6">
                <h2>Plan De Mantenimiento</h2>
              </div>
              <div className="col-md-6 text-right">
                <div className="modal fade" id="savePlan" tabIndex="-1" role="dialog" aria-labelledby="savePlanLabel" aria-hidden="true">
                  <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="savePlanLabel">Plan Actual</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="form-group">
                            <label className="control-label col-md-2 float-left label"  htmlFor="name">Nombre</label>
                            <input type="text" className="form-control col-md-9" name="name" placeholder="Escribe un nombre" value={this.state.name} onChange={this.onChange} />
                          </div>
                          <div className="form-check">
                            <label className="form-check-label col-md-2 float-left">
                              Es Definitivo?
                            </label>
                            <div className="col-sm-10 pull-left">
                              <input className="form-check-input" type="checkbox" name="isDefinitive" checked={this.state.isDefinitive} onChange={this.onChangeCheckbox} />
                            </div>
                          </div>
                        </form>                        
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                        <button type="button" className="btn btn-primary" onClick={(e) => this.generatePlan()}>Guardar</button>
                      </div>
                    </div>
                  </div>
                </div>
                &nbsp;
                <button className="btn btn-light" type="button" onClick={(e) => this.clearData()}><i className="fa fa-times"></i> Limpiar Filtros</button>&nbsp;
                <button className="btn btn-primary text-white" type="button" onClick={(e) => this.getData()}><i className="fa fa-check"></i> Aplicar Filtros</button>
              </div>
            </div>
            <br />
            <div className="card">
              <div className="row card-body">
                <div className="col-md-2">
                  <label>Vehículo</label>
                  <br />
                  <div className="input-group mb-3 flex-nowrap">
                    <input className="form-control" name="vehiculo" id="vehicle" type="text" value={this.state.vehiculo} onChange={this.onChange} />
                    <div className="input-group-append">
                      <button className="btn btn-primary text-white" type="button" onClick={(e) => {this.clearItemData("vehiculo")}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <label>Tipo Vehículo</label>
                  <br />
                  <div className="input-group mb-3 flex-nowrap">
                    <select id="catVehicleTypes" name="tipo_vehiculo" value={this.state.tipo_vehiculo} className="selectpicker" multiple data-live-search="true" onChange={this.onChange}>
                      <option></option>
                      {
                        catVehicleTypes.map(activity => {
                          return <option
                            key={activity.name}
                            value={activity.id}>
                            {activity.name}
                          </option>
                        })
                      }
                    </select>
                    <div className="input-group-append">
                        <button className="btn btn-primary text-white" type="button" onClick={(e) => {this.clearItemData("tipo_vehiculo", "catVehicleTypes")}}><i className="fa fa-times"></i></button>
                      </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <label>Ciudad</label>
                  <br />
                  <div className="input-group flex-nowrap">
                    <select id="catCities" name="ciudad" className="selectpicker" multiple data-live-search="true" value={this.state.ciudad} onChange={this.onChange}>
                      <option></option>
                      {
                        catCities.map(activity => {
                          return <option
                            key={activity.name}
                            value={activity.id}>
                            {activity.name}
                          </option>
                        })
                      }
                    </select>
                    <div className="input-group-append">
                      <button className="btn btn-primary text-white" type="button" onClick={(e) => {this.clearItemData("ciudad","catCities")}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <label>Centro de Costo</label>
                  <br />
                  <div className="input-group mb-3 flex-nowrap">
                    <select id="catFront2" name="frente_fijo" value={this.state.frente_fijo}className="selectpicker" multiple data-live-search="true" onChange={this.onChange}>
                      <option></option>
                      {
                        catFront2.map(activity => {
                          return <option
                            key={activity.name}
                            value={activity.id}>
                            {activity.name}
                          </option>
                        })
                      }
                    </select>
                    <div className="input-group-append">
                      <button className="btn btn-primary text-white" type="button" onClick={(e) => {this.clearItemData("frente_fijo","catFront2")}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <label>Grupo</label>
                  <br />
                  <div className="input-group mb-3 flex-nowrap">
                    <select id="catFront1" name="frente_operacional" className="selectpicker" multiple data-live-search="true" value={this.state.frente_operacional} onChange={this.onChange}>
                      <option></option>
                      {
                        catFront1.map(activity => {
                          return <option
                            key={activity.name}
                            value={activity.id}>
                            {activity.name}
                          </option>
                        })
                      }
                    </select>
                    <div className="input-group-append">
                      <button className="btn btn-primary text-white" type="button" onClick={(e) => {this.clearItemData("frente_operacional","catFront1")}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <label>Actividad</label>
                  <br />
                  <div className="input-group mb-3 flex-nowrap">
                    <select id="catActivities" name="actividad" className="selectpicker" data-live-search="true" value={this.state.actividad} onChange={this.onChange}>
                      <option></option>
                      {
                        catActivities.map(activity => {
                          return <option
                            key={activity.name}
                            value={activity.id}>
                            {activity.name}
                          </option>
                        })
                      }
                    </select>
                    <div className="input-group-append">
                      <button className="btn btn-primary text-white" type="button" onClick={(e) => {this.clearItemData("actividad","catActivities")}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <label>Alerta</label>
                  <br />
                  <div className="input-group mb-3 flex-nowrap">
                    <select id="catAlerts" name="alerta" className="selectpicker" multiple data-live-search="true" value={this.state.alerta} onChange={this.onChange}>
                      <option></option>
                      {
                        catAlerts.map(activity => {
                          return <option
                            key={activity.id}
                            value={activity.id}>
                            {activity.name}
                          </option>
                        })
                      }
                    </select>
                    <div className="input-group-append">
                      <button className="btn btn-primary text-white" type="button" onClick={(e) => {this.clearItemData("alerta","catAlerts")}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-2">
                  <label>Vida</label>
                  <br />
                  <div className="input-group mb-3 flex-nowrap">
                    <select id="catLives" name="vida" className="selectpicker" multiple data-live-search="true" value={this.state.vida} onChange={this.onChange}>
                      <option></option>
                      {
                        catLives.map(activity => {
                          return <option
                            key={activity.id}
                            value={activity.id}>
                            {activity.name}
                          </option>
                        })
                      }
                    </select>
                    <div className="input-group-append">
                      <button className="btn btn-primary text-white" type="button" onClick={(e) => {this.clearItemData("vida","catLives")}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-2">
                  <label>Desgaste</label>
                  <br />
                  <div className="input-group mb-3 flex-nowrap">
                    <select id="catWastages" name="desgaste" className="selectpicker" data-live-search="true" value={this.state.degaste} onChange={this.onChange}>
                      <option></option>
                      {
                        catWastages.map(activity => {
                          return <option
                            key={activity.name}
                            value={activity.id}>
                            {activity.name}
                          </option>
                        })
                      }
                    </select>
                    <div className="input-group-append">
                      <button className="btn btn-primary text-white" type="button" onClick={(e) => {this.clearItemData("desgaste","catWastages")}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div class="card mt-5 mb-5" style={{width: '1200px', margin: '0 auto'}}>
              <div class="card-header">
                Plan de Mantenimiento
              </div>
              <div class="card-body d-flex justify-content-between" style={{textAlign: 'left'}}>
                <div class="card" style={{width: "18rem", height: "15rem", overflow: 'auto'}}>
                  <div class="card-header">
                    Convenciones Plan Mantenimiento
                    <div className="d-flex justify-content-between mt-4" style={{color: '#194774', borderTop: "1px solid rgba(0,0,0,.125)", paddingTop: "10px"}}>
                      <p>Código</p>
                      <p>Actividad</p>
                    </div>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between " style={{height: "40px"}}>
                      <p>c</p>
                      <p>Cambio</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>r</p>
                      <p>Rotación</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>a</p>
                      <p>Alineación</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>au</p>
                      <p>Alineación Urgente</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>fll</p>
                      <p>Faltante Llanta</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>m</p>
                      <p>Marcar Llanta</p>
                    </li>
                  </ul>
                </div>
                <div class="card" style={{width: "25rem", height: "15rem", overflow: 'auto'}}>
                  <div class="card-header">
                    Convenciones Cambios Ejecucion
                    <div className="d-flex justify-content-between mt-4" style={{color: '#194774', borderTop: "1px solid rgba(0,0,0,.125)", paddingTop: "10px"}}>
                      <p>Código</p>
                      <p>Vida</p>
                      <p>Desgaste</p>
                    </div>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between " style={{height: "40px"}}>
                      <p>nn</p>
                      <p>NUEVA</p>
                      <p>NUEVO</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>rn</p>
                      <p>REENCAUCHE</p>
                      <p>NUEVO</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>nm</p>
                      <p>NUEVA</p>
                      <p>MEDIA VIDA</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>rm</p>
                      <p>REENCAUCHE</p>
                      <p>MEDIA VIDA</p>
                    </li>
                  </ul>
                </div>
                <div class="card" style={{width: "25rem", height: "15rem", overflow: 'auto'}}>
                  <div class="card-header">
                    Convenciones Cambios Ejecucion
                    <div className="d-flex justify-content-between mt-4" style={{color: '#194774', borderTop: "1px solid rgba(0,0,0,.125)", paddingTop: "10px"}}>
                      <p>Código</p>
                      <p>Alertas</p>
                    </div>
                  </div>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between " style={{height: "40px"}}>
                      <p>p_a</p>
                      <p>Presion Alta</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>p_b</p>
                      <p>Presion Baja</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>mm_e</p>
                      <p>Milimetraje Eje</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>mm_p</p>
                      <p>Milimetraje Pareja</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>v_p</p>
                      <p>Vida Pareja</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>d_p</p>
                      <p>Dimension Pareja</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>pf_in</p>
                      <p>Falla Interior</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>pf_ex</p>
                      <p>Falla Exterior</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>pf_h_in</p>
                      <p>Falla Hombro Interior</p>
                    </li>
                    <li class="list-group-item d-flex justify-content-between " style={{borderTop: "1px solid rgba(0,0,0,.125)", height: "40px"}}>
                      <p>pf_h_ex</p>
                      <p>Falla Hombro Exterior</p>
                    </li>
                  </ul>
                </div>


                
              </div>
            </div>


            {this.getPlanByWorksheet()}
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Cambios por Eje
                  </h6>
                  <div className="card-body">
                    { !!planByChange
                      ? this.getTable(planByChange)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Cambios por Eje/Vehículo
                  </h6>
                  <div className="card-body">
                    { !!planByChangeVehicle
                      ? this.getTable(planByChangeVehicle)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Rotaciones por Tipo Vehículo
                  </h6>
                  <div className="card-body">
                    { !!planByRotationVehicletype
                      ? this.getTable(planByRotationVehicletype)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Rotaciones por Vehículos
                  </h6>
                  <div className="card-body">
                    { !!planByRotationVehicle
                      ? this.getTable(planByRotationVehicle)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <h5>Resúmenes Generales</h5>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Resumen Actividades
                  </h6>
                  <div className="card-body">
                    { !!planByActivities
                      ? this.getTable(planByActivities)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Resumen Actividades
                  </h6>
                  <div className="card-body">
                    { !!planByActivities
                      ? this.getChart(planByActivities, "PieChart", true)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Llantas Requeridas
                  </h6>
                  <div className="card-body">
                    { !!planByRequiredTires
                      ? this.getTable(planByRequiredTires)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Llantas Requeridas por Vehículo
                  </h6>
                  <div className="card-body">
                    { !!planByRequiredTiresVehicle
                      ? this.getTable(planByRequiredTiresVehicle)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Llantas Por Bajar
                  </h6>
                  <div className="card-body">
                    { !!planByLoweringTires
                      ? this.getTable(planByLoweringTires)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Llantas Por Bajar por Vehículo
                  </h6>
                  <div className="card-body">
                    { !!planByLoweringTiresVehicle
                      ? this.getTable(planByLoweringTiresVehicle)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Estado Profundidades
                  </h6>
                  <div className="card-body">
                    { !!planByDepths
                      ? this.getTable(planByDepths, true)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Estado Profundidades por Vehículo
                  </h6>
                  <div className="card-body">
                    { !!planByDepthsVehicle
                      ? this.getTable(planByDepthsVehicle)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Alertas
                  </h6>
                  <div className="card-body">
                    { !!planByAlerts
                      ? this.getTable(planByAlerts)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Alertas
                  </h6>
                  <div className="card-body">
                    { !!planByAlerts
                      ? this.getChart(planByAlerts, "PieChart", true)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Antecedentes
                  </h6>
                  <div className="card-body">
                    { !!planByNovelties
                      ? this.getTable(planByNovelties)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <h6 className="card-header">
                    Antecedentes
                  </h6>
                  <div className="card-body">
                    { !!planByNovelties
                      ? this.getChart(planByNovelties, "PieChart", true)
                      : <Loading />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(Plan);