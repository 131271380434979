import { REQ_TIREBRANDS_SUCCESS, REQ_TIREDBRANDS_MIN_SUCCESS } from '../types/TireBrandsCatalogueTypes'
import { setErrorAction } from '../actions/MapErrorsAction'
import API from '../../constants/api'




export const getTireBrandsCatalogueAction = (customer_id) => {
  return (dispatch) => {
    API
      .getTireBrandsCatalogue(customer_id)
      .then(res => {
        dispatch({
          type: REQ_TIREBRANDS_SUCCESS,
          payload: res.TireBrands
        })
      })
      .catch(error => dispatch(setErrorAction(error.toString())))
  }
}

export const getTireBrandsCatalogueAction2 = (customer_id) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getTireBrandsCatalogue(customer_id)
        .then(res => {
          if (res.TireBrands) {
            dispatch({
              type: REQ_TIREBRANDS_SUCCESS,
              payload: res.TireBrands
            })
            resolve(res.TireBrands)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}

export const getTireBrandsCatalogueMinAction = (customer_id) => {
  return (dispatch) => {
    API
      .getTireBrandsMinCatalogue(customer_id)
      .then(res => {
        dispatch({
          type: REQ_TIREDBRANDS_MIN_SUCCESS,
          payload: res.TireCatalogue
        })
      })
      .catch(error => dispatch(setErrorAction(error.toString())))
  }
}

export const getTireBrandsCatalogueMinAction2 = (customer_id) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getTireBrandsMinCatalogue(customer_id)
        .then(res => {
          if (res.TireCatalogue) {
            dispatch({
              type: REQ_TIREDBRANDS_MIN_SUCCESS,
              payload: res.TireCatalogue
            })
            resolve(res.TireCatalogue)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}


