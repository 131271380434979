export const DATA_USERS_SUCCESS = "DATA_USERS_SUCCES"
export const DATA_USERS_SEARCH_SUCCESS = "DATA_USERS_SEARCH_SUCCESS"
export const DATA_USERS_SEARCH_CLEAR_SUCCESS = "DATA_USERS_SEARCH_CLEAR_SUCCESS"
export const DATA_USERS_ERROR = "DATA_USERS_ERROR"
export const DATA_USER_SUCCESS = "DATA_USER_SUCCESS"
export const SET_LOADING = "SET_LOADING"
export const DATA_SET_USER_SUCCESS = "DATA_SET_USER_SUCCESS"
export const REGISTER_USER_SUCCES = "DATA_SET_USER_SUCCESS"
export const DATA_USER_PAGINATION_SUCCESS = "DATA_USER_PAGINATION_SUCCESS"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"


export const DATA_ROLES_SUCCESS = "DATA_ROLES_SUCCESS"
export const DATA_PERMISSION_SUCCESS = "DATA_PERMISSION_SUCCESS"