import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsBrand from "../../../constants/actions/Brands";
import * as constantsLine from "../../../constants/actions/Lines";
import * as APIS from "../../../constants/apis";

function onSubmitLine(context, event, data, brand, lines, t) {
  event.preventDefault();

  return dispatch => {
    trackPromise(fetch(`${APIS.API_REST_LINES.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("brand_id", brand.id)}`, {
      method: "POST",
      body: JSON.stringify({
        brand_id: brand.id,
        ...data
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!!status) {
          throw new Error(status);
        } else {
          dispatch(creationSuccess(brand, lines, t));
        }
      })
      .then(() => {
        return fetch(APIS.API_REST_LINES.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("brand_id", brand.id), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.brands-item-3"));
        }
        return response.json();
      })
      .then(json => {
        context.setState({
          name: "",
          code: ""
        });
        dispatch(getBrandSuccess({
          brand: brand,
          lines: json.vehiclelinemodels
        }));
      })
      .catch(error => {
        let fmtError = "";
        if (error.message.indexOf("already exists") >= 0) {
          fmtError = `${t("alerts.brands-item-7") + data.name + t("alerts.brands-item-9")}`
        }
        if (error.message.indexOf("duplicate key") >= 0) {
          fmtError = `${t("alerts.brands-item-8") + data.code + t("alerts.brands-item-9")}`
        }
        dispatch(creationError(fmtError, brand, lines));
      }));
  };
};

export function creationSuccess(brand, lines, t) {
  const json = {
    brand: brand,
    lines: lines,
    message: t("alerts.brands-item-10")
  };
  return {
    type: constantsLine.CREATE_LINE_SUCCESS,
    json
  };
};

export function creationError(error, brand, lines) {
  const json = {
    brand: brand,
    lines: lines,
    error: error.toString()
  };
  return {
    type: constantsLine.CREATE_LINE_ERROR,
    json
  };
};

export function getBrandSuccess(json) {
  return {
    type: constantsBrand.GET_BRAND_SUCCESS,
    json
  };
};

class LineNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      code: ""
    };
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name, code } = this.state;
    const { brand, lines } = this.props;

    return (
      !!brand ?
        <form onSubmit={(event) => this.props.onSubmitLine(this, event,
          this.state, brand,
          lines, this.props.t)}>
          <div className="row">
            <div className="col-md-5">
              <i>&nbsp;</i>
              <input className="form-control"
                onChange={this.onChange}
                name="code"
                placeholder={this.props.t("globals.code")}
                value={code} />
            </div>
            <div className="col-md-5">
              <i className="text-info small">{this.props.t("globals.required")}</i>
              <input className="form-control"
                onChange={this.onChange}
                name="name"
                placeholder={this.props.t("brands.brands-item-1")}
                value={name}
                required />
            </div>
            <div className="col-md-2">
              <i>&nbsp;</i>
              <button className="btn btn-primary w-100"
                type="submit">
                <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
              </button>
            </div>
          </div>
        </form> : null
    );
  };
};

const structuredSelector = createStructuredSelector({
  brand: state => state.brand,
  lines: state => state.lines
});

const mapDispatchToProps = { onSubmitLine };

export default connect(structuredSelector, mapDispatchToProps)(LineNewItem);
