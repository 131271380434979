import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import * as constantsCities from "../../../constants/actions/Cities";

const initialState = {
  cities    : [],
  city      : {},
  message   : null,
  error     : null
};

function rootReducer(state, action) {
  switch(action.type) {
    case constantsCities.GET_CITIES_SUCCESS:
      return {
        cities    : action.json.cities,
        city      : {},
        error     : null
      };
    case constantsCities.GET_CITY_SUCCESS:
      return {
        city     : action.json.city
      };
    case constantsCities.GET_REQUEST_SUCCESS:
      return {
        cities    : action.json.cities,
        city      : action.json.city,
        message   : action.json.message,
      };
    case constantsCities.GET_REQUEST_ERROR:
      return {
        cities    : action.json.cities,
        city      : action.json.city,
        error     : action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
