import React from 'react'
import { Toast } from 'react-bootstrap';
import { AuthUserContext, withAuthorization } from '../../Auth/Session';
import * as APIS from '../../../constants/apis'
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import { Modal } from 'bootstrap';

class MacrosNewItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            macro_name: null,
            mmc_type: null,
        }
    }

    setInput = (e) => {
        this.setState({macro_name: e.target.value})
    }

    setInput2 = (e) => {
        this.setState({mmc_type: e.target.value})
    }

    onSubmit = async (e) => {
        e.preventDefault()
        let data = {
            name: this.state.macro_name,
            mmc_type: this.state.mmc_type
        }
        trackPromise(fetch(APIS.API_REST_MACRO_MACRO_ACCOUNT_NEW, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
                },
            method: "POST",
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
            if (json.message.name) throw new Error(json.message.name)
            toast.success(this.props.t("macros.m-item-36"))
            this.getRolePerMacro(json.data.id, json.data.mmc_type)   
        })
        .catch(err => {
            toast.error(err.message)
        }))
    }

    getRolePerMacro = (id, type) => {
        let role_id, macro_id;
        if(window.confirm("¿ Deseas asociar a los SUPER ADMINS ?")) {
            trackPromise(fetch(APIS.API_REST_MACRO_ROLE.replace("role_id", id), {
                headers: {
                    "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                    "Accept-language": localStorage.getItem("LanguageSelected"),
                    },
            })
            .then(response => response.json())
            .then(json => {
                if(json.mmc_role) {
                    console.log(json.mmc_role)
                    if (type === "GRN") {
                        role_id = json.mmc_role[0].id
                        macro_id = json.mmc_role[0].mmc_id      
                    } else {
                        role_id = json.mmc_role[1].id
                        macro_id = json.mmc_role[1].mmc_id  
                    }

                    this.addSuperAdmin(macro_id, role_id)
                }
            })
            .catch(err => {
                toast.error(err.message)
            }))
        } else {
            window.history.back();
        }
        
    }

    addSuperAdmin = (id, role) => {
        let str = "?type_account=mmc_account"
        let data = {
            mm_role_id: role,
            mc_mmc_id: id
        }
        trackPromise(fetch(APIS.API_REST_MACRO_POST_ADMIN + str, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                "Accept-language": localStorage.getItem("LanguageSelected"),
                },
            method: "POST",
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => {
            if (json.message != "El registro se guardado correctamente") throw new Error(json.message)
            toast.success("Super Admins Asociados")
            window.history.back();
        })
        .catch(err => {
            toast.error(err.message)
        }))
    }



    render() { 

        console.log(this.state.mmc_type)
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <div  className="container-fluid mt-100">
                        <form onSubmit={e => this.onSubmit(e)}>
                            <div className="d-flex mb-5">
                                <div className="mr-5">
                                    <label>{this.props.t("macros.m-item-33")}</label>
                                    <br></br>
                                    <i className="text-info small" style={{marginTop: "-14px"}}>{this.props.t("globals.required")}</i>
                                </div>
                                <input
                                    className="form-control mt-2 ml-3"
                                    type="text"
                                    id="search"
                                    onChange={(e) => this.setInput(e)}
                                    placeholder={this.props.t("macros.m-item-33")}
                                    required
                                    />
                            </div>
                            <div className="d-flex mb-5">
                                <div className="">
                                    <label>Rol para SUPER ADMINS</label>
                                    <br></br>
                                    <i className="text-info small" style={{marginTop: "-14px"}}>{this.props.t("globals.required")}</i>
                                </div>
                                <select className="form-control"
                                    placeholder={this.props.t("users.users-th-2")}
                                    id="roleIdInput"
                                    name="role_id"
                                    required
                                    value={this.state.mmc_type}
                                    onChange={this.setInput2}>
                                    <option value="">
                                    {this.props.t("users.users-rol-select")}
                                    </option>
                                    <option value="GRN">
                                        Gerente
                                    </option>
                                    <option value="JMTO">
                                        Jefe Mantenimiento
                                    </option>
                                </select>
                            </div>
                            <button disabled={this.state.macro_name ? false : true} className="btn btn-primary mt-1 d-block" style={{height: "40px", margin: "0 auto"}}>{this.props.t("macros.m-item-37")}</button>
                        </form>
                    </div>
                )}
            </AuthUserContext.Consumer>

        );
    }
}



 
const condition = authUser => !!authUser;

export default withAuthorization(condition)(MacrosNewItem);