export const startConfig = (authUser) => {
  const name = localStorage.getItem("GlobalCustomerName");
  const days = localStorage.getItem("GlobalCustomerDays");
  const product = localStorage.getItem("GlobalCustomerProduct");
  if (authUser && name) {
    window.mixpanel.track("Plataforma", {
      uid: authUser.uid,
      Nombre: authUser.username,
      email: authUser.email,
      Rol: authUser.roles,
      Permisos: authUser.permissions,
      "Solo reportes": authUser.only_reports ? "Si" : "No",
      Idioma: authUser.language,
      distinct_id: authUser.email,
      "Cuenta seleccionada": name ? name : " ",
      "Cuenta creada hace": days,
      "Cuenta producto": product,
    });
  }
};
