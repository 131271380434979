import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../../Auth/Session";
import MeasurementList from "../../MeasurementList";
import MeasurementNew from "../..//MeasurementNew";
import * as APIS from "../../../../constants/apis";
import * as VALIDATIONS from "../../../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const escapeRegexCharacters = str =>
  (str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

const getSuggestionValue = suggestion => suggestion;

const renderSuggestion = suggestion => (
  <div>
    {suggestion.plate + " - " + suggestion.vehiclenumber + (suggestion.sap ? ' - ' + suggestion.sap : '')}
  </div>
);

const INITIAL_STATE = {
  vehicle_number: "",
  car_code: "",
  gas_info: null,
  gas_list: [],
  pageP: null,
  pageN: null,
  total: 0,
  cars: [],
  suggestions: [],
  result: null,
  type: "fuel",
  page: 1,
  limit: 20
};

class Distance extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  };

  componentDidMount() {
    this.defaultChange();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setListGas = (fuels) => {
    this.setState({ gas_list: fuels })
  }

  defaultChange = () => {
    this.setState({
      action: "",
      state: "",
      use: "",
      fields: [],
      cars: [],
    });
    trackPromise(fetch(APIS.API_REST_VEHICLES_MIN_INFO.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        this.setState({ cars: json.vehicles });
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.fuel-item-5")
        }
        toast.error(err.toString());
        this.setState({
          error: err
        });
      }));
  };

  onSelectPlate = (value) => {
    this.setState({ vehicle_number: value });
  };

  onChangePlate = (event, { newValue }) => {
    this.setState({ vehicle_number: newValue });
  };

  onSubmit = (event, page, limit) => {
    if (!!event) event.preventDefault();
    if (isInternetConnected(toast)) return;

    const vehicle_number = this.state.vehicle_number;
    trackPromise(fetch(`${APIS.API_REST_VEHICLES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${vehicle_number}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(this.props.t("alerts.fuel-item-10"));
        }
        this.setState({ result: json });
      })
      .then(() => {
        this.setState({ page: page, limit: limit });
        var str = "?page=" + page + "&limit=" + limit;
        return fetch(`${APIS.API_REST_FUELS_HISTORY.replace("customer_id", localStorage.getItem("GlobalCustomerId"))
          .replace("vehicle_id", this.state.vehicle_number) + str}`, {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
      })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        let pageP, str, pageN;
        if (!!json.previus) {
          str = json.previus;
          pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
        }
        if (!!json.next) {
          str = json.next;
          pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
        }
        this.setState({ total: json.total, gas_list: json.combustibles, pageP: pageP, pageN: pageN });
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("alerts.fuel-item-5");
        }
        toast.error(err.toString());
        this.setState({ error: err });
      }));
  };

  getSuggestions = value => {
    let cars = this.state.cars;
    let fmtSuggestions = cars.filter(car => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return (
        car.plate.toLowerCase().search(fmtValue) >= 0 ||
        car.vehiclenumber.toLowerCase().search(fmtValue) >= 0 || 
        car.sap !== null && car.sap.toLowerCase().search(fmtValue) >= 0
      )
    });
    return fmtSuggestions.slice(0, 20);
  };

  onChangePlate = (event, { newValue }) => {
    if (typeof newValue === "object" && newValue !== null) {
      this.setState({
        vehicle_number: newValue.id,
        car_code: newValue.plate + " - " + newValue.vehiclenumber + (newValue.sap !== null ? " - " + newValue.sap : ''),
      });
    } else {
      let cars = this.state.cars;
      for (let i = 0; i < cars.length; i++) {
        if (cars[i].plate.toLowerCase().trim() ===
          newValue.toLowerCase().trim()) {
          this.setState({
            vehicle_number: cars[i].id,
            car_code: cars[i].plate + " - " + cars[i].vehiclenumber + (cars[i].sap !== null ? " - " + cars[i].sap : ''),
          });
          break;
        } else {
          this.setState({
            vehicle_number: 0,
            car_code: newValue
          });
        }
      }
    }
  };

  onPlatesFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onPlatesClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
  };

  render() {
    const { vehicle_number, car_code, gas_list, result, pageP, pageN,
      suggestions, type } = this.state;

    let plateProps = {
      placeholder: this.props.t("alerts.fuel-item-11"),
      value: car_code,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "1%",
        marginBottom: 0
      },
      onChange: this.onChangePlate
    };

    let isInvalidCarCode = !vehicle_number;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-12">
                <h3>{this.props.t("measure.client-fuel-1")}</h3>
              </div>
            </div>
            <br></br>
            <form onSubmit={(e) => this.onSubmit(e, this.state.page, this.state.limit)}>
              <div className="row">
                <div className="col-sm-2">
                  <label htmlFor="inputCarCode">{this.props.t("measure.client-fuel-2")}</label>
                </div>
                <div className="col-sm-10 input-group mb-3">
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onPlatesFetchRequested}
                    onSuggestionsClearRequested={this.onPlatesClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    shouldRenderSuggestions={() => (true)}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={plateProps}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary text-white"
                      type="submit"
                      disabled={isInvalidCarCode}><i className="fa fa-search"></i>  {this.props.t("measure.client-fuel-3")}</button>
                  </div>
                </div>
              </div>
            </form>
            {!!result ?
              <div className="card mb-5 text-center">
                <div className="card-header">
                  {this.props.t("measure.client-fuel-4")}
                </div>
                <div className="card-body">
                  <ul className="list-group">
                    <li className="list-group-item">
                      {this.props.t("measure.client-fuel-5")}: {!!result.fuel_km_info.kilometers ?
                        result.fuel_km_info.kilometers : 0}
                    </li>
                    <li className="list-group-item">
                      {this.props.t("measure.client-fuel-6")}: {result.worktype === "km" ? this.props.t("measure.client-item-2") : this.props.t("measure.client-item-3")}
                    </li>
                    <li className="list-group-item">
                      {this.props.t("measure.client-fuel-7")}: {!!result.fuel_km_info.fuel_type ?
                        result.fuel_km_info.fuel_type :
                        "No disponible"}
                    </li>
                    <li className="list-group-item">
                      {this.props.t("measure.client-fuel-8")}: {result.fuel_km_info
                        .fuel_quantity ?
                        result.fuel_km_info
                          .fuel_quantity :
                        "No disponible"}
                    </li>
                  </ul>
                </div>
                <div className="card-footer text-muted">
                  {this.props.t("measure.client-fuel-9")}: {!!result.fuel_km_info
                    .fecha_inspeccion ?
                    moment.utc(result.fuel_km_info
                      .fecha_inspeccion)
                      .local().locale("es")
                      .format("LLL") :
                    "No disponible"}
                </div>
              </div> : null}
            {!!result ?
              !!authUser.permissions.create ?
                <MeasurementNew
                  t={this.props.t}
                  type={type}
                  carNumber={vehicle_number}
                  currentKm={this.state.result.km_recorrido}
                  onSubmitCar={(e) => this.onSubmit(e, this.state.page, this.state.limit)}
                /> : null
              : null
            }
            {!!result && !!gas_list ?
              <MeasurementList
                t={this.props.t}
                worktype={result.worktype}
                type={type}
                carNumber={vehicle_number}
                pageN={pageN}
                pageP={pageP}
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                fuels={gas_list}
                setListGas={this.setListGas}
                authUser={authUser}
                onSubmitCar={(e, page, limit) => this.onSubmit(e, page, limit)}
              /> : null}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(Distance);
