import moment from "moment";
import chunk from "lodash/chunk";
import Q from "q";
import React from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as APIS from "../../constants/apis";

const numberOfItems = 10;

class InvoiceItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tires: [], page: 1, };
  };

  componentDidMount() {
    this.getInvoice(this.props.match.params.id, this.props.match.params.invoiceid);
  };

  getInvoice = (id, invoiceid) => {
    return trackPromise(fetch(`${APIS.API_REST_INVOICES.replace("customer_id", id)}` + '/' + invoiceid + '/details', {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(this.props.t("alerts.invoice-item-1"))
        }
        return response.json();
      })
      .then(json => {
        this.setState({ tires: json.tires });
      })
      .catch(error => {
        throw new Error(this.props.t("alerts.invoice-item-1"))
      }))
  };

  getRows = (rows, page) => {
    const chunks = chunk(rows, numberOfItems);
    if (!!chunks[page - 1]) {
      return chunks[page - 1];
    }
    return [];
  };

  onPageChange = event => {
    this.setState({ page: event.selected + 1 });
  };

  getTiresList = (tires, authUser) => {
    if (!tires) return null;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("invoice.inv-item-10")}</th>
              <th scope="col">{this.props.t("invoice.inv-item-11")}</th>
              <th scope="col">{this.props.t("invoice.inv-item-12")}</th>
              <th scope="col">{this.props.t("invoice.inv-item-13")}</th>
              <th scope="col">{this.props.t("invoice.inv-item-14")}</th>
              <th scope="col">{this.props.t("invoice.inv-item-15")}</th>
            </tr>
          </thead>
          <tbody>
            {
              this.getRows(tires, this.state.page).map((tire, i) => {
                return <tr id={tire.id} key={i}>
                  <td>
                    {tire.marca}
                  </td>
                  <td>
                    {tire.modelo}
                  </td>
                  <td>
                    {tire.dimension}
                  </td>
                  <td>
                    { !!tire.retreadbrand ? tire.retreadbrand + " " + tire.retreadbrand + tire.retreadimention : ""}
                  </td>
                  <td>
                    {tire.codigo}
                  </td>
                  <td>
                    {tire.vehiculo}
                  </td>
                </tr>
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td colspan="6">
        {
          Math.ceil(tires.length / numberOfItems) > 1 ?
            <ReactPaginate
              pageCount={Math.ceil(tires.length / numberOfItems)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={3}
              onPageChange={this.onPageChange}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link text-white"}
              previousLinkClassName={"page-link text-white"}
              nextLinkClassName={"page-link text-white"}
              activeClassName={"active"} /> :
            null
        }
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  generateExcel() {
    return trackPromise(fetch(`${APIS.API_REST_INVOICES.replace("customer_id", this.props.match.params.id)}` + '/' + this.props.match.params.invoiceid + '/details?export=true', {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(this.props.t("alerts.ins-one-item-73"));
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.props.t("alerts.invoice-item-5")}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success(this.props.t("alerts.ins-one-item-75"));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-6">
                <h3>{this.props.t("invoice.inv-item-16")}</h3>
                <p>{this.props.t("invoice.inv-item-17")} <b>{ !!this.state.tires ? this.state.tires.length : 0}</b></p>
              </div>
              <div className="col-md-6">
                <button className="float-right btn btn-success"
                  onClick={(event) => this.generateExcel()}>
                  <i className="fa fa-file-export"></i> {this.props.t("globals.exports")}
                  </button>
              </div>
            </div>
            <br></br>
            {this.getTiresList(this.state.tires, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const condition = authUser =>
  !!authUser

export default withAuthorization(condition)(InvoiceItem);
