import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsBrand from "../../../../constants/actions/TireBrands";
import * as constantsModels from "../../../../constants/actions/TireModels";
import * as APIS from "../../../../constants/apis";

function deleteDimension(id, model, dimensions, t) {
  if (window.confirm(t("alerts.r-tire-item-14"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_TIREDIMENSIONS
        .replace("tiremodel_id", model.id)}/${id}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }, method: "DELETE"
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(t("alerts.r-tire-item-4"));
          }
          return response.json();
        })
        .then(json => {
          dispatch(getReqSuccess(model, dimensions, json.message));
          return fetch(APIS.API_REST_TIREDIMENSIONS
            .replace("tiremodel_id", model.id), {
            headers: {
              "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            }
          });
        })
        .then(response => response.json())
        .then(json => {
          dispatch(getDimensionsSuccess({
            model: model,
            dimensions: json.Dimentions
          }));
        })
        .catch(error => {
          dispatch(getReqError(model, dimensions, error.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(getDimensionsSuccess({
        model: model,
        dimensions: dimensions
      }));
    };
  }
};

export function getDimensionsSuccess(json) {
  return {
    type: constantsModels.GET_MODEL_SUCCESS,
    json
  };
};

export function getReqSuccess(model, dimensions, message) {
  const json = {
    model: model,
    dimensions: dimensions,
    message: message
  };
  return {
    type: constantsBrand.REQUEST_SUCCESS,
    json
  };
};

export function getReqError(model, dimensions, error) {
  const json = {
    model: model,
    dimensions: dimensions,
    error: error
  };
  return {
    type: constantsBrand.REQUEST_ERROR,
    json
  };
};

class TireDimensionDeleteItem extends React.Component {
  render() {
    const { model, dimensions, dimensionDelete, deleteDimension } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteDimension(dimensionDelete.id, model,
          dimensions, this.props.t)}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({
  model: state => state.model,
  dimensions: state => state.dimensions
});

const mapDispatchToProps = { deleteDimension };

export default connect(structuredSelector, mapDispatchToProps)(TireDimensionDeleteItem);
