import moment from "moment";
import chunk from "lodash/chunk";
import React from "react";

import { AuthUserContext, withAuthorization } from "../../../Auth/Session";

import * as APIS from "../../../../constants/apis";

class TireHistoryList extends React.Component {
  constructor() {
    super();

    this.state = { inspections: [], worktype: "", tire: {} };
  };

  async componentDidMount() {
    const tirecode = this.props.match.params.id;
    let responseInspections = await fetch(`${APIS.API_REST_INSPECTIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tire_id",tirecode)}`, {
      headers:{
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      } });
    let jsonInspections = await responseInspections.json();

    let responseTire = await fetch(`${APIS.API_REST_TIRES.replace("customer_id",
    localStorage.getItem("GlobalCustomerId"))}/${tirecode}`, {
      headers:{
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }});
      let jsonTire = await responseTire.json(); 


    this.setState({inspections: jsonInspections.Inspecciones, worktype: jsonInspections.worktype, tire: jsonTire });
  };

  getLastInspectionsView = (result,worktype) => {
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th>{this.props.t("client-tires.client-his-1")}</th>
                  <th>{this.props.t("client-tires.client-his-2")}</th>
                  <th>{this.props.t("client-tires.client-his-3")}</th>
                  <th>{this.props.t("client-tires.client-his-4")}</th>
                  <th>{this.props.t("client-tires.client-his-5")}</th>
                  <th>{this.props.t("client-tires.client-his-6")}</th>
                  <th>{this.props.t("client-tires.client-his-7")}</th>
                  <th>{this.props.t("client-tires.client-his-8")}</th>
                  <th>{this.props.t("client-tires.client-his-9")}</th>
                  <th>{this.props.t("client-tires.client-his-10")}</th>
                  <th>{this.props.t("client-tires.client-his-11")}</th>
                  <th>{this.props.t("client-tires.client-his-16")}</th>
                  <th>{this.props.t("client-tires.client-his-17")}</th>
                  <th>{this.props.t("client-tires.client-his-12")}</th>
                  <th>{this.props.t("client-tires.client-his-13")}</th>
                  <th>{this.props.t("client-tires.client-his-14")}</th>
                  <th>{this.props.t("client-tires.client-his-15")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.getRows(result, 1).map((ins, i) => {
                    return <tr key={i}>
                      <td>{ins.tipo_inspeccion}</td>
                      <td>{ins.ult_prof_centro_exterior}</td>
                      <td>{ins.ult_prof_centro}</td>
                      <td>{ins.ult_prof_interior}</td>
                      <td>{ins.kilometers}</td>
                      <td>{ins.km_recorrido}</td>
                      <td>{ins.mm_gastados}</td>
                      <td>{ins.km_mm === null ? null : ins.km_mm.toFixed(2)}</td>
                      <td>{ins.mm_cost}</td>
                      <td>{ins.km_cost === null ? null : ins.km_cost.toFixed(2)}</td>
                      <td>
                        {
                          !!ins.vehicle_plate ?
                            "Montada" :
                            ins.ubicacion_final
                        }
                      </td>
                      <td>
                        {
                          !!ins.vehicle_plate ?
                            ins.vehicle_plate :
                            "No aplica"
                        }
                      </td>
                      <td>
                      {ins.order === null ? "No aplica" : ins.order}
                      </td>
                      <td>
                        {
                          !!ins.posicion_final ?
                            ins.posicion_final :
                            "No aplica"
                        }
                      </td>
                      <td>{ins.created_by ? ins.created_by : ins.update_by}</td>
                      <td>{moment.utc(ins.fc_ultima_inspeccion).local()
                                 .locale("es").format("LLL")}</td>
                      <td>{moment.utc(ins.fecha_actualizacion).local()
                                 .locale("es").format("LLL")}</td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
          <br />
        </div>
      </div>
    );
  };

  getRows = (rows, page) => {
    const orderedRows = rows.sort((a, b) => {
      return (new Date(b.fc_ultima_inspeccion) -
              new Date(a.fc_ultima_inspeccion)) ||
             (new Date(b.fecha_actualizacion) -
              new Date(a.fecha_actualizacion));
    });
    const chunks = chunk(orderedRows, 15);
    if(!!chunks[page-1]){
      return chunks[page-1];
    }
    return [];
  };

  render() {
    console.log(this.state.tire)
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("client-tires.client-his-list")}</h3>
            <br />
            
            <div className="text-center v-scroll">
              <table className="table table-striped table-bordered table-sm mb-0">
                <tbody>
                  <tr>
                    <td className="w-50">
                    {this.props.t("client-tires.client-item-6")}:
                    </td>
                    <td className="w-50">
                      {this.state.tire.codigo ?
                        this.state.tire.codigo : <span>Cargando...</span>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            {
              !!this.state.inspections && this.state.inspections.length > 0 ?
                this.getLastInspectionsView(this.state.inspections, this.state.worktype) : null
            }
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(TireHistoryList);