import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as ROLES from "../../constants/roles";
import * as APIS from "../../constants/apis";

class DashboardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboards: [],
      search: ""
    };
  };

  async componentDidMount() {
    let responseDashboards = await fetch(`${APIS.API_REST_DASHBOARDS.replace("customer_id", this.props.match.params.uid)}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    });
    let jsonDashboards = await responseDashboards.json();
    this.setState({ dashboards: jsonDashboards.dashboards });
  };

  deleteDashboard(id) {
    if (window.confirm(this.props.t("alerts.dash-item-1"))) {
      fetch(`${APIS.API_REST_DASHBOARDS.replace("customer_id", this.props.match.params.uid)}/${id}`, {
        headers: {
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "DELETE"
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.dash-item-2"));
          }
          return response.json();
        })
        .then(json => {
          toast.success(this.props.t("alerts.dash-item-3"));
          this.setState({ dashboards: this.state.dashboards.filter(dashboard => dashboard.id != id) })
        })
        .catch(error => {
          toast.error(error.toString());
        });
    }
  };

  geDashboardsList = (dashboards, authUser) => {
    const { search } = this.state;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("dashboard.dash-item-1")}</th>
              <th scope="col">{this.props.t("dashboard.dash-item-2")}</th>
              <th scope="col">{this.props.t("dashboard.dash-item-3")}</th>
              <th scope="col">{this.props.t("dashboard.dash-item-4")}</th>
            </tr>
          </thead>
          <tbody>
            {dashboards.length > 0 && dashboards.filter(dashboard => {
              return dashboard.name.toLowerCase().search(search) >= 0;
            }).map(dashboard => (
              <tr key={dashboard.id}>
                <td>
                  {dashboard.codigo}
                </td>
                <td>
                  {dashboard.name}
                </td>
                <td>
                  {dashboard.tipo}
                </td>
                <td>
                  {!!authUser.permissions.edit ?
                    <Link to={"/dashboards/" + this.props.match.params.uid + "/edit/" + dashboard.id}
                      className="btn btn-primary">
                      <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                  </Link> : null}&nbsp;
                 {!!authUser.permissions.delete ?
                    <button
                      className="btn btn-danger"
                      onClick={(e) => this.deleteDashboard(dashboard.id)}>
                      <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
                </button> : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render() {
    const { dashboards } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <div className="row">
              <div className="col-md-8">
                <h3>{this.props.t("dashboard.dash-item-5")}</h3>
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder={this.props.t("globals.input-search")}
                  onChange={this.onChangeSearch}
                />
              </div>
            </div>
            <br /><br />
            <div className="row">
              <div className="col-md-12">
                {!!authUser.permissions.create ?
                  <Link
                    to={"/dashboards/" + this.props.match.params.uid + "/new"}
                    className="btn btn-primary">
                    <i className="fa fa-plus"></i> {this.props.t("globals.register")}
                </Link> : null}
              </div>
            </div>
            <br /><br />
            {this.geDashboardsList(dashboards, authUser)}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const condition = authUser =>
  !!authUser && authUser.roles === ROLES.ADMIN;

export default withAuthorization(condition)(DashboardList);
