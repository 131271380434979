import React from "react";

class Tires extends React.Component {
  constructor(props) {
    super(props)
  };
  
  render() {
    const { tires } = this.props;

    return (
      <div className="text-center v-scroll">
        <br></br>
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("tire-brands.tire-item-8")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-9")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-10")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-11")}</th>
            </tr>
          </thead>
          <tbody>
            {
              !!tires && tires.map((tire, idx) => {
                return (
                  <tr key={idx}>
                  <td>
                    {tire.codigo}
                  </td>
                    <td>
                      {tire.marca}
                    </td>
                    <td>
                      {tire.modelo}
                    </td>
                    <td>
                      {tire.dimension}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  };
};

export default Tires;
