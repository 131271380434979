import { CREATE_GROUPS_ONE_SUCCESS, CREATE_GROUPS_SUCCESS, CREATE_GROUPS_TWO_SUCCESS, DELETE_GROUPS_ONE_SUCCESS, DELETE_GROUPS_SUCCESS, DELETE_GROUPS_TWO_SUCCESS, PUT_GROUPS_ONE_SUCCESS, PUT_GROUPS_SUCCESS, PUT_GROUPS_TWO_SUCCESS, REQ_GROUPS_ONE_SUCCESS, REQ_GROUPS_SUCCESS, REQ_GROUPS_TWO_SUCCESS } from '../types/groupsTypes'


const INITIAL_STATE = {
  groups: [],
  groupsOne: [],
  groupsTwo: [],
}

export const groupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQ_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload
      }
    case REQ_GROUPS_ONE_SUCCESS:
      return {
        ...state,
        groupsOne: action.payload
      }
    case REQ_GROUPS_TWO_SUCCESS:
      return {
        ...state,
        groupsTwo: action.payload
      }
    case CREATE_GROUPS_SUCCESS:
      state.groups.push(action.payload)
      return {
        ...state,
      }
    case CREATE_GROUPS_ONE_SUCCESS:
      state.groupsOne.push(action.payload)
      return {
        ...state,
      }
    case CREATE_GROUPS_TWO_SUCCESS:
      state.groupsTwo.push(action.payload)
      return {
        ...state,
      }
    case PUT_GROUPS_SUCCESS:
      state.groups.findIndex((elemento, indice) => {
        if (elemento.id === action.payload.id) {
          state.groups.splice(indice, 1, action.payload);
        }
      });
      return {
        ...state,
      }
    case PUT_GROUPS_ONE_SUCCESS:
      state.groupsOne.findIndex((elemento, indice) => {
        if (elemento.id === action.payload.id) {
          state.groupsOne.splice(indice, 1, action.payload);
        }
      });
      return {
        ...state,
      }
    case PUT_GROUPS_TWO_SUCCESS:
      state.groupsTwo.findIndex((elemento, indice) => {
        if (elemento.id === action.payload.id) {
          state.groupsTwo.splice(indice, 1, action.payload);
        }
      });
      return {
        ...state,
      }
    case DELETE_GROUPS_SUCCESS:
      let newGroup = state.groups.filter(
        (gp) => gp.id !== action.payload
      );
      return {
        ...state,
        groups: newGroup,
      };
    case DELETE_GROUPS_ONE_SUCCESS:
      let newGroupone = state.groupsOne.filter(
        (gp) => gp.id !== action.payload
      );
      return {
        ...state,
        groupsOne: newGroupone,
      };
    case DELETE_GROUPS_TWO_SUCCESS:
      let newGrouptwo = state.groupsTwo.filter(
        (gp) => gp.id !== action.payload
      );
      return {
        ...state,
        groupsTwo: newGrouptwo,
      };
    default:
      return state
  }
}


// let newTires = state.tires.filter(
//   (tire) => tire.id !== action.payload.id
// );
