import React from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import * as APIS from "../../../constants/apis";
import LineDeleteItem from "./LineDeleteItem";

class LineEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      line: {
        oldName: props.line.id,
        name: props.line.name,
        code: props.line.code,
        isEditing: false
      }
    };
  };

  onSubmit = event => {
    event.preventDefault();
  };

  onChange = event => {
    let line = { ...this.state.line };
    line[event.target.name] = event.target.value;
    this.setState({ line: line });
  };

  onClickEdit = (brand, t) => {
    if (this.state.line.isEditing) {
      const data = {
        brand_id: this.props.brand.id,
        name: this.state.line.name,
        code: this.state.line.code
      };
      trackPromise(fetch(`${APIS.API_REST_LINES.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("brand_id", brand.id)}/${this.state.line.oldName}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        }
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(t("alerts.brands-item-11"));
          }
          return response.json();
        })
        .then(json => {
          if (!json.message) {
            toast.success(t("alerts.brands-item-12"));
            this.setState({
              line: {
                oldName: this.state.line.oldName,
                name: json.name,
                code: json.code,
                isEditing: !this.state.line.isEditing
              }
            });
          } else {
            throw new Error(json.message);
          }
        })
        .catch(error => {
          toast.error(error.message.toString());
        }));
    } else {
      this.setState({
        line: {
          oldName: this.state.line.oldName,
          name: this.state.line.name,
          code: this.state.line.code,
          isEditing: !this.state.line.isEditing
        }
      });
    }
  };

  getEditItem = (brand, line) => {
    if (!line) return null;
    return (
      <tr>
        <td>
          {
            line.isEditing ?
              <div>
                <i>&nbsp;</i>
                <input name="code"
                  className="form-control"
                  value={line.code}
                  placeholder={this.props.t("globals.code")}
                  onChange={this.onChange} />
              </div>
              : line.code
          }
        </td>
        <td>
          {
            line.isEditing ?
              <div>
                <i className="text-info small">{this.props.t("globals.required")}</i>
                <input name="name"
                  className="form-control"
                  value={line.name}
                  placeholder={this.props.t("brands.brands-item-1")}
                  onChange={this.onChange}
                  required />
              </div>
              : line.name
          }
        </td>
        <td>
          <button className="btn btn-primary"
            onClick={() => this.onClickEdit(brand, this.props.t)}
            type="submit">
            <i className="fa fa-save"></i>  {line.isEditing ? this.props.t("globals.simple-save") : this.props.t("globals.edit")}
          </button>&nbsp;
              <LineDeleteItem
            t={this.props.t}
            brand={brand}
            lineDelete={line}
          />
        </td>
      </tr>
    );
  };

  render() {
    const { line } = this.state;
    const { brand } = this.props;

    return (this.getEditItem(brand, line));
  };
};

export default LineEditItem;
