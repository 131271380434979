import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import * as constantsBrand from "../../constants/actions/Brands";
import * as constantsLine from "../../constants/actions/Lines";

const initialState = {
  brands: [],
  lines: [],
  line: {},
  brand: {},
  message: null,
  error: null
};

function rootReducer(state, action) {
  switch (action.type) {
    case constantsBrand.GET_BRANDS_SUCCESS:
      return {
        brands: action.json.vehiclebrands,
        brand: {},
        error: null
      };
    case constantsBrand.GET_BRAND_SUCCESS:
      return {
        brand: action.json.brand,
        lines: action.json.lines,
        brands: [],
        error: null
      };
    case constantsLine.GET_LINES_SUCCESS:
      return {
        brand: action.json.brand,
        lines: action.json.lines,
        error: null
      };
    case constantsLine.GET_LINES_ERROR:
      return {
        brand: action.json.brand,
        lines: [],
        error: action.json.error,
        message: null
      };
    case constantsLine.CREATE_LINE_SUCCESS:
      return {
        brand: action.json.brand,
        lines: action.json.lines,
        message: action.json.message,
        error: null
      };
    case constantsLine.CREATE_LINE_ERROR:
      return {
        brand: action.json.brand,
        lines: action.json.lines,
        error: action.json.error,
        message: null
      };
    case constantsBrand.GET_REQUEST_SUCCESS:
      return {
        brands: action.json.brands,
        brand: action.json.brand,
        lines: action.json.lines,
        message: action.json.message
      };
    case constantsBrand.GET_REQUEST_ERROR:
      return {
        brands: action.json.brands,
        brand: action.json.brand,
        lines: action.json.lines,
        error: action.json.error
      };
    default:
      return state;
  }
};

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
    )
  );
  return store;
};
