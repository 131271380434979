import { REQ_TECHNICIAN_SUCCESS } from "../types/TechnicianTypes"



const INITIAL_STATE = {
  technicians: []
}

export const technicianReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQ_TECHNICIAN_SUCCESS:
      return {
        ...state,
        technicians: action.payload
      }
    default:
      return state
  }
}