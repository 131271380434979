import { REQ_TIREBRANDS_SUCCESS, REQ_TIREDBRANDS_MIN_SUCCESS } from '../types/TireBrandsCatalogueTypes'


const INITIAL_STATE = {
  tireBrandsCatalogue: [],
  tireBrandsCatalogueMin: []
}

export const tireBrandsCatalogueReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQ_TIREBRANDS_SUCCESS:
     return {
       ...state,
       tireBrandsCatalogue: action.payload
     }
    case REQ_TIREDBRANDS_MIN_SUCCESS:
    return {
      ...state,
      tireBrandsCatalogueMin: action.payload
    }
    default:
      return state
  }
}