import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsCustomer from "../../constants/actions/Customers";
import * as APIS from "../../constants/apis";
import API from "../../constants/api";
import * as ROLES from "../../constants/roles";
import * as VARIABLES from "../../constants/variables";
import { isSuperAdmin } from "../../constants/validations";
import {
  getCusomerAction,
  getCusomerMacroAction,
  getCustomersAction,
} from "../../redux/actions/CustomerAction";
import _debounce from "lodash/debounce";
import Loading from "../Loader/index";

import CustomerDeleteItem from "./CustomerDeleteItem";
import { paginationEstructure } from "../../constants/paginationCode";
import Pagination from "../pagination";
import CustomerActiveItem from "./CustomerActiveItem";

const CustomersList = ({ t }) => {
  const dispatch = useDispatch();
  const data = useSelector((selector) => selector);
  const [loading, setLoading] = useState(true);
  const [isPerMacro, setIsPerMacro] = useState(false);
  const [state, setState] = useState({
    customers: data.customers.customers2,
    customersMacro: data.customers.customersMacro,
    active: true,
    page: 1,
    limit: 20,
  });

  useEffect(() => {
    if (data.customers.customers2.length === 0) {
      getCustomers(state.active, state.page, state.limit);
    } else {
      setLoading(false);
    }
  }, []);

  const getCustomers = (active, page, limit) => {
    setLoading(true);
    let str = `?active=${active}&page=${page}&limit=${limit}`;
    dispatch(getCustomersAction(str))
      .then((response) => {
        setState((state) => ({
          ...state,
          customers: response.customers,
          next: response.next,
          previus: response.previus,
          total: response.total,
        }));
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          toast.error(error.response.data.message.toString());
          setLoading(false);
        }
      });
  };

  const getCustomersMacro = () => {
    setLoading(true);
    let id = sessionStorage.getItem("GlobalMacroSelectedId");
    let str;
    if (sessionStorage.getItem("GlobalMacroSelectedType") === "mc") {
      str = `?type_account=mc_account`;
    } else {
      str = `?type_account=mmc_account`;
    }

    if (state.customersMacro.length === 0) {
      dispatch(getCusomerMacroAction(id, str))
        .then((response) => {
          setState((state) => ({
            ...state,
            customersMacro: response,
          }));
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status !== 200) {
            toast.error(error.response.data.message.toString());
            setLoading(false);
          }
        });
    } else {
      setIsPerMacro(true);
      setLoading(false);
    }
  };

  const debounceFn = _debounce((searchTerm, active) => {
    if (searchTerm !== "") {
      let str = `?search=${searchTerm}&active=${active}`;
      API.getCustomers(str)
        .then((response) => {
          setState((state) => ({
            ...state,
            customers: response.Customers,
          }));
        })
        .catch((error) => {
          if (error.response.status != 200) {
            toast.error(error.response.data.message);
          }
        });
    } else {
      setState((state) => ({
        ...state,
        customers: data.customers.customers2,
      }));
    }
  }, 1000);

  const handleSearch = (value) => {
    debounceFn(value.toLowerCase(), state.active);
  };

  const paginationSubmit = (page, limit) => {
    setState((state) => ({
      ...state,
      page: page,
      limit: limit,
    }));
    getCustomers(state.active, page, limit);
  };

  const filterByActive = (e, filter) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      active: filter,
      page: 1,
      limit: 20,
    }));
    getCustomers(filter, 1, 20);
  };

  const onChangePerMacro = (e, permacro, authUser) => {
    e.preventDefault();
    if (permacro) {
      setIsPerMacro(true);
      getCustomersMacro();
    } else {
      setIsPerMacro(false);
    }
    console.log(permacro);
  };

  const chooseGlobalCustomer = (name, id, product, days, userId) => {
    return trackPromise(
      API.getPendingCount(id).then((resPending) => {
        localStorage.setItem("GlobalPendingCount", resPending.count);
        localStorage.setItem("GlobalPendingCountScrap", resPending.count_waste);
        return API.getPendingWithoutCount(id).then((reswithout) => {
          localStorage.setItem("GlobalPendingWithoutKmCount", reswithout.count);
          return API.getPendingAnalistCount(id).then((resAnalist) => {
            localStorage.setItem(
              "GlobalPendingAnalist",
              resAnalist.Vehiculos.length
            );
            return API.getPendingRotationCount(id).then((resRotation) => {
              localStorage.setItem("GlobalPendingRotation", resRotation.count);
              toast.success(t("alerts.customer-item-1"));
              localStorage.setItem("GlobalCustomerName", name);
              localStorage.setItem("GlobalCustomerId", id);
              localStorage.setItem("GlobalCustomerProduct", product);
              localStorage.setItem("GlobalCustomerDays", days);
              sessionStorage.removeItem("GlobalPilotCount");
              setTimeout(() => window.location.reload(), 1000);
            });
          });
        });
      })
    );
  };

  const generateCongelado = (id) => {
    let customData = {
      customer_id: id,
      observaciones: "",
    };
    trackPromise(
      API.generateFreeze(customData)
        .then((data) => {
          if (data.message === "tires freezed ok") {
            toast.success(t("alerts.customer-item-4"));
          }
        })
        .catch((error) => {
          if (error.response.status !== 201) {
            toast.error(error.response.data.message.toString());
          }
        })
    );
  };

  const renderTbodyCustom = (authUser, data) => {
    return (
      <tbody>
        {data.map((customer, index) => {
          return (
            <tr key={customer.id}>
              <td>{customer.zone}</td>
              <td>{customer.name}</td>
              <td>{customer.analista}</td>
              <td>{customer.account_manager}</td>
              <td>{customer.country}</td>

              {isSuperAdmin(authUser.roles, authUser.restricted_admin) ||
              authUser.roles === ROLES.ADMIN ? (
                <>
                  <td>
                    {customer.reseller_name ? customer.reseller_name : "N/A"}
                  </td>
                  <td>{customer.client_type ? customer.client_type : "N/A"}</td>
                  <td>{customer.product ? customer.product : "N/A"}</td>
                  <td>
                    {customer.product_state ? customer.product_state : "N/A"}
                  </td>
                </>
              ) : null}
              <td>{customer.tire_count}</td>
              <td>{customer.vehicle_count}</td>
              <td>{customer.standby_inspections}</td>
              <td>
                <button
                  className="btn btn-primary"
                  onClick={(e) =>
                    chooseGlobalCustomer(
                      customer.name,
                      customer.id,
                      customer.product,
                      customer.created_days,
                      authUser.id
                    )
                  }
                >
                  <i className="fa fa-briefcase"></i>{" "}
                  {t("customers.customer-choose")}
                </button>
                &nbsp;
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ? (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => generateCongelado(customer.id)}
                    >
                      <i className="fa fa-save"></i>{" "}
                      {t("customers.customer-freeze")}
                    </button>
                    &nbsp;
                  </>
                ) : null}
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ||
                authUser.roles === "ADMIN" ? (
                  <>
                    <Link
                      to={"/invoices/" + customer.id}
                      className="btn btn-primary"
                    >
                      <i className="fa fa-file-invoice-dollar"></i>{" "}
                      {t("customers.customer-billing")}
                    </Link>
                    &nbsp;
                  </>
                ) : null}
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ? (
                  <>
                    <Link
                      to={"/customers/show/" + customer.id}
                      className="btn btn-primary"
                    >
                      <i className="fa fa-eye"></i> {t("globals.see")}
                    </Link>
                    &nbsp;
                  </>
                ) : null}
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ? (
                  <>
                    {!!authUser.permissions.edit &&
                    isSuperAdmin(authUser.roles, authUser.restricted_admin) ? (
                      <>
                        <CustomerActiveItem
                          t={t}
                          customer={customer}
                          clearDelete={clearDelete}
                        />
                        <Link
                          to={"/customers/edit/" + customer.id}
                          className="btn btn-primary"
                        >
                          <i className="fa fa-edit"></i> {t("globals.edit")}
                        </Link>
                      </>
                    ) : null}
                    &nbsp;
                    {!!authUser.permissions.delete ? (
                      <CustomerDeleteItem
                        id={customer.id}
                        clearDelete={clearDelete}
                        t={t}
                      />
                    ) : null}
                  </>
                ) : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const getCustomersListMacro = (authUser) => {
    if (!state.customersMacro.length === 0) return null;

    return (
      <>
        <div className="text-center v-scroll">
          <table className="table table-striped table-bordered table-sm mb-0">
            <thead>
              <tr>
                <th scope="col">{t("customers.customer-item-1")}</th>
                <th scope="col">{t("customers.customer-item-2")}</th>
                <th scope="col">{t("customers.customer-item-3")}</th>
                <th scope="col">{t("customers.customer-item-24")}</th>
                <th scope="col">{t("customers.customer-item-25")}</th>
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ||
                authUser.roles === ROLES.ADMIN ? (
                  <>
                    <th scope="col">{t("customers.customer-item-20")}</th>
                    <th scope="col">{t("customers.customer-item-4")}</th>
                    <th scope="col">{t("customers.customer-item-5")}</th>
                    <th scope="col">{t("customers.customer-item-26")}</th>
                  </>
                ) : null}
                <th scope="col">{t("customers.customer-item-8")}</th>
                <th scope="col">{t("customers.customer-item-9")}</th>
                <th scope="col">{t("customers.customer-item-6")}</th>
                <th scope="col">{t("customers.customer-item-7")}</th>
              </tr>
            </thead>
            {renderTbodyCustom(authUser, state.customersMacro)}
          </table>
        </div>
      </>
    );
  };

  const getCustomersList = (authUser) => {
    if (!state.customers.length === 0) return null;

    const pagination = paginationEstructure(
      state.next,
      state.previus,
      state.total,
      state.limit,
      state.page
    );

    return (
      <>
        <div className="text-center v-scroll">
          <table className="table table-striped table-bordered table-sm mb-0">
            <thead>
              <tr>
                <th scope="col">{t("customers.customer-item-1")}</th>
                <th scope="col">{t("customers.customer-item-2")}</th>
                <th scope="col">{t("customers.customer-item-3")}</th>
                <th scope="col">{t("customers.customer-item-24")}</th>
                <th scope="col">{t("customers.customer-item-25")}</th>
                {isSuperAdmin(authUser.roles, authUser.restricted_admin) ||
                authUser.roles === ROLES.ADMIN ? (
                  <>
                    <th scope="col">{t("customers.customer-item-20")}</th>
                    <th scope="col">{t("customers.customer-item-4")}</th>
                    <th scope="col">{t("customers.customer-item-5")}</th>
                    <th scope="col">{t("customers.customer-item-26")}</th>
                  </>
                ) : null}
                <th scope="col">{t("customers.customer-item-8")}</th>
                <th scope="col">{t("customers.customer-item-9")}</th>
                <th scope="col">{t("customers.customer-item-6")}</th>
                <th scope="col">{t("customers.customer-item-7")}</th>
              </tr>
            </thead>
            {renderTbodyCustom(authUser, state.customers)}
          </table>
        </div>
        <div className="d-flex justify-content-center">
          {state.customers.length > 0 ? (
            <Pagination
              colspan="10"
              pageP={pagination.pageP}
              pageN={pagination.pageN}
              page={state.page}
              limit={state.limit}
              links={pagination.links}
              submit={(page, limit) => paginationSubmit(page, limit, authUser)}
            />
          ) : null}
        </div>
      </>
    );
  };

  const clearDelete = (id) => {
    let data = isPerMacro ? state.customersMacro : state.customers;
    let userss = data.filter((customer) => customer.id !== id);
    if (isPerMacro) {
      setState((state) => ({
        ...state,
        customersMacro: userss,
      }));
    } else {
      setState((state) => ({
        ...state,
        customers: userss,
      }));
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div className="container-fluid mt-100">
          <div className="row">
            <div
              className={
                isSuperAdmin(authUser.roles, authUser.restricted_admin)
                  ? "col-md-6"
                  : "col-md-6"
              }
            >
              <h3>
                {isPerMacro
                  ? t("customers.customer-list") + " Macro"
                  : t("customers.customer-list")}{" "}
              </h3>
            </div>

            <div className="col-md-2">
              {!isPerMacro ? (
                <>
                  <div
                    className="btn-group btn-group-toggle float-right"
                    data-toggle="buttons"
                  >
                    <label
                      className="btn btn-primary active"
                      onClick={(e) => filterByActive(e, true)}
                    >
                      <input
                        type="radio"
                        name="isActive"
                        value={true}
                        autoComplete="off"
                      />
                      {t("globals.actives")}
                    </label>
                    <label
                      className="btn btn-primary"
                      onClick={(e) => filterByActive(e, false)}
                    >
                      <input
                        type="radio"
                        name="isActive"
                        value={false}
                        autoComplete="off"
                      />{" "}
                      {t("globals.deactives")}
                    </label>
                  </div>
                </>
              ) : null}
            </div>
            <div className={"col-md-4"}>
              {!isPerMacro ? (
                <input
                  className="form-control"
                  type="text"
                  id="search"
                  placeholder={t("globals.search-input")}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              ) : null}
            </div>
          </div>
          <br />
          <div>
            {isSuperAdmin(authUser.roles, authUser.restricted_admin) ||
            authUser.roles === "ADMIN" ? (
              <>
                {!!authUser.permissions.create && !isPerMacro ? (
                  <Link to="/customers/new" className="btn btn-primary">
                    <i className="fa fa-plus"></i> {t("globals.register")}
                  </Link>
                ) : null}
              </>
            ) : null}
            {sessionStorage.getItem("GlobalMacroSelectedId") ? (
              <div
                className="btn-group btn-group-toggle float-right"
                data-toggle="buttons"
              >
                <label
                  className="btn btn-primary active"
                  onClick={(e) => onChangePerMacro(e, false, authUser)}
                >
                  <input
                    type="radio"
                    name="hola"
                    value={false}
                    autoComplete="off"
                  />
                  {t("globals.per-client")}
                </label>
                <label
                  className="btn btn-primary"
                  onClick={(e) => onChangePerMacro(e, true, authUser)}
                >
                  <input
                    type="radio"
                    name="hola"
                    value={true}
                    autoComplete="off"
                  />{" "}
                  {t("globals.per-macro")}
                </label>
              </div>
            ) : null}
          </div>
          <br />
          <br />
          <br />
          <br />
          {isPerMacro
            ? getCustomersListMacro(authUser)
            : getCustomersList(authUser)}
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

const condition = (authUser) =>
  !!authUser &&
  (authUser.roles === ROLES.ADMIN ||
    authUser.roles === ROLES.ANALYST ||
    authUser.roles === ROLES.CUSTOMER);

export default withAuthorization(condition)(CustomersList);
