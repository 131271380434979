import React, { Component } from "react";
import GetWasteModal from './Wastels'

const SheetJSFT = ["xlsx"].map(function(x) { return "." + x; }).join(",");

class DataInput extends Component {
  constructor(props) {
    super(props);
    this.state = [
      {
        isViewWastel: false,
      }
    ]
    this.handleChange = this.handleChange.bind(this);
  };

  handleChange = event => {
    const files = event.target.files;
    if(files && files[0]) {
      this.props.handleFile(files[0]);
    }
  };

  render() {
    const { inputKey, exportTemplate, header, novelties } = this.props;

    return (
      <div className="form-group">
        <div className="row">
          <div className={"col-md-8"}>
            <input
              type="file"
              key={inputKey}
              className="form-control"
              id="file"
              style={{ height: "calc(1.5em + .75rem + 7px)" }}
              accept={SheetJSFT}
              onChange={this.handleChange}
            />
          </div>
          {/* <div className={"col-md-4"}>
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#wasteModal"
              onClick={() => this.setState({isViewWastel: true})}
              style={{ width: "100%" }}>
              <i className="fa fa-trash"></i> {this.props.t("client-tires.client-scrap")}
            </button>
            { this.state.isViewWastel && <GetWasteModal t={this.props.t} discards={novelties} int={"1"} /> }
          </div> */}
          <div className="col-md-4">
            <button
              className="btn btn-primary"
              onClick={exportTemplate}
              style={{ width: "100%" }}
              disabled={!header.length}>
              <i className="fa fa-download"></i> {this.props.t("globals.download-excel")}
            </button>
          </div>
        </div>
      </div>
    );
  };
};

export default DataInput;
