import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withFirebase } from "../Auth/Firebase";

const service =
  window.location.hostname === "testing.ruedata.com" ||
  window.location.hostname === "localhost"
    ? "dev/"
    : "prod/";

const CustomerPilotTrial = ({ firebase, t }) => {
  const [days, setDays] = useState(null);
  let customer_id = localStorage.getItem("GlobalCustomerId");
  const db = firebase.doFreeTrial();

  useEffect(() => {
    if (customer_id) {
      const dbRef = db.ref("free-trial/" + service + customer_id);
      dbRef.on("value", (snapshot) => {
        if (snapshot.val()) {
          let today = new Date().toISOString().slice(0, 10);
          let lastDate = snapshot
            .val()
            .free_trial_date.replace(/ /g, ".")
            .split(".")[0];
          let fechaini = new Date(today);
          let fechafin = new Date(lastDate);
          let diasdif = fechafin.getTime() - fechaini.getTime();
          let contdias = Math.round(diasdif / (1000 * 60 * 60 * 24));
          setDays(contdias);
        }
      });
    }
  }, []);

  const closeFinish = () => {
    localStorage.removeItem("GlobalCustomerId");
    localStorage.removeItem("GlobalCustomerName");
    localStorage.removeItem("GlobalCustomerProduct");
    localStorage.removeItem("GlobalPendingAnalist");
    localStorage.removeItem("GlobalPendingWithoutKmCount");
    localStorage.removeItem("GlobalPendingCount");
    localStorage.removeItem("GlobalPendingCountScrap");
    window.location.reload();
  };

  if (days === 0 || days < 0) {
    return (
      <Modal show={true} dialogClassName="modal-sm-mc">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pilotTrialFinish">
            <img src="https://jira-emails.s3.amazonaws.com/pilot_trial_finish.jpg" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-primary mt-3"
            onClick={closeFinish}
          >
            {t("globals.close")}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      {days ? (
        <div className="pilotTrial">
          <div>
            <h4>{days && days}</h4>
            <span>/Dias</span>
          </div>
          <p>{t("customers.customer-item-20")}</p>
        </div>
      ) : null}
    </>
  );
};

export default withFirebase(CustomerPilotTrial);
