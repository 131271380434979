import React from "react";
import { withAuthorization } from "../Auth/Session";

const HomePage = () => (
  <div className="container-fluid  px-0" style={{ backgroundColor: '#fff' }}>
    <img src="/images/banner.png"
      alt="Ruedata - Banner"
      className="img-fluid" />
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);
