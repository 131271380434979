import Q from "q";
import xlsx from "xlsx";
import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../../Auth/Session";
import ErrorsModal from "./ErrorsModal";
import DragDropFile from "./DragDropFile";
import DataInput from "./DataInput";
import OutTable from "./OutTable";

import * as APIS from "../../../../constants/apis";
import * as VALIDATIONS from "../../../../constants/validations";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;
const randomString = Math.random().toString(36);

const INITIAL_STATE = {
  inputKey: randomString,
  done: [],
  notDone: [],
  data: [],
  cols: [],
  vehicles: [],
  errors: []
};

class MultipleFuels extends Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();

    this.serverErrors = [];
    this.handleFile = this.handleFile.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.state = { ...INITIAL_STATE };
  };

  async componentDidMount() {
    let responseVehicles = await fetch(`${APIS.API_REST_VEHICLES_MIN_INFO.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    });
    let jsonVehicles = await responseVehicles.json();
    this.setState({ vehicles: jsonVehicles.vehicles });
  };

  getVehicleId = (plate) => {
    let vehicle = this.state.vehicles.find(x => x.plate === plate);
    if (vehicle) {
      return vehicle.id;
    } else {
      vehicle = this.state.vehicles.find(x => x.vehiclenumber === plate);
      if (vehicle) {
        return vehicle.id;
      } else {
        return null;
      }
    }
  };

  makeCols = refstr => {
    let o = [],
      C = xlsx.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = {
        name: xlsx.utils.encode_col(i),
        key: i
      }
    }
    return o;
  };

  getRepeatedRows = (rowsObjects) => {
    if (rowsObjects.length > 1) {
      let result = Object.values(rowsObjects.reduce((c, v) => {
        let k = v.date + "-" + v.kilometers + "-" + v.hour + "-" + v.plate +
          "-" + v.customer + "-" + v.fuel;
        c[k] = c[k] || [];
        c[k].push(v);
        return c;
      }, {})).reduce((c, v) => v.length > 1 ? c.concat(v) : c, []);
      return result;
    } else {
      return [];
    }
  };

  getDataErrors = (cols, data) => {
    let errors = [];
    let nonRepeatedFields = [];

    // Número de columnas
    if (cols.length !== 7) {
      errors.push({
        message: this.props.t("multiple.global-item-1")
      });
    }

    // Número de datos
    if (data.length < 2) {
      errors.push({
        message: this.props.t("multiple.global-item-2")
      });
    }

    // Columnas necesarias
    let fields = [
      this.props.t("multiple.mes-item-1"),
      this.props.t("multiple.mes-item-2"),
      this.props.t("multiple.mes-item-3"),
      this.props.t("multiple.mes-item-4"),
      this.props.t("multiple.mes-item-18"),
      this.props.t("multiple.mes-item-19"),
      this.props.t("multiple.mes-item-20")
    ];
    for (let i = 0; i < fields.length; i++) {
      if (data[0].indexOf(fields[i]) === -1) {
        errors.push({
          message: this.props.t("multiple.global-item-3") + fields[i] + this.props.t("multiple.global-item-4") + this.props.t("multiple.global-item-5")
        });
      }
    }

    // Validaciones de los datos
    for (let i = 1; i < data.length; i++) {
      let rowObject = {
        date: "",
        hour: "",
        plate: "",
        customer: "",
        kilometers: "",
        idx: (i + 1)
      };
      if (data[i].length < 7) {
        errors.push({
          message: this.props.t("multiple.mes-item-8") + rowObject.idx + this.props.t("multiple.mes-item-9")
        });
      }
      for (let j = 0; j < data[i].length; j++) {
        if (data[0][j] === this.props.t("multiple.mes-item-1")) {
          rowObject.plate = data[i][j];
          if (this.getVehicleId(data[i][j]) === null) {
            errors.push({
              message: this.props.t("multiple.mes-item-8") + (i + 1) + this.props.t("multiple.mes-item-21") + data[0][j]
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.mes-item-4")) {
          rowObject.kilometers = data[i][j];
          if (isNaN(data[i][j])) {
            errors.push({
              message: this.props.t("multiple.mes-item-8") + (i + 1) + this.props.t("multiple.mes-item-22") + data[0][j]
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.mes-item-2")) {
          rowObject.date = data[i][j];
          if (moment(data[i][j], "YYYY-MM-DD").format("YYYY-MM-DD") !==
            data[i][j]) {
            errors.push({
              message: this.props.t("multiple.mes-item-8") + (i + 1) + this.props.t("multiple.mes-item-23")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.mes-item-3")) {
          rowObject.hour = data[i][j];
          const reg = new RegExp("^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$");
          if (!reg.test(data[i][j])) {
            errors.push({
              message: this.props.t("multiple.mes-item-8") + (i + 1) + this.props.t("multiple.mes-item-24")
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.mes-item-18")) {
          rowObject.fuel = data[i][j];
          const validFuels = ["acpm/diesel", "gasolina", "electrico", "gas natural licuado", "gas natural vehicular", "gas licuado del petroleo", "hibrido", "no_aplica", "no_definido"];
          if (validFuels.indexOf(data[i][j].toLowerCase().trim()) === -1) {
            errors.push({
              message: this.props.t("multiple.mes-item-8") + (i + 1) + this.props.t("multiple.mes-item-25") +
                "Los aceptados son acpm/diesel, gasolina, electrico, gas natural licuado, gas natural vehicular, gas licuado del petroleo, hibrido, no_aplica o " +
                "no_definido."
            });
          }
        }
        if (data[0][j] === this.props.t("multiple.mes-item-1")) {
          rowObject.plate = data[i][j];
        }
      }
      nonRepeatedFields.push(rowObject);
    }

    if (errors.length === 0) {
      const repeated = this.getRepeatedRows(nonRepeatedFields);
      if (repeated.length > 0) {
        let indexes = "";
        for (let i = 0; i < repeated.length; i++) {
          indexes += ((i === 0) ? "" :
            ((i === repeated.length - 1) ? " y " : ", ")) +
            repeated[i].idx;
        }
        errors.push({
          message: this.props.t("multiple.mes-item-15") + indexes +
          this.props.t("multiple.mes-item-16")
        });
      }
    }

    return errors;
  };

  handleFile = file => {
    this.setState({ data: [], cols: [], done: [], notDone: [], errors: [] });

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = xlsx.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1, raw: false });
      const cols = this.makeCols(ws["!ref"]);
      const errors = this.getDataErrors(cols, data);
      if (errors.length === 0) {
        this.setState({
          data: data,
          cols: this.makeCols(ws["!ref"]),
          inputKey: Math.random().toString(36)
        });
      } else {
        this.modal.current.showModal(errors);
        this.setState({
          errors: [],
          data: [],
          cols: [],
          inputKey: Math.random().toString(36)
        });
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  exportTemplate = event => {
    event.preventDefault();

    let header = [
      this.props.t("multiple.mes-item-1"),
      this.props.t("multiple.mes-item-2"),
      this.props.t("multiple.mes-item-3"),
      this.props.t("multiple.mes-item-4"),
      this.props.t("multiple.mes-item-18"),
      this.props.t("multiple.mes-item-19"),
      this.props.t("multiple.mes-item-20")
    ];
    const ws = xlsx.utils.aoa_to_sheet([header]);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Plantilla");
    xlsx.writeFile(wb, this.props.t("multiple.mes-item-26") + ".xlsx")
  };

  formatData = (register) => {
    let data = {
      plate: "",
      fecha_inspeccion: "",
      fueltype: "",
      valor_total: "",
      quantity: "",
      kilometers: ""
    };

    let header = this.state.data[0];
    for (let i = 0; i < header.length; i++) {
      if (header[i] === this.props.t("multiple.mes-item-1")) {
        data.plate = register[i];
      } else if (header[i] === this.props.t("multiple.mes-item-2")) {
        data.fecha_inspeccion =
          moment(register[i] + " " + register[i + 1]).utc().format();
      } else if (header[i] === this.props.t("multiple.mes-item-4")) {
        data.kilometers = 1 * register[i];
      } else if (header[i] === this.props.t("multiple.mes-item-18")) {
        data.fueltype = register[i];
      } else if (header[i] === this.props.t("multiple.mes-item-19")) {
        data.valor_total = 1 * register[i];
      } else if (header[i] === this.props.t("multiple.mes-item-20")) {
        data.quantity = 1 * register[i];
      }
    }

    return data;
  };

  saveRegister = (idx, register, authUser) => {
    let deferred = Q.defer();

    if (isInternetConnected(toast)) {
      deferred.reject();
      return deferred.promise;
    }

    let that = this;
    authUser.doGenerateToken().then(function (idToken) {
      localStorage.setItem('firebaseAuthToken', idToken);
    })
    trackPromise(fetch(APIS.API_REST_FUELS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("vehicle_id", that.getVehicleId(register[0])), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(that.formatData(register))
    })
      .then(response => response.json())
      .then(json => {
        if (!!json.message) {
          throw new Error(json.message);
        }
        const done = [...that.state.done];
        done.push(idx);
        that.setState({ done: done });
        deferred.resolve(json);
      })
      .catch(error => {
        let err = error;
        if (valConnection.indexOf(error.toString()) > -1) {
          err = this.props.t("multiple.global-item-6") +
            (idx + 1);
        } else {
          err = this.props.t("multiple.global-item-7") + (idx + 1) + ". " + error.toString();
        }
        that.serverErrors.push({ message: err });
        const notDone = [...that.state.notDone];
        notDone.push(idx);
        that.setState({ notDone: notDone });
        deferred.reject(that.serverErrors);
      }));

    return deferred.promise;
  };

  getCleanedRegisters = registers => {
    let newRegisters = [];

    for (let i = 1; i < registers.length; i++) {
      if (registers[i].length > 0) {
        newRegisters.push(registers[i]);
      }
    }

    return newRegisters;
  };

  async saveRegisters(registers, authUser) {
    let deferred = Q.defer();

    let newRegisters = this.getCleanedRegisters(registers);
    for (let i = 0; i < newRegisters.length; i++) {
      if (this.state.done.indexOf(i) === -1) {
        await this.saveRegister(i, newRegisters[i], authUser)
          .then(() => {
            return true;
          })
          .catch(errors => {
            deferred.reject(errors);
            return deferred.promise;
          });
      }
    }

    deferred.resolve();
    return deferred.promise;
  };

  onSubmit = (event, authUser) => {
    event.preventDefault();
    this.serverErrors = [];
    this.setState({ notDone: [] });

    trackPromise(this.saveRegisters(this.state.data, authUser)
      .then(() => {
        toast.success(this.props.t("multiple.global-item-8"));
        this.setState({
          data: [],
          cols: [],
          done: [],
          notDone: [],
          errors: []
        });
      })
      .catch(errors => {
        this.modal.current.showModal(errors);
      }));
  };

  render() {
    const { data, cols, done, notDone } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">
            <ErrorsModal ref={this.modal} t={this.props.t}/>
            <h3>{this.props.t("measure.client-item-15")}</h3>
            <br /><br />
            <DragDropFile t={this.props.t} handleFile={this.handleFile}>
              <form>
                <div>
                  <DataInput t={this.props.t} handleFile={this.handleFile}
                    exportTemplate={this.exportTemplate}
                    inputKey={this.state.inputKey || ""} />
                  <OutTable t={this.props.t} data={data}
                    cols={cols}
                    done={done}
                    notDone={notDone} />
                  {data.length > 1 ?
                    <div className="row">
                      <div className="col-md-12 mt-5">
                        <button type="submit"
                          className="btn btn-primary"
                          style={{ width: "100%" }}
                          onClick={(event) => this.onSubmit(event, this.props.firebase)}>
                          <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                        </button>
                      </div>
                    </div> : null}
                </div>
              </form>
            </DragDropFile>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(MultipleFuels);
