import React from "react";
import { Link } from "react-router-dom";
import RetreadModelDeleteItem from "./RetreadBandDeleteItem";

class LinesList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      search: ""
    };
  };

  getBandsList = (brand, bands) => {
    const { search } = this.state;

    if (!bands) return null;
    return (
      <div className="text-center v-scroll">
        <br></br>
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("tire-brands.tire-item-1")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-2")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-3")}</th>
            </tr>
          </thead>
          <tbody>
            {
              bands.filter(model => {
                return model.name.search(search) >= 0
              }).map((band, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {band.name}
                    </td>
                    <td>
                      {
                        !!band.active ? "Activo" : "Inactivo"
                      }
                    </td>
                    <td>
                      <Link to={"/retreadbrands/" + brand.id + "/retreadbands/" + band.id + "/tires"}
                        className="btn btn-primary">
                        <i className="fa fa-bullseye"></i> {this.props.t("tire-brands.tire-text")}
                      </Link>&nbsp;
                      {
                        this.props.authUser.roles != "ANALYST" ?
                        <>
                        <Link
                          to={"/retreadbands/" + brand.id + "/edit/" +
                            band.id}
                          className="btn btn-primary">
                          <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                        </Link>&nbsp;
                        <RetreadModelDeleteItem bandDelete={band} t={this.props.t}/>
                        </>
                        :
                        <>
                        <Link
                          to={"/retreadbands/" + brand.id + "/edit/" +
                            band.id}
                          className="btn btn-primary">
                          <i className="fa fa-eye"></i> {this.props.t("globals.see")}
                        </Link>&nbsp;
                        </>
                      }
                      
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  onChange = (event) => {
    this.setState({
      search: event.target.value
    });
  };

  render() {
    const { brand, bands } = this.props;
    const { search } = this.state;

    return (
      <div className="row">
        <div className="col-md-8">
          <h3>{this.props.t("tire-retread.tire-brand-text")}</h3>
        </div>
        <div className="col-md-4">
          <input
            className="form-control"
            id="search"
            placeholder={this.props.t("globals.search-input")}
            value={search}
            onChange={this.onChange}
          />
        </div>
        <div className="col-md-12">
          {this.getBandsList(brand, bands)}
        </div>
      </div>
    );
  };
};

export default LinesList;
