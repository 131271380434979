import React, { useState } from "react";
import { cuseDispatch, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import API from "../../constants/api";

import * as APIS from "../../constants/apis";
import { deleteUserAction } from "../../redux/actions/UsersAction";

const GroupDeleteItem = ({ t, customer, clearDelete }) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const activeOrDeactive = (customers, isActive) => {
    let data = customers
    data.active = isActive;
    if (
      window.confirm(
        `${t("alerts.users-item-4")} ${isActive ? t("alerts.users-item-5") : t("alerts.users-item-6")
        } ${t("alerts.customer-item-6")}`
      )
    ) {
      setIsUpdating(true)
      API
        .putCustomers(data.id, data)
        .then((data) => {
          toast.success(
            `${isActive ? t("alerts.users-item-8") : t("alerts.users-item-9")
            } ${t("alerts.users-item-10")}`
          );
          clearDelete(data.id);
          setIsUpdating(false)
        })
        .catch((error) => {
          if (error.response.status != 200) {
            toast.error(error.response.data.message)
            setIsUpdating(false)
          }
        });
    }
  };

  if (isUpdating) {
    return (
      <button class="btn btn-info" type="button" disabled={isUpdating ? true : false}>
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
      </button>
    )
  }

  return (
    <>
      {
        !customer.active ?
          <button
            className="btn btn-info mr-2"
            data-toggle="modal"
            data-target="#updateStatus"
            onClick={(e) => activeOrDeactive(customer, true)}
          >
            {/* <i className="fa fa-exclamation-triangle"></i>{" "} */}
            {t("globals.active")}
          </button>
          :
          <button
            className="btn btn-info mr-2"
            data-toggle="modal"
            data-target="#updateStatus"
            onClick={(e) => activeOrDeactive(customer, false)}
          >
            {/* <i className="fa fa-exclamation-triangle"></i>{" "} */}
            {t("globals.deactive")}
          </button>
      }
    </>
  )
}

export default GroupDeleteItem
