import React, { Component } from "react";
import Select from "react-select";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import * as APIS from "../../constants/apis";
import API from "../../constants/api";
import { isSuperAdmin } from "../../constants/validations";
import {
  getAllPermission,
  getAllRoles,
  getUserAction,
} from "../../redux/actions/UsersAction";
import { getCusomerAction } from "../../redux/actions/CustomerAction";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import mailgunApi from "../../constants/mailgunApi";

const getCustomers = () => {
  return (dispatch) => {
    dispatch(getCusomerAction());
  };
};

const getPermissions = () => {
  return (dispatch) => {
    dispatch(getAllPermission());
  };
};

const getRoles = () => {
  return (dispatch) => {
    dispatch(getAllRoles());
  };
};

const INITIAL_STATE = {
  display_name: "",
  email: "",
  uid: "",
  only_reports: false,
  customer_id: null,
  role_id: null,
  customers: [],
  customer_product: [],
  permissions: [],
  roles: [],
  permission_id: null,
  id: "",
  data: [],
  active: null,
  language: "",
  is_can_see_depths: null,
  isSuperAdmin: null,
  restricted_admin: false,
  role_name: "",
  is_manager: false,
};

class UserEditItem extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    if (!this.props.permissions) {
      this.props.getPermissions();
    }
    if (!this.props.roles) {
      this.props.getRoles();
    }
    this.props.getCustomers();
  }

  componentDidUpdate(prevProps) {
    const id = this.props.match.params.id;
    if (!prevProps.customers || this.props.customers !== prevProps.customers) {
      this.getUserData(id);
    }
  }

  getUserData(id) {
    if (this.props.permissions && this.props.customers && this.props.roles) {
      trackPromise(
        API.getUser(id).then((jsonUser) => {
          this.setState({
            id: jsonUser.id,
            uid: jsonUser.uid,
            display_name: jsonUser.display_name,
            email: jsonUser.email,
            only_reports: jsonUser.only_reports,
            customer_id: jsonUser.customer_id,
            permission_id: jsonUser.permission_id,
            is_can_see_depths: jsonUser.is_can_see_depths,
            active: jsonUser.active,
            is_manager: jsonUser.is_manager,
            // role_id: jsonUser.role_id,
            language: jsonUser.language,
          });
          return API.getMultiUser(jsonUser.id).then((data) => {
            this.setState({
              data: data.clients_users,
              customers: this.props.customers,
              permissions: this.props.permissions,
              roles: this.props.roles,
            });
            this.setByNewRol(
              jsonUser.role_id,
              jsonUser.restricted_admin,
              jsonUser.is_manager
            );
          });
        })
      );
    }
  }

  getMultiUsers(id) {
    API.getMultiUser(id).then((data) => {
      this.setState({
        data: data.clients_users,
        customers: this.props.customers,
        permissions: this.props.permissions,
        roles: this.props.roles,
      });
    });
  }

  setByNewRol(rolid, restricted_admin, is_manager) {
    if (rolid === 1 && restricted_admin === false) {
      this.setState({ role_id: rolid, role_name: "SADMIN" });
    } else if (rolid === 1 && restricted_admin === true) {
      this.setState({ role_id: rolid, role_name: "ADMIN" });
    } else if (rolid === 2 && is_manager === true) {
      this.setState({ role_id: rolid, role_name: "GERENTE" });
    } else if (rolid === 2 && is_manager === false) {
      this.setState({ role_id: rolid, role_name: "CUSTOMER" });
    } else if (rolid === 3) {
      this.setState({ role_id: rolid, role_name: "ANALYST" });
    } else if (rolid === 4) {
      this.setState({ role_id: rolid, role_name: "TECHNICIAN" });
    }
  }

  onSubmit = (event) => {
    let data = this.state;
    delete data.customers;
    delete data.permissions;
    delete data.roles;
    delete data.data;
    if (data.role_name === "GERENTE") {
      data.only_reports = false;
      data.role_name = "CUSTOMER";
    }
    event.preventDefault();
    event.preventDefault();

    trackPromise(
      API.putUser(this.props.match.params.id, data)
        .then((data) => {
          let message = data.message;
          if (!message) {
            if (this.state.customer_product.length > 0) {
              const body = {
                email: data.email,
                username: data.display_name,
                customers: this.state.customer_product,
              };
              return mailgunApi
                .sendDemoInitEmail(body)
                .then((json) => {
                  toast.success(this.props.t("alerts.users-item-11"));
                  this.props.history.push(ROUTES.USERS);
                })
                .catch((err) => {
                  toast.error(err.response.data.message);
                });
            } else {
              toast.success(this.props.t("alerts.users-item-11"));
              this.props.history.push(ROUTES.USERS);
            }
          } else {
            toast.error(message);
          }
        })
        .catch((error) => {
          toast.error(error.toString());
        })
    );
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onChangeRolesAction = (event) => {
    let rol_name = event.target.value;

    if (rol_name === "SADMIN") {
      this.setState({
        role_id: 1,
        role_name: rol_name,
        isSuperAdmin: true,
        restricted_admin: false,
      });
    } else if (rol_name === "ADMIN") {
      this.setState({
        role_id: 1,
        role_name: rol_name,
        isSuperAdmin: null,
        restricted_admin: true,
        is_manager: false,
      });
    } else if (rol_name === "CUSTOMER") {
      this.setState({
        role_id: 2,
        role_name: rol_name,
        isSuperAdmin: null,
        restricted_admin: false,
        is_manager: false,
      });
    } else if (rol_name === "ANALYST") {
      this.setState({
        role_id: 3,
        role_name: rol_name,
        isSuperAdmin: null,
        restricted_admin: false,
        is_manager: false,
      });
    } else if (rol_name === "TECHNICIAN") {
      this.setState({
        role_id: 4,
        role_name: rol_name,
        isSuperAdmin: null,
        restricted_admin: false,
        is_manager: false,
      });
    } else if (rol_name === "GERENTE") {
      this.setState({
        role_id: 2,
        role_name: "GERENTE",
        isSuperAdmin: null,
        restricted_admin: false,
        is_manager: true,
        // only_reports: false,
      });
    }
  };

  onChangeSubmit = (objects) => {
    const array = [];
    for (let i = 0; i < objects.length; i++) {
      if (
        objects[i].type === "Demo 14 dias" ||
        objects[i].type === "Demo 2 meses"
      ) {
        this.setState({
          customer_product: [
            ...this.state.customer_product,
            { id: objects[i].value, name: objects[i].label },
          ],
        });
      }
      array.push(objects[i].value);
    }

    if (objects.length < this.state.data.length) {
      if (array.length != 0) {
        const result = this.state.data.filter((item) => {
          const wr = array.find((el) => item.id === el);
          return !wr;
        });

        result &&
          trackPromise(
            fetch(`${APIS.API_REST_MULTI_USERS}/${this.state.id}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
              method: "DELETE",
              body: JSON.stringify({
                id: result[0].id,
              }),
            })
              .then((response) => {
                return response.json();
              })
              .then((json) => {
                let message = json.message;
                if (message === "Cliente eliminado") {
                  toast.success("Usuario modificado");
                  this.getMultiUsers(this.state.id);
                }
              })
              .catch((error) => {
                toast.error(error.toString());
              })
          );
      } else {
        toast.error(this.props.t("alerts.users-item-12"));
      }
    } else {
      trackPromise(
        fetch(`${APIS.API_REST_MULTI_USERS}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
          method: "POST",
          body: JSON.stringify({
            customer_id: array,
            user_id: this.state.id,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            let message = json.message;
            if (!message) {
              toast.success(this.props.t("alerts.users-item-11"));
              this.getMultiUsers(this.state.id);
            } else {
              toast.error(message);
            }
          })
          .catch((error) => {
            toast.error(error.toString());
          })
      );
    }
  };

  render() {
    const {
      display_name,
      email,
      only_reports,
      role_id,
      role_name,
      permission_id,
      is_can_see_depths,
      language,
      permissions,
      customers,
      is_manager,
    } = this.state;

    const optionsSelect =
      !!customers &&
      customers.map((customer) => {
        return {
          value: customer.id,
          type: customer.product,
          label: customer.name,
        };
      });

    const valuesMulti = this.state.data.map((item) => {
      return {
        value: item.id,
        label: item.customer_name,
      };
    });

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <h3>{this.props.t("users.users-edit")}</h3>
            <br />
            <br />
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="emailInput">
                      {this.props.t("users.users-th-1")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      id="emailInput"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      type="email"
                      className="form-control"
                      placeholder={this.props.t("users.users-th-1")}
                      readOnly
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="nameInput">
                      {this.props.t("users.users-th-2")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <input
                      id="nameInput"
                      name="display_name"
                      value={display_name}
                      onChange={this.onChange}
                      type="text"
                      className="form-control"
                      placeholder={this.props.t("users.users-th-2")}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="roleIdInput">
                      {this.props.t("users.users-language")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      placeholder={this.props.t("users.users-language")}
                      id="roleIdInput"
                      name="language"
                      value={language}
                      onChange={this.onChange}
                    >
                      <option value="">
                        {this.props.t("users.users-language-select")}
                      </option>
                      <option value="es">Español</option>
                      <option value="pt">Portugues</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="roleIdInput">
                      {this.props.t("users.users-th-3")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      placeholder={this.props.t("users.users-th-3")}
                      id="roleIdInput"
                      name="role_name"
                      value={role_name}
                      onChange={this.onChangeRolesAction}
                    >
                      <option value="">
                        {this.props.t("users.users-rol-select")}
                      </option>
                      {isSuperAdmin(
                        authUser.roles,
                        authUser.restricted_admin
                      ) ? (
                        <>
                          <option value="SADMIN">SUPER ADMINISTRADOR</option>
                          <option value="ADMIN">ADMINISTRADOR</option>
                        </>
                      ) : null}
                      <option value="GERENTE">GERENTE</option>
                      <option value="CUSTOMER">CLIENTE</option>
                      <option value="ANALYST">ANALISTA</option>
                      <option value="TECHNICIAN">TECNICO</option>
                      {/* {
                        !!roles && roles.map((rol, index) => {
                          let names = ["ADMINISTRADOR", "CLIENTE", "ANALISTA", "TECNICO"];
                          return <option
                            key={rol.id}
                            value={rol.id}>
                            {names[index]}
                          </option>;
                        })
                      } */}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="permissionIdInput">
                      {this.props.t("users.users-th-4")}
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <select
                      className="form-control"
                      placeholder={this.props.t("users.users-th-4")}
                      id="permissionIdInput"
                      name="permission_id"
                      value={permission_id}
                      onChange={this.onChange}
                    >
                      <option value="">
                        {this.props.t("users.users-select")}
                      </option>
                      {!!permissions &&
                        permissions.map((item) => {
                          let str = "";
                          let names = ["CREAR", "EDITAR", "ELIMINAR", "VER"];
                          Object.entries(item.permissions).forEach(
                            (permission, index) => {
                              let separator =
                                index < Object.keys(item.permissions).length - 1
                                  ? " / "
                                  : "";
                              let yesno = !!permission[1]
                                ? names[index] + separator
                                : "";
                              str = str + yesno;
                            }
                          );
                          return (
                            <option key={item.id} value={item.id}>
                              {str}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {role_id == "2" || role_id == "4" ? (
                    <>
                      <div className="col-sm-2 mt-4">
                        <label htmlFor="permissionIdInput">
                          {this.props.t("users.users-depths")}
                        </label>
                      </div>
                      <div className="col-sm-10 mt-4">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                            name="is_can_see_depths"
                            onChange={this.onChangeCheckbox}
                            checked={is_can_see_depths}
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckChecked"
                          >
                            {this.props.t("users.users-yes-no")}
                          </label>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {role_id == "2" && !is_manager ? (
                <div className="form-group" id="groupOnlyReports">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="onlyReportsInput">
                        {this.props.t("users.users-just-reports")}
                      </label>
                    </div>
                    <div className="col-sm-10">
                      <input
                        id="onlyReportsInput"
                        name="only_reports"
                        type="checkbox"
                        checked={only_reports}
                        onChange={this.onChangeCheckbox}
                      />
                      <label
                        class="form-check-label ml-2"
                        for="onlyReportsInput"
                      >
                        {this.props.t("users.users-yes-no")}
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-primary">
                    <i className="fa fa-save"></i>{" "}
                    {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
            {role_id == "2" ||
            role_id == "4" ||
            role_id == "3" ||
            (role_id == "1" && this.state.isSuperAdmin === null) ? (
              <div className="form-group mt-5 pt-2">
                <div className="row">
                  <div className="col-sm-2">
                    <label>{this.props.t("users.users-th-5")}</label>
                  </div>
                  <div className="col-sm-10">
                    <Select
                      value={valuesMulti}
                      isMulti
                      name="colors"
                      options={optionsSelect}
                      onChange={(e) => this.onChangeSubmit(e)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

// const mapStateToProps = (props) => {
//   return  {
//      roles: props.users.roles
//   }
// }
const structuredSelector = createStructuredSelector({
  roles: (state) => state.users.roles,
  permissions: (state) => state.users.permissions,
  customers: (state) => state.customers.customers,
  user: (state) => state.users.user,
});

const mapDispatchToProps = { getCustomers, getPermissions, getRoles };

const condition = (authUser) => !!authUser && authUser.roles === ROLES.ADMIN;

export default withAuthorization(condition)(
  connect(structuredSelector, mapDispatchToProps)(UserEditItem)
);
