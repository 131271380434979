import { REQ_RETREADBRANDS_SUCCESS, REQ_RETREADBRANDS_MIN_SUCCESS } from '../types/RetreadBrandsCatalogueTypes'
import { setErrorAction } from '../actions/MapErrorsAction'
import API from '../../constants/api'




export const getRetreadBrandsCatalogueAction = (customer_id) => {
  return (dispatch) => {
    API
      .getRetreadBrandsCatalogue(customer_id)
      .then(res => {
        dispatch({
          type: REQ_RETREADBRANDS_SUCCESS,
          payload: res.RetreadBrands
        })
      })
      .catch(error => dispatch(setErrorAction(error.toString())))
  }
}

export const getRetreadBrandsCatalogueAction2 = (customer_id) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getRetreadBrandsCatalogue(customer_id)
        .then(res => {
          if (res.RetreadBrands) {
            dispatch({
              type: REQ_RETREADBRANDS_SUCCESS,
              payload: res.RetreadBrands
            })
            resolve(res.RetreadBrands)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}

export const getRetreadBrandsCatalogueMinAction = (customer_id) => {
  return (dispatch) => {
    API
      .getRetreadBrandsMinCatalogue(customer_id)
      .then(res => {
        dispatch({
          type: REQ_RETREADBRANDS_MIN_SUCCESS,
          payload: res.RetreadCatalogue
        })
      })
      .catch(error => dispatch(setErrorAction(error.toString())))
  }
}

export const getRetreadBrandsCatalogueMinAction2 = (customer_id) => {
  return dispatch =>
    new Promise((resolve, reject) => {
      API
        .getRetreadBrandsMinCatalogue(customer_id)
        .then(res => {
          if (res.RetreadCatalogue) {
            dispatch({
              type: REQ_RETREADBRANDS_MIN_SUCCESS,
              payload: res.RetreadCatalogue
            })
            resolve(res.RetreadCatalogue)
          }
        })
        .catch(error => {
          reject(error)
        })
    })
}
