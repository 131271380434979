import $ from "jquery";
import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { trackPromise } from "react-promise-tracker";
import * as jsPDF from "jspdf";
import * as html2Canvas from "html2canvas";
import TireRow from "./TireRow";
import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";
import { time_event } from "mixpanel-browser";
import { rotationsEmails } from "../../../constants/variables";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const getSuggestionValue = (suggestion) => suggestion;

const renderSuggestion = (suggestion) => (
  <div>{suggestion.plate + " - " + suggestion.vehiclenumber + (suggestion.sap ? ' - ' + suggestion.sap : '')}</div>
);

const ValidateViewOldExportPdf = [
  "w.herrera@ruedata.com",
  "a.romero@ruedata.com",
  "sofia@ruedata.com",
  "jorge@ruedata.com",
  "m.barragan@ruedata.com",
  "p.camacho@ruedata.com",
];

const INITIAL_STATE = {
  codeRepeat: [],
  worktype: "",
  car_code: "",
  vehicle_number: "",
  order: "",
  vehicle: {},
  suggestions: [],
  date: "",
  technician: "",
  km: null,
  initKm: null,
  result: null,
  error: null,
  vehicleInactive: false,
  noveltysCount: 0,
  isNewKm: false,
  pageP: null,
  pageN: null,
  valueTnc: "",
  suggestionsTnc: [],
  positionsRow: [],
  showFastWarehouse: false,
};

class CarInfo extends Component {
  constructor(props) {
    super(props);
    this.references = {};
    this.state = { ...INITIAL_STATE };
  }

  chooseKm(kilometers, isKm) {
    $("#last10Km").modal("hide");
    this.setState({ km: kilometers });
  }

  getOrCreateRef = (id) => {
    if (!this.references.hasOwnProperty(id)) {
      this.references[id] = React.createRef();
    }
    return this.references[id];
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setAllDefault = (rotation) => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeDefault();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  setAllUnmount = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeUnmount();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  setAllMount = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeMount();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  setAllInspection = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeInspection();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  exportOldPdf = () => {
    const input = document.getElementById("divInspection");
    var Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa.document.open();
    pwa.document.write(input.innerHTML);
    pwa.document.close();
    pwa.focus();
    pwa.print();
  };

  exportPdf = () => {
    if (localStorage.getItem("GlobalCustomerId")) {
      return trackPromise(
        fetch(
          APIS.API_REST_INSPECTIONS_EXPORT_PDF.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("vehicle_id", this.state.vehicle_number),
          {
            headers: {
              Authorization:
                "Basic " + localStorage.getItem("firebaseAuthToken"),
              "Accept-language": localStorage.getItem("LanguageSelected"),
            },
          }
        )
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(this.props.t("alerts.retread-item-2"));
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `inspection.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            toast.success(this.props.t("alerts.query-item-6"));
          })
          .catch((error) => {
            toast.error(error.toString());
          })
      );
    }
    // const input = document.getElementById('divInspection');
    // var Pagelink = "about:blank";
    // var pwa = window.open(Pagelink, "_new");
    // pwa.document.open();
    // pwa.document.write(input.innerHTML);
    // pwa.document.close();
    // pwa.focus();
    // pwa.print();
    /*
    input.style.display = "block"
    html2Canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg');
        const imgWidth = 210; 
        const pageHeight = 295;  
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        const pdf = new jsPDF('p', 'mm');
        let position = 0;
        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save("hoja_inspeccion.pdf");
        input.style.display = "none"
      })
    ;
    */
  };

  setFastWarehouses = (name, id) => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setAllTypeWarehouse(name, id);
      }
    }
  };

  setAllPreasure = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypePreasure();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  setAllRotation = () => {
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        references[key].current.setTypeVehicleRotation();
        setTimeout(() => {
          let type = references[key].current.state.type;
          if (type == "desmonte") this.setState({ showFastWarehouse: true });
          else this.setState({ showFastWarehouse: false });
        }, 100);
      }
    }
  };

  changeStatusPositions = (position, bool) => {
    const fill = this.state.positionsRow.filter(
      (item) => item.position === position
    )[0];
    fill.selected = bool;
    this.state.positionsRow.findIndex((elemento, indice) => {
      if (elemento.position === position) {
        this.state.positionsRow.splice(indice, 1, fill);
      }
    });
  };

  getAllByVehiclePositions = () => {
    let arr = [];
    const references = this.references;
    for (let key in references) {
      if (!!references[key] && !!references[key].current) {
        if (references[key].current.state.type === "vehicle_rotation") {
          arr.push(Number(key));
        }
      }
    }
    return arr;
  };

  getAllPositions = (objects) => {
    let arr = [];
    for (let i = 0; i < objects.length; i++) {
      let position = objects[i].posicion_montaje;
      arr.push({
        position,
        selected: false,
      });
    }
    this.setState({ positionsRow: arr });
  };

  getTableResult = (objects) => {
    return (
      <div>
        <br />
        {!!this.props.authUser.permissions.create ? (
          <div className="dropdown">
            <button
              className="btn btn-primary dropdown-toggle float-left"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-bolt"></i> {this.props.t("ins-one.ins-one-1")}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.setAllDefault(false)}
              >
                {this.props.t("ins-one.ins-one-2")}
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={this.setAllUnmount}
              >
                {this.props.t("ins-one.ins-one-3")}
              </a>
              <a className="dropdown-item" href="#" onClick={this.setAllMount}>
                {this.props.t("ins-one.ins-one-4")}
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={this.setAllInspection}
              >
                {this.props.t("ins-one.ins-one-5")}
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={this.setAllRotation}
              >
                {this.props.t("selectors.vehicle_rotation")}
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={this.setAllPreasure}
              >
                {this.props.t("selectors.preasure_inspection")}
              </a>
            </div>
          </div>
        ) : null}
        <div className="float-right d-flex">
          {this.state.showFastWarehouse == true ? (
            <div className="dropdown">
              <button
                className="btn btn-primary dropdown-toggle mr-2"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-bolt"></i>{" "}
                {this.props.t("ins-one.ins-one-63")}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {this.props.warehouses.map((item) => {
                  if (item.name !== "llanta_nueva") {
                    return (
                      <a
                        onClick={() =>
                          this.setFastWarehouses(item.name, item.id)
                        }
                        className="dropdown-item"
                        href="#"
                      >
                        {item.name}
                      </a>
                    );
                  }
                })}
              </div>
            </div>
          ) : null}
          <button
            className="btn btn-primary text-white mr-2"
            onClick={() => this.props.getTires()}
            type="button"
          >
            <i className="fa fa-sync-alt"></i>{" "}
            {this.props.t("globals.act-tire")}
          </button>
          &nbsp;
          <button
            onClick={this.exportPdf}
            className="btn btn-primary"
            type="button"
          >
            <i className="fa fa-file"></i> {this.props.t("ins-one.ins-one-6")}
          </button>
          {ValidateViewOldExportPdf.includes(this.props.authUser.email) ? (
            <button
              onClick={this.exportOldPdf}
              className="btn btn-primary ml-3"
              type="button"
            >
              <i className="fa fa-file"></i> Hoja Anterior
            </button>
          ) : null}
        </div>
        <br></br>
        <br></br>
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th>{this.props.t("ins-one.ins-one-7")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-8")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-9")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-10")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-11")}</th>
              <th scope="col">{this.props.t("ins-one.ins-one-67")}</th>
              <th></th>
              <th>{this.props.t("ins-one.ins-one-12")}</th>
            </tr>
          </thead>
          <tbody>
            {objects.map((object, index) => {
              return (
                <TireRow
                  t={this.props.t}
                  key={index}
                  records={this.props.records}
                  waste={this.props.waste}
                  novelties={this.props.novelties}
                  movements={this.props.movements}
                  resetState={this.state.resetState}
                  ref={this.getOrCreateRef("" + 1 * object.posicion_montaje)}
                  tires={this.props.tires}
                  warehouses={this.props.warehouses}
                  object={object}
                  getAllPositions={this.getAllPositions}
                  objects={objects}
                  date={this.state.date}
                  kms={this.state.combustibles}
                  km={this.state.km}
                  technician={this.state.technician}
                  order={this.state.order}
                  authUser={this.props.authUser}
                  firebaseUser={this.props.firebaseUser}
                  getVehicles={this.props.getVehicles}
                  onSubmitCar={this.onSubmit}
                  positionsRow={this.state.positionsRow}
                  changeStatusPositions={this.changeStatusPositions}
                  getAllByVehiclePositions={this.getAllByVehiclePositions}
                  carCode={this.state.car_code}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  updateTires = (event) => {
    if (!!event) event.preventDefault();
    if (isInternetConnected(toast)) return;

    const vehicle_number = this.state.vehicle_number;

    let result;
    trackPromise(
      fetch(
        APIS.API_REST_TIRES_VEHICLE.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", vehicle_number),
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          if (!!json && !!json.message) {
            if (json.message.indexOf("does not have tires") >= 0) {
              throw new Error(this.props.t("alerts.ins-one-item-1"));
            }
            if (json.message.indexOf("does not exists") >= 0) {
              throw new Error(this.props.t("alerts.ins-one-item-2"));
            }
          }
          const tagged = json.tires_by_vehicle.reduce(
            (r, e) => {
              if (e.posicion_montaje < 0) {
                r.n.push(e);
              } else {
                r.p.push(e);
              }
              return r;
            },
            { p: [], n: [] }
          );
          result = [
            ...tagged.p.sort((a, b) => a.posicion_montaje - b.posicion_montaje),
            ...tagged.n.sort((a, b) => b.posicion_montaje - a.posicion_montaje),
          ];
          this.setState({
            result: result,
          });
          return true;
        })
        .catch((error) => {
          let err = error;
          if (valConnection.indexOf(error.toString()) > -1) {
            err = this.props.t("alerts.ins-one-item-3");
          }
          toast.error(err.toString());
          this.setState({ error: err });
        })
    );
  };

  onSubmit = (event, isSameCar) => {
    if (!!event) event.preventDefault();
    if (isInternetConnected(toast)) return;
    this.setState({ positionsRow: [] });

    const vehicle_number = this.state.vehicle_number;

    let vehicle;
    let result;
    trackPromise(
      fetch(
        `${APIS.API_REST_VEHICLES.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        )}/${vehicle_number}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw new Error(json.message);
          }
          vehicle = json;
          if (!isSameCar) {
            this.setState({
              vehicle: vehicle,
              worktype: json.worktype,
              date: "",
              technician: "",
              km: "",
              initKm: "",
              resetState: null,
            });
          }
          return true;
        })
        .then(() => {
          return fetch(
            APIS.API_REST_TIRES_VEHICLE.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            ).replace("vehicle_id", vehicle_number),
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          if (!!json && !!json.message) {
            if (json.message.indexOf("does not have tires") >= 0) {
              throw new Error(this.props.t("alerts.ins-one-item-1"));
            }
            if (json.message.indexOf("does not exists") >= 0) {
              throw new Error(this.props.t("alerts.ins-one-item-2"));
            }
          }
          if (json.vehicle_info.inactive) {
            toast.warning(this.props.t("alerts.ins-one-item-4"));
            this.setState({ vehicleInactive: true });
          } else {
            this.setState({ vehicleInactive: false });
          }
          const tagged = json.tires_by_vehicle.reduce(
            (r, e) => {
              if (e.posicion_montaje < 0) {
                r.n.push(e);
              } else {
                r.p.push(e);
              }
              return r;
            },
            { p: [], n: [] }
          );
          result = [
            ...tagged.p.sort((a, b) => a.posicion_montaje - b.posicion_montaje),
            ...tagged.n.sort((a, b) => b.posicion_montaje - a.posicion_montaje),
          ];
          return true;
        })
        .then(() => {
          return fetch(
            `${APIS.API_REST_FUELS_HISTORY.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            ).replace("vehicle_id", vehicle.id)}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            this.setState({
              result: result,
              //km     : 1*vehicle.fuel_km_info.kilometers,
              initKm: 1 * vehicle.fuel_km_info.kilometers,
            });
            throw new Error(this.props.t("alerts.ins-one-item-5"));
          }
          let pageP, pageN, str;
          if (!!json.previus) {
            str = json.previus;
            pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          if (!!json.next) {
            str = json.next;
            pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          this.setState({
            result: result,
            //km     : 1*vehicle.fuel_km_info.kilometers,
            initKm: 1 * vehicle.fuel_km_info.kilometers,
            combustibles: json.combustibles,
            pageP: pageP,
            pageN: pageN,
          });
          this.getAllPositions(result);
        })
        .catch((error) => {
          let err = error;
          if (valConnection.indexOf(error.toString()) > -1) {
            err = this.props.t("alerts.ins-one-item-6");
          }
          toast.error(err.toString());
          this.setState({ error: err });
        })
    );
  };

  registerNewKm = (event, isKm) => {
    event.preventDefault();
    let kmsArr = this.state.combustibles;
    let data1, url;
    url = APIS.API_REST_FUELS.replace(
      "customer_id",
      localStorage.getItem("GlobalCustomerId")
    ).replace("vehicle_id", this.state.vehicle_number);
    data1 = {
      kilometers: document.getElementById("newKmInput").value,
      fecha_subida: moment().utc().format(),
      fecha_inspeccion: moment(document.getElementById("newDateInput").value)
        .utc()
        .format(),
    };
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify(data1),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (!!json.message) {
          toast.error(json.message);
          //throw new Error(json.message);
        } else {
          kmsArr.push({ ...data1, id: json.id });
          toast.success("Km registrado");
          this.setState({
            isNewKm: false,
            combustibles: kmsArr,
            km: document.getElementById("newKmInput").value,
          });
        }
      })
      .catch((error) => {
        toast.error(error.toString());
        //throw new Error(error);
      });
  };

  getFuels = (event, page) => {
    if (!!event) event.preventDefault();
    var str = "";
    if (page !== "0" && !!page) {
      str = "?page=" + page + "&limit=20";
    } else {
      str = "?page=1&limit=20";
    }
    return trackPromise(
      fetch(
        `${
          APIS.API_REST_FUELS_HISTORY.replace(
            "customer_id",
            localStorage.getItem("GlobalCustomerId")
          ).replace("vehicle_id", this.state.vehicle_number) + str
        }`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(this.props.t("alerts.ins-one-item-7"));
          }
          return response.json();
        })
        .then((json) => {
          let pageP, pageN;
          if (!!json.previus) {
            str = json.previus;
            pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          if (!!json.next) {
            str = json.next;
            pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
          }
          this.setState({
            combustibles: json.combustibles,
            pageP: pageP,
            pageN: pageN,
          });
        })
        .catch((error) => {
          throw new Error(this.props.t("alerts.ins-one-item-8"));
        })
    );
  };

  onSubmitItems = async (event) => {
    event.preventDefault();
    let promises = [];
    let showAlert = true;
    let references = this.references;
    let arr = [];
    for (let id in references) {
      let reference = references[id];
      if (!!reference && !!reference.current) {
        let form = reference.current.form;
        if (!!form && !!form.current) {
          let inspection = form.current.state.inspection;
          inspection.send_request = true;
          if (!!inspection.codigo) {
            arr.push(inspection.codigo);
          }
        }
      }
    }
    let countErrors = 0;
    if (arr.every((num) => arr.indexOf(num) === arr.lastIndexOf(num))) {
      for (let id in references) {
        let reference = references[id];
        if (!!reference && !!reference.current) {
          let form = reference.current.form;
          if (!!form && !!form.current) {
            let inspection = form.current.state.inspection;

            let validation = form.current.areValidFields(inspection);
            if (validation.isValid) {
              if (inspection.type === "vehicle_rotation") {
                const parent = {
                  ...references[inspection.pos_final].current.form.current.state
                    .inspection,
                };
                parent.fc_ultima_inspeccion = moment(
                  parent.fc_ultima_inspeccion
                )
                  .utc()
                  .format();
                if (parent.parent_rotation) {
                  delete parent.parent_rotation;
                }
                inspection.parent_rotation = parent;
              }
              if (inspection.send_request) {
                if (showAlert) {
                  if (
                    !form.current.getKilometerId(
                      inspection.kilometers,
                      inspection.fc_ultima_inspeccion
                    )
                  ) {
                    showAlert = false;
                    if (window.confirm(this.props.t("alerts.ins-one-item-9"))) {
                      promises.push(
                        await form.current
                          .onSubmit(
                            event,
                            true,
                            true,
                            this.setInitDataInspections
                          )
                          .then(function (result) {
                            return result;
                          })
                          .catch(function (error) {
                            countErrors++;
                          })
                      );
                    } else {
                      countErrors++;
                      break;
                    }
                  } else {
                    promises.push(
                      await form.current
                        .onSubmit(
                          event,
                          true,
                          true,
                          this.setInitDataInspections
                        )
                        .then(function (result) {
                          return result;
                        })
                        .catch(function (error) {
                          countErrors++;
                        })
                    );
                  }
                } else {
                  promises.push(
                    await form.current
                      .onSubmit(event, true, true, this.setInitDataInspections)
                      .then(function (result) {
                        return result;
                      })
                      .catch(function (error) {
                        countErrors++;
                      })
                  );
                }
              }
            } else {
              countErrors++;
              toast.info(
                this.props.t("alerts.ins-one-item-10") +
                  inspection.posicion_montaje +
                  this.props.t("alerts.ins-one-item-11") +
                  validation.field +
                  this.props.t("alerts.ins-one-item-12")
              );
            }
          }
        }
      }
      return await Promise.all(promises).then(() => {
        if (countErrors === 0) {
          this.onSubmit(event, true);
        }
      });
    } else {
      toast.info(this.props.t("alerts.ins-one-item-13"));
    }
  };

  getSuggestions = (value) => {
    let cars = this.props.cars;
    let fmtSuggestions = cars.filter((car) => {
      const fmtValue = escapeRegexCharacters(value.toLowerCase().trim());
      return (
        car.plate.toLowerCase().search(fmtValue) >= 0 ||
        car.vehiclenumber.toLowerCase().search(fmtValue) >= 0 || car.sap !== null && 
        car.sap.toLowerCase().search(fmtValue) >= 0
      );
    });
    return fmtSuggestions.slice(0, 20);
  };

  onChangePlate = (event, { newValue }) => {
    if (typeof newValue === "object" && newValue !== null) {
      this.setState({
        vehicle_number: newValue.id,
        car_code: newValue.plate + " - " + newValue.vehiclenumber + (newValue.sap ? ' - ' + newValue.sap : ''),
      });
    } else {
      let cars = this.props.cars;
      for (let i = 0; i < cars.length; i++) {
        if (
          cars[i].plate.toLowerCase().trim() === newValue.toLowerCase().trim()
        ) {
          this.setState({
            vehicle_number: cars[i].id,
            car_code: cars[i].plate + " - " + cars[i].vehiclenumber + (cars[i].sap ? ' - ' + cars[i].sap : ''),
          });
          break;
        } else {
          this.setState({
            vehicle_number: 0,
            car_code: newValue,
          });
        }
      }
    }
  };

  onPlatesFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onPlatesClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  getSuggestionsTnc = (value) => {
    let tnc = this.props.technicians;
    let reTnc = tnc.filter((item) => {
      const tncValue = escapeRegexCharacters(value.toLowerCase().trim());
      return (
        (item.name.toLowerCase().search(tncValue) >= 0 && item.active) ||
        (item.email.toLowerCase().search(tncValue) >= 0 && item.active)
      );
    });
    return reTnc;
  };

  getSuggestionValueTnc = (suggestion) => suggestion.name;

  renderSuggestionTnc = (suggestion) => (
    <div>
      <p>
        {suggestion.name}: {suggestion.email}
      </p>
    </div>
  );

  onChangeTnc = (event, { newValue }) => {
    this.setState({
      technician: newValue,
    });
  };

  onSuggestionsFetchRequestedTnc = ({ value }) => {
    this.setState({
      suggestionsTnc: this.getSuggestionsTnc(value),
    });
  };

  onSuggestionsClearRequestedTnc = () => {
    this.setState({
      suggestionsTnc: [],
    });
  };

  render() {
    let {
      car_code,
      suggestions,
      result,
      combustibles,
      vehicle,
      technician,
      suggestionsTnc,
    } = this.state;

    const plateProps = {
      placeholder: "",
      value: car_code,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "1%",
        marginBottom: 0,
      },
      onChange: this.onChangePlate,
    };

    const inputProps = {
      placeholder: this.props.t("alerts.ins-one-item-14"),
      value: technician,
      className: "form-control",
      style: {
        position: "relative",
        WebkitBoxFlex: 1,
        msFlex: "1 1 auto",
        flex: "1 1 auto",
        width: "100%",
        marginBottom: 0,
      },
      onChange: this.onChangeTnc,
    };
    let isInvalidCarCode = car_code === "";
    return (
      <div className="col-sm-12">
        <form onSubmit={(event) => this.onSubmit(event, false)}>
          <div className="row mb-3">
            <div className="col-sm-2">
              <label htmlFor="inputCarCode">
                {this.props.t("ins-one.ins-one-13")}
              </label>
            </div>
            <div className="col-sm-10 input-group">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onPlatesFetchRequested}
                onSuggestionsClearRequested={this.onPlatesClearRequested}
                shouldRenderSuggestions={() => true}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={plateProps}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary text-white"
                  type="submit"
                  disabled={isInvalidCarCode}
                >
                  <i className="fa fa-search"></i>{" "}
                  {this.props.t("ins-one.ins-one-14")}
                </button>
              </div>
            </div>
          </div>
        </form>
        {!!result && result.length > 0 ? (
          <form>
            {!!this.state.vehicleInactive ? (
              <div className="alert alert-warning" role="alert">
                {this.props.t("ins-one.ins-one-15")}
              </div>
            ) : null}
            <div className="row">
              <div className="col-md-8">
                <div className="row form-group">
                  <div className="col-sm-12">
                    <label htmlFor="inputCarKm">
                      {this.props.t("ins-one.ins-one-16")}
                    </label>
                  </div>
                  <div className="col-sm-12 input-group">
                    <input
                      name="km"
                      type="number"
                      id="inputCarKm"
                      className="form-control"
                      value={this.state.km}
                      min={this.state.initKm}
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary text-white"
                        type="button"
                        data-toggle="modal"
                        data-target={"#last10Km"}
                      >
                        <i className="fa fa-chart-line"></i>{" "}
                        {this.props.t("ins-one.ins-one-17")}
                      </button>
                      <div
                        className="modal fade"
                        id="last10Km"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="last10KmLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                id="last10KmLabel"
                                className="modal-title text-dark"
                              >
                                {this.props.t("ins-one.ins-one-18")}
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Cerrar"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body text-dark">
                              {this.state.isNewKm === false ? (
                                <div>
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      this.setState({ isNewKm: true })
                                    }
                                    className="btn btn-primary float-right"
                                  >
                                    {this.props.t("ins-one.ins-one-19")}
                                  </button>
                                  <table className="table table-striped mb-0">
                                    <thead>
                                      <tr>
                                        <th>
                                          {this.props.t("ins-one.ins-one-20")}
                                        </th>
                                        <th>
                                          {this.props.t("ins-one.ins-one-21")}
                                        </th>
                                        <th>
                                          {this.props.t("ins-one.ins-one-22")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {!!combustibles &&
                                        combustibles.map((fuel, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>{fuel.kilometers}</td>
                                              <td>
                                                {moment
                                                  .utc(fuel.fecha_inspeccion)
                                                  .local()
                                                  .locale("es")
                                                  .format("LLL")}
                                              </td>
                                              <td>
                                                <a
                                                  href="#"
                                                  className="btn btn-primary"
                                                  onClick={(e) =>
                                                    this.chooseKm(
                                                      fuel.kilometers,
                                                      true
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-link"></i>{" "}
                                                  {this.props.t(
                                                    "ins-one.ins-one-23"
                                                  )}
                                                </a>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td>
                                          {!!this.state.pageP ? (
                                            <button
                                              className="btn btn-primary"
                                              onClick={(e) =>
                                                this.getFuels(
                                                  e,
                                                  this.state.pageP
                                                )
                                              }
                                            >
                                              <i className="fa fa-arrow-left"></i>
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-primary"
                                              disabled
                                            >
                                              <i className="fa fa-arrow-left"></i>
                                            </button>
                                          )}
                                        </td>
                                        <td>
                                          {!!this.state.pageN ? (
                                            <button
                                              className="btn btn-primary"
                                              onClick={(e) =>
                                                this.getFuels(
                                                  e,
                                                  this.state.pageN
                                                )
                                              }
                                            >
                                              <i className="fa fa-arrow-right"></i>
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-primary"
                                              disabled
                                            >
                                              <i className="fa fa-arrow-right"></i>
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                <div>
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      this.setState({ isNewKm: false })
                                    }
                                    className="btn btn-primary float-right"
                                  >
                                    {this.props.t("ins-one.ins-one-24")}
                                  </button>
                                  <br />
                                  <br />
                                  <fieldset>
                                    <div className="row form-group">
                                      <div className="col-sm-2">
                                        <label htmlFor="newKmInput">
                                          {this.props.t("ins-one.ins-one-25")}
                                        </label>
                                      </div>
                                      <div className="col-sm-10">
                                        <input
                                          name="km"
                                          type="text"
                                          id="newKmInput"
                                          className="form-control"
                                          placeholder={this.props.t(
                                            "ins-one.ins-one-26"
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="row form-group">
                                      <div className="col-sm-2">
                                        <label htmlFor="newDateInput">
                                          {this.props.t("ins-one.ins-one-27")}
                                        </label>
                                      </div>
                                      <div className="col-sm-10">
                                        <input
                                          name="date"
                                          type="datetime-local"
                                          id="newDateInput"
                                          className="form-control"
                                          placeholder={this.props.t(
                                            "ins-one.ins-one-27"
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        this.registerNewKm(e, true)
                                      }
                                      className="btn btn-primary float-right"
                                    >
                                      {this.props.t("ins-one.ins-one-28")}
                                    </button>
                                  </fieldset>
                                </div>
                              )}
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                aria-label="Cerrar"
                              >
                                {this.props.t("ins-one.ins-one-29")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-12">
                    <label htmlFor="inputCarDate">
                      {this.props.t("ins-one.ins-one-30")}
                    </label>
                  </div>
                  <div className="col-sm-12">
                    <input
                      name="date"
                      type="datetime-local"
                      id="inputCarDate"
                      className="form-control"
                      value={this.state.date}
                      onChange={this.onChange}
                      placeholder={this.props.t("ins-one.ins-one-30")}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-12">
                    <label htmlFor="inputCarTechnician">
                      {this.props.t("ins-one.ins-one-31")}
                    </label>
                  </div>
                  <div className="col-sm-12">
                    <Autosuggest
                      suggestions={suggestionsTnc}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequestedTnc
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequestedTnc
                      }
                      shouldRenderSuggestions={() => true}
                      getSuggestionValue={this.getSuggestionValueTnc}
                      renderSuggestion={this.renderSuggestionTnc}
                      inputProps={inputProps}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-12">
                    <label htmlFor="codigoInput">
                      {this.props.t("client-tires.client-item-27")}
                    </label>
                  </div>
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      placeholder="#"
                      id="orderInput"
                      name="order"
                      value={this.state.order}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th scope="row">{this.props.t("ins-one.ins-one-32")}</th>
                      <td>{vehicle.centrocosto}</td>
                      <th scope="row">{this.props.t("ins-one.ins-one-33")}</th>
                      <td>{vehicle.schema}</td>
                    </tr>
                    <tr>
                      <th scope="row">{this.props.t("ins-one.ins-one-34")}</th>
                      <td>{vehicle.ciudad}</td>
                      <th scope="row">{this.props.t("ins-one.ins-one-35")}</th>
                      <td>{vehicle.vehiclebrand}</td>
                    </tr>
                    <tr>
                      <th scope="row">{this.props.t("ins-one.ins-one-36")}</th>
                      <td>{vehicle.fuel}</td>
                      <th scope="row">{this.props.t("ins-one.ins-one-37")}</th>
                      <td>{vehicle.vehiclenumber}</td>
                    </tr>
                    <tr>
                      <th scope="row">{this.props.t("ins-one.ins-one-38")}</th>
                      <td>{vehicle.group}</td>
                      <th scope="row">{this.props.t("ins-one.ins-one-39")}</th>
                      <td>{vehicle.vehicletype}</td>
                    </tr>
                    <tr>
                      <th scope="row">{this.props.t("ins-one.ins-one-40")}</th>
                      <td>{vehicle.linemodel}</td>
                      <th scope="row">{this.props.t("ins-one.ins-one-41")}</th>
                      <td>{vehicle.year}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        ) : null}
        {!!result && result.length > 0 ? (
          <div className="mt-5">
            <div id="divInspection" style={{ display: "none" }}>
              <table
                style={{ "font-size": "10 px; important" }}
                border="0"
                width="100%"
              >
                <tr>
                  <td width="30%">
                    <center>
                      <img src="/images/logo.png" width="220" />
                    </center>
                    <center>
                      <h2>
                        {this.props.t("ins-one.ins-one-46")}{" "}
                        {localStorage.getItem("GlobalCustomerName")}
                      </h2>
                    </center>
                  </td>
                  <td width="70%">
                    <center>
                      <h3>{this.props.t("ins-one.ins-one-47")}</h3>
                    </center>
                    <table border="1" width="100%">
                      <tbody>
                        <tr>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-48")}
                          </th>
                          <td>{vehicle.plate}</td>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-49")}
                          </th>
                          <td>{vehicle.vehiclenumber}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-33")}
                          </th>
                          <td>{vehicle.schema}</td>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-36")}
                          </th>
                          <td>{vehicle.fuel}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-35")}
                          </th>
                          <td>{vehicle.vehiclebrand}</td>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-40")}
                          </th>
                          <td>{vehicle.linemodel}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-41")}
                          </th>
                          <td>{vehicle.year}</td>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-39")}
                          </th>
                          <td>{vehicle.vehicletype}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-32")}
                          </th>
                          <td>{vehicle.centrocosto}</td>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-34")}
                          </th>
                          <td>{vehicle.ciudad}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-38")}
                          </th>
                          <td>{vehicle.group}</td>
                          <th scope="row">
                            {this.props.t("ins-one.ins-one-62")}
                          </th>
                          <td>{vehicle.spares}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
              <center>
                <h3>{this.props.t("ins-one.ins-one-52")}</h3>
              </center>
              <table border="1" width="100%">
                <thead>
                  <tr>
                    <th scope="col">{this.props.t("ins-one.ins-one-53")}</th>
                    <th scope="col">{this.props.t("ins-one.ins-one-9")}</th>
                    <th scope="col">{this.props.t("ins-one.ins-one-10")}</th>
                    <th scope="col">{this.props.t("ins-one.ins-one-54")}</th>
                    <th scope="col">{this.props.t("ins-one.ins-one-55")}</th>
                    <th scope="col">{this.props.t("ins-one.ins-one-56")}</th>
                    <th scope="col">{this.props.t("ins-one.ins-one-57")}</th>
                    <th scope="col">{this.props.t("ins-one.ins-one-58")}</th>
                    <th scope="col">{this.props.t("ins-one.ins-one-59")}</th>
                  </tr>
                </thead>
                <tbody>
                  {result.map((object, index) => {
                    return (
                      <tr>
                        <td>{object.posicion_montaje}</td>
                        <td>{object.codigo}</td>
                        <td>
                          {!!object.marca
                            ? object.marca +
                              " " +
                              object.modelo +
                              " " +
                              object.dimension
                            : ""}
                          <b>
                            {typeof object.retreadband == "undefined"
                              ? ""
                              : object.retreadband}{" "}
                            {typeof object.retreadband == "undefined"
                              ? ""
                              : object.retreadimention}{" "}
                            {/* {!!object.codigo
                              ? !!object.retreadbrand &&
                                !!object.retreadband &&
                                !!object.retreadimention
                                ? "R"
                                : "N"
                              : ""} */}
                            {/* {object.vida} {!!object.regrabada ? "RS" : null} */}
                            {!!object.codigo
                              ? object.regrabada
                                ? "RG"
                                : !!object.retreadband &&
                                  !!object.retreadbrand &&
                                  !!object.retreadimention
                                ? "R"
                                : "N"
                              : ""}
                            {object.regrabada ? object.regrabado : object.vida}
                          </b>
                        </td>
                        <td>
                          {!!object.marca
                            ? moment
                                .utc(object.fc_ultima_inspeccion)
                                .local()
                                .locale("es")
                                .format("DD[/]MM[/]YY")
                            : ""}
                        </td>
                        <td>
                          <center>{object.ult_prof_centro_exterior}</center>
                        </td>
                        <td>
                          <center>{object.ult_prof_centro}</center>
                        </td>
                        <td>
                          <center>{object.ult_prof_interior}</center>
                        </td>
                        <td>
                          {!!object.ult_presion ? object.ult_presion : ""}
                        </td>
                        <td>{object.kilometers}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colspan="9">{this.props.t("ins-one.ins-one-60")}</td>
                  </tr>
                  <tr>
                    <td colspan="9">{this.props.t("ins-one.ins-one-61")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              {result.filter((tire) => !!tire.codigo).length +
                " " +
                this.props.t("alerts.ins-one-item-78")}
            </p>
            <div className="text-center v-scroll">
              {this.getTableResult(result)}
            </div>
            <br />
            {!!this.props.authUser.permissions.create ? (
              <form onSubmit={this.onSubmitItems}>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block"
                  >
                    <i className="fa fa-save"></i>{" "}
                    {this.props.t("globals.save-all")}
                  </button>
                </div>
              </form>
            ) : null}
          </div>
        ) : null}
        {!!result && result.length > 0 ? (
          <div className="row mt-5">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-header">
                  <h5 class="card-title">
                    {this.props.t("ins-one.ins-one-42")}
                  </h5>
                </div>
                <div class="card-body text-center">
                  <p class="card-text">
                    <span style={{ color: "#194774" }}>N0 =</span>{" "}
                    {this.props.t("ins-one.ins-one-43")}
                  </p>
                  <p class="card-text">
                    <span style={{ color: "#194774" }}>R# =</span>{" "}
                    {this.props.t("ins-one.ins-one-44")}
                  </p>
                  <p class="card-text">
                    <span style={{ color: "#194774" }}>RG =</span>{" "}
                    {this.props.t("ins-one.ins-one-45")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default CarInfo;
