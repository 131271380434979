import { DATA_USERS_SUCCESS, DATA_USERS_ERROR, SET_LOADING, DATA_USERS_SEARCH_SUCCESS, DATA_USERS_SEARCH_CLEAR_SUCCESS, DATA_ROLES_SUCCESS, DATA_PERMISSION_SUCCESS, DATA_USER_SUCCESS, DATA_SET_USER_SUCCESS, REGISTER_USER_SUCCES, DATA_USER_PAGINATION_SUCCESS, DELETE_USER_SUCCESS } from '../types/UsersTypes'

const INITIAL_STATE = {
    users: [],
    isLoading: true,
    searchedUser: null,
    roles: null,
    permissions: null,
    user: null,
    userUpdated: null,
    next: null,
    total: null,
    previus: null,
}

export const usersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DATA_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                isLoading: false
            }
        case DATA_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
            }
        case SET_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case DATA_USERS_SEARCH_SUCCESS:
            return {
                ...state,
                searchedUser: action.payload
            }
        case DATA_USER_PAGINATION_SUCCESS:
            return {
                ...state,
                users: action.payload.Users,
                total: action.payload.total,
                next: action.payload.next,
                previus: action.payload.previus,
            }
        case DATA_USERS_SEARCH_CLEAR_SUCCESS:
            return {
                ...state,
                searchedUser: action.payload
            }
        case DATA_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload
            }
        case DATA_PERMISSION_SUCCESS:
            return {
                ...state,
                permissions: action.payload
            }
        case DATA_SET_USER_SUCCESS:
            const newArray = state.users
            if (state.users) {
                const newArray = state.users.filter(user => user.id != action.payload.id)
                newArray.push(action.payload)
            }
            return {
                ...state,
                users: newArray,
                userUpdated: true
            }
        case REGISTER_USER_SUCCES:
            console.log(action.payload)
            return {
                ...state,
                // users: newArray,
                // userUpdated: true
            }
        case DELETE_USER_SUCCESS:
            let userss = state.users.filter(
                (user) => user.uid !== action.payload
            );

            return {
                ...state,
                users: userss,
            };
        default:
            return state
    }
}