import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import * as constantsTire from "../../../../constants/actions/Vehicles";
import * as APIS from "../../../../constants/apis";
import { deleteTireAction } from "../../../../redux/actions/TiresAction";
import { toast } from "react-toastify";

const deleteTire = (customer_id, tire) => {
  return dispatch => {
    dispatch(deleteTireAction(customer_id, tire))
      .then(res => {
        if (res.message) {
          toast.success(res.message)
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
      })
      .catch(error => {
        if (error.response.status != 200) {
          toast.error(error.response.data.message)
        }
      })
  }
}

class TireDeleteItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { tireDelete } = this.props;
    return (
      <React.Fragment>
        <button
          onClick={() => {
            if (window.confirm(this.props.t("alerts.c-tire-item-8"))) {
              this.props.deleteTire(localStorage.getItem("GlobalCustomerId"), tireDelete)
            }
          }}
          className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
}

const structuredSelector = createStructuredSelector({

});

const mapDispatchToProps = { deleteTire };

export default connect(structuredSelector, mapDispatchToProps)(TireDeleteItem);
