import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import * as constantsTireCatalogue from "../../../constants/actions/TireCatalogues";
import * as APIS from "../../../constants/apis";

function deleteTireCatalogue(name, catalogues, catalogue, t) {
  if(window.confirm(t("alerts.catalogue-item-4"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_TIRECATALOGUES.replace("customer_id",localStorage.getItem("GlobalCustomerId"))}/${name}`, {
        headers:{
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "DELETE" })
        .then(response => {
          if(response.status !== 200) {
            throw new Error(t("alerts.brands-item-5"));
          }
          return response.json(); })
        .then(json => {
          dispatch(getReqSuccess(catalogues, catalogue,
                                    json.message));
              return fetch(APIS.API_REST_TIRECATALOGUES.replace("customer_id",
              localStorage.getItem("GlobalCustomerId")), {
                                                                    headers:{
                                                                      "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                                      "Accept-language": localStorage.getItem("LanguageSelected"),
                                                                    } })
                .then(response => response.json())
                .then(json => {
                  dispatch(getTireCataloguesSuccess({
                    catalogues : json.TireCatalogue,
                  })); })
                .catch(error => {
                  dispatch(getReqError(catalogues,
                                       catalogue, error.toString()));
                }); })
        .catch(error => {
          dispatch(getReqError(catalogues, catalogue,
                              error.message.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(getTireCataloguesSuccess({
        catalogues : catalogues,
      }));
    };
  }
};

export function getTireCataloguesSuccess(json) {
  return {
    type: constantsTireCatalogue.GET_TICATALOGUES_SUCCESS,
    json
  };
};

export function getReqSuccess(catalogues, catalogue, message) {
  const json = {
    catalogues : catalogues,
    catalogue  : catalogue,
    message    : message
  };
  return {
    type: constantsTireCatalogue.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(catalogues, catalogue, error) {
  const json = {
    catalogues : catalogues,
    catalogue  : catalogue,
    error      : error
  };
  return {
    type: constantsTireCatalogue.GET_REQUEST_ERROR,
    json
  };
};

class TireCatalogueDeleteItem extends React.Component {
  constructor(props){
    super(props)
  }
  render () {
    const { catalogues, catalogue,
            catalogueDelete, deleteTireCatalogue } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteTireCatalogue(
                                catalogueDelete.id, catalogues, catalogue, this.props.t)}
                className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
};

const structuredSelector = createStructuredSelector({
  catalogues : state => state.catalogues,
  catalogue  : state => state.catalogue
});

const mapDispatchToProps = { deleteTireCatalogue };

export default connect(structuredSelector, mapDispatchToProps)(TireCatalogueDeleteItem);
