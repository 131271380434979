import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const DropView = ({ fileName, fileData, cols, header }) => {
  const [showModal, setShowModal] = useState(null)

  const launchModal = () => {
    fileData.shift()
    const handleClose = () => setShowModal(null)
    return (
      <Modal show={showModal} onHide={handleClose} dialogClassName="modal-sm-mc-drop-view">
        <Modal.Header closeButton>
          <p>Total de Registros: <span style={{ fontWeight: 'bold' }}>{fileData.length}</span></p>
        </Modal.Header>
        <Modal.Body>
          <div style={{ overflow: 'auto' }}>
            <table className="table table-striped table-bordered table-sm">
              <thead>
                <tr>
                  {
                    header.map((header, index) => {
                      return <th key={index} class='col'>{header}</th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  fileData.map((r, idx) => {
                    if (r.length > 0) {
                      return (
                        <tr key={idx}>
                          {
                            cols.map(c => {
                              return <td key={c.key}>
                                {!!r[c.key] ? r[c.key].toString() : r[c.key]}
                              </td>
                            })
                          }
                        </tr>
                      )
                    } else {
                      return null;
                    }
                  })
                }
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>

        </Modal.Footer>
      </Modal>
    )
  }

  const setStateShow = () => {
    setShowModal(true)
  }

  return (
    <>
      <div onClick={setStateShow} className='drop_file'>
        <img src='../../images/excel.png' alt='image_excel' />
        <p>{fileName}</p>
      </div>
      {showModal ? launchModal() : null}
    </>
  )
}

export default DropView