import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as constantsTireBrand from "../../constants/actions/TireBrands";
import * as APIS from "../../constants/apis";

import TireModelNewItem from "./TireModels/TireModelNewItem";
import TireModelList from "./TireModels/TireModelList";

function getTireBrand(name) {
  let brand = {};
  let models = [];
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_TIREBRANDS}/${name}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        brand = json;
        return fetch(APIS.API_REST_TIREMODELS.replace("tirebrand_id",
          brand.id), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        models = json.Tiremodels;
        dispatch(onTireBrandSuccess({
          brand: brand,
          models: models
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  }
}

export function onTireBrandSuccess(json) {
  return {
    type: constantsTireBrand.GET_BRAND_SUCCESS,
    json
  }
}

export function onReqSuccess(brands, brand, message) {
  const json = {
    brands: brands,
    brand: brand,
    message: message
  };
  return {
    type: constantsTireBrand.REQUEST_SUCCESS,
    json
  };
};

class TireBrandEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: ""
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getTireBrand(id);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.brand || (this.props.brand !== prevProps.brand)) {
      this.setState({
        name: this.props.brand.name
      });
    }
  }

  onSubmit = event => {
    event.preventDefault();
    const { onReqSuccess, brands } = this.props;

    const name = this.props.match.params.id;
    const data = {
      ...this.state,
    };

    return trackPromise(fetch(`${APIS.API_REST_TIREBRANDS}/${name}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "PUT",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          window.location.reload(false);
          onReqSuccess(brands, this.props.t("alerts.b-tire-item-5"));
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  showSuccessDialog = (message) => {
    if (!!message && !!toast) {
      toast.success(message);
    }
  };

  showErrorDialog = (error) => {
    if (!!error && !!toast) {
      toast.error(error);
    }
  };

  render() {
    const { brand, models, message, error } = this.props;

    this.showErrorDialog(error);
    this.showSuccessDialog(message);
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!brand ?
            <div className="container-fluid mt-100">

              <h3>{ authUser.roles != "ANALYST" ? this.props.t("tire-brands.tire-edit") : this.props.t("tire-brands.tire-see")}</h3>
              <br /><br />
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="nameInput">{this.props.t("tire-brands.tire-item-1")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-brands.tire-item-1")}
                        id="nameInput"
                        name="name"
                        value={this.state.name}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                {
                  authUser.roles != "ANALYST" ?
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <button type="submit"
                          className="btn btn-primary">
                          <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                      </button>
                      </div>
                    </div>
                  :
                  <div className="row">
                      <div className="col-md-12 text-center">
                        <button type="button"
                          onClick={() => window.history.back()}
                          className="btn btn-primary">
                          <i className="fa fa-arrow-left"></i> {this.props.t("globals.back")}
                      </button>
                      </div>
                    </div>
                }
              </form>
              <br />
              {
                authUser.roles != "ANALYST" ?
                <>
                  <h3> {this.props.t("tire-brands.tire-model")} </h3>
                  <TireModelNewItem t={this.props.t}/>
                </>
                :
                null
              }
              <br />
              <hr />
              <TireModelList brand={brand} t={this.props.t} authUser={authUser}
                models={models} />
            </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  brands: state => state.brands,
  brand: state => state.brand,
  models: state => state.models,
  message: state => state.message,
  error: state => state.error
});

const mapDispatchToProps = { getTireBrand, onReqSuccess };

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(TireBrandEditItem));
