import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import { AuthUserContext, withAuthorization } from "../../Auth/Session";

import * as constantsTireCatalogue
  from "../../../constants/actions/TireCatalogues";
import * as APIS from "../../../constants/apis";

function getTireCatalogue(name, customer, t) {
  let catalogue = {};
  let brands, models, dimensions = [];
  return dispatch => {
    return trackPromise(fetch(`${APIS.API_REST_TIRECATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        catalogue = json;
        return fetch(APIS.API_REST_CUSTOMER_TIREBRANDS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.retread-item-14"));
        }
        return response.json();
      })
      .then(json => {
        brands = json.Tirebrands;
        return fetch(APIS.API_REST_CUSTOMER_TIREMODELS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tirebrand_id",
          catalogue.marca_id), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.retread-item-20"));
        }
        return response.json();
      })
      .then(json => {
        models = json.Tiremodels;
        return fetch(APIS.API_REST_CUSTOMER_TIREDIMENSIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tiremodel_id", catalogue.modelo_id), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        })
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.retread-item-16"));
        }
        return response.json();
      })
      .then(json => {
        dimensions = json.Dimentions;
        dispatch(getTireCatalogueAndListsSuccess({
          customer: customer,
          catalogue: catalogue,
          brands: brands,
          bands: models,
          dimensions: dimensions
        }));
      })
      .catch(error => {
        toast.error(error.message.toString());
      }));
  };
};

function getBandsList(brandId, props) {
  return dispatch => {
    if (!brandId) {
      return dispatch(getTireCatalogueAndListsSuccess({
        catalogue: props.catalogue,
        brands: props.brands,
        bands: [],
        dimensions: []
      }));
    }
    trackPromise(fetch(APIS.API_REST_CUSTOMER_TIREMODELS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tirebrand_id", brandId), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(props.t("alerts.retread-item-15"));
        }
        return response.json();
      })
      .then(json => {
        dispatch(getTireCatalogueAndListsSuccess({
          customer: props.customer,
          catalogue: props.catalogue,
          brands: props.brands,
          bands: json.Tiremodels,
          dimensions: props.dimensions
        }));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

function getDimsList(modelId, props) {
  return dispatch => {
    if (!modelId) {
      return dispatch(getTireCatalogueAndListsSuccess({
        customer: props.customer,
        catalogue: props.catalogue,
        brands: props.brands,
        bands: props.bands,
        dimensions: []
      }));
    }
    trackPromise(fetch(APIS.API_REST_CUSTOMER_TIREDIMENSIONS.replace("customer_id", localStorage.getItem("GlobalCustomerId")).replace("tiremodel_id", modelId), {
      headers: {
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(props.t("alerts.retread-item-16"));
        }
        return response.json();
      })
      .then(json => {
        dispatch(getTireCatalogueAndListsSuccess({
          customer: props.customer,
          catalogue: props.catalogue,
          brands: props.brands,
          bands: props.bands,
          dimensions: json.Dimentions
        }));
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  }
};

export function getTireCatalogueAndListsSuccess(json) {
  return {
    type: constantsTireCatalogue.GET_LISTS_SUCCESS,
    json
  };
};

export function getReqSuccess(customer, catalogues, catalogue, message) {
  const json = {
    customer: customer,
    catalogues: catalogues,
    catalogue: catalogue,
    message: message
  };
  return {
    type: constantsTireCatalogue.GET_REQUEST_SUCCESS,
    json
  };
};

class TireCatalogueEditItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      marca: "",
      modelo: "",
      dimension_id: "",
      prof_original: "",
      prof_minima: "",
      precio: "",
      distribuidor: "",
      presion_minima: "",
      presion_maxima: "",
      costo_milimetraje: "",
      customer_id: "",
      is_equal_dimension: null,
    };
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    const { customer } = this.props;
    this.props.getTireCatalogue(id, customer, this.props.t);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.catalogue || (this.props.catalogue !== prevProps.catalogue)) {
      this.setState({
        marca: this.props.catalogue.marca_id + "|" +
          this.props.catalogue.marca,
        modelo: this.props.catalogue.modelo_id + "|" +
          this.props.catalogue.modelo,
        dimension_id: this.props.catalogue.dimension_id,
        prof_original: this.props.catalogue.prof_original,
        prof_minima: this.props.catalogue.prof_minima,
        precio: this.props.catalogue.precio,
        distribuidor: this.props.catalogue.distribuidor,
        presion_minima: this.props.catalogue.presion_minima,
        presion_maxima: this.props.catalogue.presion_maxima,
        costo_milimetraje: this.props.catalogue.costo_milimetraje,
        customer_id: this.props.catalogue.customer_id,
        is_equal_dimension: this.props.catalogue.is_equal_dimension
      });
    };
  };

  onSubmitTireCatalogue = (event, catalogues, catalogue, customer,
    history, getReqSuccess, t) => {
    event.preventDefault();

    if (window.confirm(this.props.t("alerts.catalogue-item-3"))) {
      const name = this.props.match.params.id;
      const data = {
        marca_id: this.state.marca.split("|")[0],
        modelo_id: this.state.modelo.split("|")[0],
        ...this.state,
      };

      return trackPromise(fetch(`${APIS.API_REST_TIRECATALOGUES.replace("customer_id", localStorage.getItem("GlobalCustomerId"))}/${name}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "PUT",
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(json => {
          const status = json.message;
          if (!status) {
            history.push("/tirecatalogues");
            getReqSuccess(customer, catalogues, catalogue, this.props.t("alerts.retread-item-18"));
          } else {
            throw new Error(status);
          }
        })
        .catch(error => {
          toast.error(error.message.toString());
        }));
    }
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    switch (event.target.name) {
      case "marca":
        const brandId = event.target.value.split("|")[0];
        this.setState({
          modelo: "",
          dimension_id: "",
          prof_original: "",
          presion_minima: "",
          presion_maxima: "",
          is_equal_dimension: false
        });
        this.props.getBandsList(brandId, this.props);
        break;
      case "modelo":
        const modelId = event.target.value.split("|")[0];
        this.setState({
          dimension_id: "",
          prof_original: "",
          presion_minima: "",
          presion_maxima: "",
          is_equal_dimension: false
        });
        this.props.getDimsList(modelId, this.props);
        break;
      case "dimension_id":
        var arr = this.props.dimensions;
        var arr2 = arr.filter(arr => arr.id == event.target.value);
        var p_ori = "";
        if (arr2.length > 0) {
          p_ori = arr2[0].profundidad_original
        }
        this.setState({
          prof_original: p_ori,
          presion_minima: "",
          presion_maxima: "",
          is_equal_dimension: false
        });
        break;
      case "precio":
        if (this.state.prof_minima != "" && this.state.prof_original != "") {
          var cxm = parseFloat(event.target.value / parseFloat(this.state.prof_original - this.state.prof_minima));
          this.setState({ costo_milimetraje: cxm })
        }
        break;
      default:
      // Do nothing
    };
  };

  onChangeEqualDimention = event => {
    if (event.target.checked) {
      var arr = this.props.dimensions;
      var arr2 = arr.filter(arr => arr.id == this.state.dimension_id);
      var p_min = "";
      var p_max = "";
      if (arr2.length > 0) {
        p_min = arr2[0].presion_minima;
        p_max = arr2[0].presion_maxima;
      }
      this.setState({
        presion_minima: p_min,
        presion_maxima: p_max,
        is_equal_dimension: true
      });
    } else {
      this.setState({
        presion_minima: "",
        presion_maxima: "",
        is_equal_dimension: false
      });
    }
  }

  onChangePess = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { customer, catalogue, catalogues, brands, bands, dimensions, history,
      getReqSuccess } = this.props;
    const { marca, modelo, dimension_id, prof_original, prof_minima,
      precio, distribuidor, presion_minima, presion_maxima,
      costo_milimetraje, is_equal_dimension } = this.state;


    console.log(presion_maxima, presion_minima)

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          !!catalogue ?
            <div className="container-fluid mt-100">

              <h3>{this.props.t("tire-catalogue.tire-edit")}</h3>
              <br /><br />
              <form onSubmit={(event) => this.onSubmitTireCatalogue(event,
                catalogues, catalogue, customer,
                history, getReqSuccess, this.props.t)}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="cataloguebrandInput">{this.props.t("tire-catalogue.tire-item-1")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-1")}
                        id="cataloguebrandInput"
                        name="marca"
                        value={marca}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("tire-catalogue.tire-select-brand")}</option>
                        {
                          !!brands && brands.map(brand => {
                            return <option key={brand.id}
                              value={brand.id + "|" + brand.name}>
                              {brand.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="cataloguebandInput">{this.props.t("tire-catalogue.tire-item-13")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-13")}
                        id="cataloguebandInput"
                        name="modelo"
                        value={modelo}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("tire-catalogue.tire-select-band")}</option>
                        {
                          !!bands && bands.map(band => {
                            return <option key={band.id}
                              value={band.id + "|" + band.name}>
                              {band.name}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="cataloguedimensionInput">{this.props.t("tire-catalogue.tire-item-3")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <select className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-3")}
                        id="cataloguedimensionInput"
                        name="dimension_id"
                        value={dimension_id}
                        onChange={this.onChange}
                        required>
                        <option value="">{this.props.t("tire-catalogue.tire-select-dimensions")}</option>
                        {
                          !!dimensions && dimensions.map(dimension => {
                            return <option key={dimension.id}
                              value={dimension.id}>
                              {dimension.dimention}
                            </option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="profOrInput">{this.props.t("tire-catalogue.tire-item-6")}</label>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-6")}
                        id="profOrInput"
                        name="prof_original"
                        type="number"
                        value={prof_original}
                        readOnly={true}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="profMinInput">{this.props.t("tire-catalogue.tire-item-7")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-7")}
                        id="profMinInput"
                        name="prof_minima"
                        type="number"
                        value={prof_minima}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="priceInput">{this.props.t("tire-catalogue.tire-item-10")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-10")}
                        id="priceInput"
                        name="precio"
                        type="number"
                        value={precio}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="providerInput">{this.props.t("tire-catalogue.tire-item-11")}</label>
                      <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-11")}
                        id="providerInput"
                        name="distribuidor"
                        type="text"
                        value={distribuidor}
                        onChange={this.onChange}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2 mt-4">
                      <label htmlFor="permissionIdInput">Mismas Presiones</label>
                    </div>
                    <div className="col-sm-10 mt-4">
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="is_equal_dimension"
                          name="is_equal_dimension"
                          checked={is_equal_dimension}
                          onChange={this.onChangeEqualDimention}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="pressMinInput">{this.props.t("tire-catalogue.tire-item-8")}</label>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-8")}
                        id="pressMinInput"
                        name="presion_minima"
                        type="number"
                        value={presion_minima}
                        readOnly={is_equal_dimension}
                        onChange={this.onChangePess}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="pressMaxInput">{this.props.t("tire-catalogue.tire-item-9")}</label>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-9")}
                        id="pressMaxInput"
                        name="presion_maxima"
                        type="number"
                        value={presion_maxima}
                        readOnly={is_equal_dimension}
                        onChange={this.onChangePess}
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="costInput">{this.props.t("tire-catalogue.tire-item-12")}</label>
                    </div>
                    <div className="col-sm-10">
                      <input className="form-control"
                        placeholder={this.props.t("tire-catalogue.tire-item-12")}
                        id="costInput"
                        name="costo_milimetraje"
                        type="number"
                        value={costo_milimetraje}
                        readOnly={true}
                        required />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button type="submit"
                      className="btn btn-primary">
                      <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                    </button>
                  </div>
                </div>
              </form>
            </div> : null
        )}
      </AuthUserContext.Consumer>
    );
  };
}

const structuredSelector = createStructuredSelector({
  customer: state => state.customer,
  catalogues: state => state.catalogues,
  catalogue: state => state.catalogue,
  brands: state => state.brands,
  bands: state => state.bands,
  dimensions: state => state.dimensions
});

const mapDispatchToProps = {
  getTireCatalogue, getBandsList,
  getDimsList, getReqSuccess
};

const condition = authUser =>
  !!authUser;

export default withAuthorization(condition)(connect(structuredSelector, mapDispatchToProps)(TireCatalogueEditItem));
