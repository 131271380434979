import { toast } from "react-toastify";
import * as APIS from "./apis";

// let authUser = null

// export const getAuthUser = (authUsers) => {
//     authUser = authUsers
// }

// export const compassifyChecklist = () => {
//     if (authUser) {
//         window.kompassifyChecklistV2.startChecklist("6c88d8fb-567a-47f6-8fa6-2ba8cd0462fb");
//     }
// }

const date = new Date();

export const initSegment = (authUser) => {
  if (authUser) {
    if (authUser.daysCreated <= 14) {
      window.kompassifySegmentation.addUserToSegment("progressbar-init");
    } else if (authUser.daysCreated >= 15) {
      window.kompassifySegmentation.addUserToSegment("after-14-checklist");
    } else {
      console.log("tour-hide");
    }

    if (date.getDate() === 21) {
      window.kompassifySegmentation.addUserToSegment("email-request");
    }
    // fetch(`${APIS.API_REST_USERS}/${authUser.uid}`, {
    //     headers: {
    //       "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
    //     },
    // })
    // .then(response => {
    //     return response.json();
    // })
    // .then(json => {
    //     fetch(`${APIS.API_REST_MULTI_USERS}/${json.id}`, {
    //         headers: {
    //             "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
    //         },
    //         })
    //         .then(response => {
    //             return response.json();
    //         })
    //         .then(json => {
    //             console.log("entrando")
    //             console.log(json.clients_users)
    //             for (let i = 0; i < json.clients_users.length; i++) {
    //                 if(json.clients_users[i].customer_id === 201 || json.clients_users[i].customer_id === 276) {
    //                     window.kompassifySegmentation.addUserToSegment("progressbar-init")
    //                     console.log(localStorage.kompassifyUserSegments)
    //                 }
    //             }
    //         })
    //         .catch(error => {
    //             toast.error(error.toString());
    //         });

    // })
    // .catch(error => {
    //     toast.error(error.toString());
    // });
  }
};

// export const compassifyInitTour = () => {
//     if(window.kompassifySegmentation) {
//         window.kompassifySegmentation.addUserToSegment("Copy Prueba")
//         window.kompassifyLaunchTour("da9ae643-903c-490a-aa66-8d5619a2305d", 0, 5);

//         window.addEventListener('message', function (event) {
//           if (event.data.TYPE === 'KOMPASSIFY_TOUR_STARTED') {
//               // the tour has started
//               console.log('The tour with uuid ', event.data.tourUuid, ' has started');
//           }

//           if (event.data.TYPE === 'KOMPASSIFY_TOUR_ENDED') {
//               // the tour has ended
//               console.log('The tour with uuid ', event.data.tourUuid, ' has ended');
//           }

//           if (event.data.TYPE === 'KOMPASSIFY_TOUR_STEP') {
//               // Tour Step played
//               console.log('The Step Number', event.data.stepIndex, 'of the tour with the uuid', event.data.tourUuid, ' has been played');
//           }

//           if (event.data.TYPE === 'KOMPASSIFY_TOUR_SKIPPED') {
//               // the tour has been skipped
//               console.log('The tour with uuid ', event.data.tourUuid, ' has been skipped');
//           }
//         });
//     }

// }
