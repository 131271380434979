export const REQ_GROUPS_SUCCESS = "REQ_GROUPS_SUCCESS"
export const CREATE_GROUPS_SUCCESS = "CREATE_GROUPS_SUCCESS"
export const PUT_GROUPS_SUCCESS = "PUT_GROUPS_SUCCESS"
export const DELETE_GROUPS_SUCCESS = "DELETE_GROUPS_SUCCESS"

export const REQ_GROUPS_ONE_SUCCESS = "REQ_GROUPS_ONE_SUCCESS"
export const CREATE_GROUPS_ONE_SUCCESS = "CREATE_GROUPS_ONE_SUCCESS"
export const PUT_GROUPS_ONE_SUCCESS = "PUT_GROUPS_ONE_SUCCESS"
export const DELETE_GROUPS_ONE_SUCCESS = "DELETE_GROUPS_ONE_SUCCESS"

export const REQ_GROUPS_TWO_SUCCESS = "REQ_GROUPS_TWO_SUCCESS"
export const CREATE_GROUPS_TWO_SUCCESS = "CREATE_GROUPS_TWO_SUCCESS"
export const PUT_GROUPS_TWO_SUCCESS = "PUT_GROUPS_TWO_SUCCESS"
export const DELETE_GROUPS_TWO_SUCCESS = "DELETE_GROUPS_TWO_SUCCESS"