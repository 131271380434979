import React from "react";
import { Link } from "react-router-dom";
import TireDimensionDeleteItem from "./TireDimensionDeleteItem";

class TireDimensionsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: ""
    };
  };

  getTireDimensionsList = (model, dimensions) => {
    const { search } = this.state;

    if (!dimensions) return null;
    return (
      <div className="text-center v-scroll">
        <table className="table table-striped table-bordered table-sm mb-0">
          <thead>
            <tr>
              <th scope="col">{this.props.t("globals.code")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-1")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-4")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-5")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-2")}</th>
              <th scope="col">{this.props.t("tire-brands.tire-item-3")}</th>
            </tr>
          </thead>
          <tbody>
            {
              dimensions.filter(dimension => {
                return dimension.dimention.toLowerCase().search(search.toLowerCase()) >= 0
              }).map((dimension, idx) => {
                return (
                  <tr key={idx}>
                    <td>{dimension.code}</td>
                    <td>{dimension.dimention}</td>
                    <td>{dimension.profundidad_original}</td>
                    <td>{dimension.profundidad_minima}</td>
                    <td>
                      {
                        !!dimension.active ? "Activo" : "Inactivo"
                      }
                    </td>
                    <td>
                      {
                        this.props.authUser.roles != "ANALYST" ?
                        <>
                        <Link to={"/tiredimensions/" + model.id +
                          "/edit/" + dimension.id}
                          className="btn btn-primary">
                          <i className="fa fa-edit"></i> {this.props.t("globals.edit")}
                        </Link>&nbsp;
                        <TireDimensionDeleteItem dimensionDelete={dimension} t={this.props.t} />
                        </>
                        :
                        <>
                        <Link to={"/tiredimensions/" + model.id +
                          "/edit/" + dimension.id}
                          className="btn btn-primary">
                          <i className="fa fa-eye"></i> {this.props.t("globals.see")}
                        </Link>&nbsp;
                        </>
                      }
                    </td>
                  </tr>)
              })
            }
          </tbody>
        </table>
      </div>
    );
  };

  onChange = (event) => {
    this.setState({
      search: event.target.value
    });
  };

  render() {
    const { model, dimensions } = this.props;
    const { search } = this.state;

    return (
      <div className="row">
        <div className="col-md-12 mb-2">
          <h3>{this.props.t("tire-brands.tire-dimensions")}</h3>
        </div>
        <div className="col-md-8">
          {
            this.props.authUser.roles != "ANALYST" ?
            <Link
              className="btn btn-primary"
              to={"/tiredimensions/" + model.id + "/new"}>
              <i className="fa fa-plus"></i> {this.props.t("globals.register")}
            </Link>
            :
            null
          }
        </div>
        <div className="col-md-4">
          <input
            className="form-control"
            id="search"
            placeholder={this.props.t("globals.search-input")}
            value={search}
            onChange={this.onChange}
          />
        </div>
        <div className="col-md-12">
          <br /><br />
          {this.getTireDimensionsList(model, dimensions)}
        </div>
      </div>
    );
  };
};

export default TireDimensionsList;
