import Q from "q";
import xlsx from "xlsx";
import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../../Auth/Session";
import ErrorsModal from "./ErrorsModal";
import DragDropFile from "./DragDropFile";
import DataInput from "./DataInput";
import OutTable from "./OutTable";
import * as APIS from "../../../constants/apis";
import * as VALIDATIONS from "../../../constants/validations";
import * as VARIABLES from "../../../constants/variables";

const valConnection = VALIDATIONS.VAL_CONNECTION;
const isInternetConnected = VALIDATIONS.isInternetConnected;
const variablesObs = VARIABLES.UNMOUNT_OBSERVATIONS;
const randomString = Math.random().toString(36);

const INITIAL_STATE = {
  filePath: "",
  inputKey: randomString,
  type: "",
  fields: [],
  done: [],
  notDone: [],
  data: [],
  cols: [],
  errors: [],
  vehicles: [],
  tires: [],
  warehouses: [],
  isTireMassive: "no",
  order: "",
  observacion: "",
};

class MultipleWorks extends Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();

    this.serverErrors = [];
    this.handleFile = this.handleFile.bind(this);
    this.exportTemplate = this.exportTemplate.bind(this);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.setState({
      isTireMassive: localStorage.getItem("is_tire_massive"),
    });
  };

  componentDidMount() {
    let warehouses, vehicles, tires;
    trackPromise(
      fetch(
        `${APIS.API_REST_WAREHOUSES.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        )}`,
        {
          headers: {
            Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw Error(json.message);
          }
          warehouses = json.bodegas;
        })
        .then(() => {
          return fetch(
            `${APIS.API_REST_TIRES_MIN_INFO.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            )}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw Error(json.message);
          }
          tires = json.tires;
        })
        .then(() => {
          return fetch(
            `${APIS.API_REST_VEHICLES_MIN_INFO.replace(
              "customer_id",
              localStorage.getItem("GlobalCustomerId")
            )}`,
            {
              headers: {
                Authorization:
                  "Basic " + localStorage.getItem("firebaseAuthToken"),
                "Accept-language": localStorage.getItem("LanguageSelected"),
              },
            }
          );
        })
        .then((response) => response.json())
        .then((json) => {
          if (!!json.message) {
            throw Error(json.message);
          }
          vehicles = json.vehicles;
          this.setState({
            vehicles: vehicles,
            warehouses: warehouses,
            tires: tires,
          });
        })
        .catch((error) => {
          toast.error(error.message);
        })
    );
  }

  onChange = (event) => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
    let elements = [];
    switch (event.target.value) {
      case "llantas":
        elements = [
          this.props.t("multiple.work-item-1"),
          this.props.t("multiple.work-item-2"),
          this.props.t("multiple.work-item-3"),
          this.props.t("multiple.work-item-4"),
          this.props.t("multiple.work-item-5"),
          this.props.t("client-tires.client-item-27"),
          this.props.t("client-vehicles.client-item-7"),
          this.props.t("ins-one.ins-one-31"),
        ];
        break;
      case "vehiculos":
        elements = [
          this.props.t("multiple.work-item-6"),
          this.props.t("multiple.work-item-7"),
          this.props.t("multiple.work-item-3"),
          this.props.t("multiple.work-item-4"),
          this.props.t("multiple.work-item-5"),
          this.props.t("multiple.work-item-8"),
          this.props.t("multiple.work-item-9"),
          this.props.t("client-tires.client-item-27"),
          this.props.t("client-vehicles.client-item-7"),
          this.props.t("ins-one.ins-one-31"),
        ];
        break;
      case "bodega-llanta":
        elements = [
          this.props.t("multiple.work-item-1"),
          this.props.t("multiple.work-item-2"),
          this.props.t("multiple.work-item-4"),
          this.props.t("client-tires.client-item-27"),
          this.props.t("client-vehicles.client-item-7"),
        ];
        break;
      default:
      // Do nothing
    }
    this.setState({ fields: elements });
  };

  makeCols = (refstr) => {
    let o = [],
      C = xlsx.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) {
      o[i] = {
        name: xlsx.utils.encode_col(i),
        key: i,
      };
    }
    return o;
  };

  findWithAttr = (array, attr, value) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };

  getVehicleId = (codigo) => {
    const vehicles = this.state.vehicles;
    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].plate === codigo) {
        return vehicles[i].id;
      }
    }
    return null;
  };

  getTireId = (codigo) => {
    const tires = this.state.tires;
    for (let i = 0; i < tires.length; i++) {
      if (tires[i].codigo == codigo) {
        return tires[i].id;
      }
    }
    return null;
  };

  getWarehouseId = (codigo) => {
    const warehouses = this.state.warehouses;
    for (let i = 0; i < warehouses.length; i++) {
      if (warehouses[i].name === codigo) {
        return warehouses[i].id;
      }
    }
    return null;
  };

  getDataErrors = (cols, data) => {
    let errors = [];

    // Número de columnas
    let fields = this.state.fields;
    if (cols.length !== fields.length) {
      errors.push({
        message: this.props.t("multiple.global-item-1"),
      });
    }

    // Número de datos
    if (data.length < 2) {
      errors.push({
        message: this.props.t("multiple.global-item-2"),
      });
    }

    // Columnas necesarias
    for (let i = 0; i < fields.length; i++) {
      if (data[0].indexOf(fields[i]) === -1) {
        errors.push({
          message:
            this.props.t("multiple.global-item-3") +
            fields[i] +
            this.props.t("multiple.global-item-4") +
            this.props.t("multiple.global-item-5"),
        });
      }
    }

    // Validaciones de los datos
    let type = this.state.type;
    for (let i = 1; i < data.length; i++) {
      for (let j = 0; j < fields.length; j++) {
        if (
          data[0][j] === this.props.t("multiple.work-item-7") ||
          data[0][j] === this.props.t("multiple.work-item-8")
        ) {
          if (!!data[i][j]) {
            if (!!data[i][j] && isNaN(data[i][j])) {
              errors.push({
                message:
                  this.props.t("multiple.vehicle-item-15") +
                  (i + 1) +
                  this.props.t("multiple.work-item-16") +
                  data[0][j],
              });
            }
          }
        }

        if (data[0][j] === this.props.t("multiple.work-item-2")) {
          if (!!data[i][j]) {
            const warehouse = this.getWarehouseId(data[i][j]);
            if (!!warehouse) {
              data[i][j] = data[i][j];
            } else {
              errors.push({
                message:
                  this.props.t("multiple.vehicle-item-15") +
                  (i + 1) +
                  this.props.t("multiple.work-item-10") +
                  data[i][j] +
                  this.props.t("multiple.work-item-11"),
              });
            }
          }
        }
        if (data[0][j] === this.props.t("multiple.work-item-6")) {
          const plate = this.getVehicleId(data[i][j]);
          if (!!plate) {
            data[i][j] = data[i][j];
          } else {
            errors.push({
              message:
                this.props.t("multiple.vehicle-item-15") +
                (i + 1) +
                this.props.t("multiple.work-item-12") +
                data[i][j] +
                this.props.t("multiple.work-item-11"),
            });
          }
        }

        if (data[0][j] === this.props.t("multiple.work-item-3")) {
          if (this.state.type === "llantas") {
            const draft = [];
            const workTypes =
              localStorage.getItem("GlobalCustomerId") === "650"
                ? VARIABLES.tireWorkTypes
                : VARIABLES.tireWorkTypes.slice(0, 3);
            workTypes.forEach((item) =>
              draft.push({
                name: this.props.t(`selectors.${item.name}`),
                value: item.value,
              })
            );
            if (draft.filter((item) => item.name === data[i][j])[0]) {
              data[i][j] = draft.filter(
                (item) => item.name === data[i][j]
              )[0].value;
            } else {
              errors.push({
                message:
                  this.props.t("multiple.vehicle-item-15") +
                  (i + 1) +
                  this.props.t("multiple.work-item-13") +
                  " " +
                  `${workTypes.map((item) => {
                    return ` ${this.props.t(`selectors.${item.name}`)}`;
                  })}`,
              });
            }
          } else if (this.state.type === "vehiculos") {
            const draft = [];
            const workTypes =
              localStorage.getItem("GlobalCustomerId") === "650"
                ? VARIABLES.vehicleWorkTypes
                : VARIABLES.vehicleWorkTypes.slice(0, 2);
            workTypes.forEach((item) =>
              draft.push({
                name: this.props.t(`selectors.${item.name}`),
                value: item.value,
              })
            );
            if (draft.filter((item) => item.name === data[i][j])[0]) {
              data[i][j] = draft.filter(
                (item) => item.name === data[i][j]
              )[0].value;
            } else {
              errors.push({
                message:
                  "La fila" +
                  (i + 1) +
                  this.props.t("multiple.work-item-13") +
                  " " +
                  `${workTypes.map((item) => {
                    return ` ${this.props.t(`selectors.${item.name}`)}`;
                  })}`,
              });
            }
          }
        }

        if (data[0][j] === this.props.t("multiple.work-item-1")) {
          const tire = this.getTireId(data[i][j]);
          if (!!tire) {
            data[i][j] = data[i][j];
          } else {
            errors.push({
              message:
                this.props.t("multiple.vehicle-item-15") +
                (i + 1) +
                this.props.t("multiple.work-item-14") +
                data[i][j] +
                this.props.t("multiple.work-item-11"),
            });
          }
        }

        if (data[0][j] === this.props.t("multiple.work-item-4")) {
          if (
            moment(data[i][j], "YYYY-MM-DD").format("YYYY-MM-DD") !== data[i][j]
          ) {
            errors.push({
              message:
                this.props.t("multiple.vehicle-item-15") +
                (i + 1) +
                this.props.t("multiple.work-item-15"),
            });
          }
        }
      }
    }

    return errors;
  };

  handleFile = (file) => {
    this.setState({ data: [], cols: [], done: [], notDone: [], errors: [] });

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (event) => {
      const bstr = event.target.result;
      const wb = xlsx.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsx.utils.sheet_to_json(ws, { header: 1, raw: false });
      const cols = this.makeCols(ws["!ref"]);
      const errors = this.getDataErrors(cols, data);
      if (errors.length === 0) {
        this.setState({
          data: data,
          cols: this.makeCols(ws["!ref"]),
          inputKey: Math.random().toString(36),
        });
      } else {
        this.modal.current.showModal(errors);
        this.setState({
          errors: [],
          data: [],
          cols: [],
          inputKey: Math.random().toString(36),
        });
      }
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  exportTemplate = (event) => {
    event.preventDefault();

    const type =
      this.state.type === "llantas"
        ? "tires"
        : this.state.type === "vehiculos"
        ? "vehicles"
        : "warehouseTires";
    let header = this.state.fields;
    const ws = xlsx.utils.aoa_to_sheet([header]);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Plantilla");
    xlsx.writeFile(
      wb,
      this.props.t("multiple.work-item-17", {
        type: this.props.t(`navigation.${type}`).toLowerCase(),
      }) + ".xlsx"
    );
  };

  formatData = (register, authUser) => {
    let work = { params: {} };
    // Set customer and type
    work.cost = register[4];
    if (this.state.type === "llantas") {
      if (register[1] != "") {
        work.warehouse_id = this.getWarehouseId(register[1]);
      }
      work.customer_id = localStorage.getItem("GlobalCustomerId");
      work.worktype = register[2];
      work.tire_id = this.getTireId(register[0]);
      work.order = register[5];
      work.observacion = register[6];
      work.technical = register[7];
      // Set last date and analyst
      work.work_date = moment(register[3]).utc().format();
    } else if (this.state.type === "bodega-llanta") {
      if (register[1] != "") {
        work.warehouse_id = this.getWarehouseId(register[1]);
      }
      work.tire_id = this.getTireId(register[0]);
      work.worktype = "bodegas";
      work.work_date = moment(register[2]).utc().format();
      work.order = register[3];
      work.observacion = register[4];
    } else {
      work.customer_id = localStorage.getItem("GlobalCustomerId");
      work.km = register[1];
      work.vehicle_id = this.getVehicleId(register[0]);
      work.next_measurament = parseInt(register[5]);
      work.worktype = register[2];
      work.params.cantidad = register[6];
      work.order = register[7];
      work.observacion = register[8];
      work.technical = register[9];
      // Set last date and analyst
      work.work_date = moment(register[3]).utc().format();
    }
    return work;
  };

  runValidations = (json) => {
    if (!!json.message) {
      if (json.message === "Fallo La Validacion") {
        let msg = "";
        Object.keys(json.resp).forEach((key) => {
          if (!json.resp[key][1]) {
            msg = msg + json.resp[key][0] + "\n";
          }
        });
        throw new Error(msg);
      } else {
        throw new Error(json.message);
      }
    }
  };

  saveRegister = (idx, register, authUser) => {
    let deferred = Q.defer();
    if (isInternetConnected(toast)) deferred.reject();
    const data = this.formatData(register, authUser);
    let url;

    if (data.worktype === "Bodegas") {
      data.worktype = data.worktype.toLowerCase();
      delete data.params;
    }

    // let str = "?by_inspection=false";
    let str = "";
    if (this.state.type == "llantas") {
      url =
        APIS.API_REST_TIRE_WORK.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("tire_id", data.tire_id) + str;
    } else if (this.state.type == "vehiculos") {
      url =
        APIS.API_REST_VEHICLE_WORK.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("vehicle_id", data.vehicle_id) + str;
    } else {
      delete data.params;
      url =
        APIS.API_REST_TIRE_WORK.replace(
          "customer_id",
          localStorage.getItem("GlobalCustomerId")
        ).replace("tire_id", data.tire_id) + str;
    }
    data.is_massive = true;
    trackPromise(
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("firebaseAuthToken"),
          "Accept-language": localStorage.getItem("LanguageSelected"),
        },
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.runValidations(json);
          return json;
        })
        .then((json) => {
          let done = [...this.state.done];
          done.push(idx);
          this.setState({ done: done });
          deferred.resolve(json);
        })
        .catch((error) => {
          let err = error;
          if (valConnection.indexOf(error.toString()) > -1) {
            err = this.props.t("multiple.global-item-6") + (idx + 1);
          } else {
            err =
              this.props.t("multiple.global-item-7") +
              (idx + 1) +
              ". " +
              error.toString();
          }
          this.serverErrors.push({ message: err });
          let notDone = [...this.state.notDone];
          notDone.push(idx);
          this.setState({ notDone: notDone });
          deferred.reject(this.serverErrors);
        })
    );
    return deferred.promise;
  };

  getCleanedRegisters = (registers) => {
    let newRegisters = [];

    for (let i = 1; i < registers.length; i++) {
      if (registers[i].length > 0) {
        newRegisters.push(registers[i]);
      }
    }

    return newRegisters;
  };

  async saveRegisters(registers, authUser) {
    let deferred = Q.defer();

    let newRegisters = this.getCleanedRegisters(registers);
    for (let i = 0; i < newRegisters.length; i++) {
      if (this.state.done.indexOf(i) === -1) {
        await this.saveRegister(i, newRegisters[i], authUser)
          .then(() => {
            return true;
          })
          .catch((errors) => {
            deferred.reject(errors);
            return deferred.promise;
          });
      }
    }

    deferred.resolve();
    return deferred.promise;
  }

  onSubmit = (event, authUser) => {
    event.preventDefault();
    this.serverErrors = [];
    this.setState({ notDone: [] });

    this.saveRegisters(this.state.data, authUser)
      .then(() => {
        toast.success(this.props.t("multiple.global-item-8"));
        this.setState({
          type: "",
          data: [],
          cols: [],
          done: [],
          notDone: [],
          errors: [],
        });
      })
      .catch((errors) => {
        this.modal.current.showModal(errors);
      });
  };

  render() {
    const { data, cols, done, notDone, fields, type } = this.state;

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div className="container-fluid mt-100">
            <ErrorsModal ref={this.modal} t={this.props.t} />
            <h3>
              {this.state.isTireMassive === "no"
                ? this.props.t("work-tire.client-massive-1")
                : this.props.t("work-tire.client-massive-2")}
            </h3>
            <br></br>
            <DragDropFile handleFile={this.handleFile} t={this.props.t}>
              <form>
                <div className="form-group">
                  <select
                    className="form-control"
                    id="typeSelect"
                    name="type"
                    value={this.state.type}
                    onChange={this.onChange}
                  >
                    <option value="">
                      {this.props.t("work-tire.client-massive-3")}
                    </option>
                    {this.state.isTireMassive === "no" ? (
                      <>
                        <option value="llantas">
                          {this.props.t("work-tire.client-massive-4")}
                        </option>
                        <option value="vehiculos">
                          {this.props.t("work-tire.client-massive-5")}
                        </option>
                      </>
                    ) : (
                      <>
                        <option value="bodega-llanta">
                          {this.props.t("work-tire.client-massive-6")}
                        </option>
                      </>
                    )}
                  </select>
                </div>
                <br />
                {!!type ? (
                  <div>
                    <DataInput
                      t={this.props.t}
                      type={type}
                      handleFile={this.handleFile}
                      exportTemplate={this.exportTemplate}
                      header={fields}
                      inputKey={this.state.inputKey || ""}
                    />
                    {data.length > 1 ? (
                      <OutTable
                        type={type}
                        data={data}
                        cols={cols}
                        done={done}
                        notDone={notDone}
                      />
                    ) : null}
                    {data.length > 1 ? (
                      <div className="row">
                        <div className="col-md-12 mt-5">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ width: "100%" }}
                            onClick={(event) => this.onSubmit(event, authUser)}
                          >
                            <i className="fa fa-save"></i>{" "}
                            {this.props.t("globals.simple-save")}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </form>
            </DragDropFile>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(MultipleWorks);
