export const paginationEstructure = (next, previus, total, limit, pageState) => {
  let pageP, pageN, str;
    if (!!previus) {
      str = previus;
      pageP = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    if (!!next) {
      str = next;
      pageN = str.substring(str.indexOf("?") + 6, str.indexOf("&"));
    }
    let pages = [];
    let start, end;
    let totalPages = Math.ceil(total / limit);
    for (var i = 1; i <= totalPages; i++) {
      pages.push(i)
    }
    let links = [];
    if (totalPages <= 10) {
      start = 1;
      end = totalPages;
    } else {
      start = Math.max(1, (pageState - 4));
      end = Math.min(totalPages, (pageState + 5));

      if (start === 1) {
        end = 10;
      } else if (end === totalPages) {
        start = (totalPages - 9);
      }
    }
    for (var page = start; page <= end; page++) {
      links.push(page);
    }

    return { pageP, pageN, links }
}