import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";
import * as constantsBrand from "../../../constants/actions/Brands";
import * as constantsTireBrand from "../../../constants/actions/TireBrands";
import * as APIS from "../../../constants/apis";

function onSubmitModel(context, event, data, brand, brandId, models, t) {
  event.preventDefault();

  return dispatch => {
    trackPromise(fetch(`${APIS.API_REST_TIREMODELS.replace("tirebrand_id", brandId)}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
      method: "POST",
      body: JSON.stringify({
        tirebrand_id: brand.id,
        ...data
      })
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!!status) {
          throw new Error(status);
        } else {
          dispatch(onRequestSuccess(brand, models, t));
        }
      })
      .then(() => {
        return fetch(APIS.API_REST_TIREMODELS.replace("tirebrand_id",
          brand.id), {
          headers: {
            "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
            "Accept-language": localStorage.getItem("LanguageSelected"),
          }
        });
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(t("alerts.b-tire-item-7"));
        }
        return response.json();
      })
      .then(json => {
        context.setState({
          name: ""
        });
        dispatch(getBrandSuccess({
          brand: brand,
          models: json.Tiremodels
        }));
      })
      .catch(error => {
        let fmtError = "";
        if (error.message.indexOf("already exists") >= 0) {
          fmtError = t("alerts.b-tire-item-8") + data.name + t("alerts.b-tire-item-9");
        }
        dispatch(onRequestError(fmtError, brand, models));
      }));
  };
};

export function onRequestSuccess(brand, models, t) {
  const json = {
    brand: brand,
    models: models,
    message: t("alerts.b-tire-item-10")
  };
  return {
    type: constantsTireBrand.REQUEST_SUCCESS,
    json
  };
};

export function onRequestError(error, brand, models) {
  const json = {
    brand: brand,
    models: models,
    error: error.toString()
  };
  return {
    type: constantsTireBrand.REQUEST_ERROR,
    json
  };
};

export function getBrandSuccess(json) {
  return {
    type: constantsBrand.GET_BRAND_SUCCESS,
    json
  };
};

class TireModelNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      customer_id: localStorage.getItem('GlobalCustomerId')
    };
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name, customer_id } = this.state;
    const { brand, models } = this.props;

    return (
      !!brand ?
        <form onSubmit={(event) => this.props.onSubmitModel(this, event,
          this.state, brand,
          brand.id, models, this.props.t)}>
          <div className="row">
            <div className="col-md-10">
              <input className="form-control"
                onChange={this.onChange}
                name="name"
                placeholder={this.props.t("tire-brands.tire-item-1")}
                value={name}
                required />
              <input type="hidden"
                name="customer_id"
                value={customer_id} />
              <i className="text-info small">{this.props.t("globals.required")}</i>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary w-100"
                type="submit">
                <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
              </button>
            </div>
          </div>
        </form> : null
    );
  };
};

const structuredSelector = createStructuredSelector({
  brand: state => state.brand,
  models: state => state.models
});

const mapDispatchToProps = { onSubmitModel };

export default connect(structuredSelector, mapDispatchToProps)(TireModelNewItem);
