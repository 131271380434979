import React from "react";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { AuthUserContext, withAuthorization } from "../Auth/Session";
import * as APIS from "../../constants/apis";

class InvoiceNewItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: null,
      name: null,
      observacion: null,
    };
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ customer_id: id });
  };

  onSubmit(event, history) {
    event.preventDefault();

    var data = {
      ...this.state,
    };

    trackPromise(fetch(`${APIS.API_REST_INVOICES.replace("customer_id", this.state.customer_id)}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
        "Accept-language": localStorage.getItem("LanguageSelected"),
      }
    })
      .then(response => response.json())
      .then(json => {
        const status = json.message;
        if (!status) {
          toast.success(this.props.t("alerts.invoice-item-6"))
          this.props.history.push("/invoices/"+this.state.customer_id);
        } else {
          throw new Error(status);
        }
      })
      .catch(error => {
        toast.error(error.toString());
      }));
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { history } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="container-fluid mt-100">

            <h3>{this.props.t("invoice.inv-item-18")}</h3>
            <br /><br />
            <form onSubmit={(event) => this.onSubmit(event, history)}>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="name">{this.props.t("invoice.inv-item-19")}</label>
                    <br></br><i className="text-info small">{this.props.t("globals.required")}</i>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("invoice.inv-item-19")}
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-2">
                    <label htmlFor="observacion">{this.props.t("invoice.inv-item-5")}</label>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control"
                      placeholder={this.props.t("invoice.inv-item-5")}
                      id="observacion"
                      name="observacion"
                      value={this.state.observacion}
                      type="text"
                      onChange={this.onChange}
                      required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit"
                    className="btn btn-primary">
                    <i className="fa fa-save"></i> {this.props.t("globals.simple-save")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  };
};

const condition = authUser =>
  !!authUser

export default withAuthorization(condition)(InvoiceNewItem);
