import { MAP_CLEAR_ERROR_SUCCESS, MAP_ERROR_SUCCESS } from '../types/MapErrorsTypes'
import API from '../../constants/api'




export const setErrorAction = (error) => {
  return dispatch => {
    dispatch({
      type: MAP_ERROR_SUCCESS,
      payload: error
    })
  }
}

export const clearErrorAction = () => {
  return dispatch => {
    dispatch({
      type: MAP_CLEAR_ERROR_SUCCESS,
      payload: null
    })
  }
}
