import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { trackPromise } from "react-promise-tracker";

import * as constantsCostCenter from "../../../constants/actions/CostCenters";
import * as APIS from "../../../constants/apis";

function deleteCostCenter(name, ccenters, ccenter, t) {
  if(window.confirm(t("alerts.cost-item-4"))) {
    return dispatch => {
      trackPromise(fetch(`${APIS.API_REST_COSTCENTERS.replace("customer_id",
                                              localStorage.getItem("GlobalCustomerId"))}/${name}`, {
                                                headers:{
                                                  "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                  "Accept-language": localStorage.getItem("LanguageSelected"),
                                                },method: "DELETE" })
        .then(response => {
          if(response.status !== 200) {
            throw new Error(t("alerts.brands-item-5"));
          }
          return response.json(); })
        .then(json => {
          dispatch(getReqSuccess(ccenters, ccenter, json.message))
              return fetch(APIS.API_REST_COSTCENTERS.replace("customer_id", localStorage.getItem("GlobalCustomerId")), {
                                                                headers:{
                                                                  "Authorization": "Basic " + localStorage.getItem('firebaseAuthToken'),
                                                                  "Accept-language": localStorage.getItem("LanguageSelected"),
                                                                } })
                .then(response => response.json())
                .then(json => {
                  dispatch(getCostCentersSuccess({
                    ccenters  : json.costcenters
                  })); })
                .catch(error => {
                  dispatch(getReqError(ccenters, ccenter,
                                      error.toString()));
                }); })
        .catch(error => {
          dispatch(getReqError(ccenters, ccenter,
                              error.toString()));
        }));
    };
  } else {
    return dispatch => {
      dispatch(getCostCentersSuccess({
        ccenters  : ccenters
      }));
    };
  }
};

export function getCostCentersSuccess(json) {
  return {
    type: constantsCostCenter.GET_CCENTERS_SUCCESS,
    json
  };
};

export function getReqSuccess(ccenters, ccenter, message) {
  const json = {
    ccenters  : ccenters,
    ccenter   : ccenter,
    message   : message
  };
  return {
    type: constantsCostCenter.GET_REQUEST_SUCCESS,
    json
  };
};

export function getReqError(ccenters, ccenter, error) {
  const json = {
    ccenters  : ccenters,
    ccenter   : ccenter,
    error     : error
  };
  return {
    type: constantsCostCenter.GET_REQUEST_ERROR,
    json
  };
};

class CostCenterDeleteItem extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { ccenters, ccenter, ccenterDelete, deleteCostCenter } = this.props;
    return (
      <React.Fragment>
        <button onClick={() => deleteCostCenter(ccenterDelete.id, ccenters, ccenter, this.props.t)}
                className="btn btn-danger">
          <i className="fa fa-trash"></i> {this.props.t("globals.delete")}
        </button>
      </React.Fragment>
    );
  };
};

const structuredSelector = createStructuredSelector({
  ccenters  : state => state.ccenters,
  ccenter   : state => state.ccenter
});

const mapDispatchToProps = { deleteCostCenter };

export default connect(structuredSelector, mapDispatchToProps)(CostCenterDeleteItem);
