import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const client = axios.create({
  baseURL: apiURL,
  json: true,
});

export default {
  //customers
  async execute(method, resource, data) {
    let accessToken = localStorage.getItem("firebaseAuthToken");
    const req = await client({
      method: method,
      url: resource,
      data: data,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
    });

    return req.data;
  },

  async executeBlob(method, resource, data) {
    let accessToken = localStorage.getItem("firebaseAuthToken");
    const req = await client({
      method: method,
      url: resource,
      data: data,
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
    });

    return req.data;
  },

  async executeFormData(method, resource, data) {
    let accessToken = localStorage.getItem("firebaseAuthToken");
    const req = await client({
      method: method,
      url: resource,
      data: data,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "multipart/form-data",
        "Accept-language": localStorage.getItem("LanguageSelected"),
      },
    });

    return req.data;
  },

  //user
  getUsers(str) {
    return this.execute("get", "/users" + str);
  },
  getUsersPagination(str) {
    return this.execute("get", `/users-list` + str);
  },
  registerUser(data) {
    return this.execute("post", "/users", data);
  },
  getSearchUsers(text, active) {
    return this.execute("get", `/users?search=${text}&active=${active}`);
  },
  getUser(id) {
    return this.execute("get", `/users/${id}`);
  },
  deleteUser(id) {
    return this.execute("delete", `/users/${id}`);
  },
  putUser(id, data) {
    return this.execute("put", `/users/${id}`, data);
  },

  // roles
  getRoles() {
    return this.execute("get", "/roles");
  },
  // permissions
  getPermission() {
    return this.execute("get", "/permissions");
  },
  // customers
  getCustomers(str) {
    let st = str
    if (!str) {
      st = ""
    }
    console.log(st)
    return this.execute("get", `/customers` + st);
  },
  getCustomers2(str) {
    return this.execute("get", `/customers-list` + str);
  },
  registerCustomers(data) {
    return this.execute("post", `/customers`, data);
  },
  getMultiUser(id) {
    return this.execute("get", `/clients_user/${id}`);
  },
  getSearchCustomers(text, active) {
    return this.execute("get", `/customers?search=${text}&active=${active}`);
  },
  putCustomers(id, data) {
    return this.execute("put", `/customers/${id}`, data);
  },
  getCustomerMacro(macro_id, str) {
    return this.execute("get", `/customers/macro-account/${macro_id}${str}`);
  },
  generateFreeze(data) {
    return this.execute("post", "/tires/freeze", data);
  },
  deleteCustomer(id) {
    return this.execute("delete", `/customers/${id}`);
  },
  // Counts
  getPendingCount(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/standby_inspections/count`
    );
  },
  getPendingRotationCount(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/standby_inspections/count?rotation=vehicle_rotation`
    );
  },
  getPendingWithoutCount(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/inspections/without_kilometers/count`
    );
  },
  getPendingAnalistCount(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/inspections/pending_list_analyst`
    );
  },

  // vehicles
  getVehiclesMin(customer_id) {
    return this.execute("get", `/customers/${customer_id}/vehicles/min_info`);
  },

  // tires
  getTiresMin(customer_id) {
    return this.execute("get", `/customers/${customer_id}/tires/min_info`);
  },

  getAllTires(customer_id, str) {
    return this.execute("get", `/customers/${customer_id}/tires` + str);
  },
  getTire(customer_id, tireId) {
    return this.execute("get", `/customers/${customer_id}/tires/${tireId}`);
  },
  putTire(customer_id, tireId, data) {
    return this.execute(
      "put",
      `/customers/${customer_id}/tires/${tireId}`,
      data
    );
  },
  registerTires(customer_id, data) {
    return this.execute("post", `/customers/${customer_id}/tires`, data);
  },
  deleteTire(customer_id, tireId) {
    return this.execute("delete", `/customers/${customer_id}/tires/${tireId}`);
  },
  getTiresExcel(customer_id) {
    return this.executeBlob("get", `/customers/${customer_id}/tires/export`);
  },
  //  warehouses
  getWarehouses(customer_id) {
    return this.execute("get", `/customers/${customer_id}/warehouses`);
  },
  // tire brands cataogues
  getTireBrandsCatalogue(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/tirebrands/tirecatalogues`
    );
  },
  getRetreadBrandsCatalogue(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/retreadbrands/retreadcatalogues`
    );
  },
  getRetreadBrandsMinCatalogue(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/retreadcatalogues/min_info`
    );
  },
  getTireBrandsMinCatalogue(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/tirecatalogues/min_info`
    );
  },

  // new massives
  massiveBulk(data, str) {
    return this.executeFormData("post", "/bulk-load" + str, data);
  },
  getAllVehicles(customer_id, str) {
    return this.execute("get", `/customers/${customer_id}/vehicles` + str);
  },
  deleteVehicle(customer_id, id) {
    return this.execute("delete", `/customers/${customer_id}/vehicles/${id}`)
  },
  putVehicle(customer_id, vehicle_id, data) {
    return this.execute(
      "put",
      `/customers/${customer_id}/vehicles/${vehicle_id}` +
      "?action=inactive_vehicle",
      data
    );
  },
  getTechnicians(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/users/technitians`
    )
  },
  getWasteCatalogue() {
    return this.execute(
      "get",
      "/discards/catalogues"
    )
  },
  getMovementsCatalogue() {
    return this.execute(
      "get",
      "/movements/catalogues"
    )
  },
  getNoveltiesCatalogue() {
    return this.execute(
      "get",
      "/noveltys/catalogues"
    )
  },
  getRecordCatalogue() {
    return this.execute(
      "get",
      "/antecedents/catalogues"
    )
  },
  // Novelties tire
  postNoveltiesTire(data, tire_id, customer_id) {
    return this.execute(
      "post",
      `/customers/${customer_id}/tires/${tire_id}/noveltys`,
      data
    );
  },

  // inspection madre
  postInspectionMadre(data, customer_id, tire_id, madre_id) {
    return this.execute(
      "post",
      `/customers/${customer_id}/tires/${tire_id}/inspections/${madre_id}`,
      data
    );
  },
  getLastTemp(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/tires/tmp-creation-last`
    );
  },
  putReviewInspectionsAll(customer_id, data) {
    return this.execute(
      "post",
      `/customers/${customer_id}/inspections/stand_by/review/all`,
      data
    );
  },
  deleteRowMassiveStandby(user_id, bulk) {
    return this.execute(
      "delete",
      `/bulk-load/user/${user_id}/task/${bulk}`
    );
  },
  getGroups(customer_id, str) {
    return this.execute(
      "get",
      `/customers/${customer_id}/vehiclegroups` + str
    )
  },
  createGroups(customer_id, str, data) {
    return this.execute(
      "post",
      `/customers/${customer_id}/vehiclegroups` + str,
      data
    )
  },
  getGroup(customer_id, id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/vehiclegroups/${id}`
    )
  },
  putGroup(customer_id, id, str, data) {
    return this.execute(
      "put",
      `/customers/${customer_id}/vehiclegroups/${id}` + str,
      data
    )
  },
  getGroupsOne(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/vehiclegroups?type_group=group1`
    )
  },
  getGroupsTwo(customer_id) {
    return this.execute(
      "get",
      `/customers/${customer_id}/vehiclegroups?type_group=group2`
    )
  },
  deleteGroup(customer_id, id) {
    return this.execute(
      "delete",
      `/customers/${customer_id}/vehiclegroups/${id}`
    )
  },
};
